import React, { Component } from 'react';
import './style.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect,
} from 'react-router-dom';
import Images from '../../helpers/images';
import AddDriverPersonalDetails from '../AddDriverPersonalDetails';
import AddDriverBankDetails from '../AddDriverBankDetails';
import AddDriverContactDetails from '../AddDriverContactDetails';
import apiservices from '../../helpers/apiservices';

class AddDriver extends Component {
  constructor(props) {
    super(props);

    console.log('receive props', props);

    this.state = {
      DriverTabStatus: 1,
      AddBank: false,
      AddContact: false,
      addDriver: null,
      DriverPersonalDetails: null,
      DriverBankDetails: null,
      DriverContactDetails: null,
    };
  }

  SendGoNext = (data) => {
    console.log('check click');
    this.setState({ AddPersonal: false });
    this.setState({ AddBank: true });
    this.setState({ AddContact: false });
  };

  displaySendData = (data, key, details, image, submitkey) => {
    console.log('image', image);

    //  console.log("driver data", data);
    //  console.log("driver key", key);
    //  console.log("driver details", details);

    if (data != 4) {
      this.setState({ DriverTabStatus: data });
    }

    this.setState({ [key]: details }, function () {
      if (data == 4) {
        const {
          DriverPersonalDetails,
          DriverBankDetails,
          DriverContactDetails,
        } = this.state;
        const token = localStorage['accesstoken'];

        var obj = {
          first_name: DriverPersonalDetails.first_name.value,
          last_name: DriverPersonalDetails.last_name.value,
          mobile_no: DriverPersonalDetails.mobile_no.value,
          alternate_no: DriverPersonalDetails.alternate_no.value,
          email: DriverPersonalDetails.email.value,
          age: DriverPersonalDetails.age.value,
          experience: DriverPersonalDetails.experience.value,
          image_url: DriverPersonalDetails.upload_profile_image.value,
          address: DriverPersonalDetails.address.value,
          city: DriverPersonalDetails.city.value,
          pincode: DriverPersonalDetails.pincode.value,
          pan_number: DriverPersonalDetails.pan_number.value,
          shift: DriverPersonalDetails.shift.value,
          total_task_month: DriverPersonalDetails.total_task_month.value,
          // mg_amount : DriverPersonalDetails.mg_amount.value,
          account_name: DriverBankDetails.account_name.value,
          account_number: DriverBankDetails.account_number.value,
          ifsc_code: DriverBankDetails.ifsc_code.value,
          swipe_machine_name: DriverBankDetails.swipe_machine_name.value,
          swipe_machine_number: DriverBankDetails.swipe_machine_number.value,
          contact_name: DriverContactDetails.contact_name.value,
          contact_relation: DriverContactDetails.contact_relation.value,
          contact_number: DriverContactDetails.contact_number.value,
          contact_address: DriverContactDetails.contact_address.value,
          contact_city: DriverContactDetails.contact_city.value,
          contact_pincode: DriverContactDetails.contact_pincode.value,
          upload_doc: image,
          aadhar_no: DriverContactDetails.aadhar_number.value,
          license_no: DriverContactDetails.license_number.value,
          zone_id: DriverPersonalDetails.zone_id.value,
        };
        console.log('driver obj', obj);
        if (submitkey == 'submit') {
          apiservices.adddriver(obj, token).then((response) => {
            console.log('driver reponse', response);
            if (!response.error) {
              // this.props.successdealer(response);
              this.setState({ addDriverSuccess: response });
            } else {
              this.setState({ addDriverFailure: response });
            }
          });
        }

        console.log('submit obj', obj);
      }
    });
  };
  backClick = () => {
    this.props.history.push('/pilots/pilot_master/pilot_details/');
  };
  render() {
    return (
      <div>
        <div className='back-btns'>
          <span className='back-button' onClick={() => this.backClick()}>
            <img src={Images.FadedArrowImg} className='img-flud' />
            Back to the Lists
          </span>
        </div>
        <div class='overall-service'>
          <p className='add_driver'>Add Pilots</p>

          {this.state.DriverTabStatus == 1 ? (
            <AddDriverPersonalDetails
              DriverPersonalDetails={this.state.DriverPersonalDetails}
              SendToNext={(data, key, details) =>
                this.displaySendData(data, key, details)
              }
              {...this.props}
              receiveDriverImage={this.state.driver_image}
              sendImages={(driver_image) =>
                this.setState({ driver_image: driver_image })
              }
            />
          ) : (
            ''
          )}

          {this.state.DriverTabStatus == 2 ? (
            <AddDriverBankDetails
              DriverBankDetails={this.state.DriverBankDetails}
              sendBack={(data) => this.displaySendData(data)}
              SendToBankNext={(data, key, details) =>
                this.displaySendData(data, key, details)
              }
              {...this.props}
            />
          ) : (
            ''
          )}

          {this.state.DriverTabStatus == 3 ? (
            <AddDriverContactDetails
              receiveImage={this.state.images}
              sendImages={(images) => this.setState({ images: images })}
              DriverContactDetails={this.state.DriverContactDetails}
              sendBack={(data) => this.displaySendData(data)}
              SendToContactNext={(data, key, details, image, submitkey) =>
                this.displaySendData(data, key, details, image, submitkey)
              }
              addDriverSuccess={this.state.addDriverSuccess}
              addDriverFailure={this.state.addDriverFailure}
              {...this.props}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default AddDriver;
