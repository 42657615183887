import React, { Component, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  HashRouter,
  withRouter,
  Redirect,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiservices from '../helpers/apiservices';
import LandingPage from '../Component/LandingPage';
import UnAvailablePage from './404';
// import Loader from "../helpers/Loader";
import Main from '../Component/Main';
import SvcMain from '../Component/SvcMain';
import BookingMain from '../Component/BookingMain';
import TicketsMain from '../Component/TicketsMain';
import Header from '../Component/Header';
import Dashboard from '../Pages/Dashboard';
import ServiceCenter from '../Pages/ServiceCenter_Dm1';
import DriverMain from '../Pages/DriverMain';
import UsersMain from '../Pages/UsersMain';
import BookingDetails from '../Pages/BookingDetails';
import Payments from '../Pages/Payments';
import ZoneMain from '../Pages/ZoneMain';
export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authenticated: null,
      token: null,
      title: '',
      Menus: null,
    };
  }
  componentDidMount() {
    this.checkauthenticated();
  }
  componentWillMount = async () => {
    const token = await localStorage.getItem('accesstoken', '');
    if (token !== null) {
      // We have data!!
      this.setState({ token: token });
    }
  };
  checkauthenticated = () => {
    var checkauthenticated = localStorage['accesstoken'];

    console.log('checkauthenticate', this.routerref);
    if (!checkauthenticated) {
      // alert(true);
      this.setState({ authenticated: false });
    } else {
      // this.routerref.history.push('/dashboard')
      if (this.routerref.history.location.pathname == '/') {
        this.routerref.history.push('/dashboard');
      }
      this.setState({ authenticated: true }, function () {
        this.loadMenus();
      });
    }
  };
  logout = () => {
    localStorage.clear('');

    this.checkauthenticated();
    // this.props.history.push("/");
  };
  ifPermission = (data) => {
    var status = true;
    var menus = this.state.Menus;
    if (menus) {
      if (menus.length > 0) {
        var getData = menus.find(
          (menudata) =>
            menudata.permission_code == data && menudata.permission == 1,
        );
        return getData ? true : false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  loadMenus = () => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.getdesigination(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        console.log('menus', response.response.permission);
        this.setState({
          Menus: response.response.permission
            ? response.response.permission
            : [],
        });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  render404 = () => {
    return <Redirect exact to='/404' />;
  };
  render() {
    const { Menus } = this.state;
    console.log('menus', Menus);
    return (
      <>
        <ToastContainer
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <HashRouter ref={(r) => (this.routerref = r)}>
          <Route
            exact
            path='/404'
            render={(props) => <UnAvailablePage {...props} />}></Route>
          {this.state.authenticated == false && (
            <Switch>
              <Route
                exact
                path={'/'}
                render={(props) => (
                  <Main
                    senduserdata={(data) => this.senduserdata(data)}
                    loggedIn={(data) => this.checkauthenticated()}
                    type='login'
                    {...props}
                  />
                )}></Route>
              <Redirect to={'/'} />
            </Switch>
          )}
          {this.state.authenticated && (
            <>
              {/* {Menus&&
               <> */}

              <Switch>
                <Route
                  exact
                  path='/dashboard'
                  render={(props) =>
                    this.ifPermission('DASHBOARD') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <Dashboard
                          {...props}
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
                <Route
                  path='/svc'
                  render={(props) =>
                    this.ifPermission('SVC') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <SvcMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>

                <Route
                  path='/users'
                  render={(props) =>
                    this.ifPermission('USERS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <UsersMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
                <Route
                  path='/zone'
                  render={(props) =>
                    this.ifPermission('ZONE') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <ZoneMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>

                <Route
                  path='/pilots'
                  render={(props) =>
                    this.ifPermission('PILOTS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <DriverMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>

                <Route
                  path='/booking'
                  render={(props) =>
                    this.ifPermission('BOOKINGS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <BookingMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
                <Route
                  path='/booking_details'
                  render={(props) =>
                    this.ifPermission('BOOKINGS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <BookingDetails
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
                <Route
                  path='/payments'
                  render={(props) =>
                    this.ifPermission('PAYMENTS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <Payments
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />{' '}
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
                <Route
                  path='/tickets'
                  render={(props) =>
                    this.ifPermission('TICKETS') ? (
                      <LandingPage
                        title={this.state.title}
                        {...this.props}
                        type={this.state.authenticated}
                        logout={() => this.checkauthenticated()}>
                        <TicketsMain
                          receivePageTitle={(title) =>
                            this.setState({ title: title })
                          }
                          {...props}
                        />
                      </LandingPage>
                    ) : (
                      this.render404()
                    )
                  }></Route>
              </Switch>

              {/* </>
   
            } */}
            </>
          )}
        </HashRouter>
      </>
    );
  }
}
