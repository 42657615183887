import React, { Component } from 'react';
import images from '../../helpers/images';
import './styles.css';
import apiservices from '../../helpers/apiservices';
import 'antd/dist/antd.css';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, Tag, Space, Select, Pagination, Tooltip } from 'antd';
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

var DisabledHeader = [
  {
    id: 1,
    driver_id: 'Pilot ID',
    driver_details: 'Driver Details',
    age: 'Age',
    swipe_machine_id: 'Swipe Machine ID',
    disabled_date: 'Disabled Date',
    disabled_age: 'Disabled Age',
    reason: 'Reason',
  },
];
export default class DisabledDriverTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempdata: [],
      DisabledHeader: DisabledHeader,
      DisabledItemArray: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
      name_search: null,
      reason_search: null,
    };
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.DisabledDriverList();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.DisabledDriverList();
    });
  };

  componentWillMount() {
    this.DisabledDriverList();
  }

  DisabledDriverList = () => {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
      name: this.state.name_search,
      reason: this.state.reason_search,
      city: '',
      zone: '',
    };
    console.log('actve', obj);
    apiservices.disabledriverlist(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({ tempdata: response.response, numbercount: calc });
        console.log('acive reponse', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  cityFilter = (e) => {
    var value = e.target.value;
    console.log('city search', value);
    // this.loadsvclist();
    if (value == '') {
      value = null;
      //
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.DisabledDriverList();
    });
  };
  activeDriver = (data) => {
    const token = localStorage['accesstoken'];
    var obj = { id: data };
    console.log('actve', obj);
    apiservices.activeDriver(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ responseMsg: response.message });
        this.DisabledDriverList();
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
    console.log('active id', data);
  };
  render() {
    return (
      <div>
        <div>
          <div className='search-header driver-search'>
            <div className='row'>
              <div className='col-md-4'>
                <div class='form-group search-list payment-search'>
                  <input
                    type='text'
                    placeholder='Search Name'
                    className='form-control'
                    name='name_search'
                    onChange={(e) => this.cityFilter(e)}
                  />
                  <img src={images.green_search} className='img-fluid' />
                </div>
              </div>
              <div className='col-md-4'></div>
              <div className='col-md-4'>
                <div className='city_search' style={{ display: 'flex' }}>
                  <label>Reason:</label>
                  <input
                    type='text'
                    name='reason_search'
                    className='form-control citysearchbox'
                    onChange={(e) => this.cityFilter(e)}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
          {this.state.responseMsg && this.state.responseMsg ? (
            <div className='failure_msg'>
              {this.state.responseMsg && this.state.responseMsg}
            </div>
          ) : (
            ''
          )}
          {this.state.responseMsg && !this.state.responseMsg ? (
            <div className='success_msg'>
              {this.state.responseMsg && this.state.responseMsg}
            </div>
          ) : (
            ''
          )}
          <div class='driverinfodiv'>
            <div className='driverActiveTable'>
              <table class='table table-hover'>
                <thead>
                  <tr>
                    <th>Driver ID</th>
                    <th>Driver Details</th>
                    <th>Age</th>
                    <th>Swipe Machine ID</th>
                    <th>Disabled Date</th>
                    <th>Disabled Age</th>
                    <th>Reason</th>
                    <th>Enable</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tempdata &&
                    this.state.tempdata.map((obj) => {
                      return (
                        <tr>
                          <td>ID{obj.id}</td>
                          <td>
                            <div className='driverImagediv'>
                              <img
                                src={
                                  obj.image_url
                                    ? obj.image_url
                                    : images.default_image
                                }
                                className='driverImage'
                              />
                            </div>
                            <div className='driverImagename'>
                              {obj.first_name} {obj.last_name}
                            </div>
                            <div className='driverImageNumber'>
                              {obj.mobile_no}
                            </div>
                          </td>
                          <td> {obj.age}</td>
                          <td>{obj.swipe_machine_number}</td>
                          <td>{moment(obj.disable_date).format('DD/MM/YY')}</td>
                          <td>{obj.disable_age}</td>
                          <td>
                            <div className='reason_reduce'>
                              {obj.disable_reason}
                            </div>
                          </td>
                          <td>
                            <Tooltip
                              placement='top'
                              title='Active Driver'
                              color='white'
                              key='#0B485A'>
                              <img
                                src={images.approvel}
                                style={{ width: 38 }}
                                onClick={() => this.activeDriver(obj.id)}
                              />
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {this.state.tempdata && this.state.tempdata.length == 0 && (
                <div className='no_records_found'>No Records Found</div>
              )}
              {this.state.loading == true && <CircularProgress />}
            </div>
          </div>
        </div>
        <Pagination
          total={this.state.numbercount}
          current={this.state.currentPage}
          onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize}
          pageSizeOptions={['5', '10', '15']}
          onShowSizeChange={(current, size) => this.showChanger(current, size)}
          showSizeChanger
          itemRender={this.itemRender}
        />
      </div>
    );
  }
}
