import React, {Component} from 'react';
 import Images from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import S3FileUpload from "react-s3";
import Aws from "../../helpers/awskeys";
const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};


class AddDriverContactDetails extends Component{

    constructor(props){

        super(props);

        this.state = {
          imageUrl:'',
          filesUpload:"",
          responseMsg:null,
          loading:false,
          filenewlist:[],
          fileupload_error:null,
          fields : {
            contact_name : {validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
            contact_relation : {validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
            contact_number : {validations:[{name:'required'},{name:'mobile'},{name:'Noemptyspace'}],error:null,value:''},
            contact_address :{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
            contact_city : {validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
            contact_pincode : {validations:[{name:'required'},{name:'Noemptyspace'},{name:'zipcode'}],error:null,value:''},
            aadhar_number : {validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
            license_number : {validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
            upload_image : {validations:[{name:'required'}],error:null,value:''}
          }
        }
    }


    checkValidations = (value, key) => {
        
      var fields = this.state.fields;
       
      fields[key].value = value;
      
      var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
      
      fields[key].error=checkstatus.msg;
      
      var getallkeys=Object.keys(fields);
      
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
       
      if(filtererrors.length>0){
  
        this.setState({error:true})
        //errorblock
      }else{
        this.setState({error:false})
  
        //successblock
      }
      this.setState({fields},function(){
        this.props.SendToContactNext(4,'DriverContactDetails', fields,this.state.filenewlist);
      });
      
    }

    goBack = () => {

        this.props.sendBack(2);
    }

    AddDriverSubmit = async () => {
    console.log("fields submit",this.state.fields);
      var fields = this.state.fields;
    var getallkeys=Object.keys(fields);
    getallkeys.map((obj)=>{
    var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
    fields[obj].error=checkstatus.msg;
    })
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      //errorblock
      this.setState({error:true})
    }else{
      // this.props.SendToContactNext(4,'DriverContactDetails', fields,apiimagefile);
      // console.log("upload,image",this.state.fields.upload_image);'
      console.log("upload image",this.state.fields.upload_image.value)
      this.setState({loading:true});
      var fileList=this.state.fields.upload_image.value;
      console.log("else part",fileList);
      var temparray=[];
      for(var i in fileList){
        var response=await S3FileUpload.uploadFile(fileList[i], config);
        console.log("response",response);
        temparray.push(response.location);
        if(fileList.length-1==i){
          // console.log();
          var apiimagefile=temparray.join(',');
          this.props.SendToContactNext(4,'DriverContactDetails', fields,apiimagefile,'submit');
        }
      }
    //   //successblock
      this.setState({error:false});
    }
    this.setState({fields});  

    }

    componentWillReceiveProps(props){
      console.log("props",props);
      if(props.DriverContactDetails){

        this.setState({fields : props.DriverContactDetails});
      }
      if(props.addDriverSuccess){
        this.setState({loading:false})
        this.setState({responseMsg:props.addDriverSuccess})
        setTimeout(() => {
          props.history.push('/pilots/pilot_master/pilot_details/');
        }, 3000);
        
      }
      if(props.addDriverFailure){
        this.setState({responseMsg:props.addDriverFailure})
      }
      
    }
   
    render(){
     console.log("file checking",this.state.filenewlist);
         var self=this;
        var temparray=[];
        const props = {
          name: 'file',
          accept:'application/pdf,image/*',
          multiple: true,
          action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
          fileList:self.state.filenewlist,
          showUploadList: {
          
            showRemoveIcon: true,
            removeIcon: <img src={Images.file_remove} className="file_remove" />,
          },
          // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
          beforeUpload:(file)=>{
          console.log("filelist",self.state.filenewlist);
          var filenewlist=self.state.filenewlist;
          filenewlist.push(file);
          if(self.state.filenewlist.length<3){
            
            self.setState({filenewlist},function(){
              self.checkValidations(filenewlist,'upload_image');
              self.props.sendImages&&self.props.sendImages(filenewlist);
            });
            
          }else {
            filenewlist=filenewlist.splice(0,2);
            self.setState({fileupload_error:'Max Files Upload is 2'})
            self.setState({filenewlist});
          }
          console.log("beforeuploadfile",file)
          // return true;
        },
        onChange:(info)=> {
          
          var filenewlist=self.state.filenewlist;
          if(info.file.status=='removed'){
            var findindex=filenewlist.findIndex((obj)=>obj.uid==info.file.uid);
            filenewlist.splice(findindex,1);
            self.setState({filenewlist});
          }
          
         
          console.log("delete info",info);
        }
       
      };
        return(
            <div>

 
           <div className="svc-dealer-sec">
             <div className="dealer-master">
                <div className="dealer-head">
                3. Contact Person Details
                </div>
                <div className="dealer-body">
                     
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Contact Person Name</label>
                          <input type="text" className="form-control" name="contact_name" value={this.state.fields.contact_name.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['contact_name'].error && this.state.fields['contact_name'].error}</span> 
                        </div>

                        <div class="form-inline">
                          <label>Contact Person Relation</label>
                          <input type="text" className="form-control" name="contact_relation" value={this.state.fields.contact_relation.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['contact_relation'].error && this.state.fields['contact_relation'].error}</span> 
                        </div>                     
                     </div>

                     <div className="form-group">
                       <div class="form-inline">
                          <label>Contact Person Mobile Number</label>
                          <input type="text" className="form-control" name="contact_number" value={this.state.fields.contact_number.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['contact_number'].error && this.state.fields['contact_number'].error}</span> 
                        </div>
 
                     </div>

                     <div className="form-group">
                         <label>
                         Address
                         </label>

                         <textarea row="5" className="form-control" name="contact_address" value={this.state.fields.contact_address.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}></textarea>
                         <span className="error_msg_driver">{this.state.fields['contact_address'].error && this.state.fields['contact_address'].error}</span>
                     </div>
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Town / City</label>
                          <input type="text" className="form-control" name="contact_city" value={this.state.fields.contact_city.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['contact_city'].error && this.state.fields['contact_city'].error}</span> 
                        </div> 

                        <div class="form-inline">
                          <label>Pincode</label>
                          <input type="text" className="form-control" name="contact_pincode" value={this.state.fields.contact_pincode.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['contact_pincode'].error && this.state.fields['contact_pincode'].error}</span> 
                        </div>                     
                     </div>
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Aadhar Number</label>
                          <input type="text" className="form-control" name="aadhar_number" value={this.state.fields.aadhar_number.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['aadhar_number'].error && this.state.fields['aadhar_number'].error}</span> 
                        </div> 

                        <div class="form-inline">
                          <label>License Number</label>
                          <input type="text" className="form-control" name="license_number" value={this.state.fields.license_number.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['license_number'].error && this.state.fields['license_number'].error}</span> 
                        </div>                     
                     </div>

                     <div className="form-group">
                         <label>
                         Upload Aadhar Card and Driving License
                         <span style={{color:'red',marginLeft:10}}>{this.state.fileupload_error}</span></label>
                         <div style={{marginTop:30}}>
                         <Dragger {...props}  >
    {/* <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p> */}
    <div><button className="upload_btn">Upload Files</button></div>
    <p className="ant-upload-text"></p>
    <p className="ant-upload-hint">
    Files can be in .jpg or .pdf format
    </p>
  </Dragger>
  
  <span className="error_msg_driver">{this.state.fields['upload_image'].error && this.state.fields['upload_image'].error}</span> 
  </div>
                         {/* <textarea row="5" className="form-control"></textarea> */}
                     </div>
 
                </div>

                {this.state.loading==true&&
            <CircularProgress />
                }
                
             </div>

             <button disabled={this.state.responseMsg&&!this.state.responseMsg.error} className="btn btn-white-blue prev-button" onClick={() => this.goBack()}>  <img src={Images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button  disabled={this.state.responseMsg&&!this.state.responseMsg.error} className="btn btn-dark-blue next-button" onClick={() => this.AddDriverSubmit()}>Submit</button>
             {this.state.responseMsg&&this.state.responseMsg?
            <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg.message}</div> :''
            }
            {this.state.responseMsg&&!this.state.responseMsg?
            <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg.message}</div> :''
            }
             
             
             {/* <button eventKey="second" className="btn btn-dark-blue next-button"  onClick={this.handleButtonClick}>Next  <img src={next} className="img-fluid arrow"/></button> */}
          </div>


        
            </div>
        )





        
    }

    componentDidMount(){

      console.log("AddDriverContact", this.props);
      if(this.props.DriverContactDetails){

        this.setState({fields : this.props.DriverContactDetails});
      }
      if(this.props.receiveImage){
        this.setState({filenewlist:this.props.receiveImage})
        console.log("eceive image",this.props.receiveImage);
      }
    }
}


export default AddDriverContactDetails;