import React, { Component } from "react";
import images from "../../helpers/images";
import apiservices from '../../helpers/apiservices';
import 'antd/dist/antd.css';
import ValidationLibrary from '../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import "./styles.css";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
 
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import ActiveTableList from './ActiveTableList';
import DriverDetails from "../DriverDetails";

export default class ActiveDriverTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
           

        }
    }

    
    
    render() {
        const{reason_type}=this.state;
       
        return (
      <div>

         <Route exact  path={`${this.props.match.path}/`}  render={props=> (<ActiveTableList {...props} parentProps={this.props} />)} />
         {/* <Route  path={`${this.props.match.path}/driverdetails`} render={props=>(<DriverDetails parentProps={this.props}  {...props}  />)}/> */}

         
</div>

        )
    }
}