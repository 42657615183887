import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
import apiservices from '../../helpers/apiservices';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
  import AddTickets from '../../Pages/AddTickets';
import HighPriority from '../../Pages/TicketsectionMain/HighPriority';
import MediumPriority from '../../Pages/TicketsectionMain/MediumPriority';
import LowPriority from '../../Pages/TicketsectionMain/LowPriority';
import AllPriority from '../../Pages/TicketsectionMain/AllPriority';
import ClosedTickets from '../../Pages/TicketsectionMain/ClosedTickets';
// import ActiveDriverTable from "../ActiveDriverTable";
// import DisabledDriverTable from "../DisabledDriverTable";
// import PilotDefaulter from '../PilotDefaulter';
// import PilotResigned from '../PilotResigned';
// import PilotInRequest from '../PilotInRequest';
// import AddDriver from '../AddDriver';
// import DriverDetails from "../DriverDetails";
class TicketsMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opentickets:false,
            reload:null
        }
    }
    listTicketCount=()=>{
        const token = localStorage['accesstoken'];
        var obj={};
          apiservices.listTicketCount(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        console.log("dashboard",response)
                      this.setState({count:response && response})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      clearNotification = () => {
        const token = localStorage['accesstoken'];
        var obj={};
          apiservices.clearNotification(obj, token)
                .then((response)=>{
                    if(!response.error){
                        console.log("clearNotification",response)
                      }
                    else{
                        console.log("clearNotification",response)
                    }
                })
      };
      componentWillMount(){
          this.listTicketCount();
          this.clearNotification();
      }
AddTickets=()=>{
    this.setState({opentickets:true});
    const token=localStorage['accesstoken'];

}
CloseTickets =()=>{
    this.setState({opentickets:false});
}
receivedialogrequest=(data,reload)=>{
    this.setState({opentickets:data,reload:reload});  
}


    render() {
        return (
            <div>
                 
                
                <div class="tickets-menu">
                   <div className="container">
                        <Row>
                            <Col sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8} sm={8} className="introcenter" >
                                        <Nav variant="pills" className="flex-row tickets" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                                            <Nav.Item>
                                            <NavLink exact={true} to={`${this.props.match.path}/`} activeClassName="active_cls">High Priority ({this.state.count && this.state.count.highTicket})</NavLink>
                                               
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink  to={`${this.props.match.path}/medium_priority`} activeClassName="active_cls">Medium Priority ({this.state.count && this.state.count.mediumTicket})</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink  to={`${this.props.match.path}/low_priority`} activeClassName="active_cls">Low Priority ({this.state.count && this.state.count.lowTicket})</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink  to={`${this.props.match.path}/all_tickets`} activeClassName="active_cls">All</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink  to={`${this.props.match.path}/closed_ticket_history`} activeClassName="active_cls">Closed Ticket History</NavLink>
                                            </Nav.Item>
                                            
                                        </Nav>
                                    </Grid>
                                    <Grid item xs={12} md={4} sm={4} className="introcenter driver_grid" >
                                        <div className="addNewDriver" >
                                            <div className="driverButton" onClick={()=>this.AddTickets()}>
                                                <img src={images.add_img}/>
                                                Add Ticket
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Col>
                            <Col sm={12}>
                            <Switch>
                <Route exact path={`${this.props.match.path}/`} render={props => (<HighPriority reload={this.state.reload}/> )} />
                <Route exact path={`${this.props.match.path}/medium_priority`} render={props => (<MediumPriority/> )} />
                <Route exact path={`${this.props.match.path}/low_priority`} render={props => (<LowPriority/>)} />
                <Route exact path={`${this.props.match.path}/all_tickets`} render={props => (<AllPriority /> )} />
                <Route exact path={`${this.props.match.path}/closed_ticket_history`} render={props => (<ClosedTickets /> )} />
                </Switch>
                            </Col>
                        </Row>
                        </div>
                </div>
               
                <Dialog  open={this.state.opentickets}  className="ticketmainpage">
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}onClick={() => this.CloseTickets()} /></div>
                    <div className="modalcntntcls">
                   
                  <div className="add_ticket_text">Add Ticket</div> 
                  <AddTickets senddialogrequest={(data,reload)=>this.receivedialogrequest(data,reload)}/>
                    </div>
                </Dialog>

            </div>

            
        )

    }
    componentDidMount() {
        this.props.receivePageTitle && this.props.receivePageTitle('Tickets');
    }

}


export default TicketsMain;