import React, {Component} from 'react';
import './style.css';
import Images from '../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
   
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";

//   import Checklist from './Checklist';
//     import Goals from './Goals';
//     import BankDetails from './BankDetails';
//     import PayoutsMain from './PayoutsMain';
import { First } from 'react-bootstrap/PageItem';
import AllPayments from './AllPayments';
import SettlementTabDetails from './SettlementAdvice';
import SVCWallet from './SVCWallet';
import PendingApprovals from './PendingApprovals';
class Payments extends Component{
    constructor(props){
      console.log("props",props);
        super(props);
        this.state = {
          SvcDetails : false,
          svcList: true,
          tabKey:'first',useredit_id:null,
          svcDynId:null,
          svc_id:null
        }
    }
    SvcDetails = () => {

      this.setState({SvcDetails : true});
    
      this.setState({svcList : false});
       
     }
    componentWillMount(){
      if(this.props.action=='first'){
        this.setState({tabKey:'fourth'})
      }else if(this.props.action=='second'){
        this.setState({tabKey:'fourth'})
      }else{
        this.setState({tabKey:'first'})
      }
      console.log("this props",this.props);
    }
     svcDetailsHide = () => {
      this.setState({SvcDetails : false});
    
      this.setState({svcList : true});
     }
     componentWillReceiveProps(props){
       this.setState({tabKey:props});
      //  var useredit_id=props.history.location.state;
      //  this.setState({useredit_id:useredit_id});
      //  console.log("will receive",this.state.useredit_id);
     }
     goBack =(data)=>{
       console.log("goback",data);
      //  this.props.goBack(data)
     }
render(){

    return(
        <div >
<div className="container">
            
                <div class="appdata-tabs payment_main">
                  <br/>
                {/* <div className="back-btns">
             <Link to={`${this.props.match.path}/`}><span className="back-button" > 
                    <img src={Images.FadedArrowImg} className="img-flud"/>
                 
                 Back to the Lists</span></Link>
             </div>            */}
             <div className="booking-tabs">

<Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row booking_mainview nav nav-pills" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
 
                  <NavLink className={`${window.location.hash.includes('/all_payments/')&&"active_cls"}`} activeClassName="active_cls" exact={true} to={`${this.props.match.path}/all_payments/`}  >All Payments</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/settlement_advice/')&&"active_cls"}`}  to={`${this.props.match.path}/settlement_advice`} activeClassName="active_cls">Settlement Advice</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink  className={`${window.location.hash.includes('/svc_wallet/')&&"active_cls"}`}  to={`${this.props.match.path}/svc_wallet`} activeClassName="active_cls">SVC Wallet</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/pending_approvals/')&&"active_cls"}`} to={`${this.props.match.path}/pending_approvals`} activeClassName="active_cls">Pending Approvals</NavLink>
                </Nav.Item>
                
              </Nav>
             
            </Col>
            <Col sm={12}>
             
              <Switch>
              <Route  path={`${this.props.match.path}/all_payments`} render={props=> (<AllPayments {...props}/>)} />
                        {/* <Route  path={`${this.props.match.path}/settlement_advice`} render={props=> (<SettlementAdvice {...props}/>)} /> */}
                        <Route  path={`${this.props.match.path}/settlement_advice`} render={props => (<SettlementTabDetails {...props} />)} />
                        <Route  path={`${this.props.match.path}/svc_wallet`} render={props=> (<SVCWallet {...props}/>)} />
                        <Route  path={`${this.props.match.path}/pending_approvals`} render={props=> (<PendingApprovals {...props}/>)} />
                 
              </Switch>
            </Col>
          </Row>

                {/* <Row>
                    <Col sm={12}>
                    <Nav variant="pills" className="flex-row payment_main_nav"  selectedIndex={this.state.selectedIndex}
                        onSelect={this.handleSelect}>
                   
                   <NavLink exact={true} className={`${window.location.hash.includes('/all_payments')&&"active"}`} to={`${this.props.match.path}/all_payments`}  >All Payments</NavLink>
                   <NavLink   to={`${this.props.match.path}/settlement_advice`}  >Settlement Advice</NavLink>
                   <NavLink  to={`${this.props.match.path}/svc_wallet`} >SVC Wallet</NavLink>
                   <NavLink   to={`${this.props.match.path}/pending_approvals`} >Pending Approvals</NavLink>
                        
                    </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="appdata_first">
                        <Switch>
                        <Route  path={`${this.props.match.path}/all_payments`} render={props=> (<AllPayments {...props}/>)} />
                        <Route  path={`${this.props.match.path}/settlement_advice`} render={props=> (<SettlementAdvice {...props}/>)} />
                        <Route  path={`${this.props.match.path}/svc_wallet`} render={props=> (<SVCWallet {...props}/>)} />
                        <Route  path={`${this.props.match.path}/pending_approvals`} render={props=> (<PendingApprovals {...props}/>)} />
            
          
            
            </Switch>
                   
                    </div>
                    </Col>
                </Row> */}
                {/* </Tab.Container> */}
                </div>
                </div>
                {/* Form Tabs start */}
        </div>
        
        </div>
    )
}
componentDidMount() {
  this.props.receivePageTitle && this.props.receivePageTitle('Payments');
}

}

export default Payments;