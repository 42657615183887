import React, {Component} from 'react';
import images from '../../../helpers/images';
import {CSVLink, CSVDownload} from 'react-csv';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Pagination, Select} from 'antd';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
 import  './styles.css';
import { DatePicker } from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import moment from 'moment';
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function handleChange(value) {
  console.log(`selected ${value}`);
}
const { RangePicker } = DatePicker;

var mishapsHeader = [{'id' : 1,  'date': 'Date','time':'Time', 'svc_name' : 'SVC Name', 'svc_id' : 'SVC ID',  'service_period' : 'Service Period','settlement_date':'Settlement Date','neft_id':'NEFT ID', 'reference_id' : 'Reference ID'}];



var settlementviewHeader = [{'id' : 1,  'date': 'Booking Date','booking_id':'Booking ID', 'type' : 'Type', 'status' : 'Status',  'amount' : 'Amount'}];

class SettlementAdvice extends Component{
    constructor(props){
        super(props);
        this.state = {
            mishapsHeader : mishapsHeader,
            settlemntList :[],
            settlementDetails:[],  
            settlementviewHeader:settlementviewHeader, 
            settlementviewList:[],         
            currentPage : 1,
            pageSize:10,
            openDialog:false,
            settlementview:false,
            svc_id:null,
            settledetails_obj:null,
            minDate:null,
            fromDate:null,
            toDate:null,
            fields: {
                svc_name: { validations: [{ name: 'required' }], error: null, value: '' },
            settlementdate:{validations:[{name:'required'}],error:null,value:''},
            },
            settlementadvice_fields:{
              svc_center:{validations:[],error:null,value:''},
            },
            csvData:[['firstname', 'lastname', 'email']]


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
openDialog =()=>{
    this.setState({openDialog:true});
}
getminDate =(data)=>{
    
    const token=localStorage['accesstoken'];
    if(!data){
        return;
    }
    var fields=this.state.fields;
    fields['svc_name'].value=data;
    this.setState({fields});
    var obj={svc_center_id:data};
    console.log("get date", obj);     
    apiservices.getminsettlementdate(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  console.log("min date",response)
                  this.setState({minDate:response.response.min_day})
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
closeDialog =()=>{
  var fields=this.state.fields;
  fields['settlementdate'].error='';
  fields['settlementdate'].value='';
    this.setState({openDialog:false,fields});
}
checkvalidations = (value, key) => {
console.log("date value",value)
if(key=='settlementdate'){
  console.log("settle date",value);
}
if(key=='svc_name'){
    this.getminDate(value);
var fields=this.state.fields;
fields['settlementdate'].value=''
    this.setState({fields,svc_id:value});
}

    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0) {
      this.setState({ error: true })
      //errorblock
    } else {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  popupSubmit =()=>{
    var fields =this.state.fields;
    var getallkeys=Object.keys(fields);
    getallkeys.map((obj)=>{
    var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
    fields[obj].error=checkstatus.msg;
    })
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      //errorblock
      this.setState({error:true})
    }else{
      //successblock
      this.setState({error:false});
      this.loadsettlementDetails();
      
    // alert('hii');
    }
    this.setState({fields});
}
loadsettlementDetails =()=>{
    const token=localStorage['accesstoken'];
    var obj={svc_center_id:this.state.fields.svc_name.value,to_date:moment(this.state.fields.settlementdate.value).format(dateFormat)};
    this.setState({settledetails_obj:obj});
    console.log("popup submit", obj); 
    apiservices.listsettlementdetails(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  console.log("settlemnt details",response)
                  setTimeout(() => {
                    this.setState({settlementview:true,openDialog:false})
                  },3000);
                  
                  this.setState({settlementviewList:response})
                  this.setState({success_msg:response.message})
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
loadServiceDrop=()=>{
    const token=localStorage['accesstoken'];
    var obj={};
          apiservices.listsvccenterdrop(obj,token)
          .then((response)=>{
            if(!response.error){
              this.setState({svc_name:response.response})
              var fields=this.state.fields;
              // if(!value){
            var svc_drop= response.response.length>0?response.response[0].id:'';   
             this.getminDate(svc_drop)
            //   this.setState({fields})
              // }
              console.log("login response",response);
      
            }else{
              this.setState({error_msg:response.message})
            }
              
          })
        }
        listSettlement =()=>{
            const token=localStorage['accesstoken'];
            var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center:this.state.settlementadvice_fields.svc_center.value,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
            console.log("actve", obj);     
            apiservices.listsettlementadvice(obj,token)
                  .then((response)=>{
                      // this.setState({loading:false});
                      if(!response.error){
                          var limit=response.count;
                          var total_items=this.state.pageSize;
                          var calc=Math.ceil(limit/total_items)*total_items;
                          console.log("calc",calc);
                          this.setState({settlemntList:response.response, numbercount:calc})
                          console.log("settle list", response);
                        }
                      else{
                        this.setState({error_msg:response.message})
                      }
                  })
        }
        onchangePagination = (page, pageSize) => {
            console.log("page", page);
            this.setState({ currentPage: page }, function(){
                this.listSettlement();        
            });
        
            console.log("page size", pageSize);
          }
          showChanger = (current, size) => {
            this.setState({ pageSize: size, currentPage : 1 }, function(){
                this.listSettlement(); 
            });
          }
          componentWillMount(){
              this.loadServiceDrop();
              this.listSettlement();
              this.loadSeriveCenter();
          }
          payNow =() =>{
            const token=localStorage['accesstoken'];
            var current_date=new Date();
            var obj={svc_center_id:this.state.settledetails_obj.svc_center_id,to_date:this.state.settledetails_obj.to_date};
            console.log("popup submiy", obj); 
           
            apiservices.makesettlementpay(obj,token)
                  .then((response)=>{
                      // this.setState({loading:false});
                      if(!response.error){
                          console.log("pay now",response)
                          setTimeout(() => {
                            this.setState({settlementview:false,payment_msg:''});
                            this.listSettlement();

                          },3000);
                          this.setState({payment_msg:response.message})
                        }
                      else{
                        this.setState({error_msg:response.message})
                      }
                  })  
          }
          disableDate =(data)=>{
            if(!this.state.minDate){
                return true;
            }else{
              var minDate=moment(this.state.minDate);
              if(data<minDate || data>new Date()){
                return true;
            }
            }
            return false;
          }
          checkfiltervalidations =(data)=>{
            console.log("priority",data);
           
            this.setState({fromDate:data[0],toDate:data[1]},function(){
              this.listSettlement();
            })
          }
          ClearDatepicker =()=>{
            this.setState({fromDate:null,toDate:null},function(){
              this.listSettlement();
            })
          }
         
        loadSeriveCenter=(data)=>{
            const token=localStorage['accesstoken'];
            var obj={};
            console.log("actve", obj);     
            apiservices.bookingsvclistdropdown(obj,token)
                  .then((response)=>{
                      // this.setState({loading:false});
                      if(!response.error){
                        this.setState({svc_center:response.response})
                        console.log("login response",response);
                
                      }else{
                        this.setState({error_msg:response.message})
                      }
                  })
        }
        checksvcfilter =(data)=>{
            var settlementadvice_fields=this.state.settlementadvice_fields;
            settlementadvice_fields['svc_center'].value=data;
            this.setState({settlementadvice_fields},function(){
              this.listSettlement();
            })
          }
          genratecsvData =(data)=>{
            console.log("export data",data);
            var stringifydata=JSON.parse(JSON.stringify(data));
            var csvData=[Object.keys(stringifydata)];
            csvData=csvData.concat([Object.values(stringifydata)]);
            console.log("csv data",csvData);
            return csvData;
            
          } 
render(){
    console.log("settle ment details",this.state.settledetails_obj)
const {svc_name,settlementviewList,svc_center} =this.state;
    return(
        <div>
            {this.state.settlementview==false?
        <div className="settlment_advice_main">
              <div className="container1">
             
                    <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      {/* <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={images.green_search} className="img-fluid"/> */}
                                  </div>
                             </div>
                             <div className="col-md-9">
                             <div className="filter_main">
                                    <div className="service_listing booking-list payments-list" style={{float:'none'}}>
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from payment_from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>


                                    <div className="payment-dropdown float-right mr-2"> <span className="payment">SVC Name:</span> 
                                    <Select value={[this.state.settlementadvice_fields.svc_center.value?parseInt(this.state.settlementadvice_fields.svc_center.value):'']}  onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                             <Option value="">All</Option>
                         {svc_center&&svc_center.length>0&&svc_center.map((item)=>{
                              return(
                               
                               
                              <Option value={item.id}>{item.centre_name}</Option>
                              
                              )
                            })} 
                            
                           </Select> 
                            </div>
                            <div>
                                <button className="maket_settle_btn" onClick={()=>this.openDialog()}>Make Settlement</button>
                            </div>
                             
                             
                             </div>
                             </div>
                         </div>
                    </div>
                              
                                 <div className="table-list customer_table">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.mishapsHeader && this.state.mishapsHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.service_period}</th>
                                                <th>{item.settlement_date}</th>
                                                <th>Download</th>
                                                {/* <th>{item.neft_id}</th> */}
                                                {/* <th>{item.reference_id}</th> */}
                                                
                                            </tr>
                                        )
                                    })}
                                </thead>
                               <tbody>

                                    {this.state.settlemntList &&this.state.settlemntList.length>0&&this.state.settlemntList.map((item) => {

                                        return(
                                            <tr>
                                                <Link to={`${this.props.parentProps.match.path}/settlement_details/${item.id}`}><td>{item.centre_name}</td></Link>
                                                <td>ID{item.svc_center_id}</td>
                                                <td>{moment(item.service_from).format('DD/MM/YY')} <span style={{color:'#000',marginLeft:10,marginRight:10}}>to</span> {moment(item.service_to).format('DD/MM/YY')}</td>
                                                <td>{moment(item.created_at).format('DD/MM/YY')}</td>
                                                <td><CSVLink data={this.genratecsvData(item)} ><img src={images.download} /></CSVLink></td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody> 
                                
                            </table>
                            {
                              this.state.settlemntList&&this.state.settlemntList.length==0&&
                              <div>No Records Found </div>
                            }
                           
                        </div>
                     </div>

                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
                     
             </div>
             </div>:


             <div className="settlement_view">
            <div className="container">
                <div className="row">
<div className="col-md-6 text-align-left">
 <div className="payment-dropdown settlment_search"> <span className="make_settle_text">Make Settlement</span>

 
                              {/* <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={images.green_search} className="img-fluid"/>
                                  </div> */}

                            </div>
</div>
                </div>
                {this.state.payment_msg?
<div className="success_msg">{this.state.payment_msg}</div>:''
}
{this.state.error_msg?
<div className="error_msg">{this.state.error_msg}</div>:''
}
                <div className="row">
                    <div className="col-md-8">

                    <div className="table-list customer_table">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.settlementviewHeader && this.state.settlementviewHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.date}</th>
                        <th>{item.booking_id}</th>
                        <th>{item.type}</th>
                        <th>{item.status}</th>
                        <th>{item.amount}</th>
                        <th>Booking Charge</th>
                        <th>Transaction Fee</th>
                        <th>GST</th>
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.settlementviewList &&this.state.settlementviewList.response&&this.state.settlementviewList.response.length>0&&this.state.settlementviewList.response.map((item) => {

                return(
                    <tr>
                      <td>{moment(item.slot_day).format('DD/MM/YY')}</td>
                        <td>ID{item.id}</td>
                        <td>{item.booking_type}</td>
                        <td>{item.status}</td>
                        <td>Rs.{item.invoice_amount}</td>
                        <td>Rs.{item.booking_charge}</td>
                        <td>Rs.{item.transit_amount}</td>
                        <td>Rs.{item.gst_amount}</td>
                        
                    </tr>
                )
            })}
        </tbody>
    </table>

    


</div>
</div>
                    </div>
                    <div className="col-md-4">

                    <div className="table-list customer_table">

<div className="summary-header">Payment Summary</div>
<div class="table-responsive summary-response">

     
    <table class="table table-borderless">
        {/* <thead>
            
                    <tr>     
                        <th>Payment Summary</th>
                        <th></th>
                    </tr>
               
        </thead> */}
        <tbody>

            
                      <tr>
                      <td>Service Amount</td>
    <td>Rs.{settlementviewList.service_charge}</td>
                        
                    </tr> 
                    <tr>
                      <td>Booking Charges</td>
      <td>Rs.{settlementviewList.booking_charge}</td>
                        
                    </tr> 
                    <tr>
                        
                    </tr>
                    <tr class="border-summary">
                    {/* <tr> */}
                    <td style={{fontSize:16,color:'#2F6690'}}>Total Payments</td>
                      <td style={{fontSize:16,color:'#2F6690'}}>Rs.{settlementviewList.service_charge}</td> 
                    </tr>
                     
                                    
        </tbody>
    </table>

    <div className="pay-buttons">
                    <button className="pay_btn" style={{opacity:settlementviewList.service_charge!=0?1:0.5}} onClick={()=>settlementviewList.service_charge!=0?this.payNow():''}>Pay Now</button>
                    </div>
                     


</div>
</div>
                    </div>
                </div>
                         
                                  </div>

             </div>
}

             <Dialog  open={this.state.openDialog}  className={`booking_pickup`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    <div className="modalcntntcls">
                   <div className="heading_text">
                       Make Settlement
                   </div>
                   <div>
                      
                   <div className="form-group">
                       <div class="form-inline position-relative edit_design_drop">
                          <label className="design_lable">SVC Name</label>
                         <Select value={[parseInt(this.state.fields.svc_name.value)]}  onChange={(value) => this.checkvalidations(value,'svc_name')} name="svc_name" >
                         {svc_name&&svc_name.length>0&&svc_name.map((item)=>{
                              return(
                              <Option value={item.id}>{item.centre_name}</Option>
                              )
                            })} 
                            
                           </Select> 
                            <img src={images.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.fields['svc_name'].error&&this.state.fields['svc_name'].error}</span>
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline">
                          <label className="design_lable">Select the Date</label>
                          
                          {/* <input type="text" className="form-control input_paused" name="svc_name" value={this.state.fields.svc_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['svc_name'].error&&this.state.fields['svc_name'].error}</span> */}
                         
                         <div className="service_listing booking-list payments-list makepayment" style={{float:'none',zIndex:9999999}}>
                                       
                                       
                                          <div className="date-pickers booking settlepick">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                           onChange={(value) => this.checkvalidations(value?value[1]:null,'settlementdate')}
                                        //    defaultValue={[null,null]}
                                          value={[(this.state.minDate?moment(this.state.minDate):null), (this.state.fields.settlementdate.value?moment(this.state.fields.settlementdate.value):null)]}
                                          allowEmpty={[true,true]}
                                            disabled={[true, false]}
                                           disabledDate={(date)=>this.disableDate(date)}
                                            // dateRender={current => {
                                            //     const style = {};
                                            //     if (current.date() === 1) {
                                            //     style.border = '1px solid #1890ff';
                                            //     style.borderRadius = '50%';
                                            //     }
                                            //     return (
                                            //     <div className="ant-picker-cell-inner" style={style}>
                                            //         {current.date()}
                                            //     </div>
                                            //     );
                                            // }}
                                            />
                                          </div>
                                          <span className="error_msg_adddealer">{this.state.fields['settlementdate'].error&&this.state.fields['settlementdate'].error}</span> 
                                    </div>
                                    <div>
                                    <div>
                                {/* <button className="maket_settle_btn" onClick={()=>this.openDialog()}>Make Settlement</button> */}
                            </div>
                            </div>
                        </div>
                       </div>
                     {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                   }
                 {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit design_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    
                </Dialog> 


             
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default SettlementAdvice;