
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import '../styles.css';
import moment from 'moment';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import ChauffeurVehicleDetails from '../Chauffeur/ChauffeurVehicleDetails';
import ChauffeurLocation from '../Chauffeur/ChauffeurLocation';
import SlotServiceDetails from '../Chauffeur/SlotServiceDetails';
const dateFormat = 'YYYY/MM/DD';
class ChauffeurMain extends Component{
    constructor(props){
        super(props);
        this.state = {
            pickupstatus:1,
            vehicleDetails:null,
            pickupLocation:null,
            slotService:null
           
        }
    }

    receivepickupdetails=(data,key,details,submitdata)=>{
        console.log("submit data",submitdata)
         // this.setState({pickupstatus:data});
         if(data!=4){
             this.setState({pickupstatus:data});
           }
         this.setState({[key]:details},function(){
            
             if(data==4){
                 const {vehicleDetails,pickupLocation,slotService}=this.state;
          const token=localStorage['accesstoken'];
         var obj={
            //  reg_number:vehicleDetails.register_number.value,
             svc_center_id:vehicleDetails.svc_list.value,
            //  mobile_no:vehicleDetails.mobile_number.value,
             vehicle_modal:vehicleDetails.vehicle_model.value,
             vehicle_varient:vehicleDetails.vehicle_variant.value,
             vehicle_number:vehicleDetails.vehicle_number.value,
             customer_salutation:vehicleDetails.salutation.value,
             customer_name:vehicleDetails.customer_name.value,
             customer_mobile:vehicleDetails.customer_mobile_number.value,
             customer_email:vehicleDetails.customer_emailid.value,
             pickup_address:pickupLocation.address.value,
             pickup_latitude:pickupLocation.currentLatLng.value.lat,
             pickup_longitude:pickupLocation.currentLatLng.value.lng,
             pickup_address_type_id:pickupLocation.address_type.value,
             slot_day:moment(slotService.slotdate.value).format(dateFormat),
             slot_time:slotService.slottimings.value,
             notes:slotService.notes.value,
             }
             console.log("booking chaufffur",obj);
             if(submitdata=='submit'){
             apiservices.addbookingchauffeur(obj,token)
       .then((response)=>{
         console.log("login response",response);
         if(!response.error){  
           // this.props.successdealer(response);     
           this.setState({addbookingSuccess:response})
         }else{
           this.setState({addbookingFailure:response})
         }
          
       })
     }
         }
         });
     
        
         console.log("details",this.state.svccentreid);
     }
    goNext=(data)=>{
        this.setState({pickupstatus:data});
    }
    render(){
        return(
            <div className="pickup">
                {this.state.pickupstatus==1 &&
                <ChauffeurVehicleDetails  sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} vehicleDetails={this.state.vehicleDetails}/>
                    }
                {this.state.pickupstatus==2 &&
                 <ChauffeurLocation pickupLocation={this.state.pickupLocation} sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} sendgoback={(data)=>this.goNext(data)}/>
                }
                {this.state.pickupstatus==3 &&
                 <SlotServiceDetails {...this.props} sendpickupdetails={(data,key,details,submitdata)=>this.receivepickupdetails(data,key,details,submitdata)} sendgoback={(data)=>this.goNext(data)} slotService={this.state.slotService} addbookingSuccess={this.state.addbookingSuccess} addbookingFailure={this.state.addbookingFailure}/> 
                }


        
            </div>
        )





        
    }
 
}


export default ChauffeurMain;
