import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
var bookingPaymentHeader = [{'id' : 1,  'drivername_id' : 'Driver Name & ID', 'booking_id': 'Booking ID', 'system_added_conveyance' : 'System Added Conveyance', 'other_added_conveyance' : 'Other Added Conveyance',  'deductions' : 'Deductions',  'early_morning_incentive' : 'Early Morning Incentive', 'last_night_incentive' : 'Last Night Incentive', 'login_incentive' : 'Login Incentive', 'other_incentive' : 'Other Incentive'}];


class BookingPayment extends Component{

    constructor(props){

        super(props);

        this.state = {
            bookingPaymentHeader : bookingPaymentHeader,

            bookingPaymentList : [],
             
            currentPage : 1,
            pageSize:10,
            fromDate:null,
            toDate:null,
            openDialog:false,
            popupDisplay:false,
            updateobj:null,
            fields:{
                amount:{validations:[{name:'allowNumaricOnly'},{name:'required'}],error:null,value:''},
            }


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadBookingPayment =()=>{
        // const token = localStorage['accesstoken'];
        // var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,id:this.props.match.params.id,search_from:this.state.fromDate,search_to:this.state.toDate};
        // console.log("obj", obj);
        //   this.props.sendid(obj.id);
        //   apiservices.llistdriverbookingpaymentsbyid(obj,token)
        //         .then((response)=>{
        //             // this.setState({loading:false});
        //             if(!response.error){
        //                 var limit=response.count;
        //                 var total_items=this.state.pageSize;
        //                 var calc=Math.ceil(limit/total_items)*total_items;
        //                 this.setState({bookingPaymentList:response.response, numbercount:calc})
        //                 console.log("booking payment", response);
        //               }
        //             else{
        //               this.setState({error_msg:response.message})
        //             }
        //         })
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,id:this.props.match.params.id,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("actve", obj);     
        apiservices.llistdriverbookingpaymentsbyid(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;

                      console.log("calc",calc);
                      this.setState({bookingPaymentList:response.response, numbercount:calc})
                      console.log("acive reponse", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })
      }
      componentWillMount(){
        this.props.sendid(this.props.match.params.id);
          this.loadBookingPayment();
      }
      
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadBookingPayment();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadBookingPayment(); 
        });
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadBookingPayment();
        });
      }
      datepickervalidations =(data)=>{
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadBookingPayment();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadBookingPayment();
        })
      }
      openDialogPayment=(data,value)=>{
          console.log("value",value);
          var fields=this.state.fields;
            if(value=='conveyance'){
          fields['amount'].value=data.added_conveyance;
            }
            if(value=='deductions'){
                fields['amount'].value=data.deductions;   
            }
          this.setState({openDialog:true,popupDisplay:value,fields,updateobj:data,success_msg:'',error_msg:''})
      }
      closeDialog =()=>{
          var fields=this.state.fields;
          fields['amount'].value='';
          fields['amount'].error='';
          this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
      }
      checkvalidations=(value,key)=>{

        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
      
          //successblock
        }
        this.setState({fields});
      }
      popupSubmit =async(data)=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
    console.log("data",data)
    if(data=='conveyance'){
        this.updateConveyance();
    }
    if(data=='deductions'){
        this.updateDeductions();
    }
          
        // alert('hii');
        }
        this.setState({fields});
    }
    updateConveyance =()=>{
        console.log("update obj",this.state.updateobj);
        
        const token = localStorage['accesstoken'];
        var updateobj=this.state.updateobj
        var obj={driver_id:updateobj.driver_id,booking_id:updateobj.booking_id,assign_id:updateobj.assign_id,added_conveyance:this.state.fields.amount.value};
        console.log("obj", obj);
          this.props.sendid(obj.id);
          apiservices.updateAddedConvetanceAmount(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        setTimeout(() => {
                            this.setState({openDialog:false});
                            this.loadBookingPayment();
                        },3000)
                        this.setState({success_msg:response.message})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
    }
    updateDeductions =()=>{
        console.log("update obj",this.state.updateobj);
        
        const token = localStorage['accesstoken'];
        var updateobj=this.state.updateobj
        var obj={driver_id:updateobj.driver_id,booking_id:updateobj.booking_id,assign_id:updateobj.assign_id,deductions:this.state.fields.amount.value};
        console.log("obj", obj);
          this.props.sendid(obj.id);
          apiservices.updateDeductionsAmount(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        setTimeout(() => {
                            this.setState({openDialog:false});
                            this.loadBookingPayment();
                        },3000)
                        this.setState({success_msg:response.message})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
    }
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
              <div className="search-header booking-mishaps" style={{marginTop:0}}>
              <div className="row">
                             <div className="col-md-3">
                                  {/* <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div> */}
                             </div>
                             <div className="col-md-9">
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from" style={{left:32}}>From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            allowClear={false}
                                            onChange={(value) => this.datepickervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.bookingPaymentHeader && this.state.bookingPaymentHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.drivername_id}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.system_added_conveyance}</th>
                                                <th>{item.other_added_conveyance}</th>
                                                <th>{item.deductions}</th>
                                                 <th>{item.early_morning_incentive}</th>
                                                 <th>{item.last_night_incentive}</th>
                                                 {/* <th>{item.login_incentive}</th>
                                                 <th>{item.other_incentive}</th> */}
                                                   
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                                                   {this.state.bookingPaymentList&&this.state.bookingPaymentList.length>0&&this.state.bookingPaymentList.map((item,index) => {

                                    return(
                                        <tr>
                                            <td>{item.driver_first_name} {item.driver_last_name}
                                            <br/>
                                            <span className="driver_id">ID{item.driver_id}</span>
                                            </td>
                                            <td>BID{item.booking_id}</td>
                                            <td>{item.conveyance}</td>
                                            <td>{item.added_conveyance}<img src={Image.edit_icon} style={{width:35,marginLeft:5}} onClick={()=>this.openDialogPayment(item,'conveyance')}/></td>
                                            <td>Rs.{item.deductions} <img style={{width:35,marginLeft:5}} src={Image.edit_icon} onClick={()=>this.openDialogPayment(item,'deductions')}/></td>
                                            <td>Rs.{item.early_morning_incentive}</td>
                                            <td>Rs.{item.late_night_incentive}</td>
                                            {/* <td>{item.login_incetive}</td>
                                            <td>{item.other_incentive}</td> */}
                                            
                                        </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {}
                            {this.state.bookingPaymentList&&this.state.bookingPaymentList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
    <Dialog  open={this.state.openDialog}  className={`booking_pickup`}>
                    <div className="modalclosecls" ><img src={Image.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    {this.state.popupDisplay=='conveyance'&&
                    <div className="modalcntntcls">
                   <div className="delete_text">
                       Added Conveyance
                   </div>
                   <div>
                      
                       
                   <div className="form-group">
                       <div class="form-inline position-relative">
                          <label className="booking_payment_label">Other Added Conveyance</label>
                          
                          <input type="text" className="form-control input_paused" name="amount" value={this.state.fields.amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                           
                            <span className="error_msg_adddealer">{this.state.fields['amount'].error&&this.state.fields['amount'].error}</span> 
                         
                        </div>
                       </div>
                     {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                   }
                 {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit" onClick={()=>this.popupSubmit('conveyance')}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    }  
                    {this.state.popupDisplay=='deductions'&&
                    <div className="modalcntntcls">
                   <div className="delete_text">
                       Deductions
                   </div>
                   <div>
                      
                       
                   <div className="form-group">
                       <div class="form-inline position-relative">
                          <label className="booking_payment_label">Add Deductions</label>
                          
                          <input type="text" className="form-control input_paused" name="amount" value={this.state.fields.amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                           
                            <span className="error_msg_adddealer">{this.state.fields['amount'].error&&this.state.fields['amount'].error}</span> 
                         
                        </div>
                       </div>
                     {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                   }
                 {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit" onClick={()=>this.popupSubmit('deductions')}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    }               
                    
                   
                </Dialog> 
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default BookingPayment;