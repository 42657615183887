import React, { Component } from 'react';
import { Table, Tag, Space, Select, Pagination,Tooltip } from 'antd';
// import Image from '../../helpers/images';  
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
const { Option } = Select;
export default class componentName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SvcItemArray: [],
            currentPage: 1,
            pageSize: 10,
            // SvcDetails: false,
            svcList: true,
            pageSizeOptions: [],
            dealerDetailsID: 0,
            nameSearch: "",
            citysearch: ""
        }
        this.getDealerList('', '');
    }
    getDealerList = () => {
        const token = localStorage['accesstoken'];
        var dealerobj = {}
        dealerobj.pageNo = this.state.currentPage;
        dealerobj.pageLimit = this.state.pageSize;
        dealerobj.dealer_name = this.state.nameSearch;
        dealerobj.city = this.state.citysearch;
        apiservices.svcDealerList(dealerobj, token)
            .then((response) => {
                console.log("getDealerList response", response);
                if (response != undefined && !response.error) {
                    // this.props.successdealer(response);    
                    var limit=response.count;
                    var total_items=this.state.pageSize;
                    var calc=Math.ceil(limit/total_items)*total_items;
                    // console.log("calc",calc);

                    this.setState({SvcItemArray:response.response,numbercount:calc}) 
                    // this.setState({ SvcItemArray: response.response });
                    // var temparray = [];
                    // var paginationoption = 1;
                    // var i = 1;
                    // var pagoption = response.response.length / 50;
                    // if (pagoption < 1) {
                    //     temparray.push(paginationoption);
                    // }
                    // for (i = 1; i < pagoption; i++) {
                    //     temparray.push(paginationoption);
                    //     paginationoption = paginationoption + 1;
                    // }
                    // this.setState({ pageSizeOptions: temparray, nameSearch: "", citysearch: "" });
                }
                // else{
                //   this.setState({addDealerResponse:response,})
                // }

            })
    }
    SvcDetails = (data) => {
console.log("data",data);
        // this.setState({ SvcDetails: true, dealerDetailsID: item.id });
        // this.setState({ svcList: false });
        this.props.history.push('/svc/dealer_list/svcdealerdetails/'+data)
    }

    svcDetailsHide = () => {
        this.setState({ SvcDetails: false });

        this.setState({ svcList: true });
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls" />Prev</a>;
        }
        if (type === 'next') {
            return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw" /></a>;
        }
        return originalElement;
    }
    onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page },function(){
          this.getDealerList();
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size ,currentPage:1},function(){
          this.getDealerList();
        })
      }
    changeData = (value,status) => {
        if(status == 1)
        {
            this.setState({ nameSearch: value });
        }
        if(status == 2)
        {
            this.setState({ citysearch: value });
        }
        
    }
    cityFilter =(e) =>{
        var value=e.target.value;
        console.log("city search",value);
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
    
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.getDealerList();
        });
  }
  render() {
    return (
      <div> 
           <div className="dealerMaster">
                {this.state.svcList &&
                    <div className="container">
                        <div className="search-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="form-group search-list dealer_search" >
                                        <input type="text" placeholder="Search Name" className="form-control" onChange={(e)=>this.cityFilter(e)} name="nameSearch"  />
                                        <img src={Images.green_search} className="img-fluid"  />
                                    </div>
                                </div>
                                <div className="col-md-6 dealer_city_search">
                                    <div className="service_listing">
                                        <div className="form-group">
                                            <div className="form-inline s-listing">
                                                <label>City / Zone:</label>
                                                <div class="form-group search-list">
                                                    <input type="text" placeholder="Search" className="form-control" onChange={(e)=>this.cityFilter(e)}  name="citysearch" />
                                                    <img src={Images.green_search} className="img-fluid"  />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-list">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                        <tr>
                                            <th>Dealer Master ID</th>
                                            <th className="text-left">Dealer Master Name</th>
                                            <th className="text-left">Vehicle Brand</th>
                                            <th>Vehicle Type</th>
                                            <th>No.of Showrooms / Service Center</th>
                                            <th>Manage Dealer Master</th>
                                            <th>City / Zone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.SvcItemArray &&this.state.SvcItemArray.length>0 && this.state.SvcItemArray.map((item) => {
                                            return (
                                               
                                               <tr>
                                                    <td onClick={() => this.SvcDetails(item.id)}>MID{item.id}</td>
                                                    <td className="text-left" onClick={() => this.SvcDetails(item.id)}> {item.dealer_name}</td>
                                                    <td onClick={() => this.SvcDetails(item.id)} className="text-left">{item.vehicle_brand}</td>
                                                    <td onClick={() => this.SvcDetails(item.id)}>{item.vehicle_type}</td>
                                                    <td onClick={() => this.SvcDetails(item.id)}>{item.no_of_service_center}</td>
                                                    <td onClick={() => this.SvcDetails(item.id)}><Tooltip placement="top" title="Edit Dealer" color="white" key="#0B485A" ><img src={Images.EditImg} /></Tooltip></td>
                                                    <td onClick={() => this.SvcDetails(item.id)}>{item.city}</td>
                                                </tr>
                                               
                                                
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {this.state.SvcItemArray&&this.state.SvcItemArray.length==0&&
            <div className="no_records_found">No Records Found</div>
            }

                            </div>

                        </div>
                        {/* {this.state.SvcItemArray.length > this.state.pageSize &&
                            <Pagination total={this.state.SvcItemArray.length} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
                                pageSize={this.state.pageSize} pageSizeOptions={this.state.pageSizeOptions} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
                            />
                        } */}
                        {this.state.SvcItemArray.length!=0&&
      <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
        pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
      />
       }
                    </div>}
                {/* {this.state.SvcDetails &&
                    <div>


                        <div className="back-btns">
                            <span className="back-button" onClick={this.svcDetailsHide}>
                                <img src={Images.FadedArrowImg} className="img-flud" />

                                Back to the Lists</span>
                        </div>

                        <SvcDealerTab dealerDetailsID={this.state.dealerDetailsID} updatedealerdetails={() => this.getDealerList('', '')} />


                    </div>


                } */}
            </div>
           </div>
    );
  }
}
