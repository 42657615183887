import React, { Component } from 'react';
import images from "../../helpers/images";
import ValidationLibrary from '../../helpers/validationfunction';
export default class PaymentDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            fields:{
                pan_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
                tan_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
                gst_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
                setup_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                prepaid_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                pickup_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                drop_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                pickup_drop_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                pickupdrop_distance_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                pickupdrop_double_distance:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                pickupdrop_noshow_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                convenience_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                chauffeur_doubledistance_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                chauffeur_doubledistance_kms:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                singleline_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                singleline_doubledistance_fee:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                singleline_distance:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                tripleline_amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                tripleline_distance:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                slab1:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
                slab2:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
                slab3:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
                slab4:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
                amount_transaction_charge:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                cancellation_charge:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''}
              },
        }
    }
    checkvalidations=(value,key)=>{
        var fields =this.state.fields;
        fields[key].value=value;
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      Service_next2=()=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          this.props.sendnextdetails(3,'paymentDetails',fields)
        //   this.setState({svc1 : false});
        //   this.setState({svc2  : true});
        }
        this.setState({fields});
      }
      goBack=()=>{
          this.props.sendgoBack(1)
      }
  render() {
    return (
      <>
      <div className="dealer-master">  <div className="dealer-body">
                     
      <div className="form-group">
        <div class="form-inline">
           <label>PAN Number</label>
           <input type="text" className="form-control" name="pan_number" onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} value={this.state.fields.pan_number.value} maxLength="10"/> 
           <span className="error_msg_adddealer">{this.state.fields['pan_number'].error&&this.state.fields['pan_number'].error}</span>
         </div>

         <div class="form-inline">
           <label>TAN Number</label>
           <input type="text" className="form-control" name="tan_number" value={this.state.fields.tan_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} maxLength="10"/>
           <span className="error_msg_adddealer">{this.state.fields['tan_number'].error&&this.state.fields['tan_number'].error}</span> 
         </div>                     
      </div>

      <div className="form-group">
        <div class="form-inline">
           <label>GST Number</label>
           <input type="text" className="form-control" name="gst_number" value={this.state.fields.gst_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} maxLength="15"/> 
           <span className="error_msg_adddealer">{this.state.fields['gst_number'].error&&this.state.fields['gst_number'].error}</span>
         </div>

         <div class="form-inline">
           <label>Setup Fee</label>
           <input type="text" className="form-control" name="setup_fee" value={this.state.fields.setup_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
           <span className="error_msg_adddealer">{this.state.fields['setup_fee'].error&&this.state.fields['setup_fee'].error}</span> 
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Prepaid Amount</label>
           <input type="text" className="form-control" name="prepaid_amount" value={this.state.fields.prepaid_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
           <span className="error_msg_adddealer">{this.state.fields['prepaid_amount'].error&&this.state.fields['prepaid_amount'].error}</span> 
         </div>

         <div class="form-inline">
           <label>Pickup Amount</label>
           <input type="text" className="form-control" name="pickup_amount" value={this.state.fields.pickup_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
           <span className="error_msg_adddealer">{this.state.fields['pickup_amount'].error&&this.state.fields['pickup_amount'].error}</span>
         </div>                     
      </div>

      <div className="form-group">
        <div class="form-inline">
           <label>Drop-off Amount</label>
           <input type="text" className="form-control" name="drop_amount" value={this.state.fields.drop_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['drop_amount'].error&&this.state.fields['drop_amount'].error}</span>
         </div>

         <div class="form-inline">
           <label>Pickup and Drop-off Amount</label>
           <input type="text" className="form-control" name="pickup_drop_amount" value={this.state.fields.pickup_drop_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['pickup_drop_amount'].error&&this.state.fields['pickup_drop_amount'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Pickup and Drop-off Double distance fees</label>
           <input type="text" className="form-control" name="pickupdrop_distance_fee" value={this.state.fields.pickupdrop_distance_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['pickupdrop_distance_fee'].error&&this.state.fields['pickupdrop_distance_fee'].error}</span>
         </div>

         <div class="form-inline">
           <label>Pickup and Drop-off Double distance (26-50 kms)</label>
           <input type="text" className="form-control" name="pickupdrop_double_distance" value={this.state.fields.pickupdrop_double_distance.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
           <span className="error_msg_adddealer">{this.state.fields['pickupdrop_double_distance'].error&&this.state.fields['pickupdrop_double_distance'].error}</span>
         </div>                     
      </div>

      <div className="form-group">
        <div class="form-inline">
           <label>Pickup and Drop-off No Show fees</label>
           <input type="text" className="form-control" name="pickupdrop_noshow_fee" value={this.state.fields.pickupdrop_noshow_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['pickupdrop_noshow_fee'].error&&this.state.fields['pickupdrop_noshow_fee'].error}</span>
         </div>

         <div class="form-inline">
           <label>Chauffeur Convenience fee</label>
           <input type="text" className="form-control" name="convenience_fee" value={this.state.fields.convenience_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['convenience_fee'].error&&this.state.fields['convenience_fee'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Chauffeur Double Distance fee</label>
           <input type="text" className="form-control" name="chauffeur_doubledistance_fee" value={this.state.fields.chauffeur_doubledistance_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['chauffeur_doubledistance_fee'].error&&this.state.fields['chauffeur_doubledistance_fee'].error}</span>
         </div>

         <div class="form-inline">
           <label>Chauffeur Double Distance in kms</label>
           <input type="text" className="form-control" name="chauffeur_doubledistance_kms" value={this.state.fields.chauffeur_doubledistance_kms.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
           <span className="error_msg_adddealer">{this.state.fields['chauffeur_doubledistance_kms'].error&&this.state.fields['chauffeur_doubledistance_kms'].error}</span> 
         </div>                     
      </div>

      <div className="form-group">
        <div class="form-inline">
           <label>Single Line Amount</label>
           <input type="text" className="form-control" name="singleline_amount" value={this.state.fields.singleline_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['singleline_amount'].error&&this.state.fields['singleline_amount'].error}</span>
         </div>

         <div class="form-inline">
           <label>Single Line Double Distance fees</label>
           <input type="text" className="form-control" name="singleline_doubledistance_fee" value={this.state.fields.singleline_doubledistance_fee.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['singleline_doubledistance_fee'].error&&this.state.fields['singleline_doubledistance_fee'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Single Line Distance (>25kms)</label>
           <input type="text" className="form-control" name="singleline_distance" value={this.state.fields.singleline_distance.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['singleline_distance'].error&&this.state.fields['singleline_distance'].error}</span>
         </div>

         <div class="form-inline">
           <label>Triple Line Amount</label>
           <input type="text" className="form-control" name="tripleline_amount" value={this.state.fields.tripleline_amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['tripleline_amount'].error&&this.state.fields['tripleline_amount'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Triple Line Distance (>50kms)</label>
           <input type="text" className="form-control" name="tripleline_distance" value={this.state.fields.tripleline_distance.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
           <span className="error_msg_adddealer">{this.state.fields['tripleline_distance'].error&&this.state.fields['tripleline_distance'].error}</span>
         </div>

         <div class="form-inline">
           <label>Slab 1</label>
           <input type="text" className="form-control" name="slab1" value={this.state.fields.slab1.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['slab1'].error&&this.state.fields['slab1'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Slab 2</label>
           <input type="text" className="form-control" name="slab2" value={this.state.fields.slab2.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
           <span className="error_msg_adddealer">{this.state.fields['slab2'].error&&this.state.fields['slab2'].error}</span> 
         </div>

         <div class="form-inline">
           <label>Slab 3</label>
           <input type="text" className="form-control"  name="slab3" value={this.state.fields.slab3.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['slab3'].error&&this.state.fields['slab3'].error}</span>
         </div>                     
      </div>


      <div className="form-group">
        <div class="form-inline">
           <label>Slab 4</label>
           <input type="text" className="form-control" name="slab4" value={this.state.fields.slab4.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
           <span className="error_msg_adddealer">{this.state.fields['slab4'].error&&this.state.fields['slab4'].error}</span>
         </div>

         <div class="form-inline">
           <label>Amount Transaction Charges</label>
           <input type="text" className="form-control" name="amount_transaction_charge" value={this.state.fields.amount_transaction_charge.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
           <span className="error_msg_adddealer">{this.state.fields['amount_transaction_charge'].error&&this.state.fields['amount_transaction_charge'].error}</span>
         </div>                     
      </div>

      <div className="form-group">
        <div class="form-inline">
           <label>Cancellation Charges</label>
           <input type="text" className="form-control" name="cancellation_charge" value={this.state.fields.cancellation_charge.value}onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
           <span className="error_msg_adddealer">{this.state.fields['cancellation_charge'].error&&this.state.fields['cancellation_charge'].error}</span>
         </div>

                           
      </div>

 </div>
 
  </div>
  <button className="btn btn-white-blue prev-button" onClick={()=>this.goBack()}>    <img src={images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button className="btn btn-dark-blue next-button" onClick={()=>this.Service_next2()}>Next  <img src={images.arrow_white} className="img-fluid arrow"/></button>
  </>
    );
  }
  componentDidMount(){
    console.log("didmount",this.props);
    if(this.props.paymentDetails){
        this.setState({fields:this.props.paymentDetails});
    }
}
}
