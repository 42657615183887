import React, { Component } from 'react';
import { Table, Tag, Pagination, Select} from 'antd';
import Image from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import moment from 'moment';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import './styles.css';
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var NotificationHeader = [{'driver_name':'Driver Name','date' : 'Date', 'time' : 'Time', 'description' : 'Description'}];

export default class Notifications extends Component {
  constructor(props){
    super(props);
    this.state={
      NotificationHeader:NotificationHeader,
      NotificationList:[],
      currentPage : 1,
            pageSize:10,
            openDialog:false,
            fields:{
                driver_list:{validations:[{name:'required'}],error:null,value:''},
                reasons:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
              },
    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  AddNotification =() =>{
    var fields=this.state.fields;
      fields['reasons'].value='';
      fields['reasons'].error='';
      this.loadDriverList();
      this.setState({openDialog:true,fields,success_msg:'',error_msg:''});
      
  }
  closeDialog =()=>{
      var fields=this.state.fields;
      fields['driver_list'].value='';
      fields['driver_list'].error='';
      fields['reasons'].value='';
      fields['reasons'].error='';

      this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
  }
  checkvalidations=(value,key)=>{
   
    var fields =this.state.fields;
    fields[key].value=value;
    console.log("value",value);
    var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
    // console.log(checkstatus);
    
    fields[key].error=checkstatus.msg;
    var getallkeys=Object.keys(fields);
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      this.setState({error:true})
      //errorblock
    }else{
      this.setState({error:false})

      //successblock
    }
    this.setState({fields});
  }
  popupSubmit =(data)=>{
      var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.AddNotificationDetails();
        this.setState({error:false});
      }
      this.setState({fields});
  }
  loadNotification = () =>{
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,};
    console.log("actve", obj);     
    apiservices.listdrivernotification(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  console.log("calc",calc);
                  this.setState({NotificationList:response.response, numbercount:calc})
                  console.log("acive reponse", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
  }
  AddNotificationDetails =()=>{
    const token=localStorage['accesstoken'];
    var obj={driver_id:this.state.fields.driver_list.value,message:this.state.fields.reasons.value,};
    console.log("actve", obj);     
    apiservices.adddrivernotification(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                  this.loadNotification();
                  this.setState({openDialog:false})
                }, 3000);
                 this.setState({success_msg:response.message})
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
  }
  componentWillMount(){
    this.loadNotification();
    this.loadDriverList();
  }
  onchangePagination = (page, pageSize) => {
    console.log("page", page);
    this.setState({ currentPage: page }, function(){
        this.loadNotification();        
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage : 1 }, function(){
        this.loadNotification(); 
    });
  }
  loadDriverList =()=>{
    const token=localStorage['accesstoken'];
      var obj={};
            apiservices.listactivedriverdrop(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({driver_list:response.response})
                var fields=this.state.fields;
                fields['driver_list'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
  }
  render() {
    const offset = moment().utcOffset();
      const {driver_list} =this.state;
    return (
      <div className="notification_main"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active" style={{paddingBottom:10}}>
                         <div className="row">
                             <div className="col-md-3">
                                  <div className="notication_text">Show Notification</div>
                             </div>
                             <div className="col-md-9">
                             
                             <div className="addNewDriver" >
                                            <div className="driverButton" onClick={()=>this.AddNotification()}>
                                                <img src={Image.add_img}/>
                                                Add New
                                            </div>
                                        </div>
                             
                             
                             </div>
                         </div>
                    </div>

                    <div class="driverinfodiv"> 
 
      <div className="driverActiveTable">
                <table class="table table-hover">
                    <thead>
                        {this.state.NotificationHeader && this.state.NotificationHeader.map((item) => {

                        return(
                        <tr>
                            <th>{item.driver_name}</th>
                            <th>{item.date}</th>
                            <th >{item.time}</th>
                            <th>{item.description}</th>
                            
                        </tr>
                        )
                        })}
                    </thead>
                    <tbody>
                    {this.state.NotificationList &&this.state.NotificationList.map((item) => {
              
                
    return (<tr>
                                 <td>{item.driver_first_name} {item.driver_last_name}</td>
                                 <td>{moment(item.created_at).format('DD/MM/YY')}</td>
                            <td >{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                            <td>{item.message}</td>
                            
                            </tr>);
                        })}




                    </tbody>

                </table>

                {this.state.NotificationList&&this.state.NotificationList.length==0&&
                <div className="no_records_found">No Records Found</div>
                }



            </div>

           
    </div>
    <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
        </div>
        <Dialog  open={this.state.openDialog}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={Image.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                                  
                    
                    <div className="modalcntntcls">
                   <div className="delete_text new_notify">
                       New Notification
                   </div>
                   <div>
                       <div className="form-group">
                       <div class="form-inline position-relative reason_text">
                          <label className="notify_label">Select the Driver</label>
                          <Select value={[parseInt(this.state.fields.driver_list.value)]}  onChange={(value) => this.checkvalidations(value,'driver_list')} name="driver_list" >
                          {driver_list&&driver_list.length>0&&driver_list.map((item)=>{
                              return(
                              <Option value={item.id}>{item.first_name} {item.last_name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Image.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.fields['driver_list'].error&&this.state.fields['driver_list'].error}</span>
                         
                        </div>
                       </div>
                       
                       <div className="form-group reason_box">
                         <label className="notify_label">
                        Description
                         </label>

                         <textarea row="5" col="4"   maxLength="300" name="reasons" className="form-control reason_box" value={this.state.fields.reasons.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['reasons'].error&&this.state.fields['reasons'].error}</span> 
                         
                     </div>
                     {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                   }
                 {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit notify_btn" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    
                                 
               
                </Dialog>   
       </div>
    );
  }
}
