import React, { Component } from 'react';
import { Table, Tag, Space } from 'antd';
import images from '../../helpers/images';
import "antd/dist/antd.css";

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];
 class Pagination extends Component {
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  render(){
    return(
<div>
<Table columns={columns} dataSource={data} pagination={{
        total: data.length,
        // pageSize: data.length,
        defaultCurrent:1,
        defaultPageSize:2,
        itemRender:this.itemRender
    }} />
</div>
    )
  }
}
export default Pagination;