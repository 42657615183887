import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import './styles.css';
import AddBooking from '../../Pages/AddBooking';

import BookingListMain from './BookingListMain';
import SlotAllocation from '../../Pages/SlotAllocation';
import Accidents from '../../Pages/Accidents';
// import SvcList from '../../Pages/SvcList';
// import DealerMasterList from "../../Pages/DealerMasterList";
class BookingMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div className="container">
        <div class="booking-tabs">
          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row booking_mainview" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/add_booking/')&&"activelink"}`} exact={true} to={`${this.props.match.path}/add_booking/`} activeClassName="active_cls">Add Booking</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/booking_list')&&"activelink"}`} to={`${this.props.match.path}/booking_list/bokinglist_main/active`} activeClassName="active_cls">Booking List</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/slot_allocation/`} activeClassName="active_cls">Slot Allocation</NavLink>
                </Nav.Item>
                {/* <Nav.Item>
                  <NavLink to={`${this.props.match.path}/accidents/`} activeClassName="active_cls">Accidents</NavLink>
                </Nav.Item> */}
                
              </Nav>
             
            </Col>
            <Col sm={12}>
             
              <Switch>
                <Route  path={`${this.props.match.path}/add_booking`} render={props => (<AddBooking {...props} />)} />
                 <Route  path={`${this.props.match.path}/booking_list`} render={props => (<BookingListMain parentProps={this.props.parentProps} {...props} />)} />
                <Route  path={`${this.props.match.path}/slot_allocation`} render={props => (<SlotAllocation  {...props} />)} />
                {/* <Route  path={`${this.props.match.path}/accidents`} render={props => (<Accidents  {...props} />)} /> */}
                {/* <Route  path={`${this.props.match.path}/booking_details`} render={props => (<BookingDetails {...props} />)} /> */}
                
              </Switch>
            </Col>
          </Row>
          {/* </Tab.Container> */}
        </div>
      </div>
    )

  }
  componentDidMount() {
    this.props.receivePageTitle && this.props.receivePageTitle('Booking');
  }

}


export default BookingMain;