import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import { Table, Tag, Pagination, Select} from 'antd';
   
import { DatePicker } from 'antd';



const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;
 
// var bookingHeader = [{label : 'SVC Name', key : 'svc_name'}, {label: 'Customer Name', key : 'customer_name'},  {label : 'Vehicle Number', key : 'vehicle_number'},  {label : 'Booking ID', key : 'booking_id'}, {label : 'Booking Type', key : 'booking_type'}, {label : 'Slot', key : 'slot'},  {label : 'Ambassador', key : 'ambassador'},  {label : 'Booking Status', key : 'booking_status'}];

var mishapsHeader = [{'id' : 1,  'driver_id' : 'Driver ID', 'date': 'Date', 'driver_name' : 'Driver Name', 'customer_name' : 'Customer Name',  'svc_name' : 'SVC Name', 'vehicle_number' : 'Vehicle Number', 'status' : 'Status'}];

var mishapsList = [{'key' : 1, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 2, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 3, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 4, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 5, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 6, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 7, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 8, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 9, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 10, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 11, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 12, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 13, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 14, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 15, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 16, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 17, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 18, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 19, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 20, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 21, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 22, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 23, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'},
{'key' : 24, 'driver_id' : 'ID23490', 'date' : '08/05/20', 'driver_name' : 'Charles Freeman', 'customer_name' : 'Rebecca Wood', 'svc_name' : 'Toyota - Adyar', 'vehicle_number' : 'TN 03 XC8390', 'status' : 'Lorem Ipsum dolor'}
]
class BookingMishaps extends Component{

    constructor(props){

        super(props);

        this.state = {
            mishapsHeader : mishapsHeader,

            mishapsList : mishapsList,
             
            currentPage : 1,
            pageSize:10


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination=(page,pageSize)=>{
        console.log("page",page);
        this.setState({currentPage:page});
        
        console.log("page size",pageSize);
      }
      showChanger=(current,size)=>{
        this.setState({pageSize:size})
      }

render(){

    const columns= mishapsHeader.map(row => ({
        title:row.label,
        key:row.key,
        dataIndex:row.key
        // key: row.ClientId,     // here
               }))
      // const columns=[{}]

    return(
        <div>
              
             <div className="search-header booking-mishaps">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                             <div className="payment-dropdown"> <span className="payment">Estimate</span> <Select defaultValue="Chad Ellis" onChange={handleChange}>
                            <Option value="Chad Ellis">Lorem Isum</Option>
                            <Option value="lucy">Lorem Isum</Option>
                            
                            <Option value="Yiminghe">Lorem Isum</Option>
                            </Select>
                            </div>
                             <div className="payment-dropdown"> <span className="payment">Status</span> <Select defaultValue="Chad Ellis" onChange={handleChange}>
                            <Option value="Chad Ellis">Lorem Isum</Option>
                            <Option value="lucy">Lorem Isum</Option>
                            
                            <Option value="Yiminghe">Lorem Isum</Option>
                            </Select>
                            </div>
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.mishapsHeader && this.state.mishapsHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.driver_id}</th>
                                                <th>{item.date}</th>
                                                <th>{item.driver_name}</th>
                                                <th>{item.customer_name}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.vehicle_number}</th>
                                                <th>{item.status}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                    {this.state.mishapsList && this.state.mishapsList.slice((this.state.currentPage-1) * this.state.pageSize, (this.state.currentPage-1) * this.state.pageSize + this.state.pageSize).map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.driver_id}</td>
                                                <td>{item.date}</td>
                                                <td>{item.driver_name}</td>
                                                <td>{item.customer_name}</td>
                                                <td>{item.svc_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>{item.status}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            

 
                        </div>
                     </div>


                     <Pagination  total= {this.state.mishapsList.length} current={this.state.currentPage} onChange={(page,pageSize)=>this.onchangePagination(page,pageSize)}
        pageSize= {this.state.pageSize} pageSizeOptions={['5','10','15']} onShowSizeChange={(current,size)=>this.showChanger(current,size)} showSizeChanger  itemRender={this.itemRender}
         />
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default BookingMishaps;