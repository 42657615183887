import React, { Component } from 'react';
import images from "../../helpers/images";
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import './styles.css';
const { Option } = Select;
function handleChange(value) {
    console.log(`selected ${value}`);
  }
export default class AddTickets extends Component {
    constructor(props){
        super(props);
        this.state={
            fields:{
                BookingList:{validations:[{name:'required'}],error:null,value:''},
                driver_name:{validations:[{name:'required'}],error:null,value:''},
                svc_name:{validations:[{name:'required'}],error:null,value:''},
                svc_id:{validations:[{name:'required'}],error:null,value:''},
                tickets:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                priority:{validations:[{name:'required'}],error:null,value:''},
            }
        }
    }
    checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        if(key=='BookingList'){
          var parseddata=JSON.parse(value);
          fields['driver_name'].value=parseddata.driver_first_name +" "+ parseddata.driver_Last_name;
          fields['svc_name'].value=parseddata.centre_name
          fields['svc_id'].value=parseddata.svc_center_id
          console.log("Booking list",value)
        }
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      submitTickets=()=>{
        // this.props.sendpickupdetails(2);
        var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
      this.AddTickets();
      }
      this.setState({fields});
      }
      loadBookingDropdown=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.listactivebookingdrop(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    this.setState({BookingListDrop:response.response})
                    var fields=this.state.fields;
                    // if(!value){
                      if(response.response.length>0){
                        fields['driver_name'].value=response.response[0].driver_first_name +" "+ response.response[0].driver_Last_name ;
                        fields['svc_name'].value=response.response[0].centre_name;
                        fields['svc_id'].value=response.response[0].svc_center_id
                      }
                     
                    fields['BookingList'].value=response.response.length>0?JSON.stringify(response.response[0]):'';            
                    this.setState({fields})
                    // }
                    console.log("login response",response);
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    loadPriorityDropdown=(data)=>{
      const token=localStorage['accesstoken'];
      var obj={};
      console.log("actve", obj);     
      apiservices.listticketprioritydrop(obj,token)
            .then((response)=>{
                // this.setState({loading:false});
                if(!response.error){
                  this.setState({priority:response.response})
                  var fields=this.state.fields;
                  // if(!value){
                  fields['priority'].value=response.response.length>0?response.response[0].id:'';            
                  this.setState({fields})
                  // }
                  console.log("login response",response);
          
                }else{
                  this.setState({error_msg:response.message})
                }
            })
  }
    componentWillMount(){
      this.loadBookingDropdown();
      this.loadPriorityDropdown();
    }
    AddTickets=()=>{
      var self=this;
        const token=localStorage['accesstoken'];
        var obj={id:JSON.parse(this.state.fields.BookingList.value).id,ticket:this.state.fields.tickets.value,priority_id:this.state.fields.priority.value,};
        console.log("add ticket", obj); 
        apiservices.addadmintickets(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                      self.props.senddialogrequest(false,'reload')
                      self.loadBookingDropdown();
                      self.loadPriorityDropdown();
                      var fields=self.state.fields;
                      fields['tickets'].value=''
                      self.setState({fields});
                    }, 3000);
                     this.setState({responseMsg:response.message})
                    }
                  else{
                    this.setState({responseMsg:response.message})
                  }
              })
    }
  render() {
    const {BookingListDrop,priority} =this.state;
    return (
      <div> 
          <div className="addbooking_main">
            <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Booking ID</label>
                          
                          <Select value={[(this.state.fields.BookingList.value)]}  onChange={(value) => this.checkvalidations(value,'BookingList')} name="BookingList" >
                          {BookingListDrop&&BookingListDrop.length>0&&BookingListDrop.map((item)=>{
                              return(
                              <Option value={JSON.stringify(item)}>{item.id}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid ticket_dropdown_img"/> 
                           
           <span className="error_msg_adddealer">{this.state.fields['BookingList'].error&&this.state.fields['BookingList'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Driver Name</label>
                          
                          <input type="text" className="form-control input_paused" name="driver_name" value={this.state.fields.driver_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                           
                            <span className="error_msg_adddealer">{this.state.fields['driver_name'].error&&this.state.fields['driver_name'].error}</span> 
                         
                        </div>
                       </div>

                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>SVC Name</label>
                          
                          <input type="text" className="form-control input_paused" name="svc_name" value={this.state.fields.svc_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/> 
                            <span className="error_msg_adddealer">{this.state.fields['svc_name'].error&&this.state.fields['svc_name'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>SVC ID</label>
                          
                          <input type="text" className="form-control input_paused" name="svc_id" value={this.state.fields.svc_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['svc_id'].error&&this.state.fields['svc_id'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Ticket</label>
                          
                          <input type="text" className="form-control input_paused" name="tickets" value={this.state.fields.tickets.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['tickets'].error&&this.state.fields['tickets'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Priority</label>
                          
                          <Select value={[parseInt(this.state.fields.priority.value)]}  onChange={(value) => this.checkvalidations(value,'priority')} name="priority" >
                          {priority&&priority.length>0&&priority.map((item)=>{
                              return(
                              <Option value={item.id}>{item.priority}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid ticket_dropdown_img"/> 
                            <span className="error_msg_adddealer">{this.state.fields['priority'].error&&this.state.fields['priority'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit" onClick={()=>this.submitTickets()}>Submit</button>
                       </div>
                       </div>
     </div>
    );
  }
}
