import React, {Component} from 'react';
import './styles.css';
import images from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';


const { RangePicker } = DatePicker;

var userReuestHeader = [{'id' : 1,  'date' : 'Date', 'time': 'Time', 'svc_id' : 'SVC ID', 'svc_name' : 'SVC Name','employee_name':'Employee Name',  'employee_id' : 'Employee ID', 'designation' : 'Designation','mobile_number':'Mobile Number','edit':'Edit'}];

var userReuestList=[{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'Edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},
{id:1,'date':'20/04/30','time':'09:00:00','svc_id':'12345','svc_name':'Hyundai-RK','employee_name':'Song Weilong','employee_id':'EMMPID1234','designation':'Accountant','mobile_number':'9578861249','edit':'edit'},

]
class UserList extends Component{

    constructor(props){

        super(props);

        this.state = {
            userReuestHeader : userReuestHeader,

            userReuestList : userReuestList,
             
            currentPage : 1,
            pageSize:10,
            openapproval:false,
            rejectapproval:false


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadDriverBookingDetails =()=>{
        // const token = localStorage['accesstoken'];
        // var obj={id:this.props.match.params.id,pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        // console.log("obj", obj);
        //   this.props.sendid(obj.id);
        //   apiservices.listdriverbooking(obj,token)
        //         .then((response)=>{
        //             // this.setState({loading:false});
        //             if(!response.error){
        //                 var limit=response.count;
        //                 var total_items=this.state.pageSize;
        //                 var calc=Math.ceil(limit/total_items)*total_items;
        //                 this.setState({userReuestList:response.response, numbercount:calc})
        //                 console.log("booking reponse", response);
        //               }
        //             else{
        //               this.setState({error_msg:response.message})
        //             }
        //         })
      }
      componentWillMount(){
          this.loadDriverBookingDetails();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadDriverBookingDetails();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadDriverBookingDetails(); 
        });
      }
      OpenApproval =()=>{
        this.setState({openapproval:true});
    
    }
    closeApproval =()=>{
        this.setState({openapproval:false});
    }
    OpenRejectApproval =()=>{
        this.setState({rejectapproval:true});
    }
    RejectApproval=()=>{
        this.setState({rejectapproval:false});  
    }
    
render(){

    return(
        <div>
              <div className="container">
             <div className="search-header booking-mishaps" >
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search" >
                                      <input type="text" placeholder="Search" className="form-control" /> 
                                      <img src={images.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.userReuestHeader && this.state.userReuestHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.date}</th>
                                                <th>{item.time}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.employee_name}</th>
                                                <th>{item.employee_id}</th>
                                                 <th>{item.designation}</th>
                                                 <th>{item.mobile_number}</th>
                                                 <th>{item.edit}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.userReuestList &&this.state.userReuestList.map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.date} </td>
                                                <td>{item.time}</td>
                                                <td>{item.svc_name}</td>
                                                <td>{item.svc_name}</td>
                                                <td>{item.employee_name}</td>
                                                <td>{item.employee_id}</td>
                                                <td>{item.designation}</td>
                                                <td>{item.mobile_number}</td>
                                                <td><img src={images.edit_icon} style={{width:37}} /></td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.userReuestList&&this.state.userReuestList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
   
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default UserList;