import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import './styles.css';
import Badge from 'react-bootstrap/Badge';
import images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
export default class DashboardCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardinfo: this.props.cardDetails,
      dashboardCard: null,
    };
  }
  goBooking = () => {
    this.props.history.push('/booking/booking_list/bokinglist_main/active');
  };
  goBookingUpcoming = () => {
    this.props.history.push('/booking/booking_list/bokinglist_main/upcoming');
  };
  gotoTickets = () => {
    this.props.history.push('/tickets');
  };
  gotoDriver = () => {
    this.props.history.push('/pilots/pilot_master/pilot_details/');
  };
  gotoZone = () => {
    this.props.history.push('/zone/zone_list');
  };
  loadDashboardCardList = () => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.listdashcarddetails(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        console.log('dashboard', response.response);
        this.setState({
          dashboardCard: response.response && response.response,
        });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  componentWillMount() {
    this.loadDashboardCardList();
  }
  render() {
    return (
      <div className='cardView'>
        <div className='container'>
          <div className='mozila_row'>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-4 introcenter'>
              {/* <Grid item lg={2} xs={6} md={4} sm={4} className="introcenter" > */}
              <div class='dashcard' onClick={() => this.gotoDriver()}>
                <div className='cardHeader drivercard'>
                  <div className='cardImage '>
                    <img alt='' src={images.driver_card} />
                  </div>
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.active_driver}
                    </b>
                  </h6>
                  <p>Drivers Active</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-4 introcenter'>
              {/* <Grid item lg={2} xs={6} md={4} sm={4} className="introcenter" > */}
              <div class='dashcard '>
                <div className='cardHeader walletcard'>
                  <img src={images.wallet_card} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.less_wallet}
                    </b>
                  </h6>
                  {this.state.dashboardCard &&
                  this.state.dashboardCard.less_wallet < 5000 ? (
                    <p>Low value wallets (Less than 5,000)</p>
                  ) : (
                    <p>Wallet Amount</p>
                  )}
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-4 introcenter'>
              {/* <Grid item lg={2} xs={6} md={4} sm={4} className="introcenter" > */}
              <div class='dashcard'>
                <div className='cardHeader svc_card'>
                  <img src={images.svc_card} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      <img src={images.rupee_icon} style={{ width: 12 }} />{' '}
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.overall_payout}
                    </b>
                  </h6>
                  <p>SVC Overall Payouts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-4 introcenter'>
              {/* <Grid item lg={2} xs={6} md={4} sm={4} className="introcenter" > */}
              <div class='dashcard'>
                <div className='cardHeader money_card'>
                  <img src={images.money_card} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      <img src={images.rupee_icon} style={{ width: 12 }} />{' '}
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.outstanging_amount}
                    </b>
                  </h6>
                  <p>Outstanding Payouts</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-4 introcenter'>
              {/* <Grid item lg={2} xs={6} md={4} sm={4} className="introcenter" > */}
              <div class='dashcard' onClick={() => this.goBooking()}>
                <div className='cardHeader wallet2_card'>
                  <img src={images.wallet2_card} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.active_booking}
                    </b>
                  </h6>
                  <p>Active Booking</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-6 introcenter'>
              <div class='dashcard' onClick={() => this.goBookingUpcoming()}>
                <div className='cardHeader online_card'>
                  <img src={images.onlinecard} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.upcomming_booking}
                    </b>
                  </h6>
                  <p> Upcoming Booking</p>
                </div>
              </div>
            </div>
            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-6 introcenter'>
              <div class='dashcard' onClick={() => this.gotoTickets()}>
                <div className='cardHeader online_card position-relative'>
                  {this.state.dashboardCard &&
                  this.state.dashboardCard.newTicketsCount == 0 ? (
                    ''
                  ) : (
                    <div
                      class='badge position-absolute top-100 left-100 translate-middle bg-warning'
                      style={{
                        right: '-3px',
                        top: '-5px',
                        borderRadius: '25px',
                        width: '30px',
                        height: '30px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.newTicketsCount}
                    </div>
                  )}
                  <img src={images.onlinecard} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.TotalTicketCount}
                    </b>
                  </h6>
                  <p>Total Tickets</p>
                </div>
              </div>
            </div>

            <div className='col-lg-2 col-md-4 col-xs-6 col-sm-6 introcenter'>
              <div class='dashcard' onClick={() => this.gotoZone()}>
                <div className='cardHeader drivercard position-relative'>
                  {/* {this.state.dashboardCard &&
                  this.state.dashboardCard.zone_count == 0 ? (
                    ''
                  ) : (
                    <div
                      class='badge position-absolute top-100 left-100 translate-middle bg-warning'
                      style={{
                        right: '-3px',
                        top: '-5px',
                        borderRadius: '25px',
                        width: '30px',
                        height: '30px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.zone_count}
                    </div>
                  )} */}
                  <img alt='' src={images.driver_card} />
                </div>
                <div class='carddetails'>
                  <h6>
                    <b>
                      {this.state.dashboardCard &&
                        this.state.dashboardCard.zone_count}
                    </b>
                  </h6>
                  <p>Active Zone</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
