import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import BookingPickup from './BookingPickUp';
import BookingSVCDetails from './BookingSVCDetails';
import BookingPilotDetails from './BookingPilotDetails';
import BookingPaymentDetails from './BookingPaymentDetails';
import BookingComplaints from './BookingComplaints';
import BookingNotes from './BookingNotes';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class BookingDetailsPickup extends Component 
{

    constructor(props){
        super(props);

        this.state = {
bookingDetails:null,
bookingid:null,
assignid:null
        }
    }
    receivebookingid=(data,data1)=>{
      console.log("receive assign id",data1)
      this.setState({bookingid:data,assignid:data1})
      this.props.sendbookingid&&this.props.sendbookingid(data,data1)
    }

    render(){

        return(
           <div>

{/* Form Tabs start */}
           
           <div class="svcDetails-tabs">
                <Row>
                    <Col sm={12}>
                    
                     <Nav variant="pills" className="flex-row svcdetails_nav bookingdetailsmain"  selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink  exact={true} to={`${this.props.match.path}/${this.state.bookingid}/${this.state.assignid}`} style={{color:'#fff'}}>Pickup</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/svc_details/${this.state.bookingid}/${this.state.assignid}`} >SVC Details</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/pilot_details/${this.state.bookingid}/${this.state.assignid}`} >Pilot Details</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/payment_details/${this.state.bookingid}/${this.state.assignid}`} >Payment Details</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/complaints/${this.state.bookingid}/${this.state.assignid}`} >Complaints</NavLink>
                </Nav.Item>
                {/* <Nav.Item>
                  <NavLink to={`${this.props.match.path}/notes/${this.state.bookingid}`} >Notes</NavLink>
                </Nav.Item> */}
                
                
              </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="svc_first pickup-booking">
                                       <Switch>
                <Route exact path={`${this.props.match.path}/:id/:assignid`} render={props => (<BookingPickup {...props} sendbookingid={(data,data1)=>this.receivebookingid(data,data1)}/>)} />
                <Route exact path={`${this.props.match.path}/svc_details/:id/:assignid`} render={props => (<BookingSVCDetails {...props} sendbookingid={(data,data1)=>this.receivebookingid(data,data1)}/>)} />
                <Route exact path={`${this.props.match.path}/pilot_details/:id/:assignid`} render={props => (<BookingPilotDetails {...props} sendbookingid={(data,data1)=>this.receivebookingid(data,data1)}/>)} />
                <Route exact path={`${this.props.match.path}/payment_details/:id/:assignid`} render={props => (<BookingPaymentDetails {...props} sendbookingid={(data,data1)=>this.receivebookingid(data,data1)} />)} />
                <Route exact path={`${this.props.match.path}/complaints/:id/:assignid`} render={props => (<BookingComplaints {...props} sendbookingid={(data,data1)=>this.receivebookingid(data,data1)} />)} />
                {/* <Route exact path={`${this.props.match.path}/notes/:id`} render={props => (<BookingNotes {...props} />)} /> */}

                </Switch>
                    </div>
                    </Col>
                </Row>
                </div>
                {/* Form Tabs start */}
           </div>
        )
    }
}

export default BookingDetailsPickup;