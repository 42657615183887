import React, { Component } from 'react';
import OtpInput from 'react-otp-input';
import { toast, ToastContainer } from 'react-toastify';
import apiservices from '../../helpers/apiservices';
import './styles.css';
export default class OtpVerification extends Component {
    constructor(props) {
        super(props);
        console.log("otpprops", props);
        this.state = {
            registerdata: props.registerdata,
            otp:'',
            error_msg:null,
            // otp0: "", otp1: "", otp2: "", otp3: "",
            timer: 45, Disabled: true,toastId:null
        }
        this.otpRef1 = React.createRef();
        this.otpRef2 = React.createRef();
        this.otpRef3 = React.createRef();
    }
    resetTimer = () => {
        this.interval = setInterval(
            () => this.setState((prevState) => ({ timer: prevState.timer - 1 })),
            1000
        );
    }
   
    componentDidMount() {
        this.resetTimer();
    }
    componentWillUpdate() {
        if (this.state.timer === 1) {
            this.setState({ timer: 0 });
            clearInterval(this.interval);
            this.setState({ Disabled: false })
        }
    }
    componentWillReceiveProps(props) {
        console.log("otp props",props);
        if (props.registerdata) {
            this.setState({ registerdata: props.registerdata });
        }
    }
    
    otpChange(state, otp) {
        this.setState({ [state]: otp })
      }
      loginSubmit=()=>{
        var number=/^[0-9]*$/;
        if (this.state.otp == "" || this.state.otp == undefined) {
            this.setState({error_msg:'Please enter OTP !'})
         } else if (!number.test(this.state.otp)) {
            this.setState({error_msg:'Please enter valid OTP !'})
            } else{
                this.setState({error_nsg:''})
                // if(this.props.resetPassword){
                //     var obj={type:'setpassword'};
                //     this.props.resetPassword();
                    // }
                    var obj={mobile_no:this.state.registerdata.mobile_no,otp:this.state.otp};
                    apiservices.otpverification(obj)
                    .then((response)=>{
                      if(!response.error){
                          if(this.props.resetPassword){
                            var registerdata = this.state.registerdata;
                            registerdata.type = 'setpassword';
                            this.props.resetPassword(registerdata);
                          }
                       
                        if (! toast.isActive(this.toastId)) {
                          this.toastId = toast.success(response.message, {
                          position: toast.POSITION.TOP_RIGHT
                          
                        });
                          }
                          // if (! toast.isActive(this.toastId)) {
                          //   this.toastId = toast.success(response.otp, {
                          //   position: toast.POSITION.TOP_RIGHT
                            
                          // });
                          //   }
                           
                        console.log("forgot response",response);
                      }else{
                        this.setState({error_msg:response.message})
                      }
                        
                    })
            }
        
      }
      resendOtp =()=>{
        var obj={mobile_no:this.state.registerdata.mobile_no};
        apiservices.forgotpassword(obj)
        .then((response)=>{
          if(!response.error){
            this.setState({timer:45},function(){
              this.resetTimer();
          })
              // if (! toast.isActive(this.toastId)) {
              //   this.toastId = toast.success(response.otp, {
              //   position: toast.POSITION.TOP_RIGHT
                
              // });
              //   }
               
            console.log("forgot response",response);
          }else{
            this.setState({error_msg:response.message})
          }
            
        })

      }
    render() {
        console.log("registerdataotp", this.state.registerdata)
        return (
            <div className="login_main">
            <div className="logincontainer">
             
                <div className="headerContentStyle">
                

                    <div className="otpverification">OTP Verification</div></div>
                <div style={{ marginBottom: '4%' }}>
                    <label className="labeltxt otpheadtxt">Enter the 4-digit OTP sent in your e-mail ID</label>
                </div>
                <p className="otptime">0:{this.state.timer} s</p>
            <div style={{display:'flex',justifyContent:'center'}}>
                <OtpInput
                          value={this.state.otp}
                          onChange={otp => this.otpChange('otp',otp)}
                          numInputs={4}
                          separator={<span> </span>}
                          inputStyle={'form-control otpInput'}
        />
        </div>
                
               <button disabled={this.state.Disabled} style={{color:this.state.Disabled?'#ccc':'#0C4FAF'}} className={`otptime resendotp otpalign ${this.state.Disabled?'disabledBtn':''}`} onClick={this.resendOtp}
                > Resend OTP</button>  
                <div className="error_msg">{this.state.error_msg}</div> 
                <div style={{ padding: 20, marginTop: 20 }}>
                    <button className="loginbtn" onClick={() => this.loginSubmit()}>VERIFY</button>
                </div>


            </div>
            </div>
        )
    }
}
