import React, {Component} from 'react';
import Geocode from "react-geocode";
import {Map, InfoWindow,  GoogleApiWrapper,Polyline,Marker} from 'google-maps-react';

class LiveTracking extends Component 
{

    constructor(props){

        super(props);

        this.state = {
           loading:false,
           routeTaken:null
        }
    }
   
render(){
    const {routeTaken} =this.state;
    const triangleCoords = [
        {lat: 10.7874, lng: 79.1376},
        {lat: 10.7501, lng: 79.1124},

      ];
   return(
       

   <div>
               <div className="booking_pickup" style={{height:'550px'}}>
                      
                      <div className="booking-route">
                      
                        <Map
                      ref={map => map}
                       google={this.props.google}
                       initialCenter={{lat:10.7870,lng:79.1378}}
        style={{width: '100%', height: '500px', position: 'relative',left:0}}
        className={'map'}
        zoom={15}>
        <Polyline
          path={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2} />
          {triangleCoords&&triangleCoords.map((item)=>{
              return(
<Marker
    position={item}
    animation={2}
    defaultAnimation={this.props.google.maps.Animation.DROP}
   
  /> 
              )
          })}
           
    </Map>  
    
   
                      </div>
</div>

</div>

 )
    }
    
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
  })(LiveTracking)