import React, {Component} from 'react';
import './style.css';

import Images  from '../../helpers/images';
import {Pagination, Select,Tooltip} from 'antd';
 


var InRequestHeader = [{'id' : 1, 'driver_id' : 'Driver ID', 'driver_name_img' : 'Driver Name & Image' , 'mobile' : 'Mobile', 'age' : 'Age', 'experience' : 'Experience', 'login_time' : 'Login Time', 'approvel' : 'Approvel', 'reject' : 'Reject'}];

 
    var InRequestItem = [{'key' : 1, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 2, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 3, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 4, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 5, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 6, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 7, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 8, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 9, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 10, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 11, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 12, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 13, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 14, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 15, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 16, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle},
    {'key' : 17, 'driver_id' : 'ID2896', 'driver_img' : Images.default_human_image, 'driver_name' : 'Walter Roberts', 'driver_mobile' : '9384774561', 'age' : '28', 'experience' : '5 years', 'login_time' : 'Login Time', 'approvel' : Images.approvel, 'reject' : Images.cancle}


 
];

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

class PilotInRequest extends Component{


    constructor(props){

        super(props);


        this.state = {

            InRequestHeader : InRequestHeader,

            InRequestItem : InRequestItem,

         currentPage : 1,
         
         pageSize:10

        }
    }


    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination=(page,pageSize)=>{
        console.log("page",page);
        this.setState({currentPage:page});
        
        console.log("page size",pageSize);
      }
      showChanger=(current,size)=>{
        this.setState({pageSize:size})
      }

    render(){


    const columns= InRequestHeader.map(row => ({
        title:row.label,
        key:row.key,
        dataIndex:row.key
 
               }))
 
       
        return(
            <div className="container">
                    
             <div className="search-header driver-search">
                         <div className="row">
                             <div className="col-md-4">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Images.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-8">
                             <div className="payment-dropdown city-dropdown"> <span className="payment">City / Zone:</span> 
                             <Select defaultValue="North Chennai" onChange={handleChange}>
                                <Option value="North Chennai">North Chennai</Option>
                                <Option value="South Chennai">South Chennai</Option>
                                <Option value="North Chennai">North Chennai</Option>
                                </Select>
                            </div>  
                            
                                     
                             </div>
                         </div>
                    </div>

<div className="driverinfodiv request defaulter-pilot">

                    <div className="driverActiveTable mt-0 defaulter-pilot">
                <table class="table table-hover">
                    <thead>
                        {this.state.InRequestHeader && this.state.InRequestHeader.map((item) => {
                            return(
                        <tr>
                         
                            <th>{item.driver_id}</th>
                            <th>{item.driver_name_img}</th>
                            <th>{item.mobile}</th>
                            <th>{item.age}</th>
                            <th>{item.experience}</th>
                            <th>{item.login_time}</th>
                            <th>{item.approvel}</th>
                            <th>{item.reject}</th>
                        </tr>
                        )
                    })}
                    </thead>
                    <tbody>
                    {this.state.InRequestItem && this.state.InRequestItem.slice((this.state.currentPage-1) * this.state.pageSize, (this.state.currentPage-1) * this.state.pageSize + this.state.pageSize).map((item) => {
                            return (<tr>
                                <td>{item.driver_id}</td>
                                <td >
                                  
                                    <div className="driverImagediv" >
                                        <img src={item.driver_img} className="img-fluid driverImage" />
                                    </div>
                                    <div className="driverImagename" >
                                        {item.driver_name}
                                    </div>
                                     
                                </td>
                                <td>{item.mobile}</td>
                                <td> {item.age}</td>
                                <td>{item.experience}</td>
                                <td>{item.login_time}</td>
                                <td><Tooltip placement="top" title="Active Driver" color="white" key="#0B485A" ><img src={item.approvel} className="img-fluid app-img"/></Tooltip></td>
                                <td><Tooltip placement="top" title="Disable Driver" color="white" key="#0B485A" ><img src={item.reject} className="img-fluid app-img"/></Tooltip></td>
                                
                            </tr>);
                        })}
                    </tbody>
                </table>

               

            </div>

             </div>
             <Pagination  total= {this.state.InRequestItem.length} current={this.state.currentPage} onChange={(page,pageSize)=>this.onchangePagination(page,pageSize)}
        pageSize= {this.state.pageSize} pageSizeOptions={['5','10','15']} onShowSizeChange={(current,size)=>this.showChanger(current,size)} showSizeChanger  itemRender={this.itemRender}
         />
             </div>
        )
    }
}

export default PilotInRequest;