import React, { Component } from 'react';
import apiservices from '../../helpers/apiservices';
import './styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';
export default class SlotAllocation extends Component {
  constructor(props){
    super(props);
    this.state={
      SlotAllocation:[],
      loading:false,
      slotData:null
    }
  }
  loadaloocationlist=()=>{
    this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={slot_day:new Date()};
    console.log("actve", obj);     
    apiservices.getSlotDetails(obj,token)
          .then((response)=>{
            this.setState({loading:false});
              this.setState({SlotAllocation:response.response,slotData:response});
              // if(!response.error){
              //     this.setState({SlotAllocation:response.response})
              //   }
              // else{
              //   this.setState({error_msg:response.message})
              // }
          })
console.log("allocation list");
  }
  componentWillMount(){
    this.loadaloocationlist();
  }
  render() {
    console.log("slot data",this.state.slotData);
    const {SlotAllocation,slotData}=this.state;
    
    return (
      <div className="slot_allocate_view"> 
      <div className="slot_display">
    <div style={{marginRight:20}}>Today Slot: <span style={{color:'#000'}}>{slotData&&slotData.today_slots}</span></div>
      <div>Tomorrow Slot: <span style={{color:'#000'}}>{slotData&&slotData.tomorrow_slots}</span></div>
      </div>
          <table className="slot_table">
            
          {this.state.loading==true&&
          <tr>
          <td colSpan="3">
              <div><CircularProgress/></div>
              </td>
              </tr>
              }
             
              <thead>
                  <tr>
                  <th className="slot_head name_width"><span>Name</span></th>
                  <th className="slot_head"><span>Time</span></th>            
                  <th className="slot_head"><span>Slots</span></th>
                  </tr>
              </thead>
             
              <tbody>
                {SlotAllocation&&SlotAllocation.length>0&&SlotAllocation.map((item)=>{
                  return(
                    <tr>
                      
                      <td><div className="slots">{item.name}</div></td>
                      <td> <div className="time">{item.time}</div></td>
                  <td> <div className="time">{item.slots_count}</div></td>
                      
                      </tr>
                  )
                })}
                  

                  
              </tbody>
          </table>

     </div>
    );
  }
}
