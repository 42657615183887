import React, {Component} from 'react';
import Image from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Pagination, Select} from 'antd';
 import  './styles.css';
 import moment from 'moment';
import { DatePicker } from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Images from '../../../helpers/images';
const dateFormat = 'YYYY/MM/DD';


const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var pendingApprovalHeader = [{'id' : 1,  'date': 'Date','time':'Time', 'svc_name' : 'SVC Name', 'svc_id' : 'SVC ID',  'description' : 'Description','amount':'Amount','approve':'Approve','reject':'Reject','status':'Status'}];


class PendingApprovals extends Component{
    constructor(props){
        super(props);
        this.state = {
            pendingApprovalHeader : pendingApprovalHeader,
            pendingapprovallist : [],            
            currentPage : 1,
            pageSize:10,
            rejectID:null,
            approveID:null,
            openapproval:false,
            rejectapproval:false,
            fromDate:null,
            toDate:null,
            peding_fields:{
                svc_center:{validations:[],error:null,value:''},
                wallet_status:{validations:[],error:null,value:''},
              },
            fields: {
                reason: { validations: [{ name: 'required' }], error: null, value: '' },
            },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadPendingApprovals();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadPendingApprovals(); 
        });
      }
OpenApproval =()=>{
  var fields=this.state.fields;
  fields['reason'].value='';
  fields['reason'].error='';
    this.setState({openapproval:true,fields,success_msg:'',error_msg:''});

}
closeApproval =()=>{
    this.setState({openapproval:false});
}
OpenRejectApproval =(data)=>{
  var fields=this.state.fields;
  fields['reason'].value='';
  fields['reason'].error='';
    console.log("data",data);
   
    this.setState({rejectapproval:true,rejectID:data,fields,success_msg:'',error_msg:''});
}
reasonReject =()=>{
    const token=localStorage['accesstoken'];
    var obj={id:this.state.rejectID,reject_reason:this.state.fields.reason.value}
    console.log("actve", obj);     
    apiservices.closependingapproval(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                    this.setState({rejectapproval:false})
                    this.loadPendingApprovals();
                    
                  }, 1000);
                  this.setState({success_msg:response.message})
                  
                
                setTimeout(() => {
                this.setState({success_msg:''})
            },3000);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
OpenApproval =(data)=>{
  this.setState({openapproval:true,approveID:data})
    
    // this.setState({rejectapproval:true});
}
approvepayment =()=>{
const token=localStorage['accesstoken'];
    var obj={id:this.state.approveID};
    apiservices.approvependingapproval(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){

                setTimeout(() => {
                  this.loadPendingApprovals();
                  this.setState({openapproval:false})
            },3000);
            this.setState({success_msg:response.message})
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
RejectApproval=()=>{
    var fields=this.state.fields;
    fields['reason'].value='';
    fields['reason'].error='';
    this.setState({rejectapproval:false,fields,success_msg:'',error_msg:''});  
}
loadPendingApprovals=()=>{
    // this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center:this.state.peding_fields.svc_center.value,wallet_status:this.state.peding_fields.wallet_status.value,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
    console.log("actve", obj);     
    apiservices.listpendingapproval(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  console.log("calc",calc);
                  this.setState({pendingapprovallist:response.response, numbercount:calc})
                  console.log("pending approval reponse", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadPendingApprovals();
    this.loadWalletStatus();
        this.loadSeriveCenter();
}
checkvalidations = (value, key) => {


    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0) {
      this.setState({ error: true })
      //errorblock
    } else {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  popupSubmit =() =>{
    var fields =this.state.fields;
    var getallkeys=Object.keys(fields);
    getallkeys.map((obj)=>{
    var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
    fields[obj].error=checkstatus.msg;
    })
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      //errorblock
      this.setState({error:true})
    }else{
      //successblock
      this.setState({error:false});
      this.reasonReject();
    }
    this.setState({fields});
  }
  checkfiltervalidations =(data)=>{
    console.log("priority",data);
   
    this.setState({fromDate:data[0],toDate:data[1]},function(){
      this.loadPendingApprovals();
    })
  }
  ClearDatepicker =()=>{
    this.setState({fromDate:null,toDate:null},function(){
      this.loadPendingApprovals();
    })
  }
  loadWalletStatus=(data)=>{
    const token=localStorage['accesstoken'];
    var obj={};
    console.log("actve", obj);     
    apiservices.listWalletStatusDrop(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                this.setState({wallet_status:response.response})
                // if(!value){
                // ticketfields['ticket_priority'].value=response.response.length>0?response.response.id:'';            
                // this.setState({ticketfields})
                // }
                console.log("wallet",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
          })
}
loadSeriveCenter=(data)=>{
    const token=localStorage['accesstoken'];
    var obj={};
    console.log("actve", obj);     
    apiservices.bookingsvclistdropdown(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                this.setState({svc_center:response.response})
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
          })
}
checksvcfilter =(data)=>{
    var peding_fields=this.state.peding_fields;
    peding_fields['svc_center'].value=data;
    this.setState({peding_fields},function(){
      this.loadPendingApprovals();
    })
  }
  checkdriverfilter =(data)=>{
    var peding_fields=this.state.peding_fields;
    peding_fields['wallet_status'].value=data;
    this.setState({peding_fields},function(){
      this.loadPendingApprovals();
    })
  }
render(){
    const offset = moment().utcOffset();
    const {svc_center,wallet_status}=this.state;
    return(
        <div>
        {/* <div>
                                            <button className="genrate_btn" >
                                                Generate Report
                                            </button>
                                            </div> */}
        <div className="settlment_advice_main">
              <div className="container1">
              
                                        
              <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      {/* <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/> */}
                                  </div>
                             </div>
                             <div className="col-md-9">
                             <div className="payment-dropdown"> <span className="payment">Status</span>
                             <Select value={[this.state.peding_fields.wallet_status.value?parseInt(this.state.peding_fields.wallet_status.value):'']}  onChange={(value) => this.checkdriverfilter(value)} name="wallet_status" >
                             <Option value="">All</Option>
                         {wallet_status&&wallet_status.length>0&&wallet_status.map((item)=>{
                              return(
                               
                               
                              <Option value={item.id}>{item.status}</Option>
                              
                              )
                            })} 
                            
                           </Select> 
                            </div>
                             <div className="payment-dropdown"> <span className="payment">SVC Name:</span> 
                             <Select value={[this.state.peding_fields.svc_center.value?parseInt(this.state.peding_fields.svc_center.value):'']}  onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                             <Option value="">All</Option>
                         {svc_center&&svc_center.length>0&&svc_center.map((item)=>{
                              return(
                               
                               
                              <Option value={item.id}>{item.centre_name}</Option>
                              
                              )
                            })} 
                            
                           </Select> 
                            </div>
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from payment_from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                                            
                                 <div className="table-list customer_table">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.pendingApprovalHeader && this.state.pendingApprovalHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.date}</th>
                                                <th>{item.time}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.amount}</th>
                                                <th>{item.approve}</th>
                                                <th>{item.reject}</th>
                                                <th>{item.status}</th>
                                                
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                    {this.state.pendingapprovallist &&this.state.pendingapprovallist.length>0&&this.state.pendingapprovallist.map((item) => {

                                        return(
                                            <tr>
                                               <td>{moment(item.created_at).format('DD/MM/YY')} </td>
                                                <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                                                <td>{item.centre_name}</td>
                                                <td>ID{item.svc_center_id}</td>
                                                <td>Rs.{item.amount}</td>
                                                <td><img src={Images.approvel} className="pending_approval_img" style={{opacity:item.status_id!=3?1:0.5}} onClick={()=>item.status_id!=3?this.OpenApproval(item.id):''}/></td>
                                                <td><img src={Images.cancel_new} style={{opacity:item.status_id!=2?1:0.5}}  className="pending_approval_img" onClick={()=>item.status_id!=2?this.OpenRejectApproval(item.id):''}/></td>
                                                <td>{item.status}</td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {this.state.pendingapprovallist&&this.state.pendingapprovallist.length==0&&
                            <div>No Records Found</div>
                            }

 
                        </div>
                     </div>
                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />

                     
             </div>
             </div>
             <Dialog  open={this.state.openapproval}  className="pending_approvals">
                    
                    <div className="">
                   <div className="title_section">
                  <div className="add_approval_text">Approve Payment</div> <div className="approval_close" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}onClick={() => this.closeApproval()} /></div>
                </div>
                <div className="approval_content">Are you sure you want to approve this payment?</div>
                {this.state.success_msg?
<div className="success_msg">{this.state.success_msg}</div>:''
}
{this.state.error_msg?
<div className="failure_msg">{this.state.error_msg}</div>:''
}
                <div className="approval_btn_main">
<button className="approval_btn" onClick={()=>this.approvepayment()}>Approve</button>
</div>
                    </div>

                </Dialog>
                <Dialog  open={this.state.rejectapproval}  className="pending_approvals">
                    
                    <div className="">
                   <div className="title_section">
                  <div className="add_approval_text">Reject Payment</div> <div className="approval_close" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}  onClick={() => this.RejectApproval()} /></div>
                </div>
                <div className="reason_reject_box">
                <div className="form-group reason_box">
                         <label>
                         Enter reason to reject
                         </label>

                         <textarea row="5" col="4"   maxLength="250" name="reason" value={this.state.fields.reason.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  className="form-control reason_box"  ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['reason'].error&&this.state.fields['reason'].error}</span>
                     </div>
                     </div>
                     {this.state.success_msg?
<div className="success_msg">{this.state.success_msg}</div>:''
}
{this.state.error_msg?
<div className="failure_msg">{this.state.error_msg}</div>:''
}
                <div className="approval_btn_main">
<button className="approval_btn" onClick={()=>this.popupSubmit()}>Submit</button>
</div>
                    </div>

                </Dialog>
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default PendingApprovals;