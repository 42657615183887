import React, { Component } from 'react';
import './style.css';
import 'antd/dist/antd.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, Tag, Space, Select, Pagination, Tooltip } from 'antd';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect,
} from 'react-router-dom';

const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var SvcListArray = [
  {
    id: 1,
    svc_id: 'SVC ID',
    svc_name: 'SVC Name',
    master_name_id: 'Master ID & Name',
    add_booking: 'Add Booking',
    add_mangeruser: 'Add & Manage Users',
    wallet_amnt: 'Wallet Amount',
    manager_details: 'Manager Details',
    add_view: 'Add & View Settlement Invoice',
    city: 'City',
    zone: 'Zone',
  },
];
export default class SvcTableList extends Component {
  constructor(props) {
    console.log('svclist', props);
    super(props);

    this.state = {
      SvcListArray: SvcListArray,
      numbercount: 0,
      SvcItemArray: [],
      currentPage: 1,
      pageSize: 10,
      SvcDetails: false,
      svcList: true,
      svcid: null,
      city_search: '',
      zone: '',
      servicecentre_name: null,
      action: null,
      addBooking: false,
      loading: false,
      wallet: '',
    };
  }
  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={Images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={Images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.loadsvclist();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.loadsvclist();
    });
  };
  componentWillMount() {
    this.loadsvclist();
  }
  loadsvclist = () => {
    this.setState({ loading: true });
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
      centre_name: this.state.servicecentre_name,
      city: this.state.city_search,
      wallet: this.state.wallet,
      zone: this.state.zone,
    };
    console.log('loadscvlist', obj);
    apiservices.listSVC(obj, token).then((response) => {
      this.setState({ loading: false });
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        // console.log("calc",calc);

        this.setState({ SvcItemArray: response.response, numbercount: calc });
        console.log('list svc', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  cityFilter = (e) => {
    var value = e.target.value;

    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.loadsvclist();
    });
  };
  SvcDetails = (data) => {
    console.log('data', data);
    this.props.history.push('/svc/svc_list/svcdetails/svc/' + data.id);
  };

  gotobooking = () => {
    this.props.history.push('/booking/add_booking');
  };
  svcDetailsHide = () => {
    this.setState({ SvcDetails: false, addBooking: false });

    this.setState({ svcList: true });
  };
  renderImg = (key, value) => {
    this.setState({ action: value, svcid: key.id });
  };
  filterwallet = (data) => {
    this.setState({ wallet: data }, function () {
      this.loadsvclist();
    });

    console.log('wallet', data);
  };

  render() {
    return (
      <div>
        {' '}
        <div className='container'>
          <div className='search-header'>
            <div className='row'>
              <div className='col-md-3'>
                <div class='form-group search-list svc_search'>
                  <input
                    type='text'
                    placeholder='Search Name'
                    name='servicecentre_name'
                    className='form-control'
                    onChange={(e) => this.cityFilter(e)}
                  />
                  <img alt='' src={Images.green_search} className='img-fluid' />
                </div>
              </div>
              <div className='col-md-3'>
                {/* <div className='service_listing'> */}
                {/* <div className='form-group'> */}
                {/* <div className='form-inline s-listing'> */}
                <div className='city_search' style={{ display: 'flex' }}>
                  <label>City:</label>
                  <input
                    type='text'
                    placeholder='Search City'
                    className='form-control citysearchbox'
                    name='city_search'
                    onChange={(e) => this.cityFilter(e)}
                  />
                  {/* </div>{' '} */}
                </div>
              </div>{' '}
              <div className='col-md-3'>
                {/* <div className='form-inline s-listing'> */}
                <div className='city_search' style={{ display: 'flex' }}>
                  <label>Zone:</label>
                  <input
                    type='text'
                    placeholder='Search City'
                    className='form-control citysearchbox'
                    name='zone'
                    onChange={(e) => this.cityFilter(e)}
                  />
                </div>
                {/* </div> */}
              </div>
              <div className='col-md-3'>
                {/* <div className='form-inline s-listing'> */}
                <div style={{ display: 'flex' }}>
                  <label>Wallet Amount:</label>
                  <Select
                    value={this.state.wallet}
                    onChange={(value) => this.filterwallet(value)}>
                    <Option value=''>All</Option>
                    <Option value='50000'>Upto 50,000</Option>
                    <Option value='100000'>Upto 1,00,000</Option>
                    <Option value='150000'>Upto 1,50,000</Option>
                    <Option value='200000'>Upto 2,00,000</Option>
                    <Option value='250000'>Upto 2,50,000</Option>
                    <Option value='300000'>Upto 3,00,000</Option>
                    <Option value='350000'>Upto 3,50,000</Option>
                    <Option value='400000'>Upto 4,00,000</Option>
                    <Option value='450000'>Upto 4,50,000</Option>
                    <Option value='500000'>Upto 5,00,000</Option>
                  </Select>
                </div>
                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className='table-list'>
            <div class='table-responsive'>
              <table class='table table-borderless'>
                <thead>
                  {this.state.SvcListArray &&
                    this.state.SvcListArray.map((item) => {
                      return (
                        <tr>
                          <th>{item.svc_id}</th>
                          <th>{item.svc_name}</th>
                          <th className='text-left'>{item.master_name_id}</th>
                          <th>{item.add_booking}</th>
                          <th>{item.add_mangeruser}</th>
                          <th>{item.wallet_amnt}</th>
                          <th>{item.manager_details}</th>
                          {/* <th>{item.add_view}</th> */}
                          <th>{item.city}</th>
                          <th>{item.zone}</th>
                        </tr>
                      );
                    })}
                </thead>
                <tbody>
                  {this.state.SvcItemArray &&
                    this.state.SvcItemArray.length > 0 &&
                    this.state.SvcItemArray.map((item) => {
                      return (
                        <tr>
                          <td onClick={() => this.SvcDetails(item)}>
                            ID{item.id}
                          </td>
                          <td onClick={() => this.SvcDetails(item)}>
                            {item.centre_name}
                          </td>
                          <td
                            className='text-left'
                            onClick={() => this.SvcDetails(item)}>
                            {item.dealer_name} <br />
                            <span class='masterId'>MID{item.id}</span>
                          </td>
                          <td onClick={() => this.gotobooking()}>
                            <Tooltip
                              placement='top'
                              title='Add Booking'
                              color='white'
                              key='#0B485A'>
                              <img src={Images.AddBookingImg} />
                            </Tooltip>
                          </td>
                          <td>
                            <Link
                              to={`${this.props.parentProps.match.path}/svcdetails/users/add_user/0/${item.id}`}>
                              <Tooltip
                                placement='top'
                                title='Add User'
                                color='white'
                                key='#0B485A'>
                                <img
                                  src={Images.AddBookingImg}
                                  onClick={() => this.renderImg(item, 'first')}
                                />
                              </Tooltip>
                            </Link>
                            <Link
                              to={`${this.props.parentProps.match.path}/svcdetails/users/user_list/${item.id}`}>
                              <Tooltip
                                placement='top'
                                title='Edit User'
                                color='white'
                                key='#0B485A'>
                                <img
                                  alt=''
                                  src={Images.EditImg}
                                  onClick={() => this.renderImg(item, 'second')}
                                />
                              </Tooltip>
                            </Link>
                          </td>
                          <td onClick={() => this.SvcDetails(item)}>
                            {item.wallet}
                          </td>
                          <td onClick={() => this.SvcDetails(item)}>
                            {item.contact_name}
                            <br />
                            {item.contact_number}
                          </td>
                          {/* <td><Tooltip placement="top" title="Add Payment" color="white" key="#0B485A" ><img src={Images.AddBookingImg}/></Tooltip><Tooltip placement="top" title="View Payment" color="white" key="#0B485A" ><img src={Images.ViewImg} /></Tooltip></td> */}
                          <td onClick={() => this.SvcDetails(item)}>
                            {item.city}
                          </td>
                          <td> {item.zone}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {this.state.SvcItemArray &&
                this.state.SvcItemArray.length == 0 && (
                  <div className='no_records_found'>No Records Found</div>
                )}
              {this.state.loading == true && <CircularProgress />}
            </div>
          </div>

          {/* {this.state.SvcItemArray.length>this.state.pageSize&&  */}
          {this.state.SvcItemArray.length != 0 && (
            <Pagination
              total={this.state.numbercount}
              current={this.state.currentPage}
              onChange={(page, pageSize) =>
                this.onchangePagination(page, pageSize)
              }
              pageSize={this.state.pageSize}
              pageSizeOptions={['5', '10', '15']}
              onShowSizeChange={(current, size) =>
                this.showChanger(current, size)
              }
              showSizeChanger
              itemRender={this.itemRender}
            />
          )}
          {/* } */}
          {/* // pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger */}
          {/* }  */}
        </div>{' '}
      </div>
    );
  }
}
