import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import { Table, Tag, Pagination, Select } from 'antd';
import ValidationLibrary from '../../../helpers/validationfunction';
import 'antd/dist/antd.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { DatePicker, Tooltip } from 'antd';
import apiservices from '../../../helpers/apiservices';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";


import
{
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";

const { Option } = Select;

function handleChange(value)
{
  console.log(`selected ${value}`);
}

const dateFormat = 'YYYY/MM/DD';
const { RangePicker } = DatePicker;


var upcomingpickupHeader = [{ 'id': 1, 'booking_id': 'ID', 'city_zone': 'City', 'booking_info': 'Booking Info', 'customer_details': 'Customer Details', 'service_center_details': 'Service Centre Details', 'pilot_details': 'Pilot Details', 'state': 'Reschedule', 'cancel': 'Cancel', 'pilot_changes': 'Pilot Change' }];

class UpComingPickUp extends Component
{

  constructor(props)
  {

    super(props);
    this.state = {

      upcomingpickupHeader: upcomingpickupHeader,

      upcomingpickupList: [],

      currentPage: 1,
      pageSize: 10,
      loading: false,
      openactiveBooking: false,
      activeID: null,
      assignID: null,
      cancelfields: {
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      fields: {
        slotdate: { validations: [{ name: 'required' }], error: null, value: moment() },
        slotime: { validations: [{ name: 'required' }], error: null, value: '' },
        service_advisor: { validations: [{ name: 'required' }], error: null, value: '' },
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      reassignfields: {
        driver_list: { validations: [{ name: 'required' }], error: null, value: '' },
      },
      customer_name: null,
      fromDate: null,
      toDate: null,
      pickup_fields: {
        svc_center: { validations: [], error: null, value: '' },
      },


    }
  }
  opendelete = () =>
  {
    this.setState({ opendelete: true });
  }
  openreschedule = () =>
  {
    console.log("data delete");
    this.setState({ openreschedule: true });
  }
  deleteClose = () =>
  {
    this.setState({ openreschedule: false });
    this.setState({ opendelete: false });
    this.setState({ openCall: false })
  }
  openCall = () =>
  {
    this.setState({ openCall: true })
  }
  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }

  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.loadUpComingPickUp();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.loadUpComingPickUp();
    })
  }
  componentWillMount()
  {
    this.loadUpComingPickUp();
    this.loadSeriveCenter();
  }
  unassignedDriverList = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.unassigneddriverlist(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ driver_list: response.response })
          var reassignfields = this.state.reassignfields;
          reassignfields['driver_list'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ reassignfields })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  loadUpComingPickUp = () =>
  {


    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, svc_center: this.state.pickup_fields.svc_center.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    apiservices.listbookingupcomingpickup(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          // console.log("calc",calc);

          this.setState({ upcomingpickupList: response.response, numbercount: calc })
          console.log("active pickup List", this.state.upcomingpickupList)

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })


  }
  pauseBooking = (data, value, svc_id) =>
  {
    var self = this;
    var fields = this.state.fields;
    fields['reasons'].value = '';
    fields['reasons'].error = null;
    this.setState({ fields });
    var cancelfields = this.state.cancelfields;
    cancelfields['reasons'].value = '';
    cancelfields['reasons'].error = null;
    this.setState({ cancelfields, responseMsg: '' });
    this.setState({ popupDisplay: data, activeID: value.id, assignID: value.assign_id, fields, cancelfields });
    console.log("value", value);
    this.loadaloocationlist();
    this.loadAdvisorDropdown(svc_id);
    if (data == 'map')
    {
      this.setState({ latitude: value.driver_latitude, longitude: value.driver_longitude }, function ()
      {
        console.log("latitude", this.state.latitude)
      })
    }


    this.setState({ openactiveBooking: true });
  }

  AssignBooking = (data, value, svc_id) =>
  {
    var self = this;
    this.setState({ responseMsg: '' });
    this.setState({ popupDisplay: data, activeID: value.id, assignID: value.assign_id, });
    console.log("value", value);
    this.unassignedDriverList();
    //  this.loadaloocationlist();
    // this.loadAdvisorDropdown(svc_id);
    //    if(data=='map'){
    //   this.setState({latitude:value.driver_latitude,longitude:value.driver_longitude},function(){
    //     console.log("latitude",this.state.latitude)
    //   })
    // }


    this.setState({ openactiveBooking: true });
  }
  Reassignvalidations = (value, key) =>
  {

    var reassignfields = this.state.reassignfields;
    reassignfields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, reassignfields[key].validations);
    // console.log(checkstatus);

    reassignfields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(reassignfields);
    var filtererrors = getallkeys.filter((obj) => reassignfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ reassignfields });
  }

  checkvalidations = (value, key) =>
  {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  popupSubmit = (data) =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      if (data == 'cancel')
      {
        this.cancelBooking();
      }
      if (data == 'reschedule')
      {
        this.RescheduleBooking();
      }
      // alert('hii');
    }
    this.setState({ fields });
  }
  cancelBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, reason: this.state.cancelfields.reasons.value, assign_id: this.state.assignID };
    console.log("cancel", obj);
    apiservices.cancelbooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.loadUpComingPickUp();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  AssignBookingSubmit = () =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, assign_id: this.state.assignID, driver_id: this.state.reassignfields.driver_list.value };
    console.log("cancel", obj);
    apiservices.assignbooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          // setTimeout(() => {
          this.loadUpComingPickUp();

          // }, 3000);
          this.setState({ responseMsg: response.message })
          setTimeout(() =>
          {
            this.setState({ openactiveBooking: false })
          }, 2000);
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  loadaloocationlist = () =>
  {
    const token = localStorage['accesstoken'];
    var obj = { slot_day: new Date() };
    apiservices.getSlotDetails(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ slotime: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['slotime'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })

  }
  loadAdvisorDropdown = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { svc_center_id: value };
    console.log("service advisor", value);
    apiservices.bookingadvicerdropdown(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ service_advisor: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['service_advisor'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  RescheduleBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, slot_day: moment(this.state.fields.slotdate.value).format(dateFormat), slot_time: this.state.fields.slotime.value, invoice_amount: '', service_advisor: this.state.fields.service_advisor.value, invoice_doc: '', notes: this.state.fields.reasons.value };
    console.log("schedule booking", obj);
    apiservices.reschedulebooking(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.loadUpComingPickUp();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })

        } else
        {
          this.setState({ error_mresponseMsgsg: response.message })
        }

      })
  }
  cancelvalidations = (value, key) =>
  {

    var cancelfields = this.state.cancelfields;
    cancelfields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, cancelfields[key].validations);
    // console.log(checkstatus);

    cancelfields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(cancelfields);
    var filtererrors = getallkeys.filter((obj) => cancelfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ cancelfields });
  }
  cancelpopupSubmit = async (data) =>
  {
    var cancelfields = this.state.cancelfields;
    var getallkeys = Object.keys(cancelfields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(cancelfields[obj].value, cancelfields[obj].validations);
      cancelfields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => cancelfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });

      if (data == 'cancel')
      {
        this.cancelBooking();
      }
      // alert('hii');
    }
    this.setState({ cancelfields });
  }

  ReassignSubmit = (data) =>
  {
    var reassignfields = this.state.reassignfields;
    var getallkeys = Object.keys(reassignfields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(reassignfields[obj].value, reassignfields[obj].validations);
      reassignfields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => reassignfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      console.log("assign booking", data);
      if (data == 'assign')
      {
        this.AssignBookingSubmit();
      }
      // this.AssignBookingSubmit();
      // if(data=='reassign'){
      //   this.ReassignBooking();
      // }
      // if(data=='interchange'){
      //   this.InterchangeBooking();
      // }
    }
    this.setState({ reassignfields });
  }

  closeDialog = () =>
  {
    console.log("cancl fields", this.state.cancelfields)
    this.setState({ openactiveBooking: false }, function ()
    {
      var fields = this.state.fields;
      fields['reasons'].value = '';
      this.setState({ fields });
      var cancelfields = this.state.cancelfields;
      cancelfields['reasons'].value = '';
      this.setState({ cancelfields, responseMsg: '' })
    });
  }
  cityFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function ()
    {
      this.loadUpComingPickUp();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.loadUpComingPickUp();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.loadUpComingPickUp();
    })
  }

  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['svc_center'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.loadUpComingPickUp();
    })
  }
  makecall = (bkid, assignid, mobilenumber) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: bkid, assign_id: assignid, mobile_no: mobilenumber };
    apiservices.makeCall(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.response.message }, function ()
          {
            this.loadUpComingPickUp();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.response.message }, function ()
          {
            this.loadUpComingPickUp();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  render()
  {
    const { slotime, service_advisor, svc_center, driver_list } = this.state;

    return (
      <div>

        <div className="search-header booking-mishaps booking-active">
          <div className="row">
            <div className="col-md-3">
              <div class="form-group search-list payment-search">
                <input type="text" placeholder="Search for Customer or Vehicle" className="form-control" name="customer_name" onChange={(e) => this.cityFilter(e)} />
                <img src={images.green_search} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                <Select value={[this.state.pickup_fields.svc_center.value ? parseInt(this.state.pickup_fields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                  <Option value="">All</Option>
                  {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                  {
                    return (


                      <Option value={item.id}>{item.centre_name}</Option>

                    )
                  })}

                </Select>
              </div>
              <div className="service_listing booking-list payments-list">


                <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.error_call ?
          <div className="failure_msg">{this.state.error_call}</div> : ''
        }

        {this.state.success_call ?
          <div className="success_msg">{this.state.success_call}</div> : ''
        }
        <div className="table-list">


          <div class="table-responsive">


            <table class="table table-borderless">
              <thead>
                {this.state.upcomingpickupHeader && this.state.upcomingpickupHeader.map((item) =>
                {
                  return (
                    <tr>
                      <th>{item.booking_id}</th>
                      <th>{item.city_zone}</th>
                      <th className="text-left">{item.booking_info}</th>
                      <th>Slot Time</th>
                      <th className="customer_thead text-left">{item.customer_details}</th>
                      <th>Customer Name</th>
                      <th>Vehicle Number</th>
                      <th className="service_thead">{item.service_center_details}</th>
                      <th>{item.state}</th>
                      <th>{item.cancel}</th>
                      <th>Assign</th>
                    </tr>
                  )
                })}
              </thead>
              <tbody>


                {this.state.upcomingpickupList && this.state.upcomingpickupList.length > 0 && this.state.upcomingpickupList.map((item) =>
                {

                  return (
                    <tr>
                      <td>ID{item.id}</td>
                      <td>{item.city}</td>
                      <td className="text-left">{moment(item.slot_day).format('DD/MM/YY')}</td>
                      <td>{item.slot_time}</td>
                      <td className="text-left">{item.vehicle_number}<br />
                        <div className="pick_address">{item.pickup_address}</div><br />
                        <div className="pick_address">{item.customer_mobile}</div>
                        <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.customer_mobile)} /></Tooltip>
                      </td>
                      <td>{item.customer_name}</td>
                      <td>{item.vehicle_number}</td>
                      <td className="service-td"><span className="service-listing">{item.centre_name}</span><br />
                        <span className="service-listing">{item.service_advisor_name}</span>
                        {item.service_advisor_mobile_no != null ?
                          <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.service_advisor_mobile_no)} /></Tooltip> : ''}<br />
                        <span className="service-listing">{item.cre_name}</span>
                        {item.cre_mobile_no != null ?
                          <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.cre_mobile_no)} /></Tooltip> : ''}

                      </td>

                      <td><Tooltip placement="top" title="Reschedule" color="white" key="#0B485A" ><img src={images.reschedule} style={{ width: 38 }} onClick={() => this.pauseBooking('reschedule', item, item.svc_center_id)} /></Tooltip></td>
                      <td><Tooltip placement="top" title="Cancel" color="white" key="#0B485A" ><img src={images.cancel_new} style={{ width: 38 }} onClick={() => this.pauseBooking('cancel', item)} /></Tooltip></td>
                      <td><Tooltip placement="top" title="Assign" color="white" key="#0B485A" ><img src={images.refresh} style={{ width: 38 }} onClick={() => this.AssignBooking('assign', item)} /></Tooltip></td>

                    </tr>
                  )
                })}
              </tbody>
            </table>

            {this.state.upcomingpickupList && this.state.upcomingpickupList.length == 0 &&
              <div className="no_records_found">No Records Found</div>
            }
            {this.state.loading == true &&
              <CircularProgress />
            }

          </div>
        </div>

        {this.state.upcomingpickupList.length != 0 &&
          <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
            pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
          />
        }

        <Dialog open={this.state.openactiveBooking} className={`booking_pickup ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.closeDialog()} /></div>

          {this.state.popupDisplay == 'cancel' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Cancel Booking
                   </div>
              <div>


                <div className="form-group reason_box">
                  <label>
                    Reason
                         </label>

                  <textarea row="5" col="4" maxLength="300" name="reasons" className="form-control reason_box" value={this.state.cancelfields.reasons.value} onChange={(e) => this.cancelvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.cancelfields['reasons'].error && this.state.cancelfields['reasons'].error}</span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button disabled={this.state.responseMsg && this.state.responseMsg} className="delete_submit" onClick={() => this.cancelpopupSubmit('cancel')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'reschedule' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Re-Schedule Booking
                     </div>
              <div>
                <div className="form-group">
                  <label className="label_paused">
                    Queue ID
                           </label>

                  <input type="text" className="form-control input_paused" value={this.state.activeID} readOnly />
                </div>

                <div className="form-group">
                  <div class="form-inline position_slot">
                    <label>Select Date</label>
                    <DatePicker className="slotdate paused_slotdate" onChange={(value) => this.checkvalidations(value, 'slotdate')} value={this.state.fields.slotdate.value} disabledDate={current =>
                    {
                      return current && current < moment().startOf('day');
                    }} suffixIcon={<img src={images.date_picker} className="datepicker_img_slot" />} />
                    <span className="error_msg_adddealer">{this.state.fields['slotdate'].error && this.state.fields['slotdate'].error}</span>
                  </div>

                </div>



                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Time Slot</label>

                    <Select value={[parseInt(this.state.fields.slotime.value)]} onChange={(value) => this.checkvalidations(value, 'slotime')} name="slotime" >
                      {slotime && slotime.length > 0 && slotime.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.time}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img_paused" />

                    <span className="error_msg_adddealer">{this.state.fields['slotime'].error && this.state.fields['slotime'].error}</span>



                  </div>
                </div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Service Advisor</label>

                    <Select value={[parseInt(this.state.fields.service_advisor.value)]} onChange={(value) => this.checkvalidations(value, 'service_advisor')} name="service_advisor" >
                      {service_advisor && service_advisor.length > 0 && service_advisor.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img_paused" />

                    <span className="error_msg_adddealer">{this.state.fields['service_advisor'].error && this.state.fields['service_advisor'].error}</span>



                  </div>
                </div>


                <div className="form-group reason_box">
                  <label>
                    Notes
                           </label>

                  <textarea row="5" maxLength="300" className="form-control notesheight" name="reasons" value={this.state.fields.reasons.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.fields['reasons'].error && this.state.fields['reasons'].error}</span>


                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.popupSubmit('reschedule')}>Submit</button>
                </div>
              </div>


            </div>
          }

          {this.state.popupDisplay == 'assign' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Assign Driver
                   </div>
              <div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select the Driver</label>
                    <Select value={[parseInt(this.state.reassignfields.driver_list.value)]} onChange={(value) => this.Reassignvalidations(value, 'driver_list')} name="driver_list" >
                      {driver_list && driver_list.length > 0 && driver_list.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.first_name} {item.last_name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.reassignfields['driver_list'].error && this.state.reassignfields['driver_list'].error}</span>

                  </div>
                </div>

                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.ReassignSubmit('assign')}>Submit</button>
                </div>
              </div>


            </div>
          }
        </Dialog>

      </div>

    )
  }
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default UpComingPickUp;
