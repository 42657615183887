import React, { Component } from 'react';
import images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
import SVCDetails from './SVCDetails';
import PaymentDetails from './PaymentDetails';
import ContactPersonDetails from './ContactPersonDetails';
class AddDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svcStatus: 1,
      svcDetails: null,
      paymentDetails: null,
      error_msg: null,
      addDealerResponse: null,
      contactpersonDetails: null,
      fields: {},
    };
  }

  // addDealer =()=>{
  //   const token=localStorage['accesstoken'];
  //   var obj={dealer_email:this.state.fields.email_id.value}
  //   console.log("obj",obj);
  //   apiservices.adddealer(obj,token)
  //   .then((response)=>{
  //     if(!response.error){
  //       localStorage.setItem('accesstoken', response.Authorization);
  //       this.props.loggedIn(true);
  //             this.props.history.push('/dashboard');

  //     }else{
  //       this.setState({error_msg:response.message})
  //     }
  //       console.log("login response",response);
  //   })
  // }
  displayNextDetails = (data, key, details, submitdata) => {
    console.log('details', details);
    console.log('key', key);
    if (data != 4) {
      this.setState({ svcStatus: data });
    }
    this.setState({ [key]: details }, function () {
      if (data == 4) {
        const { svcDetails, paymentDetails, contactpersonDetails } = this.state;
        const token = localStorage['accesstoken'];
        var obj = {
          dealer_email: svcDetails.email_id.value,
          vehicle_brand: svcDetails.vehicle_brand.value,
          dealer_name: svcDetails.dealer_name.value,
          vehicle_type: svcDetails.vehicle_type.value,
          no_of_service_center: svcDetails.service_centre.value,
          dealer_number: svcDetails.contact_number.value,
          address: svcDetails.address.value,
          city: svcDetails.city.value,
          // zone_id: svcDetails.zone_id.value,
          pincode: svcDetails.pincode.value,
          pan_number: paymentDetails.pan_number.value,
          tan_number: paymentDetails.tan_number.value,
          gst_number: paymentDetails.gst_number.value,
          setup_fee: paymentDetails.setup_fee.value,
          prepaid_amount: paymentDetails.prepaid_amount.value,
          pickup_amount: paymentDetails.pickup_amount.value,
          dropoff_amount: paymentDetails.drop_amount.value,
          pickup_dropoff_amount: paymentDetails.pickup_drop_amount.value,
          pickup_dropoff_double_fee:
            paymentDetails.pickupdrop_distance_fee.value,
          pickup_dropoff_double_distance:
            paymentDetails.pickupdrop_double_distance.value,
          pickup_dropoff_no_show_fee:
            paymentDetails.pickupdrop_noshow_fee.value,
          chauffeur_fee: paymentDetails.convenience_fee.value,
          chauffeur_double_fee:
            paymentDetails.chauffeur_doubledistance_fee.value,
          chauffeur_double_distance:
            paymentDetails.chauffeur_doubledistance_kms.value,
          single_line_fee: paymentDetails.singleline_amount.value,
          single_line_double_fee:
            paymentDetails.singleline_doubledistance_fee.value,
          single_line_distance: paymentDetails.singleline_distance.value,
          triple_line_fee: paymentDetails.tripleline_amount.value,
          triple_line_distance: paymentDetails.tripleline_distance.value,
          slab_1: paymentDetails.slab1.value,
          slab_2: paymentDetails.slab2.value,
          slab_3: paymentDetails.slab3.value,
          slab_4: paymentDetails.slab4.value,
          transaction_charge: paymentDetails.amount_transaction_charge.value,
          cancel_charge: paymentDetails.cancellation_charge.value,
          contact_name: contactpersonDetails.full_name.value,
          contact_number: contactpersonDetails.mobile_number.value,
          contact_email: contactpersonDetails.contact_emailid.value,
          dealer_code: contactpersonDetails.dealer_code.value,
          // single_point_name:contactpersonDetails.singlepnt_contactname.value,
          // single_point_number:contactpersonDetails.singlepnt_contactnumber.value
        };
        if (submitdata == 'submit') {
          apiservices.adddealer(obj, token).then((response) => {
            console.log('login response', response);
            if (!response.error) {
              // this.props.successdealer(response);
              this.setState({ addDealerResponseSuccess: response });
            } else {
              this.setState({ addDealerResponseFailure: response });
            }
          });
        }

        console.log('submit obj', obj);
      }
    });
  };
  goBack = (data) => {
    this.setState({ svcStatus: data });
  };
  render() {
    return (
      <div>
        {this.state.svcStatus == 1 ? (
          <div className='svc-dealer-sec'>
            <div className=''>
              <div className='dealer-head'>1. Dealer Master Details</div>
              <SVCDetails
                svcDetails={this.state.svcDetails}
                sendnextdetails={(data, key, details) =>
                  this.displayNextDetails(data, key, details)
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {this.state.svcStatus == 2 ? (
          <div className='svc-dealer-sec'>
            <div className=''>
              <div className='dealer-head'>2. Payment Details</div>
              <PaymentDetails
                paymentDetails={this.state.paymentDetails}
                sendgoBack={(data) => this.goBack(data)}
                sendnextdetails={(data, key, details) =>
                  this.displayNextDetails(data, key, details)
                }
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {this.state.svcStatus == 3 ? (
          <div className='svc-dealer-sec'>
            <div className=''>
              <div className='dealer-head'>3. CEO / Contact Person Details</div>
              <ContactPersonDetails
                {...this.props}
                contactpersonDetails={this.state.contactpersonDetails}
                sendgoBack={(data) => this.goBack(data)}
                sendnextdetails={(data, key, details, submitdata) =>
                  this.displayNextDetails(data, key, details, submitdata)
                }
                addDealerResponseSuccess={this.state.addDealerResponseSuccess}
                addDealerResponseFailure={this.state.addDealerResponseFailure}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default AddDealer;
