import React, { Component } from 'react';
import './styles.css';
import apiservices from '../../helpers/apiservices';
import menujson from './menus.json';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect,
} from 'react-router-dom';
import images from '../../helpers/images';

export default class Menu extends Component {
  constructor(props) {
    console.log('props', props);

    super(props);
    this.state = {
      user_type: null,
      Menus: [],
      show: false,
    };
  }
  componentWillReceiveProps(props) {
    console.log('will receive', props);
  }
  loadMenus = () => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.getdesigination(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        console.log('menus', response.response.permission);
        this.setState({
          Menus: response.response.permission
            ? response.response.permission
            : [],
        });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  componentWillMount() {
    this.loadMenus();
  }
  hideMenu = (e) => {
    alert('Permission Denied');
    // e.preventDefault();
  };
  toggleNav = () => {
    this.setState({ show: true });
  };
  toggleNavFalse = () => {
    this.setState({ show: false });
  };
  render() {
    console.log('permissions', this.state.Menus);
    const { Menus } = this.state;
    return (
      <div className='container-automobile'>
        {/* <div className = "menustyle" >
               
                <nav className="navbar navbar-expand-md  navbar-dark">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar"> */}
        <div className='menustyle'>
          <nav className='navbar navbar-expand-md  navbar-dark'>
            {!this.state.show ? (
              <button
                className='navbar-toggler'
                onClick={this.toggleNav}
                type='button'
                data-toggle='collapse'
                data-target='#collapsibleNavbar'>
                <span className='navbar-toggler-icon'></span>
              </button>
            ) : (
              <button
                className='navbar-toggler'
                onClick={this.toggleNavFalse}
                type='button'
                data-toggle='collapse'
                data-target='#collapsibleNavbar'>
                <span className='navbar-toggler-icon'></span>
              </button>
            )}
            <div
              id='collapsibleNavbar'
              className={
                this.state.show
                  ? 'collapse navbar-collapse show'
                  : 'collapse navbar-collapse'
              }>
              <ul className='navbar-nav'>
                {menujson &&
                  menujson.map((item) => {
                    var active_menu = Menus.find(
                      (data) =>
                        data.permission_code == item.code &&
                        data.permission == 0,
                    );
                    return (
                      <NavLink
                        className={`${
                          window.location.hash.includes(item.highlight) &&
                          'menu_activelink'
                        }`}
                        onClick={(e) => (active_menu ? this.hideMenu(e) : null)}
                        activeClassName='current'
                        exact={true}
                        to={item.link}>
                        <li className='nav-item'>
                          <div className='menuList' style={{ display: 'flex' }}>
                            <img
                              alt=''
                              src={images[item.icon]}
                              className='dashboaredimage img-fluid'
                            />
                            <a class='nav-link' href='#'>
                              {item.name}
                            </a>
                          </div>
                        </li>
                      </NavLink>
                    );
                  })}

                {/* <NavLink className={`${window.location.hash.includes('/pilots')&&"menu_activelink"}`} to={'/pilots/pilot_master/pilot_details/'}  activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 2 ? images.active_driver : images.driver} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">Pilot</a></div>
                            </li>
                            </NavLink>
                            <NavLink to={'/svc'} activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 3 ? images.svc_grey : images.svc_white} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">SVC</a></div>
                            </li>
                            </NavLink>
                            <NavLink to={'/users'} activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 4 ? images.user_grey: images.user_white} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">Users</a></div>
                            </li>
                            </NavLink>
                            <NavLink className={`${window.location.hash.includes('/booking/')&&"menu_activelink"}`} to={'/booking/add_booking/'}  activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 5 ? images.grey_booking : images.white_booking} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">Bookings</a></div>
                            </li>
                            </NavLink>
                            <NavLink className={`${window.location.hash.includes('/payments/')&&"menu_activelink"}`}  to={'/payments/all_payments/'}  activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 6 ? images.paymeny_grey : images.payment_white} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">Payments</a></div>
                            </li>
                            </NavLink>
                            <NavLink to={'/tickets'}  activeClassName="current">
                            <li class="nav-item">
                                <div className = "menuList" style = {{display:"flex"}}>
                                <img src={this.props.activeMenu == 7 ? images.ticket_grey : images.ticket_white} className = "dashboaredimage"/>
                                <a class="nav-link" href="#">Tickets</a></div>
                            </li>
                            </NavLink> */}
              </ul>
            </div>
          </nav>
        </div>

        <div class='menu-title'>
          <h6 className='service-name'>{this.props.title}</h6>
        </div>
      </div>
    );
  }
}
