import React, { Component } from 'react';
import { Table, Tag, Pagination, Select} from 'antd';
import Image from '../../../helpers/images';
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var zonesHeader = [{'id' : 1,  'city_name' : 'City Name', 'total_count': 'Total Count', 'present' : 'Present', 'absent' : 'Absent','disabled':'Disabled',  'defaulter' : 'Defaulter'}];
var zonesList = [{'id' : 1,  'city_name' : 'chennai North', 'total_count': '120', 'present' : '98', 'absent' : '12', 'disabled':'02', 'defaulter' : '-'},
{'id' : 1,  'city_name' : 'chennai North', 'total_count': '120', 'present' : '98', 'absent' : '12', 'disabled':'02', 'defaulter' : '-'},
{'id' : 1,  'city_name' : 'chennai North', 'total_count': '120', 'present' : '98', 'absent' : '12', 'disabled':'02', 'defaulter' : '-'},
{'id' : 1,  'city_name' : 'chennai North', 'total_count': '120', 'present' : '98', 'absent' : '12', 'disabled':'02', 'defaulter' : '-'},
{'id' : 1,  'city_name' : 'chennai North', 'total_count': '120', 'present' : '98', 'absent' : '12', 'disabled':'02', 'defaulter' : '-'}];
export default class Zones extends Component {
  constructor(props){
    super(props);
    this.state={
      zonesHeader:zonesHeader,
      zonesList:zonesList,
      currentPage : 1,
            pageSize:10,
    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
 
  render() {
    return (
      <div className="zones_main"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                             
                                    <div className="payment-dropdown float-right mr-2 city_drop"> <span className="city_zone">City / Zone:</span> <Select defaultValue="Chad Ellis" onChange={handleChange}>
                             <Option value="Chad Ellis">North Chennai</Option>
                            <Option value="lucy">North Chennai</Option>
                            
                            <Option value="Yiminghe">North Chennai</Option>
                            
                             </Select>
                            </div>
                             
                             
                             </div>
                         </div>
                    </div>

                    <div className="zone_table">
                    <div className="table-list">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.zonesHeader && this.state.zonesHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.city_name}</th>
                        <th>{item.total_count}</th>
                        <th>{item.present}</th>
                        <th>{item.absent}</th>
                        <th>{item.disabled}</th>
                        <th>{item.defaulter}</th>
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.zonesList&&this.state.zonesList.length>0&&this.state.zonesList.map((item,index) => {

                return(
                    <tr>
                       
                       <td>{item.city_name}</td>
                        <td>{item.total_count}</td>
                        <td >{item.present}</td>
                        <td>{item.absent}</td>
                        <td >{item.disabled}</td>
                        <td>{item.defaulter}</td>
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.zonesList&&this.state.zonesList.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
       </div>
    );
  }
}
