import React, {Component} from 'react';
import './styles.css';
import images from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

var userReuestHeader = [{'id' : 1,  'date' : 'Date', 'time': 'Time', 'svc_id' : 'SVC ID', 'svc_name' : 'SVC Name','employee_name':'Employee Name',  'employee_id' : 'Employee ID', 'designation' : 'Designation','mobile_number':'Mobile Number','accept':'Accept','reject':'Reject'}];

class NewUserRequest extends Component{

    constructor(props){

        super(props);

        this.state = {
            userReuestHeader : userReuestHeader,

            userReuestList : [],
             
            currentPage : 1,
            pageSize:10,
            openapproval:false,
            rejectapproval:false,
            approvalID:null,
            rejectID:null,
            employee_name:null,
            fromDate:null,
            toDate:null


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      
      
      loadnewuserRequest=()=>{
        const token = localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,name:this.state.employee_name,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("new user request",obj);
          apiservices.listnewuserrequest(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({userReuestList:response.response, numbercount:calc})
                        console.log("new userr request", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){       
          this.loadnewuserRequest();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadnewuserRequest();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadnewuserRequest(); 
        });
      }
      OpenApproval =(data)=>{
          console.log("open approval",data)
        this.setState({openapproval:true,approvalID:data.id,success_msg:'',error_msg:''});
    
    }
    closeApproval =()=>{
        this.setState({openapproval:false,success_msg:'',error_msg:''});
    }
    OpenRejectApproval =(data)=>{
        this.setState({rejectapproval:true,rejectID:data.id});
    }
    RejectApproval=()=>{
        this.setState({rejectapproval:false});  
    }
    approveUser =()=>{
        const token = localStorage['accesstoken'];
        var obj={id:this.state.approvalID};
          apiservices.acceptnewuserrequest(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                       console.log("approval user",response);
                       setTimeout(() => {
                        this.loadnewuserRequest();
                        this.setState({openapproval:false,})
                       },3000);
                       this.setState({success_msg:response.message})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
    }
    cancelUser =()=>{
        const token = localStorage['accesstoken'];
        var obj={id:this.state.rejectID};
          apiservices.rejectnewuserrequest(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                       console.log("approval user",response);
                       setTimeout(() => {
                        this.loadnewuserRequest();
                        this.setState({rejectapproval:false,})
                       },3000);
                       this.setState({success_msg:response.message})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
    }
    checkvalidations =(data)=>{
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadnewuserRequest();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadnewuserRequest();
        })
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadnewuserRequest();
        });
      }
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
              <div className="search-header booking-mishaps">
                         <div className="row">
                             <div className="col-md-3">
                                   <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for Employee name" className="form-control" name="employee_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={images.green_search} className="img-fluid"/>
                                  </div> 
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkvalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                    
                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.userReuestHeader && this.state.userReuestHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.date}</th>
                                                <th>{item.time}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.employee_name}</th>
                                                <th>{item.employee_id}</th>
                                                 <th>{item.designation}</th>
                                                 <th>{item.mobile_number}</th>
                                                 <th>{item.accept}</th>
                                                 <th>{item.reject}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.userReuestList &&this.state.userReuestList.map((item) => {

                                        return(
                                            <tr>
                                                <td>{moment(item.created_at).format('DD/MM/YY')} </td>
                                                <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                                                <td>ID{item.svc_center_id}</td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.name}</td>
                                                <td>{item.employee_id}</td>
                                                <td>{item.designation_name}</td>
                                                <td>{item.mobile_no}</td>
                                                <td><img src={images.approvel} style={{width:37}} onClick={()=>this.OpenApproval(item)}/></td>
                                                <td><img src={images.cancel_new} style={{width:37}} onClick={()=>this.OpenRejectApproval(item)}/></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.userReuestList&&this.state.userReuestList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
    <Dialog  open={this.state.openapproval}  className="pending_approvals">
                    
                    <div className="">
                   <div className="title_section">
                  <div className="add_approval_text">Approve User Request</div> <div className="approval_close" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeApproval()} /></div>
                </div>
                <div className="approval_content">Are you sure you want to approve this user request?</div>
                {/* <div className="approval_btn_main">
<button className="approval_btn" >Approve</button>
</div> */}
{this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
 <div className="deletebtns_group">
                  <div className="approval_btn_main ">
  <button className="cancel_btn_main" onClick={() => this.closeApproval()}>No, Cancel</button>
  </div>
  <div className="approval_btn_main">
  <button className="approval_btn" onClick={()=>this.approveUser()}>Yes, Approve</button>
  </div>
  </div>
                    </div>

                </Dialog>
                <Dialog  open={this.state.rejectapproval}  className="pending_approvals">
                    
                <div className="">
                <div className="title_section">
                  <div className="add_approval_text">Reject User Request</div> <div className="approval_close" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.RejectApproval()} /></div>
                </div>
                  <div className="approval_content">Are you sure you want to Reject the user?</div>
                  {this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
                  <div className="deletebtns_group">
                  <div className="approval_btn_main ">
  <button className="cancel_btn_main" onClick={() => this.RejectApproval()}>No, Cancel</button>
  </div>
  <div className="approval_btn_main">
  <button className="approval_btn" onClick={()=>this.cancelUser()}>Yes, Reject</button>
  </div>
  </div>
                      </div>

                </Dialog>
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default NewUserRequest;