import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
// import BookingDetails from  '../BookingDetails';
// import BookingList from '../BookingList';
import SettlementAdvice from './SettlementAdvice';
import SettlementDetail from "./SettlementDetail";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class SettlementTabDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {

            pilotMaster : true,
            addDriver : false
        }
    }
    render() {
console.log("SettlementTabDetails"+this.props.match.path );
        return (
            <div>
                {/* {this.state.pilotMaster &&   */}
                
                <div class="add-booking-menu">
                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Row>
                        
                            <Col sm={12}>
                               
                                <Switch>
                 {/* <Route  path={`${this.props.match.path}/booking_details`} render={props => (<BookingDetails {...props} />)} /> */}
                <Route exact  path={`${this.props.match.path}/`} render={props => (<SettlementAdvice {...props} parentProps={this.props} />)} />
                <Route   path={`${this.props.match.path}/settlement_details/:id`} render={props => (<SettlementDetail {...props}/>)} />
                
                
              </Switch>
                            </Col>
                        </Row>
                    {/* </Tab.Container> */}
                </div>
                {/* } */}


               
            </div>

            
        )

    }
  

}


export default SettlementTabDetails;