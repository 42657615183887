import React, { Component } from 'react';
import './styles.css';
import { toast, ToastContainer } from 'react-toastify';
import apiservices from '../../helpers/apiservices';
import images from '../../helpers/images';
class SetPassword extends Component {
  constructor(props) {
    super(props);
    console.log("props",props);
    this.state = {
     password:'',
     confirm_password:'',
     error_msg:null,
      userDetails: null,
      toastId: null,
      registerdata:props.registerdata,
      shopdetails: props.shop_details,
      isPasswordVisible: false,
      isConfirmPasswordVisible:false

    }
  }
  handleSubmit = () => {
    if (this.props.loadverifcation) {
      var obj = { type: 'verification' };
      this.props.loadverifcation(obj)
    }
  }
  forgotSubmit = () => {
    // if(this.props.loadforgot && this.props.forgot){
    // var obj={type:'forgotpassword',forgot:true};
    this.props.loadforgot();
    // }
  }



  
  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      var pw = (e.target.value);
      this.setState({
        score: pw.score
      });
    }

  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }
  handleChange =(e)=>{
    this.setState({ [e.target.name]: e.target.value });
  }
  loginSubmit=()=>{
    var password= /^\S{6,15}$/;
       var confirm_password= /^\S{6,15}$/;
        if (this.state.password == "" || this.state.password == undefined) {
           this.setState({error_msg:"Please enter password !"})
                    } 
         else if (!password.test(this.state.password)) {
          this.setState({error_msg:"Please enter valid password !"})

        } else if (this.state.confirm_password == "" || this.state.confirm_password == undefined) {
          this.setState({error_msg:"Please enter confirm password !"})
           }else if (!confirm_password.test(this.state.confirm_password)) {
            this.setState({error_msg:"Please enter valid confirm password !"})

        } else if (this.state.password != this.state.confirm_password) {
          this.setState({error_msg:"Password do not mismatch, please check again !"})
        } else {
          var obj={mobile_no:this.state.registerdata.mobile_no,newPass:this.state.password,confirmPass:this.state.confirm_password};
                    apiservices.setpassword(obj)
                    .then((response)=>{
                      if(!response.error){
                          if (! toast.isActive(this.toastId)) {
                          this.toastId = toast.success(response.message, {
                          position: toast.POSITION.TOP_RIGHT
                          
                        });
                          }
                          if(this.props.loadlogin){
                          var obj={type:'login'};
                          this.props.loadlogin(obj);
                          }
                        console.log("forgot response",response);
                      }else{
                        this.setState({error_msg:response.message})
                      }
                        
                    })
        }
            
  }
  render() {
    console.log("setpassword",this.state.registerdata)
    return (
        <div className="login_main">
      <div className="passwordcontainer">
        <div className="setpassword">Set Password</div>
        <div className="formdiv setpwd_formdiv">
          <label className="labeltxt">Password</label>
          <div style={{ position: 'relative' }}>
          {this.state.isPasswordVisible ?
              <input
                type='password'
                name="password"
                className="brdrcls"
                onChange={(e) => this.handleChange(e)}
                 /> :
              <input
                type='text'
                name="password"
                className="brdrcls"
                onChange={(e) => this.handleChange(e)}
                 />}
            <img onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })} src={this.state.isPasswordVisible ? images.eye_off : images.eye_on} className="pwdicon" />
          </div>

        </div>
        <div className="formdiv passwordcls setpwd_formdiv">
          <label className="labeltxt">Confirm Password</label>
          <div style={{ position: 'relative' }}>
            {this.state.isConfirmPasswordVisible ?
              <input
                type='text'
                name="confirm_password"
                onChange={(e) => this.handleChange(e)}
                className="brdrcls"
                 /> :
              <input
                type='password'
                name="confirm_password"
                className="brdrcls"
                onChange={(e) => this.handleChange(e)}
                 />}
            <img onClick={() => this.setState({ isConfirmPasswordVisible: !this.state.isConfirmPasswordVisible })} src={this.state.isConfirmPasswordVisible ? images.eye_on : images.eye_off} className="pwdicon" />

          </div>
        </div>
        <div className="error_msg">{this.state.error_msg}</div>

        <div style={{ padding: 20, marginTop: 20 }}>
          <button className="loginbtn" onClick={() => this.loginSubmit()}>CONFIRM</button>
        </div>


      </div>
      </div>
    )
  }
}
export default SetPassword;
