import React, {Component} from 'react';
import './styles.css';
import images from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';


const { RangePicker } = DatePicker;

var svctargetheader = [{'id' : 1, 'svc_name' : 'SVC Name','svc_id' : 'SVC ID', 'master_id':'Master ID',  'bookinglast_month' : 'Total No. of Bookings Last Month', 'booking_acheived' : 'Booking Achieved','set_target':'Set Target'}];

class Targets extends Component{

    constructor(props){

        super(props);

        this.state = {
            svctargetheader : svctargetheader,
            svctargetList:[],
            currentPage : 1,
            pageSize:10,
            targetID:null,
            openDialog:false,
            fields:{
                set_target:{validations:[{name:'required'},{name:'allowNumaricOnly'}],error:null,value:''},
              },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadSVCTargets =()=>{
        const token = localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        console.log("obj", obj);
          apiservices.listsvctargets(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({svctargetList:response.response, numbercount:calc})
                        console.log("target reponse", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
          this.loadSVCTargets();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadSVCTargets();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadSVCTargets(); 
        });
      }
      
      checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      popupSubmit =(data)=>{
          var fields =this.state.fields;
          var getallkeys=Object.keys(fields);
          getallkeys.map((obj)=>{
          var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
          fields[obj].error=checkstatus.msg;
          })
          var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
          if(filtererrors.length>0){
            //errorblock
            this.setState({error:true})
          }else{
            //successblock
            this.setState({error:false});
            this.updateTarget();
            
          // alert('hii');
          }
          this.setState({fields});
      }
      editTarget =(data)=>{
          console.log("data",data);
          var fields=this.state.fields;
          fields['set_target'].value=data.target;
          this.setState({openDialog:true,targetID:data.id,fields});
      }
      closeDialog =()=>{
          var fields=this.state.fields;
          fields['set_target'].value='';
          fields['set_target'].error='';
          this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
      }
      updateTarget =() =>{
        const token = localStorage['accesstoken'];
        var obj={id:this.state.targetID,target:this.state.fields.set_target.value};
        console.log("obj", obj);
          apiservices.updatesvctargets(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        setTimeout(() => {
                            this.setState({openDialog:false});
                            this.loadSVCTargets();
                            this.closeDialog();
                          }, 3000);
                           this.setState({success_msg:response.message})
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
render(){

    return(
        <div className="target_main">
              <div className="container-fluid">
             <div className="svc_target_title">SVC Targets</div>


                    <div className="table-list" style={{marginBottom:40}}>


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.svctargetheader && this.state.svctargetheader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.bookinglast_month}</th>
                                                <th>{item.booking_acheived}</th>
                                                <th>{item.set_target}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.svctargetList &&this.state.svctargetList.map((item) => {

                                        return(
                                            <tr>
                                                 <td>{item.centre_name}</td>
                                                <td>ID{item.id}</td>
                                                <td>{item.monthly_booking}</td>
                                                <td>{item.monthly_achieved}</td>
                                                <td>{item.target} <img src={images.edit_icon} style={{width:30,cursor:'pointer'}} onClick={()=>this.editTarget(item)}/></td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.svctargetList&&this.state.svctargetList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>
                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />

                     
    </div>
    <Dialog  open={this.state.openDialog}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    
                    <div className="modalcntntcls">
                   <div className="delete_text">
                      Update  Set Target
                   </div>
                   <div>
                      
                   <div className="form-group">
                           <label style={{color:'#0A5C95'}}>
                           Set Target
                           </label>
  
                           <input type="text" className="form-control input_paused" name="set_target" value={this.state.fields.set_target.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                         <span className="error_msg_adddealer">{this.state.fields['set_target'].error&&this.state.fields['set_target'].error}</span> 
                           
  
                       </div>
                       
                     {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                   }
                 {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    
                    </Dialog>
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default Targets;