import React, { Component } from "react";
import images from "../../../helpers/images";
import { Table, Tag, Space, Select, Pagination } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import Images from '../../../helpers/images';
import "./styles.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import apiservices from '../../../helpers/apiservices';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../../helpers/validationfunction';
const { Option } = Select;
var SvcUserListArray = [{ ID: "ID1256", Name: "Craig Schneider", mobileNumber: 9837643023, emailID: "craigschneider@gmail.com", designation: "Customer Relations Manager" }];

export default class UserList extends Component {
  constructor(props) {
    console.log("this props", props);
    super(props)
    this.state = {
      SvcUserListArray: SvcUserListArray,
      tempdata: [],
      currentPage: 1,
      pageSize: 10,
      name_search: null,
      editID: null,
      deleteID:null,
      loading: false,
      editOpen: false,
      designation: null,
      loadstatus:false,
      rejectapproval:false,
      fields: {
        username: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
        city: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
        designation: { validations: [{ name: 'required' }], error: null, value: '' },
        mobile_number: { validations: [{ name: 'required' }, { name: 'mobile' }, { name: 'Noemptyspace' }], error: null, value: '' },
        email_id: { validations: [{ name: 'required' }, { name: 'email' }, { name: 'Noemptyspace' }], error: null, value: '' },
        employee_id: { validations: [{ name: 'alphaNumaricOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
        salutation: { validations: [], error: null, value: 'Mr' }

      }
    };
    // this.loadDesignationDropdown();
  }
  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }
  onchangePagination = (page, pageSize) => {
    this.setState({ currentPage: page }, function () {
      this.loaduserlist();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.loaduserlist();
    })
  }
  loadDesignationDropdown = () => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.designationDropdown(obj, token)
      .then((response) => {
        if (!response.error) {
          console.log("designation",response)
          this.setState({ designation: response.response })
          var fields = this.state.fields;

          fields['designation'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })

        } else {
          //   this.setState({error_msg:response.message})
        }

      })
  }
  loaduserlist = () => {
    this.setState({ loading: true,loadstatus:false })
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, name: this.state.name_search };
    console.log("loadscvlist", obj)
    apiservices.listallactiveadminuser(obj, token)
      .then((response) => {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        this.setState({ loading: false })
        if (!response.error) {
          this.setState({ tempdata: response.response, numbercount: calc,loadstatus:true })
          console.log("list svc", response)

        } else {
          this.setState({ error_msg: response.message })
        }

      })
  }
  componentWillMount() {
    this.loaduserlist();
    this.loadDesignationDropdown();
    // this.props.sendsvcid(svc_id);
  }
  checkvalidations = (value, key) => {


    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0) {
      this.setState({ error: true })
      //errorblock
    } else {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  searchname = (e) => {
    var value = e.target.value;
    console.log("city search", value);
    // this.loadsvclist();
    if (value == '') {
      value = null;
      // 
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.loaduserlist();
    });

  }
  sortAscending = () => {
    console.log("sortAscending");
    const { tempdata } = this.state;
    tempdata.sort((a, b) => a - b).reverse()
    this.setState({ tempdata })
  }

  sortDescending = () => {
    console.log("sortDescending");
    const { tempdata } = this.state;
    tempdata.sort((a, b) => a - b).reverse()
    this.setState({ tempdata })
  }
  deleteUserList = () => {
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.deleteID };
    apiservices.disableadminuser(obj, token)
      .then((response) => {
        var self=this;
        if (!response.error) {
          setTimeout(() => {
            self.loaduserlist();
            self.setState({rejectapproval:false})
          }, 3000);
         
          this.setState({ success_msg: response.message })
          // console.log("list svc", response)

        } else {
          this.setState({ error_msg: response.message })
        }

      })
  }
  editUser = (data) => {
    
    console.log("data",data)
    const token = localStorage['accesstoken'];
    var obj = { id: data.id };
    apiservices.getadminuserbyid(obj, token)
      .then((response) => {
        if (!response.error) {
          console.log("edit response",response)
          // var editUserResponce = response.response;
          var fields = this.state.fields;
          fields['username'].value = response.response.name;
          fields['designation'].value = response.response.designation_id;
          fields['mobile_number'].value = response.response.mobile_no;
          fields['email_id'].value = response.response.email;
          fields['employee_id'].value = response.response.employee_id;
          fields['salutation'].value = response.response.salutation;
          fields['city'].value = response.response.city;
          this.setState({ fields })
          // this.setState({ popup_success_msg: response.message })

        } else {
          // this.setState({ popup_error_msg: response.message })
        }

      })
    this.setState({ editOpen: true, editID: data.id });
          // var editUserResponce = response.response;
          
    }
  updateUser = () => {
    this.setState({ error: false });
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.editID,
      designation_id: this.state.fields.designation.value,
      salutation: this.state.fields.salutation.value,
      name: this.state.fields.username.value,
      mobile_no: this.state.fields.mobile_number.value,
      email: this.state.fields.email_id.value,
      employee_id: this.state.fields.employee_id.value,
      city:this.state.fields.city.value
    };
    console.log("update user",obj);
    apiservices.updateadminuser(obj, token)
      .then((response) => {
        if (!response.error) {
          console.log("update user data",response)
          this.setState({ popup_success_msg: response.message })
          this.loaduserlist();
          setTimeout(() => {
            this.setState({editOpen:false,popup_success_msg:false})
          }, 1000);
        } else {
          this.setState({ popup_error_msg: response.message })
        }

      })
  }
  OpenRejectApproval =(data)=>{
    this.setState({rejectapproval:true,deleteID:data});
}
RejectApproval=()=>{
    this.setState({rejectapproval:false});  
}
  render() {
    const { designation,service_center } = this.state;
    return (
      <div className="svc_user_list ">
        <input className="searchinput left_aligned" type="text" placeholder="Search name" name="name_search" onChange={(e) => this.searchname(e)} />
        <img src={images.green_search} className="searchimage" />
        <div className="tablediv activeUserList">
          <table class="table table-hover">
            <thead>
              {this.state.SvcUserListArray && this.state.SvcUserListArray.map((obj) => {
                return (<tr>
                  <th>ID</th>
                  <th >Name</th>
                  <th>Mobile Number</th>
                  <th >E-mail ID</th>
                  <th >Designation</th>
                  <th >City</th>
                  <th>Edit</th>
                  <th >Delete</th>
                </tr>
                )
              })}
            </thead>
            <tbody>
              {this.state.tempdata && this.state.tempdata.length > 0 && this.state.tempdata.map((obj) => {
                return (<tr>
                  <td >ID{obj.id}</td>
                  <td > {obj.name}</td>
                  <td>{obj.mobile_no}</td>
                  <td >{obj.email}</td>
                  <td >{obj.designation_name}</td>
                  <td >{obj.city}</td>
                  <td>
                    {/* <Link to={{
  pathname: `${this.props.parentProps.match.path}/add_user/${obj.id}/${this.props.match.params.svc_id}`,}}>
   */}

                    <img src={images.EditImg} style={{ width: 38, cursor: 'pointer' }} onClick={() => this.editUser(obj)} />
                    {/* </Link> */}
                  </td>
                  <td><img src={images.delete_icon} style={{ width: 38, cursor: 'pointer' }} onClick = {()=> this.OpenRejectApproval(obj.id)}/></td>
                </tr>);
              })}
            </tbody>

          </table>
          {this.state.tempdata && this.state.tempdata.length == 0 ?
          <div className="no_records_found">No Records Found</div>:''
        }
        </div>
        
        
       

        {/* {this.state.tempdata.length>this.state.pageSize&& */}
        {this.state.tempdata.length != 0 &&
          <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
            pageSize={this.state.pageSize} itemRender={this.itemRender}
          />
        }
        {/* } */}
        <Dialog open={this.state.editOpen} className="shopbox">
          <div className="modalclosecls" >
            <img src={images.popup_clseicon} style={{ width: '12px', cursor: 'pointer' }} onClick={() => this.setState({ editOpen: false,popup_error_msg:"",popup_success_msg:"" })} /></div>
          <div className="svceditUser">
            Edit User
            </div>
          <div className="dealer-body svc-user-edit">
            <div>
              <div className="form-group">
                <div class="form-inline">
                  <label>Name</label>
                  <input type="text" className="form-control" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['username'].error && this.state.fields['username'].error}</span>
                </div>
                <div class="form-inline">
                  <label>Mobile Number</label>
                  <input type="text" className="form-control" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error && this.state.fields['mobile_number'].error}</span>
                </div>
              </div>
              <div className="form-group">
                <div class="form-inline">
                  <label>E-mail ID</label>
                  <input type="text" className="form-control" name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['email_id'].error && this.state.fields['email_id'].error}</span>
                </div>
                <div class="form-inline">
                  <label>Employee ID (Optional)</label>
                  <input type="text" className="form-control" name="employee_id" value={this.state.fields.employee_id.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['employee_id'].error && this.state.fields['employee_id'].error}</span>
                </div>
              </div>
              <div className="form-group add-user-destination svc_user_tag">
                <div class="form-inline">
                  <label>Designation</label>
                  {/* <Select name="designation" value={[this.state.fields.designation.value]} onChange={(value) => this.checkvalidations(value, 'designation')}>
                    {designation && designation.length > 0 && designation.map((item) => {
                      return (
                        <Option value={item.id}>{item.designation_name}</Option>
                      )
                    })}

                  </Select> */}
                   <Select value={[parseInt(this.state.fields.designation.value)]}  onChange={(value) => this.checkvalidations(value,'designation')} name="designation" >
                          {designation&&designation.length>0&&designation.map((item)=>{
                              return(
                              <Option value={item.id}>{item.designation_name}</Option>
                              )
                            })}
                           </Select> 
                  <img src={Images.dropdown} style={{ position: "absolute", marginTop: 14, right: "19%", width: 14 }} />
                </div>
              </div>
              <div className="form-group">
              <div class="form-inline">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city" value={this.state.fields.city.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                    <span className="error_msg_adddealer">{this.state.fields['city'].error && this.state.fields['city'].error}</span>
                                </div>
              </div>
            </div>

              {this.state.popup_error_msg ?
                <div className="failure_msg">{this.state.popup_error_msg}</div> : ''
              }

              {this.state.popup_success_msg ?
                <div className="success_msg">{this.state.popup_success_msg}</div> : ''
              }
            </div>
            <button disabled={this.state.popup_success_msg} className="btn btn-dark-blue next-button popupbuttonedit" onClick={this.updateUser}>Update</button>

        </Dialog>
        <Dialog  open={this.state.rejectapproval}  className="pending_approvals">
                    
                    <div className="">
                    <div className="title_section">
                      <div className="add_approval_text">Remove User List</div> <div className="approval_close" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.RejectApproval()} /></div>
                    </div>
                      <div className="approval_content">Are you sure you want to Remove the user?</div>
                      {this.state.popup_error_msg ?
                <div className="failure_msg">{this.state.error_msg}</div> : ''
              }

              {this.state.popup_success_msg ?
                <div className="success_msg">{this.state.success_msg}</div> : ''
              }
                      <div className="deletebtns_group">
                      <div className="approval_btn_main ">
      <button className="cancel_btn_main" onClick={() => this.RejectApproval()}>No, Cancel</button>
      </div>
      <div className="approval_btn_main">
      <button className="approval_btn"  onClick={()=>this.deleteUserList()}>Yes, Reject</button>
      </div>
      </div>
                          </div>
    
                    </Dialog>
      </div>
    )
  }
}