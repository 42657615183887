import React, { Component } from 'react';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { DatePicker ,Select,Upload,message,Button} from 'antd';
import { Dialog, DialogContent, DialogTitle, } from "@material-ui/core";
import ValidationLibrary from '../../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import S3FileUpload from "react-s3";
import Aws from "../../../helpers/awskeys";
const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
export default class Checklist extends Component {
    constructor(props){
        super(props);
        this.state={
            checklist:[],
            fields:{
                title:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                upload_image:{validations:[{name:'required'}],error:null,value:''},
            },
            filesUpload:"",
            filenewlist:[],
            fileupload_error:null,
            addlistOpen:false,
            buttondisabled:false
        }
    }
    loadCheckList = () =>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.listchecklist(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      this.setState({checklist:response.response})
                      console.log("check list",response)
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    componentWillMount(){
        this.loadCheckList();
    }
    deleteList=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={id:data};
        console.log("actve", obj);     
        apiservices.deletechecklist(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                      this.setState({checklistMsg:''}) 
                    },3000)
                    this.loadCheckList();
                      this.setState({checklistMsg:response.message})
                    
                      console.log("check list",response)
                    }
                  else{
                    this.setState({checklistMsg:response.message})
                  }
              })
console.log("delete id",data);
    }
    checkvalidations=(value,key)=>{

        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
      
          //successblock
        }
        this.setState({fields});
      }
      submitPopup= async()=>{
        
        var fields =this.state.fields;
        
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          var fileList=this.state.fields.upload_image.value;
        console.log("else part",fileList);
        var temparray=[];
        this.setState({buttondisabled:true});
        for(var i in fileList){
          var response=await S3FileUpload.uploadFile(fileList[i], config);
          console.log("response",response);
          temparray.push(response.location);
          
          if(fileList.length-1==i){
            // console.log();
            var apiimagefile=temparray.join(',');
            this.addChecklist(apiimagefile);
          }
        }
        }
        this.setState({fields});
  
      }
      addChecklist=(data)=>{
        console.log("console");
        this.setState({buttondisabled:true})
        const token=localStorage['accesstoken'];
        var obj={name:this.state.fields.title.value,icon:data};
        console.log("booking svc details obj", obj);   
        apiservices.addchecklist(obj,token)
              .then((response)=>{
                console.log("check list",response)
                  var self=this;
                  if(!response.error){ 
                    this.setState({checklistaddMsg:response.message})
                    setTimeout(() => {
                        self.setState({addlistOpen:false})
                        self.loadCheckList();
                      }, 2000);
                   
                  }else{
                    this.setState({buttondisabled:false})
                    this.setState({checklistaddMsg:response.message})
                  }
       
                })
      }
      checklistOpen=()=>{
        var fields=this.state.fields;
        fields['title'].value='';
        this.setState({addlistOpen:true,buttondisabled:false,filenewlist:[],fields,checklistaddMsg:''})
    }
    deleteClose=()=>{
        var fields=this.state.fields;
        fields['title'].value='';
        this.setState({addlistOpen:false,filenewlist:[],fields,checklistaddMsg:''})
    }
  render() {
    var self=this;
    var temparray=[];
    const props = {
      name: 'file',
      accept:'image/*',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      fileList:self.state.filenewlist,
      showUploadList: {
       
        showRemoveIcon: true,
        removeIcon: <img src={Images.file_remove} className="file_remove" />,
      },
      // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
      beforeUpload:(file)=>{
        console.log("filelist",self.state.filenewlist);
        var filenewlist=self.state.filenewlist;
        filenewlist.push(file);
        if(self.state.filenewlist.length<2){
          
          self.setState({filenewlist},function(){
            self.checkvalidations(filenewlist,'upload_image');
            self.props.sendImages&&self.props.sendImages(filenewlist);
          });
          
        }else {
          filenewlist=filenewlist.splice(0,1);
          self.setState({fileupload_error:'Max Files Upload is 1'})
          self.setState({filenewlist});
        }
        console.log("beforeuploadfile",file)
        // return true;
      },
      onChange:(info)=> {
        
        var filenewlist=self.state.filenewlist;
        if(info.file.status=='removed'){
          var findindex=filenewlist.findIndex((obj)=>obj.uid==info.file.uid);
          filenewlist.splice(findindex,1);
          self.setState({filenewlist});
        }
        
       
        console.log("delete info",info);
      }
    }
    
    return (
      <div className="checklist_main"> 
          <div className="container">
              <div className="addbtnmain">
                  <button className="add_new_btn" onClick={()=>this.checklistOpen()}>Add New</button>
              </div>
              {this.state.checklistMsg&&this.state.checklistMsg?
                 <div className="failure_msg">{this.state.checklistMsg&&this.state.checklistMsg}</div> :''
                   }
                 {this.state.checklistMsg&&!this.state.checklistMsg?
                 <div className="success_msg">{this.state.checklistMsg&&this.state.checklistMsg}</div> :''
                 }
              {this.state.checklist&&this.state.checklist.length>0&&this.state.checklist.map((item)=>{
                 return(
                    <div>
                        <div  className="content">
                  <div className="content_box">
                      <div className="content_list"><img src={item.icon} />
                      <div className="checklist_title">{item.name}</div>
                      </div>
                  </div>
                  <div className="delete_content">
                      <img src={Images.delete_icon} onClick={()=>this.deleteList(item.id)}/>
                  </div>
                 
              </div>
             
                  </div>
                 )

              })}
              {this.state.checklist&&this.state.checklist.length==0&&
              <div>No Records Found </div>
              }
              
          </div>
          <Dialog  open={this.state.addlistOpen}  className="shopbox upload-invoice">
                    <div className="modalclosecls" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}onClick={() => this.deleteClose()} /></div>
                    <div className="modalcntntcls">
                   <div className="delete_text">
                   Add Checklist Item
                   </div>
                    
                       <div className="form-group">
                           <label>Enter the Title Name</label>
                           <input type="text" className="form-control" name="title" value={this.state.fields.title.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
                            <span className="error_msg_adddealer">{this.state.fields['title'].error&&this.state.fields['title'].error}</span>
                           
                       </div>
                        <div className="form-group">
                        <label>Upload Image <span style={{color:'red',marginLeft:10}}>{this.state.fileupload_error}</span></label>
                        <div style={{width:'100%'}}>
                         <Dragger {...props} className="checklist_upload" >
                            <div><button className="upload_btn">Upload Files</button></div>
                            <p className="ant-upload-text"></p>
                            <p className="ant-upload-hint">
                            Files can be in .jpg or .pdf format
                            </p>
                        </Dragger>
                        
                        <span className="error_msg_driver">{this.state.fields['upload_image'].error && this.state.fields['upload_image'].error}</span> 
                        </div>
                          
                        </div>
                      
                        {this.state.checklistaddMsg&&this.state.checklistaddMsg?
                 <div className="failure_msg">{this.state.checklistaddMsg&&this.state.checklistaddMsg}</div> :''
                   }
                 {this.state.checklistaddMsg&&!this.state.checklistaddMsg?
                 <div className="success_msg">{this.state.checklistaddMsg&&this.state.checklistaddMsg}</div> :''
                 }
                     <div className="form-group submit-btns" style={{paddingBottom:20}}><button disabled={this.state.buttondisabled} className="btn btn-blue"  onClick={()=>this.submitPopup()}  >Submit </button></div>
                   
                  </div>
                    
                   
                    
                     
                </Dialog>
      </div>
    );
  }
}
