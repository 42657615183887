import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';


var statusarray = [
    { id: 1, status: "Active",  color: "#06AF90" },
    // { id: 2, status: "Upcoming", color: "#632AE3E6" },
    { id: 2, status: "Cancelled", color: "#F0144F" },
    { id: 3, status: "Completed", color: "#0AB3E5" },
    { id: 4, status: "Re Assigned",color:"#E39600"},
    { id: 5, status: "In Request",color:"#F200F2"},
    { id: 6, status: "Paused",color:"#632AE3E6"},
    { id: 7, status: "Un Assigned",color:"#ADB402"},
    { id: 8, status: "Re Scheduled",color:"#E39600"},
    { id: 9, status: "Payment Await",color:"#3B42FF"},
    { id: 10, status: "Payment Completed",color:"#00B546E6"}
  ];
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;
var bookingHeader = [{'id' : 1,  'svc_name' : 'SVC Name', 'customer_name': 'Customer Name', 'vehicle_number' : 'Vehicle Number', 'booking_id' : 'Booking ID',  'booking_type' : 'Booking Type', 'slot' : 'Slot', 'ambassador' : 'Ambassador', 'booking_status' : 'Booking Status'}];
class SvcDetailsBooking extends Component{

    constructor(props){

        super(props);

        this.state = {
            bookingHeader : bookingHeader,

            bookingList : [],
             
            currentPage : 1,
            pageSize:10


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadSVCBookingDetails =()=>{
        const token = localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        console.log("obj", obj);
          this.props.sendid(obj.id);
          apiservices.listsvcbooking(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({bookingList:response.response, numbercount:calc})
                        console.log("svcbooking reponse", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
          this.loadSVCBookingDetails();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadSVCBookingDetails();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadSVCBookingDetails(); 
        });
      }
      generateOrderProperties = (status, type) => {
        var filterColor = statusarray.filter((obj) => obj.status == status);
    
        switch (type) {
          case "color":
            return filterColor.length > 0 ? filterColor[0].color : "#000";
            break;
          
      };
    }
render(){

    return(
        <div>
              <div className="container">
             <div className="search-header booking-mishaps" style={{marginTop:10}}>
                         <div className="row svc_booking_row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.bookingHeader && this.state.bookingHeader.map((item)=> {
                                        return(
                                           
                                                 <tr> 
                                                <th>{item.svc_name}</th>
                                                <th>{item.customer_name}</th>
                                                <th>{item.vehicle_number}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.booking_type}</th>
                                                <th>{item.slot}</th>
                                                <th>{item.ambassador}</th>
                                                <th>{item.booking_status}</th>
                                            </tr>
                                         
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.bookingList &&this.state.bookingList.map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.centre_name}</td>
                                                <td>{item.customer_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>ID{item.id}</td>
                                                <td>{item.booking_type}</td>
                                                <td>{item.slot_time}</td>
                                                <td>{item.driver_first_name} {item.driver_last_name}</td>
                                                <td style={{
                              color: this.generateOrderProperties(
                                item.status,
                                "color"
                              ),
                            }}>{item.status}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.bookingList&&this.state.bookingList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default SvcDetailsBooking;