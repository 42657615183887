import React, { Component } from 'react';
import images from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import apiservices from '../../helpers/apiservices';
export default class UpdateSvcDealer extends Component {
  constructor(props) {
    super(props);
    console.log('props', props);
    this.state = {
      responsefailure: false,
      responseSuccess: false,
      responsemessage: '',
      detailupdate: false,
      fields: {
        dealer_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        email_id: {
          validations: [
            { name: 'required' },
            { name: 'email' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        vehicle_brand: {
          validations: [
            { name: 'required' },
            { name: 'alphaNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        vehicle_type: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        service_centre: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        contact_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'mobile' },
          ],
          error: null,
          value: '',
        },
        address: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        city: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        pincode: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'zipcode' },
          ],
          error: null,
          value: '',
        },
        pan_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        tan_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        gst_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        setup_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        prepaid_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pickup_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        drop_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pickup_drop_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pickupdrop_distance_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pickupdrop_double_distance: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pickupdrop_noshow_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        convenience_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        chauffeur_doubledistance_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        chauffeur_doubledistance_kms: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        singleline_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        singleline_doubledistance_fee: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        singleline_distance: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        tripleline_amount: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        tripleline_distance: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        slab1: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        slab2: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        slab3: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        slab4: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        amount_transaction_charge: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        cancellation_charge: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        full_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        mobile_number: {
          validations: [
            { name: 'required' },
            { name: 'mobile' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        dealer_code: {
          validations: [
            { name: 'required' },
            { name: 'alphaNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        contact_emailid: {
          validations: [
            { name: 'required' },
            { name: 'email' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        // singlepnt_contactname: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
        // singlepnt_contactnumber: { validations: [{ name: 'required' }, { name: 'mobile' }, { name: 'Noemptyspace' }], error: null, value: '' }
      },
    };
  }

  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });
    }
    this.setState({ fields });
  };
  Service_next1 = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
    }
    this.setState({ fields });
  };
  componentDidMount() {
    var dealer_id = this.props.match.params.id;
    // console.log("dealer_id",dealer_id);
    this.setState({
      responseSuccess: false,
      responsefailure: false,
      responsemessage: '',
      detailupdate: false,
    });
    this.getDealerDetails(dealer_id);
    // if (this.props.dealerDetailsID != 0) {
    //   this.getDealerDetails(this.props.dealerDetailsID);
    // }
  }
  componentWillReceiveProps(props) {
    var dealer_id = props.match.params.id;
    this.getDealerDetails(dealer_id);
    // console.log("will rceive prop",dealer_id);
  }

  getDealerDetails = (dealer_id) => {
    const token = localStorage['accesstoken'];
    var dealerobj = {};
    dealerobj.id = dealer_id;
    apiservices.svcDealerDetails(dealerobj, token).then((response) => {
      var fields = this.state.fields;
      fields['dealer_name'].value = response.response.dealer_name;
      fields['email_id'].value = response.response.dealer_email;
      fields['vehicle_brand'].value = response.response.vehicle_brand;
      fields['vehicle_type'].value = response.response.vehicle_type;
      fields['service_centre'].value = response.response.no_of_service_center;
      fields['contact_number'].value = response.response.dealer_number;
      fields['address'].value = response.response.address;
      fields['city'].value = response.response.city;
      fields['pincode'].value = response.response.pincode;
      fields['pan_number'].value = response.response.pan_number;
      fields['tan_number'].value = response.response.tan_number;
      fields['gst_number'].value = response.response.gst_number;
      fields['setup_fee'].value = response.response.setup_fee;
      fields['prepaid_amount'].value = response.response.prepaid_amount;
      fields['pickup_amount'].value = response.response.pickup_amount;
      fields['drop_amount'].value = response.response.dropoff_amount;
      fields['pickup_drop_amount'].value =
        response.response.pickup_dropoff_amount;
      fields['pickupdrop_distance_fee'].value =
        response.response.pickup_dropoff_double_fee;
      fields['pickupdrop_double_distance'].value =
        response.response.pickup_dropoff_double_distance;
      fields['pickupdrop_noshow_fee'].value =
        response.response.pickup_dropoff_no_show_fee;
      fields['convenience_fee'].value = response.response.chauffeur_fee;
      fields['chauffeur_doubledistance_fee'].value =
        response.response.chauffeur_double_fee;
      fields['chauffeur_doubledistance_kms'].value =
        response.response.chauffeur_double_distance;
      fields['singleline_amount'].value = response.response.single_line_fee;
      fields['singleline_doubledistance_fee'].value =
        response.response.single_line_double_fee;
      fields['singleline_distance'].value =
        response.response.single_line_distance;
      fields['tripleline_amount'].value = response.response.triple_line_fee;
      fields['tripleline_distance'].value =
        response.response.triple_line_distance;
      fields['slab1'].value = response.response.slab_1;
      fields['slab2'].value = response.response.slab_2;
      fields['slab3'].value = response.response.slab_3;
      fields['slab4'].value = response.response.slab_4;
      fields['amount_transaction_charge'].value =
        response.response.transaction_charge;
      fields['cancellation_charge'].value = response.response.cancel_charge;
      fields['full_name'].value = response.response.contact_name;
      fields['mobile_number'].value = response.response.contact_number;
      fields['dealer_code'].value = response.response.dealer_code;
      fields['contact_emailid'].value = response.response.contact_email;
      // fields['singlepnt_contactname'].value=response.response.single_point_name;
      // fields['singlepnt_contactnumber'].value=response.response.single_point_number;
      this.setState({ fields: fields });
    });
  };

  displayNextDetails = () => {
    const { fields } = this.state;
    var dealer_id = this.props.match.params.id;
    const token = localStorage['accesstoken'];
    var obj = {
      id: dealer_id,
      dealer_email: fields.email_id.value,
      vehicle_brand: fields.vehicle_brand.value,
      dealer_name: fields.dealer_name.value,
      vehicle_type: fields.vehicle_type.value,
      no_of_service_center: fields.service_centre.value,
      dealer_number: fields.contact_number.value,
      address: fields.address.value,
      city: fields.city.value,
      pincode: fields.pincode.value,
      pan_number: fields.pan_number.value,
      tan_number: fields.tan_number.value,
      gst_number: fields.gst_number.value,
      setup_fee: fields.setup_fee.value,
      prepaid_amount: fields.prepaid_amount.value,
      pickup_amount: fields.pickup_amount.value,
      dropoff_amount: fields.drop_amount.value,
      pickup_dropoff_amount: fields.pickup_drop_amount.value,
      pickup_dropoff_double_fee: fields.pickupdrop_distance_fee.value,
      pickup_dropoff_double_distance: fields.pickupdrop_double_distance.value,
      pickup_dropoff_no_show_fee: fields.pickupdrop_noshow_fee.value,
      chauffeur_fee: fields.convenience_fee.value,
      chauffeur_double_fee: fields.chauffeur_doubledistance_fee.value,
      chauffeur_double_distance: fields.chauffeur_doubledistance_kms.value,
      single_line_fee: fields.singleline_amount.value,
      single_line_double_fee: fields.singleline_doubledistance_fee.value,
      single_line_distance: fields.singleline_distance.value,
      triple_line_fee: fields.tripleline_amount.value,
      triple_line_distance: fields.tripleline_distance.value,
      slab_1: fields.slab1.value,
      slab_2: fields.slab2.value,
      slab_3: fields.slab3.value,
      slab_4: fields.slab4.value,
      transaction_charge: fields.amount_transaction_charge.value,
      cancel_charge: fields.cancellation_charge.value,
      contact_name: fields.full_name.value,
      contact_number: fields.mobile_number.value,
      contact_email: fields.contact_emailid.value,
      dealer_code: fields.dealer_code.value,
      // single_point_name:fields.singlepnt_contactname.value,
      // single_point_number:fields.singlepnt_contactnumber.value
    };

    apiservices.updatedealer(obj, token).then((response) => {
      if (!response.error) {
        this.setState({
          responsemessage: response.message,
          responseSuccess: true,
        });
        // this.props.sendupdatedetails()
      } else {
        this.setState({
          responsemessage: response.message,
          responsefailure: true,
        });
      }
    });
  };
  render() {
    return (
      <div>
        <div className='svc-dealer-sec'>
          <div className='dealer-master'>
            <div className='dealer-update-head'>1. Dealer Master Details</div>
            <div className='dealer-body'>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>Dealer Name *</label>
                  <input
                    type='text'
                    className='form-control'
                    name='dealer_name'
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                    value={this.state.fields.dealer_name.value}
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['dealer_name'].error &&
                      this.state.fields['dealer_name'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>E-mail ID</label>
                  <input
                    type='text'
                    className='form-control'
                    name='email_id'
                    value={this.state.fields.email_id.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['email_id'].error &&
                      this.state.fields['email_id'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Vehicle Brand</label>
                  <input
                    type='text'
                    className='form-control'
                    name='vehicle_brand'
                    value={this.state.fields.vehicle_brand.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['vehicle_brand'].error &&
                      this.state.fields['vehicle_brand'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Vehicle Type</label>
                  <input
                    type='text'
                    className='form-control'
                    name='vehicle_type'
                    value={this.state.fields.vehicle_type.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['vehicle_type'].error &&
                      this.state.fields['vehicle_type'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>No.of Showrooms / Service Center</label>
                  <input
                    type='text'
                    className='form-control'
                    name='service_centre'
                    value={this.state.fields.service_centre.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['service_centre'].error &&
                      this.state.fields['service_centre'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Contact Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='contact_number'
                    value={this.state.fields.contact_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['contact_number'].error &&
                      this.state.fields['contact_number'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <label>Address</label>

                <textarea
                  row='5'
                  className='form-control'
                  name='address'
                  value={this.state.fields.address.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }></textarea>
                <span className='error_msg_adddealer'>
                  {this.state.fields['address'].error &&
                    this.state.fields['address'].error}
                </span>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Town / City</label>
                  <input
                    type='text'
                    className='form-control'
                    name='city'
                    value={this.state.fields.city.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['city'].error &&
                      this.state.fields['city'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Pincode</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pincode'
                    value={this.state.fields.pincode.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pincode'].error &&
                      this.state.fields['pincode'].error}
                  </span>
                </div>
              </div>
            </div>
            <div className='dealer-update-head'>2. Payment Details</div>
            <div className='dealer-body'>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>PAN Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pan_number'
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                    value={this.state.fields.pan_number.value}
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pan_number'].error &&
                      this.state.fields['pan_number'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>TAN Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='tan_number'
                    value={this.state.fields.tan_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['tan_number'].error &&
                      this.state.fields['tan_number'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>GST Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='gst_number'
                    value={this.state.fields.gst_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['gst_number'].error &&
                      this.state.fields['gst_number'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Setup Fee</label>
                  <input
                    type='text'
                    className='form-control'
                    name='setup_fee'
                    value={this.state.fields.setup_fee.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['setup_fee'].error &&
                      this.state.fields['setup_fee'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Prepaid Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='prepaid_amount'
                    value={this.state.fields.prepaid_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['prepaid_amount'].error &&
                      this.state.fields['prepaid_amount'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Pickup Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pickup_amount'
                    value={this.state.fields.pickup_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pickup_amount'].error &&
                      this.state.fields['pickup_amount'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Drop-off Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='drop_amount'
                    value={this.state.fields.drop_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['drop_amount'].error &&
                      this.state.fields['drop_amount'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Pickup and Drop-off Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pickup_drop_amount'
                    value={this.state.fields.pickup_drop_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pickup_drop_amount'].error &&
                      this.state.fields['pickup_drop_amount'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Pickup and Drop-off Double distance fees</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pickupdrop_distance_fee'
                    value={this.state.fields.pickupdrop_distance_fee.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pickupdrop_distance_fee'].error &&
                      this.state.fields['pickupdrop_distance_fee'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Pickup and Drop-off Double distance (26-50 kms)</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pickupdrop_double_distance'
                    value={this.state.fields.pickupdrop_double_distance.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pickupdrop_double_distance'].error &&
                      this.state.fields['pickupdrop_double_distance'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Pickup and Drop-off No Show fees</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pickupdrop_noshow_fee'
                    value={this.state.fields.pickupdrop_noshow_fee.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['pickupdrop_noshow_fee'].error &&
                      this.state.fields['pickupdrop_noshow_fee'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Chauffeur Convenience fee</label>
                  <input
                    type='text'
                    className='form-control'
                    name='convenience_fee'
                    value={this.state.fields.convenience_fee.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['convenience_fee'].error &&
                      this.state.fields['convenience_fee'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Chauffeur Double Distance fee</label>
                  <input
                    type='text'
                    className='form-control'
                    name='chauffeur_doubledistance_fee'
                    value={this.state.fields.chauffeur_doubledistance_fee.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['chauffeur_doubledistance_fee'].error &&
                      this.state.fields['chauffeur_doubledistance_fee'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Chauffeur Double Distance in kms</label>
                  <input
                    type='text'
                    className='form-control'
                    name='chauffeur_doubledistance_kms'
                    value={this.state.fields.chauffeur_doubledistance_kms.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['chauffeur_doubledistance_kms'].error &&
                      this.state.fields['chauffeur_doubledistance_kms'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Single Line Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='singleline_amount'
                    value={this.state.fields.singleline_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['singleline_amount'].error &&
                      this.state.fields['singleline_amount'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Single Line Double Distance fees</label>
                  <input
                    type='text'
                    className='form-control'
                    name='singleline_doubledistance_fee'
                    value={
                      this.state.fields.singleline_doubledistance_fee.value
                    }
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['singleline_doubledistance_fee'].error &&
                      this.state.fields['singleline_doubledistance_fee'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Single Line Distance ({'>'}25kms)</label>
                  <input
                    type='text'
                    className='form-control'
                    name='singleline_distance'
                    value={this.state.fields.singleline_distance.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['singleline_distance'].error &&
                      this.state.fields['singleline_distance'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Triple Line Amount</label>
                  <input
                    type='text'
                    className='form-control'
                    name='tripleline_amount'
                    value={this.state.fields.tripleline_amount.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['tripleline_amount'].error &&
                      this.state.fields['tripleline_amount'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Triple Line Distance ({'>'}50kms)</label>
                  <input
                    type='text'
                    className='form-control'
                    name='tripleline_distance'
                    value={this.state.fields.tripleline_distance.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['tripleline_distance'].error &&
                      this.state.fields['tripleline_distance'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Slab 1</label>
                  <input
                    type='text'
                    className='form-control'
                    name='slab1'
                    value={this.state.fields.slab1.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['slab1'].error &&
                      this.state.fields['slab1'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Slab 2</label>
                  <input
                    type='text'
                    className='form-control'
                    name='slab2'
                    value={this.state.fields.slab2.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['slab2'].error &&
                      this.state.fields['slab2'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Slab 3</label>
                  <input
                    type='text'
                    className='form-control'
                    name='slab3'
                    value={this.state.fields.slab3.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['slab3'].error &&
                      this.state.fields['slab3'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Slab 4</label>
                  <input
                    type='text'
                    className='form-control'
                    name='slab4'
                    value={this.state.fields.slab4.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['slab4'].error &&
                      this.state.fields['slab4'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Amount Transaction Charges</label>
                  <input
                    type='text'
                    className='form-control'
                    name='amount_transaction_charge'
                    value={this.state.fields.amount_transaction_charge.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['amount_transaction_charge'].error &&
                      this.state.fields['amount_transaction_charge'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Cancellation Charges</label>
                  <input
                    type='text'
                    className='form-control'
                    name='cancellation_charge'
                    value={this.state.fields.cancellation_charge.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['cancellation_charge'].error &&
                      this.state.fields['cancellation_charge'].error}
                  </span>
                </div>
              </div>
            </div>
            <div className='dealer-update-head'>
              3. CEO / Contact Person Details
            </div>
            <div className='dealer-body'>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>Full Name</label>
                  <input
                    type='text'
                    className='form-control'
                    name='full_name'
                    value={this.state.fields.full_name.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['full_name'].error &&
                      this.state.fields['full_name'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Mobile Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='mobile_number'
                    value={this.state.fields.mobile_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['mobile_number'].error &&
                      this.state.fields['mobile_number'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>E-mail ID</label>
                  <input
                    type='text'
                    className='form-control'
                    name='contact_emailid'
                    value={this.state.fields.contact_emailid.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['contact_emailid'].error &&
                      this.state.fields['contact_emailid'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Dealer Code</label>
                  <input
                    type='text'
                    className='form-control'
                    name='dealer_code'
                    value={this.state.fields.dealer_code.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['dealer_code'].error &&
                      this.state.fields['dealer_code'].error}
                  </span>
                </div>
              </div>

              {/* <div className="form-group">
                <div class="form-inline">
                  <label>Single Point of Contact Name</label>
                  <input type="text" className="form-control" name="singlepnt_contactname" value={this.state.fields.singlepnt_contactname.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['singlepnt_contactname'].error && this.state.fields['singlepnt_contactname'].error}</span>
                </div>

                <div class="form-inline">
                  <label>Single Point of Contact Number</label>
                  <input type="text" className="form-control" name="singlepnt_contactnumber" value={this.state.fields.singlepnt_contactnumber.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['singlepnt_contactnumber'].error && this.state.fields['singlepnt_contactnumber'].error}</span>
                </div>
              </div> */}
            </div>
            <div>
              <button
                disabled={this.state.responseSuccess == true}
                eventKey='second'
                className='btn btn-dark-blue next-button'
                onClick={() => this.displayNextDetails()}>
                Save Changes
              </button>
              {this.state.responsefailure ? (
                <div className='failure_msg'>{this.state.responsemessage}</div>
              ) : (
                ''
              )}
              {this.state.responseSuccess ? (
                <div className='success_msg'>{this.state.responsemessage}</div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
