import React, { Component } from 'react';
import { Select } from 'antd';
import ValidationLibrary from '../../../helpers/validationfunction';
import './styles.css';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
class DropOffVehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        vehicle_model: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        vehicle_variant: { validations: [], error: null, value: '' },
        vehicle_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        zone_id: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        svc_list: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        customer_name: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        customer_mobile_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'mobile' },
          ],
          error: null,
          value: '',
        },
        customer_emailid: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'email' },
          ],
          error: null,
          value: '',
        },
        salutation: { validations: [], error: null, value: 'Mr' },
      },
    };
  }
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields }, () => {
      this.loadsvclistDropdown(this.state.fields.zone_id.value);
    });
  };
  GoPickupLocation = () => {
    // this.props.sendpickupdetails(2);
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      this.props.sendpickupdetails(2, 'vehicleDetails', fields);
    }
    this.setState({ fields });
  };
  loadsvclistDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = { zone_id: value };
    apiservices.bookingsvclistdropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ svc_list: response.response });
        var fields = this.state.fields;
        // if (!value) {
        fields['svc_list'].value =
          response.response.length > 0 ? response.response[0].id : '';
        this.setState({ fields });
        // }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadzoneDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.listallactivezone(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ zone_id: response.result });
        var fields = this.state.fields;
        if (!value) {
          fields['zone_id'].value =
            response.result.length > 0 ? response.result[0].id : '';
          this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  render() {
    const { zone_id } = this.state;
    const { svc_list } = this.state;
    return (
      <div className='pickup'>
        <div class='overall-service'>
          <div className='dealer-master'>
            <div className='dealer-head'>1. Vehicle and Customer Details</div>
            <div className='dealer-body'>
              {/* <div className="form-group ">
                       <div class="form-inline">
                          <label>Registration Number (optional)</label>
                          <input type="text" className="form-control" name="register_number" value={this.state.fields.register_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                           
                        </div>

                        <div class="form-inline">
                          <label>Mobile Number</label>
                          <input type="text" className="form-control" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                          <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error&&this.state.fields['mobile_number'].error}</span>
                         
                        </div>                     
                     </div> */}

              <div className='form-group'>
                <div className='form-inline'>
                  <label>Vehicle Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='vehicle_number'
                    value={this.state.fields.vehicle_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['vehicle_number'].error &&
                      this.state.fields['vehicle_number'].error}
                  </span>
                </div>
                <div class='form-inline'>
                  <label>Vehicle Model</label>
                  <input
                    type='text'
                    className='form-control'
                    name='vehicle_model'
                    value={this.state.fields.vehicle_model.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['vehicle_model'].error &&
                      this.state.fields['vehicle_model'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Vehicle Variant (optional)</label>
                  <input
                    type='text'
                    className='form-control'
                    name='vehicle_variant'
                    value={this.state.fields.vehicle_variant.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                </div>
                <div class='form-inline position-relative'>
                  <label>Zone Name</label>

                  <Select
                    name='zone_id'
                    value={[this.state.fields.zone_id.value]}
                    onChange={(value) =>
                      this.checkvalidations(value, 'zone_id')
                    }>
                    {zone_id &&
                      zone_id.length > 0 &&
                      zone_id.map((item) => {
                        return (
                          <Option value={item.id}>{item.zone_name}</Option>
                        );
                      })}
                  </Select>
                  <img
                    alt=''
                    src={Images.dropdown}
                    className='img-fluid dropdown-img'
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['zone_id'].error &&
                      this.state.fields['zone_id'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline position_set'>
                  <label>SVC List</label>
                  {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}

                  <Select
                    value={
                      // [parseInt(
                      this.state.fields.svc_list.value
                      // )]
                    }
                    onChange={(value) =>
                      this.checkvalidations(value, 'svc_list')
                    }
                    name='svc_list'>
                    {svc_list &&
                      svc_list.length > 0 &&
                      svc_list.map((item) => {
                        return (
                          <Option value={item.id}>{item.centre_name}</Option>
                        );
                      })}
                  </Select>
                  <img
                    alt=''
                    src={Images.dropdown}
                    className='img-fluid reason_dropdown-img'
                  />

                  <span className='error_msg_adddealer'>
                    {this.state.fields['svc_list'].error &&
                      this.state.fields['svc_list'].error}
                  </span>

                  {/* </div> */}
                  {/* <i
                          nput type="text" className="form-control"/>  */}
                </div>
                <div class='form-inline '>
                  <label>Customer Name</label>
                  <div className='svcUserdropdown saluation_drop'>
                    <Select
                      name='salutation'
                      value={this.state.fields.salutation.value}
                      onChange={(value) =>
                        this.checkvalidations(value, 'salutation')
                      }
                      className='svcdropList position_set'>
                      <Option value='Mr'>Mr</Option>
                      <Option value='Mrs'>Mrs</Option>
                      <Option value='Miss'>Miss</Option>
                    </Select>
                    <img
                      src={Images.dropdown}
                      className='img-fluid saluation_dropdown'
                    />
                    <input
                      type='text'
                      className='form-control'
                      name='customer_name'
                      value={this.state.fields.customer_name.value}
                      onChange={(e) =>
                        this.checkvalidations(e.target.value, e.target.name)
                      }
                    />
                  </div>
                  <span className='error_msg_adddealer'>
                    {this.state.fields['customer_name'].error &&
                      this.state.fields['customer_name'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Customer Mobile Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='customer_mobile_number'
                    value={this.state.fields.customer_mobile_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['customer_mobile_number'].error &&
                      this.state.fields['customer_mobile_number'].error}
                  </span>
                </div>{' '}
                <div className='form-inline'>
                  <label>Customer E-mail ID</label>
                  <input
                    type='text'
                    className='form-control'
                    name='customer_emailid'
                    value={this.state.fields.customer_emailid.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['customer_emailid'].error &&
                      this.state.fields['customer_emailid'].error}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <button
            className='btn btn-dark-blue next-button'
            onClick={() => this.GoPickupLocation()}>
            Next <img src={Images.next} className='img-fluid arrow' />
          </button>
        </div>
      </div>
    );
  }
  componentDidMount() {
    //   console.log("didmount", this.props);
    //   if (this.props.vehicleDetails)
    //   {
    //     this.setState({ fields: this.props.vehicleDetails }, function ()
    //     {
    //       this.loadsvclistDropdown(this.state.fields.svc_list.value);
    //     });
    //   } else
    //   {
    //     this.loadsvclistDropdown();
    //   }
    // }
    this.loadzoneDropdown(this.state.fields.zone_id.value);
    if (this.props.vehicleDetails && this.fields.zone_id.value) {
      this.setState({ fields: this.props.vehicleDetails }, function () {
        this.loadsvclistDropdown(this.state.fields.zone_id.value);
      });
    }
  }
}

export default DropOffVehicleDetails;
