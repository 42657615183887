import React, { Component } from "react";
import "./styles.css";
import apiservices from '../../helpers/apiservices';
import Images from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import { Table, Tag, Space, Select, Pagination } from 'antd';
const { Option } = Select;
export default class AddNewSvcUser extends Component {
    constructor(props) {
        super(props);
        console.log("add user",props);
        this.state = {
          // props.history.location.state.editid
            designation:null,
            editID:null,
            fields:{
                username:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
                designation:{validations:[{name:'required'}],error:null,value:''},
                mobile_number:{validations:[{name:'required'},{name:'mobile'},{name:'Noemptyspace'}],error:null,value:''},
                email_id:{validations:[{name:'required'},{name:'email'},{name:'Noemptyspace'}],error:null,value:''},
                employee_id:{validations:[{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
                salutation:{validations:[],error:null,value:'Mr'}
                
            }
        };
    }
    
    loadDesignationDropdown=()=>{
        const token=localStorage['accesstoken'];
        var obj={};
              apiservices.designationDropdown(obj,token)
              .then((response)=>{
                if(!response.error){
                    this.setState({designation:response.response})
                    var fields=this.state.fields;
                   
                    fields['designation'].value=response.response.length>0?response.response[0].id:'';            
                    this.setState({fields})
                    
                }else{
                //   this.setState({error_msg:response.message})
                }
                  
              })
    }
    componentWillMount(){
        this.loadDesignationDropdown();
        var svc_id=this.props.match.params.svc_id;
        var editID=this.props.match.params.editid;
        // if(editID==0){
          this.props.sendsvcid(svc_id);
        // }
        this.setState({editID:editID},function(){
          this.loadUserData(editID);
        });
        
        
    }
    checkvalidations=(value,key)=>{
   
    
        var fields =this.state.fields;
        fields[key].value=value;
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      AddUser=()=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else if(this.state.editID==0){
          //successblock
          this.setState({error:false});
          const token=localStorage['accesstoken'];
             var obj={
                svc_center_id:this.props.match.params.svc_id,
              designation_id:this.state.fields.designation.value,
              salutation:this.state.fields.salutation.value,
              name:this.state.fields.username.value,
              mobile_no:this.state.fields.mobile_number.value,
              email:this.state.fields.email_id.value,
              employee_id:this.state.fields.employee_id.value
          };
          console.log("obj",obj);
  
          apiservices.addsvcuser(obj,token)
          .then((response)=>{
            if(!response.error){
              this.setState({failure_msg:''})
             this.setState({success_msg:response.message})
                   
            }else{
              this.setState({success_msg:''})
              this.setState({failure_msg:response.message})
            }
              
          })
        
         
        }else{
            this.setState({error:false});
          const token=localStorage['accesstoken'];
             var obj={
                 id:this.state.editID,
                svc_center_id:this.props.sendsvcid,
              designation_id:this.state.fields.designation.value,
              salutation:this.state.fields.salutation.value,
              name:this.state.fields.username.value,
              mobile_no:this.state.fields.mobile_number.value,
              email:this.state.fields.email_id.value,
              employee_id:this.state.fields.employee_id.value
          };
          console.log("obj",obj);
  
          apiservices.svcupdateuser(obj,token)
          .then((response)=>{
            if(!response.error){
             this.setState({success_msg:response.message})
                   
            }else{
              this.setState({failure_msg:response.message})
            }
              
          })
        }
      }
      loadUserData =(data) =>{
        console.log("user data",data);
        const token=localStorage['accesstoken'];
        var obj={id:this.state.editID};
        console.log("user obj",obj);
        apiservices.getuserdata(obj,token)
        .then((response)=>{
          if(!response.error){
            var fields=this.state.fields;
            fields['username'].value=response.response.name;
            fields['designation'].value=response.response.designation_id;
            fields['mobile_number'].value=response.response.mobile_no;
            fields['email_id'].value=response.response.email;
            fields['employee_id'].value=response.response.employee_id;
            fields['salutation'].value=response.response.salutation;
            this.setState({fields})
            console.log("user details",response)
    
          }else{
            // this.setState({error_msg:response.message})
          }
            
        })
      }
    
      componentWillReceiveProps(props){
        var editid=props.history.location.state;
          
          console.log("will receive",editid);
      }
    render() {
      console.log("edit id",this.state.editID)
        const {designation} =this.state;
        return (<div className = "svc-add-user-info">
            <div className="svc-detailes-all">
                <div className="dealer-body">
                    <div className="form-group">
                         <div class="form-inline">
                            <label>User Name</label>
                            <div className="svcUserdropdown saluation_drop">
                                <Select name="salutation" value={this.state.fields.salutation.value} onChange={(value) => this.checkvalidations(value,'salutation')} className="svcdropList position_set"  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Mrs">Mrs</Option>
                                    <Option value="Miss">Miss</Option>
                                </Select>
                                <img src={Images.dropdown} className="img-fluid saluation_dropdown"/> 
                                <input type="text" className="form-control" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  />
                               
                          </div>
                            <span className="error_msg_adddealer">{this.state.fields['username'].error&&this.state.fields['username'].error}</span>
                        </div> 
                        {/* <div class="form-inline ">
                            <label>User Name</label>
                           
                             <div className="svcUserdropdown saluation_drop">
                                <Select name="salutation" value={this.state.fields.salutation.value} onChange={(value) => this.checkvalidations(value,'salutation')} className="svcdropList position_set"  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Mrs">Mrs</Option>
                                    <Option value="Miss">Miss</Option>
                                </Select>
                                <img src={Images.dropdown} className="img-fluid saluation_dropdown"/> 
                                <input type="text" className="form-control" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  />
                               
                            </div>  */}
                        <div class="form-inline">
                            <label>Mobile Number</label>
                            <input type="text" className="form-control" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
                            <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error&&this.state.fields['mobile_number'].error}</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <div class="form-inline">
                            <label>E-mail ID</label>
                            <input type="text" className="form-control"  name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/>
                            <span className="error_msg_adddealer">{this.state.fields['email_id'].error&&this.state.fields['email_id'].error}</span>
                        </div>
                        <div class="form-inline">
                            <label>Employee ID (Optional)</label>
                            <input type="text" className="form-control" name="employee_id" value={this.state.fields.employee_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['employee_id'].error&&this.state.fields['employee_id'].error}</span>
                        </div>
                    </div>
                    <div className="form-group">
                    <div class="form-inline ">
                                    <label>Designation</label>
                                    <Select name="designation" value={[this.state.fields.designation.value]} onChange={(value) => this.checkvalidations(value, 'designation')}>
                                        {designation && designation.length > 0 && designation.map((item) => {
                                            return (
                                                <Option value={item.id}>{item.designation_name}</Option>
                                            )
                                        })}

                                    </Select>
                                    <img src={Images.dropdown} className="svc-user-drop" style  = {{width:14}}/>
                                    <span className="error_msg_adddealer">{this.state.fields['designation'].error && this.state.fields['designation'].error}</span>
                                </div>
                    </div>
                </div>
            </div>
            <button disabled={this.state.success_msg} className="btn btn-dark-blue next-button" onClick={()=>this.AddUser()}>Submit</button>
            {this.state.failure_msg?
            <div className="failure_msg">{this.state.failure_msg}</div> :''
            }
            {this.state.success_msg?
            <div className="success_msg">{this.state.success_msg}</div> :''
            }
        </div>);
    }
}