
import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Pagination, Select, Tooltip } from 'antd';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import
{
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import
{
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import moment from 'moment';
import S3FileUpload from "react-s3";
import Aws from "../../../helpers/awskeys";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DatePicker, Upload } from 'antd';
const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
function handleChange(value)
{
  console.log(`selected ${value}`);
}

const MapWithAMarkers = withGoogleMap((props) =>
{
  console.log("newprops", props);
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  console.log("location", location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        // defaultCenter={props.currentLocation}
        // center={props.currentLocation}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
        />
      </GoogleMap>
    </div>
  )
}

);
const { RangePicker } = DatePicker;

// var bookingHeader = [{label : 'SVC Name', key : 'svc_name'}, {label: 'Customer Name', key : 'customer_name'},  {label : 'Vehicle Number', key : 'vehicle_number'},  {label : 'Booking ID', key : 'booking_id'}, {label : 'Booking Type', key : 'booking_type'}, {label : 'Slot', key : 'slot'},  {label : 'Ambassador', key : 'ambassador'},  {label : 'Booking Status', key : 'booking_status'}];

var activepickupHeader = [{ 'id': 1, 'booking_id': 'ID', 'city_zone': 'City / Zone', 'booking_info': 'Booking Info', 'customer_details': 'Customer Details', 'service_center_details': 'Service Centre Details', 'pilot_details': 'Pilot Details', 'state': 'State', 'status': 'Status', 'cancel': 'Cancel', 'pilot_changes': 'Pilot Change', 'progress': 'Progress' }];


class ActiveDropOff extends Component
{

  constructor(props)
  {
    super(props);

    this.state = {
      activepickupHeader: activepickupHeader,

      activedropList: [],

      currentPage: 1,
      pageSize: 10,
      deleteId: -1,
      openactiveBooking: false,
      popupDisplay: '',
      latitude: 0,
      longitude: 0,
      img: images.play_icon,
      activeID: null,
      assignID: null,
      filenewlist: [],
      fileupload_error: null,
      fields: {
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      reassignfields: {
        driver_list: { validations: [{ name: 'required' }], error: null, value: '' },
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      interchangefields: {
        assigned_driver: { validations: [{ name: 'required' }], error: null, value: '' },
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      reschedulefields: {
        slotdate: { validations: [{ name: 'required' }], error: null, value: moment() },
        slotime: { validations: [{ name: 'required' }], error: null, value: '' },
        service_advisor: { validations: [{ name: 'required' }], error: null, value: '' },
        reasons: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
      },
      customer_name: null,
      fromDate: null,
      toDate: null,
      pickup_fields: {
        svc_center: { validations: [], error: null, value: '' },
        status: { validations: [], error: null, value: '' },
      },


    }
  }

  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }
  imageHoverChange = (index, img) =>
  {
    var activedropList = this.state.activedropList;
    activedropList[index].img = img == 'pause' ? images.pause : images.play_icon;
    this.setState({ activedropList });
  }
  ActiveDropoffList = () =>
  {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, vehicle_number: this.state.vehicle_number, svc_center: this.state.pickup_fields.svc_center.value, booking_status: this.state.pickup_fields.status.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    console.log("actve", obj);
    apiservices.bookinglistactivedropoff(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          console.log("calc", calc);
          this.setState({ activedropList: response.response, numbercount: calc })
          console.log("acive reponse", response);
        }
        else
        {
          this.setState({ error_msg: response.message })
        }
      })


  }
  componentWillMount()
  {
    this.ActiveDropoffList();
    this.loadBookingStatus();
    this.loadSeriveCenter();
  }
  unassignedDriverList = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.unassigneddriverlist(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ driver_list: response.response })
          var reassignfields = this.state.reassignfields;
          reassignfields['driver_list'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ reassignfields })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.ActiveDropoffList();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.ActiveDropoffList();
    });
  }
  DropoffModal = (data, value, svc_id) =>
  {
    console.log("value", value)
    var self = this;
    this.setState({ popupDisplay: data, activeID: value.id, assignID: value.assign_id });
    var fields = this.state.fields;
    fields['reasons'].value = '';
    fields['reasons'].error = null;
    this.setState({ fields });
    var reassignfields = this.state.reassignfields;
    reassignfields['reasons'].value = '';
    reassignfields['reasons'].error = null;
    var interchangefields = this.state.interchangefields;
    interchangefields['reasons'].value = '';
    interchangefields['reasons'].error = null;
    this.unassignedDriverList();
    this.loadaloocationlist();
    this.assignedDriverList();
    this.loadAdvisorDropdown(svc_id);
    var reschedulefields = this.state.reschedulefields;
    // reschedulefields['estimate_amount'].value='';
    // reschedulefields['estimate_amount'].error='';
    reschedulefields['reasons'].value = '';
    reschedulefields['reasons'].error = '';
    // reschedulefields['upload_image'].error='';
    reschedulefields['service_advisor'].error = '';
    this.setState({ reassignfields, interchangefields, responseMsg: '', reschedulefields, filenewlist: [] });
    if (data == 'map')
    {
      this.setState({ latitude: value.driver_latitude, longitude: value.driver_longitude }, function ()
      {
        console.log("latitude", this.state.latitude)
      })
    }

    this.setState({ openactiveBooking: true });
  }
  checkvalidations = (value, key) =>
  {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  popupSubmit = (data) =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      if (data == 'cancel')
      {
        this.cancelBooking();
      }
      if (data == 'pause')
      {
        this.pauseBooking();
      }
      // alert('hii');
    }
    this.setState({ fields });
  }
  cancelBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, reason: this.state.fields.reasons.value, assign_id: this.state.assignID };
    console.log("cancel", obj);
    apiservices.cancelbooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveDropoffList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  pauseBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, reason: this.state.fields.reasons.value, assign_id: this.state.assignID };
    console.log("cancel", obj);
    apiservices.pausebooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveDropoffList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  closeDialog = () =>
  {
    this.setState({ openactiveBooking: false }, function ()
    {
      var fields = this.state.fields;
      fields['reasons'].value = '';
      this.setState({ fields });
      var reassignfields = this.state.reassignfields;
      reassignfields['reasons'].value = '';
      var interchangefields = this.state.interchangefields;
      interchangefields['reasons'].value = '';
      this.setState({ reassignfields, interchangefields });
    });
  }
  Reassignvalidations = (value, key) =>
  {

    var reassignfields = this.state.reassignfields;
    reassignfields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, reassignfields[key].validations);
    // console.log(checkstatus);

    reassignfields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(reassignfields);
    var filtererrors = getallkeys.filter((obj) => reassignfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ reassignfields });
  }
  Interchangevalidations = (value, key) =>
  {

    var interchangefields = this.state.interchangefields;
    interchangefields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, interchangefields[key].validations);
    // console.log(checkstatus);

    interchangefields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(interchangefields);
    var filtererrors = getallkeys.filter((obj) => interchangefields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ interchangefields });
  }
  ReassignBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, reason: this.state.reassignfields.reasons.value, driver_id: this.state.reassignfields.driver_list.value, assign_id: this.state.assignID };
    console.log("reassign", obj);
    apiservices.reassignbooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveDropoffList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  InterchangeBooking = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, reason: this.state.interchangefields.reasons.value, driver_id: this.state.interchangefields.assigned_driver.value, assign_id: this.state.assignID };
    console.log("reassign", obj);
    apiservices.interchangebooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveDropoffList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        }
        else
        {
          this.setState({ responseMsg: response.message })
        }
      })
  }
  ReassignSubmit = (data) =>
  {
    var reassignfields = this.state.reassignfields;
    var getallkeys = Object.keys(reassignfields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(reassignfields[obj].value, reassignfields[obj].validations);
      reassignfields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => reassignfields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      if (data == 'reassign')
      {
        this.ReassignBooking();
      }
      if (data == 'interchange')
      {
        this.InterchangeBooking();
      }
    }
    this.setState({ reassignfields });
  }
  loadaloocationlist = () =>
  {
    const token = localStorage['accesstoken'];
    var obj = { slot_day: new Date() };
    apiservices.getSlotDetails(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ slotime: response.response })
          var reschedulefields = this.state.reschedulefields;
          // if(!value){
          reschedulefields['slotime'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ reschedulefields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })

  }
  loadAdvisorDropdown = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { svc_center_id: value };
    console.log("service advisor", value);
    apiservices.bookingadvicerdropdown(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ service_advisor: response.response })
          var reschedulefields = this.state.reschedulefields;
          // if(!value){
          reschedulefields['service_advisor'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ reschedulefields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  RescheduleBooking = (image) =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, slot_day: moment(this.state.reschedulefields.slotdate.value).format(dateFormat), slot_time: this.state.reschedulefields.slotime.value, invoice_amount: '', service_advisor: this.state.reschedulefields.service_advisor.value, invoice_doc: '', notes: this.state.reschedulefields.reasons.value };
    console.log("reschedule booking", obj);

    apiservices.reschedulebooking(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveDropoffList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })

        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  reschdulevalidations = (value, key) =>
  {

    var reschedulefields = this.state.reschedulefields;
    reschedulefields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, reschedulefields[key].validations);
    // console.log(checkstatus);

    reschedulefields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(reschedulefields);
    var filtererrors = getallkeys.filter((obj) => reschedulefields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ reschedulefields });
  }
  rescheduleSubmit = async (data) =>
  {
    var reschedulefields = this.state.reschedulefields;
    var getallkeys = Object.keys(reschedulefields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(reschedulefields[obj].value, reschedulefields[obj].validations);
      reschedulefields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => reschedulefields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      if (data == 'reschedule')
      {
        this.RescheduleBooking();
        // var fileList=this.state.reschedulefields.upload_image.value;
        // console.log("else part",fileList);
        // var temparray=[];
        // for(var i in fileList){
        //   var response=await S3FileUpload.uploadFile(fileList[i], config);
        //   console.log("response",response);
        //   temparray.push(response.location);
        //   if(fileList.length-1==i){
        //     // console.log();
        //     var apiimagefile=temparray.join(',');
        //     this.RescheduleBooking(apiimagefile);
        //   }
        // }
      }
      // alert('hii');
    }
    this.setState({ reschedulefields });
  }
  customerFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ customer_name: value, vehicle_number: value, currentPage: 1 }, function ()
    {
      this.ActiveDropoffList();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.ActiveDropoffList();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.ActiveDropoffList();
    })
  }
  loadBookingStatus = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.listActiveBookingStatusDrop(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ status: response.response })
          // if(!value){
          // ticketfields['ticket_priority'].value=response.response.length>0?response.response.id:'';            
          // this.setState({ticketfields})
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  autocompleteBooking = (data, assign_id) =>
  {
    console.log("autocomplete id", data);
    const token = localStorage['accesstoken'];
    var obj = { id: data, assign_id: assign_id };
    console.log("actve", obj);
    apiservices.autoCompleteBooking(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_msg: response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ success_msg: '' })

          }, 3000);

        } else
        {
          this.setState({ error_msg_autocomplete: response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ error_msg_autocomplete: '' })

          }, 3000);
        }
      })
  }
  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['svc_center'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.ActiveDropoffList();
    })
  }
  checkdriverfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['status'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.ActiveDropoffList();
    })
  }
  makecall = (bkid, assignid, mobilenumber) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: bkid, assign_id: assignid, mobile_no: mobilenumber };
    console.log("obj", obj);
    apiservices.makeCall(obj, token)
      .then((response) =>
      {
        console.log("drop call", response);
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  assignedDriverList = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.assigneddriverlist(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ assigned_driver: response.response })
          var interchangefields = this.state.interchangefields;
          interchangefields['assigned_driver'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ interchangefields })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  InterchangeSubmit = (data) =>
  {
    var interchangefields = this.state.interchangefields;
    var getallkeys = Object.keys(interchangefields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(interchangefields[obj].value, interchangefields[obj].validations);
      interchangefields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => interchangefields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      if (data == 'reassign')
      {
        this.ReassignBooking();
      }
      if (data == 'interchange')
      {
        this.InterchangeBooking();
      }
    }
    this.setState({ interchangefields });
  }
  DriverAsCustomerPlace = (data) =>
  {
    console.log("data", data);
    const token = localStorage['accesstoken'];
    var obj = { id: data.id, assign_id: data.assign_id };
    apiservices.makeDriverAsCustomerPlace(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.message }, function ()
          {
            this.ActiveDropoffList();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  render()
  {
    var self = this;
    var temparray = [];
    const props = {
      name: 'file',
      accept: 'application/pdf,image/*',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      fileList: self.state.filenewlist,
      showUploadList: {

        showRemoveIcon: true,
        removeIcon: <img src={images.file_remove} className="file_remove" />,
      },
      // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
      beforeUpload: (file) =>
      {
        console.log("filelist", self.state.filenewlist);
        var filenewlist = self.state.filenewlist;
        filenewlist.push(file);
        if (self.state.filenewlist.length < 2)
        {

          self.setState({ filenewlist }, function ()
          {
            self.reschdulevalidations(filenewlist, 'upload_image');
            self.props.sendImages && self.props.sendImages(filenewlist);
          });

        } else
        {
          filenewlist = filenewlist.splice(0, 1);
          self.setState({ fileupload_error: 'Max Files Upload is 1' })
          self.setState({ filenewlist });
        }
        console.log("beforeuploadfile", file)
        // return true;
      },
      onChange: (info) =>
      {

        var filenewlist = self.state.filenewlist;
        if (info.file.status == 'removed')
        {
          var findindex = filenewlist.findIndex((obj) => obj.uid == info.file.uid);
          filenewlist.splice(findindex, 1);
          self.setState({ filenewlist });
        }


        console.log("delete info", info);
      }
    }
    console.log("booking id", this.state.activeID);
    console.log("assign id", this.state.assignID)
    const { driver_list, slotime, service_advisor, svc_center, status, assigned_driver } = this.state;
    return (

      <div>

        <div className="search-header booking-mishaps booking-active">
          <div className="row">
            <div className="col-md-3">
              <div class="form-group search-list payment-search">
                <input type="text" placeholder="Search for Customer or Vehicle" className="form-control" name="customer_name" onChange={(e) => this.customerFilter(e)} />
                <img src={images.green_search} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                <Select value={[this.state.pickup_fields.svc_center.value ? parseInt(this.state.pickup_fields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                  <Option value="">All</Option>
                  {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                  {
                    return (


                      <Option value={item.id}>{item.centre_name}</Option>

                    )
                  })}

                </Select>
              </div>
              <div className="payment-dropdown"> <span className="payment">Status:</span>
                <Select value={[this.state.pickup_fields.status.value ? parseInt(this.state.pickup_fields.status.value) : '']} onChange={(value) => this.checkdriverfilter(value)} name="status" >
                  <Option value="">All</Option>
                  {status && status.length > 0 && status.map((item) =>
                  {
                    return (
                      <Option value={item.id}>{item.name}</Option>
                    )
                  })}

                </Select>
              </div>
              <div className="service_listing booking-list payments-list">


                <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.error_call ?
          <div className="failure_msg">{this.state.error_call}</div> : ''
        }

        {this.state.success_call ?
          <div className="success_msg">{this.state.success_call}</div> : ''
        }
        {this.state.error_msg_autocomplete ?
          <div className="failure_msg">{this.state.error_msg_autocomplete}</div> : ''
        }

        {this.state.success_msg ?
          <div className="success_msg">{this.state.success_msg}</div> : ''
        }

        <div className="table-list">


          <div class="table-responsive">


            <table class="table table-borderless">
              <thead>
                {this.state.activepickupHeader && this.state.activepickupHeader.map((item) =>
                {
                  return (
                    <tr>
                      <th>{item.booking_id}</th>
                      <th>{item.city_zone}</th>
                      <th className="text-left">{item.booking_info}</th>

                      <th className="customer_thead text-left">{item.customer_details}</th>
                      <th>Customer Name</th>
                      <th>Vehicle Number</th>
                      <th className="service_thead">{item.service_center_details}</th>
                      <th>{item.pilot_details}</th>
                      <th>Bill Value</th>
                      <th>{item.progress}</th>
                      <th>{item.status}</th>
                      <th>{item.state}</th>
                      <th>{item.cancel}</th>
                      <th>Autocomplete</th>
                      <th>Change Address</th>
                      <th>{item.pilot_changes}</th>



                    </tr>
                  )
                })}
              </thead>
              <tbody>

                {this.state.activedropList && this.state.activedropList.length > 0 && this.state.activedropList.map((item, index) =>
                {

                  return (
                    <tr>
                      <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}><td>ID{item.id}</td></Link>
                      <td>{item.city}</td>
                      <td className="text-left">
                        <div className="bk_details"><span className="booking_span">Date</span>
                          {moment(item.slot_day).format('DD/MM/YY')}
                        </div>
                        <div className="bk_details">
                          <span className="booking_span">Time</span>
                          {item.slot_time}<br />
                        </div>
                        {/* <div className="bk_details">
                                                <span className="booking_span">Costs</span>
                                                {item.booking_costs}<br/>
                                                </div> */}
                        {/* <div className="bk_details">
                                                <span className="booking_span">Payment Status</span>
                                                {item.booking_payment}<br/>
                                                </div> */}
                      </td>
                      <td className="text-left">{item.vehicle_number}<br />
                        <div className="pick_address">{item.dropoff_address}</div><br />
                        <div className="pick_address">{item.customer_mobile}</div><br />
                        <div className="pick_address">{item.vehicle_number}</div>
                        <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.customer_mobile)} /></Tooltip>

                      </td>
                      <td>{item.customer_name}</td>
                      <td>{item.vehicle_number}</td>
                      <td className="service-td"><span className="service-listing">{item.centre_name}</span>
                        <br />
                        <span className="service-listing">{item.service_advisor_name}</span>
                        {item.service_advisor_mobile_no != null ?
                          <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.service_advisor_mobile_no)} /></Tooltip> : ''}<br />
                        <span className="service-listing">{item.cre_name}</span>
                        {item.cre_mobile_no != null ?
                          <img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.cre_mobile_no)} /> : ''}

                      </td>
                      <td>
                        <div className="pilot-default">
                          <img src={item.driver_image_url == null ? images.default_image : item.driver_image_url} style={{ width: 61, borderRadius: 4 }} />
                          {item.driver_mobile_no != null ?
                            <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} className="call-defaulter" style={{ width: 38, marginLeft: 59, marginTop: -23 }} onClick={() => this.makecall(item.id, item.assign_id, item.driver_mobile_no)} /></Tooltip> : ''}
                        </div>
                        {item.driver_first_name} {item.driver_last_name} <br />
                        {item.driver_mobile_no}
                        <div className="view-map" onClick={() => this.DropoffModal('map', item)}>View on Google Map</div></td>
                      <td>Rs.{item.invoice_amount}</td>
                      <td>{item.progress}</td>
                      <td>{item.status}</td>
                      <td><Tooltip placement="top" title="Pause" color="white" key="#0B485A" ><img src={images.pause}
                        style={{ width: 38 }} onClick={() => this.DropoffModal('pause', item)} /></Tooltip></td>
                      <td><Tooltip placement="top" title="Cancel" color="white" key="#0B485A" ><img src={images.cancel_new} style={{ width: 38, opacity: item.progress_id != 1 ? 0.5 : 1 }} onClick={() => item.progress_id != 1 ? '' : this.DropoffModal('cancel', item)} /></Tooltip></td>
                      <td><Tooltip placement="top" title="Autocomplete" color="white" key="#0B485A" ><img src={images.autocomplete} style={{ width: 30 }} onClick={() => this.autocompleteBooking(item.id, item.assign_id)} /></Tooltip></td>
                      <td><img src={images.addresschange} style={{ width: 38 }} onClick={() => this.DriverAsCustomerPlace(item)} /></td>
                      <td><Tooltip placement="top" title="Reassign" color="white" key="#0B485A" ><img src={images.refresh} style={{ width: 38 }} onClick={() => this.DropoffModal('reassign', item)} /></Tooltip><Tooltip placement="top" title="InterChange" color="white" key="#0B485A" > <img src={images.plotchange} style={{ width: 38 }} onClick={() => this.DropoffModal('interchange', item)} /></Tooltip><Tooltip placement="top" title="Reschedule" color="white" key="#0B485A" ><img src={images.reschedule} style={{ width: 38, opacity: item.status_id == 6 ? 1 : 0.5 }} onClick={() => item.status_id == 6 ? this.DropoffModal('reschedule', item, item.svc_center_id) : null} /></Tooltip></td>


                    </tr>
                  )
                })}
              </tbody>
            </table>
            {this.state.activedropList && this.state.activedropList.length == 0 &&
              <div className="no_records_found">No Records Found</div>
            }

          </div>
        </div>

        <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
        />

        <Dialog open={this.state.openactiveBooking} className={`booking_pickup ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.closeDialog()} /></div>
          {this.state.popupDisplay == 'pause' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Pause Booking
                   </div>
              <div>


                <div className="form-group reason_box">
                  <label>
                    Reason
                         </label>

                  <textarea row="5" col="4" maxLength="300" name="reasons" className="form-control reason_box" value={this.state.fields.reasons.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.fields['reasons'].error && this.state.fields['reasons'].error}</span>

                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.popupSubmit('pause')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'cancel' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Cancel Booking
                   </div>
              <div>


                <div className="form-group reason_box">
                  <label>
                    Reason
                         </label>

                  <textarea row="5" col="4" maxLength="300" name="reasons" className="form-control reason_box" value={this.state.fields.reasons.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.fields['reasons'].error && this.state.fields['reasons'].error}</span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button disabled={this.state.responseMsg && this.state.responseMsg} className="delete_submit" onClick={() => this.popupSubmit('cancel')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'reassign' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Re-assign Driver
                   </div>
              <div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select the Driver</label>
                    <Select value={[parseInt(this.state.reassignfields.driver_list.value)]} onChange={(value) => this.Reassignvalidations(value, 'driver_list')} name="driver_list" >
                      {driver_list && driver_list.length > 0 && driver_list.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.first_name} {item.last_name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.reassignfields['driver_list'].error && this.state.reassignfields['driver_list'].error}</span>

                  </div>
                </div>

                <div className="form-group reason_box">
                  <label>
                    Reason
                         </label>

                  <textarea row="5" col="4" maxLength="300" name="reasons" className="form-control reason_box" value={this.state.reassignfields.reasons.value} onChange={(e) => this.Reassignvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.reassignfields['reasons'].error && this.state.reassignfields['reasons'].error}</span>

                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.ReassignSubmit('reassign')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'interchange' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Interchange Driver
                   </div>
              <div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select the Driver</label>
                    {/* <Select defaultValue="Chad Ellis" onChange={handleChange}>
                                        <Option value="Chad Ellis">Lorem Isum</Option>
                                        <Option value="lucy">Lorem Isum</Option>
                                        <Option value="Yiminghe">Lorem Isum</Option>
                                    </Select>
                                    <img src={images.dropdown} className="reason_drop_down" /> */}
                    <Select value={[parseInt(this.state.interchangefields.assigned_driver.value)]} onChange={(value) => this.Interchangevalidations(value, 'assigned_driver')} name="assigned_driver" >
                      {assigned_driver && assigned_driver.length > 0 && assigned_driver.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.first_name} {item.last_name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.interchangefields['assigned_driver'].error && this.state.interchangefields['assigned_driver'].error}</span>

                  </div>
                </div>

                <div className="form-group reason_box">
                  <label>
                    Reason
                         </label>

                  <textarea row="5" col="4" maxLength="300" name="reasons" className="form-control reason_box" value={this.state.interchangefields.reasons.value} onChange={(e) => this.Interchangevalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.interchangefields['reasons'].error && this.state.interchangefields['reasons'].error}</span>

                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.InterchangeSubmit('interchange')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'map' &&
            <div className="modalcntntcls" style={{ width: '100%', padding: 10 }}>

              {this.state.latitude != 0 && this.state.longitude != 0 &&
                <MapWithAMarkers
                  currentLocation={{ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) }}
                  //  currentLocation={this.state.currentLatLng}
                  containerElement={<div style={{ borderRadius: 2, height: '550px', width: '100%' }} />}
                  mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                />
              }


            </div>
          }
          {this.state.popupDisplay == 'reschedule' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Re-Schedule Booking
                     </div>
              <div>
                <div className="form-group">
                  <label className="label_paused">
                    Queue ID
                           </label>

                  <input type="text" className="form-control input_paused" value={this.state.activeID} readOnly />
                </div>

                <div className="form-group">
                  <div class="form-inline position_slot">
                    <label>Select Date</label>
                    <DatePicker className="slotdate paused_slotdate" onChange={(value) => this.reschdulevalidations(value, 'slotdate')} value={this.state.reschedulefields.slotdate.value} disabledDate={current =>
                    {
                      return current && current < moment().startOf('day');
                    }} suffixIcon={<img src={images.date_picker} className="datepicker_img_slot" />} />
                    <span className="error_msg_adddealer">{this.state.reschedulefields['slotdate'].error && this.state.reschedulefields['slotdate'].error}</span>
                  </div>

                </div>



                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Time Slot</label>

                    <Select value={[parseInt(this.state.reschedulefields.slotime.value)]} onChange={(value) => this.reschdulevalidations(value, 'slotime')} name="slotime" >
                      {slotime && slotime.length > 0 && slotime.map((item) =>
                      {
                        return (
                          <Option disabled={item.is_disable == 1} value={item.id}>{item.time}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img_paused" />

                    <span className="error_msg_adddealer">{this.state.reschedulefields['slotime'].error && this.state.reschedulefields['slotime'].error}</span>



                  </div>
                </div>
                {/* <div className="form-group">
                           <label className="label_paused">
                           Estimate Amount
                           </label>
  
                           <input type="text" className="form-control input_paused" name="estimate_amount" value={this.state.reschedulefields.estimate_amount.value} onChange={(e) => this.reschdulevalidations(e.target.value,e.target.name)} />
                         <span className="error_msg_adddealer">{this.state.reschedulefields['estimate_amount'].error&&this.state.reschedulefields['estimate_amount'].error}</span> 
                       </div> */}
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Service Advisor</label>

                    <Select value={[parseInt(this.state.reschedulefields.service_advisor.value)]} onChange={(value) => this.reschdulevalidations(value, 'service_advisor')} name="service_advisor" >
                      {service_advisor && service_advisor.length > 0 && service_advisor.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img_paused" />

                    <span className="error_msg_adddealer">{this.state.reschedulefields['service_advisor'].error && this.state.reschedulefields['service_advisor'].error}</span>



                  </div>
                </div>
                {/* <div className="form-group">
                       <label className="upload_invoice_label">Upload Invoice Copy <span style={{color:'red',marginLeft:10}}>{this.state.fileupload_error}</span></label>

                       <div style={{width:'115%'}}>
                         <Dragger {...props}  >
                        <div><button className="upload_btn">Upload Files</button></div>
                       <p className="ant-upload-text"></p>
                       <p className="ant-upload-hint">
                      Files can be in .jpg or .pdf format
                     </p>
                     </Dragger>

                 <span className="error_msg_driver">{this.state.reschedulefields['upload_image'].error && this.state.reschedulefields['upload_image'].error}</span> 
                </div>
                         

                     </div> */}
                <div className="form-group reason_box">
                  <label>
                    Notes
                           </label>

                  <textarea row="5" maxLength="300" className="form-control notesheight" name="reasons" value={this.state.reschedulefields.reasons.value} onChange={(e) => this.reschdulevalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.reschedulefields['reasons'].error && this.state.reschedulefields['reasons'].error}</span>


                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.rescheduleSubmit('reschedule')}>Submit</button>
                </div>
              </div>


            </div>
          }
        </Dialog>

      </div>

    )
  }
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(ActiveDropOff)
