import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import './style.css';
import AddSvc from '../../Pages/ServiceCenter_Dm1';
import SvcList from '../../Pages/SvcList';
import DealerMasterList from "../../Pages/DealerMasterList";
import NewUserRequest from '../../Pages/NewUserRequest';
import Performance from '../../Pages/Performance';
import UserList from '../../Pages/UserList';
import Designation from '../../Pages/Designation';
import Targets from '../../Pages/Targets';
import Trends from '../../Pages/Trends';
class SvcMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {

    return (
      <div className="container">
        <div class="svc-tabs">
          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row svc_mainview" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink exact={true} to={`${this.props.match.path}/`} activeClassName="active_cls">Add SVC</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/svc_list/`} activeClassName="active_cls">SVC List</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/dealer_list/`} activeClassName="active_cls">Dealer Master List</NavLink>
                </Nav.Item>
               <Nav.Item>
                  <NavLink to="/svc/user_request" activeClassName="active_cls">New User Request</NavLink>
                </Nav.Item> 
                {/* <Nav.Item>
                  <NavLink to="/svc/user_list" activeClassName="active_cls">User List</NavLink>
                </Nav.Item> */}
                <Nav.Item>
                  <NavLink to="/svc/designation" activeClassName="active_cls">Designation</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/svc/trends" activeClassName="active_cls">Trends</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/svc/performance" activeClassName="active_cls">Performance</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/svc/targets" activeClassName="active_cls">Targets</NavLink>
                </Nav.Item>
              </Nav>
             
            </Col>
            <Col sm={12}>
             
              <Switch>
                <Route exact path={`${this.props.match.path}/`} render={props => (<AddSvc {...props} />)} />
                <Route path={`${this.props.match.path}/svc_list`} render={props => (<SvcList  {...props} />)} />
                <Route path={`${this.props.match.path}/dealer_list`} render={props => (<DealerMasterList  {...props} />)} />
                <Route exact path="/svc/user_request" render={props => (<NewUserRequest {...props}/>)} />
                {/* <Route exact path="/svc/user_list" render={props => (<UserList {...props}/>)} /> */}
                <Route exact path="/svc/designation" render={props => (<Designation {...props}/>)} />
                <Route exact path="/svc/trends" render={props => (<Trends {...props}/>)} />
                <Route exact path="/svc/performance" render={props => (<Performance {...props}/>)} />
                <Route exact path="/svc/targets" render={props => (<Targets {...props}/>)} />
                
              </Switch>
            </Col>
          </Row>
          {/* </Tab.Container> */}
        </div>
      </div>
    )

  }
  componentDidMount() {
    this.props.receivePageTitle && this.props.receivePageTitle('Service Centre');
  }

}


export default SvcMain;