import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
import ActiveDriverTable from "../ActiveDriverTable";
import BookingListActive from '../BookingListActive';
import BookingListPause from '../BookingListPause';
import BookingListUpComing from '../BookingListUpComing';
import BookingListCompleted from '../BookingListCompleted';
import BookingListCancelled from '../BookingListCancelled';
import BookingDetails from '../BookingDetails';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class BookingList extends Component {

    constructor(props) {
        super(props);
        this.state = {

            pilotMaster : true,
            addDriver : false
        }
    }

    AddDriver = () => {
  console.log("adddriver clicj")
        this.setState({pilotMaster : false});
         this.setState({addDriver  : true})
    }
    render() {
        return (
            <div>
                {this.state.pilotMaster &&  
                
                <div class="add-booking-menu">
                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Row>
                             <Col sm={12}> 
                               
                           <Nav variant="pills" className="flex-row add_booking" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/active`} activeClassName="booking_active_cls">Active</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/paused')&&"activelink"}`} to={`${this.props.match.path}/paused`} activeClassName="booking_active_cls">Paused</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/upcoming/`} activeClassName="booking_active_cls">Upcoming</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/completed/`} activeClassName="booking_active_cls">Completed</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/cancelled/`} activeClassName="booking_active_cls">Cancelled</NavLink>
                </Nav.Item>
                
              </Nav>
              </Col> 
                            <Col sm={12}>
                               
                                <Switch>
                 {/* <Route  path={`${this.props.match.path}/booking_details`} render={props => (<BookingDetails {...props} />)} /> */}
                <Route  path={`${this.props.match.path}/active`} render={props => (<BookingListActive {...props} parentProps={this.props.parentProps} />)} />
                <Route   path={`${this.props.match.path}/paused`} render={props => (<BookingListPause {...props}/>)} />
                <Route   path={`${this.props.match.path}/upcoming`} render={props => (<BookingListUpComing {...props}/>)} />
                <Route   path={`${this.props.match.path}/completed`} render={props => (<BookingListCompleted {...props} parentProps={this.props.parentProps}/>)} />
                <Route   path={`${this.props.match.path}/cancelled`} render={props => (<BookingListCancelled {...props}/>)} />
                
                
              </Switch>
                            </Col>
                        </Row>
                    {/* </Tab.Container> */}
                </div>
                }


               
            </div>

            
        )

    }
    componentDidMount() {
        this.props.receivePageTitle && this.props.receivePageTitle('Drivers');
    }

}


export default BookingList;