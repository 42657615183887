import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import { DatePicker, Select, Upload, message } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import S3FileUpload from "react-s3";
import Aws from "../../../helpers/awskeys";
const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
const { Option } = Select;
class BookingPaymentDetails extends Component 
{

  constructor(props)
  {
    console.log("booking pickup", props);
    super(props);

    this.state = {
      uploadInvoiceOpen: false,
      popupDisplay: false,
      loading: false,
      filesUpload: "",
      filenewlist: [],
      paymentDetails: null,
      fileupload_error: null,
      customersDetails: null,
      fields: {
        invoice_amount: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'allowNumaricOnly' }], error: null, value: '' },
        // notes:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
        // contact_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'mobile'}],error:null,value:''},
        // alternate_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'mobile'}],error:null,value:''},
        // email_id:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'email'}],error:null,value:''},
      },
      upload_fields: {
        invoice_amount: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'allowNumaricOnly' }], error: null, value: '' },
        upload_image: { validations: [{ name: 'required' }], error: null, value: '' },
        service_advisor: { validations: [{ name: 'required' }], error: null, value: '' },
      }
    }
  }

  uploadInvoiceOpen = (data) =>
  {
    console.log("data delete");
    this.setState({ popupDisplay: data, uploadInvoiceOpen: true }, function ()
    {
      this.loadAdvisorDropdown();
    });
  }
  deleteClose = () =>
  {
    var fields = this.state.fields;
    fields['invoice_amount'].value = '';
    var upload_fields = this.state.upload_fields;
    upload_fields['invoice_amount'].value = '';
    upload_fields['upload_image'].value = '';
    this.loadAdvisorDropdown();
    this.setState({ uploadInvoiceOpen: false, responseMsg: '', fields, upload_fields, filenewlist: [] });
  }
  loadPaymentDetails = (data) =>
  {
    this.setState({ loading: true })
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id, assign_id: this.props.match.params.assignid };
    console.log("booking svc details obj", obj);
    apiservices.bookingdetailsview(obj, token)
      .then((response) =>
      {
        this.setState({ loading: false });
        if (!response.error)
        {

          this.setState({ paymentDetails: response.response.paymentDetails, svc_center_id: response.response.svcDetails, customersDetails: response.response && response.response.customersDetails })
        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  componentWillMount()
  {
    this.loadPaymentDetails();
  }
  checkvalidations = (value, key) =>
  {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    console.log("fields[key].validations", fields[key].validations)
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  SubmitPopupAdvisory = () =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      this.addAdvisoryFees();
    }
    this.setState({ fields });

  }
  addAdvisoryFees = () =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id, invoice_amount: this.state.fields.invoice_amount.value, assign_id: this.props.match.params.assignid };
    console.log("booking svc details obj", obj);
    apiservices.changeadvisorfees(obj, token)
      .then((response) =>
      {
        var self = this;
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.loadPaymentDetails();
            self.setState({ uploadInvoiceOpen: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  uploadvalidations = (value, key) =>
  {

    var upload_fields = this.state.upload_fields;
    upload_fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, upload_fields[key].validations);
    // console.log(checkstatus);

    upload_fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(upload_fields);
    var filtererrors = getallkeys.filter((obj) => upload_fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ upload_fields });
  }
  SubmitPopup = async () =>
  {
    var upload_fields = this.state.upload_fields;
    var getallkeys = Object.keys(upload_fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(upload_fields[obj].value, upload_fields[obj].validations);
      upload_fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => upload_fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      //   this.uploadInvoice();
      var fileList = this.state.upload_fields.upload_image.value;
      console.log("else part", fileList);
      var temparray = [];
      for (var i in fileList)
      {
        var response = await S3FileUpload.uploadFile(fileList[i], config);
        console.log("response", response);
        temparray.push(response.location);
        if (fileList.length - 1 == i)
        {
          // console.log();
          var apiimagefile = temparray.join(',');
          this.uploadInvoice(apiimagefile);
        }
      }
    }
    this.setState({ upload_fields });

  }
  uploadInvoice = (image) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id, invoice_amount: this.state.upload_fields.invoice_amount.value, invoice_doc: image, service_advisor: this.state.upload_fields.service_advisor.value, assign_id: this.props.match.params.assignid };
    console.log("upload invoice", obj);
    apiservices.bookingpaymentuploadinvoice(obj, token)
      .then((response) =>
      {
        var self = this;
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.loadPaymentDetails();
            self.setState({ uploadInvoiceOpen: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  loadAdvisorDropdown = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { svc_center_id: this.state.svc_center_id && this.state.svc_center_id.svc_center_id };
    console.log("service advosir", obj)
    apiservices.bookingadvicerdropdown(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ service_advisor: response.response })
          var upload_fields = this.state.upload_fields;
          //   if(!value){
          upload_fields['service_advisor'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ upload_fields })
          //   }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.loadPaymentDetails();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.loadPaymentDetails();
    });
  }
  render()
  {
    const { paymentDetails, service_advisor, customersDetails } = this.state;
    console.log("svc_center_id", this.state.svc_center_id)
    var self = this;
    var temparray = [];
    const props = {
      name: 'file',
      accept: 'application/pdf,image/*',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      fileList: self.state.filenewlist,
      showUploadList: {

        showRemoveIcon: true,
        removeIcon: <img src={images.file_remove} className="file_remove" />,
      },
      // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
      beforeUpload: (file) =>
      {
        console.log("filelist", self.state.filenewlist);
        var filenewlist = self.state.filenewlist;
        filenewlist.push(file);
        if (self.state.filenewlist.length < 2)
        {

          self.setState({ filenewlist }, function ()
          {
            self.uploadvalidations(filenewlist, 'upload_image');
            self.props.sendImages && self.props.sendImages(filenewlist);
          });

        } else
        {
          filenewlist = filenewlist.splice(0, 1);
          self.setState({ fileupload_error: 'Max Files Upload is 1' })
          self.setState({ filenewlist });
        }
        console.log("beforeuploadfile", file)
        // return true;
      },
      onChange: (info) =>
      {

        var filenewlist = self.state.filenewlist;
        if (info.file.status == 'removed')
        {
          var findindex = filenewlist.findIndex((obj) => obj.uid == info.file.uid);
          filenewlist.splice(findindex, 1);
          self.setState({ filenewlist });
        }


        console.log("delete info", info);
      }
    }
    return (


      <div>
        <div className="booking_pickup pilot-booking payment-booking">
          {this.state.loading == true &&
            <CircularProgress />}
          {/* <button className="btn btn-linear-color remind">Remind Late</button>
                   <img src={images.edit_icon} className="img-fluid edit-details"/> */}

          <div className="pickup-details pick-drops">
            <div className="payment-details-booking clearfix">
              <div className="payment-header clearfix">
                <label className="total-pay">Total Amount</label>
                <div className="total-input">₹ {paymentDetails && paymentDetails.invoice_amount}</div>
              </div>
              <div className="pick-whole">

                <div className="customer_detail payments clearfix">
                  <ul className="customer-other payment-other">

                    <li style={{ display: 'flex' }}>
                      <div className="other-part" style={{ opacity: customersDetails && customersDetails.status_id != 3 ? 1 : 0.5 }} onClick={() => customersDetails && customersDetails.status_id != 3 ? this.uploadInvoiceOpen('invoice_amount') : ''}>Change Advisory Fees</div>
                      <div className="other-part">View Estimate</div>
                    </li>
                    <li style={{ display: 'flex' }}>
                      <div className="other-part" style={{ opacity: customersDetails && customersDetails.status_id != 3 ? 1 : 0.5 }} onClick={() => customersDetails && customersDetails.status_id != 3 ? this.uploadInvoiceOpen('upload_invoice') : ''}>Upload Invoice</div>
                      <div className="other-part" onClick={() => this.uploadInvoiceOpen('view_invoice')}>View Invoice</div>
                    </li>

                    {/* <li style={{display:'flex'}}>
                                    <div className="other-part">Send Pay Link - SMS</div>
                                    <div className="other-part">Send Pay Link - Email</div>
                                </li> */}

                    {/* <li>
                                <div className="other-part clear-payment clearfix">Clear Payment</div>
                                    
                                </li> */}
                  </ul>
                </div>
              </div>
            </div>



          </div>
        </div>


        <Dialog open={this.state.uploadInvoiceOpen} className="shopbox upload-invoice">
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.deleteClose()} /></div>
          {this.state.popupDisplay == 'upload_invoice' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Upload Invoice
                   </div>

              <div className="form-group">
                <label>Invoice Amount</label>
                <input type="text" className="form-control" name="invoice_amount" value={this.state.upload_fields.invoice_amount.value} onChange={(e) => this.uploadvalidations(e.target.value, e.target.name)} />
                <span className="error_msg_adddealer">{this.state.upload_fields['invoice_amount'].error && this.state.upload_fields['invoice_amount'].error}</span>

              </div>
              <div class="form-group position_set" style={{ position: 'relative' }}>
                <label>Service Advisor *</label>
                <Select value={[parseInt(this.state.upload_fields.service_advisor.value)]} onChange={(value) => this.uploadvalidations(value, 'service_advisor')} name="service_advisor" >
                  {service_advisor && service_advisor.length > 0 && service_advisor.map((item) =>
                  {
                    return (
                      <Option value={item.id}>{item.name}</Option>
                    )
                  })}
                </Select>
                <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                <span className="error_msg_adddealer">{this.state.upload_fields['service_advisor'].error && this.state.upload_fields['service_advisor'].error}</span>

                {/* </div> */}

              </div>
              <div className="form-group">
                <label>Upload Invoice Copy <span style={{ color: 'red', marginLeft: 10 }}>{this.state.fileupload_error}</span></label>
                <div style={{ width: '100%' }}>
                  <Dragger {...props}  >
                    <div><button className="upload_btn">Upload Files</button></div>
                    <p className="ant-upload-text"></p>
                    <p className="ant-upload-hint">
                      Files can be in .jpg or .pdf format
                            </p>
                  </Dragger>

                  <span className="error_msg_driver">{this.state.upload_fields['upload_image'].error && this.state.upload_fields['upload_image'].error}</span>
                </div>

              </div>

              {this.state.responseMsg && this.state.responseMsg ?
                <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
              }
              {this.state.responseMsg && !this.state.responseMsg ?
                <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
              }
              <div className="form-group submit-btns" style={{ paddingBottom: 20 }}><button className="btn btn-blue" onClick={() => this.SubmitPopup()}>Submit </button></div>

            </div>
          }
          {this.state.popupDisplay == 'invoice_amount' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Change Advisory Fees
                    </div>

              <div className="form-group">
                <label>Invoice Amount</label>
                <input type="text" className="form-control" name="invoice_amount" value={this.state.fields.invoice_amount.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                <span className="error_msg_adddealer">{this.state.fields['invoice_amount'].error && this.state.fields['invoice_amount'].error}</span>
              </div>
              {this.state.responseMsg && this.state.responseMsg ?
                <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
              }
              {this.state.responseMsg && !this.state.responseMsg ?
                <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
              }
              <div className="form-group submit-btns" style={{ paddingBottom: 20 }}><button className="btn btn-blue" onClick={() => this.SubmitPopupAdvisory()}>Submit </button></div>

            </div>
          }
          {this.state.popupDisplay == 'view_invoice' &&
            <div className="modalcntntcls" style={{ width: '100%', padding: 20, }}>
              <img src={paymentDetails && paymentDetails.invoice_doc} className="img-fluid" />
            </div>

          }



        </Dialog>

      </div>

    )
  }
  componentDidMount()
  {
    this.props.sendbookingid && this.props.sendbookingid(this.props.match.params.id, this.props.match.params.assignid)
  }
}
export default BookingPaymentDetails;