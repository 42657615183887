import React, { Component } from 'react';
import apiservices from '../../../helpers/apiservices';
import images from "../../../helpers/images";
import ValidationLibrary from '../../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
export default class Goals extends Component {
  constructor(props){
    super(props);
    this.state={
      SlotAllocation:null,
      openDialog:false,
      deleteID:null,
      editID:null,
      apiinsert:null,
      fields:{
        name:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        task:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
        amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
      },
      loginbonus_fields:{
        task_name:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
      },
    }
  }
  loadGoalsList =() =>{
    const token=localStorage['accesstoken'];
    var obj={};
    apiservices.listdrivergoals(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  this.setState({SlotAllocation:response.response})
                  console.log("goals",response)
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
componentWillMount(){
    this.loadGoalsList();
}
addForm =(data,item,apiinsert)=>{
  console.log("apiinsert",apiinsert)
  var fields=this.state.fields;
  fields['name'].value='';
  fields['amount'].value='';
  fields['task'].value='';
  var loginbonus_fields=this.state.loginbonus_fields;
  loginbonus_fields['amount'].value='';
  loginbonus_fields['amount'].error='';
  loginbonus_fields['task_name'].value='';
  loginbonus_fields['task_name'].error='';
    this.setState({openDialog:true,popupDisplay:data,responseMsg:'',deleteID:item,apiinsertfield:apiinsert,deleteMsg:'',fields,loginbonus_fields})
}
checkvalidations=(value,key)=>{
   
    var fields =this.state.fields;
    fields[key].value=value;
    console.log("value",value);
    var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
    // console.log(checkstatus);
    
    fields[key].error=checkstatus.msg;
    var getallkeys=Object.keys(fields);
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      this.setState({error:true})
      //errorblock
    }else{
      this.setState({error:false})

      //successblock
    }
    this.setState({fields});
  }
  popupSubmit =(data)=>{
      var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
        if(this.state.apiinsertfield=='daily_task'){
        this.goalsDailyTaskInsertData();
        }
        if(this.state.apiinsertfield=='week_task_incentive'){
          this.goalsWeekTaskIncentiveInsertData();
        }
        if(this.state.apiinsertfield=='week_incentive'){
          this.goalsWeekIncentiveInsertData();
        }
      }
      this.setState({fields});
  }
  goalsDailyTaskInsertData =()=>{
    var addeditapi=this.state.editID==null?apiservices.adddailytaskgoals:apiservices.editdailytaskgoals;
    const token=localStorage['accesstoken'];
    if(this.state.editID==null){
    var obj={stage_name:this.state.fields.name.value,no_of_task:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }else{
    var obj={id:this.state.editID,stage_name:this.state.fields.name.value,no_of_task:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }
    addeditapi(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                var self=this;
                setTimeout(() => {
                  this.loadGoalsList();
                  self.setState({openDialog:false})
                }, 3000);
                  this.setState({responseMsg:response.message})
                }
              else{
                this.setState({responseMsg:response.message})
              }
          })
   
  }
  goalsWeekTaskIncentiveInsertData =()=>{
    var addeditapi=this.state.editID==null?apiservices.addWweklytaskgoals:apiservices.editweeklytaskgoals
    const token=localStorage['accesstoken'];
    if(this.state.editID==null){
    var obj={stage_name:this.state.fields.name.value,no_of_task:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }else{
    var obj={id:this.state.editID,stage_name:this.state.fields.name.value,no_of_task:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }
    addeditapi(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                var self=this;
                setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false})
                }, 3000);
                  this.setState({responseMsg:response.message})
                  console.log("goals",response)
                }
              else{
                this.setState({responseMsg:response.message})
              }
          })
   
  }
  goalsWeekIncentiveInsertData =()=>{
    var addeditapi=this.state.editID==null?apiservices.addWeeklyincentivegoals:apiservices.editweeklyincentivegoals
    const token=localStorage['accesstoken'];
    if(this.state.editID==null){
    var obj={incentive_name:this.state.fields.name.value,no_days_tasks:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }else{
    var obj={id:this.state.editID,incentive_name:this.state.fields.name.value,no_days_tasks:this.state.fields.task.value,amount:this.state.fields.amount.value};
    }
    addeditapi(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                var self=this;
                setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false})
                }, 3000);
                  this.setState({responseMsg:response.message})
                  console.log("goals",response)
                }
              else{
                this.setState({responseMsg:response.message})
              }
          })
   
  }
  closeDialog=()=>{
    var fields=this.state.fields;
    fields['name'].value='';
    fields['name'].error='';
    fields['task'].value='';
    fields['task'].error='';
    fields['amount'].value='';
    fields['amount'].error='';
this.setState({openDialog:false,fields,responseMsg:''}) 
}
editGoals =(item,data,apiinsert)=>{
  console.log("apiinser",apiinsert)
  console.log("edit item",data)
  var fields=this.state.fields;
  if(apiinsert=='week_incentive'){
    fields['name'].value=data.incentive_name;
    fields['task'].value=data.no_days_tasks;
    fields['amount'].value=data.amount;
  } 
    else{
    fields['name'].value=data.stage_name;
  fields['task'].value=data.no_of_task;
  fields['amount'].value=data.amount;
  }
  var loginbonus_fields=this.state.loginbonus_fields;
  
    loginbonus_fields['task_name'].value=data.name;
    loginbonus_fields['amount'].value=data.amount;
  
  this.setState({popupDisplay:item,openDialog:true,editID:data.id,fields,responseMsg:'',apiinsertfield:apiinsert,loginbonus_fields})
}
deleteGoalsDetails=()=>{
  const token=localStorage['accesstoken'];
  var obj={id:this.state.deleteID};
  if(this.state.apiinsertfield=='daily_task'){
    apiservices.deletedailytaskgoals(obj,token)
        .then((response)=>{
          var self=this;
            // this.setState({loading:false});
            if(!response.error){
              setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false,deleteID:null})
                }, 3000);
                 this.setState({deleteMsg:response.message})
              }
            else{
              this.setState({deleteMsg:response.message})
            }
        })
      }else if(this.state.apiinsertfield=='week_task_incentive'){
        apiservices.deleteweeklytaskgoals(obj,token)
        .then((response)=>{
          var self=this;
            // this.setState({loading:false});
            if(!response.error){
              setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false,deleteID:null})
                }, 3000);
                 this.setState({deleteMsg:response.message})
              }
            else{
              this.setState({deleteMsg:response.message})
            }
        })
      }
      else if(this.state.apiinsertfield=='week_incentive'){
        apiservices.deleteweeklyincentivegoals(obj,token)
        .then((response)=>{
          var self=this;
            // this.setState({loading:false});
            if(!response.error){
              setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false,deleteID:null})
                }, 3000);
                 this.setState({deleteMsg:response.message})
              }
            else{
              this.setState({deleteMsg:response.message})
            }
        })
      }else if(this.state.apiinsertfield=='login_bonus'){
        
        apiservices.deleteloginbonusgoals(obj,token)
        .then((response)=>{
          var self=this;
            // this.setState({loading:false});
            if(!response.error){
              setTimeout(() => {
                  self.loadGoalsList();
                  self.setState({openDialog:false,deleteID:null})
                }, 3000);
                 this.setState({deleteMsg:response.message})
              }
            else{
              this.setState({deleteMsg:response.message})
            }
        })
      }else{

      }
      }
      loginbonusvalidations=(value,key)=>{
   
        var loginbonus_fields =this.state.loginbonus_fields;
        loginbonus_fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,loginbonus_fields[key].validations);
        // console.log(checkstatus);
        
        loginbonus_fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(loginbonus_fields);
        var filtererrors=getallkeys.filter((obj)=>loginbonus_fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({loginbonus_fields});
      }
      loginBonusSubmit =(data)=>{
          var loginbonus_fields =this.state.loginbonus_fields;
          var getallkeys=Object.keys(loginbonus_fields);
          getallkeys.map((obj)=>{
          var checkstatus=ValidationLibrary.checkValidation(loginbonus_fields[obj].value,loginbonus_fields[obj].validations);
          loginbonus_fields[obj].error=checkstatus.msg;
          })
          var filtererrors=getallkeys.filter((obj)=>loginbonus_fields[obj].error!="");
          if(filtererrors.length>0){
            //errorblock
            this.setState({error:true})
          }else{
            //successblock
            this.setState({error:false});
            this.loginBonusInsertData();
          }
          this.setState({loginbonus_fields});
      }
      loginBonusInsertData =()=>{
        var addeditapi=this.state.editID==null?apiservices.addloginbonusgoals:apiservices.editloginbonusgoals
        const token=localStorage['accesstoken'];
        if(this.state.editID==null){
        var obj={name:this.state.loginbonus_fields.task_name.value,amount:this.state.loginbonus_fields.amount.value};
        }else{
        var obj={id:this.state.editID,name:this.state.loginbonus_fields.task_name.value,amount:this.state.loginbonus_fields.amount.value};
        }
        addeditapi(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    var self=this;
                    setTimeout(() => {
                      self.loadGoalsList();
                      self.setState({openDialog:false})
                    }, 3000);
                      this.setState({responseMsg:response.message})
                      console.log("goals",response)
                    }
                  else{
                    this.setState({responseMsg:response.message})
                  }
              })
       
      }
  render() {
    console.log("slot alloate",this.state.SlotAllocation);
    const {SlotAllocation}=this.state;
    
    return (
        <div>
            <div className="container">
      <div className="goals_view"> 
      <div className="heading_mainview"><div className="daily_task">Daily Task Incentives</div></div>
      {/* <div className="daily_task">Daily Task Incentives <div>Add</div></div> */}
          <table className="daily_table table-responsive">
              <thead>
                  <tr>
                  <th className="slot_head name_width"><span>Name</span></th>
                  <th className="slot_head"><span>Task</span></th>            
                  <th className="slot_head"><span>Amount</span></th>
                  </tr>
              </thead>
              <tbody>
                {SlotAllocation&&SlotAllocation.dailyTaskIncentive.length>0&&SlotAllocation.dailyTaskIncentive.map((item)=>{
                    return(
                        <tr>
                      
                        <td><div className="slots">{item.stage_name}</div></td>
                        <td><div className="slots">{item.no_of_task}</div></td>
                        <td><div className="slots">{item.amount}</div></td>
                        <td><img src={images.edit_icon} onClick={()=>this.editGoals('add',item,'daily_task')} style={{ width: 38,marginRight:20 }} /></td>
                        {/* <td><input type="text" className="time"/></td> */}
                    {/* <td><input type="text" className="time"/></td> */}
                        
                        </tr>
                    )
                })}
                   
                     
              </tbody>
          </table>
          {SlotAllocation&&SlotAllocation.dailyTaskIncentive.length==0&&
          <div style={{color:'#0681C0'}}>No Records in Daily Task Incentives </div>
          }
          <br/>
          <div className="heading_mainview"><div className="daily_task">Weekly Task Incentives</div></div>
          {/* <div className="daily_task">Weekly Task Incentives</div> */}
          <table className="daily_table table-responsive">
              <thead>
                  <tr>
                  <th className="slot_head name_width"><span>Name</span></th>
                  <th className="slot_head"><span>Task</span></th>            
                  <th className="slot_head"><span>Amount</span></th>
                  </tr>
              </thead>
              <tbody>
                {SlotAllocation&&SlotAllocation.listWeeklyTaskIncentive.length>0&&SlotAllocation.listWeeklyTaskIncentive.map((item)=>{
                    return(
                        <tr>
                      
                      <td><div className="slots">{item.stage_name}</div></td>
                        <td><div className="slots">{item.no_of_task}</div></td>
                        <td><div className="slots">{item.amount}</div></td>
                         <td><img src={images.edit_icon} onClick={()=>this.editGoals('add',item,'week_task_incentive')} style={{ width: 38,marginRight:20 }} /></td> 
                      
                      </tr>
                    )
                })}
                    
                                       
              </tbody>
          </table>
          {SlotAllocation&&SlotAllocation.listWeeklyTaskIncentive.length==0&&
          <div style={{color:'#0681C0'}}>No Records in Weekly Task Incentives </div>
          }
          <br/>
          <div className="heading_mainview"><div className="daily_task">Login Bonus</div></div>
          {/* <div className="daily_task">Login Bonus</div> */}
          <table className="daily_table table-responsive">
              <thead>
                  <tr>
                  <th className="slot_head name_width"><span>Name</span></th>
                  <th className="slot_head"><span>Amount</span></th>
                  </tr>
              </thead>
              <tbody>
                {SlotAllocation&&SlotAllocation.listLoginBonus.length>0&&SlotAllocation.listLoginBonus.map((item)=>{
                    return(
                        <tr>
                      
            <td><div className="slots">{item.name}</div></td>
            <td><div className="slots">{item.amount}</div></td>
                   {/**/}
                    <td><img src={images.edit_icon}  onClick={()=>this.editGoals('login',item,'login_bonus')} style={{ width: 38,marginRight:20 }} /></td> 
                   <td><input type="text" className="time visible_hidden"/></td> 
                      
                      </tr> 
                    )
                })}
                   
                     
                  
              </tbody>
          </table>
          {SlotAllocation&&SlotAllocation.listLoginBonus.length==0&&
          <div style={{color:'#0681C0'}}>No Records in Login Bonus </div>
          }
          <br/>
          {/* <div className="heading_mainview"><div className="daily_task">Weekly Incentives</div><div className="daily_task add_btn_goals" onClick={()=>this.addForm('add','','week_incentive')} style={{cursor:'pointer'}}><img src={images.blue_add} className="goals_add_img"/>Add</div></div> */}
          {/* <table className="daily_table">
              <thead>
                  <tr>
                  <th className="slot_head name_width"><span>Name</span></th>
                  <th className="slot_head"><span>Max.Days</span></th>            
                  <th className="slot_head"><span>Amount/Day</span></th>
                  </tr>
              </thead>
              <tbody>
                {SlotAllocation&&SlotAllocation.listWeeklyIncentive.length>0&&SlotAllocation.listWeeklyIncentive.map((item)=>{
                    return(
                        <tr>
                      
                    <td><div className="slots" style={{fontSize:13}}>{item.incentive_name}</div></td>
                        <td><div className="slots">{item.no_days_tasks}</div></td>
                    <td><div className="slots">{item.amount}</div></td>
                    <td><img src={images.edit_icon}  onClick={()=>this.editGoals('add',item,'week_incentive')} style={{ width: 38,marginRight:20 }} /><img src={images.delete_icon} style={{ width: 38 }} onClick={()=>this.addForm('delete',item.id,'week_incentive')}/></td>
                        
                        </tr>
                    )
                })}
                   
                      
                   
              </tbody>
          </table>
          {SlotAllocation&&SlotAllocation.listWeeklyIncentive.length==0&&
          <div style={{color:'#0681C0'}}>No Records in Weekly Incentives </div>
          }
          */}
     </div>
     </div>
     <Dialog  open={this.state.openDialog}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    {this.state.popupDisplay=='add'&&
                     <div className="modalcntntcls">
                     <div className="delete_text">
                         {this.state.apiinsertfield=='daily_task'?'New Daily Task Incentives':''}
                         {this.state.apiinsertfield=='week_task_incentive'?'New Weekly Task Incentives':''}
                         {this.state.apiinsertfield=='week_incentive'?'Add New Weekly Incentives':''}
                     </div>
                     <div>
                     <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Task Name</label>
                          
                          <input type="text" className="form-control input_paused" name="name" value={this.state.fields.name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['name'].error&&this.state.fields['name'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>No of Task </label>
                          
                          <input type="text" className="form-control input_paused" name="task" value={this.state.fields.task.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['task'].error&&this.state.fields['task'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Amount</label>
                          
                          <input type="text" className="form-control input_paused" name="amount" value={this.state.fields.amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['amount'].error&&this.state.fields['amount'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.responseMsg&&!this.state.responseMsg} className="delete_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                    </div>
                     
                      
                      </div>
                    }
                    {this.state.popupDisplay=='delete'&&
                     <div className="">
                  <div className="approval_content">Are you sure you want to remove this item?</div>
                  {this.state.deleteMsg&&this.state.deleteMsg?
                   <div className="failure_msg">{this.state.deleteMsg&&this.state.deleteMsg}</div> :''
                     }
                   {this.state.deleteMsg&&!this.state.deleteMsg?
                   <div className="success_msg">{this.state.deleteMsg&&this.state.deleteMsg}</div> :''
                   }
                  <div className="deletebtns_group">
                  <div className="approval_btn_main ">
  <button className="cancel_btn_main" onClick={()=>this.setState({openDialog:false})}>No, Cancel</button>
  </div>
  <div className="approval_btn_main">
  <button className="approval_btn" onClick={()=>this.deleteGoalsDetails()}>Yes, Delete</button>
  </div>
  </div>
                      </div>
                    }
                    {this.state.popupDisplay=='login'&&
                     <div className="modalcntntcls">
                     <div className="delete_text">
                         New Login Bonus
                     </div>
                     <div>
                     <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Task Name</label>
                          
                          <input type="text" className="form-control input_paused" name="task_name" value={this.state.loginbonus_fields.task_name.value} onChange={(e) => this.loginbonusvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.loginbonus_fields['task_name'].error&&this.state.loginbonus_fields['task_name'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Amount</label>
                          
                          <input type="text" className="form-control input_paused" name="amount" value={this.state.loginbonus_fields.amount.value} onChange={(e) => this.loginbonusvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.loginbonus_fields['amount'].error&&this.state.loginbonus_fields['amount'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.responseMsg&&!this.state.responseMsg} className="delete_submit" onClick={()=>this.loginBonusSubmit()}>Submit</button>
                       </div>
                    </div>
                     
                      
                      </div>
                    }
                        
                
                </Dialog> 
     </div>
    );
  }
}
