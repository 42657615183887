import React, {Component} from 'react';
import './style.css';
 
class SubMenu extends Component{

    constructor(props){
         
        super(props);

        this.state = {

         



        }
    }



    render(){

           return(

               <div>
   
<div class="container clearfix">
                   <div class="second-menus">
                   <nav className="navbar navbar-expand-md  navbar-dark">
    
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                 
                                 
                                <a class="nav-link menu-active" href="#">Add SVC</a> 
                            </li>
                            <li class="nav-item">
                                 
                                 
                                <a class="nav-link" href="#">SVC List</a>
                             </li>
                            <li class="nav-item">
                              
                                 
                                <a class="nav-link" href="#">New User Request</a> 
                            </li>
                            <li class="nav-item">
                                 
                                 
                                <a class="nav-link" href="#">Designation</a> 
                            </li>
                            <li class="nav-item">
                                 
                                 
                                <a class="nav-link" href="#">Trends</a> 
                            </li>
                            <li class="nav-item">
                                 
                                 
                                <a class="nav-link" href="#">Performance</a> 
                            </li>
                            <li class="nav-item">
                                 
                                
                                <a class="nav-link" href="#">Targets</a> 
                            </li>
                        </ul>
                    </div>
                </nav>
                </div>
  

                </div>
                  
               </div>
           ) 
    }
}
 
export default SubMenu;

    
