import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import PilotMaster from "../PilotMaster";
import BookingMishaps from '../../Pages/BookingMishaps';
import AppdataMain from '../../Pages/AppdataMain';
import AttendanceMain from '../../Pages/AttendanceMain';
import Accidents from '../../Pages/Accidents';
import ChannelPartner from '../../Pages/ChannelPartner';
import Notifications from '../../Pages/Notifications';
import DriverPayments from '../../Pages/DriverPayments';
import DriverPerformanceMain from '../../Pages/DriverPerformanceMain';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import './style.css';
import ComplaintsMain from '../ComplaintsMain';
class DriverMain extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {

    return (
      <div className="container">
        <div class="driver-menu-tabs">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-row driver_mainview" selectedIndex={this.state.selectedIndex}
                  onSelect={this.handleSelect}>
                  <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/pilot_master/')&&"activelink"}`} exact={true} to={`${this.props.match.path}/pilot_master/pilot_details/`} activeClassName="active_cls">Pilot Master</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/attendance/drivers`} activeClassName="active_cls">Attendance</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/complaints/')&&"activelink"}`}   to={`${this.props.match.path}/complaints/active/`} activeClassName="active_cls">Complaints</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/driver_payments/')&&"activelink"}`} to={`${this.props.match.path}/driver_payments/conveyance`} activeClassName="active_cls">Payments</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/notifications/`} activeClassName="active_cls">Notifications</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/driver_performance/')&&"activelink"}`} to={`${this.props.match.path}/driver_performance/slotwise/`} activeClassName="active_cls">Performance</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/channel_partner/`} activeClassName="active_cls">Channel Partner</NavLink>
                  </Nav.Item>
                  <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/appdata/`} activeClassName="active_cls">App Data</NavLink>
                  </Nav.Item>
                  {/* <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/mishaps/`} activeClassName="active_cls">Mishaps</NavLink>
                  </Nav.Item> */}
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/accidents/`} activeClassName="active_cls">Accidents</NavLink>
                </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
              <Switch>
                <Route path={`${this.props.match.path}/pilot_master`} render={props => (<PilotMaster parentProps={this.props} {...props} />)} />
                <Route path={`${this.props.match.path}/appdata`} render={props => (<AppdataMain  {...props} />)} />
                <Route path={`${this.props.match.path}/attendance/drivers`} render={props => (<AttendanceMain  {...props} />)} />
                <Route path={`${this.props.match.path}/notifications`} render={props => (<Notifications  {...props} />)} />
                <Route path={`${this.props.match.path}/complaints`} render={props => (<ComplaintsMain  {...props} />)} />
                <Route path={`${this.props.match.path}/channel_partner`} render={props => (<ChannelPartner  {...props} />)} />
                <Route path={`${this.props.match.path}/driver_payments`} render={props => (<DriverPayments  {...props} />)} />
                <Route path={`${this.props.match.path}/driver_performance`} render={props => (<DriverPerformanceMain  {...props} />)} />
                {/* <Route path={`${this.props.match.path}/mishaps`} render={props => (<BookingMishaps  {...props} />)} /> */}
                <Route  path={`${this.props.match.path}/accidents`} render={props => (<Accidents  {...props} />)} />
                
              </Switch>
              </Col>
            </Row>
        </div>
      </div>
    )

  }
  componentDidMount() {
    this.props.receivePageTitle && this.props.receivePageTitle('Pilots');
  }

}


export default DriverMain;
