import React, {Component} from 'react';
import Image from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { Table, Tag, Pagination, Select} from 'antd';
 import  './styles.css';
import { DatePicker } from 'antd';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var svcWalletHeader = [{'id' : 1,  'date': 'Date','time':'Time', 'svc_name' : 'SVC Name', 'svc_id' : 'SVC ID',  'credit_amount' : 'Credit Amount','debit_amount':'Debit Amount','balance_amount':'Total Amount'}];


class SVCWallet extends Component{
    constructor(props){
        super(props);
        this.state = {
            svcWalletHeader : svcWalletHeader,
            listSVCWallet : [],            
            currentPage : 1,
            pageSize:10,
            fromDate:null,
            toDate:null,
            svcwallet_fields:{
                svc_center:{validations:[],error:null,value:''},
              },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadSVCWallet();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadSVCWallet(); 
        });
      }
      loadSVCWallet=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center:this.state.svcwallet_fields.svc_center.value,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("actve", obj);     
        apiservices.listsvcwallethistory(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({listSVCWallet:response.response, numbercount:calc})
                      console.log("svc wallet", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    componentWillMount(){
        this.loadSVCWallet();
        this.loadSeriveCenter();
    }
    checkfiltervalidations =(data)=>{
        console.log("priority",data);
       
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadSVCWallet();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadSVCWallet();
        })
      }
     
    loadSeriveCenter=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.bookingsvclistdropdown(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    this.setState({svc_center:response.response})
                    console.log("login response",response);
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    checksvcfilter =(data)=>{
        var svcwallet_fields=this.state.svcwallet_fields;
        svcwallet_fields['svc_center'].value=data;
        this.setState({svcwallet_fields},function(){
          this.loadSVCWallet();
        })
      }
render(){
    const offset = moment().utcOffset();
    const {svc_center}=this.state;
    return(
        <div>
        {/* <div>
                                            <button className="genrate_btn" >
                                                Generate Report
                                            </button>
                                            </div> */}
        <div className="settlment_advice_main">
              <div className="container1">

                                                          
              <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      {/* <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/> */}
                                  </div>
                             </div>
                             <div className="col-md-9">
                             
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from payment_from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>


                                    <div className="payment-dropdown float-right mr-2"> <span className="payment">SVC Name:</span>
                                    <Select value={[this.state.svcwallet_fields.svc_center.value?parseInt(this.state.svcwallet_fields.svc_center.value):'']}  onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                             <Option value="">All</Option>
                         {svc_center&&svc_center.length>0&&svc_center.map((item)=>{
                              return(
                               
                               
                              <Option value={item.id}>{item.centre_name}</Option>
                              
                              )
                            })} 
                            
                           </Select> 
                            </div>
                             
                             
                             </div>
                         </div>
                    </div>

              

                                            
                                 <div className="table-list customer_table">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.svcWalletHeader && this.state.svcWalletHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.date}</th>
                                                <th>{item.time}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.credit_amount}</th>
                                                <th>{item.debit_amount}</th>
                                                <th>{item.balance_amount}</th>
                                                
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                    {this.state.listSVCWallet &&this.state.listSVCWallet.length>0&&this.state.listSVCWallet.map((item) => {

                                        return(
                                            <tr>
                                               <td>{moment(item.created_at).format('DD/MM/YY')}</td>
                                                <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.svc_center_id}</td>
                                                <td>{item.credit_amount}</td>
                                                <td>{item.debit_amount}</td>
                                                <td>{item.total_amount}</td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {this.state.listSVCWallet&&this.state.listSVCWallet.length==0&&
                            <div style={{marginBottom:10}}>No Records Found </div>
                            }

 
                        </div>
                     </div>
                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />

                     
             </div>
             </div>
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default SVCWallet;