import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
// import ActiveDriverTable from "../ActiveDriverTable";
// import PickUpMain from "./PickUp/PickUpMain";
// import DropOffMain from "./DropOff/DropOffMain";
// import ChauffeurMain from "./Chauffeur/ChauffeurMain";
// import PickUpDropOffMain from "./PickUpDropOff/PickUpDropOffMain";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
// import ActiveMain from './ActiveMain';
// import ClosedMain from './ClosedMain';
import Conveneyance from './Conveneyance';
import Incentives from './Incentives';
import Decrement from './Decrement';
import Increment from './Increment';
import Payout from './Payout';
class DriverPayments extends Component {

    constructor(props) {
        super(props);
        this.state = {

            pilotMaster : true,
            addDriver : false
        }
    }

    AddDriver = () => {
  console.log("adddriver clicj")
        this.setState({pilotMaster : false});
         this.setState({addDriver  : true})
    }
    render() {
        return (
            <div>
                {this.state.pilotMaster &&  
                
                <div class="add-booking-menu driver_payments">
                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Row>
                             <Col sm={12}> 
                                                           
                           <Nav variant="pills" className="flex-row add_booking" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/conveyance`} activeClassName="booking_active_cls">Conveyance</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/incentives/')&&"booking_active_cls"}`} to={`${this.props.match.path}/incentives`} activeClassName="booking_active_cls">Incentives</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/increment/')&&"booking_active_cls"}`} to={`${this.props.match.path}/increment`} activeClassName="booking_active_cls">Increment</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/decrement/')&&"booking_active_cls"}`} to={`${this.props.match.path}/decrement`} activeClassName="booking_active_cls">Decrement</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/payout/')&&"booking_active_cls"}`} to={`${this.props.match.path}/payout`} activeClassName="booking_active_cls">Payout</NavLink>
                </Nav.Item>
              </Nav>
              <div className="generate-btn_payments"><button className="btn btn-linear-color">Generate Report</button></div>
              </Col> 
                            <Col sm={12}>                            

                                <Switch>
                                 <Route exact path={`${this.props.match.path}/conveyance`} render={props => (<Conveneyance {...props}/>)} />
                                 <Route exact path={`${this.props.match.path}/incentives`} render={props => (<Incentives {...props}/> )} />
                                 <Route exact path={`${this.props.match.path}/increment`} render={props => (<Increment {...props}/> )} />
                                 <Route exact path={`${this.props.match.path}/decrement`} render={props => (<Decrement {...props}/> )} />
                                 <Route exact path={`${this.props.match.path}/payout`} render={props => (<Payout {...props}/> )} />
                
              </Switch>
                            </Col>
                        </Row>
                    {/* </Tab.Container> */}
                </div>
                }


               
            </div>

            
        )

    }

}


export default DriverPayments;