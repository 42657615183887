import React, {Component} from 'react';
import './styles.css';
import images from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';


const { RangePicker } = DatePicker;
const { Option } = Select;
var designationHeader = [{'id' : 1,  'permissions' : 'Permissions', 'vp': 'VP', 'gm' : 'GM', 'sm' : 'SM','cre':'CRE',  'advisor' : 'Advisor', 'accountant' : 'Accountant'}];

var designationList=[
{id:1,'permissions':'Dashboard'},
{id:1,'permissions':'Service Center'},
{id:1,'permissions':'Centre List'},
{id:1,'permissions':'SVC Booking'},
{id:1,'permissions':'SVC User'},
{id:1,'permissions':'SVC Payments'},
{id:1,'permissions':'Wallet'},
{id:1,'permissions':'Manage Bookings'},
{id:1,'permissions':'Vehicle Details'},
{id:1,'permissions':'Manage Users'},
{id:1,'permissions':'Manage Wallet'},
{id:1,'permissions':'Create Bookings'},
{id:1,'permissions':'SVC Details'},
{id:1,'permissions':'Permissions'}


]
class Designation extends Component{

    constructor(props){

        super(props);

        this.state = {
            designationHeader : designationHeader,

            designationList : designationList,
             
            currentPage : 1,
            pageSize:10,
            openapproval:false,
            rejectapproval:false,
            openDialog:false,
            popupDisplay:false,
            loading:false,
            designationDrop:[],
            fields: {
                designation: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
            },
            editfields: {
                select_role: { validations: [{ name: 'required' }], error: null, value: '' },
                replace_word: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
                
            },
            deletefields: {
              select_role: { validations: [{ name: 'required' }], error: null, value: '' },
              
          }


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadListDesignation =()=>{
        const token = localStorage['accesstoken'];
        var obj={};
        console.log("obj", obj);
          apiservices.getSvcDesignation(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({designationList:response.response, numbercount:calc})
                        console.log("designation reponse", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
          this.loadListDesignation();
          this.loadDesignationDropdown();
      }
      openDialog =(data)=>{
        var editfields=this.state.editfields;
        editfields['replace_word'].value='';
        editfields['replace_word'].error='';
          this.setState({openDialog:true,popupDisplay:data,success_msg:'',failure_msg:'',editfields})
          this.loadDesignationDropdown();
          console.log("data",data)
      }
      checkvalidations = (value, key) => {


        var fields = this.state.fields;
        fields[key].value = value;
        var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
        // console.log(checkstatus);
        fields[key].error = checkstatus.msg;
        var getallkeys = Object.keys(fields);
        var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
        if (filtererrors.length > 0) {
          this.setState({ error: true })
          //errorblock
        } else {
          this.setState({ error: false })
    
          //successblock
        }
        this.setState({ fields });
      }
      deletevalidation = (value, key) => {


        var deletefields = this.state.deletefields;
        deletefields[key].value = value;
        var checkstatus = ValidationLibrary.checkValidation(value, deletefields[key].validations);
        // console.log(checkstatus);
        deletefields[key].error = checkstatus.msg;
        var getallkeys = Object.keys(deletefields);
        var filtererrors = getallkeys.filter((obj) => deletefields[obj].error != "");
        if (filtererrors.length > 0) {
          this.setState({ error: true })
          //errorblock
        } else {
          this.setState({ error: false })
    
          //successblock
        }
        this.setState({ deletefields });
      }
      popupSubmit =(data)=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          if(data=='add'){
            this.addDesignation();
          }
        // alert('hii');
        }
        this.setState({fields});
    }
    loadDesignationDropdown = () => {
      this.setState({loading:true})
      const token = localStorage['accesstoken'];
      var obj = {};
      apiservices.listSvcDesignationDrop(obj, token)
          .then((response) => {
            this.setState({loading:false})
              if (!response.error) {
                  console.log("designation",response)
                  this.setState({ designationDrop: response.response, })
                  var editfields = this.state.editfields;

                  editfields['select_role'].value = response.response.length > 0 ? response.response[0].id : '';
                  var deletefields = this.state.deletefields;

                  deletefields['select_role'].value = response.response.length > 0 ? response.response[0].id : '';
                  this.setState({ editfields ,deletefields})

              } else {
                  //   this.setState({error_msg:response.message})
              }

          })
  }
    addDesignation =()=>{
      const token = localStorage['accesstoken'];
            var obj = {
                designation_name: this.state.fields.designation.value,
            };
            console.log("add user",obj)
            apiservices.addSvcDesignation(obj, token)
                .then((response) => {
                    if (!response.error) {
                      setTimeout(() => {
                        this.setState({openDialog:false})
                        this.loadListDesignation();
                        
                      }, 1000);
                        this.setState({ failure_msg: '' })
                        this.setState({ success_msg: response.message })

                    } else {
                        this.setState({ success_msg: '' })
                        this.setState({ failure_msg: response.message })
                    }

                })
    }
    closeDialog =()=>{
      var fields=this.state.fields;
      fields['designation'].value='';
      fields['designation'].error='';
        this.setState({openDialog:false,fields,success_msg:'',failure_msg:''})
    }
    editvalidations = (value, key) => {


        var editfields = this.state.editfields;
        editfields[key].value = value;
        var checkstatus = ValidationLibrary.checkValidation(value, editfields[key].validations);
        // console.log(checkstatus);
        editfields[key].error = checkstatus.msg;
        var getallkeys = Object.keys(editfields);
        var filtererrors = getallkeys.filter((obj) => editfields[obj].error != "");
        if (filtererrors.length > 0) {
          this.setState({ error: true })
          //errorblock
        } else {
          this.setState({ error: false })
    
          //successblock
        }
        this.setState({ editfields });
      }  
      editSubmit =() =>{
        var editfields =this.state.editfields;
        var getallkeys=Object.keys(editfields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(editfields[obj].value,editfields[obj].validations);
        editfields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>editfields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          this.editdesignationDetails();
        }
        this.setState({editfields}); 
      }
      editdesignationDetails =()=>{
        
        const token = localStorage['accesstoken'];
            var obj = {
                id: this.state.editfields.select_role.value,
                designation_name:this.state.editfields.replace_word.value
            };
            apiservices.editSvcDesignationName(obj, token)
                .then((response) => {
                  if (!response.error) {
                    setTimeout(() => {
                      this.setState({openDialog:false})
                      this.loadListDesignation();
                      
                    }, 3000);
                      this.setState({ failure_msg: '' })
                      this.setState({ success_msg: response.message })
  
                  } else {
                      this.setState({ success_msg: '' })
                      this.setState({ failure_msg: response.message })
                  }

                })
      }
      deleteDesignation =()=>{
        
        const token = localStorage['accesstoken'];
        var obj = {
            id: this.state.deletefields.select_role.value,
        };
        console.log("add user",obj)
        apiservices.deleteSvcDesignation(obj, token)
            .then((response) => {
                if (!response.error) {
                  setTimeout(() => {
                    this.setState({openDialog:false})
                    this.loadListDesignation();
                    
                  }, 3000);
                    this.setState({ failure_msg: '' })
                    this.setState({ success_msg: response.message })

                } else {
                    this.setState({ success_msg: '' })
                    this.setState({ failure_msg: response.message })
                }

            })
      }
      updateDesigntion =(parentindex,childindex,data) =>{
        var designationList=this.state.designationList;
        designationList[parentindex].designation[childindex].permission=data.permission==0?1:0;
        this.setState({designationList});
        console.log("update data",data);
        return;
       
      }
      updatedesignationList =() =>{
        var designationList=this.state.designationList;
        var concatarray=[];
        designationList.map((obj)=>{
          concatarray=concatarray.concat(obj.designation.map((obj1)=>{return {id:obj1.id,permission:obj1.permission}}));
          // return concatarray;
        });
        console.log("filter design",concatarray);
 const token = localStorage['accesstoken'];
            var obj = {
                "permission":concatarray
            };
            console.log("add user",obj)
            apiservices.updateSvcDesignation(obj, token)
                .then((response) => {
                    if (!response.error) {
                      this.setState({update_success_msg:response.message},function(){
                        this.loadListDesignation();
                    });
                    setTimeout(() => {
                    this.setState({update_success_msg:''})
                  
                },3000);
                      console.log("update designation",response)
                    } else {
                        this.setState({ update_success_msg: '' })
                        this.setState({ update_failure_msg: response.message })
                    }

                })
      }
render(){
const {select_role,designationList,designationDrop} =this.state;
    return(
        <div className="designation_main">
              <div className="container">
                  <div className="row">
                      <div className="col-md-4 align-left top-align">
                      <button className="edit_delete_btn" onClick={()=>this.openDialog('edit')}> Edit / Delete</button>
                                         <button className="add_design_btn" onClick={()=>this.openDialog('add')}><img src={images.add_img}/> Add New</button>
             </div>
             <div className="col-md-4 align-center ">
             <div className="designation_roles">Roles</div>
             </div>
             <div className="col-md-4 align-right top-align">
             <button className="edit_delete_btn update_design_btn" onClick={()=>this.updatedesignationList()}> Update Changes</button>
             </div>
             
             </div>
             {this.state.update_failure_msg ?
                    <div className="failure_msg" style={{textAlign:'center',padding:10,marginBottom:0}}>{this.state.update_failure_msg}</div> : ''
                }

                {this.state.update_success_msg ?
                    <div className="success_msg" style={{textAlign:'center',padding:10,marginBottom:0}}>{this.state.update_success_msg}</div> : ''
                }


          {this.state.loading==true&&
          <div><CircularProgress /> </div>
          }       

                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                  <tr>
                                    <th>Permissions</th>
                                  
                               
                                        
                                          {this.state.designationList && this.state.designationList.length>0&&this.state.designationList[0].designation&&this.state.designationList[0].designation.map((obj)=>{
                                            
                                            return(
                                              
                                            
                                              <th>{obj.designation_name}</th>
                                          
                                            )
                                          })
                                          }
                                           
                                           
                                        
                                  
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.designationList &&this.state.designationList.map((item,index) => {

                                        return(
                                            <tr>
                                                <td>{item.permission_name} </td>
                                                {item.designation&&item.designation.map((desig,desig_index)=>{
                                                  return(
                                                    <td><img src={desig.permission==0?images.checkbox_uncheck:images.checkbox_check} className="checkbox_img" onClick={()=>this.updateDesigntion(index,desig_index,desig)}/></td>
                                                  )
                                                })}
                                                
                                                
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.designationList&&this.state.designationList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                    
    </div>
    <Dialog  open={this.state.openDialog}  className={`booking_pickup`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    {this.state.popupDisplay=='add'&&
                    <div className="modalcntntcls">
                   <div className="heading_text">
                       Add New Roles
                   </div>
                   <div>
                      
                   <div className="form-group">
                       <div class="form-inline">
                          <label className="design_lable">Enter the Designation</label>
                          
                          <input type="text" className="form-control input_paused" name="designation" value={this.state.fields.designation.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['designation'].error&&this.state.fields['designation'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
                       <div className="btn_main">
                           <button className="delete_submit design_submit" onClick={()=>this.popupSubmit('add')}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
                    }               
                    {this.state.popupDisplay=='edit'&&
                    <div className="modalcntntcls edit_model">
                   
                    <div class="form-tabs" style={{marginTop:0}}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={12} className="p-0 " style={{paddingLeft: '30% !important'}}>
          <Nav variant="pills" className="flex-row serive_center"  selectedIndex={this.state.selectedIndex}
            onSelect={this.handleSelect}>
            <Nav.Item>
              <Nav.Link eventKey="first">Edit Role</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Delete Role</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={12}  className="p-0">
          <Tab.Content>
            <Tab.Pane eventKey="first">
           <div>
           <div className="form-group">
                       <div class="form-inline position-relative edit_design_drop">
                          <label className="design_lable">Select the Role you want to edit</label>
                         <Select value={[parseInt(this.state.editfields.select_role.value)]}  onChange={(value) => this.editvalidations(value,'select_role')} name="select_role" >
                          {designationDrop&&designationDrop.length>0&&designationDrop.map((item)=>{
                              return(
                              <Option value={item.id}>{item.designation_name} </Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.editfields['select_role'].error&&this.state.editfields['select_role'].error}</span>
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline">
                          <label className="design_lable">Enter the replace word</label>
                          
                          <input type="text" className="form-control input_paused" name="replace_word" value={this.state.editfields.replace_word.value} onChange={(e) => this.editvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.editfields['replace_word'].error&&this.state.editfields['replace_word'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
                       <div className="btn_main">
                           <button className="delete_submit design_submit" onClick={()=>this.editSubmit()}>Submit</button>
                       </div>
                       </div>        
    
            </Tab.Pane>
            <Tab.Pane eventKey="second">
            <div>
           <div className="form-group">
                       <div class="form-inline position-relative edit_design_drop">
                          <label className="design_lable">Select the Role you want to Delete</label>
                         <Select value={[parseInt(this.state.deletefields.select_role.value)]}  onChange={(value) => this.deletevalidation(value,'select_role')} name="select_role" >
                          {designationDrop&&designationDrop.length>0&&designationDrop.map((item)=>{
                              return(
                              <Option value={item.id}>{item.designation_name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.deletefields['select_role'].error&&this.state.deletefields['select_role'].error}</span>
                         
                        </div>
                       </div>
                       <div className="sure_text">Are you sure you want to Delete the role?</div>
                       {this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
                        <div className="deletebtns_group design_delete_group">
                      <div className="approval_btn_main design_cancel_btn">
      <button className="cancel_btn_main" onClick={() => this.closeDialog()}>No, Cancel</button>
      </div>
      <div className="approval_btn_main design_cancel_btn">
      <button className="approval_btn"  onClick={()=>this.deleteDesignation()}>Yes, Delete</button>
      </div>
      </div>
                       </div>    
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </div></div>
                        }
                </Dialog> 
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default Designation;