import React, { Component } from 'react';
import apiservices from '../../helpers/apiservices';
import { Select } from 'antd';
import images from '../../helpers/images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { GoogleApiWrapper } from 'google-maps-react';
import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
} from 'react-google-maps';
import Geocode from 'react-geocode';
import ValidationLibrary from '../../helpers/validationfunction';
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
const MapWithAMarker = withGoogleMap((props) => {
  const location = {
    lat: props.currentLocation.lat,
    lng: props.currentLocation.lng,
  };
  const onMarkerDragEnd = (event) => {
    props.sendingDrabbleLocation && props.sendingDrabbleLocation(event.latLng);
  };

  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        defaultCenter={location}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    </div>
  );
});
class SvcDetails extends Component {
  constructor(props) {
    console.log('svcdetail page', props);
    super(props);
    this.state = {
      fields: {
        servicecentre_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        dealer_master: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        brand: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        vehicle_type: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        address: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        city: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        zone_id: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        pincode: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'zipcode' },
          ],
          error: null,
          value: '',
        },
        capacity: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        potential: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        shop_type: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        authorized_private: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        // account_manager:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
        bank_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        bank_address: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        account_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        account_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        ifsc_code: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        gst_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        pan_number: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        bank_pincode: {
          validations: [
            { name: 'required' },
            { name: 'zipcode' },
            { name: 'alphaNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        full_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        mobile_number: {
          validations: [
            { name: 'required' },
            { name: 'mobile' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        dealer_code: {
          validations: [
            { name: 'required' },
            { name: 'alphaNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        contact_emailid: {
          validations: [
            { name: 'required' },
            { name: 'email' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        // singlepnt_contactname:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
        // singlepnt_contactnumber:{validations:[{name:'required'},{name:'mobile'},{name:'Noemptyspace'}],error:null,value:''},
        workshop_code: {
          validations: [
            { name: 'required' },
            { name: 'alphaNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        currentLatLng: {
          validations: [],
          error: null,
          value: { lat: 0, lng: 0 },
        },
      },
    };
  }

  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    if (key == 'address') {
      this.onChangeAddress(value);
      this.getlocationbyAddress(value, 'currentLatLng');
    }
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  updateServiceCentre = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      const token = localStorage['accesstoken'];
      var fields = this.state;

      var obj = {
        id: this.props.match.params.id,
        svc_dealer_id: this.state.fields.dealer_master.value,
        centre_name: this.state.fields.servicecentre_name.value,
        address: this.state.fields.address.value,
        longitude: null,
        latitude: null,
        city: this.state.fields.city.value,
        zone_id: this.state.fields.zone_id.value,
        pincode: this.state.fields.pincode.value,
        capacity: this.state.fields.capacity.value,
        potential: this.state.fields.potential.value,
        shop_type_id: this.state.fields.shop_type.value,
        authorised_id: this.state.fields.authorized_private.value,
        vehicle_type_id: this.state.fields.vehicle_type.value,
        brand: this.state.fields.brand.value,
        // account_manager:this.state.fields.account_manager.value,
        bank_name: this.state.fields.bank_name.value,
        bank_address: this.state.fields.bank_address.value,
        account_name: this.state.fields.account_name.value,
        account_number: this.state.fields.account_number.value,
        ifsc_code: this.state.fields.ifsc_code.value,
        gst_number: this.state.fields.gst_number.value,
        pan_number: this.state.fields.pan_number.value,
        bank_pincode: this.state.fields.bank_pincode.value,
        contact_name: this.state.fields.full_name.value,
        contact_number: this.state.fields.mobile_number.value,
        contact_email: this.state.fields.contact_emailid.value,
        dealer_code: this.state.fields.dealer_code.value,
        workshop_code: this.state.fields.workshop_code.value,
        latitude: this.state.fields.currentLatLng.value.lat,
        longitude: this.state.fields.currentLatLng.value.lng,

        // single_point_name:this.state.fields.singlepnt_contactname.value,
        // single_point_number:this.state.fields.singlepnt_contactnumber.value
      };
      console.log('update obj', obj);
      apiservices.updatesvccentre(obj, token).then((response) => {
        if (!response.error) {
          console.log('update response', response);
          this.setState({ responseMsg: response.message });
          this.props.reload('true');
        } else {
          this.setState({ responseMsg: response.message });
        }
      });
    }
  };
  loadsvcdetails = () => {
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id };
    this.props.sendid(obj.id);
    apiservices.svcdetails(obj, token).then((response) => {
      console.log('svc details', response);
      if (!response.error) {
        var fields = this.state.fields;
        fields['servicecentre_name'].value = response.response.centre_name;
        fields['dealer_master'].value = response.response.svc_dealer_id;
        fields['address'].value = response.response.address;
        fields['city'].value = response.response.city;
        fields['zone_id'].value = response.response.zone_id;
        fields['pincode'].value = response.response.pincode;
        fields['capacity'].value = response.response.capacity;
        fields['potential'].value = response.response.potential;
        fields['shop_type'].value = response.response.shop_type_id;
        fields['authorized_private'].value = response.response.authorised_id;
        fields['vehicle_type'].value = response.response.vehicle_type_id;
        fields['brand'].value = response.response.brand;
        // fields['account_manager'].value=response.response.account_manager;
        fields['bank_name'].value = response.response.bank_name;
        fields['bank_address'].value = response.response.bank_address;
        fields['account_name'].value = response.response.account_name;
        fields['account_number'].value = response.response.account_number;
        fields['ifsc_code'].value = response.response.ifsc_code;
        fields['gst_number'].value = response.response.gst_number;
        fields['pan_number'].value = response.response.pan_number;
        fields['bank_pincode'].value = response.response.bank_pincode;
        fields['full_name'].value = response.response.contact_name;
        fields['mobile_number'].value = response.response.contact_number;
        fields['contact_emailid'].value = response.response.contact_email;
        fields['dealer_code'].value = response.response.dealer_code;
        fields['workshop_code'].value = response.response.workshop_code;
        fields['currentLatLng'].value.lat = response.response.latitude;
        fields['currentLatLng'].value.lng = response.response.longitude;
        // fields['singlepnt_contactname'].value=response.response.single_point_name;
        // fields['singlepnt_contactnumber'].value=response.response.single_point_number;
        this.setState({ fields }, function () {
          this.loaddealerDropdown(this.state.fields.dealer_master.value);
          this.loadshoptypeDropdown(this.state.fields.shop_type.value);
          this.loadvehicletypeDropdown(this.state.fields.vehicle_type.value);
          this.loadauthorizedDropdown(
            this.state.fields.authorized_private.value,
          );
        });
        console.log('svc details', response.response);
      } else {
        // this.setState({error_msg:response.message})
      }
    });
  };
  componentWillMount() {
    this.loadsvcdetails();
    this.loadzoneDropdown(this.state.fields.zone_id.value);

    // this.loaddealerDropdown();
    // this.loadshoptypeDropdown();
    // this.loadvehicletypeDropdown();
    // this.loadauthorizedDropdown();
  }
  loaddealerDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.dealerdropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ dealer_master: response.response });
        var fields = this.state.fields;

        // fields['dealer_master'].value=response.response.length>0?response.response[0].id:'';
        // this.setState({fields})
        if (!value) {
          fields['dealer_master'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }

        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadshoptypeDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.shoptypedropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ shop_type: response.response });
        var fields = this.state.fields;

        // fields['shop_type'].value=response.response.length>0?response.response[0].id:'';
        // this.setState({fields})
        if (!value) {
          fields['shop_type'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }

        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };

  loadauthorizedDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.authorizeddropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ authorized_private: response.response });
        var fields = this.state.fields;
        //   fields['authorized_private'].value=response.response.length>0?response.response[0].id:'';
        //   this.setState({fields})
        if (!value) {
          fields['authorized_private'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadvehicletypeDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.vehicletypedropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ vehicle_type: response.response });
        var fields = this.state.fields;
        // fields['vehicle_type'].value=response.response.length>0?response.response[0].id:'';
        // this.setState({fields})
        if (!value) {
          fields['vehicle_type'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };

  onChangeAddress = (value) => {
    var address_url =
      'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' +
      value +
      '&components=country:ind&key=';
    const token = localStorage['accesstoken'];
    var obj = { url: address_url };
    console.log('get location obj', obj);
    apiservices.getlocation(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ predictions: response.response.predictions });
        console.log('get location', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  printAddress = () => {
    return (
      <div className='address_bardisplay'>
        <List className='address_boxlist'>
          {this.state.predictions &&
            this.state.predictions.length > 0 &&
            this.state.predictions.map((data, key) => {
              return (
                <ListItem
                  className='list-location'
                  key={key}
                  onClick={() => this.changeAddress(data)}>
                  <img src={images.map_icon} className='map_icon_cls' />
                  <p style={{ cursor: 'pointer' }}>{data.description}</p>
                </ListItem>
              );
            })}
        </List>
      </div>
    );
  };
  changeAddress = (data) => {
    console.log('change address', data);
    var fields = this.state.fields;
    fields['address'].value = data.description;
    this.setState({ fields, predictions: [] }, function () {
      this.loadMapPredictions(data.place_id);
    });
  };
  loadMapPredictions = (value) => {
    // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
    var addres_url =
      'https://maps.googleapis.com/maps/api/place/details/json?placeid=' +
      value +
      '&key=';
    const token = localStorage['accesstoken'];
    console.log('');
    var obj = { url: addres_url };
    apiservices.getlocation(obj, token).then((response) => {
      if (!response.error) {
        var getlocation = response.response.result.geometry.location;
        console.log('geolocation', getlocation);
        var fields = this.state.fields;
        fields['currentLatLng'].value.lat = getlocation.lat;
        fields['currentLatLng'].value.lng = getlocation.lng;
        this.setState({ fields });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  getCurrentPosition = () => {
    var self = this;
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('position', position);

      var obj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        latitudeDelta: 0.005,
        longitudeDelta: 0.005,
        coords: position.coords,
      };
      var fields = this.state.fields;
      fields['currentLatLng'].value = obj;
      this.setState({ fields });
    });
  };
  getlocationbyAddress = (value, key) => {
    console.log('value', value);
    var self = this;
    Geocode.setApiKey('AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc');
    Geocode.enableDebug();
    Geocode.fromAddress(value).then(
      (response) => {
        console.log('response', response);
        const { lat, lng } = response.results[0].geometry.location;
        var getlocation = { lat: lat, lng: lng };
        var fields = this.state.fields;
        fields[key].value = getlocation;
        this.setState({ fields });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  getAddressbyLatLng = (data) => {
    var latitude = data.lat.toString();
    var longitude = data.lng.toString();
    var self = this;
    Geocode.setApiKey('AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc');
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        console.log('response', response);
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        console.log('address', address);
        var fields = self.state.fields;
        fields['currentLatLng'].value.lat = lat;
        fields['currentLatLng'].value.lng = lng;
        fields['address'].value = address;
        self.setState({ fields });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  loadzoneDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = { search: '' };
    apiservices.listallactivezone(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ zone_id: response.result });
        var fields = this.state.fields;
        if (!value) {
          fields['zone_id'].value =
            response.result.length > 0 ? response.result[0].id : '';
          this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  render() {
    const { dealer_master, shop_type, authorized_private, vehicle_type } =
      this.state;
    const { zone_id } = this.state;

    return (
      <div>
        <div className='svc-detailes-all'>
          <div className='dealer-head'>1. SVC Details</div>
          <div className='dealer-body'>
            <div className='form-group'>
              <div class='form-inline'>
                <label>Service Centre Name *</label>
                <input
                  type='text'
                  className='form-control'
                  name='servicecentre_name'
                  value={this.state.fields.servicecentre_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['servicecentre_name'].error &&
                    this.state.fields['servicecentre_name'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Dealer Master</label>

                <Select
                  name='dealer_master'
                  value={[this.state.fields.dealer_master.value]}
                  onChange={(value) =>
                    this.checkvalidations(value, 'dealer_master')
                  }>
                  {dealer_master &&
                    dealer_master.length > 0 &&
                    dealer_master.map((item) => {
                      return (
                        <Option value={item.id}>
                          {item.dealer_name}-{item.city}
                        </Option>
                      );
                    })}
                </Select>
                <span className='error_msg_adddealer'>
                  {this.state.fields['dealer_master'].error &&
                    this.state.fields['dealer_master'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div className='map-img'>
                <MapWithAMarker
                  //  currentLocation={{lat:this.state.fields.currentLatLng.value.lat,lng: this.state.fields.currentLatLng.value.lng}}
                  currentLocation={{
                    lat: parseFloat(this.state.fields.currentLatLng.value.lat),
                    lng: parseFloat(this.state.fields.currentLatLng.value.lng),
                  }}
                  sendingDrabbleLocation={(latlng) =>
                    this.getAddressbyLatLng({
                      lat: latlng.lat(),
                      lng: latlng.lng(),
                    })
                  }
                  //  currentLocation={this.state.currentLatLng}
                  containerElement={
                    <div style={{ height: `400px`, borderRadius: 20 }} />
                  }
                  mapElement={
                    <div style={{ height: `100%`, borderRadius: 20 }} />
                  }></MapWithAMarker>
                {/* }   */}
              </div>
            </div>
            <div className='form-group position-relative'>
              <label>Address</label>

              <textarea
                row='5'
                className='form-control'
                name='address'
                value={this.state.fields.address.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }></textarea>
              <span className='error_msg_adddealer'>
                {this.state.fields['address'].error &&
                  this.state.fields['address'].error}
              </span>
              {this.printAddress()}
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Town / City</label>
                <input
                  type='text'
                  className='form-control'
                  name='city'
                  value={this.state.fields.city.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['city'].error &&
                    this.state.fields['city'].error}
                </span>
              </div>
              <div class='form-inline position-relative'>
                <label>Zone Name</label>

                <Select
                  name='zone_id'
                  value={[this.state.fields.zone_id.value]}
                  onChange={(value) => this.checkvalidations(value, 'zone_id')}>
                  {zone_id &&
                    zone_id.length > 0 &&
                    zone_id.map((item) => {
                      return <Option value={item.id}>{item.zone_name}</Option>;
                    })}
                </Select>
                <img
                  alt=''
                  src={images.dropdown}
                  className='img-fluid dropdown-img'
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['zone_id'].error &&
                    this.state.fields['zone_id'].error}
                </span>
              </div>
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label>Pincode</label>
                <input
                  type='text'
                  className='form-control'
                  name='pincode'
                  value={this.state.fields.pincode.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['pincode'].error &&
                    this.state.fields['pincode'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Capacity</label>
                <input
                  type='text'
                  className='form-control'
                  name='capacity'
                  value={this.state.fields.capacity.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['capacity'].error &&
                    this.state.fields['capacity'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Potential</label>
                <input
                  type='text'
                  className='form-control'
                  name='potential'
                  value={this.state.fields.potential.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['potential'].error &&
                    this.state.fields['potential'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Select the Type of Shop</label>
                <Select
                  value={[parseInt(this.state.fields.shop_type.value)]}
                  onChange={(value) =>
                    this.checkvalidations(value, 'shop_type')
                  }
                  name='shop_type'>
                  {shop_type &&
                    shop_type.length > 0 &&
                    shop_type.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
              </div>
              <div class='form-inline'>
                <label>Authorised / Private</label>
                <Select
                  value={[this.state.fields.authorized_private.value]}
                  name='authorized_private'
                  onChange={(value) =>
                    this.checkvalidations(value, 'authorized_private')
                  }>
                  {authorized_private &&
                    authorized_private.length > 0 &&
                    authorized_private.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
              </div>{' '}
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Select Vehicle Type</label>
                <Select
                  value={[this.state.fields.vehicle_type.value]}
                  name='vehicle_type'
                  onChange={(value) =>
                    this.checkvalidations(value, 'vehicle_type')
                  }>
                  {vehicle_type &&
                    vehicle_type.length > 0 &&
                    vehicle_type.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
              </div>
              <div class='form-inline'>
                <label>Brand Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='brand'
                  value={this.state.fields.brand.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['brand'].error &&
                    this.state.fields['brand'].error}
                </span>
              </div>
            </div>

            {/* <div className="form-group">
                      

                        <div class="form-inline">
                          <label>Account Manager</label>
                          <input type="text" className="form-control" name="account_manager" value={this.state.fields.account_manager.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['account_manager'].error&&this.state.fields['account_manager'].error}</span>
                        </div>                     
                     </div> */}
          </div>

          <div className='dealer-head'>2. Bank KYC</div>
          <div className='dealer-body'>
            <div className='form-group'>
              <div class='form-inline'>
                <label>Bank Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='bank_name'
                  value={this.state.fields.bank_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['bank_name'].error &&
                    this.state.fields['bank_name'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Bank Address</label>
                <input
                  type='text'
                  className='form-control'
                  name='bank_address'
                  value={this.state.fields.bank_address.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['bank_address'].error &&
                    this.state.fields['bank_address'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Account Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='account_name'
                  value={this.state.fields.account_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['account_name'].error &&
                    this.state.fields['account_name'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Account Number</label>
                <input
                  type='text'
                  className='form-control'
                  name='account_number'
                  value={this.state.fields.account_number.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['account_number'].error &&
                    this.state.fields['account_number'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>IFSC Code</label>
                <input
                  type='text'
                  className='form-control'
                  name='ifsc_code'
                  value={this.state.fields.ifsc_code.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['ifsc_code'].error &&
                    this.state.fields['ifsc_code'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>GST Number</label>
                <input
                  type='text'
                  className='form-control'
                  name='gst_number'
                  value={this.state.fields.gst_number.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['gst_number'].error &&
                    this.state.fields['gst_number'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>PAN Number</label>
                <input
                  type='text'
                  className='form-control'
                  name='pan_number'
                  value={this.state.fields.pan_number.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['pan_number'].error &&
                    this.state.fields['pan_number'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Pincode</label>
                <input
                  type='text'
                  className='form-control'
                  name='bank_pincode'
                  value={this.state.fields.bank_pincode.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['bank_pincode'].error &&
                    this.state.fields['bank_pincode'].error}
                </span>
              </div>
            </div>
          </div>

          <div className='dealer-head'>3. Contact Person Details</div>
          <div className='dealer-body'>
            <div className='form-group'>
              <div class='form-inline'>
                <label>Full Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='full_name'
                  value={this.state.fields.full_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['full_name'].error &&
                    this.state.fields['full_name'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Mobile Number</label>
                <input
                  type='text'
                  className='form-control'
                  name='mobile_number'
                  value={this.state.fields.mobile_number.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['mobile_number'].error &&
                    this.state.fields['mobile_number'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>E-mail ID</label>
                <input
                  type='text'
                  className='form-control'
                  name='contact_emailid'
                  value={this.state.fields.contact_emailid.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['contact_emailid'].error &&
                    this.state.fields['contact_emailid'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Dealer Code</label>
                <input
                  type='text'
                  className='form-control'
                  name='dealer_code'
                  value={this.state.fields.dealer_code.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['dealer_code'].error &&
                    this.state.fields['dealer_code'].error}
                </span>
              </div>
            </div>

            {/* <div className="form-group">
                       <div class="form-inline">
                          <label>Single Point of Contact Name</label>
                          <input type="text" className="form-control" name="singlepnt_contactname" value={this.state.fields.singlepnt_contactname.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['singlepnt_contactname'].error&&this.state.fields['singlepnt_contactname'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Single Point of Contact Number</label>
                          <input type="text" className="form-control" name="singlepnt_contactnumber" value={this.state.fields.singlepnt_contactnumber.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                          <span className="error_msg_adddealer">{this.state.fields['singlepnt_contactnumber'].error&&this.state.fields['singlepnt_contactnumber'].error}</span>
                        </div>                     
                     </div> */}
            <div className='form-group'>
              <div class='form-inline'>
                <label>Workshop Code</label>
                <input
                  type='text'
                  className='form-control'
                  name='workshop_code'
                  value={this.state.fields.workshop_code.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['workshop_code'].error &&
                    this.state.fields['workshop_code'].error}
                </span>
              </div>
            </div>
          </div>
        </div>

        <button
          disabled={this.state.responseMsg}
          className='btn btn-dark-blue next-button'
          onClick={() => this.updateServiceCentre()}>
          Save Changes
        </button>
        {this.state.responseMsg && this.state.responseMsg.error ? (
          <div className='failure_msg'>{this.state.responseMsg}</div>
        ) : (
          ''
        )}
        {this.state.responseMsg && !this.state.responseMsg.error ? (
          <div className='success_msg'>{this.state.responseMsg}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  // apiKey: ('AIzaSyA0AzaHQi3LQ_zRW8yknO6WF2wGbe50NtU')

  apiKey: 'AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas',
})(SvcDetails);
