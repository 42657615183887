import React, {Component} from 'react';
import apiservices from '../../../helpers/apiservices';
import images from '../../../helpers/images';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Pagination, Select} from 'antd';
   
import { DatePicker } from 'antd';



const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var bankheader = [{'id' : 1,  'bank_name' : 'Bank Name','branch_name':'Branch Name', 'bankaccount_name': 'Bank Account Name', 'bankaccount_number' : 'Bank Account Number', 'ifsc_code' : 'Bank IFSC Code'}];
class BankDetails extends Component{

    constructor(props){

        super(props);

        this.state = {
            bankheader : bankheader,

            bankdetailsList : [],
            openDialog:false,
            currentPage: 1,
            pageSize: 10,
            fields:{
                bank_name:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                branch_name:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                account_name:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                account_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
                ifsc_code:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
              },
            
        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadBankDetails =() =>{
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        apiservices.listbankdetails(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    var limit=response.count;
                    var total_items=this.state.pageSize;
                    var calc=Math.ceil(limit/total_items)*total_items;
                      this.setState({bankdetailsList:response.response,numbercount:calc})
                      console.log("bank details",response)
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    onchangePagination = (page, pageSize) => {
      console.log("page", page);
      this.setState({ currentPage: page }, function(){
          this.loadBankDetails();        
      });
    
      console.log("page size", pageSize);
    }
    showChanger = (current, size) => {
      this.setState({ pageSize: size, currentPage : 1 }, function(){
          this.loadBankDetails(); 
      });
    }
    componentWillMount(){
        this.loadBankDetails();
    }
    openDialog =()=>{
        var fields=this.state.fields;
          fields['bank_name'].value='';
          fields['branch_name'].value='';
          fields['account_name'].value='';
          fields['account_number'].value='';
          fields['ifsc_code'].value='';
        this.setState({openDialog:true,responseMsg:'',fields})
    }
    checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      popupSubmit =(data)=>{
          var fields =this.state.fields;
          var getallkeys=Object.keys(fields);
          getallkeys.map((obj)=>{
          var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
          fields[obj].error=checkstatus.msg;
          })
          var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
          if(filtererrors.length>0){
            //errorblock
            this.setState({error:true})
          }else{
            //successblock
            this.setState({error:false});
            this.addBankDetails();
          }
          this.setState({fields});
      }
      closeDialog =() =>{
          var fields=this.state.fields;
          fields['bank_name'].value='';
          fields['bank_name'].error='';
          fields['branch_name'].value='';
          fields['branch_name'].error='';
          fields['account_name'].value='';
          fields['account_name'].error='';
          fields['account_number'].value='';
          fields['account_number'].error='';
          fields['ifsc_code'].value='';
          fields['ifsc_code'].error='';
          this.setState({openDialog:false,fields,responseMsg:''})
      }
      addBankDetails =()=>{
        const token=localStorage['accesstoken'];
        var obj={bank_name:this.state.fields.bank_name.value,bank_branch:this.state.fields.branch_name.value,account_name:this.state.fields.account_name.value,account_number:this.state.fields.account_number.value,ifsc_code:this.state.fields.ifsc_code.value};
        console.log("bank details obj",obj)
        apiservices.addbankdetails(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    var self=this;
                    setTimeout(() => {
                      self.loadBankDetails();
                      self.setState({openDialog:false})
                    }, 3000);
                      this.setState({responseMsg:response.message})
                      console.log("goals",response)
                    }
                  else{
                    this.setState({responseMsg:response.message})
                  }
              })
      }
render(){

  
    return(
        <div className="bankdetails_main">
              <div className="container">
              <div className="addbtnmain">
                  <button className="add_new_btn" onClick={()=>this.openDialog()}>Add New</button>
              </div>


                    <div className="table-list boxshadow_none">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.bankheader && this.state.bankheader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.bank_name}</th>
                                                <th>{item.branch_name}</th>
                                                <th>{item.bankaccount_name}</th>
                                                <th>{item.bankaccount_number}</th>
                                                <th>{item.ifsc_code}</th>
                                               
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                    {this.state.bankdetailsList && this.state.bankdetailsList.map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.bank_name}</td>
                                                <td>{item.bank_branch}</td>
                                                <td>{item.account_name}</td>
                                                <td>{item.account_number}</td>
                                                <td>{item.ifsc_code}</td>
                                               
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                                    {this.state.bankdetailsList&&this.state.bankdetailsList.length==0&&
                                    <div>No Records Found </div>
                                    }
                            

 
                        </div>
                     </div>


                     </div> 
                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
                     <Dialog  open={this.state.openDialog}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                   
                     <div className="modalcntntcls">
                     <div className="delete_text">
                        Add Bank Details
                     </div>
                     <div>
                     <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Bank Name</label>
                          
                          <input type="text" className="form-control input_paused" name="bank_name" value={this.state.fields.bank_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['bank_name'].error&&this.state.fields['bank_name'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Branch Name</label>
                          
                          <input type="text" className="form-control input_paused" name="branch_name" value={this.state.fields.branch_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['branch_name'].error&&this.state.fields['branch_name'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Bank Account Name </label>
                          
                          <input type="text" className="form-control input_paused" name="account_name" value={this.state.fields.account_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['account_name'].error&&this.state.fields['account_name'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Bank Account Number </label>
                          
                          <input type="text" className="form-control input_paused" name="account_number" value={this.state.fields.account_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['account_number'].error&&this.state.fields['account_number'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>IFSC Code </label>
                          
                          <input type="text" className="form-control input_paused" name="ifsc_code" value={this.state.fields.ifsc_code.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['ifsc_code'].error&&this.state.fields['ifsc_code'].error}</span>
                         
                         
                        </div>
                       </div>
                      
                       {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.responseMsg&&!this.state.responseMsg} className="delete_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                    </div>
                     
                      
                      </div>
                    
                    
                </Dialog>   
             </div>
         
    )
}     
}



export default BankDetails;