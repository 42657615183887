import React, { Component } from 'react';
import './styles.css';
import Images from '../../helpers/images';

export default class Logo extends Component {
  render() {
    return (
      <div> 
          <div className="logo_container">
                <div className="logo_text"><img src={Images.logo} className="img-fluid" style={{width:430}}/></div>
          </div>
      </div>
    );
  }
}
