import React, { Component } from 'react';
import images from '../../../helpers/images';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import Images from '../../../helpers/images';
import './styles.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect,
} from 'react-router-dom';
import apiservices from '../../../helpers/apiservices';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import ValidationLibrary from '../../../helpers/validationfunction';
const { Option } = Select;
var SvcZoneListArray = [
  {
    ID: 'ID1256',
    Name: 'Craig Schneider',
    mobileNumber: 9837643023,
    emailID: 'craigschneider@gmail.com',
    designation: 'Customer Relations Manager',
  },
];

export default class ZoneList extends Component {
  constructor(props) {
    console.log('this props', props);
    super(props);
    this.state = {
      SvcZoneListArray: SvcZoneListArray,
      tempdata: [],
      currentPage: 1,
      pageSize: 10,
      search: '',
      editID: null,
      deleteID: null,
      loading: false,
      editOpen: false,
      designation: null,
      loadstatus: false,
      rejectapproval: false,
      fields: {
        zone_name: {
          validations: [
            { name: 'required' },
            { name: 'zone' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
      },
    };
    // this.loadDesignationDropdown();
  }
  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  onchangePagination = (page, pageSize) => {
    this.setState({ currentPage: page }, function () {
      this.loadZoneList();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.loadZoneList();
    });
  };

  loadZoneList = () => {
    this.setState({ loading: true, loadstatus: false });
    const token = localStorage['accesstoken'];
    var obj = {
      // pageNo: this.state.currentPage,
      // pageLimit: this.state.pageSize,
      search: this.state.search,
    };
    apiservices.listallactivezoneTable(obj, token).then((response) => {
      var limit = response.count;
      var total_items = this.state.pageSize;
      var calc = Math.ceil(limit / total_items) * total_items;
      this.setState({ loading: false });
      if (!response.error) {
        this.setState({
          tempdata: response.result,
          numbercount: calc,
          loadstatus: true,
        });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  componentWillMount() {
    this.loadZoneList();
    // this.props.sendsvcid(svc_id);
  }
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  searchname = (e) => {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '') {
      value = '';
      //
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.loadZoneList();
    });
  };
  sortAscending = () => {
    console.log('sortAscending');
    const { tempdata } = this.state;
    tempdata.sort((a, b) => a - b).reverse();
    this.setState({ tempdata });
  };

  sortDescending = () => {
    console.log('sortDescending');
    const { tempdata } = this.state;
    tempdata.sort((a, b) => a - b).reverse();
    this.setState({ tempdata });
  };
  deleteZoneList = () => {
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.deleteID };
    apiservices.disableadminzone(obj, token).then((response) => {
      var self = this;
      if (!response.error) {
        setTimeout(() => {
          self.loadZoneList();
          self.setState({ rejectapproval: false });
        }, 3000);

        this.setState({ success_msg: response.message });
        // console.log("list svc", response)
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  editZone = (data) => {
    console.log('data', data);
    const token = localStorage['accesstoken'];
    var obj = { id: data.id };
    apiservices.getadminzonebyid(obj, token).then((response) => {
      if (!response.error) {
        // var editZoneResponce = response.response;
        var fields = this.state.fields;
        fields['zone_name'].value = response.result[0].zone_name;

        this.setState({ fields });
        // this.setState({ popup_success_msg: response.message })
      } else {
        // this.setState({ popup_error_msg: response.message })
      }
    });
    this.setState({ editOpen: true, editID: data.id });
    // var editZoneResponce = response.response;
  };
  updateZone = () => {
    this.setState({ error: false });
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.editID,
      zone_name: this.state.fields.zone_name.value,
    };
    apiservices.updateeditZone(obj, token).then((response) => {
      if (!response.error) {
        console.log('update zone data', response);
        this.setState({ popup_success_msg: response.message });
        this.loadZoneList();
        setTimeout(() => {
          this.setState({ editOpen: false, popup_success_msg: false });
        }, 1000);
      } else {
        this.setState({ popup_error_msg: response.message });
      }
    });
  };
  OpenRejectApproval = (data) => {
    this.setState({ rejectapproval: true, deleteID: data });
  };
  RejectApproval = () => {
    this.setState({ rejectapproval: false });
  };
  render() {
    const { designation, service_center } = this.state;
    return (
      <div className='svc_user_list '>
        <div className='search-header driver-search'>
          <div className='row'>
            <div className='col-md-4'>
              <div class='form-group search-list payment-search'>
                <input
                  type='text'
                  placeholder='Search zone name'
                  className='form-control'
                  name='search'
                  onChange={(e) => this.searchname(e)}
                />
                <img alt='' src={images.green_search} className='img-fluid' />
              </div>
            </div>
            <div className='col-md-2'></div>
            <div className='col-md-4'>
              {/* <div className="payment-dropdown"> <span className="payment">city / Zone</span>  */}
              {/* <div className='city_search' style={{ display: 'flex' }}>
                <label>City / Zone:</label>
                <input
                  type='text'
                  className='form-control citysearchbox'
                  name='city_search'
                  onChange={(e) => this.cityFilter(e)}
                />
              </div> */}
              {/* </div> */}
            </div>
            <div className='col-md-2'>
              <div className='city_search' style={{ display: 'flex' }}>
                {/* <label>State:</label>
                <div className='switch_div'>
                  <Switch onClick={(data) => this.toggleCheck(data)} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='tablediv activeUserList'>
          <table class='table table-hover'>
            <thead>
              {this.state.SvcZoneListArray &&
                this.state.SvcZoneListArray.map((obj) => {
                  return (
                    <tr>
                      <th>ID</th>
                      <th>Zone Name</th>

                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  );
                })}
            </thead>
            <tbody>
              {this.state.tempdata &&
                this.state.tempdata.length > 0 &&
                this.state.tempdata.map((obj) => {
                  return (
                    <tr>
                      <td>ID{obj.id}</td>
                      <td> {obj.zone_name}</td>

                      <td>
                        {/* <Link to={{
  pathname: `${this.props.parentProps.match.path}/add_zone/${obj.id}/${this.props.match.params.svc_id}`,}}>
   */}

                        <img
                          alt=''
                          src={images.EditImg}
                          style={{ width: 38, cursor: 'pointer' }}
                          onClick={() => this.editZone(obj)}
                        />
                        {/* </Link> */}
                      </td>
                      <td>
                        <img
                          alt=''
                          src={images.delete_icon}
                          style={{ width: 38, cursor: 'pointer' }}
                          onClick={() => this.OpenRejectApproval(obj.id)}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {this.state.tempdata && this.state.tempdata.length == 0 ? (
            <div className='no_records_found'>No Records Found</div>
          ) : (
            ''
          )}
        </div>
        {/* {this.state.tempdata.length>this.state.pageSize&& */}
        {/* {this.state.tempdata.length != 0 && (
          <Pagination
            total={this.state.numbercount}
            current={this.state.currentPage}
            onChange={(page, pageSize) =>
              this.onchangePagination(page, pageSize)
            }
            pageSize={this.state.pageSize}
            itemRender={this.itemRender}
          />
        )} */}
        {/* } */}
        <Dialog open={this.state.editOpen} className='shopbox'>
          <div className='modalclosecls'>
            <img
              alt=''
              src={images.popup_clseicon}
              style={{ width: '12px', cursor: 'pointer' }}
              onClick={() =>
                this.setState({
                  editOpen: false,
                  popup_error_msg: '',
                  popup_success_msg: '',
                })
              }
            />
          </div>
          <div className='svceditUser'>Edit zone</div>
          <div className='dealer-body svc-user-edit'>
            <div>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>Zone Name</label>
                  <input
                    type='text'
                    className='form-control'
                    name='zone_name'
                    value={this.state.fields.zone_name.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['zone_name'].error &&
                      this.state.fields['zone_name'].error}
                  </span>
                </div>
              </div>
            </div>

            {this.state.popup_error_msg ? (
              <div className='failure_msg'>{this.state.popup_error_msg}</div>
            ) : (
              ''
            )}

            {this.state.popup_success_msg ? (
              <div className='success_msg'>{this.state.popup_success_msg}</div>
            ) : (
              ''
            )}
          </div>
          <button
            disabled={this.state.popup_success_msg}
            className='btn btn-dark-blue next-button popupbuttonedit'
            onClick={this.updateZone}>
            Update
          </button>
        </Dialog>
        <Dialog open={this.state.rejectapproval} className='pending_approvals'>
          <div className=''>
            <div className='title_section'>
              <div className='add_approval_text'>Remove Zone in List</div>{' '}
              <div className='approval_close'>
                <img
                  src={images.popup_clseicon}
                  style={{ width: '15px', cursor: 'pointer' }}
                  onClick={() => this.RejectApproval()}
                />
              </div>
            </div>
            <div className='approval_content'>
              Are you sure you want to Remove the zone?
            </div>
            {this.state.popup_error_msg ? (
              <div className='failure_msg'>{this.state.error_msg}</div>
            ) : (
              ''
            )}

            {this.state.popup_success_msg ? (
              <div className='success_msg'>{this.state.success_msg}</div>
            ) : (
              ''
            )}
            <div className='deletebtns_group'>
              <div className='approval_btn_main '>
                <button
                  className='cancel_btn_main'
                  onClick={() => this.RejectApproval()}>
                  No, Cancel
                </button>
              </div>
              <div className='approval_btn_main'>
                <button
                  className='approval_btn'
                  onClick={() => this.deleteZoneList()}>
                  Yes, Reject
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
