import React, {Component} from 'react';
import './style.css';
import Images from '../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import DriverPersonalDetails from './DriverPersonalDetails';
import DriverBookingDetails from './DriverBookingDetails';
import DriverPerformanceDetails from './DriverPerformanceDetails';
import DriverPaymentDetails from './DriverPaymentDetails';
import DriverAuditDetails from './DriverAuditDetails';
import DriverOversfeedDetails from './DriverOversfeedDetails';

import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
   
class DriverDetails extends Component{
    constructor(props){
        console.log("driver details",props);
        super(props);
                this.state = {
          SvcDetails : false,
          svcList: true,
          svcDynId:null,
         }
    }
    SvcDetails = () => {

      this.setState({SvcDetails : true});
    
      this.setState({svcList : false});
       
     }
    
     svcDetailsHide = () => {
      this.setState({SvcDetails : false});
    
      this.setState({svcList : true});
     }
     DriverDetailsHide = () => {

        this.props.sendHideDetails('hide');
     }

     backClick=()=>{
        this.props.history.push('/pilots/pilot_master/pilot_details/');
    }
    componentWillMount(){
        console.log("svc id",this.state.svcDynId);
    }
    
     
render(){
    return(
        <div>

{/* Form Tabs start */}
             
<div className="back-btns">
             <span className="back-button" onClick={()=>this.backClick()} > 
                    <img src={Images.FadedArrowImg} className="img-flud"/>
                 
                 Back to the Lists</span>
             </div>
                <div class="svcDetails-tabs svcdetails_main">
                <Row style={{display:'inline-flex'}}>
                    <Col sm={12}>
                    <Nav variant="pills" className="flex-row svcdetails_nav" selectedIndex={this.state.selectedIndex}
                        onSelect={this.handleSelect}>
                        
                        <NavLink   to={`${this.props.match.path}/${this.props.driverid}`}  >Pilot Details</NavLink>
                        {/* <Nav.Link eventKey="first">Driver Details</Nav.Link> */}
                       
                        <NavLink  to={`${this.props.match.path}/bookings/${this.props.driverid}`}  >Bookings</NavLink>
                        <NavLink  to={`${this.props.match.path}/driverdet_performance/${this.props.driverid}`}  >Performance</NavLink>
                        <NavLink  to={`${this.props.match.path}/audit/${this.props.driverid}`}  >Audit</NavLink>
                       
                        <NavLink className={`${window.location.hash.includes('/driverdetails_payment/')&&"activelink"}`} to={`${this.props.match.path}/driverdetails_payment/booking_payment/${this.props.driverid}`}  >Payment</NavLink>
                        
                        <NavLink  to={`${this.props.match.path}/overspeed/${this.props.driverid}`}  >Overspeed</NavLink>
                    </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="svc_first">
                    <Switch>
                    <Route exact  path={`${this.props.match.path}/:id/`} render={props=> (<DriverPersonalDetails  {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)}/>)} />
                    <Route  path={`${this.props.match.path}/bookings/:id/`} render={props=> (<DriverBookingDetails {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)} />)} />
                    <Route  path={`${this.props.match.path}/audit/:id/`} render={props=> (<DriverAuditDetails {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)}/>)} />
                    <Route  path={`${this.props.match.path}/driverdet_performance/:id/`} render={props=> (<DriverPerformanceDetails {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)}/>)} />
                    <Route  path={`${this.props.match.path}/driverdetails_payment`} render={props=> (<DriverPaymentDetails {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)} driverid={this.props.driverid}/>)} />
                    <Route  path={`${this.props.match.path}/overspeed/:id/`} render={props=> (<DriverOversfeedDetails {...props} sendid={(id)=>this.props.sendid&&this.props.sendid(id)}/>)} />
                    </Switch>
                    </div>
                    </Col>
                </Row>
                </div>
                {/* Form Tabs start */}
        </div>
    )
}


}

export default DriverDetails;