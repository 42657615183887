import React, { Component } from 'react';
import './styles.css';
import images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
class Login extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mobile_no:'',
      password:'',
      error_msg:null,
      userDetails: null,
      toastId: null,
      shopdetails: props.shop_details,
      isPasswordVisible: false,

    }
  }
  handleSubmit = () => {
    if (this.props.loadverifcation) {
      var obj = { type: 'verification' };
      this.props.loadverifcation(obj)
    }
  }
  forgotSubmit = () => {
    // if(this.props.loadforgot && this.props.forgot){
    // var obj={type:'forgotpassword',forgot:true};
    this.props.loadforgot();
    // }
  }



  
  passwordStrength(e) {
    if (e.target.value === '') {
      this.setState({
        score: 'null'
      })
    }
    else {
      var pw = (e.target.value);
      this.setState({
        score: pw.score
      });
    }

  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === 'input' ? 'password' : 'input'
    })
  }
  loginSubmit =()=>{
    console.log("submit");
  }
  changeLogin = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log("password",e.target.value);
}
  loginSubmit=()=>{
    var mobile_no = /^(0|[1-9][0-9]{9})$/;
    var password = /^\S{6,15}$/;
    if (this.state.mobile_no == "" || this.state.mobile_no == undefined) {
     this.setState({error_msg:"Please enter Mobile Number"})
    } else if (!mobile_no.test(this.state.mobile_no)) {
      this.setState({error_msg:"Please enter valid Mobile Number"})

    }
    else if (this.state.password == "" || this.state.password == undefined) {
      this.setState({error_msg:"Please enter password!"})
    }
    else if (!password.test(this.state.password)) {
     
      this.setState({error_msg:"Please enter valid password!"})

    }else{
      this.setState({error_msg:''})
      apiservices.login({mobile_no:this.state.mobile_no,password:this.state.password})
    .then((response)=>{
      if(!response.error){
        localStorage.setItem('accesstoken', response.Authorization);
        localStorage.setItem('name', response.response.name);
        localStorage.setItem('mobile', response.response.mobile_no);
        localStorage.setItem('type', response.response.user_type);
        this.props.loggedIn(true);
              this.props.history.push('/dashboard');

      }else{
        this.setState({error_msg:response.message})
      }
        console.log("login response",response);
    })
    }
  }
  render() {
    return (
        <div className="login_main">
      <div className="logincontainer">
        <div className="logintext">Login to your account</div>
        <div className="formdiv login_formdiv">
          <label className="labeltxt">Mobile Number</label>
          <div>
            <input  className="brdrcls"  type="text" name="mobile_no" onChange={(e)=>this.changeLogin(e)}  />
           
          </div>

        </div>
        <div className="formdiv passwordcls login_formdiv">
          <label className="labeltxt">Enter the password</label>
          <div style={{ position: 'relative' }}>
              {this.state.isPasswordVisible ?
              <input
                type='text'
                name="password"
                className="brdrcls"
                maxLength="15"
                onChange={(e)=>this.changeLogin(e)} 
                 /> :
              <input
                type='password'
                name="password"
                className="brdrcls"
                maxLength="15"
                onChange={(e)=>this.changeLogin(e)} 
                 />}
            <img onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })} src={this.state.isPasswordVisible ? images.eye_off : images.eye_on} className="pwdicon" />
          
          </div>
        </div>


        <p className="forgtottxt" onClick={this.forgotSubmit}> Forgot Password ?</p>
            <div className="error_msg">{this.state.error_msg}</div>
        <div style={{ padding: 20, marginTop: 8 }}>
          <button className="loginbtn" onClick={() => this.loginSubmit()}>LOGIN</button>
        </div>


      </div>
      </div>
    )
  }
}
export default Login;
