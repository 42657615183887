import React, { Component } from 'react';
import { Table, Tag, Pagination, Select,DatePicker} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../helpers/validationfunction';
import Image from '../../helpers/images';

import apiservices from '../../helpers/apiservices';
import moment from 'moment';
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const { RangePicker } = DatePicker;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var payoutHeader = [{'id' : 1,  'pilot_name' : 'Pilot Name, ID', 'city': 'City/Zone', 'present' : 'Present/ Total Days', 'early_morning' : 'Early Morning & Late Night Incentives','system_added':'System added & Other Conveyance',  'addtional_deduction' : 'Additions & Deductions','tds':'TDS','total_amount':'Total Amount','status':'Status'}];

export default class Payout extends Component {
  constructor(props){
    super(props);
    this.state={
      payoutHeader:payoutHeader,
      payoutList:[],
      closeID:null,
      currentPage : 1,
      pageSize:10,
      driver:null,
      city:null,
      fromDate:null,
      toDate:null
     

    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  loadPaymentPayouts=()=>{
    this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
    console.log("actve", obj); 
    apiservices.listpaymentpayout(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  this.setState({payoutList:response.response, numbercount:calc})
                  console.log("payout reponse", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadPaymentPayouts();
     
}
cityFilter =(e) =>{
  var value=e.target.value;
  // this.loadsvclist();
  if(value==''){
    value=null;
    // 
  }
  this.setState({[e.target.name]:value,currentPage:1},function(){
    this.loadPaymentPayouts();
  });
}
onchangePagination = (page, pageSize) => {
  console.log("page", page);
  this.setState({ currentPage: page }, function(){
      this.loadPaymentPayouts();        
  });

  console.log("page size", pageSize);
}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadPaymentPayouts(); 
  });
}
checkvalidations =(data)=>{
  this.setState({fromDate:data[0],toDate:data[1]},function(){
    this.loadPaymentPayouts();
  })
}
ClearDatepicker =()=>{
  this.setState({fromDate:null,toDate:null},function(){
    this.loadPaymentPayouts();
  })
}
  render() {
    const offset = moment().utcOffset();
    return (
      <div className="zones_main conveyance"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active" style={{paddingTop:30}}>
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkvalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>
                    <div >
                    <div className="table-list">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.payoutHeader && this.state.payoutHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.pilot_name}</th>
                        {/* <th>{item.city}</th> */}
                        <th>{item.present}</th>
                        {/* <th>{item.status}</th> */}
                        <th>{item.early_morning}</th>
                        {/* <th>{item.complaint_from}</th> */}
                        <th>{item.system_added}</th>
                        <th>{item.addtional_deduction}</th>
                        <th>{item.tds}</th>
                        <th>{item.total_amount}</th>
                        {/* <th>{item.status}</th> */}
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.payoutList&&this.state.payoutList.length>0&&this.state.payoutList.map((item,index) => {

                return(
                    <tr>
                       
                <td ><span className="pilot_name">{item.driver_first_name} {item.driver_last_name}</span><br/>
                <div className="service-listing pilot_id">ID{item.driver_id}</div>
                                                  {/* <span className="service-listing total_task"> 120 Task</span> */}
                                                 
                                                </td>
                        {/* <td><span className="pilot_name">{item.city}</span></td> */}
                        <td><span className="pilot_name">{item.present_days}/{item.total_days}</span></td>
                        {/* <td>{item.status}</td> */}
                        <td><span className="service-listing total_task">Rs.{item.early_morning_incentive}</span><br/>
                                                  <div className="service-listing pilot_name" >Rs.{item.late_night_incentive}</div></td>
                        <td><span className="service-listing pilot_name" style={{paddingBottom:5}}>{item.added_conveyance}</span><br/>
                                                  <div className="service-listing total_task">{item.conveyance}</div></td>
                                                  <td><span className="service-listing pilot_name" style={{paddingBottom:5}}>Rs.{item.other_incentive}</span><br/>
                                                  <div className="service-listing total_task">Rs.{item.deductions}</div></td>
                        <td><span className="pilot_name">Rs.{item.tds}</span></td>
                        <td><span className="pilot_name">Rs.{item.total_payout}</span></td>
                        {/* <td><img src={Image.cancel_new}/></td> */}
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.payoutList&&this.state.payoutList.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
       </div>
    );
  }
}
