import React, {Component} from 'react';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { DatePicker } from 'antd';
import './styles.css';


const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var channelpartnerHeader = [{'id' : 1,  'driver_name' : 'Name', 'id': 'ID', 'creation_date' : 'Creation Date', 'mobile_number' :'Mobile Number',  'email_id' : 'Email ID','commision':'Commission','no_of_drivers':'No of Drivers','city' : 'City/Zone'}];
class ChannelPartner extends Component{
    constructor(props){
        console.log("high props",props);
        super(props);
        this.state = {
            channelpartnerHeader : channelpartnerHeader,
            channelpartnerList : [],  
            currentPage : 1,
            pageSize:10,
            openDialog:false,
            fields:{
                partner_name:{validations:[{name:'required'},{name:'alphabetsOnly'}],error:null,value:''},
                mobile_number:{validations:[{name:'required'},{name:'mobile'}],error:null,value:''},
                email_id:{validations:[{name:'required'},{name:'email'}],error:null,value:''},
                no_driver:{validations:[{name:'required'},{name:'allowNumaricOnly'}],error:null,value:''},
                city:{validations:[{name:'required'},{name:'alphabetsOnly'}],error:null,value:''},
                zone:{validations:[{name:'required'},{name:'alphabetsOnly'}],error:null,value:''},
                commision:{validations:[{name:'required'}],error:null,value:''}
            }


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadChannelPartner();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadChannelPartner(); 
        });
      }
      loadChannelPartner=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        console.log("actve", obj);     
        apiservices.listchannelpartner(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({channelpartnerList:response.response, numbercount:calc})
                      console.log("ticket high reponse", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    
    componentWillMount(){
        this.loadChannelPartner();
    }
    
    AddAccidents=()=>{

        this.setState({openDialog:true,responseMsg:'',successMsg:''});
    }
    
    checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        if(key=='BookingList'){
          var parseddata=JSON.parse(value);
          fields['driver_name'].value=parseddata.driver_first_name +" "+ parseddata.driver_Last_name;
          fields['svc_name'].value=parseddata.centre_name
          fields['svc_id'].value=parseddata.svc_center_id
          console.log("Booking list",value)
        }
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      submitPopup=()=>{
        // this.props.sendpickupdetails(2);
        var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
      this.SubmitAccidents();
      }
      this.setState({fields});
      }
      SubmitAccidents =() =>{
        var self=this;
        const token=localStorage['accesstoken'];
        var obj={partner_name:this.state.fields.partner_name.value,
          mobile_no:this.state.fields.mobile_number.value,
          email:this.state.fields.email_id.value,
          no_driver:this.state.fields.no_driver.value,
          city:this.state.fields.city.value,
          zone:this.state.fields.zone.value,
          commision:this.state.fields.commision.value};
        console.log("add channel", obj); 
        apiservices.addchannelpartner(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                      self.setState({openDialog:false});
                      this.loadChannelPartner();
                      this.closeDialog();
                    }, 3000);
                     this.setState({success_msg:response.message})
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })
      }
      closeDialog =()=>{
        var fields=this.state.fields;
        fields['partner_name'].value='';
        fields['partner_name'].error='';
        fields['mobile_number'].value='';
        fields['mobile_number'].error='';
        fields['email_id'].value='';
        fields['email_id'].error='';
        fields['no_driver'].value='';
        fields['no_driver'].error='';
        fields['city'].value='';
        fields['city'].error='';
        fields['zone'].value='';
        fields['zone'].error='';
        fields['commision'].value='';
        fields['commision'].error='';
        this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
      }
render(){
const {BookingListDrop} =this.state;
    return(
        <div className="channel_main">
              <div className="chanell_btn_main ">
                {/* <div className="driverButton" onClick={()=>this.AddAccidents()}>
                <img src={Images.add_img}/>Add Ticket</div>
                                         */}
                                         <button className="driverButton add_aac" onClick={()=>this.AddAccidents()}><img src={Images.add_img}/> Add New</button>
              </div>

                    {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                    <div className="table-list">


                        <div class="table-responsive">

                        
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.channelpartnerHeader &&this.state.channelpartnerHeader.length>0&& this.state.channelpartnerHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.driver_name}</th>
                                                <th>{item.id}</th>
                                                <th>{item.creation_date}</th>
                                                <th>{item.mobile_number}</th>
                                                <th>{item.email_id}</th>
                                                <th>{item.commision}</th>
                                                <th>{item.no_of_drivers}</th>
                                                <th>{item.city}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                {this.state.channelpartnerList&&this.state.channelpartnerList.length>0&&this.state.channelpartnerList.map((item,index) => {

                                        return(
                                            <tr>
                                                 <td>{item.partner_name}</td>
                                                <td>ID{item.id}</td>
                                                <td>{moment(item.created_at).format('DD/MM/YY')}</td>
                                                <td>{item.mobile_no}</td>
                                                <td>{item.email}</td>
                                                <td>{item.commision}</td>
                                                <td>{item.no_driver}</td>
                                        <td>{item.city}/{item.zone}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {
                                this.state.channelpartnerList&&this.state.channelpartnerList.length==0&&
                                <div className="no_records_found">No Records Found</div>
                            }

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />


<Dialog  open={this.state.openDialog}  className="ticketmainpage">
                    <div className="modalclosecls" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    <div className="modalcntntcls">
                   
                  <div className="add_ticket_text add_channel_text">Add New Channel Partner</div> 
                  <div className="addbooking_main add_channel">
            <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Channel Partner Name</label>
                          
                          <input type="text" className="form-control input_paused" name="partner_name" value={this.state.fields.partner_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                           
                           <span className="error_msg_adddealer">{this.state.fields['partner_name'].error&&this.state.fields['partner_name'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Mobile Number</label>
                          
                          <input type="text" className="form-control input_paused" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                           
                            <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error&&this.state.fields['mobile_number'].error}</span> 
                         
                        </div>
                       </div>

                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Email ID</label>
                          
                          <input type="text" className="form-control input_paused" name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                            <span className="error_msg_adddealer">{this.state.fields['email_id'].error&&this.state.fields['email_id'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Number of Driver</label>
                          
                          <input type="text" className="form-control input_paused" name="no_driver" value={this.state.fields.no_driver.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['no_driver'].error&&this.state.fields['no_driver'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>City</label>
                          
                          <input type="text" className="form-control input_paused" name="city" value={this.state.fields.city.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['city'].error&&this.state.fields['city'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Zone</label>
                          
                          <input type="text" className="form-control input_paused" name="zone" value={this.state.fields.zone.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['zone'].error&&this.state.fields['zone'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Commision</label>
                          
                          <input type="text" className="form-control input_paused" name="commision" value={this.state.fields.commision.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['commision'].error&&this.state.fields['commision'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.error_msg?
                 <div className="failure_msg">{this.state.error_msg}</div> :''
                   }
                 {this.state.success_msg?
                 <div className="success_msg">{this.state.success_msg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.success_msg&&!this.state.success_msg} className="delete_submit" onClick={()=>this.submitPopup()}>Submit</button>
                       </div>
                       </div>
                    </div>
                </Dialog>
             </div>

         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default ChannelPartner;
