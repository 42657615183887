import React, { Component } from 'react';
import './styles.css';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Table, Tag, Pagination, Select} from 'antd';
import { Doughnut, Bar, Chart as ChartJS, Line } from 'react-chartjs-2';
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
// ChartJS.elements.Rectangle.prototype.draw = function () {
//     const ctx = this._chart.ctx
//     const vm = this._view
//     let left, right, top, bottom, signX, signY, borderSkipped
//     let borderWidth = vm.borderWidth

//     // If radius is less than 0 or is large enough to cause drawing errors a max
//     // radius is imposed. If cornerRadius is not defined set it to 0.
//     let cornerRadius = this._chart.config.options.cornerRadius
//     if (cornerRadius < 0) { cornerRadius = 0 }
//     if (typeof cornerRadius == 'undefined') { cornerRadius = 0 }

//     if (!vm.horizontal) {
//         left = vm.x - vm.width / 2
//         right = vm.x + vm.width / 2
//         top = vm.y
//         bottom = vm.base
//         signX = 1
//         signY = bottom > top ? 1 : -1
//         borderSkipped = vm.borderSkipped || 'bottom'
//     }

//     // Canvas doesn't allow us to stroke inside the width so we can
//     // adjust the sizes to fit if we're setting a stroke on the line
//     if (borderWidth) {
//         // borderWidth shold be less than bar width and bar height.
//         const barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom))
//         borderWidth = borderWidth > barSize ? barSize : borderWidth
//         const halfStroke = borderWidth / 2
//         // Adjust borderWidth when bar top position is near vm.base(zero).
//         const borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0)
//         const borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0)
//         const borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0)
//         const borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0)
//         // not become a vertical line?
//         if (borderLeft !== borderRight) {
//             top = borderTop
//             bottom = borderBottom
//         }
//         // not become a horizontal line?
//         if (borderTop !== borderBottom) {
//             left = borderLeft
//             right = borderRight
//         }
//     }

//     ctx.beginPath()
//     ctx.fillStyle = vm.backgroundColor
//     ctx.strokeStyle = vm.borderColor
//     ctx.lineWidth = borderWidth

//     // Corner points, from bottom-left to bottom-right clockwise
//     // | 1 2 |
//     // | 0 3 |
//     const corners = [
//         [left, bottom],
//         [left, top],
//         [right, top],
//         [right, bottom],
//     ]

//     // Find first (starting) corner with fallback to 'bottom'
//     const borders = ['bottom', 'left', 'top', 'right']
//     let startCorner = borders.indexOf(borderSkipped, 0)
//     if (startCorner === -1) {
//         startCorner = 0
//     }

//     function cornerAt(index) {
//         return corners[(startCorner + index) % 4]
//     }

//     // Draw rectangle from 'startCorner'
//     let corner = cornerAt(0)
//     ctx.moveTo(corner[0], corner[1])

//     for (let i = 1; i < 4; i++) {
//         corner = cornerAt(i)
//         let nextCornerId = i + 1
//         if (nextCornerId == 4) {
//             nextCornerId = 0
//         }

//         const width = corners[2][0] - corners[1][0]
//         const height = corners[0][1] - corners[1][1]
//         const x = corners[1][0]
//         const y = corners[1][1]

//         let radius = cornerRadius

//         // Fix radius being too large
//         if (radius > Math.abs(height) / 1.5) {
//             radius = Math.floor(Math.abs(height) / 1.5)
//         }
//         if (radius > Math.abs(width) / 1.5) {
//             radius = Math.floor(Math.abs(width) / 1.5)
//         }

//         if (height < 0) {
//             // Negative values in a standard bar chart
//             const x_tl = x; const x_tr = x + width
//             const y_tl = y + height; const y_tr = y + height

//             const x_bl = x; const x_br = x + width
//             const y_bl = y; const y_br = y

//             // Draw
//             ctx.moveTo(x_bl + radius, y_bl)
//             ctx.lineTo(x_br - radius, y_br)
//             ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius)
//             ctx.lineTo(x_tr, y_tr + radius)
//             ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr)
//             ctx.lineTo(x_tl + radius, y_tl)
//             ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius)
//             ctx.lineTo(x_bl, y_bl - radius)
//             ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl)
//         } else {
//             ctx.moveTo(x + radius, y)
//             ctx.lineTo(x + width - radius, y)
//             ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
//             ctx.lineTo(x + width, y + height - radius)
//             ctx.quadraticCurveTo(x + width, y + height, x + width, y + height)
//             ctx.lineTo(x + radius, y + height)
//             ctx.quadraticCurveTo(x, y + height, x, y + height)
//             ctx.lineTo(x, y + radius)
//             ctx.quadraticCurveTo(x, y, x + radius, y)
//         }
//     }

//     ctx.fill()
//     if (borderWidth) {
//         ctx.stroke()
//     }
// }

const daylinedata = {
    labels: [],
    datasets: [
        {
            label: "Target",
            data: [],
            fill: false,
            //   backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#F46FA2",
            borderWidth: 1
        },
        {
            label: "Achieved",
            data: [],
            fill: false,
            borderColor: "#44A8C7",
            borderWidth: 1
        }
        
    ]

};
const weeklinedata = {
    labels: ['SUN', 'MUN', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    datasets: [
        {
            label: "First dataset",
            data: [80, 120, 100, 70, 60, 90, 30],
            fill: false,
            //   backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#44A8C7",
            borderWidth: 1
        },
        {
            label: "Second dataset",
            data: [80, 150, 140, 100, 150, 100, 130],
            fill: false,
            borderColor: "#F46FA2",
            borderWidth: 1
        }
    ]

};
const yearlinedata = {
    labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
    datasets: [
        {
            label: "First dataset",
            data: [80, 150, 140, 100, 150, 100, 130, 150, 50, 80, 100, 150],
            fill: false,
            //   backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "#44A8C7",
            borderWidth: 1
        },
        {
            label: "Second dataset",
            data: [100, 120, 110, 90, 120, 50, 80, 40, 20, 100, 70, 120],
            fill: false,
            borderColor: "#F46FA2",
            borderWidth: 1
        }
    ]

};
const data = {
    innerRadius: 90,
    datasets: [{
        data: [60, 40],
        borderWidth: 0,
        cutoutPercentage: 10,
        backgroundColor: [
            "#E39654",
            "#F4F4F4",
        ],
        hoverBackgroundColor: [
            "#E39654",
            "#F4F4F4",
        ]
    }],
    options: {
        cutoutPercentage: 70
    }
};
export default class Trends extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedbar: "",
            selectline: "",
            loading:false,
            daylinedata:JSON.parse(JSON.stringify(daylinedata)),
            // lineChart: daylinedata,
            fields: {
                svc_name: { validations: [{ name: 'required' }], error: null, value: '' },
            },
        }
    }
    handleChangelinechart = (e) => {
        var val = e.target.value;
        if (val == "1") {
            this.setState({ lineChart: daylinedata });
        }
        if (val == "2") {
            this.setState({ lineChart: weeklinedata });
        }
        if (val == "3") {
            this.setState({ lineChart: yearlinedata });
        }
        this.setState({ selectline: val })
    }
    loadSVCTrends=(data)=>{
        if(!data){
            return;
        }
        var fields=this.state.fields;
        fields['svc_name'].value=data;
        this.setState({fields});
        var obj={svc_center_id:data};
        this.setState({loading:true});
        const token=localStorage['accesstoken'];
        console.log("actve", obj);     
        apiservices.listsvctrends(obj,token)
              .then((response)=>{
                  this.setState({loading:false});
                  if(!response.error){
                      var daylinedata=this.state.daylinedata;
                      if(response.response.length>0){
                        daylinedata.labels=[];
                        daylinedata.datasets[0].data=[];
                        daylinedata.datasets[1].data=[];
                          response.response.map((item)=>{
                              daylinedata.labels.push(item.time);
                              daylinedata.datasets[0].data.push(item.target);
                              daylinedata.datasets[1].data.push(item.acheived_booking);
                          })
                          this.setState({daylinedata});
                      }
                      
                      console.log("trendes",response)
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    componentWillMount(){
        // this.loadSVCTrends();
        this.loadServiceDrop();
    }
    loadServiceDrop=()=>{
        const token=localStorage['accesstoken'];
        var obj={};
              apiservices.listsvccenterdrop(obj,token)
              .then((response)=>{
                if(!response.error){
                  this.setState({svc_name:response.response})
                  var fields=this.state.fields;
                  // if(!value){
                var svc_drop= response.response.length>0?response.response[0].id:'';   
                 this.loadSVCTrends(svc_drop)
                //   this.setState({fields})
                  // }
                  console.log("login response",response);
          
                }else{
                  this.setState({error_msg:response.message})
                }
                  
              })
            }
            checkvalidations = (value, key) => {
                console.log("date value",value)
                if(key=='svc_name'){
                    this.loadSVCTrends(value);
                    this.setState({svc_id:value});
                }
                    var fields = this.state.fields;
                    fields[key].value = value;
                    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
                    // console.log(checkstatus);
                    fields[key].error = checkstatus.msg;
                    var getallkeys = Object.keys(fields);
                    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
                    if (filtererrors.length > 0) {
                      this.setState({ error: true })
                      //errorblock
                    } else {
                      this.setState({ error: false })
                
                      //successblock
                    }
                    this.setState({ fields });
                  }
                  
  render() {
      const {svc_name}=this.state;
    return (
      <div> 
          <div className="search-header booking-mishaps">
                         <div className="row">
                             <div className="col-md-5">
                                  <div class="form-group search-list payment-search">
                                      <div className="title_trends">SVC Wise</div>
                                  </div>
                             </div>
                             <div className="col-md-7">
                             <div className="payment-dropdown trends_drop"> <span className="payment">SVC Name</span> 
                             <Select value={[parseInt(this.state.fields.svc_name.value)]}  onChange={(value) => this.checkvalidations(value,'svc_name')} name="svc_name" >
                         {svc_name&&svc_name.length>0&&svc_name.map((item)=>{
                              return(
                              <Option value={item.id}>{item.centre_name}</Option>
                              )
                            })} 
                            
                           </Select> 
                            </div>
                            
                                    
                             </div>
                         </div>
                    </div> 
                    <div className="tablediv svctablediv linechartdiv">
                {/* <div className="bookingStatics">
                    Overall Performance
                                    </div> */}
                <div style={{ width: "100%", display: "flex", paddingLeft: 20, paddingRight: 20, marginTop: 8 ,paddingBottom:20}}>
                    <div style={{ width: "50%", textAlign: "left", fontSize: 13, display: "flex" }}>
                        <div style={{ display: "flex", }}>
                            <div  >
                                <button class="svclinebutton target_color" ></button>
                            </div>
                            <div className="line_chart_text" >
                                Target
                                                </div>
                        </div>
                        <div style={{ display: "flex", marginLeft: 20 }}>
                            <div  >
                                <button class="svclinebutton achieve_color " ></button>
                            </div>
                            <div className="line_chart_text" >
                                Achieved
                                                </div>
                        </div>
                        {/* <div className="barChat-pickup activechart">Pickup</div>
                                        <div className="barChat-dropoff">Drop-off</div> */}
                    </div>
                    {/* <div style={{ width: "50%", textAlign: "right" }}>
                        <select className="barchatselect" value={this.state.selectline} onChange={this.handleChangelinechart}>
                            <option value="1"> In a Day</option>
                            <option value="2"> In a Week</option>
                            <option value="3"> In a Year</option>
                        </select>
                    </div> */}
                </div>
                <div className="linechart">
                    <Line data={this.state.daylinedata} legend={{
                        display: false,
                        position: "bottom",
                        labels: {
                            fontColor: "#323130",
                            fontSize: 14
                        }
                    }} options={{
                        title: {
                            display: false,
                            text: "Chart Title"
                        },
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    display: false,
                                    color: "rgba(255,99,132,0.2)"
                                }
                            }],
                            yAxes: [
                                {
                                    //     gridLines: {
                                    //     display: false,
                                    //     color: "rgba(255,99,132,0.2)"
                                    //  },
                                    ticks: {
                                        suggestedMin: 0,
                                        // suggestedMax: 100
                                    }
                                }
                            ]
                        }
                    }}
                        redraw />
                        <div className="trends_label">Time Slots</div>
                        <div className="trends_label booking_text">Bookings</div>

                </div>
            </div>
         </div>
    );
  }
}
