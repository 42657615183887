import React, {Component} from 'react';
import './style.css';
import moment from 'moment';
import images from '../../../helpers/images';
import { Table, Tag, Pagination, Select,Tooltip} from 'antd';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import apiservices from '../../../helpers/apiservices';

var smsHeader = [{'id' : 1, 'date' : 'Date', 'time' : 'Time', 'sms_call' : 'SMS/Calls', 'from' : 'From', 'to' : 'To', 'content' : 'Content'}];


class PhotoGraphsSmsCall extends Component 
{

    constructor(props){

        super(props);

        this.state = {

            smsHeader : smsHeader,
            smsList : [],
            currentPage : 1,
            pageSize:10,
        }
    }
    itemRender =(current, type, originalElement)=> {
      if (type === 'prev') {
        return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
      }
      if (type === 'next') {
        return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
      }
      return originalElement;
    }
    loadSMSCallList=(data)=>{
      this.setState({loading:true})
              const token=localStorage['accesstoken'];
              var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,id:this.props.match.params.id,assign_id:this.props.match.params.assignid};    
              apiservices.listbookingcalllogs(obj,token)
                    .then((response)=>{
                        // this.setState({loading:false});
                        if(!response.error){
                            var limit=response.count;
                            var total_items=this.state.pageSize;
                            var calc=Math.ceil(limit/total_items)*total_items;
                            console.log("calc",calc);
                            this.setState({smsList:response.response, numbercount:calc})
                            console.log("sms calls", response);
                          }
                        else{
                          this.setState({error_msg:response.message})
                        }
                    })
  }
  componentWillMount(){
    this.loadSMSCallList();
  }
  onchangePagination = (page, pageSize) => {
    console.log("page", page);
    this.setState({ currentPage: page }, function(){
        this.loadSMSCallList();        
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage : 1 }, function(){
        this.loadSMSCallList(); 
    });
  }
render(){
  const offset = moment().utcOffset();
   return(
       

   <div>
               <div className="booking_pickup">
                       

                      <div className="graph-table-listing" style={{marginTop:0}}>
                      <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
      {this.state.smsHeader && this.state.smsHeader.map((item)=> {
          return(
     
        <tr>
          <th>{item.date}</th>
          <th>{item.time}</th>
          <th>Call Status</th>
          <th>Driver Mobile</th>
          <th>Customer Mobile</th>
          <th>Call Duration</th>
        </tr>
      )})}
      </thead>
      <tbody>
      {this.state.smsList && this.state.smsList.map((item)=> {
          return(
     
        <tr>
          <td>{moment(item.call_date).format('DD/MM/YY')}</td>
          <td>{item.call_time}</td>
          <td>{item.call_status}</td>
          <td>{item.agent_number}</td>
          <td>{item.customer_number}</td>
          <td>{item.call_time}</td>
        </tr>
          )})} 
      </tbody>
    </table>
    {this.state.smsList&&this.state.smsList.length==0&&
    <div style={{marginBottom:20}}>No Records Found</div>
    }
  </div>
       

      

               </div>
</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />

</div>

 )
    }
    componentDidMount(){
      console.log("params",this.props);
      this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
    }
}
export default PhotoGraphsSmsCall;