import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
    import  BookingDetailsPickup from '../BookingDetailsPickup';
     import BookingPhotoGraphs from '../BookingPhotoGraphs';
 import PhotoGraphsSmsCall from '../BookingSmsCall';
 import BookingDetailsSMS from '../BookingDetailsSMS';
 import BookingHistory from '../BookingHistory';
 import BookingTickets from '../BookingTickets';
 import BookingRouteToken from '../BookingRouteToken';
class BookingDetailsTab extends Component{

    constructor(props){
        super(props);

        this.state = {


          SvcDetails : false,

          svcList: true,
          bookingDetails:null,
          bookingid:null,
          assignid:null
        }
    }
    SvcDetails = () => {

      this.setState({SvcDetails : true});
    
      this.setState({svcList : false});
       
     }
    
     svcDetailsHide = () => {
      this.setState({SvcDetails : false});
    
      this.setState({svcList : true});
     }
     sendbookingid=(data,data1)=>{
       this.setState({bookingid:data,assignid:data1})
       this.props.sendbookingid&&this.props.sendbookingid(data,data1)
     }
render(){
    return(
        <div className="float-left">

                <div class="svcDetails-tabs bookingTabs">
                <Row>
                  
                    <Col sm={12}>
              <Nav variant="pills" className="flex-row bookingdetails_tab" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/details')&&"activelink"}`} exact={true} to={`${this.props.match.path}/details/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">Details</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/photographs')&&"activelink"}`} to={`${this.props.match.path}/photographs/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">Photographs</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/sms/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">SMS</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/calls/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">Calls</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/history/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">History</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/tickets/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">Tickets</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/route_token/${this.state.bookingid}/${this.state.assignid}`} activeClassName="active_cls">Route Taken</NavLink>
                </Nav.Item>
                
              </Nav>
             
            </Col>
                    <Col sm={12}>
                        <div className="svc_first booking_tabs">
                   
                     <Switch>
                <Route  path={`${this.props.match.path}/details`} render={props => (<BookingDetailsPickup {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)} />)} />
                <Route  path={`${this.props.match.path}/photographs`} render={props => (<BookingPhotoGraphs {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)} />)} />
                <Route  path={`${this.props.match.path}/sms/:id/:assignid`} render={props => (<BookingDetailsSMS {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)}/>)} />
                <Route  path={`${this.props.match.path}/calls/:id/:assignid`} render={props => (<PhotoGraphsSmsCall {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)}/>)} />
                <Route  path={`${this.props.match.path}/history/:id/:assignid`} render={props => (<BookingHistory {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)}/>)} />
                <Route  path={`${this.props.match.path}/tickets/:id/:assignid`} render={props => (<BookingTickets {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)} />)} />
                <Route  path={`${this.props.match.path}/route_token/:id/:assignid`} render={props => (<BookingRouteToken {...props} sendbookingid={(data,data1)=>this.sendbookingid(data,data1)}/>)} />

                </Switch>
                    </div>
                    </Col>
                </Row>
                </div>
                {/* Form Tabs start */}
        </div>
    )
}


}

export default BookingDetailsTab; 