import React, { Component } from "react";
import './styles.css';
import 'antd/dist/antd.css';

import SvcDealerTab from "../SvcDealerTab";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
   
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
  import DealerTableList from './DealerTableList';
  import UpdateSvcDealer from "../UpdateSvcDealer";

class DealerMasterList extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }
    
    render() {
        return (
           <div>
            <Route exact  path={`${this.props.match.path}/`}  render={props=> (<DealerTableList {...props} parentProps={this.props} />)} />
             <Route  path={`${this.props.match.path}/svcdealerdetails/:id`} render={props=>(<SvcDealerTab parentProps={this.props}  {...props}  />)}/>
           </div>
        )
    }
}

export default DealerMasterList;