import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import moment from 'moment';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import
{
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
const { Option } = Select;
const MapWithAMarker = withGoogleMap((props) =>
{
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const onMarkerDragEnd = (event) =>
  {
    props.sendingDrabbleLocation && props.sendingDrabbleLocation(event.latLng);
  }

  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        defaultCenter={location}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        {props.isMarkerShown &&
          <Marker
            position={location}
            draggable={false}
            ref={props.onMarkerMounted}
            onDragEnd={onMarkerDragEnd}
          />
        }
      </GoogleMap>
    </div>
  )

}

);
// only drop off
const MapWithAMarkerOnlyDrop = withGoogleMap((props) =>
{
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const onMarkerDragEnd = (event) =>
  {
    props.sendingDrabbleLocation && props.sendingDrabbleLocation(event.latLng);
  }

  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        defaultCenter={location}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        {props.isMarkerShown &&
          <Marker
            position={location}
            draggable={false}
            ref={props.onMarkerMounted}
            onDragEnd={onMarkerDragEnd}
          />
        }
      </GoogleMap>
    </div>
  )

}

);

// pickup and drop
const MapWithAMarkerPickup = withGoogleMap((props) =>
{
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const onMarkerDragEnd = (event) =>
  {
    props.sendingDrabbleLocationPickup && props.sendingDrabbleLocationPickup(event.latLng);
  }
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        defaultCenter={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragEnd={(data) => onMarkerDragEnd(data)}
        />
      </GoogleMap>
    </div>
  )
}

);
const MapWithAMarkerDrop = withGoogleMap((props) =>
{
  const locationdropoff = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const onMarkerDragEndDrop = (event) =>
  {
    props.sendingDrabbleLocationDropoff && props.sendingDrabbleLocationDropoff(event.latLng);
  }
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={locationdropoff}
        defaultCenter={locationdropoff}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={locationdropoff}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragEnd={(data) => onMarkerDragEndDrop(data)}
        />
      </GoogleMap>
    </div>
  )
}

);
class BookingPickup extends Component 
{
  constructor(props)
  {
    console.log("bookingpickup", props);
    super(props);
    this.state = {
      editOpen: false,
      predictions: [],
      predictionsPickup: [],
      predictionDropoff: [],
      bookingDetails: null,
      customerDetails: null,
      marker: true,
      fields: {
        username: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
        address: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
        dropaddress: { validations: [{ name: 'Noemptyspace' }], error: null, value: '' },
        contact_number: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'mobile' }], error: null, value: '' },
        email_id: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'email' }], error: null, value: '' },
        salutation: { validations: [], error: null, value: 'Mr' },
        pickupLatLng: { validations: [], error: null, value: { lat: 0, lng: 0 } },
        dropLatLng: { validations: [], error: null, value: { lat: 0, lng: 0 } },
        cloneddropLatLng: { validations: [], error: null, value: { lat: 0, lng: 0 } },
        clonedaddress: { validations: [], error: null, value: '' },
        sameaddress: { validations: [], error: null, value: false },
      }
    }
  }
  getCurrentPosition = () =>
  {

    var self = this;
    navigator.geolocation.getCurrentPosition(position =>
    {
      console.log("position", position)

      var obj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        latitudeDelta: 0.005,
        longitudeDelta: 0.005,
        coords: position.coords
      }
      var fields = this.state.fields;
      fields['pickupLatLng'].value = obj;

      this.setState({ fields });

    });

  }
  EditPickup = () =>
  {
    this.setState({ editOpen: true, responseMsg: '' })
    this.loadCustomerDetails();
    // this.loadCustomerDetails();
  }
  checkvalidations = (value, key) =>
  {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);
    if (key == 'address')
    {
      this.onChangeAddress(value);
      this.getlocationbyAddress(value, "pickupLatLng");
    }
    if (key == 'dropaddress')
    {
      this.onChangeDropOnlyAddress(value);
      this.getlocationbyAddress(value, "dropLatLng");
      if (this.state.customerDetails && this.state.customerDetails.booking_type_id == 4)
      {
        this.onChangeDropoffAddress(value);
        this.getlocationbyAddress(value, "dropLatLng", "cloneddropLatLng", "clonedaddress");
      }
    }
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  getlocationbyAddress = (value, key) =>
  {
    var self = this;
    Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromAddress(value).then(
      response =>
      {
        console.log("response", response)
        const { lat, lng } = response.results[0].geometry.location;
        var getlocation = { lat: lat, lng: lng };
        var fields = this.state.fields;
        fields[key].value = getlocation;
        this.setState({ fields });

      },
      error =>
      {
        console.error(error);
      }
    );
  }
  getAddressbyLatLng = (data) =>
  {
    var latitude = data.lat.toString();
    var longitude = data.lng.toString();
    var self = this;
    Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      response =>
      {
        console.log("response", response)
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        console.log("address", address)
        var fields = self.state.fields;
        fields['pickupLatLng'].value.lat = lat;
        fields['pickupLatLng'].value.lng = lng;
        fields['address'].value = address;
        self.setState({ fields });
      },
      error =>
      {
        console.error(error);
      }
    );

  }
  getAddressbyLatLngOnlyDrop = (data) =>
  {
    var latitude = data.lat.toString();
    var longitude = data.lng.toString();
    var self = this;
    Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      response =>
      {
        console.log("response", response)
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        console.log("address", address)
        var fields = self.state.fields;
        fields['dropLatLng'].value.lat = lat;
        fields['dropLatLng'].value.lng = lng;
        fields['dropaddress'].value = address;
        self.setState({ fields });
      },
      error =>
      {
        console.error(error);
      }
    );

  }
  updateCustomer = () =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      this.updateCustomerDetails();
    }
    this.setState({ fields });

  }
  updateCustomerDetails = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.props.match.params.id, customer_salutation: this.state.fields.salutation.value, customer_name: this.state.fields.username.value, customer_mobile: this.state.fields.contact_number.value, customer_email: this.state.fields.email_id.value, pickup_latitude: this.state.fields.pickupLatLng.value.lat, pickup_longitude: this.state.fields.pickupLatLng.value.lng, pickup_address: this.state.fields.address.value, assign_id: this.props.match.params.assignid, dropoff_latitude: this.state.fields.dropLatLng.value.lat, dropoff_longitude: this.state.fields.dropLatLng.value.lng, dropoff_address: this.state.fields.dropaddress.value
    };

    console.log("customer details obj", obj);
    apiservices.updatebookingcustomerdetails(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          console.log("success", response)
          setTimeout(() =>
          {
            self.loadCustomerDetails();
            self.setState({ editOpen: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        } else
        {
          console.log("failure", response)
          this.setState({ responseMsg: response.message })
        }

      })
  }
  loadCustomerDetails = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id, assign_id: this.props.match.params.assignid };
    console.log("booking details obj", obj);
    apiservices.bookingdetailsview(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          console.log("booking details pickup", response)
          this.setState({ customerDetails: response.response && response.response.customersDetails })
          var fields = this.state.fields;
          fields['address'].value = response.response.customersDetails.pickup_address;
          fields['dropaddress'].value = response.response.customersDetails.dropoff_address;
          fields['clonedaddress'].value = response.response.customersDetails.dropoff_address;
          fields['salutation'].value = response.response.customersDetails.customer_salutation;
          fields['username'].value = response.response.customersDetails.customer_name;
          fields['contact_number'].value = response.response.customersDetails.customer_mobile;
          fields['email_id'].value = response.response.customersDetails.customer_email;
          this.setState({ fields });
          fields['pickupLatLng'].value.lat = response.response.customersDetails.pickup_latitude;
          fields['pickupLatLng'].value.lng = response.response.customersDetails.pickup_longitude;
          fields['dropLatLng'].value.lat = response.response.customersDetails.dropoff_latitude;
          fields['dropLatLng'].value.lng = response.response.customersDetails.dropoff_longitude;
          fields['cloneddropLatLng'].value.lat = response.response.customersDetails.dropoff_latitude;
          fields['cloneddropLatLng'].value.lat = response.response.customersDetails.dropoff_longitude;
          this.setState({ fields });
        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  componentWillMount()
  {
    this.loadCustomerDetails();
    // this.getCurrentPosition();
  }
  delayMarker = () =>
  {
    this.setState({ marker: true })
  }
  onChangeAddress = (value) =>
  {
    var address_url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + value + '&components=country:ind&key='
    const token = localStorage['accesstoken'];
    var obj = { url: address_url };
    console.log("get location obj", obj);
    apiservices.getlocation(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ predictions: response.response.predictions });
          console.log("get location", response)

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })


  }
  // drop only
  onChangeDropOnlyAddress = (value) =>
  {
    var address_url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + value + '&components=country:ind&key='
    const token = localStorage['accesstoken'];
    var obj = { url: address_url };
    console.log("get location obj", obj);
    apiservices.getlocation(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ droponlypredictions: response.response.predictions });
          console.log("get location", response)

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })


  }
  printAddress = () =>
  {
    return (
      <div className="address_bardisplay" style={{ top: '100%', left: 17 }}>
        <List className="address_boxlist">

          {this.state.predictions && this.state.predictions.length > 0 && this.state.predictions.map((data, key) =>
          {
            return (

              <ListItem className="list-location" key={key} onClick={() => this.changeAddress(data)}>

                <img src={images.map_icon} className="map_icon_cls" /><p style={{ cursor: 'pointer' }}>{data.description}</p>
              </ListItem>
            )
          })}

        </List>
      </div>
    )
  }
  printDropOnlyAddress = () =>
  {
    return (
      <div className="address_bardisplay" >
        <List className="address_boxlist">

          {this.state.droponlypredictions && this.state.droponlypredictions.length > 0 && this.state.droponlypredictions.map((data, key) =>
          {
            return (

              <ListItem className="list-location" key={key} onClick={() => this.changedroponlyAddress(data)}>

                <img src={images.map_icon} className="map_icon_cls" /><p style={{ cursor: 'pointer' }}>{data.description}</p>
              </ListItem>
            )
          })}

        </List>
      </div>
    )
  }
  // pickup and chauffeur
  changeAddress = (data) =>
  {
    console.log("change address", data);
    var fields = this.state.fields;
    fields['address'].value = data.description;
    this.setState({ fields, predictions: [] })
  }
  // drop off only
  changedroponlyAddress = (data) =>
  {
    console.log("change address", data);
    var fields = this.state.fields;
    fields['dropaddress'].value = data.description;
    this.setState({ fields, droponlypredictions: [] })
  }
  // pickup and drop only
  changeDropAddress = (data) =>
  {
    var fields = this.state.fields;
    fields['dropaddress'].value = data.description;
    this.setState({ fields, predictionDropoff: [] }, function ()
    {
      this.loadDropMapPredictions(data.place_id);
    })
  }

  loadDropMapPredictions = (value) =>
  {
    // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
    var addres_url = 'https://maps.googleapis.com/maps/api/place/details/json?placeid=' + value + '&key='
    const token = localStorage['accesstoken'];
    console.log("")
    var obj = { url: addres_url };
    apiservices.getlocation(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {

          var getlocation = response.response.result.geometry.location;
          console.log("geolocation", getlocation)
          var fields = this.state.fields;
          fields['dropLatLng'].value.lat = getlocation.lat;
          fields['dropLatLng'].value.lng = getlocation.lng;
          //  fields['cloneddropLatLng'].value.lat=getlocation.lat;
          //  fields['cloneddropLatLng'].value.lng=getlocation.lng;
          this.setState({ fields });

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  checkboxChange = () =>
  {
    var fields = this.state.fields;
    fields['sameaddress'].value = !fields['sameaddress'].value;
    if (fields.sameaddress.value == true)
    {
      fields['dropaddress'].value = fields['address'].value;
      fields['dropLatLng'].value = fields['pickupLatLng'].value;
    } else
    {
      fields['dropaddress'].value = fields['clonedaddress'].value
      fields['dropLatLng'].value = fields['dropLatLng'].value;
    }
    this.setState({ fields })


  }
  getAddressbyLatLngDropoff = (data) =>
  {
    var latitude = data.lat.toString();
    var longitude = data.lng.toString();
    var self = this;
    Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      response =>
      {
        console.log("response", response)
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        console.log("address", address)
        var fields = self.state.fields;
        fields['dropLatLng'].value.lat = lat;
        fields['dropLatLng'].value.lng = lng;
        fields['dropaddress'].value = address;
        self.setState({ fields });
      },
      error =>
      {
        console.error(error);
      }
    );

  }
  // pickup and drop 
  onChangeDropoffAddress = (value) =>
  {
    var address_url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' + value + '&components=country:ind&key='
    const token = localStorage['accesstoken'];
    var obj = { url: address_url };
    console.log("get location obj", obj);
    apiservices.getlocation(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ predictionDropoff: response.response.predictions });
          // this.setState({predictionDropoff:response.response.predictions});
          console.log("get location", response)

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })


  }
  printPickupAddress = () =>
  {
    return (
      <div className="address_bardisplay">
        <List className="address_boxlist">

          {this.state.predictionsPickup.map((data, key) =>
          {
            return (

              <ListItem className="list-location" key={key} onClick={() => this.changeAddress(data)}>

                <img src={images.map_icon} className="map_icon_cls" /><p style={{ cursor: 'pointer' }}>{data.description}</p>
              </ListItem>
            )
          })}

        </List>
      </div>
    )
  }
  printDropoffAddress = () =>
  {
    return (
      <div className="address_bardisplay" style={{ top: '195%', left: 20 }}>
        <List className="address_boxlist">

          {this.state.predictionDropoff.map((data, key) =>
          {
            return (

              <ListItem className="list-location" key={key} onClick={() => this.changeDropAddress(data)}>

                <img src={images.map_icon} className="map_icon_cls" /><p style={{ cursor: 'pointer' }}>{data.description}</p>
              </ListItem>
            )
          })}

        </List>
      </div>
    )
  }
  render()
  {
    console.log("cloned lat", this.state.fields.cloneddropLatLng.lat);
    const { customerDetails } = this.state;
    return (
      <div>
        <div className="booking_pickup">

          <h4>Customer Details</h4>
          <img src={images.edit_icon} style={{ opacity: customerDetails && customerDetails.status_id != 3 ? 1 : 0.5 }} className="img-fluid edit-details" onClick={() => customerDetails.status_id != 3 ? this.EditPickup() : ''} />

          <div className="pickup-details">
            <div className="pick-whole">
              <div className="customer_label">Address</div>
              <div className="customer_detail">{customerDetails && customerDetails.customer_address}</div>
            </div>

            <div className="pick-whole">
              <div className="customer_label">Contact Number</div>
              <div className="customer_detail"><span className="mr-2">{customerDetails && customerDetails.customer_mobile}</span> <span>{customerDetails && customerDetails.mobile_no}</span></div>
            </div>


            <div className="pick-whole">
              <div className="customer_label">Email ID</div>
              <div className="customer_detail">{customerDetails && customerDetails.customer_email}</div>
            </div>


            <div className="pick-whole">
              <div className="customer_label">Slot</div>
              <div className="customer_detail"><span className="mr-2">{moment(customerDetails && customerDetails.slot_day).format('YYYY/MM/DD')}</span> <span>{customerDetails && customerDetails.slot_time}</span></div>
            </div>

            <div className="pick-whole">
              <div className="customer_label">Note</div>
              <div className="customer_detail">{customerDetails && customerDetails.notes}</div>
            </div>

          </div>
          <Dialog open={this.state.editOpen} className={`booking_pickup ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
            <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.setState({ editOpen: false })} /></div>
            <div className="modalcntntcls" style={{ width: '90%' }}>
              <div className="delete_text">
                Customer Details
                   </div>
              <div>
                {customerDetails && customerDetails.booking_type_id == 1 || customerDetails && customerDetails.booking_type_id == 3 ?
                  <div className="form-group reason_box" >
                    <div className="map-img" style={{ width: '100%' }}>

                      <MapWithAMarker
                        isMarkerShown={this.state.marker}
                        currentLocation={{ lat: parseFloat(this.state.fields.pickupLatLng.value.lat), lng: parseFloat(this.state.fields.pickupLatLng.value.lng) }}
                        //  currentLocation={this.state.pickupLatLng}
                        sendingDrabbleLocation={(latlng) => this.getAddressbyLatLng({ lat: latlng.lat(), lng: latlng.lng() })}
                        containerElement={<div style={{ height: `300px`, borderRadius: 20, width: '100%' }} />}
                        mapElement={<div style={{ height: `100%`, borderRadius: 20 }} />}
                      />



                    </div>
                  </div> : ''}
                {customerDetails && customerDetails.booking_type_id == 4 ?
                  <div>

                    <div className="form-group reason_box">
                      <div className="map-img" style={{ width: '100%' }}>
                        <MapWithAMarkerPickup
                          currentLocation={{ lat: parseFloat(this.state.fields.pickupLatLng.value.lat), lng: parseFloat(this.state.fields.pickupLatLng.value.lng) }}
                          sendingDrabbleLocationPickup={(latlng) => this.getAddressbyLatLngPickup({ lat: latlng.lat(), lng: latlng.lng() })}
                          //  currentLocation={this.state.currentLatLng}
                          containerElement={<div style={{ height: `300px`, borderRadius: 20, width: '100%' }} />}
                          mapElement={<div style={{ height: `100%`, borderRadius: 20 }} />}
                        />



                      </div>
                    </div>
                    <div className="form-group reason_box">
                      <label>
                        Pickup Address
                         </label>

                      <textarea row="5" col="3" maxLength="300" name="address" className="form-control reason_box" value={this.state.fields.address.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                      <span className="error_msg_adddealer">{this.state.fields['address'].error && this.state.fields['address'].error}</span>
                      {/* {this.printPickupAddress()} */}
                      {this.printAddress()}
                    </div>
                    <div>
                      <div className="drop_off_location_text"><img className="check_img" src={this.state.fields.sameaddress.value == false ? images.checkbox_uncheck : images.checkbox_check} onClick={() => this.checkboxChange()} />Drop-off Location is same as pickup</div></div>
                    <div className="form-group reason_box">
                      <div className="map-img" style={{ width: '100%' }}>

                        <MapWithAMarkerDrop

                          currentLocation={{ lat: parseFloat(this.state.fields.dropLatLng.value.lat), lng: parseFloat(this.state.fields.dropLatLng.value.lng) }}
                          sendingDrabbleLocationDropoff={(latlng) => this.getAddressbyLatLngDropoff({ lat: latlng.lat(), lng: latlng.lng() })}
                          //  currentLocation={this.state.currentLatLng}
                          containerElement={<div style={{ height: `300px`, borderRadius: 20, width: '100%' }} />}
                          mapElement={<div style={{ height: `100%`, borderRadius: 20 }} />}
                        />



                      </div>
                    </div>
                    <div className="form-group reason_box">
                      <label>
                        Drop-off Address
                         </label>

                      <textarea row="5" col="3" maxLength="300" name="dropaddress" className="form-control reason_box" value={this.state.fields.dropaddress.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                      <span className="error_msg_adddealer">{this.state.fields['dropaddress'].error && this.state.fields['dropaddress'].error}</span>
                      {this.printDropoffAddress()}
                    </div>
                  </div> : ''
                }

                {customerDetails && customerDetails.booking_type_id == 1 || customerDetails && customerDetails.booking_type_id == 3 ?
                  <div className="form-group reason_box">
                    <label>
                      Address
                         </label>

                    <textarea row="5" col="3" maxLength="300" name="address" className="form-control reason_box" value={this.state.fields.address.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                    <span className="error_msg_adddealer">{this.state.fields['address'].error && this.state.fields['address'].error}</span>
                    {this.printAddress()}
                  </div> : ''}
                {customerDetails && customerDetails.booking_type_id == 2 ?
                  <div>
                    <div className="form-group reason_box" >
                      <div className="map-img" style={{ width: '100%' }}>

                        <MapWithAMarkerOnlyDrop
                          isMarkerShown={this.state.marker}
                          currentLocation={{ lat: parseFloat(this.state.fields.dropLatLng.value.lat), lng: parseFloat(this.state.fields.dropLatLng.value.lng) }}
                          //  currentLocation={this.state.pickupLatLng}
                          sendingDrabbleLocation={(latlng) => this.getAddressbyLatLngOnlyDrop({ lat: latlng.lat(), lng: latlng.lng() })}
                          containerElement={<div style={{ height: `300px`, borderRadius: 20, width: '100%' }} />}
                          mapElement={<div style={{ height: `100%`, borderRadius: 20 }} />}
                        />



                      </div>
                    </div>
                    <div className="form-group position-relative">
                      <label className="label_paused">
                        Drop-off Address
                         </label>

                      <textarea row="5" className="form-control" name="dropaddress" value={this.state.fields.dropaddress.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)}  ></textarea>
                      <span className="error_msg_adddealer">{this.state.fields['dropaddress'].error && this.state.fields['dropaddress'].error}</span>
                      {this.state.droponlypredictions && this.state.droponlypredictions.length > 0 &&
                        this.printDropOnlyAddress()}
                    </div>
                  </div>
                  : ''}
                <div class="form-group ">
                  <label className="label_paused">Customer Name</label>

                  <div className="svcUserdropdown saluation_drop">
                    <Select name="salutation" value={this.state.fields.salutation.value} onChange={(value) => this.checkvalidations(value, 'salutation')} className="svcdropList position_set"  >
                      <Option value="Mr">Mr</Option>
                      <Option value="Mrs">Mrs</Option>
                      <Option value="Miss">Miss</Option>
                    </Select>
                    <img src={images.dropdown} className="img-fluid saluation_dropdown_cus" />
                    <input type="text" className="form-control input_paused" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />

                  </div>
                  <span className="error_msg_adddealer">{this.state.fields['username'].error && this.state.fields['username'].error}</span>
                </div>
                <div className="form-group">
                  <label className="label_paused">
                    Contact Number
                           </label>

                  <input type="text" className="form-control input_paused" name="contact_number" value={this.state.fields.contact_number.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['contact_number'].error && this.state.fields['contact_number'].error}</span>
                </div>

                <div className="form-group">
                  <label className="label_paused">
                    Email ID
                           </label>

                  <input type="text" className="form-control input_paused" name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['email_id'].error && this.state.fields['email_id'].error}</span>

                </div>
                {/* <div className="form-group reason_box">
                         <label>
                         Notes
                         </label>

                         <textarea row="5" col="3"   maxLength="300" name="notes" className="form-control reason_box" value={this.state.fields.notes.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['notes'].error&&this.state.fields['notes'].error}</span> 
                         
                     </div> */}
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.updateCustomer()}>Submit</button>
                </div>
              </div>


            </div>
          </Dialog>
        </div>

      </div>

    )
  }
  componentDidMount()
  {
    this.props.sendbookingid && this.props.sendbookingid(this.props.match.params.id, this.props.match.params.assignid)
    this.getCurrentPosition();
    var self = this;
    setTimeout(() =>
    {
      self.delayMarker();
    }, 3000);

  }
}
export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(BookingPickup)