import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import Geocode from "react-geocode";
import {Map, InfoWindow,  GoogleApiWrapper,Polyline,Marker} from 'google-maps-react';

class BookingRouteToken extends Component 
{

    constructor(props){

        super(props);

        this.state = {
           loading:false,
           routeTaken:null
        }
    }
    loadCustomerDetails=(data)=>{
        this.setState({loading:true})
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
        console.log("booking svc details obj", obj);   
        apiservices.bookingdetailsview(obj,token)
              .then((response)=>{
                  this.setState({loading:false});
                  if(!response.error){
                      this.setState({routeTaken:response.response.routeTaken})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    componentWillMount(){
      this.loadCustomerDetails();
    }
    onMarkerDragEnd =(evt)=>{
      this.getAddressbyLatLng(evt.position)
      console.log("draggable",evt)

    }
    getAddressbyLatLng =(data)=>{
      console.log("get address",data)
      var latitude=data.lat.toString();
      var longitude=data.lng.toString();
      var self=this;
      Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
      Geocode.enableDebug();
      Geocode.fromLatLng(latitude,longitude).then(
        response => {
          console.log("response",response.results[0].formatted_address)
          // const { lat, lng } = response.results[0].geometry.location;
          // var fields=self.state.fields;
          // fields['currentLatLng'].value.lat=lat;
          // fields['currentLatLng'].value.lng=lng;
          // self.setState({fields});
          // currentLatLng['lat']=lat;
          // currentLatLng['lng']=lng;
          // self.setState({currentLatLng})
        },
        error => {
          console.error(error);
        }
      );
  
    }
render(){
    const {routeTaken} =this.state;
    const triangleCoords = [
        {lat: parseFloat(routeTaken&&routeTaken.centre_latitude), lng: parseFloat(routeTaken&&routeTaken.centre_longitude)},
        {lat: parseFloat(routeTaken&&routeTaken.customer_latitude), lng: parseFloat(routeTaken&&routeTaken.customer_longitude)},

      ];
      // const bounds = new window.google.maps.LatLngBounds();
      // for (var i = 0; i < triangleCoords.length; i++) {
      //   bounds.extend( new window.google.maps.LatLng(triangleCoords[i].lat, triangleCoords[i].lng) );
      // }
    //   this.refs.map&&this.refs.map.fitBounds(bounds)
    //   this.props.googles.fitBounds(bounds);
    //   console.log("map",this.props.google)
   return(
       

   <div>
               <div className="booking_pickup" style={{height:'550px'}}>
                      
                      <div className="booking-route">
                      {this.state.loading==true&&
    <CircularProgress />}
                       <Map
                      ref={map => map}
                       google={this.props.google}
                      center={{lat:routeTaken&&routeTaken.customer_latitude,lng:routeTaken&&routeTaken.customer_longitude}}
        style={{width: '100%', height: '500px', position: 'relative',left:0}}
        className={'map'}
        zoom={15}>
        <Polyline
          path={triangleCoords}
          strokeColor="#0000FF"
          strokeOpacity={0.8}
          strokeWeight={2} />
          {triangleCoords&&triangleCoords.map((item)=>{
              return(
<Marker
    position={item}
   
  />
              )
          })}
           
    </Map> 
   
                      </div>
</div>

</div>

 )
    }
    componentDidMount(){
        this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
      }
}
export default GoogleApiWrapper({
    apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
  })(BookingRouteToken)