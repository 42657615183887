import React, { Component } from "react";
import images from "../../helpers/images";
import { Table, Tag, Space, Select, Pagination } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./styles.css";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
   
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import apiservices from '../../helpers/apiservices';
var SvcUserListArray = [{ ID: "ID1256", Name: "Craig Schneider", mobileNumber: 9837643023, emailID: "craigschneider@gmail.com", designation: "Customer Relations Manager" }];

export default class SvcUserList extends Component {
    constructor(props) {
        console.log("this props",props);
        super(props)
        this.state = {
            SvcUserListArray: SvcUserListArray,
            tempdata: [],
            currentPage: 1,
            pageSize: 10,
            name_search:null,
            editID:null,
            loading:false
        };
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
        }
        if (type === 'next') {
            return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
        }
        return originalElement;
    }
    onchangePagination = (page, pageSize) => {
      console.log("page", page);
      this.setState({ currentPage: page },function(){
        this.loaduserlist();
      });
  
      console.log("page size", pageSize);
    }
    showChanger = (current, size) => {
      this.setState({ pageSize: size ,currentPage:1},function(){
        this.loaduserlist();
      })
    }
    loaduserlist=()=>{
      this.setState({loading:true})
        const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center_id:this.props.match.params.svc_id,name:this.state.name_search};
    console.log("loadscvlist",obj)
          apiservices.svcuserlist(obj,token)
          .then((response)=>{
            var limit=response.count;
            var total_items=this.state.pageSize;
            var calc=Math.ceil(limit/total_items)*total_items;
            this.setState({loading:false})
            if(!response.error){
              this.setState({tempdata:response.response,numbercount:calc})
              console.log("list svc",response)
      
            }else{
              this.setState({error_msg:response.message})
            }
              
          })
    }
    componentWillMount(){
        this.loaduserlist();
        var svc_id=this.props.match.params.svc_id;
        this.props.sendsvcid(svc_id);
    }
    
    searchname =(e)=>{
        var value=e.target.value;
        console.log("city search",value);
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loaduserlist();
        });
        
    }
    sortAscending = () => {
        const { tempdata } = this.state;
        tempdata.sort((a, b) => a - b)    
        this.setState({ tempdata })
      }
    
      sortDescending = () => {
        const { tempdata } = this.state;
        tempdata.sort((a, b) => a - b).reverse()
        this.setState({ tempdata })
      }
      deleteUserList =(data)=>{
        const token=localStorage['accesstoken'];
        var obj={id:data};
        console.log("loadscvlist",obj)
              apiservices.svcdeleteuser(obj,token)
              .then((response)=>{
                if(!response.error){
                  
                    this.loaduserlist();
                    this.setState({success_msg:response.message})
                  console.log("list svc",response)
          
                }else{
                  this.setState({error_msg:response.message})
                }
                  
              })
          console.log("delete id",data);
      }
      editUser=(data)=>{

          this.setState({editID:data},function(){
            this.props.sendeditdata(this.state.editID);
          })
          
      }
    render() {
        return (
            <div className="svc_user_list">
                <input className="searchinput" style={{marginLeft:0}} type="text" placeholder="Search" name="name_search" onChange={(e)=>this.searchname(e)} />
                <img src={images.green_search} className="searchimage" />
                <table class="table table-hover">
                    <thead>
                        {this.state.SvcUserListArray && this.state.SvcUserListArray.map((obj) => {
                            return (<tr>
                                <th>ID</th>
                                <th className="tableleftAlign">Name
                                <span style = {{position:"absolute",marginTop:9,marginLeft:10}}><img src={images.down_arrow} onClick={this.sortAscending} style={{ width: 8 }} /></span>
                              <span style = {{position:"absolute",marginTop:-6,marginLeft:10}}><img onClick={this.sortDescending} src={images.up_arrow} style={{ width: 8 }} /></span></th>
                                <th>Mobile Number</th>
                                <th className="tableleftAlign">E-mail ID</th>
                                <th className="tableleftAlign">Designation</th>
                                <th>Edit</th>
                                <th >Delete</th>
                            </tr>
                            )
                        })}
                    </thead>
                    <tbody>
                    {this.state.tempdata &&this.state.tempdata.length>0 && this.state.tempdata.map((obj) => {
                        {/* {this.state.tempdata && this.state.tempdata.slice((this.state.currentPage - 1) * this.state.pageSize, (this.state.currentPage - 1) * this.state.pageSize + this.state.pageSize).map((obj) => { */}
                            return (<tr>
                                <td >ID{obj.id}</td>
                                <td className="tableleftAlign"> {obj.name}</td>
                                <td>{obj.mobile_no}</td>
                                <td className="tableleftAlign">{obj.email}</td>
                                <td className="tableleftAlign">{obj.designation_name}</td>
                                <td><Link to={{
  pathname: `${this.props.parentProps.match.path}/add_user/${obj.id}/${this.props.match.params.svc_id}`,
  
}}><img src={images.EditImg} style={{ width: 38,cursor:'pointer' }} onClick={()=>this.editUser(obj.id)} /></Link></td>
                                <td><img src={images.delete_icon} style={{ width: 38,cursor:'pointer' }} onClick={()=>this.deleteUserList(obj.id)} /></td>
                            </tr>);
                        })}
                    </tbody>

                </table>
                {this.state.tempdata&&this.state.tempdata.length==0&&
                <div className="no_records_found">No Records Found</div>
                }
                 {this.state.loading==true &&
    <CircularProgress />
            }
                {this.state.error_msg?
            <div className="failure_msg">{this.state.error_msg}</div> :''
            }
            {this.state.success_msg?
            <div className="success_msg">{this.state.success_msg}</div> :''
            }

                {/* {this.state.tempdata.length>this.state.pageSize&& */}
                {this.state.tempdata.length!=0&&
                <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
                    pageSize={this.state.pageSize} itemRender={this.itemRender}
                />
    }
    {/* } */}
            </div>
        )
    }
}