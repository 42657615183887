import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';

class BookingNotes extends Component 
{

    constructor(props){

        super(props);

        this.state = {

        }
    }
render(){

   return(
       

   <div>
               <div className="booking_pickup">

                    <input type="text" placeholder="Same day Delivery" className="form-control paid_service"/>
               </div>

</div>

 )
    }
}
export default BookingNotes;