
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import '../styles.css';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import moment from 'moment';
import PickupDropVehicleDetails from '../PickUpDropOff/PickupDropVehicleDetails';
import PickupDropLocation from '../PickUpDropOff/PickupDropLocation';
import SlotServiceDetails from '../PickUpDropOff/SlotServiceDetails';
const dateFormat = 'YYYY/MM/DD';
class PickUpDropOffMain extends Component{
    constructor(props){
        super(props);
        this.state = {
            pickupstatus:1,
            vehicleDetails:null,
            pickupLocation:null,
            slotService:null
           
        }
    }

    receivepickupdetails=(data,key,details,submitdata)=>{
        if(data!=4){
            this.setState({pickupstatus:data});
          }
        this.setState({[key]:details},function(){
            if(details.svc_list){
            this.setState({svccentreid:details.svc_list.value})
            }
            if(data==4){
                const {vehicleDetails,pickupLocation,slotService}=this.state;
         const token=localStorage['accesstoken'];
        var obj={
            reg_number:'',
            svc_center_id:vehicleDetails.svc_list.value,
            // mobile_no:vehicleDetails.mobile_number.value,
            vehicle_modal:vehicleDetails.vehicle_model.value,
            vehicle_varient:vehicleDetails.vehicle_variant.value,
            vehicle_number:vehicleDetails.vehicle_number.value,
            customer_salutation:vehicleDetails.salutation.value,
            customer_name:vehicleDetails.customer_name.value,
            customer_mobile:vehicleDetails.customer_mobile_number.value,
            customer_email:vehicleDetails.customer_emailid.value,
            pickup_address:pickupLocation.pickup_address.value,
            pickup_latitude:pickupLocation.pickupLatLng.value.lat,
            pickup_longitude:pickupLocation.pickupLatLng.value.lng,
            pickup_address_type_id:pickupLocation.pickup_address_type.value,
            dropoff_address:pickupLocation.drop_address.value,
            dropoff_latitude:pickupLocation.cloneddropLatLng.value.lat,
            dropoff_longitude:pickupLocation.cloneddropLatLng.value.lng,
            dropoff_address_type_id:pickupLocation.drop_address_type.value,
            slot_day:moment(slotService.slotdate.value).format(dateFormat),
            slot_time:slotService.slottimings.value,
            cre:slotService.cre.value,
            service_advisor:slotService.service_advisor.value,
            // invoice_amount:slotService.invoice_amount.value,
            // invoice_doc:image,
            notes:slotService.notes.value,

 
           }
                
            console.log("booking pickup dropoff",obj);
            if(submitdata=='submit'){
            apiservices.addbookingpickupdropoff(obj,token)
      .then((response)=>{
        console.log("add booking pickup drop",response);
        if(!response.error){  
          // this.props.successdealer(response);     
          this.setState({addpickupdropoffSuccess:response})
        }else{
          this.setState({addpickupdropoffFailure:response})
        }
         
      })
    }
        }
        });
    }
    goNext=(data)=>{
        this.setState({pickupstatus:data});
    }
    render(){
        return(
            <div className="pickup">
                {this.state.pickupstatus==1 &&
                <PickupDropVehicleDetails sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} vehicleDetails={this.state.vehicleDetails}/>
                    }
                {this.state.pickupstatus==2 &&
                 <PickupDropLocation pickupLocation={this.state.pickupLocation} sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} sendgoback={(data)=>this.goNext(data)}/>
                }
                {this.state.pickupstatus==3 &&
                 <SlotServiceDetails {...this.props}  sendpickupdetails={(data,key,details,submitdata)=>this.receivepickupdetails(data,key,details,submitdata)} sendgoback={(data)=>this.goNext(data)} slotService={this.state.slotService} svccentreid={this.state.svccentreid} addpickupdropoffSuccess={this.state.addpickupdropoffSuccess} addpickupdropoffFailure={this.state.addpickupdropoffFailure}/> 
                }


        
            </div>
        )





        
    }
 
}


export default PickUpDropOffMain;
