import React, { Component } from 'react';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { DatePicker, Select, Upload, message } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../../helpers/validationfunction';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
const { Option } = Select;

var ticketList = [{ 'id': 1, 'ticket': 'Ticket' },
{ 'id': 2, 'ticket': 'Ticket' },
{ 'id': 3, 'ticket': 'Ticket' },
{ 'id': 4, 'ticket': 'Ticket' }];
export default class Tickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketList: [],
      loading: false,
      fields: {
        title: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'alphabetsOnly' }], error: null, value: '' },
        priority: { validations: [{ name: 'required' }], error: null, value: '' },
        ticketType: { validations: [{ ticket_name: 'required' }], error: null, value: '' },

      },
      addlistOpen: false
    }
  }
  loadTicketList = () => {
    this.setState({ loading: true })
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.listticketdrop(obj, token)
      .then((response) => {
        this.setState({ loading: false });
        if (!response.error) {
          this.setState({ ticketList: response.response })
          console.log("check list", response)
        }
        else {
          this.setState({ error_msg: response.message })
        }
      })
  }
  loadPriorityDropdown = (data) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.listticketprioritydrop(obj, token)
      .then((response) => {
        // this.setState({loading:false});
        if (!response.error) {
          this.setState({ priority: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['priority'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else {
          this.setState({ error_msg: response.message })
        }
      })
  }

  loadTicketTypeDropdown = (data) => {
    console.log("data",data)
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.listticketTypedrop(obj, token)
      .then((response) => {
        // this.setState({loading:false});
        console.log("rersposnseType",response)
        if (!response.error) {
          this.setState({ ticketType: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['ticketType'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else {
          this.setState({ error_msg: response.message })
        }
      })
  }

  componentWillMount() {
    this.loadTicketList();
    this.loadPriorityDropdown();
    this.loadTicketTypeDropdown();
  }
  deleteList = (data) => {
    const token = localStorage['accesstoken'];
    var obj = { id: data };
    console.log("actve", obj);
    apiservices.deleteticketdrop(obj, token)
      .then((response) => {
        // this.setState({loading:false});
        if (!response.error) {
          this.loadTicketList();
          this.setState({ ticket_success_msg: response.message })
          setTimeout(() => {
            this.setState({ ticket_success_msg: '' })
          }, 3000);

          console.log("check list", response)
        }
        else {
          this.setState({ ticket_error_msg: response.message })
          setTimeout(() => {
            this.setState({ ticket_error_msg: '' })
          }, 3000);
        }
      })
    console.log("delete id", data);
  }
  checkvalidations = (value, key) => {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0) {
      this.setState({ error: true })
      //errorblock
    } else {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  submitPopup = async () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true })
    } else {
      //successblock
      this.setState({ error: false });
      this.addChecklist();
    }
    this.setState({ fields });

  }
  addChecklist = (data) => {
    const token = localStorage['accesstoken'];
    var obj = { ticket: this.state.fields.title.value, priority_id: this.state.fields.priority.value, ticket_type: this.state.fields.ticketType.value };
    console.log("booking svc details obj", obj);
    apiservices.addticketdrop(obj, token)
      .then((response) => {
        var self = this;
        if (!response.error) {
          setTimeout(() => {
            self.loadTicketList();
            self.loadPriorityDropdown();
            self.loadTicketTypeDropdown();
            self.setState({ addlistOpen: false })
          }, 3000);
          this.setState({ success_msg: response.message })
        } else {
          this.setState({ error_msg: response.message })
        }

      })
  }
  checklistOpen = () => {
    var fields = this.state.fields;
    fields['title'].value = '';
    this.setState({ addlistOpen: true, filenewlist: [], fields, success_msg: '', error_msg: '' })
  }
  deleteClose = () => {
    var fields = this.state.fields;
    fields['title'].value = '';
    fields['title'].error = '';
    this.setState({ addlistOpen: false, filenewlist: [], fields, success_msg: '', error_msg: '' })
  }
  render() {
    const { priority,ticketType } = this.state;
    return (
      <div className="checklist_main">
        <div className="container">
          <div className="addbtnmain">
            <button className="add_new_btn" onClick={() => this.checklistOpen()}>Add New</button>
          </div>
          <div>
            <div class="appdata-tabs appdata_main">
              <br />
              <Row>
                <Col sm={12}>
                  <Nav variant="pills" className="flex-row appdatamain_nav" selectedIndex={this.state.selectedIndex}
                    onSelect={this.handleSelect}>

                    <NavLink exact={true} to={`${this.props.match.path}/`}  >On route to customer location</NavLink>
                    <NavLink to={`${this.props.match.path}/customerLocationSVC`}  >Customer location to SVC</NavLink>
                    <NavLink to={`${this.props.match.path}/routeSVC`} >On route to SVC</NavLink>
                    <NavLink to={`${this.props.match.path}/svcCustomer`} >SVC to customer</NavLink>

                  </Nav>
                </Col>
                <Col sm={12}>
                  <div className="appdata_first">
                    <Switch>
                      <Route exact path={`${this.props.match.path}/`} render={props => (<ListTickets ticket_type={1} ticketList={this.state.ticketList} deleteList={(id)=>this.deleteList(id)}/>)} />
                      <Route exact path={`${this.props.match.path}/customerLocationSVC`} render={props => (<ListTickets ticket_type={2} ticketList={this.state.ticketList} deleteList={(id)=>this.deleteList(id)}/>)} />
                      <Route exact path={`${this.props.match.path}/routeSVC`} render={props => (<ListTickets ticket_type={3} ticketList={this.state.ticketList} deleteList={(id)=>this.deleteList(id)}/>)} />
                      <Route exact path={`${this.props.match.path}/svcCustomer`} render={props => (<ListTickets ticket_type={4} ticketList={this.state.ticketList} deleteList={(id)=>this.deleteList(id)}/>)} />
                    </Switch>

                  </div>
                </Col>
              </Row>
              {/* </Tab.Container> */}
            </div>
            {/* Form Tabs start */}
          </div>

          {this.state.loading == true &&
            <CircularProgress />
          }
          {this.state.ticket_error_msg ?
            <div className="failure_msg">{this.state.ticket_error_msg}</div> : ''
          }

          {this.state.ticket_success_msg ?
            <div className="success_msg">{this.state.ticket_success_msg}</div> : ''
          }

        </div>
        <Dialog open={this.state.addlistOpen} className="shopbox upload-invoice">
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.deleteClose()} /></div>
          <div className="modalcntntcls">
            <div className="delete_text">
              Add Tickets
                   </div>

            <div className="form-group">
              <label>Enter the Ticket Name</label>
              <input type="text" className="form-control" name="title" value={this.state.fields.title.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
              <span className="error_msg_adddealer">{this.state.fields['title'].error && this.state.fields['title'].error}</span>

            </div>
            <div className="form-group">
              <div class="form-inline position-relative">
                <label style={{ display: 'flex', justifyContent: 'flex-start' }} >Priority</label>

                <Select value={[parseInt(this.state.fields.priority.value)]} onChange={(value) => this.checkvalidations(value, 'priority')} name="priority" >
                  {priority && priority.length > 0 && priority.map((item) => {
                    return (
                      <Option value={item.id}>{item.priority}</Option>
                    )
                  })}
                </Select>
                <img src={images.dropdown} className="img-fluid ticket_dropdown_img" />
                <span className="error_msg_adddealer">{this.state.fields['priority'].error && this.state.fields['priority'].error}</span>


              </div>
            </div>
            {console.log("thick",this.state.fields.ticketType.value)}
            <div className="form-group">
              <div class="form-inline position-relative">
                <label style={{ display: 'flex', justifyContent: 'flex-start' }} >Priority</label>

                <Select value={[parseInt(this.state.fields.ticketType.value)]} onChange={(value) => this.checkvalidations(value, 'ticketType')} name="ticketType" >
                  {ticketType && ticketType.length > 0 && ticketType.map((item) => {
                    console.log(item,"item")
                    return (
                      <Option value={item.id}>{item.ticket_name}</Option>
                    )
                  })}
                </Select>
                <img src={images.dropdown} className="img-fluid ticket_dropdown_img" />
                <span className="error_msg_adddealer">{this.state.fields['ticketType'].error && this.state.fields['ticketType'].error}</span>
              </div>
            </div>
            {this.state.error_msg ?
              <div className="failure_msg">{this.state.error_msg}</div> : ''
            }

            {this.state.success_msg ?
              <div className="success_msg">{this.state.success_msg}</div> : ''
            }
            <div className="form-group submit-btns" style={{ paddingBottom: 20 }}><button disabled={this.state.checklistaddMsg && !this.state.checklistaddMsg.error} className="btn btn-blue" onClick={() => this.submitPopup()}>Submit </button></div>

          </div>
        </Dialog>
      </div>
    );
  }
}

// main List

export class ListTickets extends Component {
  constructor(props) {
    super(props);   
  }
//   componentWillReceiveProps=()=>{
// console.log('jjjjjj')
//   }

  render() {
    return (
      <div className="checklist_main">
        <div className="container">
           {/* {console.log("data", this.state.ticketList)} */}
          {this.props.ticketList && this.props.ticketList.length > 0 &&
            this.props.ticketList.filter(({ ticket_type }) => ticket_type == this.props.ticket_type).map((item) => {
              {
                return (
                  <div>
                    <div className="content">
                      <div className="content_box">
                        <div className="content_list">
                          <div className="checklist_title">{item.ticket}</div>
                        </div>
                      </div>
                      <div className="delete_content">
                        <img src={images.delete_icon} onClick={() => this.props.deleteList(item.id)} />
                      </div>

                    </div>

                  </div>
                )
              }
            })
          }

          {this.props.ticketList && this.props.ticketList.length == 0 &&
            <div>No Records Found </div>
          }

        </div>
      </div>
    );
  }
}
