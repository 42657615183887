
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import apiservices from '../../../helpers/apiservices';
import { Select } from 'antd';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import '../styles.css';
import Images from '../../../helpers/images';
import {Map, InfoWindow,  GoogleApiWrapper} from 'google-maps-react';
import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";

function handleChange(value) {
  console.log(`selected ${value}`);
}
const { Option } = Select;
const MapWithAMarker = withGoogleMap((props) =>
{
  const location={ lat: props.currentLocation.lat, lng: props.currentLocation.lng };
 const  onMarkerDragEnd = (event) =>{
props.sendingDrabbleLocation&&props.sendingDrabbleLocation(event.latLng);
  }
  
return(
<div>
<GoogleMap
  zoom={15}
  style={{borderRadius:20,width: '100%', height: '100%'}}
  center={location}
  defaultCenter={location}
  mapTypeControl= {false}
        streetViewControl= {false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
  <Marker
    position={location}
    draggable={true}
     ref={props.onMarkerMounted}
     onDragEnd={onMarkerDragEnd}
  />
</GoogleMap>
</div>
) 
 
}

);

class PickUpLocation extends Component{
    constructor(props){
        super(props);
        this.state = {
          predictions:[],
          fields:{
            address:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
            address_type:{validations:[{name:'required'}],error:null,value:''},
            currentLatLng:{validations:[],error:null,value:{lat:0,lng:0}},
          },
          address_type:'',
       
           
        }
    }

    
    getCurrentPosition = () => {
         
      var self = this;
      navigator.geolocation.getCurrentPosition(position => {
        console.log("position",position)
         
          var obj={
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            latitudeDelta: 0.005,
            longitudeDelta: 0.005,
            coords: position.coords
        }
        var fields=this.state.fields;
        fields['currentLatLng'].value=obj;
       this.setState({fields});
          
             });
     
  }
  getlocationbyAddress=(value,key)=>{
    console.log("value",value);
    var self=this;
    Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromAddress(value).then(
      response => {
        console.log("response",response)
        const { lat, lng } = response.results[0].geometry.location;
        var getlocation={lat:lat,lng:lng};
        var fields=this.state.fields;
        fields[key].value=getlocation;
        this.setState({fields});
        
      },
      error => {
        console.error(error);
      }
    );
       
  }
  getAddressbyLatLng =(data)=>{
    var latitude=data.lat.toString();
        var longitude=data.lng.toString();
      var self=this;
      Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
      Geocode.enableDebug();
      Geocode.fromLatLng(latitude,longitude).then(
        response => {
          console.log("response",response)
          const { lat, lng } = response.results[0].geometry.location;
          const address=response.results[0].formatted_address;
          console.log("address",address)
          var fields=self.state.fields;
          fields['currentLatLng'].value.lat=lat;
          fields['currentLatLng'].value.lng=lng;
          fields['address'].value=address;
          self.setState({fields});
          // currentLatLng['lat']=lat;
          // currentLatLng['lng']=lng;
          // self.setState({currentLatLng})
        },
        error => {
          console.error(error);
        }
      );
  
    }
    gotoslotservice=()=>{
      var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
        this.props.sendpickupdetails(3,'pickupLocation',fields);
      
      }
      this.setState({fields});
      // this.props.sendpickupdetails(3)
    }
    goBack=()=>{
      this.props.sendgoback(1)
    }
    checkvalidations=(value,key)=>{
   
      var fields =this.state.fields;
      fields[key].value=value;
      console.log("value",value);
      var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
      // console.log(checkstatus);
      // if(key=='address'){
      //   this.getlocationbyAddress(value);
      // }
      if(key=='address'){
        this.onChangeAddress(value);
        this.getlocationbyAddress(value,"currentLatLng");
      }
      fields[key].error=checkstatus.msg;
      var getallkeys=Object.keys(fields);
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        this.setState({error:true})
        //errorblock
      }else{
        this.setState({error:false})
  
        //successblock
      }
      this.setState({fields});
    }
    loadaddresstypeDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={};
            apiservices.listaddresstype(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({address_type:response.response})
                var fields=this.state.fields;
                if(!value){
                fields['address_type'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    onChangeAddress =(value) => {
      var address_url='https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+value+'&components=country:ind&key='
      const token=localStorage['accesstoken'];
      var obj={url:address_url};
      console.log("get location obj",obj);
            apiservices.getlocation(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({predictions:response.response.predictions});
               console.log("get location",response)
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
      
  
  }
  printAddress=()=> {
    return(
        <div className="address_bardisplay"> 
  <List className="address_boxlist">
  
  {this.state.predictions.map((data,key)=>{
   return(
       
  <ListItem className="list-location" key={key}  onClick={()=>this.changeAddress(data)}>
  
  <img src={Images.map_icon} className="map_icon_cls"/><p style={{cursor:'pointer'}}>{data.description}</p>
       </ListItem>  
       )
  })}
       
       </List> 
       </div>
    )
  }
  changeAddress=(data)=>{
    console.log("change address",data);
    var fields=this.state.fields;
    fields['address'].value=data.description;
    this.setState({fields,predictions:[]},function(){
      this.loadMapPredictions(data.place_id);
    })
  }
  loadMapPredictions =(value)=>{
    // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
    var addres_url='https://maps.googleapis.com/maps/api/place/details/json?placeid='+value+'&key='
    const token=localStorage['accesstoken'];
    console.log("")
      var obj={url:addres_url};
    apiservices.getlocation(obj,token)
    .then((response)=>{
      if(!response.error){
  
        var getlocation=response.response.result.geometry.location;
       console.log("geolocation",getlocation)
       var fields=this.state.fields;
       fields['currentLatLng'].value.lat=getlocation.lat;
       fields['currentLatLng'].value.lng=getlocation.lng;
       this.setState({fields});
  
      }else{
        this.setState({error_msg:response.message})
      }
        
    })
  }
    render(){
      const {address_type} =this.state;
        return(
            <div className="pickup">

 
        <div class="overall-service">
         <div className="dealer-master">
            
                <div className="dealer-head">
                   2. Pickup Location
                </div>
                <div className="dealer-body">
                     
                <div className="form-group">
                         <div className="map-img">
                           
                         <MapWithAMarker
           currentLocation={{lat:this.state.fields.currentLatLng.value.lat,lng: this.state.fields.currentLatLng.value.lng}}
           sendingDrabbleLocation={(latlng)=>this.getAddressbyLatLng({lat:latlng.lat(),lng:latlng.lng()})}
         //  currentLocation={this.state.currentLatLng}
     containerElement={<div style={{ height: `400px`,borderRadius:20 }} />}
     mapElement={<div style={{ height: `100%`,borderRadius:20 }} />}
    >
     
      </MapWithAMarker> 
        

       
                     </div>
                      </div>
                       
                      
                      <div className="form-group position-relative">
                         <label>
                         Address
                         </label>

                         <textarea row="5" className="form-control" name="address" value={this.state.fields.address.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  maxLength="200"></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['address'].error&&this.state.fields['address'].error}</span>
                         {this.state.predictions&&this.state.predictions.length>0&&
                         
                         this.printAddress()
                        }
                     </div>
                     <div className="form-group">
                      

                        <div class="form-inline position_set">
                          <label>Type of Address &nbsp; * </label>
                          {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}
                          
                          <Select value={[parseInt(this.state.fields.address_type.value)]}  onChange={(value) => this.checkvalidations(value,'address_type')} name="address_type" >
                          {address_type&&address_type.length>0&&address_type.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid reason_dropdown-img"/>
                            <span className="error_msg_adddealer">{this.state.fields['address_type'].error&&this.state.fields['address_type'].error}</span> 
                           
                         
                         
                        {/* </div> */}
                          {/* <i
                          nput type="text" className="form-control"/>  */}
                        </div>                     
                     </div>

                     
                </div>

                
             </div>
             <button className={`btn btn-white-blue prev-button `}  onClick={()=>this.goBack()}>  <img src={Images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button className="btn btn-dark-blue next-button" onClick={()=>this.gotoslotservice()}>Next  <img src={Images.next} className="img-fluid arrow"/></button>
             </div>

            </div>
        )





        
    }
    componentWillMount(){
      
      // this.loadaddresstypeDropdown();
    }
    componentDidMount(){
      console.log("didmount",this.props);
      if(this.props.pickupLocation){
          this.setState({fields:this.props.pickupLocation},function(){
            this.loadaddresstypeDropdown(this.state.fields.address_type.value);
          });
      }else{
        this.getCurrentPosition();
        this.loadaddresstypeDropdown();
      }
 
    }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(PickUpLocation)
