import React, { Component } from 'react';
// import './style.css';
import images from '../../../helpers/images';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,

    useRouteMatch,
    Switch,
    Redirect
} from "react-router-dom";
import Image from '../../../helpers/images';
import { Table, Tag, Pagination, Select } from 'antd';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from 'antd';
import moment from 'moment';
var mishapsHeader = [{ 'id': 1, 'Serial_No': "Serial No.", "customer_details": "Customer Name & Booking ID", "vechicle_number": "Vehicle Number", "invoiceAmount": "Invoice Amount", "Fee": "Transit Fee", "overallfee": "Overall Fee", "gst": "GST", "payout": "Payout", "released": "Released" }]
class SettlementDetail extends Component {

    constructor(props) {


        super(props);

        this.state = {
            mishapsHeader: mishapsHeader,
            mishapsList: [],
            currentPage: 1,
            pageSize: 10,
            pageLimit: 10,
            loadstatus: false,
            loading: true,
        }
    }
    goBack = () => {
        this.props.history.push('/payments/settlement_advice');
    }

    loadBookingDetails = (data) => {
        this.setState({ loading: true })
        const token = localStorage['accesstoken'];
        var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, id: this.props.match.params.id };

        apiservices.getSettlementAdviceDetails(obj, token)
            .then((response) => {
                this.setState({ loading: false });
                if (!response.error) {
                    var limit = response.count;
                    var total_items = this.state.pageSize;
                    var calc = Math.ceil(limit / total_items) * total_items;
                    console.log("response",response)
                    this.setState({ mishapsList: response.response, numbercount: calc, loadStatus: true })
                    // this.setState({
                    //     mishapsList: response.response
                    // })
                } else {
                    this.setState({ responseMsg: response.message })
                }

            })
    }
componentWillMount(){
    this.loadBookingDetails();
}
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
            return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls" />Prev</a>;
        }
        if (type === 'next') {
            return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw" /></a>;
        }
        return originalElement;
    }
    onchangePagination = (page, pageSize) => {
        this.setState({ currentPage: page }, function () {
            this.loadBookingDetails();
        });

        console.log("page size", pageSize);
    }
    showChanger = (current, size) => {
        this.setState({ pageSize: size })
    }
    render() {
        console.log("this.props.match.params.id :: " + this.props.match.params.id);
        const offset = moment().utcOffset();
        const { customerDetails, bookingDetails, svcDetails, driverDetails, paymentDetails, photoDetails } = this.state
        return (

            <div className="">

                <div className="back-btns">
                    <span className="back-button" onClick={() => this.goBack()}><img src={images.FadedArrowImg} className="img-flud" />Back to the Lists</span>                                                                                                                                                                                                                                                                                                                                                            </div>

                
                <div>
                    
                    <div className="table-list customer_table">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.mishapsHeader && this.state.mishapsHeader.map((item)=> {
                                        return(
                                            <tr>     
                                               <th>{item.Serial_No}</th>
                                        <th className="text-left">{item.customer_details}</th>
                                        <th>{item.vechicle_number}</th>
                                        <th>{item.invoiceAmount}</th>
                                        <th>{item.Fee}</th>
                                        <th>{item.overallfee}</th>
                                        <th>{item.gst}</th>
                                        {/* <th>{item.payout}</th> */}
                                        <th>{item.released}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                               <tbody>

                                    {this.state.mishapsList &&this.state.mishapsList.length>0&&this.state.mishapsList.map((item,index) => {

                                        return(
                                            <tr>
                                                <td>{this.state.currentPage - 1}{index + 1}</td>
                                        <td className="text-left"><div style={{ marginTop: 10 }}>
                                            {item.customer_name}
                                        </div>
                                            <div style={{ marginTop: 14, color: "#620B0B" }}>
                                                {item.booking_id}
                                            </div></td>
                                        <td>{item.vehicle_number}</td>
                                        <td>
                                            {item.invoice_amount}
                                            {/* <div style={{ marginTop: 14, color: "#620B0B" }}>
                                                {item.transactionAmount}
                                            </div> */}
                                            </td>
                                        <td>{item.transit_amount}</td>
                                        <td>{item.invoice_amount}</td>
                                        <td>{item.gst_amount}</td>
                                        {/* <td>{item.payout}</td> */}
                                        <td>{item.invoice_amount}</td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody> 
                                
                            </table>
                            {
                              this.state.settlemntList&&this.state.settlemntList.length==0&&
                              <div>No Records Found </div>
                            }
                           
                        </div>
                     </div>
                    {this.state.mishapsList.length == 0 && !this.state.loading &&
                        <div className="no_records_found" >No Records Found</div>
                    }
                    {this.state.loading == true &&
                        <CircularProgress />
                    }


                    {this.state.mishapsList.length > 0 &&
                        <Pagination className="customer_pagination" total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
                            pageSize={this.state.pageSize} itemRender={this.itemRender}
                        />
                    }
                </div>
                <div>

                </div>
            </div>
        )


    }
}
export default SettlementDetail;