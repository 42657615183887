import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
// import ActiveDriverTable from "../ActiveDriverTable";
// import BookingListActive from '../BookingListActive';
// import BookingListPause from '../BookingListPause';
// import BookingListUpComing from '../BookingListUpComing';
// import BookingListCompleted from '../BookingListCompleted';
// import BookingListCancelled from '../BookingListCancelled';
import BookingDetails from  '../../Pages/BookingDetails';
import BookingList from '../../Pages/BookingList';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class BookingListMain extends Component {

    constructor(props) {
        super(props);
        this.state = {

            pilotMaster : true,
            addDriver : false
        }
    }

    AddDriver = () => {
  console.log("adddriver clicj")
        this.setState({pilotMaster : false});
         this.setState({addDriver  : true})
    }
    render() {
        return (
            <div>
                {this.state.pilotMaster &&  
                
                <div class="add-booking-menu">
                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Row>
                        
                            <Col sm={12}>
                               
                                <Switch>
                 {/* <Route  path={`${this.props.match.path}/booking_details`} render={props => (<BookingDetails {...props} />)} /> */}
                <Route  path={`${this.props.match.path}/bokinglist_main`} render={props => (<BookingList {...props} parentProps={this.props} />)} />
                <Route   path={`${this.props.match.path}/booking_details`} render={props => (<BookingDetails {...props}/>)} />
                
                
              </Switch>
                            </Col>
                        </Row>
                    {/* </Tab.Container> */}
                </div>
                }


               
            </div>

            
        )

    }
    componentDidMount() {
        this.props.receivePageTitle && this.props.receivePageTitle('Drivers');
    }

}


export default BookingListMain;