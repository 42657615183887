import React, { Component } from 'react';
import images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import 'antd/dist/antd.css';
import ValidationLibrary from '../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, Tag, Space, Select, Pagination, Tooltip, Switch } from 'antd';
import './styles.css';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Redirect,
} from 'react-router-dom';
import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
var ActiverHeader = [
  {
    id: 'ID',
    driver_id: 'Pilot ID',
    driver_details: 'Pilot Details',
    age: 'Age',
    experience: 'Experience',
    shift: 'Shift',
    swipe_machine_id: 'Swipe Machine ID',
    account_details: 'Account Details',
    attendance: 'Attendance',
    city: 'City',
    zone: 'Zone',
    state: 'State',
    edit: 'Edit',
    delete: 'Delete',
  },
];
const MapWithAMarkers = withGoogleMap((props) => {
  console.log('newprops', props);
  const location = {
    lat: props.currentLocation.lat,
    lng: props.currentLocation.lng,
  };
  console.log('location', location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        // defaultCenter={props.currentLocation}
        // center={props.currentLocation}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker position={location} />
      </GoogleMap>
    </div>
  );
});
class ActiveTableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempdata: [],
      deleteid: null,
      ActiverHeader: ActiverHeader,
      ActiveItemArray: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
      numbercount: 0,
      latitude: 0,
      longitude: 0,
      activeDriverList: true,
      openMap: false,
      driverEdit: false,
      name_search: null,
      city_search: '',
      zone: '',
      deleteOpen: false,
      reason_type: null,
      available_status: null,
      fields: {
        reason_type: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        reasons: { validations: [], error: null, value: '' },
        // age:{validations:[{name:'required'},{name:'allowNumaricOnly'}],error:null,value:''},
      },
    };
  }

  driverDetials = () => {
    console.log('clicked');
    this.setState({ driverEdit: true });
    this.setState({ activeDriverList: false });
  };

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img alt='' src={images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next{' '}
          <img
            alt=''
            src={images.arrow_forward}
            className='arrow_cls nextarw'
          />
        </a>
      );
    }
    return originalElement;
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.ActiveDriverList();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.ActiveDriverList();
    });
  };

  deleteOpen = (data) => {
    var fields = this.state.fields;
    fields['reasons'].value = '';
    fields['reasons'].error = '';
    console.log('data delete', data);
    this.loadReasonDropdown();
    this.setState({
      deleteOpen: true,
      deleteid: data,
      fields,
      responseMsg: '',
    });
  };
  deleteClose = () => {
    this.setState({ deleteOpen: false });
  };
  componentWillMount() {
    this.ActiveDriverList();
    this.loadReasonDropdown();
    console.log('component will mount');
  }
  ActiveDriverList = () => {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
      name: this.state.name_search,
      city: this.state.city_search,
      zone: this.state.zone,
      available_status:
        this.state.available_status == null
          ? ''
          : this.state.available_status == true
          ? 1
          : 0,
    };
    console.log('actve', obj);
    // ()
    apiservices.activedriverlist(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({
          ActiveItemArray: response.response,
          numbercount: calc,
        });
        console.log('acive reponse', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };

  openDetails = (data) => {
    this.props.history.push('/pilots/pilot_master/driverdetails/' + data.id);
    // this.props.detailsClick(data.id);
  };
  cityFilter = (e) => {
    var value = e.target.value;

    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.ActiveDriverList();
    });
  };
  loadReasonDropdown = () => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.drverreasondropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ reason_type: response.response });
        var fields = this.state.fields;

        fields['reason_type'].value =
          response.response.length > 0 ? response.response[0].id : '';
        this.setState({ fields });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    if (key == 'address') {
      this.getlocationbyAddress(value);
    }
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  DisableDriver = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      const token = localStorage['accesstoken'];
      var obj = {
        id: this.state.deleteid,
        active_status: this.state.fields.reason_type.value,
        reason: this.state.fields.reasons.value,
      };
      console.log('disable obj', obj);
      apiservices.deleteDriver(obj, token).then((response) => {
        var self = this;
        if (!response.error) {
          setTimeout(() => {
            self.ActiveDriverList();
            self.setState({ deleteOpen: false });
          }, 3000);

          this.setState({ responseMsg: response.message });
        } else {
          this.setState({ responseMsg: response.message });
        }
      });
    }
    this.setState({ fields });
  };
  onPasteFunc = (e) => {
    e.preventDefault();
  };
  openMap = (data) => {
    console.log('data', data);
    this.setState({ openMap: true }, function () {
      this.setState({ latitude: data.latitude, longitude: data.longitude });
    });
    console.log('map data', data);
  };
  toggleCheck = (data) => {
    this.setState({ available_status: data }, function () {
      this.ActiveDriverList();
    });
    console.log('toggle', data);
  };
  render() {
    const { reason_type } = this.state;

    return (
      <div>
        {this.state.activeDriverList && (
          <div>
            <div className='search-header driver-search'>
              <div className='row'>
                <div className='col-md-4'>
                  <div class='form-group search-list payment-search'>
                    <input
                      type='text'
                      placeholder='Search Name'
                      className='form-control'
                      name='name_search'
                      onChange={(e) => this.cityFilter(e)}
                    />
                    <img src={images.green_search} className='img-fluid' />
                  </div>
                </div>
                <div className='col-md-3'>
                  {/* <div className="payment-dropdown"> <span className="payment">city / Zone</span>  */}
                  <div className='city_search' style={{ display: 'flex' }}>
                    <label>City:</label>
                    <input
                      type='text'
                      className='form-control citysearchbox'
                      name='city_search'
                      onChange={(e) => this.cityFilter(e)}
                    />
                  </div>

                  {/* </div> */}
                </div>
                <div className='col-md-3'>
                  <div className='city_search' style={{ display: 'flex' }}>
                    <label>Zone:</label>
                    <input
                      type='text'
                      className='form-control citysearchbox'
                      name='zone'
                      onChange={(e) => this.cityFilter(e)}
                    />
                  </div>
                </div>
                <div className='col-md-2'>
                  <div className='city_search' style={{ display: 'flex' }}>
                    <label>State:</label>
                    <div className='switch_div'>
                      <Switch onClick={(data) => this.toggleCheck(data)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class='driverinfodiv'>
              <div className='driverActiveTable'>
                <table class='table table-hover'>
                  <thead>
                    {this.state.ActiverHeader &&
                      this.state.ActiverHeader.map((item) => {
                        return (
                          <tr>
                            <th>{item.driver_id}</th>
                            <th className='text-left'>{item.driver_details}</th>
                            <th>{item.city}</th>
                            <th>{item.zone}</th>
                            <th>{item.experience}</th>
                            <th>{item.shift}</th>
                            <th>{item.swipe_machine_id}</th>
                            <th>{item.account_details}</th>
                            <th>{item.attendance}</th>
                            <th>{item.state}</th>
                            <th>{item.edit}</th>
                            <th>{item.delete}</th>
                          </tr>
                        );
                      })}
                  </thead>
                  <tbody>
                    {this.state.ActiveItemArray &&
                      this.state.ActiveItemArray.map((item) => {
                        return (
                          <tr>
                            <td onClick={() => this.openDetails(item)}>
                              ID{item.id}
                            </td>
                            <td style={{ textAlign: 'left' }}>
                              <div className='driverImagediv'>
                                <img
                                  src={
                                    item.image_url == ''
                                      ? images.default_image
                                      : item.image_url
                                  }
                                  className='driverImage'
                                />
                              </div>
                              <div className='driverImagename'>
                                {item.first_name} {item.last_name}
                              </div>
                              <div className='driverImageNumber'>
                                {item.mobile_no}
                              </div>
                              <div
                                className='view-map'
                                onClick={() => this.openMap(item)}>
                                View on Google Map
                              </div>
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {' '}
                              {item.city}
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {' '}
                              {item.zone}
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {item.experience} Years
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {item.shift}
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {item.swipe_machine_number}
                            </td>
                            <td
                              style={{ textAlign: 'left' }}
                              onClick={() => this.openDetails(item)}>
                              <div style={{ color: '#8AABB6', fontSize: 13 }}>
                                Account No.
                              </div>
                              <div style={{ marginTop: 5 }}>
                                {item.account_number}
                              </div>
                              <div
                                style={{
                                  color: '#8AABB6',
                                  fontSize: 13,
                                  marginTop: 10,
                                }}>
                                IFSC Code:
                              </div>
                              <div style={{ marginTop: 5 }}>
                                {item.ifsc_code}
                              </div>
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {item.attendance_status}
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              {item.available_status}
                            </td>
                            <td onClick={() => this.openDetails(item)}>
                              <Tooltip
                                placement='top'
                                title='Edit Driver'
                                color='white'
                                key='#0B485A'>
                                <img
                                  alt=''
                                  src={images.edit_icon}
                                  style={{ width: 38 }}
                                />
                              </Tooltip>
                            </td>
                            <td>
                              <Tooltip
                                placement='top'
                                title='Disable Driver'
                                color='white'
                                key='#0B485A'>
                                <img
                                  alt=''
                                  src={images.delete_icon}
                                  style={{ width: 38 }}
                                  onClick={() => this.deleteOpen(item.id)}
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>

                {this.state.ActiveItemArray &&
                  this.state.ActiveItemArray.length == 0 && (
                    <div className='no_records_found'>No Records Found</div>
                  )}
              </div>
            </div>

            {this.state.loading == true && <CircularProgress />}
            {/* // {this.state.ActiveItemArray.length>this.state.pageSize&&  */}
            <Pagination
              total={this.state.numbercount}
              current={this.state.currentPage}
              onChange={(page, pageSize) =>
                this.onchangePagination(page, pageSize)
              }
              pageSize={this.state.pageSize}
              pageSizeOptions={['5', '10', '15']}
              onShowSizeChange={(current, size) =>
                this.showChanger(current, size)
              }
              showSizeChanger
              itemRender={this.itemRender}
            />
            {/* }  */}
          </div>
        )}

        <Dialog open={this.state.deleteOpen} className='shopbox'>
          <div className='modalclosecls'>
            <img
              src={images.popup_clseicon}
              style={{ width: '15px', cursor: 'pointer' }}
              onClick={() => this.deleteClose()}
            />
          </div>
          <div className='modalcntntcls'>
            <div className='delete_text'>Delete Pilot</div>
            <div>
              <div className='form-group'>
                <div class='form-inline position-relative reason_text'>
                  <label>Select Reason</label>

                  <Select
                    value={[this.state.fields.reason_type.value]}
                    name='reason_type'
                    onChange={(value) =>
                      this.checkvalidations(value, 'reason_type')
                    }>
                    {reason_type &&
                      reason_type.length > 0 &&
                      reason_type.map((item) => {
                        return <Option value={item.id}>{item.name}</Option>;
                      })}
                  </Select>
                  <img
                    src={images.dropdown}
                    className='img-fluid reason_dropdown-img'
                  />

                  <span className='error_msg_adddealer'>
                    {this.state.fields['reason_type'].error &&
                      this.state.fields['reason_type'].error}
                  </span>
                </div>
              </div>
              {/* <div className="form-group age_label">
                       
                          <label>Age</label>
                          <input type="text" className="form-control age_box" name="age" value={this.state.fields.age.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['age'].error&&this.state.fields['age'].error}</span>
                        
                       </div> */}
              <div className='form-group reason_box'>
                <label>Notes</label>

                <textarea
                  row='5'
                  col='4'
                  maxLength='250'
                  name='reasons'
                  className='form-control reason_box'
                  value={this.state.fields.reasons.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }></textarea>
              </div>
              {this.state.responseMsg && this.state.responseMsg ? (
                <div className='failure_msg'>
                  {this.state.responseMsg && this.state.responseMsg}
                </div>
              ) : (
                ''
              )}
              {this.state.responseMsg && !this.state.responseMsg ? (
                <div className='success_msg'>
                  {this.state.responseMsg && this.state.responseMsg}
                </div>
              ) : (
                ''
              )}
              <div className='btn_main'>
                <button
                  className='delete_submit'
                  onClick={() => this.DisableDriver()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={this.state.openMap} className='mapbox'>
          <div className='modalclosecls'>
            <img
              src={images.popup_clseicon}
              style={{ width: '15px', cursor: 'pointer' }}
              onClick={() => this.setState({ openMap: false })}
            />
          </div>
          <div className='modalcntntcls' style={{ width: '100%', padding: 10 }}>
            {this.state.latitude != 0 && this.state.longitude != 0 && (
              <MapWithAMarkers
                currentLocation={{
                  lat: parseFloat(this.state.latitude),
                  lng: parseFloat(this.state.longitude),
                }}
                //  currentLocation={this.state.currentLatLng}
                containerElement={
                  <div
                    style={{ borderRadius: 2, height: '550px', width: '100%' }}
                  />
                }
                mapElement={<div style={{ height: `100%`, width: '100%' }} />}
              />
            )}
          </div>
        </Dialog>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas',
})(ActiveTableList);
