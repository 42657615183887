import React, { Component } from 'react';
import './style.css';
import images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import { Table, Tag, Pagination, Select, Tooltip } from 'antd';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { CSVLink, CSVDownload } from "react-csv";
import
{
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import
{
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import moment from 'moment';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DatePicker } from 'antd';
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
function handleChange(value)
{
  console.log(`selected ${value}`);
}
const MapWithAMarkers = withGoogleMap((props) =>
{
  console.log("newprops", props);
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  console.log("location", location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        // defaultCenter={props.currentLocation}
        // center={props.currentLocation}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
        />
      </GoogleMap>
    </div>
  )
}

);
const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];
const { RangePicker } = DatePicker;

var activepickupHeader = [{ 'id': 1, 'booking_id': 'ID', 'city_zone': 'City', 'booking_info': 'Booking Info', 'customer_details': 'Customer Details', 'service_center_details': 'Service Centre Details', 'pilot_details': 'Pilot Details', 'reason': 'Reason', 'cancelled': 'Cancelled By' }];
class BookingListCancelled extends Component
{

  constructor(props)
  {

    super(props);

    this.state = {
      activepickupHeader: activepickupHeader,

      cancelledBookingList: [],

      currentPage: 1,
      pageSize: 10,
      latitude: 0,
      longitude: 0,
      customer_name: null,
      fromDate: null,
      toDate: null,
      pickup_fields: {
        svc_center: { validations: [], error: null, value: '' },
      },


    }
  }

  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }
  CancelBookingList = () =>
  {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, svc_center: this.state.pickup_fields.svc_center.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    console.log("actve", obj);
    apiservices.bookinglistcancel(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          console.log("calc", calc);
          this.setState({ cancelledBookingList: response.response, numbercount: calc })
          console.log("cancelled reponse", response);
        }
        else
        {
          this.setState({ error_msg: response.message })
        }
      })


  }
  pauseBooking = (data, value) =>
  {
    this.setState({ popupDisplay: data });
    console.log("popup", value);
    if (data == 'map')
    {
      this.setState({ latitude: value.driver_latitude, longitude: value.driver_longitude }, function ()
      {
        console.log("latitude", this.state.latitude)
      })
    }

    this.setState({ openactiveBooking: true });
  }
  componentWillMount()
  {
    this.CancelBookingList();
    this.loadSeriveCenter();
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.CancelBookingList();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.CancelBookingList();
    });
  }
  cityFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function ()
    {
      this.CancelBookingList();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.CancelBookingList();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.CancelBookingList();
    })
  }

  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['svc_center'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.CancelBookingList();
    })
  }
  makecall = (bkid, assignid, mobilenumber) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: bkid, assign_id: assignid, mobile_no: mobilenumber };
    console.log("obj", obj);
    apiservices.makeCall(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.response.message }, function ()
          {
            this.CancelBookingList();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.response.message }, function ()
          {
            this.CancelBookingList();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  render()
  {
    const { svc_center } = this.state;
    console.log("cancelled latitude", this.state.latitude);
    console.log("cancelled longitude", this.state.longitude);
    return (
      <div>
        <CSVLink data={this.state.cancelledBookingList} target="_blank" ><div className="generate-btn"><button className="btn btn-linear-color generate-report">Generate Report</button></div></CSVLink>
        <div className="search-header booking-mishaps booking-active">
          <div className="row">
            <div className="col-md-3">
              <div class="form-group search-list payment-search">
                <input type="text" placeholder="Search for Customer or Vehicle" className="form-control" name="customer_name" onChange={(e) => this.cityFilter(e)} />
                <img src={images.green_search} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                <Select value={[this.state.pickup_fields.svc_center.value ? parseInt(this.state.pickup_fields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                  <Option value="">All</Option>
                  {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                  {
                    return (


                      <Option value={item.id}>{item.centre_name}</Option>

                    )
                  })}

                </Select>
              </div>
              <div className="service_listing booking-list payments-list">


                <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.error_call ?
          <div className="failure_msg">{this.state.error_call}</div> : ''
        }

        {this.state.success_call ?
          <div className="success_msg">{this.state.success_call}</div> : ''
        }
        <div className="table-list">


          <div class="table-responsive">


            <table class="table table-borderless">
              <thead>
                {this.state.activepickupHeader && this.state.activepickupHeader.map((item) =>
                {
                  return (
                    <tr>
                      <th>{item.booking_id}</th>
                      <th>{item.city_zone}</th>
                      <th className="text-left">{item.booking_info}</th>
                      <th>Slot Time</th>
                      <th className="customer_thead text-left">{item.customer_details}</th>
                      <th>Customer Name</th>
                      <th>Vehicle Number</th>
                      <th className="service_thead">{item.service_center_details}</th>
                      <th>{item.pilot_details}</th>
                      <th>{item.reason}</th>
                      <th>{item.cancelled}</th>
                    </tr>
                  )
                })}
              </thead>
              <tbody>

                {this.state.cancelledBookingList && this.state.cancelledBookingList.length > 0 && this.state.cancelledBookingList.map((item, index) =>
                {

                  return (
                    <tr>
                      <td >ID{item.id}</td>
                      <td>{item.city}</td>
                      <td className="text-left">{moment(item.slot_day).format('DD/MM/YY')}</td>
                      <td>{item.slot_time}</td>
                      <td className="text-left">{item.vehicle_number}<br />
                        <div className="pick_address">{item.pickup_address}</div><br />
                        <div className="pick_address">{item.customer_mobile}</div>
                        <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.customer_mobile)} /></Tooltip>

                      </td>
                      <td>{item.customer_name}</td>
                      <td>{item.vehicle_number}</td>
                      <td className="service-td"><span className="service-listing">{item.centre_name}</span><br />
                        <span className="service-listing">{item.service_advisor_name}</span>
                        {item.service_advisor_mobile_no != null ?
                          <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.service_advisor_mobile_no)} /></Tooltip> : ''}<br />
                        <span className="service-listing">{item.cre_name}</span>
                        {item.cre_mobile_no != null ?
                          <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.cre_mobile_no)} /></Tooltip> : ''}

                      </td>
                      <td>
                        <div className="pilot-default">
                          <img src={item.driver_image_url ? item.driver_image_url : images.default_image} style={{ width: 61 }} />
                          {item.driver_mobile_no != null ?
                            <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} className="call-defaulter" style={{ width: 38, marginLeft: 59, marginTop: -23 }} onClick={() => this.makecall(item.id, item.assign_id, item.driver_mobile_no)} /></Tooltip> : ''}
                        </div>
                        {item.driver_first_name} {item.driver_last_name} <br />
                        {item.driver_mobile_no}
                        <div className="view-map" onClick={() => this.pauseBooking('map', item)}>View on Google Map</div></td>

                      <td>{item.cancel_reason}</td>
                      <td>{item.cancel_by}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {this.state.cancelledBookingList && this.state.cancelledBookingList.length == 0 &&
              <div className="no_records_found">No Records Found</div>
            }

          </div>

        </div>


        <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
        />
        <Dialog open={this.state.openactiveBooking} className={`booking_pickup ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.setState({ openactiveBooking: false })} /></div>

          {this.state.popupDisplay == 'map' &&
            <div className="modalcntntcls" style={{ width: '100%', padding: 10 }}>

              {this.state.latitude != 0 && this.state.longitude != 0 &&
                <MapWithAMarkers
                  currentLocation={{ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) }}
                  //  currentLocation={this.state.currentLatLng}
                  containerElement={<div style={{ borderRadius: 2, height: '550px', width: '100%' }} />}
                  mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                />
              }


            </div>
          }
        </Dialog>
      </div>

    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(BookingListCancelled)
