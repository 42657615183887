import React, { Component } from "react";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import AddNewSvcUser from "../AddNewSvcUser";
import SvcUserList from "../SvcUserList";
import SvcDisabledUser from "../SvcDisabledUser";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import "./styles.css";
export default class SvcUser extends Component {
    constructor(props) {
        console.log("svc user props",props);
        super(props);
        this.state = {
            receiveedit:null,
            tabKey:'first',
            editid:null,
            svc_id:null
        }
      }
      receiveditdata=(data)=>{
          console.log("receive edit",data);
          this.setState({receiveedit:data});
          console.log("edit id",this.state.receiveedit)
        //   console.log("receive edit state",this.state.receiveedit);
      }
      componentWillReceiveProps(props){
          console.log("svc user will recieve",props);
        //   var editid=props.history.location.state.editid;
        //   this.setState({editid:editid})
        //   console.log("will receive svc user",editid);
      }
    render() {
        return (
        <div class = "svc_user_details">
           
            
            {/* <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.tabKey} mountOnEnter="true" forceRenderTabPanel={true}>  */}
                <Row>
                    <Col sm={12}>
                    <Nav variant="pills"  className="flex-row svcuser" selectedIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
           <Nav.Item>
    <NavLink parentClass="parent_active" to={`${this.props.match.path}/add_user/0/${this.state.svc_id}`} activeClassName="active_user">Add User</NavLink>
          </Nav.Item>
          <Nav.Item>
    <NavLink  to={`${this.props.match.path}/user_list/${this.state.svc_id}`} activeClassName="active_user">User List</NavLink>
          </Nav.Item>
          <Nav.Item>
    <NavLink  to={`${this.props.match.path}/disable_user/${this.state.svc_id}`} activeClassName="active_user">Disabled User</NavLink>
          </Nav.Item>
          </Nav>
                    </Col>
                    <Col sm={12}>
                    <Switch>
            <Route exact  path={`${this.props.match.path}/add_user/:editid/:svc_id`}  render={props=> (<AddNewSvcUser sendsvcid={(svid)=>{this.setState({svc_id:svid});this.props.sendSVCID(svid);}} {...props} receiveedit={this.state.editid}/>)} />
            <Route exact  path={`${this.props.match.path}/user_list/:svc_id`} render={props=> (<SvcUserList  sendsvcid={(svid)=>{this.setState({svc_id:svid});this.props.sendSVCID(svid);}} {...props} parentProps={this.props} action={this.props.action} sendeditdata={(data)=>this.receiveditdata(data)}/>)} />
            <Route  exact path={`${this.props.match.path}/disable_user/:svc_id`} render={props=> (<SvcDisabledUser {...props} parentProps={this.props} sendsvcid={(svid)=>{this.setState({svc_id:svid});this.props.sendSVCID(svid);}}/>)} />
            </Switch>
                    </Col>
                </Row>
            {/* </Tab.Container> */}
        </div>);
    }
}