import React, {Component} from 'react';
import './style.css';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import images from '../../../helpers/images';
import moment from 'moment';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';


var ticketsHeader = [{'id' : 1, 'date' : 'Date', 'time' : 'Time', 'ambassador_name' : 'Ambassador Name', 'action_by' : 'Action By','ticket':'Ticket'}];
class BookingTickets extends Component 
{

    constructor(props){

        super(props);

        this.state = {
          ticketsHeader : ticketsHeader,
          ticketsList : [],
          loading:false
        }
    }
    loadBookingTickets=(data)=>{
      this.setState({loading:true})
      const token=localStorage['accesstoken'];
      var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
      console.log("booking svc details obj", obj);   
      apiservices.listbookingtickets(obj,token)
            .then((response)=>{
              console.log("ticket list",response)
                this.setState({loading:false});
                if(!response.error){
                    this.setState({ticketsList:response.response})
                }else{
                  this.setState({responseMsg:response.message})
                }
     
              })
  }
  componentWillMount(){
    this.loadBookingTickets();
  }
render(){
  const offset = moment().utcOffset();
   return(
       

   <div>
               <div className="booking_pickup">
                       
               {this.state.loading==true&&
    <CircularProgress />}
                      <div className="graph-table-listing">
                      <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        
            {this.state.ticketsHeader && this.state.ticketsHeader.map((item)=> {
          return(
            <tr>
            <th>{item.date}</th>
          <th>{item.time}</th>
          <th>{item.ambassador_name}</th>
          <th>{item.action_by}</th>
          <th>{item.ticket}</th>
          </tr>
          )
            })}
           
        
      </thead>
      <tbody>
      {this.state.ticketsList && this.state.ticketsList.map((item)=> {
          return(
            <tr>
            <td>{moment(item.created_at).format('DD/MM/YY')}</td>
            <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
           <td>{item.first_name} {item.last_name}</td>
           <td>{item.action_by}</td>
           <td>{item.ticket}</td>
          </tr>
          )
            })}
      </tbody>
    </table>
    {this.state.ticketsList&&this.state.ticketsList.length==0&&
    <div>No Tickets</div>}
  </div>
       

      

               </div>
</div>

</div>

 )
    }
    componentDidMount(){
      this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
    }
}
export default BookingTickets;