import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import Images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
import ActiveDriverTable from "../ActiveDriverTable";
import DisabledDriverTable from "../DisabledDriverTable";
import PilotDefaulter from '../PilotDefaulter';
import PilotResigned from '../PilotResigned';
import PilotHistory from '../PilotHistory';
import PilotInRequest from '../PilotInRequest';
import AddDriver from '../AddDriver';
import PilotDetails from '../PilotDetails';
import DriverDetails from "../DriverDetails";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class PilotMaster extends Component {
constructor(props){
    super(props);
    this.state={
        driverid:null
    }
}
   
    recceivedriverid =(data)=>{
        this.setState({driverid:data});
        console.log("receive driver id",data);
    }


    render() {
        console.log("parent props",this.props.parentProps.match.path)
        return (
            <div>
                  
            <Switch>
            <Route  path={`${this.props.match.path}/pilot_details`} render={props => (<PilotDetails {...props} parentProps={this.props} />)} />
                <Route  path={`${this.props.match.path}/add_driver`} render={props => (<AddDriver {...props} />)} />
                <Route  path={`${this.props.match.path}/driverdetails`} render={props=>(<DriverDetails parentProps={this.props} sendid={(data)=>this.recceivedriverid(data)}  {...props} driverid={this.state.driverid}  />)}/>
               </Switch>
                

                
            </div>

            
        )

    }
    componentDidMount() {
        this.props.receivePageTitle && this.props.receivePageTitle('Drivers');
    }

}


export default PilotMaster; 