import React, {Component} from 'react';
import './style.css';
import images from '../../helpers/images';
import { Table, Tag, Pagination, Select} from 'antd';
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';
import moment from 'moment';


const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var statementHeader = [{'id' : 1,  'description' : 'Description', 'credit_amount': 'Credit Amount', 'debit_amount' : 'Debit Amount', 'balance_amount' : 'Balance Amount'}];


class Account_Statements extends Component{

    constructor(props){

        super(props);

        this.state = {
            statementHeader : statementHeader,

            customerPaymentList : [],
             
            currentPage : 1,
            pageSize:10,
            customer_name:null,
            fromDate:null,
            toDate:null,


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadCustomerPaymentSVC=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center_id:this.props.match.params.svc_id,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("customer payment", obj);     
        apiservices.getSvcAccountStatementById(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({customerPaymentList:response.response, numbercount:calc})
                      console.log("acive pickup reponse", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
      onchangePagination=(page,pageSize)=>{
        console.log("page",page);
        this.setState({currentPage:page});
        
        console.log("page size",pageSize);
      }
      showChanger=(current,size)=>{
        this.setState({pageSize:size})
      }
      componentWillMount(){
        var svc_id=this.props.match.params.svc_id;
        
          this.props.sendsvcid(svc_id);
          this.loadCustomerPaymentSVC();
          this.loadPaymentType();
       
    }
    
      checkdriverfilter =(data)=>{
        var pickup_fields=this.state.pickup_fields;
        pickup_fields['payment_type'].value=data;
        this.setState({pickup_fields},function(){
          this.loadCustomerPaymentSVC();
        })
      }
      checkfiltervalidations =(data)=>{
        console.log("priority",data);
       
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadCustomerPaymentSVC();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadCustomerPaymentSVC();
        })
      }
      loadPaymentType=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.listAllPaymentTypeDrop(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    this.setState({payment_type:response.response})
                    // if(!value){
                    // ticketfields['ticket_priority'].value=response.response.length>0?response.response.id:'';            
                    // this.setState({ticketfields})
                    // }
                    console.log("login response",response);
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadCustomerPaymentSVC();
        });
      }
render(){
    const offset = moment().utcOffset();
    const {driver_list,slotime,service_advisor,svc_center,payment_type} =this.state;
    return(
        <div>
              <div className="container">
              <div className="search-header booking-mishaps booking-active" style={{marginTop:0}}>
                         <div className="row">
                             <div className="col-md-3">
                                  
                             </div>
                             <div className="col-md-9">
                             
                             
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from" style={{left:'33px'}}>From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list customer_table">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.statementHeader && this.state.statementHeader.map((item)=> {
                return(
                    <tr>                         <th>Date</th>
                                                <th>{item.description}</th>
                                                <th>{item.credit_amount}</th>
                                                <th>{item.debit_amount}</th>
                                                <th>{item.balance_amount}</th>
                    </tr>
                )
            })}
        </thead>
        <tbody>

        {this.state.customerPaymentList&&this.state.customerPaymentList.length>0&&this.state.customerPaymentList.map((item,index) => {

                return(
                    <tr>
                       <td>{moment(item.created_at).format('DD/MM/YY')} </td>
                       
                        <td style={{width:300,wordBreak:'break-word'}}>  {item.purpose}</td>
                        <td>{item.credit_amount}</td>
                        <td>{item.debit_amount}</td>
                        <td>{item.total_amount}</td>
                        
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.customerPaymentList&&this.state.customerPaymentList.length==0&&
    <div style={{paddingBottom:15}}>No Records Found </div>
    }
    

</div>
</div>
<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />  


                     
             </div>
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default Account_Statements;