import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
var performanceHeader = [{'id' : 1,  'name_id' : 'Name & ID', 'city': 'City', 'total_tasks' : 'Total Tasks', 'on_time' : 'On Time',  'late' : 'Late', 'early_pickup' : 'Early Pickup', 'late_drop' : 'Late Drop', 'missed' : 'Missed', 'mishaps' : 'Mishaps', 'complaints' : 'Complaints', 'attendance' : 'Attendance %'}];
class DriverPerformanceDetails extends Component{

    constructor(props){

        super(props);

        this.state = {
            performanceHeader : performanceHeader,

            PerformanceList : [],
             
            currentPage : 1,
            pageSize:10


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadDriverOverspeedDetails =()=>{
        const token = localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
        console.log("obj", obj);
          this.props.sendid(obj.id);
          apiservices.listdriverperformancebyid(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({PerformanceList:response.response, numbercount:calc})
                        console.log("performance", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
          this.loadDriverOverspeedDetails();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadDriverOverspeedDetails();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadDriverOverspeedDetails(); 
        });
      }
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
              {/* <div className="search-header booking-mishaps" style={{marginTop:0}}>
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                             <div className="payment-dropdown oversfeed" style={{marginLeft:'24%'}}> <span className="payment">City/Zone</span> <Select defaultValue="Chad Ellis" onChange={handleChange}>
                            <Option value="Chad Ellis">North Chennai</Option>
                            <Option value="lucy">North Chennai</Option>
                            
                            <Option value="Yiminghe">North Chennai</Option>
                            </Select>
                            </div>
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div> */}


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.performanceHeader && this.state.performanceHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.name_id}</th>
                                                <th>{item.city}</th>
                                                <th>{item.total_tasks}</th>
                                                <th>{item.on_time}</th>
                                                <th>{item.late}</th>
                                                 <th>{item.early_pickup}</th>
                                                 <th>{item.late_drop}</th>
                                                 {/* <th>{item.missed}</th> */}
                                                 <th>{item.mishaps}</th>
                                                 <th>{item.complaints}</th>
                                                 <th>{item.attendance}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.PerformanceList &&this.state.PerformanceList.map((item) => {

                                        return(
                                            <tr>
                                                  <td ><span className="pilot_name">{item.driver_first_name} {item.driver_last_name}</span><br/>
                 <div className="service-listing pilot_id">ID{item.driver_id}</div>
                                                  {/* <span className="service-listing total_task"> 120 Task</span> */}
                                                 
                                                </td>
                                                <td>{item.city}</td>
                                                <td>{item.total_tasks}</td>
                                                <td>{item.on_time_tasks}</td>
                                                 <td>{item.late_pickup}</td>
                                                <td>{item.early_pickup}</td>
                                                <td>{item.late_dropoff}</td>
                                                <td>{item.mishaps}</td> 
                                                <td>{item.complaints}</td> 
                                                <td>{item.attendance_percentage}%</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.PerformanceList&&this.state.PerformanceList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     {/* <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    /> */}
    </div>
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default DriverPerformanceDetails;