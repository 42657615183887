import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../../helpers/images";
// import './styles.css';
import Grid from '@material-ui/core/Grid';
// import ActiveDriverTable from "../ActiveDriverTable";
// import DisabledDriverTable from "../DisabledDriverTable";
// import PilotDefaulter from '../PilotDefaulter';
// import PilotResigned from '../PilotResigned';
// import PilotHistory from '../PilotHistory';
// import PilotInRequest from '../PilotInRequest';
// import AddDriver from '../AddDriver';
// import DriverDetails from "../DriverDetails";
import CustomerPayments from '../AllPayments/CustomerPayments';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import CashPayments from './CashPayments';
import CardPaymentsList from '../AllPayments/CardPayments';
import ChequePayments from './ChequePayments';
import OnlinePaymentsList from './OnlinePayments';
import QRPayments from './QRPayments';
import SVCPayments from './svcPayment';
class AllPayments extends Component {

    constructor(props) {
        super(props);

        console.log("receive pilot", props);
        this.state = {

            pilotMaster : true,
            addDriver : false,
            driverId : null
        }
    }
    receiveData=(data)=>{
 
    this.setState({driverId : data});        
    this.setState({driverEdit : true});
    this.setState({pilotMaster : false});

           
    if(data == "hide"){
        this.setState({driverEdit : false});
        this.setState({pilotMaster : true});
    
    }
}
AddDriver=()=>{
    this.setState({pilotMaster : false});
    this.setState({addDriver  : true})
}


    render() {
        return (
            <div>
                 {/* {this.state.driverEdit && <DriverDetails sendDriverId={this.state.driverId} sendHideDetails={(data)=>this.receiveData(data)}/>}
                {this.state.pilotMaster &&   */}
                {/* {this.state.driverEdit && <DriverDetails sendDriverId={this.state.driverId} sendHideDetails={(data)=>this.receiveData(data)}/>} */}
                 
               
                <div class="driver-piolet-menu allpayments_menu">
                        <Row>
                            <Col sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10} sm={10} className="introcenter mb-4" >
                                        <Nav variant="pills" className="flex-row pilot allpaymnets" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                                            <Nav.Item>
                                            <NavLink exact={true}   to={`${this.props.match.path}/`} activeClassName="active_cls">Customer Payments</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/cash_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/cash_payments`} activeClassName="active_cls">Cash Payments</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/card_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/card_payments`} activeClassName="active_cls">Card Payments</NavLink>
                                                
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/cheque_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/cheque_payments`} activeClassName="active_cls">Cheque Payments</NavLink>
                                             </Nav.Item>
                                            <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/online_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/online_payments`} activeClassName="active_cls">Online Payments</NavLink>
                                             </Nav.Item>
                                             <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/qr_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/qr_payments`} activeClassName="active_cls">QR Payments</NavLink>
                                             </Nav.Item>
                                             <Nav.Item>
                                            <NavLink className={`${window.location.hash.includes('/svc_payments/')&&"active_cls"}`}  to={`${this.props.match.path}/svc_payments`} activeClassName="active_cls">SVC Payments</NavLink>
                                             </Nav.Item>
                                             
                                        </Nav>
                                    </Grid>
                                     <Grid item xs={12} md={2} sm={2} className="introcenter driver_grid" >
                                                                        
                                    </Grid> 
                                </Grid>

                                <div className="generate-btn_payments"><button className="btn btn-linear-color">Generate Report</button></div>
                            </Col>
                            <Col sm={12}>
                                 <Switch>
                                 <Route exact path={`${this.props.match.path}/`} render={props => (<CustomerPayments {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/cash_payments`} render={props => (<CashPayments {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/card_payments`} render={props => (<CardPaymentsList {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/cheque_payments`} render={props => (<ChequePayments {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/online_payments`} render={props => (<OnlinePaymentsList {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/qr_payments`} render={props => (<QRPayments {...props} />)} />
                                 <Route  exact path={`${this.props.match.path}/svc_payments`} render={props => (<SVCPayments {...props} />)} />  
                </Switch>
                            </Col>
                        </Row>
                </div>
                
            </div>

            
        )

    }
    

}


export default AllPayments;