import React, { Component } from 'react';
import './style.css';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import SVCDetails from './SVCDetails';
import BankKYC from './BankKYC';
import ContactPersonDetails from './ContactPersonDetails';
import apiservices from '../../helpers/apiservices';

class AddDealer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      servicecentreStatus: 1,
      svcDetails: null,
      paymentDetails: null,
      error_msg: null,
      addServiceCentre: null,
    };
  }

  displayNextDetails = (data, key, details, submitdata) => {
    console.log('details', details);
    console.log('key', key);
    if (data != 4) {
      this.setState({ servicecentreStatus: data });
    }
    // this.setState({[key]:details});
    this.setState({ [key]: details }, function () {
      if (data == 4) {
        const { svcDetails, paymentDetails, contactpersonDetails } = this.state;
        const token = localStorage['accesstoken'];
        var obj = {
          // dealer_email:svcDetails.email_id.value,
          svc_dealer_id: svcDetails.dealer_master.value,
          centre_name: svcDetails.servicecentre_name.value,
          address: svcDetails.address.value,
          latitude: svcDetails.currentLatLng.value.lat,
          longitude: svcDetails.currentLatLng.value.lng,
          city: svcDetails.city.value,
          zone_id: svcDetails.zone_id.value,
          pincode: svcDetails.pincode.value,
          capacity: svcDetails.capacity.value,
          potential: svcDetails.potential.value,
          shop_type_id: svcDetails.shop_type.value,
          authorised_id: svcDetails.authorized_private.value,
          vehicle_type_id: svcDetails.vehicle_type.value,
          brand: svcDetails.brand.value,
          // account_manager:svcDetails.account_manager.value,
          bank_name: paymentDetails.bank_name.value,
          bank_address: paymentDetails.bank_address.value,
          account_name: paymentDetails.account_name.value,
          account_number: paymentDetails.account_number.value,
          ifsc_code: paymentDetails.ifsc_code.value,
          gst_number: paymentDetails.gst_number.value,
          pan_number: paymentDetails.pan_number.value,
          bank_pincode: paymentDetails.pincode.value,
          contact_name: contactpersonDetails.full_name.value,
          contact_number: contactpersonDetails.mobile_number.value,
          contact_email: contactpersonDetails.contact_emailid.value,
          dealer_code: contactpersonDetails.dealer_code.value,
          workshop_code: contactpersonDetails.workshop_code.value,
          // single_point_name:contactpersonDetails.singlepnt_contactname.value,
          // single_point_number:contactpersonDetails.singlepnt_contactnumber.value,
        };

        console.log('addservice obj', obj);
        if (submitdata == 'submit') {
          apiservices.addservicecentre(obj, token).then((response) => {
            console.log('login response', response);
            if (!response.error) {
              // this.props.successdealer(response);
              this.setState({ addServiceCentreSuccess: response });
            } else {
              this.setState({ addServiceCentreFailure: response });
            }
          });
        }
        console.log('submit obj', obj);
      }
    });
  };
  goBack = (data) => {
    this.setState({ servicecentreStatus: data });
  };
  render() {
    return (
      <div>
        <div>
          {this.state.servicecentreStatus == 1 && (
            <div class='overall-service'>
              <div className=''>
                <div className='dealer-head'>1. SVC Details</div>
                <SVCDetails
                  svcDetails={this.state.svcDetails}
                  sendnextdetails={(data, key, details) =>
                    this.displayNextDetails(data, key, details)
                  }
                />
              </div>
            </div>
          )}

          {this.state.servicecentreStatus == 2 && (
            <div class='overall-service'>
              <div className=''>
                <div className='dealer-head'>2. Bank KYC</div>

                <BankKYC
                  paymentDetails={this.state.paymentDetails}
                  sendgoBack={(data) => this.goBack(data)}
                  sendnextdetails={(data, key, details) =>
                    this.displayNextDetails(data, key, details)
                  }
                />
              </div>
            </div>
          )}

          {this.state.servicecentreStatus == 3 && (
            <div class='overall-service'>
              <div className=''>
                <div className='dealer-head'>3. Contact Person Details</div>
                <ContactPersonDetails
                  {...this.props}
                  contactpersonDetails={this.state.contactpersonDetails}
                  sendgoBack={(data) => this.goBack(data)}
                  sendnextdetails={(data, key, details, submitdata) =>
                    this.displayNextDetails(data, key, details, submitdata)
                  }
                  addServiceCentreSuccess={this.state.addServiceCentreSuccess}
                  addServiceCentreFailure={this.state.addServiceCentreFailure}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AddDealer;
