import React, { Component } from 'react';
import './style.css';
import apiservices from '../../helpers/apiservices';
import Images from '../../helpers/images';
import { Pagination, Select } from 'antd';
import moment from 'moment';
var ResignedHeader = [
  {
    idDefaulterItem: 1,
    driver_id: 'Pilot ID',
    driver_details: 'Driver Details',
    age: 'Age',
    swife_machine_id: 'Swipe Machine ID',
    resigned_date: 'Deleted Date',
    resigned_age: 'Resigned Age',
    reason: 'Deleted Reason',
  },
];

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
class PilotHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ResignedHeader: ResignedHeader,
      ResignedItem: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
      name_search: null,
      reason_search: null,
    };
  }

  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={Images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={Images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.DriverHistory();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.ResignedDriverList();
    });
  };

  componentWillMount() {
    this.DriverHistory();
  }
  DriverHistory = () => {
    this.setState({ loading: true });
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
      name: this.state.name_search,
      reason: this.state.reason_search,
      city: '',
      zone: '',
    };
    console.log('actve', obj);
    apiservices.listdeleteddrivers(obj, token).then((response) => {
      this.setState({ loading: false });
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({ ResignedItem: response.response, numbercount: calc });
        console.log('acive reponse', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  cityFilter = (e) => {
    var value = e.target.value;
    console.log('city search', value);
    // this.loadsvclist();
    if (value == '') {
      value = null;
      //
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.DriverHistory();
    });
  };
  render() {
    return (
      <div className='container'>
        <div className='search-header driver-search'>
          <div className='row'>
            <div className='col-md-4'>
              <div class='form-group search-list payment-search'>
                <input
                  type='text'
                  placeholder='Search Name'
                  className='form-control'
                  name='name_search'
                  onChange={(e) => this.cityFilter(e)}
                />
                <img src={Images.green_search} className='img-fluid' />
              </div>
            </div>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <div className='city_search' style={{ display: 'flex' }}>
                <label>Reason:</label>
                <input
                  type='text'
                  name='reason_search'
                  className='form-control citysearchbox'
                  onChange={(e) => this.cityFilter(e)}
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        {this.state.responseMsg && this.state.responseMsg ? (
          <div className='failure_msg'>
            {this.state.responseMsg && this.state.responseMsg}
          </div>
        ) : (
          ''
        )}
        {this.state.responseMsg && !this.state.responseMsg ? (
          <div className='success_msg'>
            {this.state.responseMsg && this.state.responseMsg}
          </div>
        ) : (
          ''
        )}

        <div className='driverinfodiv defaulter-pilot'>
          <div className='driverActiveTable mt-0 defaulter-pilot'>
            <table class='table table-hover'>
              <thead>
                {this.state.ResignedHeader &&
                  this.state.ResignedHeader.map((item) => {
                    return (
                      <tr>
                        <th>{item.driver_id}</th>
                        <th>{item.driver_details}</th>
                        <th>{item.age}</th>
                        <th>{item.swife_machine_id}</th>
                        <th>{item.resigned_date}</th>
                        <th>{item.reason}</th>
                      </tr>
                    );
                  })}
              </thead>
              <tbody>
                {this.state.ResignedItem &&
                  this.state.ResignedItem.map((item) => {
                    return (
                      <tr>
                        <td>ID{item.id}</td>
                        <td>
                          <div className='driverImagediv'>
                            <img
                              src={
                                item.image_url
                                  ? item.image_url
                                  : Images.default_image
                              }
                              className='img-fluid driverImage'
                            />
                          </div>
                          <div className='driverImagename'>
                            {item.first_name} {item.last_name}
                          </div>
                          <div className='driverImageNumber'>
                            {item.mobile_no}
                          </div>
                        </td>
                        <td> {item.age}</td>
                        <td>{item.swipe_machine_number}</td>
                        <td>{moment(item.deleted_date).format('DD/MM/YY')}</td>
                        <td>{item.deleted_reason}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {this.state.ResignedItem && this.state.ResignedItem.length == 0 && (
              <div className='no_records_found'>No Records Found</div>
            )}
          </div>
        </div>

        <Pagination
          total={this.state.numbercount}
          current={this.state.currentPage}
          onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize}
          pageSizeOptions={['5', '10', '15']}
          onShowSizeChange={(current, size) => this.showChanger(current, size)}
          showSizeChanger
          itemRender={this.itemRender}
        />
      </div>
    );
  }
}

export default PilotHistory;
