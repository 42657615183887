import React, { Component } from 'react';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { Table, Tag, Pagination, Select } from 'antd';

import { DatePicker } from 'antd';

import moment from 'moment';

const dateFormat = 'YYYY/MM/DD';

const { Option } = Select;

function handleChange(value)
{
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

// var bookingHeader = [{label : 'SVC Name', key : 'svc_name'}, {label: 'Customer Name', key : 'customer_name'},  {label : 'Vehicle Number', key : 'vehicle_number'},  {label : 'Booking ID', key : 'booking_id'}, {label : 'Booking Type', key : 'booking_type'}, {label : 'Slot', key : 'slot'},  {label : 'Ambassador', key : 'ambassador'},  {label : 'Booking Status', key : 'booking_status'}];

var cashPaymentHeader = [{ 'id': 1, 'date': 'Date', 'time': 'Time', 'svc_name': 'SVC Name', 'customer_name': 'Customer Name', 'vehicle_number': 'Vehicle Number', 'driver_name': 'Pilot Name', 'driver_id': 'Pilot ID', 'booking_id': 'Booking ID', 'state': 'Handed To', 'payment_mode': 'Clear', 'amount': 'Amount in Cash','cash_image':'Customer Image' }];

class CashPayments extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      cashPaymentHeader: cashPaymentHeader,
      cashPayment: [],
      currentPage: 1,
      pageSize: 10,
      customer_name: null,
      fromDate: null,
      toDate: null,
      onlinefields: {
        svc_center: { validations: [], error: null, value: '' },
        driver_id: { validations: [], error: null, value: '' },
      },


    }
  }

  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }


  loadCashPayment = () =>
  {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, svc_center: this.state.onlinefields.svc_center.value, driver: this.state.onlinefields.driver_id.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    console.log("actve", obj);
    apiservices.listcashpayments(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          this.setState({ cashPayment: response.response, numbercount: calc })
          console.log("cash payment", response);
        }
        else
        {
          this.setState({ error_msg: response.message })
        }
      })


  }
  componentWillMount()
  {
    this.loadCashPayment();
    this.loadDriverList();
    this.loadSeriveCenter();
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.loadCashPayment();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.loadCashPayment();
    });
  }
  closePayment = (data) =>
  {
    console.log("data", data)
    const token = localStorage['accesstoken'];
    var obj = { id: data.id, assign_id: data.assign_id };
    apiservices.closepayment(obj, token)
      .then((response) =>
      {
        console.log("response", response)
        // this.setState({loading:false});
        if (response.error == false)
        {
          this.setState({ success_msg: response.message }, function ()
          {
            this.loadCashPayment();
          });
          setTimeout(() =>
          {
            this.setState({ success_msg: '' })

          }, 3000);
          console.log("close payment", response.message)
        }
        else
        {
          this.setState({ error_msg: response.message }, function ()
          {
            this.loadCashPayment();
          });
          setTimeout(() =>
          {
            this.setState({ error_msg: '' })
          }, 3000);
        }
      })
    console.log("close payment", data);
  }
  cityFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function ()
    {
      this.loadCashPayment();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.loadCashPayment();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.loadCashPayment();
    })
  }
  loadDriverList = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.listAllActiveDriverDrop(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ driver_id: response.response })
          // if(!value){
          // ticketfields['ticket_priority'].value=response.response.length>0?response.response.id:'';            
          // this.setState({ticketfields})
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var onlinefields = this.state.onlinefields;
    onlinefields['svc_center'].value = data;
    this.setState({ onlinefields }, function ()
    {
      this.loadCashPayment();
    })
  }
  checkdriverfilter = (data) =>
  {
    var onlinefields = this.state.onlinefields;
    onlinefields['driver_id'].value = data;
    this.setState({ onlinefields }, function ()
    {
      this.loadCashPayment();
    })
  }

  render()
  {
    const { svc_center, driver_id } = this.state;
    const offset = moment().utcOffset();
    return (
      <div>
        {/* <div>
                                            <button className="genrate_btn" >
                                                Generate Report
                                            </button>
                                            </div> */}
        <div className="customer_payments_main">
          <div className="container1">


            <div className="search-header mt-0 booking-mishaps booking-active">
              <div className="row">
                <div className="col-md-3">
                  <div class="form-group search-list payment-search">
                    <input type="text" placeholder="Search for Customer" className="form-control" name="customer_name" onChange={(e) => this.cityFilter(e)} />
                    <img src={images.green_search} className="img-fluid" />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                    <Select value={[this.state.onlinefields.svc_center.value ? parseInt(this.state.onlinefields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                      <Option value="">All</Option>
                      {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                      {
                        return (


                          <Option value={item.id}>{item.centre_name}</Option>

                        )
                      })}

                    </Select>
                  </div>
                  <div className="payment-dropdown"> <span className="payment">Pilot Name:</span>
                    <Select value={[this.state.onlinefields.driver_id.value ? parseInt(this.state.onlinefields.driver_id.value) : '']} onChange={(value) => this.checkdriverfilter(value)} name="driver_id" >
                      <Option value="">All</Option>
                      {driver_id && driver_id.length > 0 && driver_id.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.first_name} {item.last_name}</Option>
                        )
                      })}

                    </Select>
                  </div>
                  <div className="service_listing booking-list payments-list">


                    <div className="date-pickers booking">
                      <img src={images.date_picker} className="img-fluid date-icon" />
                      <span className="from">From :</span>
                      <span className="to">To :</span>
                      <RangePicker
                        allowClear={false}
                        onChange={(value) => this.checkfiltervalidations(value)}
                        //    defaultValue={[null,null]}
                        value={[this.state.fromDate, this.state.toDate]}

                      />
                      <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.error_msg ?
              <div className="failure_msg">{this.state.error_msg}</div> : ''
            }

            {this.state.success_msg ?
              <div className="success_msg">{this.state.success_msg}</div> : ''
            }

            <div className="table-list customer_table">


              <div class="table-responsive">


                <table class="table table-borderless">
                  <thead>
                    {this.state.cashPaymentHeader && this.state.cashPaymentHeader.map((item) =>
                    {
                      return (
                        <tr>
                          <th>{item.date}</th>
                          <th>{item.time}</th>
                          <th>{item.svc_name}</th>
                          <th>{item.customer_name}</th>
                          <th>{item.vehicle_number}</th>
                          <th>{item.driver_name}</th>
                          <th>{item.driver_id}</th>
                          <th>{item.booking_id}</th>
                          <th>{item.amount}</th>
                          <th>{item.state}</th>
                          <th>{item.cash_image}</th>
                          <th>{item.payment_mode}</th>

                        </tr>
                      )
                    })}
                  </thead>
                  <tbody>

                    {this.state.cashPayment && this.state.cashPayment.length > 0 && this.state.cashPayment.map((item, index) =>
                    {

                      return (
                        <tr>
                          <td>{moment(item.completed_at).format('DD/MM/YY')} </td>
                          <td>{moment.utc(new Date(item.completed_at), 'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                          <td>{item.centre_name}</td>
                          <td>{item.customer_name}</td>
                          <td>{item.vehicle_number}</td>
                          <td>{item.driver_first_name} {item.driver_last_name}</td>
                          <td>{item.driver_id}</td>
                          <td>ID{item.id}</td>
                          <td>Rs.{item.invoice_amount}</td>
                          <td>{item.driver_first_name} {item.driver_last_name}</td>
                          <td style={{ width: '100px' }}><img style={{ width: 80, height: 80, borderRadius: 3 }} src={item.cash_image} /></td>                          <td><img src={images.cancel_new} style={{ width: 40, opacity: item.is_admin_verified != 1 ? 1 : 0.5 }} onClick={() => item.is_admin_verified != 1 ? this.closePayment(item) : ''} /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                {this.state.cashPayment && this.state.cashPayment.length == 0 &&
                  <div style={{ paddingBottom: 15 }}>No Records Found </div>
                }



              </div>
            </div>
            <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
              pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
            />


          </div>
        </div>
      </div>

    )
  }
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default CashPayments;