import React, { Component } from 'react';
import './styles.css';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import Images from '../../../helpers/images';
const { Option } = Select;
export default class AddZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // props.history.location.state.editid
      designation: null,
      editID: null,
      success_msg: '',
      failure_msg: '',
      fields: {
        zone_name: {
          validations: [
            { name: 'required' },
            { name: 'zone' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
      },
    };
  }
  // componentWillUpdate(prevProps, prevState) {
  //   if (this.state.success_msg !== '' || this.state.failure_msg !== '') {
  //     this.setState({ success_msg: '', failure_msg: '' });
  //   }
  // }
  checkvalidations = (value, key) => {
    console.log('checkvalidations');
    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  AddZone = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      const token = localStorage['accesstoken'];
      var obj = {
        zone_name: this.state.fields.zone_name.value,
      };
      apiservices.addzone(obj, token).then((response) => {
        if (!response.error) {
          this.setState({ failure_msg: '' });
          this.setState({ success_msg: response.message });
          this.setState({ fields: { zone_name: { value: '' } } });
        } else {
          this.setState({ success_msg: '' });
          this.setState({ failure_msg: response.message });
          this.setState({ fields: { zone_name: { value: '' } } });
        }
      });
    }
  };

  render() {
    console.log('edit id', this.state.editID);
    const { designation, service_center } = this.state;
    return (
      <div>
        <div className='svc-add-user-info svc_first'>
          <div className='svc-detailes-all'>
            <div className='dealer-body'>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>Zone Name</label>
                  <input
                    type='text'
                    className='form-control'
                    name='zone_name'
                    value={this.state.fields.zone_name.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['zone_name'].error &&
                      this.state.fields['zone_name'].error}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          // disabled={this.state.success_msg}
          className='btn btn-dark-blue next-button'
          onClick={this.AddZone}>
          Submit
        </button>
        {this.state.failure_msg ? (
          <div className='failure_msg'>{this.state.failure_msg}</div>
        ) : (
          ''
        )}
        {this.state.success_msg ? (
          <div className='success_msg'>{this.state.success_msg}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
