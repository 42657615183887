import React, { Component } from 'react';
import ValidationLibrary from '../../helpers/validationfunction';
import Images from '../../helpers/images';
import S3FileUpload from 'react-s3';
import { Upload, message } from 'antd';
import Aws from '../../helpers/awskeys';
import { Select } from 'antd';
import apiservices from '../../helpers/apiservices';
const { Option } = Select;

const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: 'photos' /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
class AddDriverPersonalDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filenewlist: [],
      fileupload_error: null,

      fields: {
        first_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        last_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        mobile_no: {
          validations: [
            { name: 'required' },
            { name: 'mobile' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        alternate_no: {
          validations: [
            { name: 'required' },
            { name: 'mobile' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        email: {
          validations: [
            { name: 'required' },
            { name: 'email' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        age: {
          validations: [
            { name: 'required' },
            { name: 'allowNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        address: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        city: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        pincode: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'zipcode' },
          ],
          error: null,
          value: '',
        },
        pan_number: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        shift: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        total_task_month: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        experience: {
          validations: [
            { name: 'required' },
            { name: 'allowNumaricOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        zone_id: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },

        upload_profile_image: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        // mg_amount : {validations:[{name:'required'},{name:'Noemptyspace'},{name : 'allowNumaricOnly'}],error:null,value:''}
      },
    };
  }

  checkvalidations = (value, key) => {
    var fields = this.state.fields;

    fields[key].value = value;

    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );

    fields[key].error = checkstatus.msg;

    var getallkeys = Object.keys(fields);

    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');

    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };

  PersonalDetails_next = async () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //   //successblock
      //   this.setState({error:false});

      //   this.props.SendToNext(2,'DriverPersonalDetails', fields);

      // //   this.setState({svc1 : false});
      // //   this.setState({svc2  : true});
      // }
      // this.setState({fields});

      var fileList = this.state.fields.upload_profile_image.value;
      console.log('else part', fileList);
      var temparray = [];
      for (var i in fileList) {
        var response = await S3FileUpload.uploadFile(fileList[i], config);
        console.log('response', response);
        temparray.push(response.location);
        if (fileList.length - 1 == i) {
          // console.log();
          var apiimagefile = temparray.join(',');
          fields['upload_profile_image'].value = apiimagefile;
          console.log('bsbfdhwef', fields);
          this.props.SendToNext(2, 'DriverPersonalDetails', fields);
        }
      }
      //   //successblock
      this.setState({ error: false });
    }
    this.setState({ fields });
  };
  loadzoneDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.listallactivezone(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ zone_id: response.result });
        var fields = this.state.fields;
        if (!value) {
          fields['zone_id'].value =
            response.result.length > 0 ? response.result[0].id : '';
          this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  render() {
    var self = this;
    var temparray = [];
    const { zone_id } = this.state;

    const props = {
      name: 'file',
      accept: 'image/*',
      multiple: false,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      fileList: self.state.filenewlist,
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <img src={Images.file_remove} className='file_remove' />,
      },
      // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
      beforeUpload: (file) => {
        console.log('filelist', self.state.filenewlist);
        var filenewlist = self.state.filenewlist;
        filenewlist.push(file);
        if (self.state.filenewlist.length < 2) {
          self.setState({ filenewlist }, function () {
            self.checkvalidations(filenewlist, 'upload_profile_image');
            self.props.sendImages && self.props.sendImages(filenewlist);
          });
        } else {
          filenewlist = filenewlist.splice(0, 1);
          self.setState({ fileupload_error: 'Max Files Upload is 1' });
          self.setState({ filenewlist });
        }
        console.log('beforeuploadfile', file);
        // return true;
      },
      onChange: (info) => {
        var filenewlist = self.state.filenewlist;
        if (info.file.status == 'removed') {
          var findindex = filenewlist.findIndex(
            (obj) => obj.uid == info.file.uid,
          );
          filenewlist.splice(findindex, 1);
          self.setState({ filenewlist });
        }

        console.log('delete info', info);
      },
    };
    return (
      <div>
        <div class='overall-service'>
          <div className='dealer-master'>
            <div className='dealer-head'>1. Personal Details</div>
            <div className='dealer-body'>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>First Name *</label>
                  <input
                    type='text'
                    className='form-control'
                    name='first_name'
                    value={this.state.fields.first_name.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['first_name'].error &&
                      this.state.fields['first_name'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Last Name *</label>
                  <input
                    type='text'
                    className='form-control'
                    name='last_name'
                    value={this.state.fields.last_name.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />

                  <span className='error_msg_driver'>
                    {this.state.fields['last_name'].error &&
                      this.state.fields['last_name'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Mobile Number *</label>
                  <input
                    type='text'
                    className='form-control'
                    name='mobile_no'
                    value={this.state.fields.mobile_no.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['mobile_no'].error &&
                      this.state.fields['mobile_no'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Alternate Mobile Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='alternate_no'
                    value={this.state.fields.alternate_no.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['alternate_no'].error &&
                      this.state.fields['alternate_no'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div className='form-inline'>
                  <label>E-mail ID</label>
                  <input
                    type='text'
                    className='form-control'
                    name='email'
                    value={this.state.fields.email.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['email'].error &&
                      this.state.fields['email'].error}
                  </span>
                </div>

                <div class='form-inline'>
                  <label>Age</label>
                  <input
                    type='text'
                    className='form-control'
                    name='age'
                    value={this.state.fields.age.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['age'].error &&
                      this.state.fields['age'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <label>Address</label>

                <textarea
                  row='5'
                  className='form-control'
                  name='address'
                  value={this.state.fields.address.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }></textarea>
                <span className='error_msg_driver'>
                  {this.state.fields['address'].error &&
                    this.state.fields['address'].error}
                </span>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Town / City</label>
                  <input
                    type='text'
                    className='form-control'
                    name='city'
                    value={this.state.fields.city.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['city'].error &&
                      this.state.fields['city'].error}
                  </span>
                </div>
                <div class='form-inline position-relative'>
                  <label>Zone Name</label>

                  <Select
                    name='zone_id'
                    value={[this.state.fields.zone_id.value]}
                    onChange={(value) =>
                      this.checkvalidations(value, 'zone_id')
                    }>
                    {zone_id &&
                      zone_id.length > 0 &&
                      zone_id.map((item) => {
                        return (
                          <Option value={item.id}>{item.zone_name}</Option>
                        );
                      })}
                  </Select>
                  <img
                    alt=''
                    src={Images.dropdown}
                    className='img-fluid dropdown-img'
                  />
                  <span className='error_msg_adddealer'>
                    {this.state.fields['zone_id'].error &&
                      this.state.fields['zone_id'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Pincode</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pincode'
                    value={this.state.fields.pincode.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['pincode'].error &&
                      this.state.fields['pincode'].error}
                  </span>
                </div>
                <div class='form-inline'>
                  <label>PAN Number</label>
                  <input
                    type='text'
                    className='form-control'
                    name='pan_number'
                    value={this.state.fields.pan_number.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['pan_number'].error &&
                      this.state.fields['pan_number'].error}
                  </span>
                </div>
              </div>

              <div className='form-group'>
                <div class='form-inline'>
                  <label>Shift</label>
                  <input
                    type='text'
                    className='form-control'
                    name='shift'
                    value={this.state.fields.shift.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['shift'].error &&
                      this.state.fields['shift'].error}
                  </span>
                </div>{' '}
                <div class='form-inline'>
                  <label>Total Task for Month</label>
                  <input
                    type='text'
                    className='form-control'
                    name='total_task_month'
                    value={this.state.fields.total_task_month.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['total_task_month'].error &&
                      this.state.fields['total_task_month'].error}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <div class='form-inline'>
                  <label>Experience</label>
                  <input
                    type='text'
                    className='form-control'
                    name='experience'
                    value={this.state.fields.experience.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }
                  />
                  <span className='error_msg_driver'>
                    {this.state.fields['experience'].error &&
                      this.state.fields['experience'].error}
                  </span>
                </div>
              </div>
              <div className='form-group'>
                <label>
                  Upload Driver Profile
                  <span style={{ color: 'red', marginLeft: 10 }}>
                    {this.state.fileupload_error}
                  </span>
                </label>
                <div style={{ marginTop: 30 }}>
                  <Dragger {...props} className='driver_image'>
                    <div>Upload Driver Image</div>
                  </Dragger>

                  <span className='error_msg_driver'>
                    {this.state.fields['upload_profile_image'].error &&
                      this.state.fields['upload_profile_image'].error}
                  </span>
                </div>
                {/* <textarea row="5" className="form-control"></textarea> */}
              </div>
            </div>
          </div>

          <button
            onClick={() => this.PersonalDetails_next()}
            className='btn btn-dark-blue next-button'>
            Next <img alt='' src={Images.next} className='img-fluid arrow' />
          </button>
        </div>
      </div>
    );
  }

  componentDidMount() {
    console.log('DriverPersonalDetails', this.props.DriverPersonalDetails);
    this.loadzoneDropdown(this.state.fields.zone_id.value);
    if (this.props.DriverPersonalDetails) {
      this.setState({ fields: this.props.DriverPersonalDetails });
    }
    if (this.props.receiveDriverImage) {
      this.setState({ filenewlist: this.props.receiveDriverImage });
    }
  }
}

export default AddDriverPersonalDetails;
