import React, { Component } from 'react';
import { Table, Tag, Pagination, Select} from 'antd';
import Image from '../../../helpers/images';
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var selfHeader = [{'id' : 1,  'date' : 'Date', 'tme': 'Time', 'booking_id' : 'Booking ID', 'city' : 'City','svc_name':'SVC Name',  'complaint_from' : 'Complaint From','complaint_to':'Complaint To','action_taken':'Action Taken','close_complaint':'Close Complanint','days_elapsed':'Days Elapsed'}];
var selfList = [{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'},
{'id' : 1,  'date' : '13/05/20', 'tme': '09:00:00', 'booking_id' : '123456', 'city' : 'Chennai','svc_name':'Hyundai - RK',  'complaint_from' : 'Dennis Wills','complaint_to':'Ambassador','action_taken':'No action','close_complaint':'Close Complanint','days_elapsed':'23'}];
export default class ServiceCenter extends Component {
  constructor(props){
    super(props);
    this.state={
      selfHeader:selfHeader,
      selfList:selfList,
      currentPage : 1,
            pageSize:10,
    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
 
  render() {
    return (
      <div className="zones_main"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control"/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                             
                                    <div className="payment-dropdown float-right mr-2 city_drop"> <span className="city_zone">City / Zone:</span> <Select defaultValue="Chad Ellis" onChange={handleChange}>
                             <Option value="Chad Ellis">North Chennai</Option>
                            <Option value="lucy">North Chennai</Option>
                            
                            <Option value="Yiminghe">North Chennai</Option>
                            
                             </Select>
                            </div>
                             
                             
                             </div>
                         </div>
                    </div>

                    <div >
                    <div className="table-list add_border">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.selfHeader && this.state.selfHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.date}</th>
                        <th>{item.time}</th>
                        <th>{item.booking_id}</th>
                        <th>{item.city}</th>
                        <th>{item.svc_name}</th>
                        <th>{item.complaint_from}</th>
                        <th>{item.complaint_to}</th>
                        <th>{item.action_taken}</th>
                        <th>{item.close_complaint}</th>
                        <th>{item.days_elapsed}</th>
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.selfList&&this.state.selfList.length>0&&this.state.selfList.map((item,index) => {

                return(
                    <tr>
                       
                       <td>{item.date}</td>
                        <td>{item.time}</td>
                        <td>{item.booking_id}</td>
                        <td>{item.city}</td>
                        <td>{item.svc_name}</td>
                        <td>{item.complaint_from}</td>
                        <td>{item.complaint_to}</td>
                        <td>{item.action_taken}</td>
                        <td><img src={Image.cancel_new}/></td>
                        <td>{item.days_elapsed}</td>
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.selfList&&this.state.selfList.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
       </div>
    );
  }
}
