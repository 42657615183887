import React, {Component} from 'react';
import './style.css';
import Images from '../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
   
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
  import SvcDetails from '../SvcDetails';
  import SvcDetailsBooking from '../SvcDetailsBooking';
  import Payment from '../Svc_Payment';
  import SvcUsers from '../SvcUser';
import { First } from 'react-bootstrap/PageItem';
class SvcDetails_Tab extends Component{
    constructor(props){
      console.log("props",props);
        super(props);
        this.state = {
          SvcDetails : false,
          svcList: true,
          tabKey:'first',useredit_id:null,
          svcDynId:null,
          svc_id:null
        }
    }
    SvcDetails = () => {

      this.setState({SvcDetails : true});
    
      this.setState({svcList : false});
       
     }
    componentWillMount(){
      if(this.props.action=='first'){
        this.setState({tabKey:'fourth'})
      }else if(this.props.action=='second'){
        this.setState({tabKey:'fourth'})
      }else{
        this.setState({tabKey:'first'})
      }
      console.log("this props",this.props);
    }
     svcDetailsHide = () => {
      this.setState({SvcDetails : false});
    
      this.setState({svcList : true});
     }
     componentWillReceiveProps(props){
       this.setState({tabKey:props});
      //  var useredit_id=props.history.location.state;
      //  this.setState({useredit_id:useredit_id});
      //  console.log("will receive",this.state.useredit_id);
     }
     goBack =(data)=>{
       console.log("goback",data);
      //  this.props.goBack(data)
     }
render(){

    return(
        <div >

            
                <div class="svcDetails-tabs svcdetails_main">
                <div className="back-btns">
             <Link to={`${this.props.parentProps.match.path}/`}><span className="back-button" > 
                    <img src={Images.FadedArrowImg} className="img-flud"/>
                 
                 Back to the Lists</span></Link>
             </div>           
                <Row className="svcDetails_tab_row">
                    <Col sm={12}>
                    <Nav variant="pills" className="flex-row svcdetails_nav"  selectedIndex={this.state.selectedIndex}
                        onSelect={this.handleSelect}>
                   
                   <NavLink   to={`${this.props.match.path}/svc/${this.state.svcDynId}`}  >SVC Details</NavLink>
                   <NavLink className={`${window.location.hash.includes('booking')&&"activelink"}`}  to={`${this.props.match.path}/booking/${this.state.svcDynId}`}  >Booking</NavLink>
                   <NavLink className={`${window.location.hash.includes('payments')&&"activelink"}`}  to={`${this.props.match.path}/svc_payments/customer_payments/${this.state.svcDynId}`} >Payments</NavLink>
                   <NavLink className={`${window.location.hash.includes('users')&&"activelink"}`}  to={`${this.props.match.path}/users/add_user/0/${this.state.svcDynId}`} >Users</NavLink>
                        
                    </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="svc_first">
                        <Switch>
            <Route exact  path={`${this.props.match.path}/svc/:id/`} render={props=> (<SvcDetails sendid={(id)=>this.setState({svcDynId:id})} sendsvcid={this.props.sendsvcid} reload={(data)=>this.goBack(data)} {...props} />)} />
            <Route  exact path={`${this.props.match.path}/booking/:id/`} render={props=> (<SvcDetailsBooking {...props} sendid={(id)=>this.setState({svcDynId:id})} sendsvcid={this.props.sendsvcid}/>)} />
            <Route  path={`${this.props.match.path}/svc_payments`} render={props=> (<Payment {...props} sendsvcid={this.props.sendsvcid} sendSVCID={(svcid)=>this.setState({svcDynId:svcid})} parentProps={this.props}  action={this.props.action}/>)} />
            <Route   path={`${this.props.match.path}/users`} render={props=> (<SvcUsers sendsvcid={this.props.sendsvcid} sendSVCID={(svcid)=>this.setState({svcDynId:svcid})} parentProps={this.props}  action={this.props.action} {...props} />)} />
            </Switch>
                   
                    </div>
                    </Col>
                </Row>
                {/* </Tab.Container> */}
                </div>
                {/* Form Tabs start */}
        </div>
    )
}


}

export default SvcDetails_Tab;