import React, {Component} from 'react';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { Table, Tag, Pagination, Select} from 'antd';
import moment from 'moment';
import { DatePicker } from 'antd';
const dateFormat = 'YYYY-MM-DD';


const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var TicketMediumHeader = [{'id' : 1,'created_at':'Created Date'  ,'driver_name' : 'Driver Name', 'mobile_number': 'Mobile Number', 'booking_id' : 'Booking ID', 'svc_name' :'SVC Name',  'svc_id' : 'SVC ID', 'ticket_name' : 'Ticket Name', 'close' : 'Close Ticket'}];
class MediumPriority extends Component{
    constructor(props){
        super(props);
        this.state = {
            TicketMediumHeader : TicketMediumHeader,
            TicketMediumList : [],  
            currentPage : 1,
            deleteID:null,
            openDialog:false,
            driver_name:null,
            fromDate:null,
            toDate:null,
            pageSize:10,
            fields:{
                reasons:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
              },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.TicketMediumPriority();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.TicketMediumPriority(); 
        });
      }
      TicketMediumPriority=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver_name,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("actve", obj);     
        apiservices.listticketmedium(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({TicketMediumList:response.response, numbercount:calc})
                      console.log("ticket high reponse", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    componentWillMount(){
        this.TicketMediumPriority();
    }
    closeTicket=()=>{
        
        const token=localStorage['accesstoken'];
        var obj={id:this.state.deleteID,close_reason:this.state.fields.reasons.value};
        apiservices.closetickets(obj,token)
              .then((response)=>{
                  if(!response.error){
                        setTimeout(() => {
                            this.TicketMediumPriority();
                            this.setState({openDialog:false})
                          }, 3000);
                           this.setState({success_msg:response.message})
                      

                  }else{
                    this.setState({error_msg:response.message})
                  }
       
                })
    }
    checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      popupSubmit =(data)=>{
          var fields =this.state.fields;
          var getallkeys=Object.keys(fields);
          getallkeys.map((obj)=>{
          var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
          fields[obj].error=checkstatus.msg;
          })
          var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
          if(filtererrors.length>0){
            //errorblock
            this.setState({error:true})
          }else{
            //successblock
            this.setState({error:false});
            this.closeTicket();
          // alert('hii');
          }
          this.setState({fields});
      }
      closeTicketDialog =(data)=>{
          this.setState({openDialog:true,deleteID:data,success_msg:'',error_msg:''})
      }
      closeDialog =()=>{
          var fields=this.state.fields;
          fields['reasons'].value='';
          fields['reasons'].error='';
          this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
      }
      checkvalidations =(data)=>{
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.TicketMediumPriority();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.TicketMediumPriority();
        })
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.TicketMediumPriority();
        });
      }
render(){

    return(
        <div>
              
              <div className="search-header booking-mishaps" style={{marginTop:10}}>
                         <div className="row">
                             <div className="col-md-3">
                                   <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for Driver" className="form-control" name="driver_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Images.green_search} className="img-fluid"/>
                                  </div> 
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkvalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                    <div className="table-list">


                        <div class="table-responsive">

                        
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.TicketMediumHeader &&this.state.TicketMediumHeader.length>0&& this.state.TicketMediumHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.created_at}</th>
                                                <th>Created Time</th>
                                                <th>{item.driver_name}</th>
                                                <th>Vehicle Number</th>
                                                <th>{item.mobile_number}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.ticket_name}</th>
                                                <th>{item.close}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                {this.state.TicketMediumList&&this.state.TicketMediumList.length>0&&this.state.TicketMediumList.map((item,index) => {
                                  const date = new Date(item.created_at)
                                  const getmonth=date.getMonth() + 1;
                                  const finalDate = date.getDate()+'-'+getmonth+'-'+date.getFullYear()
                                  const finalTime = date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
                                        return(
                                            <tr>
                                                  <td>{finalDate}</td>
                                                  <td>{finalTime}</td>
                                                 <td>{item.driver_first_name} {item.driver_Last_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>{item.driver_mobile_no}</td>
                                                <td>{item.booking_id}</td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.svc_center_id}</td>
                                                <td style={{width:200,wordBreak:'break-word'}}>{item.ticket}</td>
                                                <td><img disabled src={item.status==0?Images.approvel:Images.cancel_new} onClick={()=>item.status!=0?this.closeTicketDialog(item.id):''}/></td>
                                                {/* <td><img src={Images.cancel_new} onClick={()=>this.closeTicket(item.id)}/></td> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {
                                this.state.TicketMediumList&&this.state.TicketMediumList.length==0&&
                                <div className="no_records_found">No Records Found</div>
                            }

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />

<Dialog  open={this.state.openDialog}  className="shopbox schedule-popup">
                    <div className="modalclosecls" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                    <div className="modalcntntcls">
                   <div className="delete_text">
                       Close Ticket
                   </div>
                   <div>
                       
                       
                       <div className="form-group reason_box">
                         <label>
                         Reason
                         </label>

                         <textarea row="5" col="4"   maxLength="300" name="reasons" className="form-control reason_box" value={this.state.fields.reasons.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['reasons'].error&&this.state.fields['reasons'].error}</span> 
                     </div>
                     {this.state.error_msg ?
                <div className="failure_msg">{this.state.error_msg}</div> : ''
              }

              {this.state.success_msg ?
                <div className="success_msg">{this.state.success_msg}</div> : ''
              }
                       <div className="btn_main">
                           <button disabled={this.state.responseMsg&&this.state.responseMsg} className="delete_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
            
                 </Dialog>
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default MediumPriority;