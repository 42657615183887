import React, { Component } from "react";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import AddNewSvcUser from "../AddNewSvcUser";
import SvcUserList from "../SvcUserList";
import SvcDisabledUser from "../SvcDisabledUser";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
// import "./styles.css";
import Customer_Payment from '../Customer_Payment';

 import Account_Statements from '../Account_Statements';
export default class Svc_Payment extends Component {
    constructor(props) {
        console.log("svc user props",props);
        super(props);
        this.state = {
            receiveedit:null,
            tabKey:'first',
            editid:null,
            svc_id:null
        }
      }
      receiveditdata=(data)=>{
          console.log("receive edit",data);
          this.setState({receiveedit:data});
          console.log("edit id",this.state.receiveedit)
        //   console.log("receive edit state",this.state.receiveedit);
      }
      componentWillReceiveProps(props){
          console.log("svc user will recieve",props);
        //   var editid=props.history.location.state.editid;
        //   this.setState({editid:editid})
        //   console.log("will receive svc user",editid);
      }
    render() {
        return (
        <div class = "svc_user_details">
           
            
            {/* <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.tabKey} mountOnEnter="true" forceRenderTabPanel={true}>  */}
                <Row>
                    <Col sm={12}>
                    <Nav variant="pills"  className="flex-row svcuser" selectedIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
           <Nav.Item>
    <NavLink parentClass="parent_active" to={`${this.props.match.path}/customer_payments/${this.state.svc_id}`} activeClassName="active_user">Customer Payments</NavLink>
          </Nav.Item>
          <Nav.Item>
    <NavLink  to={`${this.props.match.path}/account_statement/${this.state.svc_id}`} activeClassName="active_user">Account Statements</NavLink>
          </Nav.Item>
          </Nav>
                    </Col>
                    <Col sm={12}>
                    <Switch>
            <Route exact  path={`${this.props.match.path}/customer_payments/:svc_id`}  render={props=> (<Customer_Payment sendsvcid={(svid)=>{this.setState({svc_id:svid}); this.props.sendSVCID(svid);}} {...props} />)} />
            <Route exact  path={`${this.props.match.path}/account_statement/:svc_id`}  render={props=> (<Account_Statements sendsvcid={(svid)=>{this.setState({svc_id:svid}); this.props.sendSVCID(svid);}} {...props} />)} />
            </Switch>
                    </Col>
                </Row>
            {/* </Tab.Container> */}
        </div>);
    }
}