import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import PhotoGraphsPickUp from './PhotoGraphsPickUp';
import PhotoGraphsDropOff from './PhotoGraphsDropOff';
import
  {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
class BookingPhotoGraphs extends Component 
{

  constructor(props)
  {

    super(props);

    this.state = {
      bookingid: null,
      assignid: null
    }
  }
  receivebookingid = (data, data1) =>
  {
    this.setState({ bookingid: data, assignid: data1 })
    this.props.sendbookingid && this.props.sendbookingid(data, data1)
  }
  render()
  {

    return (
      <div>

        {/* Form Tabs start */}

        <div class="svcDetails-tabs">
          <Row>
            <Col sm={12}>

              <Nav variant="pills" className="flex-row svcdetails_nav bookingdetailsmain" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink exact={true} to={`${this.props.match.path}/${this.state.bookingid}/${this.state.assignid}`} >Pickup</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/drop_off/${this.state.bookingid}/${this.state.assignid}`} >Drop Off</NavLink>
                </Nav.Item>



              </Nav>
            </Col>
            <Col sm={12}>
              <div className="svc_first pickup-booking">
                <Switch>
                  <Route exact path={`${this.props.match.path}/:id/:assignid`} render={props => (<PhotoGraphsPickUp {...props} sendbookingid={(data, data1) => this.receivebookingid(data, data1)} />)} />
                  <Route exact path={`${this.props.match.path}/drop_off/:id/:assignid`} render={props => (<PhotoGraphsDropOff {...props} sendbookingid={(data, data1) => this.receivebookingid(data, data1)} />)} />


                </Switch>
              </div>
            </Col>
          </Row>
        </div>
        {/* Form Tabs start */}
      </div>
    )
  }
}

export default BookingPhotoGraphs;