import React, { Component } from 'react';
import './style.css';
import 'antd/dist/antd.css';
import { Table, Tag, Space, Select, Pagination } from 'antd';
// import Image from '../../helpers/images';  
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import SvcDetails_Tab from '../SvcDetails_Tab';
import SvcDetailsAddBooking from '../SvcDetailsAddBooking';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
 
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import SvcTableList from './SvcTableList';
const { Option } = Select;


var SvcListArray = [{ 'id': 1, 'svc_id': 'SVC ID', 'svc_name': 'SVC Name', 'master_name_id': 'Master ID & Name', 'add_booking': 'Add Booking', 'add_mangeruser': 'Add & Manage Users', 'wallet_amnt': 'Wallet Amount', 'manager_details': 'Manager Details', 'add_view': 'Add & View Settlement Invoice', 'city_zone': 'City / Zone' }];

class SvcList extends Component {

  constructor(props) {
console.log("svclist",props);
    super(props);

    this.state = {
      SvcListArray: SvcListArray,

      SvcItemArray: null,
      currentPage: 1,
      pageSize: 10,
      SvcDetails: false,
      svcList:true,svcid:null,
      city_search:null,
      servicecentre_name:null,
      action:null,
      addBooking:false
    }
  }
  SvcDetails = (data) => {
    console.log("data",data);
    this.setState({svcid:data.id})
console.log("hide svc list");
this.props.history.push('/svc/svc_list/svcdetails')
    this.setState({ SvcDetails: true });

    this.setState({ svcList: false });

  }

  svcDetailsHide = () => {
    this.setState({ SvcDetails: false,addBooking:false });

    this.setState({ svcList: true });
  }
  
  
  gotoAddUser=(data)=>{
    console.log("data",data);
  }
  renderImg =(key,value)=>{
    this.setState({action:value,svcid:key.id});
    this.setState({ SvcDetails: true,svcList:false });
  //   if(value='add_booking'){
    
  // }
  }
  showaddBooking=()=>{
    this.setState({addBooking:true,SvcDetails:false,svcList:false})
  }
  // receivegoBack=(data)=>{
  //   this.loadsvclist();
  //   console.log("receive goback",data);
  // }

  render() {
         return (
      <div>
        
        
        
          <div>


            {/* svc details start */}
             
             <Route exact  path={`${this.props.match.path}/`}  render={props=> (<SvcTableList {...props} parentProps={this.props} />)} />
             <Route  path={`${this.props.match.path}/svcdetails`} render={props=>(<SvcDetails_Tab parentProps={this.props}  {...props} sendsvcid={this.state.svcid} action={this.state.action} />)}/>

            {/* svc details end */}

          </div>


        
        {/* {this.state.addBooking==true?
       
         
      <SvcDetailsAddBooking />
     
      :''  
     
      } */}
        
      </div>
    )
  }
}

export default SvcList;