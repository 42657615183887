import React, {Component} from 'react';
import apiservices from '../../../helpers/apiservices';
import Images  from '../../../helpers/images';
import {Pagination, Select,Tooltip} from 'antd';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
var InRequestHeader = [{'sno' : 'S.No', 'title' : 'Title', 'description' : 'Amount Per Task' ,'amount':'Amount', 'edit' : 'Edit', 'delete' : 'Delete'}];


const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}

class BasePayout extends Component{


    constructor(props){

        super(props);


        this.state = {

            InRequestHeader : InRequestHeader,

            InRequestItem : [],
            openDialog:false,
            deleteID:null,
            deletePayout:false,
            editID:null,
            currentPage: 1,
            pageSize: 10,
            fields:{
                title:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
                description:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
                amount:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
              },

        }
    }
    itemRender = (current, type, originalElement) => {
      if (type === 'prev') {
        return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls" />Prev</a>;
      }
      if (type === 'next') {
        return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw" /></a>;
      }
      return originalElement;
    }

loadBasePayout =() =>{
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize};
    apiservices.listbasepayout(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                var limit=response.count;
                var total_items=this.state.pageSize;
                var calc=Math.ceil(limit/total_items)*total_items;
                  this.setState({InRequestItem:response.response,numbercount:calc})
                  console.log("base payout",response)
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
}
onchangePagination = (page, pageSize) => {
  console.log("page", page);
  this.setState({ currentPage: page }, function(){
      this.loadBasePayout();        
  });

  console.log("page size", pageSize);
}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadBasePayout(); 
  });
}
componentWillMount(){
    this.loadBasePayout();
}
addBasePayout =() =>{
    var fields=this.state.fields;
    fields['title'].value='';
    fields['description'].value='';
    fields['amount'].value='';
this.setState({openDialog:true,fields,responseMsg:''})
}
closeDialog=()=>{
    var fields=this.state.fields;
    fields['title'].value='';
    fields['title'].error='';
    fields['description'].value='';
    fields['description'].error='';
    fields['amount'].value='';
    fields['amount'].error='';
    
this.setState({openDialog:false,fields,responseMsg:''}) 
}
checkvalidations=(value,key)=>{
   
    var fields =this.state.fields;
    fields[key].value=value;
    console.log("value",value);
    var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
    // console.log(checkstatus);
    
    fields[key].error=checkstatus.msg;
    var getallkeys=Object.keys(fields);
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      this.setState({error:true})
      //errorblock
    }else{
      this.setState({error:false})

      //successblock
    }
    this.setState({fields});
  }
  popupSubmit =(data)=>{
      var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
        this.addBasePayoutDetails();
      }
      this.setState({fields});
  }
  addBasePayoutDetails =()=>{
     if(this.state.editID==null){
    const token=localStorage['accesstoken'];
    var obj={title:this.state.fields.title.value,description:this.state.fields.description.value,amount:this.state.fields.amount.value};
    apiservices.addbasepayout(obj,token)
          .then((response)=>{
            var self=this;
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                    self.loadBasePayout();
                    self.setState({openDialog:false})
                  }, 3000);
                   this.setState({responseMsg:response.message})
                }
              else{
                this.setState({responseMsg:response.message})
              }
          })
        }else{
            const token=localStorage['accesstoken'];
    var obj={id:this.state.editID,title:this.state.fields.title.value,description:this.state.fields.description.value,amount:this.state.fields.amount.value};
    apiservices.editpayout(obj,token)
          .then((response)=>{
            var self=this;
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                    self.loadBasePayout();
                    self.setState({openDialog:false,editID:null})
                  }, 3000);
                   this.setState({responseMsg:response.message})
                }
              else{
                this.setState({responseMsg:response.message})
              }
          })
        }
  }
  editPayout=(data)=>{
      console.log("edit id",data)
      var fields=this.state.fields;
      fields['amount'].value='';
      fields['description'].value='';
      fields['title'].value='';
      this.setState({editID:data.id,responseMsg:'',fields},function(){
          var fields=this.state.fields;
          fields['title'].value=data.title;
          fields['description'].value=data.description;
          fields['amount'].value=data.amount
          this.setState({fields,openDialog:true})
      })
  }
  deletePayout =(data)=>{
      this.setState({deletePayout:true,deleteID:data,deleteMsg:''})
  }
  deletePayoutDetails=()=>{
    const token=localStorage['accesstoken'];
    var obj={id:this.state.deleteID};
    apiservices.deletepayout(obj,token)
          .then((response)=>{
            var self=this;
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                    self.loadBasePayout();
                    self.setState({deletePayout:false,deleteID:null})
                  }, 3000);
                   this.setState({deleteMsg:response.message})
                }
              else{
                this.setState({deleteMsg:response.message})
              }
          })
        }
  
    render(){
        return(
            <div className="base_payouts_main">
            <div className="container">
{/*                     
            <div className="addbtnmain">
                  <button className="add_new_btn" onClick={()=>this.addBasePayout()}>Add New</button>
              </div> */}

<div className=" request defaulter-pilot">

                    <div className="driverActiveTable mt-0 defaulter-pilot">
                <table class="table table-hover">
                    <thead>
                        {this.state.InRequestHeader && this.state.InRequestHeader.map((item) => {
                            return(
                        <tr>
                         
                            <th>{item.sno}</th>
                            <th>{item.title}</th>
                            <th>{item.description}</th>
                            <th>{item.amount}</th>
                            <th>{item.edit}</th>
                            {/* <th>{item.delete}</th> */}
                            
                        </tr>
                        )
                    })}
                    </thead>
                    <tbody>
                    {this.state.InRequestItem &&this.state.InRequestItem.map((item,index) => {
                            return (<tr>
                                
                                <td>{index+1}</td>
                                <td> {item.title}</td>
                                <td>{item.description}</td>
                                <td>{item.amount}</td>
                                <td><Tooltip placement="top" title="Edit" color="white" key="#0B485A" ><img src={Images.EditImg} className="img-fluid app-img" onClick={()=>this.editPayout(item)}/></Tooltip></td>
                                {/* <td><Tooltip placement="top" title="Delete" color="white" key="#0B485A" ><img src={Images.delete_icon} className="img-fluid app-img" onClick={()=>this.deletePayout(item.id)}/></Tooltip></td> */}
                                
                            </tr>);
                        })}
                    </tbody>
                </table>
                {this.state.InRequestItem&&this.state.InRequestItem.length==0&&
                <div>No Records Found</div>

                }
               

            </div></div>
            

             </div>
             <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
             <Dialog  open={this.state.openDialog}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeDialog()} /></div>
                     <div className="modalcntntcls">
                     <div className="delete_text">
                         BasePayout
                     </div>
                     <div>
                     <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Title</label>
                          
                          <input type="text" className="form-control input_paused" name="title" value={this.state.fields.title.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['title'].error&&this.state.fields['title'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Description</label>
                          
                          <input type="text" className="form-control input_paused" name="description" value={this.state.fields.description.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['description'].error&&this.state.fields['description'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group basepayout_label">
                       <div class="form-inline position-relative">
                          <label>Amount</label>
                          
                          <input type="text" className="form-control input_paused" name="amount" value={this.state.fields.amount.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                            <span className="error_msg_adddealer">{this.state.fields['amount'].error&&this.state.fields['amount'].error}</span>
                         
                         
                        </div>
                       </div>
                       {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.responseMsg&&!this.state.responseMsg} className="delete_submit" onClick={()=>this.popupSubmit()}>Submit</button>
                       </div>
                    </div>
                     
                      
                      </div>
                        
                
                </Dialog>  
                <Dialog  open={this.state.deletePayout}  className="pending_approvals">
                    
                <div className="">
                   <div className="title_section">
                  <div className="add_approval_text">Remove BasePayout</div> <div className="approval_close" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}onClick={() => this.setState({deletePayout:false})} /></div>
                </div>
                <div className="approval_content">Are you sure you want to remove this basepayout?</div>
                {this.state.deleteMsg&&this.state.deleteMsg?
                 <div className="failure_msg">{this.state.deleteMsg&&this.state.deleteMsg}</div> :''
                   }
                 {this.state.deleteMsg&&!this.state.deleteMsg?
                 <div className="success_msg">{this.state.deleteMsg&&this.state.deleteMsg}</div> :''
                 }
                <div className="deletebtns_group">
                <div className="approval_btn_main ">
<button className="cancel_btn_main" onClick={()=>this.setState({deletePayout:false})}>No, Cancel</button>
</div>
<div className="approval_btn_main">
<button className="approval_btn" onClick={()=>this.deletePayoutDetails()}>Yes, Delete</button>
</div>
</div>
                    </div>

                </Dialog>
             </div>
        )
    }
}

export default BasePayout;