import React, { Component } from 'react';
import apiservice from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';

import { Upload, message, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import S3FileUpload from 'react-s3';
import apiservices from '../../helpers/apiservices';

import Aws from '../../helpers/awskeys';
import Images from '../../helpers/images';
const { Dragger } = Upload;
const { Option } = Select;
const config = {
  bucketName: Aws.bucketName,
  dirName: 'photos' /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};

function handleChange(value) {
  console.log(`selected ${value}`);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg';
  if (!isJpgOrPng) {
    // message.error('');
    alert('You can only upload JPG/JPEG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    // message.error('');
    alert('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class DriverPersonalDetails extends Component {
  constructor(props) {
    console.log('personal props', props);
    super(props);

    this.state = {
      upload_image: null,
      splitimage: [],
      filenewlist: [],
      filedriverimage: [],
      fields: {
        first_name: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        last_name: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        mobile_no: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'mobile',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        alternate_no: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'mobile',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        email: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'email',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        age: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'allowNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        address: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        zone_id: {
          validations: [
            {
              name: 'required',
            },
          ],
          error: null,
          value: '',
        },
        zone_name: {
          validations: [],
          error: null,
          value: '',
        },
        city: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'alphabetsOnly',
            },
          ],
          error: null,
          value: '',
        },
        pincode: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'zipcode',
            },
          ],
          error: null,
          value: '',
        },
        pan_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        shift: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'alphabetsOnly',
            },
          ],
          error: null,
          value: '',
        },
        total_task_month: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'allowNumaricOnly',
            },
          ],
          error: null,
          value: '',
        },

        account_name: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        account_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'allowNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        ifsc_code: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphaNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        swipe_machine_name: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        swipe_machine_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphaNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        contact_name: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        contact_relation: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphabetsOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        contact_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'mobile',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        contact_address: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        contact_city: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'alphabetsOnly',
            },
          ],
          error: null,
          value: '',
        },
        contact_pincode: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'Noemptyspace',
            },
            {
              name: 'zipcode',
            },
          ],
          error: null,
          value: '',
        },
        aadhar_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphaNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        license_number: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'alphaNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        upload_image: {
          validations: [],
          error: null,
          edit_images: null,
          value: '',
        },
        experience: {
          validations: [
            {
              name: 'required',
            },
            {
              name: 'allowNumaricOnly',
            },
            {
              name: 'Noemptyspace',
            },
          ],
          error: null,
          value: '',
        },
        upload_profile_image: {
          validations: [],
          error: null,
          edit_images: null,
          value: '',
        },
        image_url: {
          value: '',
        },
      },
    };
  }

  componentWillMount() {
    this.loadDeriverDerails();
    this.loadzoneDropdown();
  }
  loadzoneDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {
      search: '',
    };
    apiservices.listallactivezone(obj, token).then((response) => {
      if (!response.error) {
        this.setState({
          zone_id: response.result,
        });
        var fields = this.state.fields;
        if (!value) {
          // fields['zone_id'].value =
          //   response.result.length > 0 ? response.result[0].id : '';
          // this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({
          error_msg: response.message,
        });
      }
    });
  };
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({
        error: true,
      });
      //errorblock
    } else {
      this.setState({
        error: false,
      });

      //successblock
    }
    this.setState({
      fields,
    });
  };

  loadDeriverDerails = () => {
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.props.match.params.id,
    };
    console.log('obj', obj);

    this.props.sendid(obj.id);
    apiservice.driverDetails(obj, token).then((response) => {
      console.log('details', response);

      if (!response.error) {
        var fields = this.state.fields;
        fields['first_name'].value = response.response.first_name;
        fields['last_name'].value = response.response.last_name;
        fields['mobile_no'].value = response.response.mobile_no;
        fields['alternate_no'].value = response.response.alternate_no;
        fields['email'].value = response.response.email;
        fields['age'].value = response.response.age;
        fields['address'].value = response.response.address;
        fields['city'].value = response.response.city;
        fields['zone_id'].value = response.response.zone_id;
        fields['zone_name'].value = response.response.zone_name;
        fields['pincode'].value = response.response.pincode;
        fields['pan_number'].value = response.response.pan_number;
        fields['shift'].value = response.response.shift;
        fields['total_task_month'].value = response.response.total_task_month;
        // fields['mg_amount'].value=response.response.mg_amount;
        fields['account_name'].value = response.response.account_name;
        fields['account_number'].value = response.response.account_number;
        fields['ifsc_code'].value = response.response.ifsc_code;
        fields['swipe_machine_name'].value =
          response.response.swipe_machine_name;
        fields['swipe_machine_number'].value =
          response.response.swipe_machine_number;
        fields['contact_name'].value = response.response.contact_name;
        fields['contact_relation'].value = response.response.contact_relation;
        fields['contact_number'].value = response.response.contact_number;
        fields['contact_address'].value = response.response.contact_address;
        fields['contact_city'].value = response.response.contact_city;
        fields['contact_pincode'].value = response.response.contact_pincode;
        fields['aadhar_number'].value = response.response.aadhar_no;
        fields['license_number'].value = response.response.license_no;
        fields['upload_image'].edit_images = response.response.upload_doc;
        fields['experience'].value = response.response.experience;
        fields['image_url'].value = response.response.image_url;

        fields['upload_profile_image'].edit_images =
          response.response.image_url;
        var upload_image = this.state.fields.upload_image;
        var array =
          upload_image.edit_images && upload_image.edit_images.split(',');
        var filenewlist = this.state.filenewlist;
        if (array != '') {
          filenewlist = filenewlist.concat(
            array &&
              array.map((obj) => {
                return {
                  uid: new Date().getTime(),
                  name: obj,
                  exist: true,
                };
              }),
          );
        }
        var upload_profile_image = this.state.fields.upload_profile_image;
        var upload_array =
          upload_profile_image.edit_images &&
          upload_profile_image.edit_images.split(',');
        console.log('upload array', upload_array);
        var filedriverimage = this.state.filedriverimage;
        if (upload_array != '') {
          filedriverimage = filedriverimage.concat(
            upload_array &&
              upload_array.map((obj) => {
                return {
                  uid: new Date().getTime(),
                  name: obj,
                  exist: true,
                };
              }),
          );
          this.setState({
            filenewlist,
            filedriverimage,
          });
        }

        this.setState(
          {
            fields,
          },
          function () {},
        );
        // filedriverimage
      } else {
      }
      console.log('image', this.state.upload_image);
    });
  };

  UpdateDriverDetails = async () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({
        error: true,
      });
    } else {
      //successblock
      this.setState({
        error: false,
      });
      this.uploadDoc();
      this.uploadDriverImage();
    }
  };
  uploadDriverImage = async () => {
    var fileList = this.state.filedriverimage;
    var filterimage = this.state.filedriverimage.filter((obj) => !obj.exist);
    var existfilterimage = this.state.filedriverimage.filter(
      (obj) => obj.exist,
    );
    var temparray = [];
    if (filterimage.length == 0) {
      var existsimage = this.state.filedriverimage
        .map((obj) => obj.name)
        .join(',');
      var fields = this.state.fields;
      fields['upload_profile_image'].value = existsimage;
      this.setState({
        fields,
      });
      this.updateData();
      console.log('existsimage', existsimage);
      // this.updateData();
    } else {
      for (var i in filterimage) {
        var response = await S3FileUpload.uploadFile(filterimage[i], config);
        console.log('response', response);
        var fields = this.state.fields;
        fields['upload_profile_image'].value = response.location;
        this.setState(
          {
            fields,
          },
          function () {
            this.updateData();
          },
        );
      }
    }
  };
  uploadDoc = async () => {
    var fileList = this.state.filenewlist;
    var filterimage = this.state.filenewlist.filter((obj) => !obj.exist);
    var existfilterimage = this.state.filenewlist.filter((obj) => obj.exist);
    var temparray = [];
    if (filterimage.length == 0) {
      var existsimage = this.state.filenewlist.map((obj) => obj.name).join(',');
      var fields = this.state.fields;
      fields['upload_image'].value = existsimage;
      this.setState({
        fields,
      });
      this.updateData();
      // this.updateData(this.state.filenewlist.map((obj)=>obj.name).join(','));
    } else {
      for (var i in filterimage) {
        var response = await S3FileUpload.uploadFile(filterimage[i], config);
        console.log('response', response);
        temparray.push(response.location);
        if (filterimage.length - 1 == i) {
          // console.log();
          if (existfilterimage.length > 0) {
            // console.log("splitImages",JSON.parse(JSON.stringify(this.state.splitimage)))
            temparray = temparray.concat(
              existfilterimage.map((obj) => obj.name),
            );
          }
          var apiimagefile = temparray.join(',');
          console.log('apiimagefile', apiimagefile);
          // console.log("array",array);
          var array = apiimagefile.split(',');
          console.log('apiimagefile', apiimagefile);
          // this.setState({splitimage:array});
          var fields = this.state.fields;
          fields['upload_image'].value = array.join(',');
          this.setState({
            fields,
          });
          this.updateData();
          // this.updateData(array.join(','));
          // this.props.SendToContactNext(4,'DriverContactDetails', fields,apiimagefile);
        }
      }
    }
  };
  updateData = (image) => {
    console.log('image', image);
    const token = localStorage['accesstoken'];
    const fields = this.state;

    console.log(this.state.fields);
    var obj = {
      id: this.props.match.params.id,

      first_name: this.state.fields.first_name.value,
      last_name: this.state.fields.last_name.value,
      mobile_no: this.state.fields.mobile_no.value,
      alternate_no: this.state.fields.alternate_no.value,
      email: this.state.fields.email.value,
      age: this.state.fields.age.value,
      address: this.state.fields.address.value,
      city: this.state.fields.city.value,
      zone_id: this.state.fields.zone_id.value,
      pincode: this.state.fields.pincode.value,
      pan_number: this.state.fields.pan_number.value,
      shift: this.state.fields.shift.value,
      total_task_month: this.state.fields.total_task_month.value,
      // mg_amount : this.state.fields.mg_amount.value,
      account_name: this.state.fields.account_name.value,
      account_number: this.state.fields.account_number.value,
      ifsc_code: this.state.fields.ifsc_code.value,
      swipe_machine_name: this.state.fields.swipe_machine_name.value,
      swipe_machine_number: this.state.fields.swipe_machine_number.value,
      contact_name: this.state.fields.contact_name.value,
      contact_relation: this.state.fields.contact_relation.value,
      contact_number: this.state.fields.contact_number.value,
      contact_address: this.state.fields.contact_address.value,
      contact_city: this.state.fields.contact_city.value,
      contact_pincode: this.state.fields.contact_pincode.value,
      aadhar_no: this.state.fields.aadhar_number.value,
      license_no: this.state.fields.license_number.value,
      experience: this.state.fields.experience.value,
      image_url:
        this.state.fields.upload_profile_image.value ||
        this.state.fields.image_url.value,
      // upload_doc : this.state.fields.upload_image.value?this.state.fields.upload_image.value:image
      upload_doc: this.state.fields.upload_image.value,
    };
    console.log('update obj', obj);
    apiservice.updatedriverDetails(obj, token).then((response) => {
      console.log('update propr', response);
      if (!response.error) {
        console.log('update response', response);
        this.setState({
          responseMsg: response.message,
        });
        //  setTimeout(() => {
        //   this.props.history.push("/drivers/pilot_master/")
        // }, 3000);
        //  this.props.reload('true');
      } else {
        this.setState({
          responseMsg: response.message,
        });
      }
    });
    console.log('obj personal', obj);
  };
  deletefile = (data) => {
    console.log('upload_image', this.state.fields.upload_image.edit_images);
    let splitimage = this.state.splitimage.slice();
    splitimage.splice(splitimage.indexOf(data), 1);
    this.setState({
      splitimage,
    });
    var fields = this.state.fields;
    fields['upload_image'].edit_images = data;
    this.setState({
      fields,
    });
  };
  render() {
    var self = this;
    var temparray = [];
    const { zone_id } = this.state;

    const props = {
      name: 'file',
      accept: 'application/pdf,image/*',
      multiple: true,
      fileList: self.state.filenewlist,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <img src={Images.file_remove} className='file_remove' />,
      },
      beforeUpload: (file) => {
        console.log('filelist', self.state.filenewlist);
        var filenewlist = self.state.filenewlist;
        filenewlist.push(file);
        if (self.state.filenewlist.length < 3) {
          self.setState(
            {
              filenewlist,
            },
            function () {
              self.checkvalidations(filenewlist, 'upload_image');
              // self.props.sendImages&&self.props.sendImages(filenewlist);
            },
          );
        } else {
          filenewlist = filenewlist.splice(0, 2);
          self.setState({
            fileupload_error: 'Max Files Upload is 2',
          });
          self.setState({
            filenewlist,
          });
        }
        console.log('beforeuploadfile', file);
        // return true;
      },
      onChange: (info) => {
        var filenewlist = self.state.filenewlist;
        if (info.file.status == 'removed') {
          var findindex = filenewlist.findIndex(
            (obj) => obj.uid == info.file.uid,
          );
          filenewlist.splice(findindex, 1);
          self.setState({
            filenewlist,
          });
        }

        console.log('delete info', info);
      },
    };

    const props_data = {
      name: 'file',
      accept: 'application/pdf,image/*',
      multiple: false,
      fileList: self.state.filedriverimage,
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <img src={Images.file_remove} className='file_remove' />,
      },
      beforeUpload: (file) => {
        console.log('filelist', self.state.filedriverimage);
        var filedriverimage = self.state.filedriverimage;
        filedriverimage.push(file);
        if (self.state.filedriverimage.length < 2) {
          self.setState(
            {
              filedriverimage,
            },
            function () {
              self.checkvalidations(filedriverimage, 'upload_image');
              // self.props.sendImages&&self.props.sendImages(filenewlist);
            },
          );
        } else {
          filedriverimage = filedriverimage.splice(0, 1);
          self.setState({
            fileupload_error: 'Max Files Upload is 1',
          });
          self.setState({
            filedriverimage,
          });
        }
        console.log('beforeuploadfile', file);
        // return true;
      },
      onChange: (info) => {
        var filedriverimage = self.state.filedriverimage;
        if (info.file.status == 'removed') {
          var findindex = filedriverimage.findIndex(
            (obj) => obj.uid == info.file.uid,
          );
          filedriverimage.splice(findindex, 1);
          self.setState({
            filedriverimage,
          });
        }

        console.log('delete info', info);
      },
    };
    console.log('fields', this.state.fields);
    return (
      <div className='driver_details'>
        <div className='svc-detailes-all'>
          <div className='dealer-head'> 1. Personal Details </div>{' '}
          <div className='dealer-body'>
            <div className='form-group'>
              <div class='form-inline'>
                <label> First Name * </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='first_name'
                  value={this.state.fields.first_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['first_name'].error &&
                    this.state.fields['first_name'].error}{' '}
                </span>{' '}
              </div>
              <div class='form-inline'>
                <label> Last Name * </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='last_name'
                  value={this.state.fields.last_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['last_name'].error &&
                    this.state.fields['last_name'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label> Mobile Number * </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='mobile_no'
                  value={this.state.fields.mobile_no.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['mobile_no'].error &&
                    this.state.fields['mobile_no'].error}{' '}
                </span>{' '}
              </div>
              <div class='form-inline'>
                <label> Alternate Mobile Number </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='alternate_no'
                  value={this.state.fields.alternate_no.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['alternate_no'].error &&
                    this.state.fields['alternate_no'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>
            <div className='form-group'>
              <div className='form-inline'>
                <label> E - mail ID </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='email'
                  value={this.state.fields.email.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['email'].error &&
                    this.state.fields['email'].error}{' '}
                </span>{' '}
              </div>
              <div class='form-inline'>
                <label> Age </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='age'
                  value={this.state.fields.age.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['age'].error &&
                    this.state.fields['age'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>
            <div className='form-group'>
              <label> Address </label>
              <textarea
                row='5'
                className='form-control'
                name='address'
                value={this.state.fields.address.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }>
                {' '}
              </textarea>{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['address'].error &&
                  this.state.fields['address'].error}{' '}
              </span>{' '}
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label> Town / City </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='city'
                  value={this.state.fields.city.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['city'].error &&
                    this.state.fields['city'].error}{' '}
                </span>{' '}
              </div>{' '}
              <div class='form-inline position-relative'>
                <label> Zone Name </label>
                <Select
                  name='zone_id'
                  value={
                    this.state.fields.zone_name.value == null
                      ? ''
                      : [this.state.fields.zone_id.value]
                  }
                  onChange={(value) => {
                    this.checkvalidations(value, 'zone_id');
                    this.checkvalidations(value, 'zone_name');
                  }}>
                  {' '}
                  {zone_id &&
                    zone_id.length > 0 &&
                    zone_id.map((item) => {
                      return (
                        <Option value={item.id}> {item.zone_name} </Option>
                      );
                    })}{' '}
                </Select>{' '}
                <img
                  alt=''
                  src={Images.dropdown}
                  className='img-fluid dropdown-img'
                />
                <span className='error_msg_adddealer'>
                  {' '}
                  {this.state.fields['zone_id'].error &&
                    this.state.fields['zone_id'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label> Pincode </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='pincode'
                  value={this.state.fields.pincode.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['pincode'].error &&
                    this.state.fields['pincode'].error}{' '}
                </span>{' '}
              </div>{' '}
              <div class='form-inline'>
                <label> PAN Number </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='pan_number'
                  value={this.state.fields.pan_number.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['pan_number'].error &&
                    this.state.fields['pan_number'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label> Shift </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='shift'
                  value={this.state.fields.shift.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['shift'].error &&
                    this.state.fields['shift'].error}{' '}
                </span>{' '}
              </div>{' '}
              <div class='form-inline'>
                <label> Total Task for Month </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='total_task_month'
                  value={this.state.fields.total_task_month.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['total_task_month'].error &&
                    this.state.fields['total_task_month'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>{' '}
            <div className='form-group'>
              <div class='form-inline'>
                <label> Experience </label>{' '}
                <input
                  type='text'
                  className='form-control'
                  name='experience'
                  value={this.state.fields.experience.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['experience'].error &&
                    this.state.fields['experience'].error}{' '}
                </span>{' '}
              </div>{' '}
            </div>{' '}
            <div className='form-group'>
              <label>
                Upload Driver Profile{' '}
                <span
                  style={{
                    color: 'red',
                    marginLeft: 10,
                  }}>
                  {' '}
                  {this.state.fileupload_error}{' '}
                </span>{' '}
              </label>{' '}
              <div
                style={{
                  marginTop: 30,
                }}>
                <Dragger {...props_data} className='driver_image'>
                  <div> Upload Driver Image </div>{' '}
                </Dragger>{' '}
                <span className='error_msg_driver'>
                  {' '}
                  {this.state.fields['upload_profile_image'].error &&
                    this.state.fields['upload_profile_image'].error}{' '}
                </span>
                <> </>{' '}
              </div>{' '}
            </div>{' '}
          </div>{' '}
        </div>
        <div className='dealer-head'> 2. Bank Details </div>{' '}
        <div className='dealer-body'>
          <div className='form-group'>
            <div class='form-inline'>
              <label> Account Name </label>{' '}
              <input
                type='text'
                className='form-control'
                name='account_name'
                value={this.state.fields.account_name.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['account_name'].error &&
                  this.state.fields['account_name'].error}{' '}
              </span>{' '}
            </div>
            <div class='form-inline'>
              <label> Account Number </label>{' '}
              <input
                type='text'
                className='form-control'
                name='account_number'
                value={this.state.fields.account_number.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['account_number'].error &&
                  this.state.fields['account_number'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>
          <div className='form-group'>
            <div class='form-inline'>
              <label> IFSC Code </label>{' '}
              <input
                type='text'
                className='form-control'
                name='ifsc_code'
                value={this.state.fields.ifsc_code.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['ifsc_code'].error &&
                  this.state.fields['ifsc_code'].error}{' '}
              </span>{' '}
            </div>
            <div class='form-inline'>
              <label> Swipe Machine Name </label>{' '}
              <input
                type='text'
                className='form-control'
                name='swipe_machine_name'
                value={this.state.fields.swipe_machine_name.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['swipe_machine_name'].error &&
                  this.state.fields['swipe_machine_name'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>
          <div className='form-group'>
            <div class='form-inline'>
              <label> Swipe Machine Serial Number </label>{' '}
              <input
                type='text'
                className='form-control'
                name='swipe_machine_number'
                value={this.state.fields.swipe_machine_number.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['swipe_machine_number'].error &&
                  this.state.fields['swipe_machine_number'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>{' '}
        </div>
        <div className='dealer-head'> 3. Contact Person Details </div>{' '}
        <div className='dealer-body'>
          <div className='form-group'>
            <div class='form-inline'>
              <label> Contact Person Name </label>{' '}
              <input
                type='text'
                className='form-control'
                name='contact_name'
                value={this.state.fields.contact_name.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['contact_name'].error &&
                  this.state.fields['contact_name'].error}{' '}
              </span>{' '}
            </div>
            <div class='form-inline'>
              <label> Contact Person Relation </label>{' '}
              <input
                type='text'
                className='form-control'
                name='contact_relation'
                value={this.state.fields.contact_relation.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['contact_relation'].error &&
                  this.state.fields['contact_relation'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>
          <div className='form-group'>
            <div class='form-inline'>
              <label> Contact Person Mobile Number </label>{' '}
              <input
                type='text'
                className='form-control'
                name='contact_number'
                value={this.state.fields.contact_number.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['contact_number'].error &&
                  this.state.fields['contact_number'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>
          <div className='form-group'>
            <label> Address </label>
            <textarea
              row='5'
              className='form-control'
              name='contact_address'
              value={this.state.fields.contact_address.value}
              onChange={(e) =>
                this.checkvalidations(e.target.value, e.target.name)
              }>
              {' '}
            </textarea>{' '}
            <span className='error_msg_driver'>
              {' '}
              {this.state.fields['contact_address'].error &&
                this.state.fields['contact_address'].error}{' '}
            </span>{' '}
          </div>{' '}
          <div className='form-group'>
            <div class='form-inline'>
              <label> Town / City </label>{' '}
              <input
                type='text'
                className='form-control'
                name='contact_city'
                value={this.state.fields.contact_city.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['contact_city'].error &&
                  this.state.fields['contact_city'].error}{' '}
              </span>{' '}
            </div>
            <div class='form-inline'>
              <label> Pincode </label>{' '}
              <input
                type='text'
                className='form-control'
                name='contact_pincode'
                value={this.state.fields.contact_pincode.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['contact_pincode'].error &&
                  this.state.fields['contact_pincode'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>{' '}
          <div className='form-group'>
            <div class='form-inline'>
              <label> Aadhar Number </label>{' '}
              <input
                type='text'
                className='form-control'
                name='aadhar_number'
                value={this.state.fields.aadhar_number.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['aadhar_number'].error &&
                  this.state.fields['aadhar_number'].error}{' '}
              </span>{' '}
            </div>
            <div class='form-inline'>
              <label> License Number </label>{' '}
              <input
                type='text'
                className='form-control'
                name='license_number'
                value={this.state.fields.license_number.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }
              />{' '}
              <span className='error_msg_driver'>
                {' '}
                {this.state.fields['license_number'].error &&
                  this.state.fields['license_number'].error}{' '}
              </span>{' '}
            </div>{' '}
          </div>{' '}
          <div className='form-group'>
            <label>
              Upload Aadhar Card and Driving License{' '}
              <span
                style={{
                  color: 'red',
                  marginLeft: 10,
                }}>
                {' '}
                {this.state.fileupload_error}{' '}
              </span>{' '}
            </label>{' '}
            <div
              style={{
                marginTop: 30,
              }}>
              <Dragger {...props}>
                <div>
                  <button className='upload_btn'> Upload Files </button>{' '}
                </div>{' '}
                <p className='ant-upload-text'> </p>{' '}
                <p className='ant-upload-hint'>
                  Files can be in .jpg or.pdf format{' '}
                </p>{' '}
              </Dragger>
              <>
                {' '}
                {/* {this.state.splitimage&&this.state.splitimage.length>0&&this.state.splitimage.map((obj)=>{
            return(
        <div className="upload_miandiv"><img src={Images.file} className="file_display"/>{obj} <img src={Images.file_remove} className="file_remove" onClick={()=>this.deletefile(obj)}/></div>
            )
            
          })}  */}{' '}
              </>{' '}
            </div>{' '}
          </div>{' '}
        </div>
        <button
          disabled={this.state.responseMsg}
          className='btn btn-dark-blue next-button'
          onClick={() => this.UpdateDriverDetails()}>
          Save Changes{' '}
        </button>{' '}
        {this.state.responseMsg && this.state.responseMsg ? (
          <div className='failure_msg'> {this.state.responseMsg} </div>
        ) : (
          ''
        )}{' '}
        {this.state.responseMsg && !this.state.responseMsg ? (
          <div className='success_msg'> {this.state.responseMsg} </div>
        ) : (
          ''
        )}{' '}
      </div>
    );
  }
}

export default DriverPersonalDetails;
