import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import moment from 'moment';
import ValidationLibrary from '../../../helpers/validationfunction';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";

class BookingPilotDetails extends Component 
{

    constructor(props){

        super(props);

        this.state = {
            pilotDetails:null,
            loading:false,
            addpilotExtradata:false,
            customerDetails:null,
            fields:{
                ticket_complaints:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
              },

        }
    }
    loadCustomerDetails=(data)=>{
        this.setState({loading:true})
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
        console.log("booking svc details obj", obj);   
        apiservices.bookingdetailsview(obj,token)
              .then((response)=>{
                  this.setState({loading:false});
                  if(!response.error){
                      this.setState({pilotDetails:response.response.driverDetails,customerDetails:response.response&&response.response.customersDetails})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    componentWillMount(){
      this.loadCustomerDetails();
    }
    checkvalidations=(value,key)=>{

        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
      
          //successblock
        }
        this.setState({fields});
      }
      SubmitData=(data)=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          if(data=='complaints'){
              this.addPilotComplaints();
          }
          if(data=='tickets'){
              this.addPilotTickets();
          }
        }
        this.setState({fields});
  
      }
      openDialog=(data)=>{
var fields=this.state.fields;
fields['ticket_complaints'].value='';
fields['ticket_complaints'].error='';
        this.setState({dialogDisplay:data,addpilotExtradata:true,fields,responseMsg:''})
    }
    addPilotComplaints=()=>{
        var self=this;
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,complaint:this.state.fields.ticket_complaints.value,assign_id:this.props.match.params.assignid};
        console.log("add complaints", obj);   
        apiservices.addbookingcomplaints(obj,token)
              .then((response)=>{
                //   this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                        // self.ActivePickupList();
                        self.setState({addpilotExtradata:false})
                      }, 3000);
                    this.setState({responseMsg:response.message})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    addPilotTickets=()=>{
        var self=this;
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,ticket:this.state.fields.ticket_complaints.value,assign_id:this.props.match.params.assignid};
        console.log("add complaints", obj);   
        apiservices.addbookingtickets(obj,token)
              .then((response)=>{
                //   this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                        // self.ActivePickupList();
                        self.setState({addpilotExtradata:false})
                      }, 3000);
                    this.setState({responseMsg:response.message})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    closeTicketComplaints=()=>{
      var fields=this.state.fields;
      fields['ticket_complaints'].value='';
      this.setState({addpilotExtradata:false,fields,responseMsg:''})
    }
render(){
const {pilotDetails,customerDetails} =this.state;
   return(
       

   <div>
               <div className="booking_pickup pilot-booking">
               {this.state.loading==true&&
    <CircularProgress />}
                   {/* <button className="btn btn-linear-color remind">Remind Late</button>
                   <img src={images.edit_icon} className="img-fluid edit-details"/> */}

                 <div className="pickup-details pick-drops">
                     <div className="pickup-details-booking">
                     <div className="pick-whole">
                        <div className="customer_label">Name</div>
                <div className="customer_detail">{pilotDetails&&pilotDetails.driver_first_name} {pilotDetails&&pilotDetails.driver_last_name}</div>
                     </div>

                     <div className="pick-whole">
                        <div className="customer_label">Contact Number</div>
                        <div className="customer_detail"><span className="mr-2">{pilotDetails&&pilotDetails.driver_mobile_no}</span> <span>{pilotDetails&&pilotDetails.driver_alternate_no}</span></div>
                     </div>


                     <div className="pick-whole">
                        <div className="customer_label">Driver ID</div>
                <div className="customer_detail">{pilotDetails&&pilotDetails.driver_email}</div>
                     </div>


                     <div className="pick-whole">
                        <div className="customer_label">Live Location</div>
                <div className="customer_detail">{pilotDetails&&pilotDetails.driver_address}</div>
                     </div>


                     <div className="pick-whole">
                        <div className="customer_label">Date & Time Slot</div>
                <div className="customer_detail"> <span className="mr-2">{moment(pilotDetails&&pilotDetails.slot_day).format('YYYY/MM/DD')}</span> &    <span>{pilotDetails&&pilotDetails.slot_time}</span></div>
                     </div>


{/* 
                     <div className="pick-whole">
                        <div className="customer_label">ETA</div>
                        <div className="customer_detail"> 10:00:00</div>
                     </div> */}


                     <div className="pick-whole">
                        <div className="customer_label">Other</div>
                        <div className="customer_detail">
                            <ul className="customer-other">
                                <li>
                                    <div className="other-part" style={{opacity:customerDetails&&customerDetails.status_id!=3?1:0.5}} onClick={()=>customerDetails&&customerDetails.status_id!=3?this.openDialog('complaints'):''}>Add Complaint</div>
                                    <div className="other-part" style={{opacity:customerDetails&&customerDetails.status_id!=3?1:0.5}} onClick={()=>customerDetails&&customerDetails.status_id!=3?this.openDialog('tickets'):''}>Add Ticket</div>
                                    {/* <div className="other-part">Add SVC Complaint</div> */}
                                </li>
                                {/* <li>
                                   
                                     <div className="other-part">Add Penality</div> 
                                </li>  */}
                                {/* <li>
                                    <div className="other-part">Text Pilot</div>
                                    <div className="other-part">Incoming Call Log</div>
                                </li> */}
                            </ul>
                        </div>
                     </div>
                     </div>


                     <div className="upload-pickup">
                              <div className="upload-img">
                                  <img src={pilotDetails&&pilotDetails.driver_image_url?pilotDetails&&pilotDetails.driver_image_url:images.default_image} style={{width:205,height:198,borderRadius:7}}/>
                              </div>
                     </div>
                 </div>
               </div>
               <Dialog  open={this.state.addpilotExtradata}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.closeTicketComplaints()} /></div>
                    {this.state.dialogDisplay=='complaints'&&
                    <div className="modalcntntcls">
                   <div className="delete_text">
                       Add Complaints
                   </div>
                   <div>
                      
                         <div className="form-group">
                           <label className="label_paused">
                           Complaints
                           </label>
                         <input type="text" maxLength="300" className="form-control input_paused" name="ticket_complaints" value={this.state.fields.ticket_complaints.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                         <span className="error_msg_adddealer">{this.state.fields['ticket_complaints'].error&&this.state.fields['ticket_complaints'].error}</span> 
                       </div>
                     {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button className="delete_submit" onClick={()=>this.SubmitData('complaints')}>Submit</button>
                       </div>
                  </div>
                   
                    
                    </div>
    }
    {this.state.dialogDisplay=='tickets'&&
     <div className="modalcntntcls">
     <div className="delete_text">
         Add Ticket
     </div>
     <div>
        
     <div className="form-group">
           <label className="label_paused">
           Ticket
           </label>

           <input type="text" maxLength="300" className="form-control input_paused" name="ticket_complaints" value={this.state.fields.ticket_complaints.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
           <span className="error_msg_adddealer">{this.state.fields['ticket_complaints'].error&&this.state.fields['ticket_complaints'].error}</span> 
           
       </div>
       {this.state.responseMsg&&this.state.responseMsg?
   <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
     }
   {this.state.responseMsg&&!this.state.responseMsg?
   <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
   }
         <div className="btn_main">
             <button className="delete_submit" onClick={()=>this.SubmitData('tickets')}>Submit</button>
         </div>
    </div>
     
      
      </div>}
                    </Dialog> 
</div>

 )
    }
    componentDidMount(){
        this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
      }
}
export default BookingPilotDetails;