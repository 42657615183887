import React, {Component} from 'react';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import { Table, Tag, Pagination, Select} from 'antd';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { DatePicker } from 'antd';

import './styles.css';
const dateFormat = 'YYYY-MM-DD';

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var AccidentListHeader = [{'id' : 1, 'driver_name' : 'Driver Name', 'mobile_number': 'Mobile Number', 'booking_id' : 'Booking ID', 'svc_name' :'SVC Name',  'svc_id' : 'SVC ID','customer_name':'Customer Name','vehicle_number':'Vehicle Number','reason' : 'Reason', 'close' : 'Close Ticket'}];
class Accidents extends Component{
    constructor(props){
        console.log("high props",props);
        super(props);
        this.state = {
            AccidentListHeader : AccidentListHeader,
            AccidedntList : [],  
            currentPage : 1,
            pageSize:10,
            driver_name:null,
            fromDate:null,
            toDate:null,
            openaccidents:false,
            fields:{
                BookingList:{validations:[{name:'required'}],error:null,value:''},
                driver_name:{validations:[{name:'required'}],error:null,value:''},
                svc_name:{validations:[{name:'required'}],error:null,value:''},
                svc_id:{validations:[{name:'required'}],error:null,value:''},
                reason:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
            },
            accidentfields:{
              status:{validations:[],error:null,value:''},
            },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadAccidentList();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadAccidentList(); 
        });
      }
      loadAccidentList=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver_name,status:this.state.accidentfields.status.value,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("actve", obj);     
        apiservices.listdriveraccidents(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({AccidedntList:response.response, numbercount:calc})
                      console.log("ticket high reponse", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    componentWillReceiveProps(props){
        console.log("high will props");
        if(props.reload){
            this.loadAccidentList();
        }
    }
    componentWillMount(){
        this.loadAccidentList();
    }
    closeTicket=(data)=>{
        
        const token=localStorage['accesstoken'];
        var obj={id:data};
        apiservices.closedriveraccidents(obj,token)
              .then((response)=>{
                  if(!response.error){
                    setTimeout(() => {
                      this.loadAccidentList();
                      this.setState({responseMsg:''})
                    },2000);
                    this.setState({responseMsg:response.message},function(){
                        
                    });

                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
console.log("close ticket",data);
    }
    AddAccidents=()=>{

        this.setState({openaccidents:true,responseMsg:'',successMsg:''});
        this.loadBookingDropdown();
    }
    loadBookingDropdown=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.listactivebookingdrop(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    this.setState({BookingListDrop:response.response})
                    var fields=this.state.fields;
                    // if(!value){
                      if(response.response.length>0){
                        fields['driver_name'].value=response.response[0].driver_first_name +" "+ response.response[0].driver_Last_name ;
                        fields['svc_name'].value=response.response[0].centre_name;
                        fields['svc_id'].value=response.response[0].svc_center_id
                      }
                     
                    fields['BookingList'].value=response.response.length>0?JSON.stringify(response.response[0]):'';            
                    this.setState({fields})
                    // }
                    console.log("booking dropdown",response);
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
    checkvalidations=(value,key)=>{
   
        var fields =this.state.fields;
        fields[key].value=value;
        console.log("value",value);
        if(key=='BookingList'){
          var parseddata=JSON.parse(value);
          fields['driver_name'].value=parseddata.driver_first_name +" "+ parseddata.driver_Last_name;
          fields['svc_name'].value=parseddata.centre_name
          fields['svc_id'].value=parseddata.svc_center_id
          console.log("Booking list",value)
        }
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      submitPopup=()=>{
        // this.props.sendpickupdetails(2);
        var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
      this.SubmitAccidents();
      }
      this.setState({fields});
      }
      SubmitAccidents =() =>{
        var self=this;
        const token=localStorage['accesstoken'];
        var obj={id:JSON.parse(this.state.fields.BookingList.value).id,reason:this.state.fields.reason.value};
        console.log("add accident", obj); 
        apiservices.addaccidents(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    setTimeout(() => {
                      self.loadBookingDropdown();
                      var fields=self.state.fields;
                      fields['reason'].value=''
                      self.setState({fields,openaccidents:false});
                      this.loadAccidentList();
                    }, 3000);
                     this.setState({successMsg:response.message})
                    }
                  else{
                    this.setState({successMsg:response.message})
                  }
              })
      }
      checkfiltervalidations =(data)=>{
        console.log("priority",data);
       
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadAccidentList();
        })
      }
      checkpriorityfilter =(data)=>{
        var accidentfields=this.state.accidentfields;
        accidentfields['status'].value=data;
        this.setState({accidentfields},function(){
          this.loadAccidentList();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadAccidentList();
        })
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadAccidentList();
        });
      }
render(){
const {BookingListDrop,status} =this.state;
    return(
        <div className="accident_main">
              <div className="add_acc_main">
                {/* <div className="driverButton" onClick={()=>this.AddAccidents()}>
                <img src={Images.add_img}/>Add Ticket</div>
                                         */}
                                         <button className="driverButton add_aac" onClick={()=>this.AddAccidents()}><img src={Images.add_img}/> Add Accident</button>
              </div>
              <div className="search-header booking-mishaps" style={{marginTop:10}}>
                         <div className="row">
                             <div className="col-md-3">
                                   <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for Driver" className="form-control" name="driver_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Images.green_search} className="img-fluid"/>
                                  </div> 
                             </div>
                             <div className="col-md-9">
                             <div className="payment-dropdown trends_drop"> <span className="payment">Status :</span> 
                             <Select value={this.state.accidentfields.status.value}  onChange={(value) => this.checkpriorityfilter(value)} name="status" >
                             <Option value="">All</Option>
                             <Option value="1">Open</Option>
                             <Option value="0">Closed</Option>
                            
                           </Select> 
                            </div>
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                    {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                    <div className="table-list">


                        <div class="table-responsive">

                        
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.AccidentListHeader &&this.state.AccidentListHeader.length>0&& this.state.AccidentListHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.driver_name}</th>
                                                <th>{item.mobile_number}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.customer_name}</th>
                                                <th>{item.vehicle_number}</th>
                                                <th>{item.reason}</th>
                                                <th>{item.close}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                {this.state.AccidedntList&&this.state.AccidedntList.length>0&&this.state.AccidedntList.map((item,index) => {

                                        return(
                                            <tr>
                                                 <td>{item.driver_first_name} {item.driver_Last_name}</td>
                                                <td>{item.driver_mobile_no}</td>
                                                <td>{item.booking_id}</td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.svc_center_id}</td>
                                                <td>{item.customer_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>{item.reason}</td>
                                                <td><img disabled src={item.status==0?Images.approvel:Images.cancel_new} onClick={()=>item.status!=0?this.closeTicket(item.id):''}/></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {
                                this.state.AccidedntList&&this.state.AccidedntList.length==0&&
                                <div className="no_records_found">No Records Found</div>
                            }

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />


<Dialog  open={this.state.openaccidents}  className="ticketmainpage">
                    <div className="modalclosecls" ><img src={Images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.setState({openaccidents:false})} /></div>
                    <div className="modalcntntcls">
                   
                  <div className="add_ticket_text">Add Accident</div> 
                  <div className="addbooking_main">
            <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Booking ID</label>
                          
                          <Select value={[(this.state.fields.BookingList.value)]}  onChange={(value) => this.checkvalidations(value,'BookingList')} name="BookingList" >
                          {BookingListDrop&&BookingListDrop.length>0&&BookingListDrop.map((item)=>{
                              return(
                              <Option value={JSON.stringify(item)}>{item.id}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid ticket_dropdown_img"/> 
                           
           <span className="error_msg_adddealer">{this.state.fields['BookingList'].error&&this.state.fields['BookingList'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>Driver Name</label>
                          
                          <input type="text" className="form-control input_paused" name="driver_name" value={this.state.fields.driver_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                           
                            <span className="error_msg_adddealer">{this.state.fields['driver_name'].error&&this.state.fields['driver_name'].error}</span> 
                         
                        </div>
                       </div>

                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>SVC Name</label>
                          
                          <input type="text" className="form-control input_paused" name="svc_name" value={this.state.fields.svc_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/> 
                            <span className="error_msg_adddealer">{this.state.fields['svc_name'].error&&this.state.fields['svc_name'].error}</span> 
                         
                         
                        </div>
                       </div>
                       <div className="form-group">
                       <div class="form-inline position-relative">
                          <label>SVC ID</label>
                          
                          <input type="text" className="form-control input_paused" name="svc_id" value={this.state.fields.svc_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} readOnly/>
                            <span className="error_msg_adddealer">{this.state.fields['svc_id'].error&&this.state.fields['svc_id'].error}</span>
                         
                         
                        </div>
                       </div>
                       <div className="form-group reason_box">
                          <label>Reason</label>
                          
                          <textarea row="5" col="4"   maxLength="300" name="reason" className="form-control reason_box" value={this.state.fields.reason.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} ></textarea>
                            <span className="error_msg_adddealer">{this.state.fields['reason'].error&&this.state.fields['reason'].error}</span>
                         
                         
                       </div>
                       {this.state.successMsg&&this.state.successMsg?
                 <div className="failure_msg">{this.state.successMsg&&this.state.successMsg}</div> :''
                   }
                 {this.state.successMsg&&!this.state.successMsg?
                 <div className="success_msg">{this.state.successMsg&&this.state.successMsg}</div> :''
                 }
                       <div className="btn_main">
                           <button disabled={this.state.successMsg&&!this.state.successMsg} className="delete_submit" onClick={()=>this.submitPopup()}>Submit</button>
                       </div>
                       </div>
                    </div>
                </Dialog>
             </div>

         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default Accidents;