import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';

const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;
const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
var monthlyHeader = [{'id' : 1,  'drivername_id' : 'Driver Name & ID', 'system_added': 'System Added & Other added Conveyance', 'deduction' : 'Deductions', 'early_morning_incentive' : 'Early Morning & Late Night Incentive',  'login&other_incentive' : 'Login & Other Incentives',  'base_mg_amount' : 'Other Incentives', 'present_day' : 'Present / Total Days', 'tds' : 'TDS', 'total_payout' : 'Total Payout'}];


class MonthlyPayment extends Component{

    constructor(props){

        super(props);

        this.state = {
            monthlyHeader : monthlyHeader,

            bookingPaymentList : [],
             
            currentPage : 1,
            pageSize:10,
            fromDate:null,
            toDate:null,
            openDialog:false,
            popupDisplay:false,
            updateobj:null,
            fields:{
                amount:{validations:[{name:'allowNumaricOnly'},{name:'required'}],error:null,value:''},
            }


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadMonthlyPayments =()=>{
        const token = localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,id:this.props.match.params.id,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("obj", obj);
          this.props.sendid(obj.id);
          apiservices.llistDriverMonthlyPaymentsById(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({bookingPaymentList:response.response, numbercount:calc})
                        console.log("booking payment", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
        this.props.sendid(this.props.match.params.id);
          this.loadMonthlyPayments();
      }
      
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadMonthlyPayments();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadMonthlyPayments(); 
        });
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadMonthlyPayments();
        });
      }
      datepickervalidations =(data)=>{
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadMonthlyPayments();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadMonthlyPayments();
        })
      }
      
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
              <div className="search-header booking-mishaps" style={{marginTop:0}}>
              <div className="row">
                             <div className="col-md-3">
                                  {/* <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div> */}
                             </div>
                             <div className="col-md-9">
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            allowClear={false}
                                            onChange={(value) => this.datepickervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.monthlyHeader && this.state.monthlyHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                 <th>{item.drivername_id}</th>
                                                <th>{item.system_added}</th>
                                                <th>{item.deduction}</th>
                                                <th>{item.early_morning_incentive}</th>
                                                {/* <th>{item.login_other_incentive}</th> */}
                                                 <th>{item.base_mg_amount}</th>
                                                 <th>{item.present_day}</th>
                                                 <th>{item.tds}</th>
                                                 <th>{item.total_payout}</th>
                                                   
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                                                    {this.state.bookingPaymentList && this.state.bookingPaymentList.slice((this.state.currentPage-1) * this.state.pageSize, (this.state.currentPage-1) * this.state.pageSize + this.state.pageSize).map((item) => {

                                    return(
                                        <tr>
                                            <td>{item.driver_first_name} {item.driver_last_name}
                                            <br/>
                                            <span className="driver_id">ID{item.driver_id}</span>
                                            </td>
                                            <td><span style={{color:'#0B485A'}}>{item.conveyance}</span><br/>
                                            <span className="driver_id" style={{color:'#0695AF'}}>{item.added_conveyance}</span>
                                            </td>
                                            <td>Rs.{item.deductions} </td>
                                            {/* <td><span className="driver_id" style={{color:'#0695AF'}}>{item.early_morning_incentive}</span>
                                                <span style={{color:'#0B485A'}}>{item.late_night_incentive}</span><br/>
                                            
                                            </td> */}
                                             <td><span style={{color:'#0695AF'}}>Rs.{item.early_morning_incentive}</span><br/>
                                            <span className="driver_id"  style={{color:'#0B485A'}}>Rs.{item.late_night_incentive}</span>
                                            </td>
                                            <td>Rs.{item.other_incentive}</td>
                                        <td>{item.present_days}/{item.total_days}</td>
                                             <td>{item.tds}</td> 
                                            <td>Rs.{item.total_payout}</td> 
                                            
                                        </tr>
                                    )
                                    })}
                                </tbody>
                            </table>
                            {}
                            {this.state.bookingPaymentList&&this.state.bookingPaymentList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                      {/* <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />  */}
    </div>
    
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default MonthlyPayment;