import React, {Component} from 'react';
import './style.css';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
  
import AddDealers from '../../Pages/AddDealer';
import AddService from '../../Pages/AddService';
import SubMenu from '../../Component/SubMenu';
class ServiceCenter_Dm1 extends Component{

    constructor(props){
         
        super(props);

        this.state = {

         



        }
    }



    render(){

           return(

               <div>
                  
                  {/* <SubMenu/>      */}

<div class="container clearfix">
                    
{/* Form Tabs start */}

  <div class="form-tabs">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={12} className="p-0">
      <Nav variant="pills" className="flex-row serive_center"  selectedIndex={this.state.selectedIndex}
        onSelect={this.handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="first">Add Dealer Master</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Add Service Centre</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={12}  className="p-0">
      <Tab.Content>
        <Tab.Pane eventKey="first">
        <AddDealers {...this.props}/>           

        </Tab.Pane>
        <Tab.Pane eventKey="second">
           <AddService {...this.props}/>
 
        
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container>
</div>
{/* Form Tabs start */}

                </div>
                  
               </div>
           ) 
    }
    
}
 
export default ServiceCenter_Dm1;

    
