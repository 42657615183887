const Images = {
  searchImage: require('../Images/search2x.png'),
  notificationImage: require('../Images/notification2x.png'),
  profileImage: require('../Images/profile2x.png'),
  dashboardImage: require('../Images/dashboard - active2x.png'),
  activedriver: require('../Images/drivers active2x.png'),
  call: require('../Images/call icon2x.png'),
  cancle: require('../Images/delete icon2x.png'),
  editicon: require('../Images/edit icon2x.png'),
  pause: require('../Images/pause2x.png'),
  plotchange: require('../Images/pilot change icon2x.png'),
  default_image: require('../Images/user Image2x.png'),
  driver_card: require('../Images/drivers active card2x.png'),
  wallet_card: require('../Images/low value walletscard 2x.png'),
  money_card: require('../Images/outstanding payouts card2x.png'),
  onlinecard: require('../Images/pending postpaid paymentcard2x.png'),
  svc_card: require('../Images/SVC overall payouts card2x.png'),
  wallet2_card: require('../Images/wallet amount card2x.png'),
  rupee_icon: require('../Images/rupee2x.png'),
  grey_booking: require('../Images/bookings - grey2x.png'),
  white_booking: require('../Images/bookings - white2x.png'),
  grey_driver: require('../Images/drivers - grey2x.png'),
  svc_white: require('../Images/SVC - white2x.png'),
  svc_grey: require('../Images/SVC - grey2x.png'),
  user_grey: require('../Images/users - grey2x.png'),
  user_white: require('../Images/users - white2x.png'),
  paymeny_grey: require('../Images/payments - grey2x.png'),
  payment_white: require('../Images/payments - white2x.png'),
  ticket_grey: require('../Images/tickets - grey2x.png'),
  ticket_white: require('../Images/tickets - white@2x.png'),
  gery_dashboard: require('../Images/dashboard - grey@2x.png'),
  arrow_white: require('../Images/arrow - white@2x.png'),
  arrow_green: require('../Images/arrow - green@2x.png'),
  eye_off: require('../Images/Icon material-visibility-off@2x.png'),
  eye_on: require('../Images/Icon material-visibility@2x.png'),
  SearchImg: require('../Images/search - green@2x.png'),
  AddBookingImg: require('../Images/add-icon@2x.png'),
  EditImg: require('../Images/manage-icon@2x.png'),
  ViewImg: require('../Images/view-icon@2x.png'),
  arrow_forward: require('../Images/Icon ionic-ios-arrow-round-forward@2x.png'),
  green_search: require('../Images/search - green@2x.png'),
  date_picker: require('../Images/calendar icon@2x.png'),
  FadedArrowImg: require('../Images/arrow - faded green@2x.png'),
  next: require('../Images/arrow - white@2x.png'),
  add_img: require('../Images/add2x.png'),
  edit_icon: require('../Images/manage icon2x.png'),
  default_human_image: require('../Images/Image 12x.png'),
  delete_icon: require('../Images/delete icon2x.png'),
  approvel: require('../Images/enable@2x.png'),
  dropdown: require('../Images/dropdown_arrow @2x.png'),
  refresh: require('../Images/reassign@2x.png'),
  cancel_new: require('../Images/reject@2x.png'),
  popup_clseicon: require('../Images/pop-up close@2x.png'),
  file: require('../Images/file@2x.png'),
  file_remove: require('../Images/file - close@2x.png'),
  checkbox_uncheck: require('../Images/checkbox (1)@2x.png'),
  checkbox_check: require('../Images/checkbox@2x.png'),
  pre_assign: require('../Images/Pre-assign@2x.png'),
  schedule: require('../Images/schedule@2x.png'),
  reschedule: require('../Images/reschedule@2x.png'),
  driver: require('../Images/drivers - white@2x.png'),
  active_driver: require('../Images/drivers - grey2x.png'),
  pause_booking: require('../Images/awesome-pause@2x.png'),
  play: require('../Images/awesome-play@2x.png'),
  location: require('../Images/material-location-on@2x.png'),
  location1: require('../Images/map-marker-radius.png'),
  blue_call: require('../Images/Group 152@2x.png'),
  water_drop: require('../Images/water-droplets@2x.png'),
  charger: require('../Images/Group 1034@2x.png'),
  power_bank: require('../Images/charging@2x.png'),
  uniform: require('../Images/uniform@2x.png'),
  backpack: require('../Images/portfolio@2x.png'),
  swiping_machine: require('../Images/card-machine@2x.png'),
  id_card: require('../Images/id-card@2x.png'),
  play_icon: require('../Images/play icon@2x.png'),
  logo: require('../Images/Final Logo Outline White.png'),
  blue_add: require('../Images/add@2x.png'),
  checkbox_new: require('../Images/checkbox (1)@2x.png'),
  map_icon: require('../Images/Icon material-location-on.png'),
  unavailableimage: require('../Images/404.png'),
  logout: require('../Images/noun_logout_3393780@2x.png'),
  download: require('../Images/download@2x (1).png'),
  autocomplete: require('../Images/auto complete@2x.png'),
  addresschange: require('../Images/update@2x.png'),
};

export default Images;
