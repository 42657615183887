import React, { Component } from 'react';
import './styles.css';
import Grid from '@material-ui/core/Grid';
import Logo from '../Logo';
import Login from '../../Pages/Login';
import ForgotPassword from '../../Pages/ForgotPassword';
import OtpVerification from '../../Pages/OtpVerification';
import SetPassword from '../../Pages/SetPassword';
export default class Main extends Component {
  constructor(props){
    super(props);
    this.state={
      type: props.type,
      registerdata:null
    }
  }
  loadmodaltype = (data) => {
    console.log("type",data);
    this.setState({ type: data.type });
    this.setState({ registerdata: data });
}
loadforgot = () => {
  // alert('');
  this.setState({ forgot: true, type: 'forgotpassword' });
}
otpverification = (data) => {
  console.log("email",data)
  this.setState({ type: 'otp' })
}
  render() {
    return (
      <div> 
           <Grid container spacing={0}>
        
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Logo/>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div>
          {this.state.type == 'login' &&
          <Login loadforgot={(data) => this.loadforgot(data)} {...this.props} />
          }
          {this.state.type == 'forgotpassword' &&
          <ForgotPassword registerdata={this.state.registerdata} loadlogin={this.loadmodaltype} otpverification={this.loadmodaltype}/>
          }
           {this.state.type == 'otp' &&
          <OtpVerification otpverification={(data)=>this.otpverification(data)} registerdata={this.state.registerdata} {...this.props} resetPassword={() => this.setState({ type: 'setpassword' })}/>
          }
          {this.state.type == 'setpassword' &&
          <SetPassword {...this.props} registerdata={this.state.registerdata} loadlogin={this.loadmodaltype} />
        }
          </div>
        </Grid>
        
      </Grid>
      </div>
    );
  }
}
