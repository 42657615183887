import React, {Component} from 'react';
import './style.css';
import moment from 'moment';
import images from '../../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';

var historyHeader = [{'id' : 1, 'date' : 'Date', 'time' : 'Time', 'action' : 'Action', 'action_by' : 'Action By'}];

class BookingHistory extends Component 
{

    constructor(props){

        super(props);

        this.state = {
          historyHeader : historyHeader,
          historyList : null,
          loading:[]
        }
    }
    loadBookingHistory=(data)=>{
      this.setState({loading:true})
      const token=localStorage['accesstoken'];
      var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
      console.log("booking history obj", obj);   
      apiservices.listbookinghistory(obj,token)
            .then((response)=>{
                this.setState({loading:false});
                if(!response.error){
                  console.log("ticket history",response.response)
                    this.setState({historyList:response.response})
                }else{
                  this.setState({responseMsg:response.message})
                }
     
              })
  }
  componentWillMount(){
    this.loadBookingHistory();
  }
render(){
  const offset = moment().utcOffset();
   return(
       

   <div>
               <div className="booking_pickup">
               {this.state.loading==true&&
    <CircularProgress />}  

                      <div className="graph-table-listing">
                      <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        
            {this.state.historyHeader && this.state.historyHeader.map((item)=> {
          return(
            <tr>
            <th>{item.date}</th>
          <th>{item.time}</th>
          <th>{item.action}</th>
          <th>{item.action_by}</th>
          </tr>
          )
            })}
           
        
      </thead>
      
      <tbody>
      {this.state.historyList &&this.state.historyList.length>0&& this.state.historyList.map((item)=> {
          return(
            <tr>
            <td>{moment(item.created_at).format('DD/MM/YY')}</td>
            <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
          <td>{item.action}</td>
          <td>{item.action_by}</td>
          </tr>
          )
      })}
      </tbody>
    </table>
    {this.state.historyList&&this.state.historyList.length==0&&
    <div>No History yet !</div>
    }
  </div>
       

      

               </div>
</div>

</div>

 )
    }
    componentDidMount(){
      console.log("params",this.props);
      this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
    }
}
export default BookingHistory;