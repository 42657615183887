import React, { Component } from 'react';
// import Header from "../../Component/Header";
import DashboardCard from '../../Component/DashboardCard';
import ValidationLibrary from '../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import './styles.css';
import moment from 'moment';
import apiservices from '../../helpers/apiservices';
import { Table, Tag, Pagination, Select, Tooltip } from 'antd';
import images from '../../helpers/images';

import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect,
} from 'react-router-dom';
const { Option } = Select;
var performanceHeader = [
  {
    id: 1,
    svc_id: 'SVC ID',
    svc_name: 'SVC Name',
    total_task: 'Total Task',
    ontime_task: 'On Time Tasks',
    ontime_dropoff: 'On Time Dropoff',
    cancelled: 'Cancelled',
    rescheduled: 'Rescheduled',
    no_show: 'No Show',
  },
];
var cardDetails = {
  activedriver: 132,
  wallet: 50,
  scvPayment: 50000,
  outstandingPayment: 50000,
  walletAmount: 50000,
  postpaidAmount: 50000,
};
const MapWithAMarkers = withGoogleMap((props) => {
  console.log('newprops', props);
  const location = {
    lat: props.currentLocation.lat,
    lng: props.currentLocation.lng,
  };
  console.log('location', location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker position={location} />
      </GoogleMap>
    </div>
  );
});
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      performanceHeader: performanceHeader,
      performanceList: [],
      bookingDetails: [],
      currentPage: 1,
      pageSize: 10,
      svccurrentPage: 1,
      svcpageSize: 10,
      bookingShow: false,
      BookingList: true,
      img: images.play_icon,
      deleteId: -1,
      openactiveBooking: false,
      popupDisplay: '',
      latitude: 0,
      longitude: 0,
      activeID: null,
      assignID: null,
      filenewlist: [],
      fileupload_error: null,
      fields: {
        reasons: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
      },
      reassignfields: {
        driver_list: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        reasons: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
      },
      reschedulefields: {
        slotdate: {
          validations: [{ name: 'required' }],
          error: null,
          value: moment(),
        },
        slotime: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        service_advisor: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        reasons: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
      },
    };
  }
  loadActiveBookingDashboard = () => {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
    };
    console.log('actve', obj);
    apiservices.listactivebookingdash(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({ bookingDetails: response.response, numbercount: calc });
        console.log('acive reponse', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.loadActiveBookingDashboard();
    });

    console.log('page size', pageSize);
  };
  onchangeSVCPagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ svccurrentPage: page }, function () {
      this.loadSVCPerformance();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.loadActiveBookingDashboard();
    });
  };
  loadSVCPerformance = () => {
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.svccurrentPage,
      pageLimit: this.state.svcpageSize,
      centre_name: '',
    };
    apiservices.listsvcperformance(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.svcpageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({
          performanceList: response.response,
          svcnumbercount: calc,
        });
        console.log('new userr request', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  componentWillMount() {
    this.loadActiveBookingDashboard();
    this.loadSVCPerformance();
  }
  makecall = (data) => {
    const token = localStorage['accesstoken'];
    var obj = { mobile_no: data };
    apiservices.makeCall(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var self = this;
        this.setState({ success_call: response.response.message }, function () {
          this.loadActiveBookingDashboard();
        });
        setTimeout(() => {
          this.setState({ success_call: '' });
        }, 3000);
      } else {
        this.setState({ error_call: response.response.message }, function () {
          this.loadActiveBookingDashboard();
        });
        setTimeout(() => {
          this.setState({ error_call: '' });
        }, 3000);
      }
    });
  };
  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  unassignedDriverList = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.unassigneddriverlist(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ driver_list: response.response });
        var reassignfields = this.state.reassignfields;
        reassignfields['driver_list'].value =
          response.response.length > 0 ? response.response[0].id : '';
        this.setState({ reassignfields });
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  BookingDialog = (data, value, svc_id) => {
    console.log('value', value);
    console.log('data', data);
    var self = this;
    this.setState({
      popupDisplay: data,
      activeID: value.id,
      assignID: value.assign_id,
    });
    var fields = this.state.fields;
    fields['reasons'].value = '';
    fields['reasons'].error = null;
    this.setState({ fields });
    var reassignfields = this.state.reassignfields;
    reassignfields['reasons'].value = '';
    reassignfields['reasons'].error = null;
    this.unassignedDriverList();
    this.loadaloocationlist();
    this.loadAdvisorDropdown(svc_id);
    this.setState({ reassignfields, responseMsg: '' });
    if (data == 'map') {
      console.log('value');
      this.setState(
        { latitude: value.driver_latitude, longitude: value.driver_longitude },
        function () {
          console.log('latitude', this.state.latitude);
        },
      );
    }

    this.setState({ openactiveBooking: true });
  };
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  popupSubmit = (data) => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      if (data == 'cancel') {
        this.cancelBooking();
      }
      if (data == 'pause') {
        this.pauseBooking();
      }
      // alert('hii');
    }
    this.setState({ fields });
  };
  cancelBooking = () => {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.activeID,
      reason: this.state.fields.reasons.value,
      assign_id: this.state.assignID,
    };
    console.log('cancel', obj);
    apiservices.cancelbooking(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        setTimeout(() => {
          self.loadActiveBookingDashboard();
          self.setState({ openactiveBooking: false });
        }, 3000);
        this.setState({ responseMsg: response.message });
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
  };
  pauseBooking = () => {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.activeID,
      reason: this.state.fields.reasons.value,
      assign_id: this.state.assignID,
    };
    console.log('pause booking', obj);
    apiservices.pausebooking(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        setTimeout(() => {
          self.loadActiveBookingDashboard();
          self.setState({ openactiveBooking: false });
        }, 3000);
        this.setState({ responseMsg: response.message });
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
  };
  closeDialog = () => {
    this.setState({ openactiveBooking: false }, function () {
      var fields = this.state.fields;
      fields['reasons'].value = '';
      this.setState({ fields });
      var reassignfields = this.state.reassignfields;
      reassignfields['reasons'].value = '';
      this.setState({ reassignfields });
    });
  };
  Reassignvalidations = (value, key) => {
    var reassignfields = this.state.reassignfields;
    reassignfields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      reassignfields[key].validations,
    );
    // console.log(checkstatus);

    reassignfields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(reassignfields);
    var filtererrors = getallkeys.filter(
      (obj) => reassignfields[obj].error != '',
    );
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ reassignfields });
  };
  ReassignBooking = () => {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.activeID,
      reason: this.state.reassignfields.reasons.value,
      driver_id: this.state.reassignfields.driver_list.value,
      assign_id: this.state.assignID,
    };
    console.log('reassign', obj);
    apiservices.reassignbooking(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        setTimeout(() => {
          self.loadActiveBookingDashboard();
          self.setState({ openactiveBooking: false });
        }, 3000);
        this.setState({ responseMsg: response.message });
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
  };
  InterchangeBooking = () => {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = {
      id: this.state.activeID,
      reason: this.state.reassignfields.reasons.value,
      driver_id: this.state.reassignfields.driver_list.value,
      assign_id: this.state.assignID,
    };
    console.log('reassign', obj);
    apiservices.interchangebooking(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        setTimeout(() => {
          self.loadActiveBookingDashboard();
          self.setState({ openactiveBooking: false });
        }, 3000);
        this.setState({ responseMsg: response.message });
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
  };
  ReassignSubmit = (data) => {
    var reassignfields = this.state.reassignfields;
    var getallkeys = Object.keys(reassignfields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        reassignfields[obj].value,
        reassignfields[obj].validations,
      );
      reassignfields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter(
      (obj) => reassignfields[obj].error != '',
    );
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      if (data == 'reassign') {
        this.ReassignBooking();
      }
      if (data == 'interchange') {
        this.InterchangeBooking();
      }
    }
    this.setState({ reassignfields });
  };
  loadaloocationlist = () => {
    const token = localStorage['accesstoken'];
    var obj = { slot_day: new Date() };
    apiservices.getSlotDetails(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ slotime: response.response });
        var reschedulefields = this.state.reschedulefields;
        // if(!value){
        reschedulefields['slotime'].value =
          response.response.length > 0 ? response.response[0].id : '';
        this.setState({ reschedulefields });
        // }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadAdvisorDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = { svc_center_id: value };
    console.log('service advisor', value);
    apiservices.bookingadvicerdropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ service_advisor: response.response });
        var reschedulefields = this.state.reschedulefields;
        // if(!value){
        reschedulefields['service_advisor'].value =
          response.response.length > 0 ? response.response[0].id : '';
        this.setState({ reschedulefields });
        // }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  goPerformance = () => {
    this.props.history.push('/svc/performance');
  };
  goBookings = () => {
    this.props.history.push('/booking/booking_list/bokinglist_main/active');
  };
  render() {
    const { driver_list, slotime, service_advisor } = this.state;
    return (
      <div>
        {/* <Header activePage = {2} /> */}
        <DashboardCard cardDetails={cardDetails} {...this.props} />
        <div className='svchead'>
          <div className='svclabel' style={{ paddingBottom: 10 }}>
            <h6>SVC Performance</h6>
          </div>
          <div className='view_all' onClick={() => this.goPerformance()}>
            <p style={{ fontSize: 13 }}>View All</p>
          </div>
        </div>

        <div className='tablediv'>
          <table class='table table-hover'>
            <thead>
              {this.state.performanceHeader &&
                this.state.performanceHeader.map((item) => {
                  return (
                    <tr>
                      <th>{item.svc_id}</th>
                      <th>{item.svc_name}</th>
                      <th>{item.total_task}</th>
                      <th>{item.ontime_task}</th>
                      <th>{item.ontime_dropoff}</th>
                      <th>{item.cancelled}</th>
                      <th>{item.rescheduled}</th>
                      {/* <th>{item.no_show}</th> */}
                    </tr>
                  );
                })}
            </thead>
            <tbody>
              {this.state.performanceList &&
                this.state.performanceList.map((item) => {
                  return (
                    <tr>
                      <td>ID{item.id} </td>
                      <td>{item.centre_name}</td>
                      <td>{item.total_booking}</td>
                      <td>{item.on_time_tasks}</td>
                      <td>{item.on_time_dropoff}</td>
                      <td>{item.cancel_booking}</td>
                      <td>{item.reschedule_booking}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {this.state.performanceList &&
            this.state.performanceList.length == 0 && (
              <div>No Records Found </div>
            )}
        </div>
        {/* <Pagination total={this.state.svcnumbercount} current={this.state.svccurrentPage} onChange={(page, pageSize) => this.onchangeSVCPagination(page, pageSize)}
      pageSize={this.state.svcpageSize}  itemRender={this.itemRender}
    /> */}
        <div className='bookinghead'>
          <div className='svclabel'>
            <h6>Active Bookings</h6>
          </div>
          <div className='view_all' onClick={() => this.goBookings()}>
            <p style={{ fontSize: 13 }}>View All</p>
          </div>
        </div>
        {this.state.error_call ? (
          <div className='failure_msg'>{this.state.error_call}</div>
        ) : (
          ''
        )}

        {this.state.success_call ? (
          <div className='success_msg'>{this.state.success_call}</div>
        ) : (
          ''
        )}
        <div className='tablediv'>
          <table class='table table-hover'>
            <thead>
              <tr>
                <th>
                  <span>ID </span>
                  <span style={{ float: 'right' }}>
                    <img src={images.searchImage} style={{ width: 10 }} />
                    <img src={images.searchImage} style={{ width: 10 }} />
                  </span>
                </th>
                <th>City / Zone</th>
                <th>Booking Info</th>
                <th>Customer Details</th>
                <th>Service Centre Details</th>
                <th>Pilot Details</th>
                <th>Progress</th>
                <th>Status</th>
                <th>State</th>
                <th>Cancel</th>
                <th>Pilot Change</th>
              </tr>
            </thead>
            <tbody>
              {this.state.bookingDetails &&
                this.state.bookingDetails.length > 0 &&
                this.state.bookingDetails.map((obj) => {
                  return (
                    <tr>
                      <td>ID{obj.id}</td>
                      <td>{obj.city}</td>
                      <td className='text-left'>
                        {moment(obj.slot_day).format('DD/MM/YY')}
                      </td>
                      <td className='customer_details'>
                        <div style={{ marginTop: 20 }}>{obj.customer_name}</div>
                        <div style={{ marginTop: 20 }}>
                          {obj.pickup_address}
                        </div>
                        <div>
                          <Tooltip
                            placement='top'
                            title='Call'
                            color='white'
                            key='#0B485A'>
                            <img
                              src={images.call}
                              style={{ width: 38 }}
                              onClick={() => this.makecall(obj.customer_mobile)}
                            />
                          </Tooltip>
                          {/* <img src={images.editicon} style={{ width: 38 }} /> */}
                        </div>
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ width: 120 }}>{obj.centre_name}</div>
                          {/* <span > */}
                          {/* <img src={images.editicon} style={{ width: 38, }} /> */}
                          {/* </span> */}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ width: 120 }}>{obj.cre_name}</div>
                          {/* <span > */}
                          {obj.service_advisor_mobile_no != null ? (
                            <img
                              src={images.call}
                              style={{ width: 38 }}
                              onClick={() =>
                                this.makecall(obj.service_advisor_mobile_no)
                              }
                            />
                          ) : (
                            ''
                          )}
                          {/* </span> */}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ width: 120 }}>
                            {obj.service_advisor_name}
                          </div>
                          {/* <span > */}
                          {obj.cre_mobile_no != null ? (
                            <img
                              src={images.call}
                              style={{ width: 38 }}
                              onClick={() => this.makecall(obj.cre_mobile_no)}
                            />
                          ) : (
                            ''
                          )}
                          {/* </span> */}
                        </div>
                      </td>
                      <td>
                        <div>
                          <img
                            src={
                              obj.driver_image_url
                                ? obj.driver_image_url
                                : images.default_image
                            }
                            style={{ width: 61 }}
                          />
                          {obj.driver_mobile_no != null ? (
                            <img
                              src={images.call}
                              style={{
                                width: 38,
                                marginLeft: 59,
                                marginTop: -23,
                              }}
                              onClick={() =>
                                this.makecall(obj.driver_mobile_no)
                              }
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          {obj.driver_first_name} {obj.driver_last_name}
                        </div>
                        <div style={{ marginTop: 10 }}>
                          {obj.driver_mobile_no}
                        </div>
                        <div style={{ marginTop: 10 }}>
                          {' '}
                          <div
                            className='view-map'
                            onClick={() => this.BookingDialog('map', obj)}>
                            View on Google Map
                          </div>
                        </div>
                      </td>
                      <td>{obj.progress}</td>
                      <td>{obj.status}</td>
                      <td>
                        <Tooltip
                          placement='top'
                          title='Pause'
                          color='white'
                          key='#0B485A'>
                          <img
                            src={images.pause}
                            style={{ width: 38 }}
                            onClick={() => this.BookingDialog('pause', obj)}
                          />
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          placement='top'
                          title='Cancel'
                          color='white'
                          key='#0B485A'>
                          <img
                            src={images.cancel_new}
                            style={{
                              width: 38,
                              opacity: obj.progress_id != 1 ? 0.5 : 1,
                            }}
                            onClick={() =>
                              obj.progress_id != 1
                                ? ''
                                : this.BookingDialog('cancel', obj)
                            }
                          />
                        </Tooltip>
                      </td>
                      <td>
                        <Tooltip
                          placement='top'
                          title='Reassign'
                          color='white'
                          key='#0B485A'>
                          <img
                            src={images.refresh}
                            style={{ width: 38 }}
                            onClick={() => this.BookingDialog('reassign', obj)}
                          />
                        </Tooltip>
                        <Tooltip
                          placement='top'
                          title='InterChange'
                          color='white'
                          key='#0B485A'>
                          {' '}
                          <img
                            src={images.plotchange}
                            style={{ width: 38 }}
                            onClick={() =>
                              this.BookingDialog('interchange', obj)
                            }
                          />
                        </Tooltip>{' '}
                      </td>
                      {/* <td><img src={images.pause} style={{ width: 38 }} /></td>
                                    <td><img src={images.cancel_new} style={{ width: 38 }} /></td>
                                    <td><img src={images.refresh} style={{ width: 38 }} /> <img src={images.plotchange} style={{ width: 38 }} /></td> */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {this.state.bookingDetails &&
            this.state.bookingDetails.length == 0 && (
              <div>No Records Found </div>
            )}
        </div>
        {/* <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize}  itemRender={this.itemRender}
    /> */}

        <Dialog
          open={this.state.openactiveBooking}
          className={`booking_pickup ${
            this.state.popupDisplay == 'map' && 'map_booking_pickup'
          }`}>
          <div className='modalclosecls'>
            <img
              src={images.popup_clseicon}
              style={{ width: '15px', cursor: 'pointer' }}
              onClick={() => this.closeDialog()}
            />
          </div>
          {this.state.popupDisplay == 'pause' && (
            <div className='modalcntntcls'>
              <div className='delete_text'>Pause Booking</div>
              <div>
                <div className='form-group reason_box'>
                  <label>Reason</label>

                  <textarea
                    row='5'
                    col='4'
                    maxLength='300'
                    name='reasons'
                    className='form-control reason_box'
                    value={this.state.fields.reasons.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }></textarea>
                  <span className='error_msg_adddealer'>
                    {this.state.fields['reasons'].error &&
                      this.state.fields['reasons'].error}
                  </span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ? (
                  <div className='failure_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                {this.state.responseMsg && !this.state.responseMsg ? (
                  <div className='success_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                <div className='btn_main'>
                  <button
                    className='delete_submit'
                    onClick={() => this.popupSubmit('pause')}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.popupDisplay == 'cancel' && (
            <div className='modalcntntcls'>
              <div className='delete_text'>Cancel Booking</div>
              <div>
                <div className='form-group reason_box'>
                  <label>Reason</label>

                  <textarea
                    row='5'
                    col='4'
                    maxLength='300'
                    name='reasons'
                    className='form-control reason_box'
                    value={this.state.fields.reasons.value}
                    onChange={(e) =>
                      this.checkvalidations(e.target.value, e.target.name)
                    }></textarea>
                  <span className='error_msg_adddealer'>
                    {this.state.fields['reasons'].error &&
                      this.state.fields['reasons'].error}
                  </span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ? (
                  <div className='failure_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                {this.state.responseMsg && !this.state.responseMsg ? (
                  <div className='success_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                <div className='btn_main'>
                  <button
                    disabled={this.state.responseMsg && this.state.responseMsg}
                    className='delete_submit'
                    onClick={() => this.popupSubmit('cancel')}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.popupDisplay == 'reassign' && (
            <div className='modalcntntcls'>
              <div className='delete_text'>Re-assign Driver</div>
              <div>
                <div className='form-group'>
                  <div class='form-inline position-relative reason_text'>
                    <label>Select the Driver</label>
                    <Select
                      value={[
                        parseInt(this.state.reassignfields.driver_list.value),
                      ]}
                      onChange={(value) =>
                        this.Reassignvalidations(value, 'driver_list')
                      }
                      name='driver_list'>
                      {driver_list &&
                        driver_list.length > 0 &&
                        driver_list.map((item) => {
                          return (
                            <Option value={item.id}>
                              {item.first_name} {item.last_name}
                            </Option>
                          );
                        })}
                    </Select>
                    <img
                      src={images.dropdown}
                      className='img-fluid reason_dropdown-img'
                    />

                    <span className='error_msg_adddealer'>
                      {this.state.reassignfields['driver_list'].error &&
                        this.state.reassignfields['driver_list'].error}
                    </span>
                  </div>
                </div>

                <div className='form-group reason_box'>
                  <label>Reason</label>

                  <textarea
                    row='5'
                    col='4'
                    maxLength='300'
                    name='reasons'
                    className='form-control reason_box'
                    value={this.state.reassignfields.reasons.value}
                    onChange={(e) =>
                      this.Reassignvalidations(e.target.value, e.target.name)
                    }></textarea>
                  <span className='error_msg_adddealer'>
                    {this.state.reassignfields['reasons'].error &&
                      this.state.reassignfields['reasons'].error}
                  </span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ? (
                  <div className='failure_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                {this.state.responseMsg && !this.state.responseMsg ? (
                  <div className='success_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                <div className='btn_main'>
                  <button
                    className='delete_submit'
                    onClick={() => this.ReassignSubmit('reassign')}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.popupDisplay == 'interchange' && (
            <div className='modalcntntcls'>
              <div className='delete_text'>Interchange Driver</div>
              <div>
                <div className='form-group'>
                  <div class='form-inline position-relative reason_text'>
                    <label>Select the Driver</label>
                    {/* <Select defaultValue="Chad Ellis" onChange={handleChange}>
                                        <Option value="Chad Ellis">Lorem Isum</Option>
                                        <Option value="lucy">Lorem Isum</Option>
                                        <Option value="Yiminghe">Lorem Isum</Option>
                                    </Select>
                                    <img src={images.dropdown} className="reason_drop_down" /> */}
                    <Select
                      value={[
                        parseInt(this.state.reassignfields.driver_list.value),
                      ]}
                      onChange={(value) =>
                        this.Reassignvalidations(value, 'driver_list')
                      }
                      name='driver_list'>
                      {driver_list &&
                        driver_list.length > 0 &&
                        driver_list.map((item) => {
                          return (
                            <Option value={item.id}>
                              {item.first_name} {item.last_name}
                            </Option>
                          );
                        })}
                    </Select>
                    <img
                      src={images.dropdown}
                      className='img-fluid reason_dropdown-img'
                    />

                    <span className='error_msg_adddealer'>
                      {this.state.reassignfields['driver_list'].error &&
                        this.state.reassignfields['driver_list'].error}
                    </span>
                  </div>
                </div>

                <div className='form-group reason_box'>
                  <label>Reason</label>

                  <textarea
                    row='5'
                    col='4'
                    maxLength='300'
                    name='reasons'
                    className='form-control reason_box'
                    value={this.state.reassignfields.reasons.value}
                    onChange={(e) =>
                      this.Reassignvalidations(e.target.value, e.target.name)
                    }></textarea>
                  <span className='error_msg_adddealer'>
                    {this.state.reassignfields['reasons'].error &&
                      this.state.reassignfields['reasons'].error}
                  </span>
                </div>
                {this.state.responseMsg && this.state.responseMsg ? (
                  <div className='failure_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                {this.state.responseMsg && !this.state.responseMsg ? (
                  <div className='success_msg'>
                    {this.state.responseMsg && this.state.responseMsg}
                  </div>
                ) : (
                  ''
                )}
                <div className='btn_main'>
                  <button
                    className='delete_submit'
                    onClick={() => this.ReassignSubmit('interchange')}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.popupDisplay == 'map' && (
            <div
              className='modalcntntcls'
              style={{ width: '100%', padding: 10 }}>
              {this.state.latitude != 0 && this.state.longitude != 0 && (
                <MapWithAMarkers
                  currentLocation={{
                    lat: parseFloat(this.state.latitude),
                    lng: parseFloat(this.state.longitude),
                  }}
                  //  currentLocation={this.state.currentLatLng}
                  containerElement={
                    <div
                      style={{
                        borderRadius: 2,
                        height: '550px',
                        width: '100%',
                      }}
                    />
                  }
                  mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                />
              )}
            </div>
          )}
        </Dialog>
      </div>
    );
  }
  componentDidMount() {
    this.props.receivePageTitle && this.props.receivePageTitle('Dashboard');
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas',
})(Dashboard);
