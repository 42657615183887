import React, {Component} from 'react';
import './style.css';
import Image from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';
const dateFormat = 'YYYY-MM-DD';
const { RangePicker } = DatePicker;

var AuditHeader = [{'id' : 1,  'driver_name' : 'Driver Name', 'driver_id': 'Driver ID', 'date' : 'Date', 'audit_time' : 'Audit Time','image_file':'Image File',  'notes' : 'Notes'}];


class DriverAuditDetails extends Component{

    constructor(props){

        super(props);

        this.state = {
            AuditHeader : AuditHeader,

            auditList : [],
             
            currentPage : 1,
            pageSize:10,
            fromDate:null,
            toDate:null


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      loadDriverAuditDetails =()=>{
        const token = localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,pageNo:this.state.currentPage,pageLimit:this.state.pageSize,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log(" auditobj", obj);
          this.props.sendid(obj.id);
          apiservices.listdriveraudit(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({auditList:response.response, numbercount:calc})
                        console.log("booking reponse", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){
          this.loadDriverAuditDetails();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadDriverAuditDetails();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadDriverAuditDetails(); 
        });
      }
      checkvalidations =(data)=>{
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadDriverAuditDetails();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadDriverAuditDetails();
        })
      }
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
              <div className="search-header booking-mishaps" style={{marginTop:10}}>
                         <div className="row">
                             <div className="col-md-3">
                                  {/* <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for Customer" className="form-control" name="customer_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div> */}
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from" style={{left:32}}>From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkvalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.AuditHeader && this.state.AuditHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.driver_name}</th>
                                                <th>{item.driver_id}</th>
                                                <th>{item.date}</th>
                                                <th>{item.audit_time}</th>
                                                <th>{item.image_file}</th>
                                                <th>{item.notes}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.auditList &&this.state.auditList.map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.driver_first_name} {item.driver_last_name}</td>
                                                <td>ID{item.driver_id}</td>
                                                <td>{moment(item.audit_time).format('DD/MM/YY')}</td>
                                                <td>{moment.utc(new Date(item.audit_time)).utcOffset(offset).format("hh:mm A")}</td>
                                                <td style={{color:'#0AB3E5',width:250,wordBreak:'break-all'}}>{item.audit_image}</td>
                                                <td style={{width:200,wordBreak:'break-word'}}>{item.note}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.auditList&&this.state.auditList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default DriverAuditDetails;