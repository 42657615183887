import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import "./styles.css";
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useRouteMatch,
//     useParams
// } from "react-router-dom";
import Menu from "../Menu";
import images from "../../helpers/images";
export default class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            user_type:null
        }
    }
    logout = () => {
        localStorage.clear("");
        // window.sessionStorage.clear("");
        // this.props.history.push('/');
        this.props.logout&&this.props.logout();
      };
      componentWillMount(){
        const type=localStorage['type'];
        this.setState({user_type:type});
      }
    render() {
        return (
            <div>
                <div style={{ width: "100%", background: "linear-gradient(to right, #0681C0, #0169B1)", minHeight: 50, float: "left" }}>
                <div className="container-automobile">
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={6} sm={12} className="introcenter pl-0" >
                            <div className="hederlogodiv" style={{paddingLeft:0}}>
                                <img src={images.logo} className="img-fluid"  style={{width:350}}
                                />
                                </div>
                        </Grid>

                        <Grid item xs={12} md={6} sm={12} className="introcenter pr-0">
                            <div className="hedersecound">  
                                <input className="searchinput" type="text" placeholder="Search" />
                                 <img src={images.searchImage} className = "searchimage"/>

                                 <div className="head-imgs" style={{ display: "flex" }}>
                                 {this.state.user_type=='ADMINUSER'?
                                 <div style={{ color: "#fff", lineHeight:1.5,marginRight:5 }}>
                                     
                                            {localStorage['name']} <div>{localStorage['mobile']}</div></div>:''
                                        }
                                     {/* <div className="notify-img"><img src={images.notificationImage} className = "notificationimage"/></div> */}
                                <div className="notify-img"><img src={images.logout} onClick={()=>this.logout()} className = "notificationimage"/></div> 
                                </div>
                                {/* <img src={images.notificationImage} className = "notificationimage"/>
                                <img src={images.profileImage} onClick={()=>this.logout()} className = "notificationimage"/>  */}
                            </div>
                        </Grid>
                    </Grid>
                    </div>
                    {/* <Grid container spacing={0}> */}
                    <Menu {...this.props}/>
                    {/* </Grid> */}
                </div>
                
            </div>
        )
    }
}