import React, { Component } from 'react';
import { Table, Tag, Pagination, Select,DatePicker} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import Image from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function handleChange(value) {
  console.log(`selected ${value}`);
}
var PickupHeader = [{'id' : 1,  'driver_name' : 'Driver Name', 'date': 'Date', 'total' : 'Total', 'ontime_task' :'On Time Tasks',  'late_task' : 'Late Tasks','cancelled':'Cancelled','mishaps':'Mishaps','ontime_percentage' : 'On Time Percentage'}];
export default class Pickup extends Component {
  constructor(props){
    super(props);
    this.state={
      PickupHeader:PickupHeader,
      performancePickup:[],
      closeID:null,
      currentPage : 1,
      loading:false,
      pageSize:10,
      driver:null,
      city:null,
      fromDate:null,
     
     

    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  loadPerformancePickup=()=>{
    this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver,search_date:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):null};
    console.log("actve", obj); 
    apiservices.listdriverdateperformance(obj,token)
          .then((response)=>{
              this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  this.setState({performancePickup:response.response, numbercount:calc})
                  console.log("performacen pickup", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadPerformancePickup();
     
}
cityFilter =(e) =>{
  var value=e.target.value;
  // this.loadsvclist();
  if(value==''){
    value=null;
    // 
  }
  this.setState({[e.target.name]:value,currentPage:1},function(){
    this.loadPerformancePickup();
  });
}
onchangePagination = (page, pageSize) => {
  this.setState({ currentPage: page }, function(){
      this.loadPerformancePickup();        
  });

}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadPerformancePickup(); 
  });
}
checkvalidations =(data)=>{
    console.log("date",data)
  this.setState({fromDate:data},function(){
    this.loadPerformancePickup();
  })
}
ClearDatepicker =()=>{
  this.setState({fromDate:null,toDate:null},function(){
    this.loadPaymentDecrement();
  })
}
  render() {
    const offset = moment().utcOffset();
    return (
      <div className="conveyance"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active" style={{paddingTop:30}}>
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                                    <div className="service_listing booking-list payments-list pickup_slot">
                                    <DatePicker className="slotdate" onChange={(value) => this.checkvalidations(value)} value={this.state.fromDate}    suffixIcon={<img src={Image.date_picker} className="datepicker_img_slot" />}/>
                                    </div>
                             </div>
                         </div>
                    </div>

                    <div >
                    {/* {this.state.loading==true&&
          <div><CircularProgress /> </div>
          }  */}
                    <div className="table-list">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.PickupHeader&&this.state.PickupHeader.map((item)=>{
              return(
                <tr>
                <th>{item.driver_name}</th>
                <th>{item.date}</th>
                <th>{item.total}</th>
                <th>{item.ontime_task}</th>
                <th>{item.late_task}</th>
                <th>{item.cancelled}</th>
                <th>{item.mishaps}</th>
                <th>{item.ontime_percentage}</th>
                </tr>
              )
            })}
             
               
        </thead>
        <tbody>

        {this.state.performancePickup &&this.state.performancePickup.map((item,index) => {

return(
    <tr>
      <td>{item.driver_first_name} {item.driver_last_name}</td>
<td>{item.date}</td>
      <td>{item.total_pickup}</td>
      <td>{item.on_time_pickup_pickup}</td>
      <td>{item.on_late_pickup_pickup}</td>
      <td>{item.total_pickup_cancel}</td>
      <td>{item.mishaps}</td>
      <td>{item.total_pickup_on_time_percentage}%</td>
    </tr>
)
})}
        </tbody>
    </table>

    {this.state.performancePickup&&this.state.performancePickup.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
       </div>
    );
  }
}
