import React, { Component } from 'react';
import { Select } from 'antd';
import images from '../../helpers/images';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
import { GoogleApiWrapper } from 'google-maps-react';
import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
} from 'react-google-maps';
import Geocode from 'react-geocode';
const { Option } = Select;
const MapWithAMarker = withGoogleMap((props) => {
  const location = {
    lat: props.currentLocation.lat,
    lng: props.currentLocation.lng,
  };
  const onMarkerDragEnd = (event) => {
    props.sendingDrabbleLocation && props.sendingDrabbleLocation(event.latLng);
  };

  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        center={location}
        defaultCenter={location}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
          draggable={true}
          ref={props.onMarkerMounted}
          onDragEnd={onMarkerDragEnd}
        />
      </GoogleMap>
    </div>
  );
});
class SVCDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      predictions: [],
      fields: {
        servicecentre_name: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        dealer_master: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        address: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        // latitude:{validations:[],error:null,value:''},
        // longitude:{validations:[],error:null,value:''},
        city: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'alphabetsOnly' },
          ],
          error: null,
          value: '',
        },
        zone_id: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },

        pincode: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'zipcode' },
          ],
          error: null,
          value: '',
        },
        capacity: {
          validations: [
            { name: 'required' },
            { name: 'Noemptyspace' },
            { name: 'allowNumaricOnly' },
          ],
          error: null,
          value: '',
        },
        potential: {
          validations: [{ name: 'required' }, { name: 'Noemptyspace' }],
          error: null,
          value: '',
        },
        shop_type: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        authorized_private: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        vehicle_type: {
          validations: [{ name: 'required' }],
          error: null,
          value: '',
        },
        brand: {
          validations: [
            { name: 'required' },
            { name: 'alphabetsOnly' },
            { name: 'Noemptyspace' },
          ],
          error: null,
          value: '',
        },
        currentLatLng: {
          validations: [],
          error: null,
          value: { lat: 0, lng: 0 },
        },
        // account_manager:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
      },
    };
  }
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    if (key == 'address') {
      this.onChangeAddress(value);
      this.getlocationbyAddress(value, 'currentLatLng');
    }
    // if(key=='latitude' && this.state.fields.latitude.value&&this.state.fields.longitude.value ){
    //   this.getAddressbyLatLng(value,this.state.fields.longitude.value);
    // }
    // if(key == 'longitude' && this.state.fields.latitude.value&&this.state.fields.longitude.value){
    //   this.getAddressbyLatLng(this.state.fields.latitude.value,value);
    // }
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  onChangeAddress = (value) => {
    var address_url =
      'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=' +
      value +
      '&components=country:ind&key=';
    const token = localStorage['accesstoken'];
    var obj = { url: address_url };
    console.log('get location obj', obj);
    apiservices.getlocation(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ predictions: response.response.predictions });
        console.log('get location', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  printAddress = () => {
    return (
      <div className='address_bardisplay'>
        <List className='address_boxlist'>
          {this.state.predictions &&
            this.state.predictions.length > 0 &&
            this.state.predictions.map((data, key) => {
              return (
                <ListItem
                  className='list-location'
                  key={key}
                  onClick={() => this.changeAddress(data)}>
                  <img src={images.map_icon} className='map_icon_cls' />
                  <p style={{ cursor: 'pointer' }}>{data.description}</p>
                </ListItem>
              );
            })}
        </List>
      </div>
    );
  };
  changeAddress = (data) => {
    console.log('change address', data);
    var fields = this.state.fields;
    fields['address'].value = data.description;
    this.setState({ fields, predictions: [] }, function () {
      this.loadMapPredictions(data.place_id);
    });
  };
  loadMapPredictions = (value) => {
    // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
    var addres_url =
      'https://maps.googleapis.com/maps/api/place/details/json?placeid=' +
      value +
      '&key=';
    const token = localStorage['accesstoken'];
    console.log('');
    var obj = { url: addres_url };
    apiservices.getlocation(obj, token).then((response) => {
      if (!response.error) {
        var getlocation = response.response.result.geometry.location;
        console.log('geolocation', getlocation);
        var fields = this.state.fields;
        fields['currentLatLng'].value.lat = getlocation.lat;
        fields['currentLatLng'].value.lng = getlocation.lng;
        this.setState({ fields });
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  sendDetails = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });

      this.props.sendnextdetails(2, 'svcDetails', fields);

      //   this.setState({svc1 : false});
      //   this.setState({svc2  : true});
    }
    this.setState({ fields });
  };

  getCurrentPosition = () => {
    var self = this;
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('position', position);

      var obj = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        latitudeDelta: 0.005,
        longitudeDelta: 0.005,
        coords: position.coords,
      };
      var fields = this.state.fields;
      fields['currentLatLng'].value = obj;
      this.setState({ fields });
    });
  };
  loaddealerDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.dealerdropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ dealer_master: response.response });
        var fields = this.state.fields;
        if (!value) {
          fields['dealer_master'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadshoptypeDropdown = (value) => {
    console.log('value', value);
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.shoptypedropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ shop_type: response.response });
        var fields = this.state.fields;
        if (!value) {
          fields['shop_type'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }

        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };

  loadauthorizedDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.authorizeddropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ authorized_private: response.response });
        var fields = this.state.fields;
        if (!value) {
          fields['authorized_private'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }

        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  loadvehicletypeDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = {};
    apiservices.vehicletypedropdown(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ vehicle_type: response.response });
        var fields = this.state.fields;
        if (!value) {
          fields['vehicle_type'].value =
            response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields });
        }
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  getlocationbyAddress = (value, key) => {
    console.log('value', value);
    var self = this;
    Geocode.setApiKey('AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc');
    Geocode.enableDebug();
    Geocode.fromAddress(value).then(
      (response) => {
        console.log('response', response);
        const { lat, lng } = response.results[0].geometry.location;
        var getlocation = { lat: lat, lng: lng };
        var fields = this.state.fields;
        fields[key].value = getlocation;
        this.setState({ fields });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  getAddressbyLatLng = (data) => {
    var latitude = data.lat.toString();
    var longitude = data.lng.toString();
    var self = this;
    Geocode.setApiKey('AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc');
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        console.log('response', response);
        const { lat, lng } = response.results[0].geometry.location;
        const address = response.results[0].formatted_address;
        console.log('address', address);
        var fields = self.state.fields;
        fields['currentLatLng'].value.lat = lat;
        fields['currentLatLng'].value.lng = lng;
        fields['address'].value = address;
        self.setState({ fields });
      },
      (error) => {
        console.error(error);
      },
    );
  };
  componentWillReceiveProps(nextProps) {
    console.log('nextprops', nextProps);
    this.loadzoneDropdown();
  }
  loadzoneDropdown = (value) => {
    const token = localStorage['accesstoken'];
    var obj = { search: '' };
    apiservices.listallactivezone(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ zone_id: response.result });
        var fields = this.state.fields;
        if (!value) {
          fields['zone_id'].value =
            response.result.length > 0 ? response.result[0].id : '';
          this.setState({ fields });
        }
        console.log('login response', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  render() {
    // var coords={lat:this.state.currentLatLng.lat.toString(),lng:this.state.currentLatLng.lng.toString}
    const { zone_id } = this.state;
    const { dealer_master, shop_type, authorized_private, vehicle_type } =
      this.state;
    console.log('latitude longitude', this.state.fields.currentLatLng);
    return (
      <>
        <div className='dealer-master'>
          <div className='dealer-body'>
            <div className='form-group'>
              <div class='form-inline'>
                <label>
                  Service Centre Name&nbsp;
                  <span style={{ opacity: 0.6 }}>(Branch) </span> *
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='servicecentre_name'
                  value={this.state.fields.servicecentre_name.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['servicecentre_name'].error &&
                    this.state.fields['servicecentre_name'].error}
                </span>
              </div>

              <div class='form-inline position-relative'>
                <label>Dealer Master</label>

                <Select
                  name='dealer_master'
                  value={[this.state.fields.dealer_master.value]}
                  onChange={(value) =>
                    this.checkvalidations(value, 'dealer_master')
                  }>
                  {dealer_master &&
                    dealer_master.length > 0 &&
                    dealer_master.map((item) => {
                      return (
                        <Option value={item.id}>
                          {item.dealer_name}-{item.city}
                        </Option>
                      );
                    })}
                </Select>
                <img src={images.dropdown} className='img-fluid dropdown-img' />
                <span className='error_msg_adddealer'>
                  {this.state.fields['dealer_master'].error &&
                    this.state.fields['dealer_master'].error}
                </span>
              </div>
            </div>
            <div className='form-group'>
              <div className='map-img'>
                {/* <Map
                          //  defaultCenter
                         style={{borderRadius:20,width: '100%', height: '100%'}}
          google={this.props.google}
          center={{lat: this.state.fields.currentLatLng.value.lat,
            lng: this.state.fields.currentLatLng.value.lng}}
            // center={{lat: this.state.currentLatLng.latitude.toString(),
            //   lng: this.state.currentLatLng.longitude.toString()}}
          zoom={15}
          mapTypeControl= {false}
          streetViewControl= {false}
          fullscreenControl={false}
          zoomControl={true}
          centerAroundCurrentLocation={true}
          
        >
         
             <Marker
  //  optimized={false} 
  draggable
  onPositionChanged={this.onMarkerPositionChange}
  tracksViewChanges={false}
  position={{lat: this.state.fields.currentLatLng.value.lat, lng:  this.state.fields.currentLatLng.value.lng}} 
  onDragend={(event,coords)=>this.onMarkerDragEnd(coords)}
   />   
        </Map>      */}
                {/* {this.state.fields.currentLatLng.value.lat!=0&&this.state.fields.currentLatLng.value.lng!=0&& */}
                <MapWithAMarker
                  currentLocation={{
                    lat: this.state.fields.currentLatLng.value.lat,
                    lng: this.state.fields.currentLatLng.value.lng,
                  }}
                  sendingDrabbleLocation={(latlng) =>
                    this.getAddressbyLatLng({
                      lat: latlng.lat(),
                      lng: latlng.lng(),
                    })
                  }
                  //  currentLocation={this.state.currentLatLng}
                  containerElement={
                    <div style={{ height: `400px`, borderRadius: 20 }} />
                  }
                  mapElement={
                    <div style={{ height: `100%`, borderRadius: 20 }} />
                  }></MapWithAMarker>
                {/* }   */}
              </div>
            </div>
            {/* <div className="form-group">
                       <div class="form-inline">
                          <label>Latitude </label>
                          <input type="text" className="form-control" name="latitude" value={this.state.fields.latitude.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                          <span className="error_msg_adddealer">{this.state.fields['latitude'].error&&this.state.fields['latitude'].error}</span> 
                        </div>

                        <div class="form-inline">
                          <label>Logitude</label>
                          <input type="text" className="form-control" name="longitude" value={this.state.fields.longitude.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                          <span className="error_msg_adddealer">{this.state.fields['longitude'].error&&this.state.fields['longitude'].error}</span> 
                        </div>                     
                     </div> */}
            <div className='form-group position-relative'>
              <label>Address</label>

              <textarea
                row='5'
                className='form-control'
                name='address'
                value={this.state.fields.address.value}
                onChange={(e) =>
                  this.checkvalidations(e.target.value, e.target.name)
                }></textarea>
              <span className='error_msg_adddealer'>
                {this.state.fields['address'].error &&
                  this.state.fields['address'].error}
              </span>
              {this.printAddress()}
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>
                  Town / City&nbsp;<span style={{ opacity: 0.6 }}>(Zone) </span>{' '}
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='city'
                  value={this.state.fields.city.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['city'].error &&
                    this.state.fields['city'].error}
                </span>
              </div>
              <div class='form-inline position-relative'>
                <label>Zone Name</label>

                <Select
                  name='zone_id'
                  value={[this.state.fields.zone_id.value]}
                  onChange={(value) => this.checkvalidations(value, 'zone_id')}>
                  {zone_id &&
                    zone_id.length > 0 &&
                    zone_id.map((item) => {
                      return <Option value={item.id}>{item.zone_name}</Option>;
                    })}
                </Select>
                <img
                  alt=''
                  src={images.dropdown}
                  className='img-fluid dropdown-img'
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['zone_id'].error &&
                    this.state.fields['zone_id'].error}
                </span>
              </div>
            </div>
            <div className='form-group'>
              <div class='form-inline'>
                <label>Pincode</label>
                <input
                  type='text'
                  className='form-control'
                  name='pincode'
                  value={this.state.fields.pincode.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['pincode'].error &&
                    this.state.fields['pincode'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div class='form-inline'>
                <label>Capacity</label>
                <input
                  type='text'
                  className='form-control'
                  name='capacity'
                  value={this.state.fields.capacity.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['capacity'].error &&
                    this.state.fields['capacity'].error}
                </span>
              </div>

              <div class='form-inline'>
                <label>Potential</label>
                <input
                  type='text'
                  className='form-control'
                  name='potential'
                  value={this.state.fields.potential.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['potential'].error &&
                    this.state.fields['potential'].error}
                </span>
              </div>
            </div>

            <div className='form-group'>
              <div className='form-inline position-relative'>
                <label>Select the Type of Shop</label>
                <Select
                  value={[parseInt(this.state.fields.shop_type.value)]}
                  onChange={(value) =>
                    this.checkvalidations(value, 'shop_type')
                  }
                  name='shop_type'>
                  {shop_type &&
                    shop_type.length > 0 &&
                    shop_type.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
                <img src={images.dropdown} className='img-fluid dropdown-img' />
              </div>
              <div className='form-inline position-relative'>
                <label>Authorised / Private</label>
                <Select
                  value={[this.state.fields.authorized_private.value]}
                  name='authorized_private'
                  onChange={(value) =>
                    this.checkvalidations(value, 'authorized_private')
                  }>
                  {authorized_private &&
                    authorized_private.length > 0 &&
                    authorized_private.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
                <img src={images.dropdown} className='img-fluid dropdown-img' />
              </div>{' '}
            </div>

            <div className='form-group'>
              <div class='form-inline position-relative'>
                <label>Select Vehicle Type</label>
                <Select
                  value={[this.state.fields.vehicle_type.value]}
                  name='vehicle_type'
                  onChange={(value) =>
                    this.checkvalidations(value, 'vehicle_type')
                  }>
                  {vehicle_type &&
                    vehicle_type.length > 0 &&
                    vehicle_type.map((item) => {
                      return <Option value={item.id}>{item.name}</Option>;
                    })}
                </Select>
                <img src={images.dropdown} className='img-fluid dropdown-img' />
              </div>
              <div class='form-inline'>
                <label>Brand Name</label>
                <input
                  type='text'
                  className='form-control'
                  name='brand'
                  value={this.state.fields.brand.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }
                />
                <span className='error_msg_adddealer'>
                  {this.state.fields['brand'].error &&
                    this.state.fields['brand'].error}
                </span>
              </div>
            </div>

            {/* <div className="form-group">
                       

                        <div class="form-inline">
                          <label>Account Manager</label>
                          <input type="text" className="form-control" name="account_manager" value={this.state.fields.account_manager.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['account_manager'].error&&this.state.fields['account_manager'].error}</span>
                        </div>                     
                     </div> */}
          </div>
        </div>
        <button
          className='btn btn-dark-blue next-button'
          onClick={() => this.sendDetails()}>
          Next <img src={images.arrow_white} className='img-fluid arrow' />
        </button>
      </>
    );
  }
  componentDidMount() {
    console.log('didmount', this.props);
    if (this.props.svcDetails) {
      this.setState({ fields: this.props.svcDetails }, function () {
        this.loaddealerDropdown(this.state.fields.dealer_master.value);
        this.loadshoptypeDropdown(this.state.fields.shop_type.value);
        this.loadauthorizedDropdown(this.state.fields.authorized_private.value);
        this.loadvehicletypeDropdown(this.state.fields.vehicle_type.value);
      });
    } else {
      this.loaddealerDropdown();
      this.loadshoptypeDropdown();
      this.loadauthorizedDropdown();
      this.loadvehicletypeDropdown();
      this.getCurrentPosition();
    }
  }
}
export default GoogleApiWrapper({
  apiKey: 'AIzaSyA0AzaHQi3LQ_zRW8yknO6WF2wGbe50NtU',

  //  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(SVCDetails);
