import React, { Component } from 'react';
import images from "../../helpers/images";
import apiservices from '../../helpers/apiservices';
import ValidationLibrary from '../../helpers/validationfunction';
export default class BankKYC extends Component {
  constructor(props){
    super(props);
    this.state={
      fields:{
        bank_name:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
        bank_address:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
        account_name:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
        account_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        ifsc_code:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        gst_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        pan_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphaNumaricOnly'}],error:null,value:''},
        pincode:{validations:[{name:'required'},{name:'zipcode'}],error:null,value:''}
      }
    }
  }
  checkvalidations=(value,key)=>{
   
    
    var fields =this.state.fields;
    fields[key].value=value;
    var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
    // console.log(checkstatus);
    fields[key].error=checkstatus.msg;
    var getallkeys=Object.keys(fields);
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      this.setState({error:true})
      //errorblock
    }else{
      this.setState({error:false})

      //successblock
    }
    this.setState({fields});
  }
  sendBankDetails=()=>{
    var fields =this.state.fields;
    var getallkeys=Object.keys(fields);
    getallkeys.map((obj)=>{
    var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
    fields[obj].error=checkstatus.msg;
    })
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      //errorblock
      this.setState({error:true})
    }else{
      //successblock
      this.setState({error:false});
    
      this.props.sendnextdetails(3,'paymentDetails',fields);
      
    //   this.setState({svc1 : false});
    //   this.setState({svc2  : true});
    }
    this.setState({fields});
  }
  goBack=()=>{
    this.props.sendgoBack(1)
}
  render() {
    return (
      <>
      <div className="dealer-master"> 
           <div className="dealer-body">
                     
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Bank Name</label>
                          <input type="text" className="form-control" name="bank_name" value={this.state.fields.bank_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['bank_name'].error&&this.state.fields['bank_name'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Bank Address</label>
                          <input type="text" className="form-control" name="bank_address" value={this.state.fields.bank_address.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                          <span className="error_msg_adddealer">{this.state.fields['bank_address'].error&&this.state.fields['bank_address'].error}</span>
                        </div>                     
                     </div>
                       
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Account Name</label>
                          <input type="text" className="form-control" name="account_name" value={this.state.fields.account_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                          <span className="error_msg_adddealer">{this.state.fields['account_name'].error&&this.state.fields['account_name'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Account Number</label>
                          <input type="text" className="form-control" name="account_number" value={this.state.fields.account_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} maxLength="16" />
                          <span className="error_msg_adddealer">{this.state.fields['account_number'].error&&this.state.fields['account_number'].error}</span> 
                        </div>                     
                     </div>


                     <div className="form-group">
                       <div class="form-inline">
                          <label>IFSC Code</label>
                          <input type="text" className="form-control" name="ifsc_code" value={this.state.fields.ifsc_code.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} />
                          <span className="error_msg_adddealer">{this.state.fields['ifsc_code'].error&&this.state.fields['ifsc_code'].error}</span>  
                        </div>

                        <div class="form-inline">
                          <label>GST Number</label>
                          <input type="text" className="form-control"  name="gst_number" value={this.state.fields.gst_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} maxLength="15"/> 
                          <span className="error_msg_adddealer">{this.state.fields['gst_number'].error&&this.state.fields['gst_number'].error}</span>
                        </div>                     
                     </div>

                     <div className="form-group">
                     <div class="form-inline">
                          <label>PAN Number</label>
                          <input type="text" className="form-control" name="pan_number" value={this.state.fields.pan_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} maxLength="10"/> 
                          <span className="error_msg_adddealer">{this.state.fields['pan_number'].error&&this.state.fields['pan_number'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Pincode</label>
                          <input type="text" className="form-control" name="pincode" value={this.state.fields.pincode.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  /> 
                          <span className="error_msg_adddealer">{this.state.fields['pincode'].error&&this.state.fields['pincode'].error}</span>
                        </div>                                       
                        </div>
 
                     
                </div>
              
                 </div>
                   <button className="btn btn-white-blue prev-button" onClick={()=>this.goBack()}>  <img src={images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
                   <button className="btn btn-dark-blue next-button" onClick={()=>this.sendBankDetails()}>Next  <img src={images.arrow_white} className="img-fluid arrow"/></button>
                   </>
    );
  }
  componentDidMount(){
    console.log("didmount",this.props);
    if(this.props.paymentDetails){
        this.setState({fields:this.props.paymentDetails});
    }
}
}
