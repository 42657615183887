import React,{Component} from 'react';
import logo from './logo.svg';
import './App.css';
import Logo from '../src/Component/Logo';
import PaginationComp from '../src/Component/Pagination';
import Router from "./Routes/routes";
import ServiceCenter from '../src/Pages/ServiceCenter_Dm1';
import SvcDetailsAddBooking from '../src/Pages/SvcDetailsAddBooking';
import Routes from './Routes/routes';
import { Beforeunload } from 'react-beforeunload';
import LiveTracking from './LiveTracking';
class App extends Component {
  
  render(){
  return (
    <div className="App">
      {/* <LiveTracking /> */}
      <Routes />
    </div>
  );
  }
}

export default App;
