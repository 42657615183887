
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import '../styles.css';
import Images from '../../../helpers/images';
import moment from 'moment';
import apiservices from '../../../helpers/apiservices';
import DropOffVehicleDetails from '../DropOff/DropOffVehicleDetails';
import DropOffLocation from '../DropOff/DropOffLocation';
import SlotServiceDetails from '../DropOff/SlotServiceDetails';
const dateFormat = 'YYYY/MM/DD';
class DropOffMain extends Component{
    constructor(props){
        super(props);
        this.state = {
            pickupstatus:1,
            vehicleDetails:null,
            pickupLocation:null,
            slotService:null,
            adddropoff:null,
            
           
        }
    }

    receivepickupdetails=(data,key,details,image,submitdata)=>{
        if(data!=4){
            this.setState({pickupstatus:data});
          }
        this.setState({[key]:details},function(){
            if(details.svc_list){
            this.setState({svccentreid:details.svc_list.value})
            }
            if(data==4){
                const {vehicleDetails,pickupLocation,slotService}=this.state;
         const token=localStorage['accesstoken'];
        var obj={
            // reg_number:vehicleDetails.register_number.value,
            svc_center_id:vehicleDetails.svc_list.value,
            // mobile_no:vehicleDetails.mobile_number.value,
            vehicle_modal:vehicleDetails.vehicle_model.value,
            vehicle_varient:vehicleDetails.vehicle_variant.value,
            vehicle_number:vehicleDetails.vehicle_number.value,
            customer_salutation:vehicleDetails.salutation.value,
            customer_name:vehicleDetails.customer_name.value,
            customer_mobile:vehicleDetails.customer_mobile_number.value,
            customer_email:vehicleDetails.customer_emailid.value,
            dropoff_address:pickupLocation.address.value,
            dropoff_latitude:pickupLocation.currentLatLng.value.lat,
            dropoff_longitude:pickupLocation.currentLatLng.value.lng,
            dropoff_address_type_id:pickupLocation.address_type.value,
            slot_day:moment(slotService.slotdate.value).format(dateFormat),
            slot_time:slotService.slottimings.value,
            cre:slotService.cre.value,
            service_advisor:slotService.service_advisor.value,
            invoice_amount:slotService.invoice_amount.value,
            invoice_doc:image,
            notes:slotService.notes.value,
 
           }
                
            console.log("booking dropoff",obj);
            if(submitdata=='submit'){
                // return;
            apiservices.addbookingdropoff(obj,token)
      .then((response)=>{
        console.log("login response",response);
        if(!response.error){  
          // this.props.successdealer(response);     
          this.setState({adddropoffSuccess:response})
        }else{
          this.setState({adddropoffFailure:response})
        }
         
      })
    }
        }
        });
    }
   
    goNext=(data)=>{
        this.setState({pickupstatus:data});
    }
    render(){
        
        return(
            <div className="pickup">
                {this.state.pickupstatus==1 &&
                <DropOffVehicleDetails sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} vehicleDetails={this.state.vehicleDetails}/>
                    }
                {this.state.pickupstatus==2 &&
                 <DropOffLocation pickupLocation={this.state.pickupLocation} sendpickupdetails={(data,key,details)=>this.receivepickupdetails(data,key,details)} sendgoback={(data)=>this.goNext(data)}/>
                }
                {this.state.pickupstatus==3 &&
                 <SlotServiceDetails {...this.props} receiveImage={this.state.images} sendImages={(images)=>this.setState({images:images})} sendpickupdetails={(data,key,details,image,submitdata)=>this.receivepickupdetails(data,key,details,image,submitdata)} sendgoback={(data)=>this.goNext(data)} slotService={this.state.slotService} svccentreid={this.state.svccentreid} adddropoffSuccess={this.state.adddropoffSuccess} adddropoffFailure={this.state.adddropoffFailure}/> 
                }


        
            </div>
        )





        
    }
 
    
}


export default DropOffMain;
