import React, { Component } from 'react';
import './style.css';
import images from '../../helpers/images';
import { Table, Tag, Pagination, Select } from 'antd';
import apiservices from '../../helpers/apiservices';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DatePicker, Tooltip } from 'antd';
import
{
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import moment from 'moment';
import
{
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
function handleChange(value)
{
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

const MapWithAMarkers = withGoogleMap((props) =>
{
  console.log("newprops", props);
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  console.log("location", location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        // defaultCenter={props.currentLocation}
        // center={props.currentLocation}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
        />
      </GoogleMap>
    </div>
  )
}

);

var activepickupHeader = [{ 'id': 1, 'booking_id': 'ID', 'city_zone': 'City', 'booking_info': 'Booking Info', 'customer_details': 'Customer Details', 'service_center_details': 'Service Centre Details', 'pilot_details': 'Pilot Details', 'state': 'State' }];

class BookingListCompleted extends Component
{

  constructor(props)
  {

    super(props);

    this.state = {
      activepickupHeader: activepickupHeader,

      bookingListCompleted: [],

      currentPage: 1,
      pageSize: 10,
      customer_name: null,
      fromDate: null,
      toDate: null,
      pickup_fields: {
        svc_center: { validations: [], error: null, value: '' },
      },


    }
  }

  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }
  CompletedBookingList = () =>
  {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, svc_center: this.state.pickup_fields.svc_center.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    console.log("actve", obj);
    apiservices.bookinglistcompleted(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          console.log("calc", calc);
          this.setState({ bookingListCompleted: response.response, numbercount: calc })
          console.log("acive reponse", response);
        }
        else
        {
          this.setState({ error_msg: response.message })
        }
      })


  }
  componentWillMount()
  {
    this.CompletedBookingList();
    this.loadSeriveCenter();
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.CompletedBookingList();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.CompletedBookingList();
    });
  }
  BookingDialog = (value, data) =>
  {
    console.log("value", data)
    var self = this;
    this.setState({ activeID: value });
    console.log("value", value);

    this.setState({ latitude: data.driver_latitude, longitude: data.driver_longitude }, function ()
    {
      console.log("latitude", this.state.latitude)
    })

    this.setState({ openactiveBooking: true });
  }
  gotoBookingDetails = (data, data1) =>
  {
    console.log("data", data);
    console.log("data1", data1);
    this.props.history.push('/booking/booking_list/booking_details/details/' + data + data1)
    // this.props.history.push(booking_details/details/${data}/${data1})
  }
  cityFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function ()
    {
      this.CompletedBookingList();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.CompletedBookingList();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.CompletedBookingList();
    })
  }

  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['svc_center'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.CompletedBookingList();
    })
  }
  makecall = (bkid, assignid, mobilenumber) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: bkid, assign_id: assignid, mobile_no: mobilenumber };
    apiservices.makeCall(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.response.message }, function ()
          {
            this.CompletedBookingList();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.response.message }, function ()
          {
            this.CompletedBookingList();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  render()
  {
    const { svc_center } = this.state;

    return (
      <div>
        <div className="generate-btn"><button className="btn btn-linear-color generate-report">Generate Report</button></div>
        <div className="search-header booking-mishaps booking-active">
          <div className="row">
            <div className="col-md-3">
              <div class="form-group search-list payment-search">
                <input type="text" placeholder="Search for Customer or Vehicle" className="form-control" name="customer_name" onChange={(e) => this.cityFilter(e)} />
                <img src={images.green_search} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                <Select value={[this.state.pickup_fields.svc_center.value ? parseInt(this.state.pickup_fields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                  <Option value="">All</Option>
                  {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                  {
                    return (


                      <Option value={item.id}>{item.centre_name}</Option>

                    )
                  })}

                </Select>
              </div>
              <div className="service_listing booking-list payments-list">


                <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.error_call ?
          <div className="failure_msg">{this.state.error_call}</div> : ''
        }

        {this.state.success_call ?
          <div className="success_msg">{this.state.success_call}</div> : ''
        }
        <div className="table-list">


          <div class="table-responsive">


            <table class="table table-borderless">
              <thead>
                {this.state.activepickupHeader && this.state.activepickupHeader.map((item) =>
                {
                  return (
                    <tr>
                      <th>{item.booking_id}</th>
                      <th>{item.city_zone}</th>
                      <th className="text-left">{item.booking_info}</th>
                      <th>Slot Time</th>
                      <th className="customer_thead text-left">{item.customer_details}</th>
                      <th>Customer Name</th>
                      <th>Vehicle Number</th>
                      <th className="service_thead">{item.service_center_details}</th>
                      <th>{item.pilot_details}</th>
                      <th>{item.state}</th>
                    </tr>
                  )
                })}
              </thead>
              <tbody>

                {this.state.bookingListCompleted && this.state.bookingListCompleted.length > 0 && this.state.bookingListCompleted.map((item, index) =>
                {

                  return (
                    <tr>
                      <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>   <td>ID{item.id}</td></Link>
                      <td > <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>{item.city}</Link></td>
                      <td className="text-left"> <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>{item.slot_day ? moment(item.slot_day).format('DD/MM/YY') : ''}</Link></td>
                      <td><Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>{item.slot_time}</Link></td>
                      <td className="text-left"> <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>{item.vehicle_number}</Link><br />
                        <div className="pick_address"> <Link to={{
                          pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                        }}>{item.pickup_address}</Link></div><br />
                        <div className="pick_address">{item.customer_mobile}</div>
                        <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.customer_mobile)} /></Tooltip>

                      </td>
                      <td>{item.customer_name}</td>
                      <td>{item.vehicle_number}</td>
                      <td className="service-td"><span className="service-listing"> <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}>{item.centre_name}</Link></span><br />
                        <span className="service-listing">{item.service_advisor_name}</span>{item.service_advisor_mobile_no != null ? <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.service_advisor_mobile_no)} /></Tooltip> : ''}<br />
                        <span className="service-listing">{item.cre_name}</span>{item.cre_mobile_no != null ? <Tooltip placement="top" title="Call" color="white" key="#0B485A" ><img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.cre_mobile_no)} /></Tooltip> : ''}

                      </td>
                      <td>
                        <div className="pilot-default">
                          <img src={item.driver_image_url == '' ? images.default_image : item.driver_image_url} style={{ width: 61 }} />
                          {item.driver_mobile_no != null ?
                            <img src={images.call} className="call-defaulter" style={{ width: 38, marginRight: 80, marginTop: -23 }} onClick={() => this.makecall(item.id, item.assign_id, item.driver_mobile_no)} /> : ''}
                        </div> <Link to={{
                          pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                        }}>
                          {item.driver_first_name} {item.driver_last_name} <br />
                          {item.driver_mobile_no}</Link>
                        <div className="view-map" onClick={() => this.BookingDialog('map', item)}>View on Google Map</div>
                      </td>
                      <td><span style={{ color: '#000000' }}>{item.is_pickup ? 'Pickup' : 'Drop-off'}: </span>
                        <Link to={{ pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}` }}>
                          <span style={{ color: '#07C3BE' }}>{item.status}</span></Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {this.state.bookingListCompleted && this.state.bookingListCompleted.length == 0 &&
              <div className="no_records_found">No Records Found</div>
            }


          </div>
        </div>


        <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
        />
        <Dialog open={this.state.openactiveBooking} className={`booking_pickup booking_completed ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.setState({ openactiveBooking: false })} /></div>
          <div className="modalcntntcls" style={{ width: '100%', padding: 10 }}>

            {this.state.latitude != 0 && this.state.longitude != 0 &&
              <MapWithAMarkers
                currentLocation={{ lat: parseFloat(this.state.latitude), lng: parseFloat(this.state.longitude) }}
                //  currentLocation={this.state.currentLatLng}
                containerElement={<div style={{ borderRadius: 2, height: '550px', width: '100%' }} />}
                mapElement={<div style={{ height: `100%`, width: '100%' }} />}
              />
            }


          </div>
        </Dialog>

      </div>

    )
  }
}
export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(BookingListCompleted)