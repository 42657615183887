import React, { Component } from 'react';
import images from "../../helpers/images";
import ValidationLibrary from '../../helpers/validationfunction';
export default class ContactPersonDetails extends Component {
    constructor(props){
      console.log("props",props);
        super(props);
        this.state={
          responseMsg:props.addDealerResponse,
            fields:{
                full_name:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
                mobile_number:{validations:[{name:'required'},{name:'mobile'},{name:'Noemptyspace'}],error:null,value:''},
                dealer_code:{validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
                contact_emailid:{validations:[{name:'required'},{name:'email'},{name:'Noemptyspace'}],error:null,value:''},
               
            }
        }
    }
    checkvalidations=(value,key)=>{
        var fields =this.state.fields;
        fields[key].value=value;
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields},function(){
          this.props.sendnextdetails(4,'contactpersonDetails',fields);
        });
      }
      Service_next3=()=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          this.props.sendnextdetails(4,'contactpersonDetails',fields,'submit')
        }
        this.setState({fields});
      }
      goBack=()=>{
        this.props.sendgoBack(2)
    }
    
    componentWillReceiveProps(props){
      if(props.addDealerResponseSuccess){
        this.setState({responseMsg:props.addDealerResponseSuccess})
        setTimeout(() => {
          props.history.push('/svc/dealer_list/');
        }, 3000);
        
      }
      if(props.addDealerResponseFailure){
        this.setState({responseMsg:props.addDealerResponseFailure})
      }
      
    }
  render() {
    return (
      <>
      <div className="dealer-master"> 
            <div className="dealer-body">
                     
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Full Name</label>
                          <input type="text" className="form-control" name="full_name" value={this.state.fields.full_name.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['full_name'].error&&this.state.fields['full_name'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Mobile Number</label>
                          <input type="text" className="form-control" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error&&this.state.fields['mobile_number'].error}</span>
                        </div>                     
                     </div>

                     <div className="form-group">
                       <div class="form-inline">
                          <label>E-mail ID</label>
                          <input type="text" className="form-control" name="contact_emailid" value={this.state.fields.contact_emailid.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                          <span className="error_msg_adddealer">{this.state.fields['contact_emailid'].error&&this.state.fields['contact_emailid'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Dealer Code</label>
                          <input type="text" className="form-control" name="dealer_code" value={this.state.fields.dealer_code.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} /> 
                          <span className="error_msg_adddealer">{this.state.fields['dealer_code'].error&&this.state.fields['dealer_code'].error}</span>
                        </div>                     
                     </div>


                     
 
                </div>
                
      </div>
      <button disabled={this.state.responseMsg&&!this.state.responseMsg.error} className={`btn btn-white-blue prev-button ${this.state.responseMsg&&!this.state.responseMsg.error && 'disabled_btn'}`}  onClick={()=>this.goBack()}>  <img src={images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button disabled={this.state.responseMsg&&!this.state.responseMsg.error} className={`btn btn-dark-blue next-button ${this.state.responseMsg&&!this.state.responseMsg.error && 'disabled_btn'}`} onClick={()=>this.Service_next3()}>Submit</button>

             {this.state.responseMsg&&this.state.responseMsg.error?
            <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg.message}</div> :''
            }
            {this.state.responseMsg&&!this.state.responseMsg.error?
            <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg.message}</div> :''
            }
            </>
    );
  }
  componentDidMount(){
    console.log("didmount",this.props);
    if(this.props.contactpersonDetails){
        this.setState({fields:this.props.contactpersonDetails});
    }
}
}
