import React, {Component} from "react";
import images from "../../helpers/images";
import { Table, Tag, Space, Select, Pagination } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import "./styles.css";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
 
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import apiservices from '../../helpers/apiservices';
var SvcUserListArray = [{ID: "ID2645",Name:"Bruce Young",mobileNumber:7686993215,emailID:"bruceyoung@gmail.com",designation:"Service Manager"}];
export default class SvcDisabledUser extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            SvcUserListArray:SvcUserListArray,
            tempdata: [],
            currentPage: 1,
            pageSize: 10,
            name_search:null,
            loading:false
        };
    }
    itemRender = (current, type, originalElement) => {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page },function(){
          this.loaduserdisablelist();
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size ,currentPage:1},function(){
          this.loaduserdisablelist();
        })
      }
      loaduserdisablelist =()=>{
        this.setState({loading:true});
        const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,svc_center_id:this.props.match.params.svc_id,name:this.state.name_search};
          apiservices.svcuserdisablelist(obj,token)
          .then((response)=>{
            this.setState({loading:false});
            if(!response.error){
              var limit=response.count;
              var total_items=this.state.pageSize;
              var calc=Math.ceil(limit/total_items)*total_items;
              this.setState({tempdata:response.response,numbercount:calc})
              console.log("disable userlist",response)
      
            }else{
              this.setState({error_msg:response.message})
            }
              
          })
      }
      componentWillMount(){
        this.loaduserdisablelist();
      }
      // componentWillReceiveProps(props){
      //   console.log("return props",props)
      //   this.loaduserdisablelist();
      // }
      searchname =(e)=>{
        var value=e.target.value;
        console.log("city search",value);
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loaduserdisablelist();
        });
    }
    deleteDisableUser =(data)=>{
      console.log("delete disable",data);
      const token=localStorage['accesstoken'];
      var obj={id:data};
      console.log("loadscvlist",obj)
            apiservices.svcactiveuser(obj,token)
            .then((response)=>{
              if(!response.error){
                  this.loaduserdisablelist();
                  this.setState({success_msg:response.message})
                console.log("list svc",response)
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
        console.log("delete id",data);
    }
    render()
    {
        return(
             <div className="svc_user_list">
               <input className="searchinput" type="text" placeholder="Search" name="name_search" onChange={(e)=>this.searchname(e)} style={{marginLeft:0}}/>
               <img src={images.green_search} className = "searchimage"/>
                <table class="table table-hover">
                    <thead>
                    { this.state.SvcUserListArray && this.state.SvcUserListArray.map((obj) => {
                        return ( <tr>
                            <th>ID</th>
                            <th className = "tableleftAlign">
                              Name <span style = {{position:"absolute",marginTop:9,marginLeft:10}}><img src={images.down_arrow} style={{ width: 8,cursor:'pointer' }} /></span>
                              <span style = {{position:"absolute",marginTop:-6,marginLeft:10}}><img src={images.up_arrow} style={{ width: 8 ,cursor:'pointer'}} /></span>
                              </th>
                            <th>Mobile Number</th>
                            <th className = "tableleftAlign">E-mail ID</th>
                            <th className = "tableleftAlign">Designation</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        )
                    })}
                    </thead>
                    <tbody>
                        {/* {this.state.tempdata && this.state.tempdata.slice(0, 10).map((obj) => { */}
                        {this.state.tempdata &&this.state.tempdata.length>0 && this.state.tempdata.map((obj) => {
                            return (<tr>
                                <td >ID{obj.id}</td>
                                <td className = "tableleftAlign"> {obj.name}</td>
                                <td>{obj.mobile_no}</td>
                                <td className = "tableleftAlign">{obj.email}</td>
                                <td className = "tableleftAlign">{obj.designation_name}</td>
                                <td><Link to={{
  pathname: `${this.props.parentProps.match.path}/add_user/${obj.id}/${this.props.match.params.svc_id}`,
  
}}><img src={images.EditImg} style={{ width: 38,cursor:'pointer' }} /></Link></td>
                                <td><img src={images.delete_icon} style={{ width: 38,cursor:'pointer' }} onClick={()=>this.deleteDisableUser(obj.id)}/></td>
                            </tr>);
                        })}
                    </tbody>

                </table>
                {this.state.tempdata&&this.state.tempdata.length==0&&
                <div className="no_records_found">No Records Found</div>
                }
                 {this.state.loading==true &&
    <CircularProgress />
            }
                {this.state.error_msg?
            <div className="failure_msg">{this.state.error_msg}</div> :''
            }
            {this.state.success_msg?
            <div className="success_msg">{this.state.success_msg}</div> :''
            }
                 {/* {this.state.tempdata>=this.state.pageSize? */}
                 {this.state.tempdata.length!=0&&
                <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
            pageSize={this.state.pageSize} itemRender={this.itemRender}
          />
                 }
                 {/* } */}
                
            </div>
        )
    }
}