import React, { Component } from 'react';
import Header from '../Component/Header';
export default class LandingPage extends Component {
  render() {
    return (
      <div> 
          <Header {...this.props}/>
              {this.props.children}
             
           </div>
    );
  }
}
