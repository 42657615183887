import React, {Component} from 'react';
import './styles.css';
import Image from '../../helpers/images';
import { Table, Tag, Pagination} from 'antd';
   
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;
 
// var bookingHeader = [{label : 'SVC Name', key : 'svc_name'}, {label: 'Customer Name', key : 'customer_name'},  {label : 'Vehicle Number', key : 'vehicle_number'},  {label : 'Booking ID', key : 'booking_id'}, {label : 'Booking Type', key : 'booking_type'}, {label : 'Slot', key : 'slot'},  {label : 'Ambassador', key : 'ambassador'},  {label : 'Booking Status', key : 'booking_status'}];

var bookingHeader = [{'id' : 1,  'svc_name' : 'Date', 'customer_name': 'Booking ID', 'vehicle_number' : 'Customer Name', 'booking_id' : 'Mobile Number',  'booking_type' : 'Vehicle Number', 'slot' : 'Amount', 'booking_status' : 'Paid'}];

var bookingList = [{'key' : 1, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348',  'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 2, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'}, 
 {'key' : 3, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 4, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 5, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 6, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'}, 
 {'key' : 7, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 8, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 9, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 10, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'}, 
 {'key' : 11, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'},
 {'key' : 12, 'svc_name' : '10/04/2020', 'customer_name' : 'ID0764', 'vehicle_number' : 'Bharathi', 'booking_type' : 'TN 04 CZ2348', 'booking_id' : '9578861249', 'slot' : 'Rs.15000',  'booking_status' : 'Paid'}
]
class SvcDetailsAddBooking extends Component{

    constructor(props){

        super(props);

        this.state = {
            bookingHeader : bookingHeader,

            bookingList : bookingList,
             
            currentPage : 1,
            pageSize:10


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination=(page,pageSize)=>{
        console.log("page",page);
        this.setState({currentPage:page});
        
        console.log("page size",pageSize);
      }
      showChanger=(current,size)=>{
        this.setState({pageSize:size})
      }

render(){

    const columns= bookingHeader.map(row => ({
        title:row.label,
        key:row.key,
        dataIndex:row.key
        // key: row.ClientId,     // here
               }))
      // const columns=[{}]

    return(
        <div>
             <div className="heading">
                 <div>Add Invoice</div></div> 
            
                         <div className="row">
                            
                             
                                    <div className="service_listing booking-list add_booking_list">
                                          <div className="date-pickers">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                         
                                    </div>
                             </div><div className="last_invoice">Last Invoice sent on 28/04/20</div>
                         </div>
                   


                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.bookingHeader && this.state.bookingHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.svc_name}</th>
                                                <th>{item.customer_name}</th>
                                                <th>{item.vehicle_number}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.booking_type}</th>
                                                <th>{item.slot}</th>
                                                <th>{item.ambassador}</th>
                                                <th>{item.booking_status}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                    {this.state.bookingList && this.state.bookingList.slice((this.state.currentPage-1) * this.state.pageSize, (this.state.currentPage-1) * this.state.pageSize + this.state.pageSize).map((item) => {

                                        return(
                                            <tr>
                                                <td>{item.svc_name}</td>
                                                <td>{item.customer_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>{item.booking_id}</td>
                                                <td>{item.booking_type}</td>
                                                <td>{item.slot}</td>
                                                <td>{item.ambassador}</td>
                                                <td><span className={item.booking_status}>{item.booking_status}</span></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            
                           
 
                        </div>
                     </div>

                     <button  className="btn btn-dark-blue next-button" >Submit</button>
                     <div className="total_amount">Total Amount for Settlement Invoice is Rs.2,32,000/-</div>
                     
             </div>
         
    )
}     
}



export default SvcDetailsAddBooking;