import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Pagination, Select } from 'antd';
import
{
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import
{
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,

  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import S3FileUpload from "react-s3";
import Aws from "../../../helpers/awskeys";

import moment from 'moment';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { DatePicker, Tooltip, Upload } from 'antd';

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
const { Dragger } = Upload;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
function handleChange(value)
{
  console.log(`selected ${value}`);
}
const MapWithAMarkers = withGoogleMap((props) =>
{
  console.log("newprops", props);
  const location = { lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  console.log("location", location);
  return (
    <div>
      <GoogleMap
        zoom={15}
        style={{ borderRadius: 20, width: '100%', height: '100%' }}
        // defaultCenter={props.currentLocation}
        // center={props.currentLocation}
        center={location}
        // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
        <Marker
          position={location}
        />
      </GoogleMap>
    </div>
  )
}

);

const { RangePicker } = DatePicker;

var activepickupHeader = [{ 'id': 1, 'booking_id': 'ID', 'city_zone': 'City / Zone', 'booking_info': 'Booking Info', 'customer_details': 'Customer Details', 'service_center_details': 'Service Centre Details', 'pilot_details': 'Pilot Details', 'state': 'State', 'status': 'Status', 'pilot_changes': 'Pilot Change', 'progress': 'Progress' }];


class ActiveAtCenter extends Component
{

  constructor(props)
  {

    super(props);

    this.state = {
      activepickupHeader: activepickupHeader,
      atcenterList: [],
      currentPage: 1,
      pageSize: 10,
      // scheduleOpen:false,
      // rescheduleOpen : false,
      // openCall : false,
      // reassignOpen : false,
      // preassignOpen:false,
      openactiveBooking: false,
      popupDisplay: '',
      latitude: 0,
      longitude: 0,
      activeID: null,
      assignID: null,
      svc_center_id: null,
      filenewlist: [],
      fileupload_error: null,
      fields: {
        slotdate: { validations: [{ name: 'required' }], error: null, value: moment() },
        slotime: { validations: [{ name: 'required' }], error: null, value: '' },
        estimate_amount: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }], error: null, value: '' },
        service_advisor: { validations: [{ name: 'required' }], error: null, value: '' },
        upload_image: { validations: [{ name: 'required' }], error: null, value: '' },
        notes: { validations: [{ name: 'required' }, { name: 'Noemptyspace' },], error: null, value: '' },
      },
      customer_name: null,
      fromDate: null,
      toDate: null,
      pickup_fields: {
        svc_center: { validations: [], error: null, value: '' },
      },



    }
  }

  itemRender = (current, type, originalElement) =>
  {
    if (type === 'prev')
    {
      return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls" />Prev</a>;
    }
    if (type === 'next')
    {
      return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw" /></a>;
    }
    return originalElement;
  }
  ActiveAtcenterList = () =>
  {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = { pageNo: this.state.currentPage, pageLimit: this.state.pageSize, customer_name: this.state.customer_name, vehicle_number: this.state.vehicle_number, svc_center: this.state.pickup_fields.svc_center.value, search_from: this.state.fromDate ? moment(this.state.fromDate).format(dateFormat) : '', search_to: this.state.toDate ? moment(this.state.toDate).format(dateFormat) : '' };
    console.log("actve", obj);
    apiservices.bookinglistatcentre(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var limit = response.count;
          var total_items = this.state.pageSize;
          var calc = Math.ceil(limit / total_items) * total_items;
          console.log("calc", calc);
          this.setState({ atcenterList: response.response, numbercount: calc })
          console.log("acive reponse", response);
        }
        else
        {
          this.setState({ error_msg: response.message })
        }
      })


  }
  componentWillMount()
  {
    this.ActiveAtcenterList();
    this.loadSeriveCenter();
  }
  onchangePagination = (page, pageSize) =>
  {
    console.log("page", page);
    this.setState({ currentPage: page }, function ()
    {
      this.ActiveAtcenterList();
    });

    console.log("page size", pageSize);
  }
  showChanger = (current, size) =>
  {
    this.setState({ pageSize: size, currentPage: 1 }, function ()
    {
      this.ActiveAtcenterList();
    });
  }
  BookingDialog = (data, value, svc_id) =>
  {
    console.log("svc centre id", svc_id);
    var self = this;
    this.setState({ popupDisplay: data, activeID: value.id, assignID: value.assign_id });
    var fields = this.state.fields;
    fields['estimate_amount'].value = '';
    fields['estimate_amount'].error = null;
    fields['notes'].value = '';
    fields['notes'].error = null;
    fields['upload_image'].value = '';
    fields['upload_image'].error = null;
    this.setState({ fields });
    this.loadaloocationlist();
    this.loadAdvisorDropdown(svc_id);
    this.setState({ openactiveBooking: true });
  }
  checkvalidations = (value, key) =>
  {

    var fields = this.state.fields;
    fields[key].value = value;
    console.log("value", value);
    var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
    // console.log(checkstatus);

    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      this.setState({ error: true })
      //errorblock
    } else
    {
      this.setState({ error: false })

      //successblock
    }
    this.setState({ fields });
  }
  popupSubmit = async (data) =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });

      if (data == 'schedule')
      {
        var fileList = this.state.fields.upload_image.value;
        console.log("else part", fileList);
        var temparray = [];
        for (var i in fileList)
        {
          var response = await S3FileUpload.uploadFile(fileList[i], config);
          console.log("response", response);
          temparray.push(response.location);
          if (fileList.length - 1 == i)
          {
            // console.log();
            var apiimagefile = temparray.join(',');
            this.scheduleBooking(apiimagefile);
          }
        }

      }
      if (data == 'reschedule')
      {
        var fileList = this.state.fields.upload_image.value;
        console.log("else part", fileList);
        var temparray = [];
        for (var i in fileList)
        {
          var response = await S3FileUpload.uploadFile(fileList[i], config);
          console.log("response", response);
          temparray.push(response.location);
          if (fileList.length - 1 == i)
          {
            // console.log();
            var apiimagefile = temparray.join(',');
            this.RescheduleBooking(apiimagefile);
          }
        }

      }

    }
    this.setState({ fields });
  }
  loadaloocationlist = () =>
  {
    const token = localStorage['accesstoken'];
    var obj = { slot_day: new Date() };
    apiservices.getSlotDetails(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ slotime: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['slotime'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })

  }
  loadAdvisorDropdown = (value) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { svc_center_id: value };
    console.log("service advisor", value);
    apiservices.bookingadvicerdropdown(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          this.setState({ service_advisor: response.response })
          var fields = this.state.fields;
          // if(!value){
          fields['service_advisor'].value = response.response.length > 0 ? response.response[0].id : '';
          this.setState({ fields })
          // }
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }

      })
  }
  scheduleBooking = (image) =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, slot_day: moment(this.state.fields.slotdate.value).format(dateFormat), slot_time: this.state.fields.slotime.value, invoice_amount: this.state.fields.estimate_amount.value, service_advisor: this.state.fields.service_advisor.value, invoice_doc: image, notes: this.state.fields.notes.value, assign_id: this.state.assignID };
    console.log("schedule booking", obj);
    apiservices.schedulebooking(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveAtcenterList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })

        } else
        {
          this.setState({ error_mresponseMsgsg: response.message })
        }

      })
  }
  RescheduleBooking = (image) =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    var obj = { id: this.state.activeID, slot_day: moment(this.state.fields.slotdate.value).format(dateFormat), slot_time: this.state.fields.slotime.value, invoice_amount: this.state.fields.estimate_amount.value, service_advisor: this.state.fields.service_advisor.value, invoice_doc: image, notes: this.state.fields.notes.value };
    console.log("schedule booking", obj);
    apiservices.reschedulebooking(obj, token)
      .then((response) =>
      {
        if (!response.error)
        {
          setTimeout(() =>
          {
            self.ActiveAtcenterList();
            self.setState({ openactiveBooking: false })
          }, 3000);
          this.setState({ responseMsg: response.message })

        } else
        {
          this.setState({ error_mresponseMsgsg: response.message })
        }

      })
  }
  closeDialog = () =>
  {
    console.log("cancl fields", this.state.cancelfields)
    this.setState({ openactiveBooking: false }, function ()
    {
      var fields = this.state.fields;
      fields['estimate_amount'].value = '';
      fields['notes'].value = '';
      fields['upload_image'].value = '';
      this.setState({ fields });

    });
  }
  customerFilter = (e) =>
  {
    var value = e.target.value;
    // this.loadsvclist();
    if (value == '')
    {
      value = null;
      // 
    }
    this.setState({ customer_name: value, vehicle_number: value, currentPage: 1 }, function ()
    {
      this.ActiveAtcenterList();
    });
  }
  checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.ActiveAtcenterList();
    })
  }
  ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.ActiveAtcenterList();
    })
  }

  loadSeriveCenter = (data) =>
  {
    const token = localStorage['accesstoken'];
    var obj = {};
    console.log("actve", obj);
    apiservices.bookingsvclistdropdown(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          this.setState({ svc_center: response.response })
          console.log("login response", response);

        } else
        {
          this.setState({ error_msg: response.message })
        }
      })
  }
  checksvcfilter = (data) =>
  {
    var pickup_fields = this.state.pickup_fields;
    pickup_fields['svc_center'].value = data;
    this.setState({ pickup_fields }, function ()
    {
      this.ActiveAtcenterList();
    })
  }
  makecall = (bkid, assignid, mobilenumber) =>
  {
    const token = localStorage['accesstoken'];
    var obj = { id: bkid, assign_id: assignid, mobile_no: mobilenumber };
    apiservices.makeCall(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          var self = this;
          this.setState({ success_call: response.response.message }, function ()
          {
            this.ActiveAtcenterList();
          });
          setTimeout(() =>
          {
            this.setState({ success_call: '' })

          }, 3000);

        } else
        {
          this.setState({ error_call: response.response.message }, function ()
          {
            this.ActiveAtcenterList();
          });
          setTimeout(() =>
          {
            this.setState({ error_call: '' })

          }, 3000);
        }
      })
  }
  render()
  {
    var self = this;
    var temparray = [];
    const props = {
      name: 'file',
      accept: 'application/pdf,image/*',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      fileList: self.state.filenewlist,
      showUploadList: {

        showRemoveIcon: true,
        removeIcon: <img src={images.file_remove} className="file_remove" />,
      },
      // <div className="antdiconcustomremove" onClick={(e)=>self.deleteItem(this)}>Remove </div>
      beforeUpload: (file) =>
      {
        console.log("filelist", self.state.filenewlist);
        var filenewlist = self.state.filenewlist;
        filenewlist.push(file);
        if (self.state.filenewlist.length < 2)
        {

          self.setState({ filenewlist }, function ()
          {
            self.checkvalidations(filenewlist, 'upload_image');
            self.props.sendImages && self.props.sendImages(filenewlist);
          });

        } else
        {
          filenewlist = filenewlist.splice(0, 1);
          self.setState({ fileupload_error: 'Max Files Upload is 1' })
          self.setState({ filenewlist });
        }
        console.log("beforeuploadfile", file)
        // return true;
      },
      onChange: (info) =>
      {

        var filenewlist = self.state.filenewlist;
        if (info.file.status == 'removed')
        {
          var findindex = filenewlist.findIndex((obj) => obj.uid == info.file.uid);
          filenewlist.splice(findindex, 1);
          self.setState({ filenewlist });
        }


        console.log("delete info", info);
      }
    }
    const { slotime, service_advisor, svc_center } = this.state;
    return (
      <div>

        <div className="search-header booking-mishaps booking-active">
          <div className="row">
            <div className="col-md-3">
              <div class="form-group search-list payment-search">
                <input type="text" placeholder="Search for Customer or Vehicle" className="form-control" name="customer_name" onChange={(e) => this.customerFilter(e)} />
                <img src={images.green_search} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-9">
              <div className="payment-dropdown"> <span className="payment">SVC Name:</span>
                <Select value={[this.state.pickup_fields.svc_center.value ? parseInt(this.state.pickup_fields.svc_center.value) : '']} onChange={(value) => this.checksvcfilter(value)} name="svc_center" >
                  <Option value="">All</Option>
                  {svc_center && svc_center.length > 0 && svc_center.map((item) =>
                  {
                    return (


                      <Option value={item.id}>{item.centre_name}</Option>

                    )
                  })}

                </Select>
              </div>
              <div className="service_listing booking-list payments-list">


                <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.error_call ?
          <div className="failure_msg">{this.state.error_call}</div> : ''
        }

        {this.state.success_call ?
          <div className="success_msg">{this.state.success_call}</div> : ''
        }
        <div className="table-list">


          <div class="table-responsive">


            <table class="table table-borderless">
              <thead>
                {this.state.activepickupHeader && this.state.activepickupHeader.map((item) =>
                {
                  return (
                    <tr>
                      <th>{item.booking_id}</th>
                      <th>{item.city_zone}</th>
                      <th className="text-left">{item.booking_info}</th>
                      <th>Slot Time</th>
                      <th className="customer_thead text-left">{item.customer_details}</th>
                      <th>Customer Name</th>
                      <th>Vehicle Number</th>
                      <th className="service_thead">{item.service_center_details}</th>
                      {/* <th>{item.pilot_details}</th> */}
                      <th>{item.progress}</th>
                      <th>{item.state}</th>
                      <th>{item.status}</th>
                      {/* <th>{item.pilot_changes}</th> */}
                    </tr>
                  )
                })}
              </thead>
              <tbody>

                {this.state.atcenterList && this.state.atcenterList.length > 0 && this.state.atcenterList.map((item, index) =>
                {

                  return (
                    <tr>
                      <Link to={{
                        pathname: `${this.props.parentProps.match.path}/booking_details/details/${item.id}/${item.assign_id}`,

                      }}><td>ID{item.id}</td></Link>
                      <td>{item.city}</td>
                      <td className="text-left">{moment(item.slot_day).format('DD/MM/YY')}</td>
                      <td>{item.slot_time}</td>
                      <td className="text-left">{item.vehicle_number}<br />
                        <div className="pick_address">{item.dropoff_address}</div><br />
                        <div className="pick_address">{item.customer_mobile}</div>

                        <img src={images.call} style={{ width: 38 }} onClick={() => this.makecall(item.id, item.assign_id, item.customer_mobile)} />

                      </td>
                      <td>{item.customer_name}</td>
                      <td>{item.vehicle_number}</td>
                      <td className="service-td"><span className="service-listing">{item.centre_name}</span><br />
                        <span className="service-listing">{item.service_advisor_name}</span>
                        {item.service_advisor_mobile_no != null ?
                          <img src={images.call} onClick={() => this.makecall(item.id, item.assign_id, item.service_advisor_mobile_no)} style={{ width: 38 }} /> : ''}<br />
                        <span className="service-listing">{item.cre_name}</span>
                        {item.cre_mobile_no != null ?
                          <img src={images.call} onClick={() => this.makecall(item.id, item.assign_id, item.cre_mobile_no)} style={{ width: 38 }} /> : ''}

                      </td>

                      <td>{item.progress}</td>
                      <td><Tooltip placement="top" title="Schedule" color="white" key="#0B485A" ><img src={images.schedule} onClick={() => this.BookingDialog('schedule', item, item.svc_center_id)} style={{ width: 38 }} /></Tooltip>
                        <Tooltip placement="top" title="Re-Schedule" color="white" key="#0B485A" ><img src={images.reschedule} style={{ width: 38 }} onClick={() => this.BookingDialog('reschedule', item, item.svc_center_id)} /></Tooltip>
                      </td>
                      <td>{item.status}</td>
                      {/* <td><Tooltip placement="top" title="PreAssign" color="white" key="#0B485A" ><img src={images.pre_assign} onClick={()=>this.BookingDialog('preassign',item.id)} style={{ width: 38 }} /></Tooltip> <Tooltip placement="top" title="Reassign  " color="white" key="#0B485A" ><img src={images.refresh} onClick={()=>this.BookingDialog('reassign',item.id)} style={{ width: 38 }} /></Tooltip></td> */}
                    </tr>
                  )
                })}
              </tbody>
            </table>

            {this.state.atcenterList && this.state.atcenterList.length == 0 &&
              <div className="no_records_found">No Records Found</div>
            }

          </div>

        </div>

        <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
        />
        <Dialog open={this.state.openactiveBooking} className="shopbox schedule-popup">
          <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.closeDialog()} /></div>
          {this.state.popupDisplay == 'schedule' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Schedule Booking
                   </div>
              <div>
                <div className="form-group">
                  <label>
                    Queue ID
                         </label>

                  <input type="text" className="form-control" value={this.state.activeID} readOnly />
                </div>

                <div className="form-group">
                  <div class="form-inline position_slot">
                    <label>Select Date</label>
                    <DatePicker className="slotdate" onChange={(value) => this.checkvalidations(value, 'slotdate')} value={this.state.fields.slotdate.value} disabledDate={current =>
                    {
                      return current && current < moment().startOf('day');
                    }} suffixIcon={<img src={images.date_picker} className="datepicker_img_slot" />} />
                    <span className="error_msg_adddealer">{this.state.fields['slotdate'].error && this.state.fields['slotdate'].error}</span>
                  </div>

                </div>



                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Time Slot</label>

                    <Select value={[parseInt(this.state.fields.slotime.value)]} onChange={(value) => this.checkvalidations(value, 'slotime')} name="slotime" >
                      {slotime && slotime.length > 0 && slotime.map((item) =>
                      {
                        return (
                          <Option disabled={item.is_disable == 1} value={item.id}>{item.time}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.fields['slotime'].error && this.state.fields['slotime'].error}</span>



                  </div>
                </div>


                <div className="form-group">
                  <label>
                    Estimate Amount
                         </label>

                  <input type="text" className="form-control" name="estimate_amount" value={this.state.fields.estimate_amount.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['estimate_amount'].error && this.state.fields['estimate_amount'].error}</span>
                </div>

                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Service Advisor</label>

                    <Select value={[parseInt(this.state.fields.service_advisor.value)]} onChange={(value) => this.checkvalidations(value, 'service_advisor')} name="service_advisor" >
                      {service_advisor && service_advisor.length > 0 && service_advisor.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.fields['service_advisor'].error && this.state.fields['service_advisor'].error}</span>



                  </div>
                </div>


                <div className="form-group">
                  <label>Upload Invoice Copy <span style={{ color: 'red', marginLeft: 10 }}>{this.state.fileupload_error}</span></label>

                  <div style={{ width: '115%' }}>
                    <Dragger {...props}  >
                      <div><button className="upload_btn">Upload Files</button></div>
                      <p className="ant-upload-text"></p>
                      <p className="ant-upload-hint">
                        Files can be in .jpg or .pdf format
                     </p>
                    </Dragger>

                    <span className="error_msg_driver">{this.state.fields['upload_image'].error && this.state.fields['upload_image'].error}</span>
                  </div>


                </div>



                <div className="form-group reason_box">
                  <label>
                    Notes
                         </label>

                  <textarea row="5" maxLength="300" className="form-control notesheight" name="notes" value={this.state.fields.notes.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.fields['notes'].error && this.state.fields['notes'].error}</span>


                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.popupSubmit('schedule')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'reschedule' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Re-Schedule Booking
                   </div>
              <div>
                <div className="form-group">
                  <label>
                    Queue ID
                         </label>

                  <input type="text" className="form-control" value={this.state.activeID} readOnly />
                </div>

                <div className="form-group">
                  <div class="form-inline position_slot">
                    <label>Select Date</label>
                    <DatePicker className="slotdate" onChange={(value) => this.checkvalidations(value, 'slotdate')} value={this.state.fields.slotdate.value} disabledDate={current =>
                    {
                      return current && current < moment().startOf('day');
                    }} suffixIcon={<img src={images.date_picker} className="datepicker_img_slot" />} />
                    <span className="error_msg_adddealer">{this.state.fields['slotdate'].error && this.state.fields['slotdate'].error}</span>
                  </div>

                </div>



                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Time Slot</label>

                    <Select value={[parseInt(this.state.fields.slotime.value)]} onChange={(value) => this.checkvalidations(value, 'slotime')} name="slotime" >
                      {slotime && slotime.length > 0 && slotime.map((item) =>
                      {
                        return (
                          <Option disabled={item.is_disable == 1} value={item.id}>{item.time}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.fields['slotime'].error && this.state.fields['slotime'].error}</span>



                  </div>
                </div>


                <div className="form-group">
                  <label>
                    Estimate Amount
                         </label>

                  <input type="text" className="form-control" name="estimate_amount" value={this.state.fields.estimate_amount.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                  <span className="error_msg_adddealer">{this.state.fields['estimate_amount'].error && this.state.fields['estimate_amount'].error}</span>
                </div>

                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Service Advisor</label>

                    <Select value={[parseInt(this.state.fields.service_advisor.value)]} onChange={(value) => this.checkvalidations(value, 'service_advisor')} name="service_advisor" >
                      {service_advisor && service_advisor.length > 0 && service_advisor.map((item) =>
                      {
                        return (
                          <Option value={item.id}>{item.name}</Option>
                        )
                      })}
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />

                    <span className="error_msg_adddealer">{this.state.fields['service_advisor'].error && this.state.fields['service_advisor'].error}</span>



                  </div>
                </div>


                <div className="form-group">
                  <label>Upload Invoice Copy <span style={{ color: 'red', marginLeft: 10 }}>{this.state.fileupload_error}</span></label>

                  <div style={{ width: '115%' }}>
                    <Dragger {...props}  >
                      <div><button className="upload_btn">Upload Files</button></div>
                      <p className="ant-upload-text"></p>
                      <p className="ant-upload-hint">
                        Files can be in .jpg or .pdf format
                     </p>
                    </Dragger>

                    <span className="error_msg_driver">{this.state.fields['upload_image'].error && this.state.fields['upload_image'].error}</span>
                  </div>


                </div>



                <div className="form-group reason_box">
                  <label>
                    Notes
                         </label>

                  <textarea row="5" maxLength="300" className="form-control notesheight" name="notes" value={this.state.fields.notes.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} ></textarea>
                  <span className="error_msg_adddealer">{this.state.fields['notes'].error && this.state.fields['notes'].error}</span>


                </div>
                {this.state.responseMsg && this.state.responseMsg ?
                  <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                {this.state.responseMsg && !this.state.responseMsg ?
                  <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                }
                <div className="btn_main">
                  <button className="delete_submit" onClick={() => this.popupSubmit('reschedule')}>Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'reassign' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Re-assign Driver
           </div>
              <div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select Reason</label>

                    <Select className="Reason_drop" name="Reason_drop" onChange={handleChange}>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />



                  </div>
                </div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select the Driver</label>

                    <Select className="Reason_drop" name="Reason_drop" onChange={handleChange}>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />



                  </div>
                </div>

                <div className="form-group reason_box">
                  <label>
                    Notes
                 </label>

                  <textarea row="5" col="4" className="form-control reason_box" name="address" ></textarea>


                </div>
                <div className="btn_main">
                  <button className="delete_submit">Submit</button>
                </div>
              </div>


            </div>
          }
          {this.state.popupDisplay == 'preassign' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Preassign Driver
         </div>
              <div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select Reason</label>

                    <Select className="Reason_drop" name="Reason_drop" onChange={handleChange}>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />



                  </div>
                </div>
                <div className="form-group">
                  <div class="form-inline position-relative reason_text">
                    <label>Select the Driver</label>

                    <Select className="Reason_drop" name="Reason_drop" onChange={handleChange}>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                      <option value="reason1">Reason1</option>
                    </Select>
                    <img src={images.dropdown} className="img-fluid reason_dropdown-img" />



                  </div>
                </div>

                <div className="form-group reason_box">
                  <label>
                    Notes
               </label>

                  <textarea row="5" col="4" className="form-control reason_box" name="address" ></textarea>


                </div>
                <div className="btn_main">
                  <button className="delete_submit">Submit</button>
                </div>
              </div>


            </div>}
          {this.state.popupDisplay == 'contact' &&
            <div className="modalcntntcls">
              <div className="delete_text">
                Contact Number
           </div>
              <div className="reschedule mt-5">
                <div className="form-group position-relative text-center">
                  <label className="form-label">Terry Gibson</label>
                  <div className="text-center contact-num">9876543210</div>


                </div>

              </div>


            </div>
          }
        </Dialog>


      </div>



    )
  }
}
export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(ActiveAtCenter)