import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import images from "../../helpers/images";
import './styles.css';
import Grid from '@material-ui/core/Grid';
// import ActiveDriverTable from "../ActiveDriverTable";
// import PickUpMain from "./PickUp/PickUpMain";
// import DropOffMain from "./DropOff/DropOffMain";
// import ChauffeurMain from "./Chauffeur/ChauffeurMain";
// import PickUpDropOffMain from "./PickUpDropOff/PickUpDropOffMain";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import ActiveMain from './ActiveMain';
import ClosedMain from './ClosedMain';
class ComplaintsMain extends Component {

    constructor(props) {
        super(props);
        this.state = {

            pilotMaster : true,
            addDriver : false
        }
    }

    AddDriver = () => {
  console.log("adddriver clicj")
        this.setState({pilotMaster : false});
         this.setState({addDriver  : true})
    }
    render() {
        return (
            <div>
                {this.state.pilotMaster &&  
                
                <div class="add-booking-menu">
                    {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
                        <Row>
                             <Col sm={12}> 
                                                           
                           <Nav variant="pills" className="flex-row add_booking" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/active/')&&"booking_active_cls"}`} exact={true} to={`${this.props.match.path}/active`} activeClassName="booking_active_cls">Active</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink className={`${window.location.hash.includes('/closed/')&&"booking_active_cls"}`} to={`${this.props.match.path}/closed`} activeClassName="booking_active_cls">Closed</NavLink>
                </Nav.Item>
              </Nav>
              </Col> 
                            <Col sm={12}>                            

                                <Switch>
                                <Route  path={`${this.props.match.path}/active`} render={props => (<ActiveMain {...props}/>)} />
                                <Route  path={`${this.props.match.path}/closed`} render={props => (<ClosedMain {...props}/>)} />
                {/* <Route exact path={`${this.props.match.path}/`} render={props => (<PickUpMain {...props}/>)} />
                <Route exact  path={`${this.props.match.path}/drop-off`} render={props => (<DropOffMain {...props}/>)} />
                <Route exact  path={`${this.props.match.path}/chauffeur`} render={props => (<ChauffeurMain {...props}/>)} />
                <Route exact  path={`${this.props.match.path}/pickupdrop`} render={props => (<PickUpDropOffMain {...props}/>)} /> */}
                
              </Switch>
                            </Col>
                        </Row>
                    {/* </Tab.Container> */}
                </div>
                }


               
            </div>

            
        )

    }

}


export default ComplaintsMain;