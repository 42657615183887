import React, { Component } from 'react';
import './style.css';
import apiservices from '../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import images from '../../helpers/images';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import
{
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
} from "react-router-dom";
import BookingDetailsTab from './BookingDetailsTab';
import BookingDetailsPickup from './BookingDetailsPickup';
import Item from 'antd/lib/list/Item';
import ValidationLibrary from '../../helpers/validationfunction';


class BookingDetails extends Component
{

    constructor(props)
    {
        console.log("booking details", props);

        super(props);

        this.state = {
            bookingDetails: null,
            customerDetails: null,
            bookingid: null,
            assignid: null,
            loading: false,
            editOpen: false,
            responseMsg: '',
            fields: {
                vehicle_modal: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'alphaNumaricOnly' }], error: null, value: '' },
                vehicle_varient: { validations: [], error: null, value: '' },
                vehicle_number: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'alphaNumaricOnly' }], error: null, value: '' },
                vehicle_brand: { validations: [{ name: 'required' }, { name: 'Noemptyspace' }, { name: 'alphaNumaricOnly' }], error: null, value: '' },
            },
            error: false
        }
    }
    goBack = () =>
    {
        this.props.history.push('/booking/booking_list/bokinglist_main/active');
        // this.props.history.goBack(); 
    }
    componentWillMount()
    {
        this.loadBookingDetails();
    }
    loadBookingDetails = (data) =>
    {
        this.setState({ loading: true })
        const token = localStorage['accesstoken'];
        var obj = { id: this.state.bookingid, assign_id: this.state.assignid };
        console.log("booking details obj", obj);
        apiservices.bookingdetailsview(obj, token)
            .then((response) =>
            {
                this.setState({ loading: false });
                if (!response.error)
                {
                    this.setState({ customerDetails: response.response.customersDetails, bookingDetails: response.response })
                    var fields = this.state.fields;
                    fields['vehicle_number'].value = response.response.customersDetails.vehicle_number;
                    fields['vehicle_brand'].value = response.response.customersDetails.vehicle_brand;
                    fields['vehicle_modal'].value = response.response.customersDetails.vehicle_modal;
                    fields['vehicle_varient'].value = response.response.customersDetails.vehicle_varient;

                } else
                {
                    this.setState({ responseMsg: response.message })
                }

            })
    }
    checkvalidations = (value, key) =>
    {

        var fields = this.state.fields;
        fields[key].value = value;
        console.log("value", value);
        var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
        // console.log(checkstatus);
        if (key == 'address')
        {
            this.onChangeAddress(value);
            this.getlocationbyAddress(value, "pickupLatLng");
        }
        if (key == 'dropaddress')
        {
            this.onChangeDropOnlyAddress(value);
            this.getlocationbyAddress(value, "dropLatLng");
            if (this.state.customerDetails && this.state.customerDetails.booking_type_id == 4)
            {
                this.onChangeDropoffAddress(value);
                this.getlocationbyAddress(value, "dropLatLng", "cloneddropLatLng", "clonedaddress");
            }
        }
        fields[key].error = checkstatus.msg;
        var getallkeys = Object.keys(fields);
        var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
        if (filtererrors.length > 0)
        {
            this.setState({ error: true })
            //errorblock
        } else
        {
            this.setState({ error: false })

            //successblock
        }
        this.setState({ fields });
    }
    updateBooking = () =>
  {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) =>
    {
      var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
      fields[obj].error = checkstatus.msg;
    })
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
    if (filtererrors.length > 0)
    {
      //errorblock
      this.setState({ error: true })
    } else
    {
      //successblock
      this.setState({ error: false });
      this.updateBookingDetails();
    }
    this.setState({ fields });

  }
  
  updateBookingDetails = () =>
  {
    var self = this;
    const token = localStorage['accesstoken'];
    console.log(this.props.match.params)
    var obj = {
        booking_id: this.state.bookingid, vehicle_number: this.state.fields.vehicle_number.value, vehicle_brand: this.state.fields.vehicle_brand.value, vehicle_modal: this.state.fields.vehicle_modal.value,vehicle_varient:this.state.fields.vehicle_varient.value
    };

    console.log("customer details obj", obj);
    apiservices.updatebookingVehicledetails(obj, token)
      .then((response) =>
      {
        // this.setState({loading:false});
        if (!response.error)
        {
          console.log("success", response)
          setTimeout(() =>
          {
            self.loadBookingDetails();
            self.setState({ editOpen: false })
          }, 3000);
          this.setState({ responseMsg: response.message })
        } else
        {
          console.log("failure", response)
          this.setState({ responseMsg: response.message })
        }

      })
  }
    receivebookingid = (data, data1) =>
    {
        this.setState({ bookingid: data, assignid: data1 }, function ()
        {
            this.loadBookingDetails()
        })
        console.log("receivebooking id", data);
    }
    EditVehicle = () =>
    {
        this.setState({ editOpen: true, responseMsg: '' })
        this.loadBookingDetails();
    }
    
    render()
    {
        const { customerDetails } = this.state;
        return (

            <div className="container">

                <div className="back-btns">
                    <span className="back-button" onClick={() => this.goBack()}><img src={images.FadedArrowImg} className="img-flud" />Back to the Lists</span>                                                                    
                    </div>
                <div className="booking-details-box">

                    <div>
                        <h3>{customerDetails && customerDetails.customer_salutation} {customerDetails && customerDetails.customer_name}</h3>

                        <div className="row mt-4">
                            <div className="col-md-4">
                                <div className="left-booking">
                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Mobile Number</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.customer_mobile}</div>
                                    </div>

                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Email ID</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.customer_email}</div>
                                    </div>

                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Queue ID</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.booking_id}</div>
                                    </div>

                                </div>

                            </div>
                            <div className="col-md-4">
                                {/* <div className="booking-img">
              <div className="booking-center-img">
                  <img src={images.play} className="img-fluid"/>
                  <br/>
                  <span className="play_list">Play</span>
              </div>
              <div className="booking-center-img"><img src={images.pause_booking} className="img-fluid"/>
              <br/>
                  <span className="play_list">Pause</span>
              </div>
          </div> */}
                            </div>
                            <div className="col-md-4">
                                <div className="left-booking right-booking">
                                    <div className="bookings-list">
                                        <img src={images.edit_icon} style={{ opacity: customerDetails && customerDetails.status_id != 3 ? 1 : 0.5 }} className="img-fluid edit-details" onClick={() => customerDetails.status_id != 3 ? this.EditVehicle() : ''} />

                                        <div className="booking-card-first first-card">Vehicle Number</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.vehicle_number}</div>
                                    </div>

                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Vehicle Brand</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.vehicle_brand}</div>
                                    </div>

                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Vehicle Model</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.vehicle_modal}</div>
                                    </div>

                                    <div className="bookings-list">
                                        <div className="booking-card-first first-card">Vehicle Variant</div>
                                        <div className="booking-card-first second-card">{customerDetails && customerDetails.vehicle_varient}  </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                {this.state.loading == true &&
                    <CircularProgress />}
                <BookingDetailsTab {...this.props} sendbookingid={(data, data1) => this.receivebookingid(data, data1)} />

                <Dialog open={this.state.editOpen} className={`booking_pickup ${this.state.popupDisplay == 'map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px', cursor: 'pointer' }} onClick={() => this.setState({ editOpen: false })} /></div>
                    <div className="modalcntntcls" style={{ width: '90%' }}>
                        <div className="delete_text">
                            Vehicle Details
                   </div>
                        <div>


                            <div className="form-group">
                                <label className="label_paused">Vehicle Number</label>
                                <input type="text" className="form-control input_paused" name="vehicle_number" value={this.state.fields.vehicle_number.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                <span className="error_msg_adddealer">{this.state.fields['vehicle_number'].error && this.state.fields['vehicle_number'].error}</span>
                            </div>
                            <div className="form-group">
                                <label className="label_paused">Vehicle Brand</label>
                                <input type="text" className="form-control input_paused" name="vehicle_brand" value={this.state.fields.vehicle_brand.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                <span className="error_msg_adddealer">{this.state.fields['vehicle_brand'].error && this.state.fields['vehicle_brand'].error}</span>
                            </div>
                            <div className="form-group">
                                <label className="label_paused">Vehicle Model</label>
                                <input type="text" className="form-control input_paused" name="vehicle_modal" value={this.state.fields.vehicle_modal.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                <span className="error_msg_adddealer">{this.state.fields['vehicle_modal'].error && this.state.fields['vehicle_modal'].error}</span>
                            </div>
                            <div className="form-group">
                                <label className="label_paused"> Vehicle Variant (optional)</label>
                                <input type="text" className="form-control input_paused" name="vehicle_varient" value={this.state.fields.vehicle_varient.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                <span className="error_msg_adddealer">{this.state.fields['vehicle_varient'].error && this.state.fields['vehicle_varient'].error}</span>
                            </div>


                            {this.state.responseMsg && this.state.responseMsg ?
                                <div className="failure_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                            }
                            {this.state.responseMsg && !this.state.responseMsg ?
                                <div className="success_msg">{this.state.responseMsg && this.state.responseMsg}</div> : ''
                            }
                            <div className="btn_main">
                                <button className="delete_submit" onClick={() => this.updateBooking()}>Submit</button>
                            </div>
                        </div>


                    </div>
                </Dialog>

            </div>
        )


    }
}
export default BookingDetails;