import React, { Component } from 'react';
import './styles.css';
import { toast, ToastContainer } from 'react-toastify';
import apiservices from '../../helpers/apiservices';
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      mobile_no:'',
      error_msg:null,
      userDetails: null,
      toastId: null,
      shopdetails: props.shop_details,

    }
  }

  gotoLogin =()=>{
    if(this.props.loadlogin){
      var obj={type:'login'};
      this.props.loadlogin(obj)
      }
  }
  changeForgot =(e)=>{
    this.setState({ [e.target.name]: e.target.value });
  }
  loginSubmit=()=>{
    
    var mobile_no = /^(0|[1-9][0-9]{9})$/;
      var password = /^\S{6,15}$/;
      if (this.state.mobile_no == "" || this.state.mobile_no == undefined) {
       this.setState({error_msg:"Please enter Mobile Number"})
      }else if (!mobile_no.test(this.state.mobile_no)) {
        this.setState({error_msg:"Please enter valid Mobile Number"})
        }
      else{
        this.setState({error_msg:''})
        var obj={mobile_no:this.state.mobile_no,type: 'otp'};
        apiservices.forgotpassword(obj)
        .then((response)=>{
          if(!response.error){
            // if(this.props.otpverification){
            //   var obj={type:'otp'};
              this.props.otpverification(obj);
              // }
            if (! toast.isActive(this.toastId)) {
              this.toastId = toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT
              
            });
              }
              
               
            console.log("forgot response",response);
          }else{
            this.setState({error_msg:response.message})
          }
            
        })
          //   if(this.props.otpverification){
          // console.log("inside");
          // var obj={type:'otp',forgot:true};
          // this.props.otpverification(this.state.mobilenumber);
          // }
        }
  }
  render() {
    return (
        <div className="login_main">
      <div className="forgotcontainer">
        <div className="forgottext">Forgot Password</div>
        <div className="formdiv forgot_formdiv">
          <label className="labeltxt">Enter your Mobile Number</label>
          <div>
            <input name="mobile_no" className="brdrcls"  type="text" onChange={(e) => this.changeForgot(e)} />
          </div>

        </div>
      

        <div className="error_msg">{this.state.error_msg}</div>
        <div style={{ padding: 20, marginTop: 10,marginBottom:50 }}>
          <button className="loginbtn" onClick={() => this.loginSubmit()}>SUBMIT</button>
        </div>
        <p className="remember_text" onClick={this.gotoLogin}> Remember Password ? <span>Login</span> </p>


      </div>
      </div>
    )
  }
}
export default ForgotPassword;
