import React, {Component} from 'react';
import './style.css';
import images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import CircularProgress from '@material-ui/core/CircularProgress';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { DatePicker ,Select} from 'antd';
const { Option } = Select;
class BookingSVCDetails extends Component{

    constructor(props){

        super(props);

        this.state = {

            addphonenumber:false,
            svcDetails:null,
            loading:false,
            customersDetails:null,
              editfields:{
                svc_list:{validations:[{name:'required'}],error:null,value:'Select'},
                cre_name:{validations:[{name:'required'}],error:null,value:'Select'},
                advisor_name:{validations:[{name:'required'}],error:null,value:'Select'},
              }
        }
    }

    AddPhoneNumber=(data)=>{

        this.setState({dialogDisplay:data,addphonenumber:true})
        this.loadsvclistDropdown();
    }
      editvalidations=(value,key)=>{

        var editfields =this.state.editfields;
        editfields[key].value=value;
        console.log("value",value);
        if(key=='svc_list'){
          this.loadcreDropdown(value);
          this.loadAdvisorDropdown(value);
        }
        var checkstatus=ValidationLibrary.checkValidation(value,editfields[key].validations);
        // console.log(checkstatus);
        
        editfields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(editfields);
        var filtererrors=getallkeys.filter((obj)=>editfields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
      
          //successblock
        }
        this.setState({editfields});
      }
      updateSVC=()=>{
        var editfields =this.state.editfields;
        var getallkeys=Object.keys(editfields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(editfields[obj].value,editfields[obj].validations);
        editfields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>editfields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
          this.updateSVCDetails();
        }
        this.setState({editfields});
  
      }
      updateSVCDetails =() =>{
        var self=this;
      const token=localStorage['accesstoken'];
      var obj={id:this.props.match.params.id,svc_center_id:this.state.editfields.svc_list.value,cre:this.state.editfields.cre_name.value,service_advisor:this.state.editfields.advisor_name.value,assign_id:this.props.match.params.assignid};
      console.log("svc details obj", obj);  
      apiservices.updatebookingsvcdetails(obj,token)
            .then((response)=>{
                // this.setState({loading:false});
                if(!response.error){
                  setTimeout(() => {
                    self.loadSVCDetails();
                    self.setState({addphonenumber:false})
                  }, 3000);
                  this.setState({responseMsg:response.message})
                }else{
                  this.setState({responseMsg:response.message})
                }
     
              })
      }
      loadSVCDetails=(data)=>{
        this.setState({loading:true})
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
        console.log("booking svc details obj", obj);   
        apiservices.bookingdetailsview(obj,token)
              .then((response)=>{
                  this.setState({loading:false});
                  if(!response.error){
                      this.setState({svcDetails:response.response.svcDetails,customersDetails:response.response&&response.response.customersDetails})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    componentWillMount(){
      this.loadSVCDetails();
    }
    loadsvclistDropdown=()=>{
      const token=localStorage['accesstoken'];
      var obj={};
            apiservices.bookingsvclistdropdown(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({svc_list:response.response})
                var editfields=this.state.editfields;
                // if(!value){
                editfields['svc_list'].value=response.response.length>0?response.response[0].id:'Select';            
                this.setState({editfields})
                // }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    loadcreDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={svc_center_id:value};
            apiservices.bookingcredropdown(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({cre_name:response.response})
                var editfields=this.state.editfields;
                // if(!value){
                editfields['cre_name'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({editfields})
                // }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    loadAdvisorDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={svc_center_id:value};
            apiservices.bookingadvicerdropdown(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({advisor_name:response.response})
                var editfields=this.state.editfields;
                // if(!value){
                editfields['advisor_name'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({editfields})
                // }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    render(){
const{svcDetails,svc_list,cre_name,advisor_name,customersDetails} =this.state;
console.log("svc details",customersDetails);
        return(
             <div>
                  <div className="whole-svc-details">
                  {this.state.loading==true&&
    <CircularProgress />}
        <h4>{svcDetails&&svcDetails.centre_name}</h4>
                      <img src={images.edit_icon}style={{opacity:customersDetails&&customersDetails.status_id!=3?1:0.5}} onClick={()=>customersDetails&&customersDetails.status_id!=3?this.AddPhoneNumber('edit'):''} className="img-fluid edit-details"/>
                     <div className="whole-svcDetails clearfix">
                       {svcDetails&&svcDetails.cre_name!=null?
                      <div className="details-svc">
                          <div className="svc-label">
                          CRE Name
                          </div>
                      <div className="svc-details">{svcDetails&&svcDetails.cre_name}</div>
                      </div>:''}
                      {svcDetails&&svcDetails.cre_mobile_no !=null?
                      <div className="details-svc">
                          <div className="svc-label">
                          CRE Number
                          </div>
                          <div className="svc-details">{svcDetails&&svcDetails.cre_mobile_no} <img src={images.blue_call} className="img-fluid" style={{cursor:'pointer'}}/></div>
                      </div>:''}
                      {svcDetails&&svcDetails.service_advisor_name!=null?
                      <div className="details-svc">
                          <div className="svc-label">
                          Advisor Name
                          </div>
                   <div className="svc-details">{svcDetails&&svcDetails.service_advisor_name}</div>
                      </div>:''}
                      {svcDetails&&svcDetails.service_advisor_mobile_no!=null?
                      <div className="details-svc">
                          <div className="svc-label">
                          Advisor Number
                          </div>
                          <div className="svc-details">{svcDetails&&svcDetails.service_advisor_mobile_no}<img src={images.blue_call} className="img-fluid" style={{cursor:'pointer'}}/> </div>
                      </div>:''}

                      <div className="details-svc">
                          <div className="svc-label">
                          Manager Name
                          </div>
        <div className="svc-details">{svcDetails&&svcDetails.contact_name}</div>
                      </div>

                      <div className="details-svc">
                          <div className="svc-label">
                          Manager Number
                          </div>
                          <div className="svc-details">{svcDetails&&svcDetails.contact_number}<img src={images.blue_call} className="img-fluid" style={{cursor:'pointer'}}/> </div>
                      </div>
 
                 </div> 

                  </div>   
                  <Dialog  open={this.state.addphonenumber}  className={`booking_pickup ${this.state.popupDisplay=='map' && 'map_booking_pickup'}`}>
                    <div className="modalclosecls" ><img src={images.popup_clseicon} style={{ width: '15px',cursor:'pointer' }} onClick={() => this.setState({addphonenumber:false})} /></div>
    {this.state.dialogDisplay=='edit'&&
     <div className="modalcntntcls">
     <div className="delete_text">
         SVC Details
     </div>
     <div>
     <div className="form-group pos_relative" style={{position:'relative'}}>
           <label className="label_paused">
           SVC Centre
           </label>
           <Select value={[parseInt(this.state.editfields.svc_list.value)]}  onChange={(value) => this.editvalidations(value,'svc_list')} name="svc_list" >
                          {svc_list&&svc_list.length>0&&svc_list.map((item)=>{
                              return(
                              <Option value={item.id}>{item.centre_name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid svcdetails_drop_images"/> 
                           
           <span className="error_msg_adddealer">{this.state.editfields['svc_list'].error&&this.state.editfields['svc_list'].error}</span> 
           
       </div> 
     <div className="form-group pos_relative" style={{position:'relative'}}>
           <label className="label_paused">
           CRE Name
           </label>
           <Select value={[parseInt(this.state.editfields.cre_name.value)]}  onChange={(value) => this.editvalidations(value,'cre_name')} name="cre_name" >
                          {cre_name&&cre_name.length>0&&cre_name.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid svcdetails_drop_images"/> 
                           
           <span className="error_msg_adddealer">{this.state.editfields['cre_name'].error&&this.state.editfields['cre_name'].error}</span> 
           
       </div>
           <div className="form-group pos_relative" style={{position:'relative'}}>
             <label className="label_paused">
             Advisor Name
             </label>
             <Select value={[parseInt(this.state.editfields.advisor_name.value)]}  onChange={(value) => this.editvalidations(value,'advisor_name')} name="advisor_name" >
                          {advisor_name&&advisor_name.length>0&&advisor_name.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={images.dropdown} className="img-fluid svcdetails_drop_images"/> 
           <span className="error_msg_adddealer">{this.state.editfields['advisor_name'].error&&this.state.editfields['advisor_name'].error}</span> 
         </div>
       {this.state.responseMsg&&this.state.responseMsg?
   <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
     }
   {this.state.responseMsg&&!this.state.responseMsg?
   <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
   }
         <div className="btn_main">
             <button className="delete_submit" onClick={()=>this.updateSVC()}>Submit</button>
         </div>
    </div>
     
      
      </div>}
                    </Dialog>              
             </div>
        )
    }
    componentDidMount(){
      this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
    }
}


export default BookingSVCDetails;
