import React, { Component } from 'react';
import { Table, Tag, Pagination, Select} from 'antd';
import images from '../../../helpers/images';
import moment from 'moment';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { DatePicker } from 'antd';
import './styles.css'

const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var driverHeader = [{'pilot_id' : 'Pilot ID', 'pilot_name' : 'Pilot Name', 'city' : 'City', 'shift' : 'Shift', 'status' : 'Status', 'logged_in' : 'Logged In At', 'location_update_time':'Location Updated Time', 'live_location' : 'Live Location', 'notes' : 'Notes','audit' : 'Audit'}];
const { RangePicker } = DatePicker;

export default class Drivers extends Component {
  constructor(props){
    super(props);
    this.state={
      driverHeader:driverHeader,
      driverList:[],
      currentPage : 1,
            pageSize:10,
            driver_name:null,
            city_search:null
    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  loadDriverAttendance=()=>{
    // this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver_name,city:this.state.city_search};
    console.log("actve", obj);     
    apiservices.listdriverattendance(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  console.log("calc",calc);
                  this.setState({driverList:response.response, numbercount:calc})
                  console.log("driver attendance", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadDriverAttendance();
}
onchangePagination = (page, pageSize) => {
  console.log("page", page);
  this.setState({ currentPage: page }, function(){
      this.loadDriverAttendance();        
  });
  

  console.log("page size", pageSize);
}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadDriverAttendance(); 
  });
}
cityFilter =(e) =>{
  var value=e.target.value;
  // this.loadsvclist();
  if(value==''){
    value=null;
    // 
  }
  this.setState({[e.target.name]:value,currentPage:1},function(){
    this.loadDriverAttendance();
  });
}
checkfiltervalidations = (data) =>
  {
    console.log("priority", data);

    this.setState({ fromDate: data[0], toDate: data[1] }, function ()
    {
      this.loadDriverAttendance();
    })
}
ClearDatepicker = () =>
  {
    this.setState({ fromDate: null, toDate: null }, function ()
    {
      this.loadDriverAttendance();
    })
  }
  render() {
    const offset = moment().utcOffset();
    console.log("driver list",this.state.driverList)
    return (
      <div className="zones_main"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for driver" className="form-control" name="driver_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9" style={{display:'flex'}}>
                             <div className="service_listing booking-list payments-list">
                             <div className="date-pickers booking">
                  <img src={images.date_picker} className="img-fluid date-icon" />
                  <span className="from">From :</span>
                  <span className="to">To :</span>
                  <RangePicker
                    allowClear={false}
                    onChange={(value) => this.checkfiltervalidations(value)}
                    //    defaultValue={[null,null]}
                    value={[this.state.fromDate, this.state.toDate]}

                  />
                  <span className="datepicker_clear" ><img src={images.file_remove} onClick={() => this.ClearDatepicker()} /></span>
                </div>
                </div>
                </div>
                             <div className="col-md-4">
                             
                             <div className="city_search" style={{display:'flex'}}>
                  <label>City / Zone:</label>
                 <input type="text" className="form-control citysearchbox"  name="city_search" onChange={(e)=>this.cityFilter(e)}/>
                 </div>
                             
                             
                             </div>
                         </div>
                    </div>

                    <div class="driverinfodiv"> 
 
      <div className="driverActiveTable">
                <table class="table table-hover">
                    <thead>
                        {this.state.driverHeader && this.state.driverHeader.map((item) => {

                        return(
                        <tr>
                            
                            <th>{item.pilot_id}</th>
                            <th className="text-left">{item.pilot_name}</th>
                            <th>{item.city}</th>
                            <th>{item.shift}</th>
                            <th>{item.status}</th>
                            <th>{item.logged_in}</th>
                            <th>{item.location_update_time}</th>
                            {/* <th>{item.live_location}</th> */}
                            <th>{item.notes}</th>
                            <th>{item.audit}</th>
                        </tr>
                        )
                        })}
                    </thead>
                    <tbody>
                    {this.state.driverList &&this.state.driverList.length>0&&this.state.driverList.map((item) => {
              
                
    return (<tr>
                                 
                                <td>ID{item.id}</td>
                                <td style={{ textAlign: "left" }} >
                                    <div className="driverImagediv" >
                                        <img src={item.image_url?item.image_url:Image.default_image} className="driverImage" />
                                    </div>
                                    <div className="driverImagename" >
                                        {item.first_name}  {item.last_name} 
                                    </div>
                                    <div className="driverImagename" >
                                       <span style={{color:'#3A7CA5'}}>Age {item.age}</span> 
                                    </div>
                              <div className="driverImageNumber" >{item.mobile_no}</div>
                                </td>
                                <td > {item.city}</td>
                                <td >{item.shift}</td>
                                <td >{item.attendance_status}</td>
                                <td >{item.login_time!=null?moment.utc(item.login_time).format("hh:mm A"):''}                                  </td>
                                <td >{moment.utc(new Date(item.location_update_at)).utcOffset(offset).format("hh:mm A")} </td>
                                {/* <td >{item.live_location}</td> */}
                                <td >{item.note}</td>
                                <td >{item.is_audit==0?'false':'true'}</td>
                            </tr>);
                        })}




                    </tbody>

                </table>

                {this.state.driverList&&this.state.driverList.length==0&&
                <div className="no_records_found">No Records Found</div>
                }



            </div>

           
    </div>
    <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
        </div>
       </div>
    );
  }
}
