import React, { Component } from 'react';
import './style.css';
import apiservices from '../../helpers/apiservices';
import Images from '../../helpers/images';
import { Pagination, Select, Tooltip } from 'antd';
import ValidationLibrary from '../../helpers/validationfunction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
var DefaulterHeader = [
  {
    id: 1,
    driver_id: 'Pilot ID',
    driver_details: 'Driver Details',
    age: 'Age',
    shift: 'Shift',
    swife_machine_id: 'Swipe Machine ID',
    defaulting_age: 'Defaulting Age',
    reason: 'Reason',
    enable: 'Enable',
    delete: 'Delete',
  },
];

const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}
class PilotDefaulter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DefaulterHeader: DefaulterHeader,
      DefaulterItem: [],
      currentPage: 1,
      pageSize: 10,
      name_search: null,
      reason_search: null,
      loading: false,
      deleteOpen: false,
      fields: {
        reasons: { validations: [], error: null, value: '' },
      },
    };
  }

  deleteOpen = (data) => {
    console.log('data delete', data);
    var fields = this.state.fields;

    fields['reasons'].value = '';

    this.setState({
      deleteOpen: true,
      deleteid: data,
      fields,
      responseMsg: '',
    });
  };
  deleteClose = () => {
    this.setState({ deleteOpen: false });
  };
  itemRender = (current, type, originalElement) => {
    if (type === 'prev') {
      return (
        <a className='prevnext'>
          <img src={Images.arrow_green} className='arrow_cls' />
          Prev
        </a>
      );
    }
    if (type === 'next') {
      return (
        <a className='prevnext'>
          {' '}
          Next <img src={Images.arrow_forward} className='arrow_cls nextarw' />
        </a>
      );
    }
    return originalElement;
  };
  componentWillMount() {
    this.defaulterList();
  }
  defaulterList = () => {
    // this.setState({loading:true});
    const token = localStorage['accesstoken'];
    var obj = {
      pageNo: this.state.currentPage,
      pageLimit: this.state.pageSize,
      name: this.state.name_search,
      reason: this.state.reason_search,
      city: '',
      zone: '',
    };
    console.log('actve', obj);
    apiservices.defaulterdriverlist(obj, token).then((response) => {
      // this.setState({loading:false});
      if (!response.error) {
        var limit = response.count;
        var total_items = this.state.pageSize;
        var calc = Math.ceil(limit / total_items) * total_items;
        console.log('calc', calc);
        this.setState({ DefaulterItem: response.response, numbercount: calc });
        console.log('acive reponse', response);
      } else {
        this.setState({ error_msg: response.message });
      }
    });
  };
  cityFilter = (e) => {
    var value = e.target.value;
    console.log('city search', value);
    // this.loadsvclist();
    if (value == '') {
      value = null;
      //
    }
    this.setState({ [e.target.name]: value, currentPage: 1 }, function () {
      this.defaulterList();
    });
  };
  activeDriver = (data) => {
    const token = localStorage['accesstoken'];
    var obj = { id: data };
    console.log('actve', obj);
    apiservices.activeDriver(obj, token).then((response) => {
      if (!response.error) {
        this.setState({ responseMsg: response.message });
        this.defaulterList();
      } else {
        this.setState({ responseMsg: response.message });
      }
    });
    console.log('active id', data);
  };
  onchangePagination = (page, pageSize) => {
    console.log('page', page);
    this.setState({ currentPage: page }, function () {
      this.defaulterList();
    });

    console.log('page size', pageSize);
  };
  showChanger = (current, size) => {
    this.setState({ pageSize: size, currentPage: 1 }, function () {
      this.defaulterList();
    });
  };

  DisableDriver = () => {
    var fields = this.state.fields;
    var getallkeys = Object.keys(fields);
    getallkeys.map((obj) => {
      var checkstatus = ValidationLibrary.checkValidation(
        fields[obj].value,
        fields[obj].validations,
      );
      fields[obj].error = checkstatus.msg;
    });
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      //errorblock
      this.setState({ error: true });
    } else {
      //successblock
      this.setState({ error: false });
      const token = localStorage['accesstoken'];
      var obj = {
        id: this.state.deleteid,
        reason: this.state.fields.reasons.value,
      };
      console.log('disable obj', obj);
      apiservices.permenentDeleteDriver(obj, token).then((response) => {
        var self = this;
        if (!response.error) {
          setTimeout(() => {
            self.defaulterList();
            self.setState({ deleteOpen: false });
          }, 3000);

          this.setState({ responseMsg: response.message });
        } else {
          this.setState({ responseMsg: response.message });
        }
      });
    }
    this.setState({ fields });
  };
  checkvalidations = (value, key) => {
    var fields = this.state.fields;
    fields[key].value = value;
    console.log('value', value);
    var checkstatus = ValidationLibrary.checkValidation(
      value,
      fields[key].validations,
    );
    // console.log(checkstatus);
    if (key == 'address') {
      this.getlocationbyAddress(value);
    }
    fields[key].error = checkstatus.msg;
    var getallkeys = Object.keys(fields);
    var filtererrors = getallkeys.filter((obj) => fields[obj].error != '');
    if (filtererrors.length > 0) {
      this.setState({ error: true });
      //errorblock
    } else {
      this.setState({ error: false });

      //successblock
    }
    this.setState({ fields });
  };
  render() {
    const { reason_type } = this.state;
    return (
      <div className='container'>
        <div className='search-header driver-search'>
          <div className='row'>
            <div className='col-md-4'>
              <div class='form-group search-list payment-search'>
                <input
                  type='text'
                  placeholder='Search Name'
                  className='form-control'
                  name='name_search'
                  onChange={(e) => this.cityFilter(e)}
                />
                <img src={Images.green_search} className='img-fluid' />
              </div>
            </div>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <div className='city_search' style={{ display: 'flex' }}>
                <label>Reason:</label>
                <input
                  type='text'
                  name='reason_search'
                  className='form-control citysearchbox'
                  onChange={(e) => this.cityFilter(e)}
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        {this.state.responseMsg && this.state.responseMsg ? (
          <div className='failure_msg'>
            {this.state.responseMsg && this.state.responseMsg}
          </div>
        ) : (
          ''
        )}
        {this.state.responseMsg && !this.state.responseMsg ? (
          <div className='success_msg'>
            {this.state.responseMsg && this.state.responseMsg}
          </div>
        ) : (
          ''
        )}
        <div className='driverinfodiv defaulter-pilot'>
          <div className='driverActiveTable mt-0 defaulter-pilot'>
            <table class='table table-hover'>
              <thead>
                {this.state.DefaulterHeader &&
                  this.state.DefaulterHeader.map((item) => {
                    return (
                      <tr>
                        <th>{item.driver_id}</th>
                        <th>{item.driver_details}</th>
                        <th>{item.age}</th>
                        <th>{item.shift}</th>
                        <th>{item.swife_machine_id}</th>
                        <th>{item.defaulting_age}</th>
                        <th>{item.reason}</th>
                        <th>{item.enable}</th>
                        <th>{item.delete}</th>
                      </tr>
                    );
                  })}
              </thead>
              <tbody>
                {this.state.DefaulterItem &&
                  this.state.DefaulterItem.map((item) => {
                    return (
                      <tr>
                        <td>ID {item.id}</td>
                        <td>
                          <div className='driverImagediv'>
                            <img
                              src={
                                item.image_url
                                  ? item.image_url
                                  : Images.default_image
                              }
                              className='img-fluid driverImage'
                            />
                          </div>
                          <div className='driverImagename'>
                            {item.first_name} {item.last_name}
                          </div>
                          <div className='driverImageNumber'>
                            {item.mobile_no}
                          </div>
                        </td>
                        <td> {item.age}</td>
                        <td>{item.shift}</td>
                        <td>{item.swipe_machine_number}</td>
                        <td>{item.defaulter_age}</td>
                        <td>{item.defaulter_reason}</td>
                        <td>
                          <Tooltip
                            placement='top'
                            title='Active Driver'
                            color='white'
                            key='#0B485A'>
                            <img
                              src={Images.approvel}
                              className='img-fluid  icons_editdelete'
                              style={{ width: 40, height: 'auto' }}
                              onClick={() => this.activeDriver(item.id)}
                            />
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            placement='top'
                            title='Disable Driver'
                            color='white'
                            key='#0B485A'>
                            <img
                              src={Images.delete_icon}
                              onClick={() => this.deleteOpen(item.id)}
                              className='img-fluid  icons_editdelete'
                              style={{ width: 40, height: 'auto' }}
                            />
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {this.state.DefaulterItem &&
              this.state.DefaulterItem.length == 0 && (
                <div className='no_records_found'>No Records Found</div>
              )}
            {this.state.loading == true && <CircularProgress />}
          </div>
        </div>
        <Pagination
          total={this.state.numbercount}
          current={this.state.currentPage}
          onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
          pageSize={this.state.pageSize}
          pageSizeOptions={['5', '10', '15']}
          onShowSizeChange={(current, size) => this.showChanger(current, size)}
          showSizeChanger
          itemRender={this.itemRender}
        />

        <Dialog open={this.state.deleteOpen} className='shopbox'>
          <div className='modalclosecls'>
            <img
              src={Images.popup_clseicon}
              style={{ width: '15px', cursor: 'pointer' }}
              onClick={() => this.deleteClose()}
            />
          </div>
          <div className='modalcntntcls'>
            <div className='delete_text'>Delete Pilot</div>
            <div>
              <div className='form-group reason_box'>
                <label>Notes</label>

                <textarea
                  row='5'
                  col='4'
                  name='reasons'
                  className='form-control reason_box'
                  value={this.state.fields.reasons.value}
                  onChange={(e) =>
                    this.checkvalidations(e.target.value, e.target.name)
                  }></textarea>
              </div>
              {this.state.responseMsg && this.state.responseMsg ? (
                <div className='failure_msg'>
                  {this.state.responseMsg && this.state.responseMsg}
                </div>
              ) : (
                ''
              )}
              {this.state.responseMsg && !this.state.responseMsg ? (
                <div className='success_msg'>
                  {this.state.responseMsg && this.state.responseMsg}
                </div>
              ) : (
                ''
              )}
              <div className='btn_main'>
                <button
                  className='delete_submit'
                  onClick={() => this.DisableDriver()}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default PilotDefaulter;
