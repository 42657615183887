import React, { Component } from 'react';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../../helpers/validationfunction';
import Image from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import moment from 'moment';
const { Option } = Select;
function handleChange(value) {
  console.log(`selected ${value}`);
}
var selfHeader = [{'id' : 1,  'date' : 'Date', 'time': 'Time', 'booking_id' : 'Booking ID','driver_name':'Driver Name', 'city' : 'City','svc_name':'SVC Name',  'complaint_from' : 'Complaint From','complaint_to':'Complaint To','action_taken':'Action Taken','close_complaint':'Close Complanint','days_elapsed':'Days Elapsed'}];

export default class Self extends Component {
  constructor(props){
    super(props);
    this.state={
      selfHeader:selfHeader,
      selfList:[],
      closeID:null,
      currentPage : 1,
      pageSize:10,
      driver:null,
      city:null,
      fields: {
        reason: { validations: [{ name: 'required' }], error: null, value: '' },
    },

    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  loadSelfActiveComplaints=()=>{
    // this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver:this.state.driver,city:this.state.city};
    console.log("actve", obj); 
    apiservices.listselfactivcomplaints(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  console.log("calc",calc);
                  this.setState({selfList:response.response, numbercount:calc})
                  console.log("complaint self", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadSelfActiveComplaints();
     
}
cityFilter =(e) =>{
  var value=e.target.value;
  // this.loadsvclist();
  if(value==''){
    value=null;
    // 
  }
  this.setState({[e.target.name]:value,currentPage:1},function(){
    this.loadSelfActiveComplaints();
  });
}
onchangePagination = (page, pageSize) => {
  console.log("page", page);
  this.setState({ currentPage: page }, function(){
      this.loadSelfActiveComplaints();        
  });

  console.log("page size", pageSize);
}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadSelfActiveComplaints(); 
  });
}
closeComplaints =(data)=>{
  // 
  var fields=this.state.fields;
  fields['reason'].value='';
  fields['reason'].error='';
  this.setState({openDialog:true,closeID:data,fields,success_msg:'',error_msg:''})
  console.log("close complaint id",data)
}
closeDialog=()=>{
  var fields=this.state.fields;
  fields['reason'].value='';
  fields['reason'].error='';
  this.setState({openDialog:false,fields,success_msg:'',error_msg:''})
}
checkvalidations = (value, key) => {


  var fields = this.state.fields;
  fields[key].value = value;
  var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
  // console.log(checkstatus);
  fields[key].error = checkstatus.msg;
  var getallkeys = Object.keys(fields);
  var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
  if (filtererrors.length > 0) {
    this.setState({ error: true })
    //errorblock
  } else {
    this.setState({ error: false })

    //successblock
  }
  this.setState({ fields });
}
popupSubmit =() =>{
  var fields =this.state.fields;
  var getallkeys=Object.keys(fields);
  getallkeys.map((obj)=>{
  var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
  fields[obj].error=checkstatus.msg;
  })
  var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
  if(filtererrors.length>0){
    //errorblock
    this.setState({error:true})
  }else{
    //successblock
    this.setState({error:false});
    this.closeComplaintDetails();
  }
  this.setState({fields});
}
closeComplaintDetails =()=>{
    // this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={id:this.state.closeID,action_taken:this.state.fields.reason.value};
    console.log("actve", obj); 
    apiservices.closeselfactivecomplaints(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                setTimeout(() => {
                  this.setState({openDialog:false})
                  this.loadSelfActiveComplaints();
                  
                }, 3000);
                this.setState({success_msg:response.message})
                }
              else{
                this.setState({error_msg:response.message})
              }
          })



}
  render() {
    const offset = moment().utcOffset();
    return (
      <div className="zones_main"> 
        <div className="container">
        <div className="search-header mt-0 booking-mishaps booking-active">
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-5"></div>
                             <div className="col-md-4">
                             
                             <div className="city_search" style={{display:'flex'}}>
                  <label>City / Zone:</label>
                 <input type="text" className="form-control citysearchbox"  name="city" onChange={(e)=>this.cityFilter(e)}/>
                 </div>
                             
                             
                             </div>
                         </div>
                    </div>

                    <div >
                    <div className="table-list add_border">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.selfHeader && this.state.selfHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.date}</th>
                        <th>{item.time}</th>
                        <th>{item.booking_id}</th>
                        {/* <th>{item.driver_name}</th> */}
                        <th>{item.city}</th>
                        <th>{item.svc_name}</th>
                        {/* <th>{item.complaint_from}</th> */}
                        <th>{item.complaint_to}</th>
                        {/* <th>{item.action_taken}</th> */}
                        <th>{item.close_complaint}</th>
                        {/* <th>{item.days_elapsed}</th> */}
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.selfList&&this.state.selfList.length>0&&this.state.selfList.map((item,index) => {

                return(
                    <tr>
                       
                       <td>{moment(item.created_at).format('DD/MM/YY')}</td>
                        <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
                        <td>ID{item.booking_id}</td>
                        {/* <td>{item.driver_first_name}</td> */}
                        <td>{item.city}</td>
                        <td>{item.centre_name}</td>
                        {/* <td>{item.customer_name}</td> */}
                <td>{item.driver_first_name} {item.driver_last_name}</td>
                        {/* <td>{item.action_taken}</td> */}
                        <td><img src={Image.cancel_new} onClick={()=>this.closeComplaints(item.id)}/></td>
                        {/* <td>{item.days_elapsed}</td> */}
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.selfList&&this.state.selfList.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
        <Dialog  open={this.state.openDialog}  className="pending_approvals">
                    
                    <div className="">
                   <div className="title_section">
                  <div className="add_approval_text">Close Complaints</div> <div className="approval_close" ><img src={Image.popup_clseicon} style={{ width: '15px',cursor:'pointer' }}  onClick={() => this.closeDialog()} /></div>
                </div>
                <div className="reason_reject_box">
                <div className="form-group reason_box">
                         <label>
                         Action Taken
                         </label>

                         <textarea row="5" col="4"   maxLength="250" name="reason" value={this.state.fields.reason.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  className="form-control reason_box"  ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['reason'].error&&this.state.fields['reason'].error}</span>
                     </div>
                     </div>
                    
                <div className="approval_btn_main">
<button className="approval_btn" onClick={()=>this.popupSubmit()}>Submit</button>
</div>
{this.state.success_msg?
<div className="success_msg">{this.state.success_msg}</div>:''
}
{this.state.error_msg?
<div className="failure_msg">{this.state.error_msg}</div>:''
}
                    </div>

                </Dialog>
       </div>
    );
  }
}
