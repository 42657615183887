import React, { Component } from 'react';
import images from "../../helpers/images";
import ValidationLibrary from '../../helpers/validationfunction';
export default class SVCDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            fields:{dealer_name:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
            email_id:{validations:[{name:'required'},{name:'email'},{name:'Noemptyspace'}],error:null,value:''},
            vehicle_brand:{validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
            vehicle_type:{validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
            service_centre:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'allowNumaricOnly'}],error:null,value:''},
            contact_number:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'mobile'}],error:null,value:''},
            address:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},city:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'alphabetsOnly'}],error:null,value:''},
            pincode:{validations:[{name:'required'},{name:'Noemptyspace'},{name:'zipcode'}],error:null,value:''},}
        }
    }
    checkvalidations=(value,key)=>{
        var fields =this.state.fields;
        fields[key].value=value;
        var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
        // console.log(checkstatus);
        fields[key].error=checkstatus.msg;
        var getallkeys=Object.keys(fields);
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          this.setState({error:true})
          //errorblock
        }else{
          this.setState({error:false})
    
          //successblock
        }
        this.setState({fields});
      }
      Service_next1=()=>{
        var fields =this.state.fields;
        var getallkeys=Object.keys(fields);
        getallkeys.map((obj)=>{
        var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
        fields[obj].error=checkstatus.msg;
        })
        var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
        if(filtererrors.length>0){
          //errorblock
          this.setState({error:true})
        }else{
          //successblock
          this.setState({error:false});
        
          this.props.sendnextdetails(2,'svcDetails',fields);
          
        //   this.setState({svc1 : false});
        //   this.setState({svc2  : true});
        }
        this.setState({fields});
      }
  render() {
    return (
      <>
      <div className="dealer-master"> 
          <div className="dealer-body">
                     
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Dealer Name *</label>
                          <input type="text" className="form-control" name="dealer_name" onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} value={this.state.fields.dealer_name.value} /> 
                          <span className="error_msg_adddealer">{this.state.fields['dealer_name'].error&&this.state.fields['dealer_name'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>E-mail ID</label>
                          <input type="text" className="form-control" name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['email_id'].error&&this.state.fields['email_id'].error}</span>
                        </div>                     
                     </div>

                     <div className="form-group">
                       <div class="form-inline">
                          <label>Vehicle Brand</label>
                          <input type="text" className="form-control" name="vehicle_brand" value={this.state.fields.vehicle_brand.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['vehicle_brand'].error&&this.state.fields['vehicle_brand'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Vehicle Type</label>
                          <input type="text" className="form-control" name="vehicle_type" value={this.state.fields.vehicle_type.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['vehicle_type'].error&&this.state.fields['vehicle_type'].error}</span>
                        </div>                     
                     </div>


                     <div className="form-group">
                       <div class="form-inline">
                          <label>No.of Showrooms / Service Center</label>
                          <input type="text" className="form-control" name="service_centre" value={this.state.fields.service_centre.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['service_centre'].error&&this.state.fields['service_centre'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Contact Number</label>
                          <input type="text" className="form-control" name="contact_number" value={this.state.fields.contact_number.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['contact_number'].error&&this.state.fields['contact_number'].error}</span>
                        </div>                     
                     </div>

                     <div className="form-group">
                         <label>
                         Address
                         </label>

                         <textarea row="5" className="form-control" name="address" value={this.state.fields.address.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['address'].error&&this.state.fields['address'].error}</span>
                     </div>

                     <div className="form-group">
                       <div class="form-inline">
                          <label>Town / City</label>
                          <input type="text" className="form-control" name="city" value={this.state.fields.city.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['city'].error&&this.state.fields['city'].error}</span>
                        </div>

                        <div class="form-inline">
                          <label>Pincode</label>
                          <input type="text" className="form-control" name="pincode" value={this.state.fields.pincode.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}/> 
                          <span className="error_msg_adddealer">{this.state.fields['pincode'].error&&this.state.fields['pincode'].error}</span>
                        </div>                     
                     </div>

                </div>
                 
                
                  </div>
                   <div>
                   <button eventKey="second" className="btn btn-dark-blue next-button"   onClick={()=>this.Service_next1()}>Next  <img src={images.arrow_white} className="img-fluid arrow"/></button>
                   </div>
                   </>
    );
  }
  componentDidMount(){
      console.log("didmount",this.props);
      if(this.props.svcDetails){
          this.setState({fields:this.props.svcDetails});
      }
  }

}
