import React, {Component} from 'react';
import './style.css';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import Image from '../../helpers/images';
 import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';

 import BookingPayment from './BookingPayment';

 import MonthlyPayment from './MonthlyPayment';


class DriverPaymentDetails extends Component{

    constructor(props){
console.log("contrsuctor props",props);
        super(props);

        this.state = {
          currentPage : 1,
          pageSize:10,
          driver_id:props.match.params.id
        }
    }
componentWillMount (){

  this.props.sendid(this.props.match.params.id);
}
    render(){

        return(
            <div>
<div class="form-tabs driver_booking" style={{marginTop:0}}>
                {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  <Row>
    <Col sm={12}>
      <Nav variant="pills" className="flex-row payment_tab serive_center"  selectedIndex={this.state.selectedIndex}
        onSelect={this.handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="first">Booking Payment</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="second">Monthly Payment</Nav.Link>
        </Nav.Item>
      </Nav>
    </Col>
    <Col sm={12}>
      <Tab.Content>
        <Tab.Pane eventKey="first">
                    <BookingPayment/>

        </Tab.Pane>
        <Tab.Pane eventKey="second">
            
             <MonthlyPayment/>
        
        </Tab.Pane>
      </Tab.Content>
    </Col>
  </Row>
</Tab.Container> */}
<div class = "svc_user_details">
           
            
           {/* <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.tabKey} mountOnEnter="true" forceRenderTabPanel={true}>  */}
               <Row>
                   <Col sm={12}>
                   <Nav variant="pills"  className="flex-row svcuser" selectedIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
          <Nav.Item>
   <NavLink parentClass="parent_active" to={`${this.props.match.path}/booking_payment/${this.props.driverid}`} activeClassName="active_user">Booking Payment</NavLink>
         </Nav.Item>
         <Nav.Item>
   <NavLink  to={`${this.props.match.path}/monthly_payment/${this.props.driverid}`} activeClassName="active_user">Monthly Payment</NavLink>
         </Nav.Item>
         
         </Nav>
                   </Col>
                   <Col sm={12}>
                   <Switch>
            <Route exact  path={`${this.props.match.path}/booking_payment/:id`}  render={props=> (<BookingPayment  {...props} driverid={this.props.match.params.id} sendid={(data)=>this.props.sendid(data)} />)} />
           <Route exact  path={`${this.props.match.path}/monthly_payment/:id`} render={props=> (<MonthlyPayment   {...props} driverid={this.props.match.params.id} sendid={(data)=>this.props.sendid(data)}/>)} /> 
           
           </Switch>
                   </Col>
               </Row>
           {/* </Tab.Container> */}
       </div>
</div>
{/* Form Tabs start */}

            </div>
        )
    }
}


export default DriverPaymentDetails;