import React, { Component } from 'react';
import './style.css';
import images from '../../../helpers/images';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import apiservices from '../../../helpers/apiservices';

var pickupHeader = [{ 'id': 1, 'date': 'Date', 'time': 'Time', 'description': 'Description', 'file_name': 'File Name', 'photo_count': 'Photo Count' }]

class PhotoGraphsPickUp extends Component
{

  constructor(props)
  {

    super(props);

    this.state = {
      pickupHeader: pickupHeader,
      customer_image: [],
      servicecenter_image: [],
      pickupList: null,
      loading: false,
      servicecenter_image: [],
      progressDetails: null,
      driverDetails: null,
      customerDetails: null
    }
  }
  loadPhotographPickup = (data) =>
  {
    this.setState({ loading: true })
    const token = localStorage['accesstoken'];
    var obj = { id: this.props.match.params.id, assign_id: this.props.match.params.assignid };
    console.log("booking svc details obj", obj);
    apiservices.bookingdetailsview(obj, token)
      .then((response) =>
      {
        this.setState({ loading: false });
        if (!response.error)
        {

          this.setState({ pickupList: response.response.photoDetails, progressDetails: response.response.progressDetails, driverDetails: response.response.driverDetails, customerDetails: response.response.customersDetails ? response.response.customersDetails : null })
        } else
        {
          this.setState({ responseMsg: response.message })
        }

      })
  }
  componentWillMount()
  {
    this.loadPhotographPickup();
  }
  displayPickup = (customerDetails, pickupList) =>
  {

    if (customerDetails)
    {
      if (customerDetails.booking_type_id === 1 || customerDetails.booking_type_id === 3 || (customerDetails.booking_type_id === 4 && customerDetails.is_dropoff == 0))
      {
        //customer images
        // console.log("customer images will show here")
        return (pickupList && pickupList.customer_images === null || pickupList && pickupList.customer_images == undefined)
          ?
          <div className="svc_payment_no_data">No Images yet !</div>
          :
          <div>
            <div className={`${pickupList && pickupList.customer_images.length === 0 && "addpaddingbottom"} graph_dropOff`}>
              <h4>Photos at Customer</h4>
            </div>
            <div className="form-group">
              <div class="form-inline" >
                {pickupList && pickupList.customer_images && pickupList.customer_images.length > 0 && pickupList.customer_images.map((item, index) =>
                {
                  return (
                    <div className="slot_box" style={{ border: 'none' }}>
                      <div style={{ marginTop: 20 }}>
                        <img alt='' className="booking_photo_graph" src={item.imagePath == null ? images.default_image : item.imagePath} style={{ width: 170, height: 170, borderRadius: 7 }} />
                      </div>
                      <div style={{ marginTop: 20 }}> {item.fileName}</div>

                    </div>
                  )
                })}

              </div>
            </div> </div>

      }

      if (customerDetails.booking_type_id === 2 || (customerDetails.booking_type_id === 4 && customerDetails.is_dropoff == 1))
      {
        //center images
        // console.log("center images will show here")
        return (
          pickupList && pickupList.center_images === null || pickupList && pickupList.center_images == undefined ?
            <div className="svc_payment_no_data">No Images yet !</div>
            :
            <div>
              <div className={`${pickupList && pickupList.center_images.length === 0 && "addpaddingbottom"} graph_dropOff`}>
                <h4>Photos at Service Center</h4>
              </div>
              <div className="form-group">
                <div class="form-inline" >
                  {pickupList && pickupList.center_images && pickupList && pickupList.center_images.length > 0 && pickupList && pickupList.center_images.map((item, index) =>
                  {
                    return (
                      <div className="slot_box" style={{ border: 'none' }}>
                        <div style={{ marginTop: 20 }}>
                          <img className="booking_photo_graph" src={item.imagePath == null ? images.default_image : item.imagePath} style={{ width: 170, height: 170, borderRadius: 7 }} />
                        </div>
                        <div style={{ marginTop: 20 }}> {item.fileName}</div>

                      </div>
                    )
                  })}

                </div>
              </div>
            </div>

        )

      }
    }

  }
  render()
  {
    console.log("pickup image details", this.state.pickupList)
    const { pickupList, progressDetails, driverDetails, customerDetails } = this.state;

    return (
      <div>
        <div className="booking_pickup">
          {
            this.displayPickup(customerDetails, pickupList)
          }

          <h4 className="vehicle-table">Vehicle Handed Over to</h4>

          <div className="graph-table-listing">

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Pilot Name</th>
                    <th>Handed By</th>
                    <th>Signature</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{driverDetails && driverDetails.driver_first_name} {driverDetails && driverDetails.driver_last_name}</td>
                    <td>{progressDetails && progressDetails.handed_by && progressDetails.handed_by.name}</td>
                    <td>{progressDetails && progressDetails.handed_by && progressDetails.handed_by.signature}</td>

                  </tr>

                </tbody>
              </table>

            </div>

          </div>


        </div>

      </div>

    )
  }
  componentDidMount()
  {
    console.log("params", this.props);
    this.props.sendbookingid && this.props.sendbookingid(this.props.match.params.id, this.props.match.params.assignid)
  }
}
export default PhotoGraphsPickUp;