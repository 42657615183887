import React, { Component } from "react";
import "./styles.css";
import apiservices from '../../../helpers/apiservices';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Table, Tag, Space, Select, Pagination } from 'antd';
import Images from '../../../helpers/images';
const { Option } = Select;
export default class AddUser extends Component {
    constructor(props) {
        super(props);
        console.log("add user", props);
        this.state = {
            // props.history.location.state.editid
            designation: null,
            editID: null,
            fields: {
                username: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
                city: { validations: [{ name: 'required' }, { name: 'alphabetsOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
                designation: { validations: [{ name: 'required' }], error: null, value: '' },
                 mobile_number: { validations: [{ name: 'required' }, { name: 'mobile' }, { name: 'Noemptyspace' }], error: null, value: '' },
                email_id: { validations: [{ name: 'required' }, { name: 'email' }, { name: 'Noemptyspace' }], error: null, value: '' },
                employee_id: { validations: [{ name: 'alphaNumaricOnly' }, { name: 'Noemptyspace' }], error: null, value: '' },
                salutation: { validations: [], error: null, value: 'Mr' }

            }
        };
    }

    loadDesignationDropdown = () => {
        const token = localStorage['accesstoken'];
        var obj = {};
        apiservices.listadmindesignationdrop(obj, token)
            .then((response) => {
                if (!response.error) {
                    console.log("designation",response)
                    this.setState({ designation: response.response })
                    var fields = this.state.fields;

                    fields['designation'].value = response.response.length > 0 ? response.response[0].id : '';
                    this.setState({ fields })

                } else {
                    //   this.setState({error_msg:response.message})
                }

            })
    }
    componentWillMount() {
        this.loadDesignationDropdown();
        // this.loadsvccenterDropdown();
           }
    checkvalidations = (value, key) => {
        console.log("checkvalidations");
        var fields = this.state.fields;
        fields[key].value = value;
        var checkstatus = ValidationLibrary.checkValidation(value, fields[key].validations);
        // console.log(checkstatus);
        fields[key].error = checkstatus.msg;
        var getallkeys = Object.keys(fields);
        var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
        if (filtererrors.length > 0) {
            this.setState({ error: true })
            //errorblock
        } else {
            this.setState({ error: false })

            //successblock
        }
        this.setState({ fields });
    }
    AddUser = () => {
        var fields = this.state.fields;
        var getallkeys = Object.keys(fields);
        getallkeys.map((obj) => {
            var checkstatus = ValidationLibrary.checkValidation(fields[obj].value, fields[obj].validations);
            fields[obj].error = checkstatus.msg;
        })
        var filtererrors = getallkeys.filter((obj) => fields[obj].error != "");
        if (filtererrors.length > 0) {
            //errorblock
            this.setState({ error: true })
        } else {
            //successblock
            this.setState({ error: false });
            const token = localStorage['accesstoken'];
            var obj = {
                city:this.state.fields.city.value,
                designation_id: this.state.fields.designation.value,
                salutation: this.state.fields.salutation.value,
                name: this.state.fields.username.value,
                mobile_no: this.state.fields.mobile_number.value,
                email: this.state.fields.email_id.value,
                employee_id: this.state.fields.employee_id.value
            };
            console.log("add user",obj)
            apiservices.addadminuser(obj, token)
                .then((response) => {
                    if (!response.error) {
                        this.setState({ failure_msg: '' })
                        this.setState({ success_msg: response.message })

                    } else {
                        this.setState({ success_msg: '' })
                        this.setState({ failure_msg: response.message })
                    }

                })
        }
    }

    render() {
        console.log("edit id", this.state.editID)
        const { designation,service_center } = this.state;
        return (
            <div>
                <div className="svc-add-user-info svc_first">
                    <div className="svc-detailes-all">
                        <div className="dealer-body">
                            <div className="form-group">
                                {/* <div class="form-inline">
                                    <label>User Name</label>
                                    <div className="svcUserdropdown">
                                        <Select name="salutation"  value={this.state.fields.salutation.value} onChange={(value) => this.checkvalidations(value, 'salutation')} className="svcdropList" style={{width:'25%'}} >
                                            <Option value="Ms">Ms</Option>
                                            <Option value="Mr">Mr</Option>
                                            <Option value="Miss">Miss</Option>
                                        </Select>
                                        <img src={Images.dropdown} style={{ position: "absolute", marginTop: 18, marginLeft: 44, width: 13 }} />
                                        <input type="text" className="form-control" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} style={{marginTop:4}}/>
                                    </div>
                                    <span className="error_msg_adddealer">{this.state.fields['username'].error && this.state.fields['username'].error}</span>
                                </div> */}
                                   <div class="form-inline ">
                            <label>Customer Name</label>
                           
                             <div className="svcUserdropdown saluation_drop">
                                <Select name="salutation" value={this.state.fields.salutation.value} onChange={(value) => this.checkvalidations(value,'salutation')} className="svcdropList position_set"  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Mrs">Mrs</Option>
                                    <Option value="Miss">Miss</Option>
                                </Select>
                                <img src={Images.dropdown} className="img-fluid saluation_dropdown"/> 
                                <input type="text" className="form-control" name="username" value={this.state.fields.username.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  />
                               
                            </div> 
                            <span className="error_msg_adddealer">{this.state.fields['username'].error&&this.state.fields['username'].error}</span>
                        </div>
                                <div class="form-inline">
                                    <label>Mobile Number</label>
                                    <input type="text" className="form-control" name="mobile_number" value={this.state.fields.mobile_number.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                    <span className="error_msg_adddealer">{this.state.fields['mobile_number'].error && this.state.fields['mobile_number'].error}</span>
                                </div>

                            </div>
                            <div className="form-group">
                                <div class="form-inline">
                                    <label>E-mail ID</label>
                                    <input type="text" className="form-control" name="email_id" value={this.state.fields.email_id.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                    <span className="error_msg_adddealer">{this.state.fields['email_id'].error && this.state.fields['email_id'].error}</span>
                                </div>
                                <div class="form-inline">
                                    <label>Employee ID (Optional)</label>
                                    <input type="text" className="form-control" name="employee_id" value={this.state.fields.employee_id.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                    <span className="error_msg_adddealer">{this.state.fields['employee_id'].error && this.state.fields['employee_id'].error}</span>
                                </div>
                            </div>
                            <div className="form-group add-user-destination">
                                <div class="form-inline ">
                                    <label>Designation</label>
                                    <Select name="designation" value={[this.state.fields.designation.value]} onChange={(value) => this.checkvalidations(value, 'designation')}>
                                        {designation && designation.length > 0 && designation.map((item) => {
                                            return (
                                                <Option value={item.id}>{item.designation_name}</Option>
                                            )
                                        })}

                                    </Select>
                                    <img src={Images.dropdown} className="svc-user-drop" style  = {{width:14}}/>
                                    <span className="error_msg_adddealer">{this.state.fields['designation'].error && this.state.fields['designation'].error}</span>
                                </div>
                                {/* <div class="form-inline " style={{position:'relative'}}>
                                    <label>Service Center</label>
                                    <Select name="designation" value={[this.state.fields.service_center.value]} onChange={(value) => this.checkvalidations(value, 'service_center')}>
                                        {service_center && service_center.length > 0 && service_center.map((item) => {
                                            return (
                                                <Option value={item.id}>{item.centre_name}</Option>
                                            )
                                        })}

                                    </Select>
                                    <img src={Images.dropdown} className="service_drop"  style  = {{width:14}}/>
                                    <span className="error_msg_adddealer">{this.state.fields['service_center'].error && this.state.fields['service_center'].error}</span>
                                </div> */}
                                <div class="form-inline">
                                    <label>City</label>
                                    <input type="text" className="form-control" name="city" value={this.state.fields.city.value} onChange={(e) => this.checkvalidations(e.target.value, e.target.name)} />
                                    <span className="error_msg_adddealer">{this.state.fields['city'].error && this.state.fields['city'].error}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button disabled={this.state.success_msg} className="btn btn-dark-blue next-button" onClick={this.AddUser}>Submit</button>
                {this.state.failure_msg ?
                    <div className="failure_msg">{this.state.failure_msg}</div> : ''
                }

                {this.state.success_msg ?
                    <div className="success_msg">{this.state.success_msg}</div> : ''
                }
            </div>
        );
    }
}