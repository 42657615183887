import React, { Component } from 'react';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
// import './styles.css';
import Grid from '@material-ui/core/Grid';

import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";

import Pickup from './Pickup';
import Dropoff from './Dropoff';
class DatewiseMain extends Component {

    constructor(props) {
        super(props);

        console.log("receive pilot", props);
        this.state = {

            pilotMaster : true,
            addDriver : false,
            driverId : null
        }
    }
    receiveData=(data)=>{
 
    this.setState({driverId : data});        
    this.setState({driverEdit : true});
    this.setState({pilotMaster : false});

           
    if(data == "hide"){
        this.setState({driverEdit : false});
        this.setState({pilotMaster : true});
    
    }
}
AddDriver=()=>{
    this.setState({pilotMaster : false});
    this.setState({addDriver  : true})
}


    render() {
        return (
            <div>
                 {/* {this.state.driverEdit && <DriverDetails sendDriverId={this.state.driverId} sendHideDetails={(data)=>this.receiveData(data)}/>}
                {this.state.pilotMaster &&   */}
                {/* {this.state.driverEdit && <DriverDetails sendDriverId={this.state.driverId} sendHideDetails={(data)=>this.receiveData(data)}/>} */}
                 
               
                <div class="payouts_menu">
                        <Row>
                            <Col sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={8} sm={8} className="introcenter" >
                                        <Nav variant="pills" className="flex-row pilot" selectedIndex={this.state.selectedIndex}
                                            onSelect={this.handleSelect}>
                                            <Nav.Item>
                                            <NavLink exact={true}   to={`${this.props.match.path}/`} activeClassName="active_cls">Pickup</NavLink>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <NavLink   to={`${this.props.match.path}/drop-off`} activeClassName="active_cls">Drop-off</NavLink>
                                            </Nav.Item>
                                           
                                            
                                        </Nav>
                                    </Grid>
                                    {/* <Grid item xs={12} md={4} sm={4} className="introcenter driver_grid" >
                                    <NavLink  exact={true} to={`${this.props.parentProps.match.path}/add_driver`} activeClassName="active_cls">                                        <div className="addNewDriver" >
                                            <div className="driverButton" onClick={this.AddDriver}>
                                                <img src={images.add_img}/>
                                                Add Pilots
                                            </div>
                                        </div>
                                        </NavLink>
                                    </Grid> */}
                                </Grid>
                            </Col>
                            <Col sm={12}>
                                 <Switch>
                <Route exact path={`${this.props.match.path}/`} render={props => (<Pickup {...props}/>)} />
                <Route exact path={`${this.props.match.path}/drop-off`} render={props => (<Dropoff {...props}/>)} />
              
                </Switch>
                            </Col>
                        </Row>
                </div>
                
            </div>

            
        )

    }
    

}


export default DatewiseMain;
