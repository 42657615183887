import React, {Component} from 'react';
import './styles.css';
import images from '../../helpers/images';
import moment from 'moment';
import { Table, Tag, Pagination, Select} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import apiservices from '../../helpers/apiservices';
import { DatePicker } from 'antd';


const { RangePicker } = DatePicker;

var performanceHeader = [{'id' : 1,  'svc_id' : 'SVC ID', 'svc_name': 'SVC Name', 'total_task' : 'Total Task', 'ontime_task' : 'On Time Tasks','ontime_dropoff':'On Time Dropoff',  'cancelled' : 'Cancelled', 'rescheduled' : 'Rescheduled','no_show':'No Show'}];

class Performance extends Component{

    constructor(props){

        super(props);

        this.state = {
            performanceHeader : performanceHeader,

            performanceList : [],
            center_search:null,
            currentPage : 1,
            pageSize:10,
            openapproval:false,
            rejectapproval:false,
            approvalID:null,
            rejectID:null


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      
      
      loadSVCPerformance=()=>{
        const token = localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,centre_name:this.state.center_search};
          apiservices.listsvcperformance(obj,token)
                .then((response)=>{
                    // this.setState({loading:false});
                    if(!response.error){
                        var limit=response.count;
                        var total_items=this.state.pageSize;
                        var calc=Math.ceil(limit/total_items)*total_items;
                        this.setState({performanceList:response.response, numbercount:calc})
                        console.log("new userr request", response);
                      }
                    else{
                      this.setState({error_msg:response.message})
                    }
                })
      }
      componentWillMount(){       
          this.loadSVCPerformance();
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadSVCPerformance();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadSVCPerformance(); 
        });
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadSVCPerformance();
        });
      }
render(){
    const offset = moment().utcOffset();
    return(
        <div>
              <div className="container">
             <div className="search-header booking-mishaps" >
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search" className="form-control" name="center_search" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={images.green_search} className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                               
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                            dateRender={current => {
                                                const style = {};
                                                if (current.date() === 1) {
                                                style.border = '1px solid #1890ff';
                                                style.borderRadius = '50%';
                                                }
                                                return (
                                                <div className="ant-picker-cell-inner" style={style}>
                                                    {current.date()}
                                                </div>
                                                );
                                            }}
                                            />
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                    
                    <div className="table-list">


                        <div class="table-responsive">

                             
                            <table class="table table-borderless">
                                <thead>
                                {this.state.performanceHeader && this.state.performanceHeader.map((item)=> {
                                        return(
                                            <tr> 
                                                <th>{item.svc_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.total_task}</th>
                                                <th>{item.ontime_task}</th>
                                                <th>{item.ontime_dropoff}</th>
                                                <th>{item.cancelled}</th>
                                                 <th>{item.rescheduled}</th>
                                                 {/* <th>{item.no_show}</th> */}
                                                
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>
                                {this.state.performanceList &&this.state.performanceList.map((item) => {

                                        return(
                                            <tr>
                                                <td>ID{item.id} </td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.total_booking}</td>
                                                <td>{item.on_time_tasks}</td>
                                                <td>{item.on_time_dropoff}</td>
                                                <td>{item.cancel_booking}</td>
                                                <td>{item.reschedule_booking}</td>
                                               
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {this.state.performanceList&&this.state.performanceList.length==0&&
                            <div>No Records Found </div>
                            }
                            

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
    </div>
    
            
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default Performance;
