import React, {Component} from 'react';
import './style.css';

import images from '../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import UpdateSvcDealer from "../UpdateSvcDealer";

class SvcDealerTab extends Component{

    constructor(props){

        super(props);

        this.state = {


          SvcDetails : false,

          svcList: true
        }
    }
    SvcDetails = () => {

      this.setState({SvcDetails : true});
    
      this.setState({svcList : false});
       
     }
    
     svcDetailsHide = () => {
      this.setState({SvcDetails : false});
    
      this.setState({svcList : true});
     }
     componentWillReceiveProps(props){
         console.log("will receive",props);
     }
     
render(){
    return(
        <div>

             {/* <div className="back-btns"> */}
                {/* <span className="back-button" onClick={this.svcDetailsHide}> <img src={images.FadedArrowImg} className="img-flud"/>
                 Back to the Lists</span> */}
             {/* </div> */}
{/* Form Tabs start */}
{/* dealerDetailsID = {this.props.dealerDetailsID} */}
<div className="back-btns">
             <Link to={`${this.props.parentProps.match.path}/`}><span className="back-button" > 
                    <img src={images.FadedArrowImg} className="img-flud"/>
                 
                 Back to the Lists</span></Link>
             </div> 
                <div class="svcDetails-tabs">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row style={{display:'inline-flex'}}>
                    <Col sm={12}>
                    <Nav variant="pills" className="flex-row svcdetails_nav"  selectedIndex={this.state.selectedIndex}
                        onSelect={this.handleSelect}>
                        <Nav.Item>
                        <Nav.Link eventKey="first">Dealer Details</Nav.Link></Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={12}>
                        <div className="svc_dealer_update">
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                         
                          <UpdateSvcDealer  {...this.props} />

                        </Tab.Pane>
                    </Tab.Content>
                    </div>
                    </Col>
                </Row>
                </Tab.Container>
                </div>
                {/* Form Tabs start */}
        </div>
    )
}


}

export default SvcDealerTab;