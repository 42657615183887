import React, { Component } from 'react';
import { Table, Tag, Pagination, Select,DatePicker} from 'antd';
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import ValidationLibrary from '../../helpers/validationfunction';
import Image from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';
function handleChange(value) {
  console.log(`selected ${value}`);
}
var incentiveHeader = [
    {'id' : 1,  'driver_name' : 'Driver Name', 'driver_id': 'Driver ID', 'incentive_type':'Incentive Type','amount':'Amount'}];

export default class Incentives extends Component {
  constructor(props){
    super(props);
    this.state={
      incentiveHeader:incentiveHeader,
      incentiveList:[],
      closeID:null,
      currentPage : 1,
      pageSize:10,
      driver:null,
      city:null,
      fromDate:null,
      toDate:null
     

    }
  }
  itemRender =(current, type, originalElement)=> {
    if (type === 'prev') {
      return <a className="prevnext"><img src={Image.arrow_green} className="arrow_cls"/>Prev</a>;
    }
    if (type === 'next') {
      return <a className="prevnext"> Next <img src={Image.arrow_forward} className="arrow_cls nextarw"/></a>;
    }
    return originalElement;
  }
  loadPaymentIncentives=()=>{
    this.setState({loading:true});
    const token=localStorage['accesstoken'];
    var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
    console.log("actve", obj); 
    apiservices.listpaymentincentive(obj,token)
          .then((response)=>{
              // this.setState({loading:false});
              if(!response.error){
                  var limit=response.count;
                  var total_items=this.state.pageSize;
                  var calc=Math.ceil(limit/total_items)*total_items;
                  this.setState({incentiveList:response.response, numbercount:calc})
                  console.log("acive reponse", response);
                }
              else{
                this.setState({error_msg:response.message})
              }
          })


} 
componentWillMount(){
    this.loadPaymentIncentives();
     
}
checkvalidations =(data)=>{
  this.setState({fromDate:data[0],toDate:data[1]},function(){
    this.loadPaymentIncentives();
  })
}
cityFilter =(e) =>{
  var value=e.target.value;
  // this.loadsvclist();
  if(value==''){
    value=null;
    // 
  }
  this.setState({[e.target.name]:value,currentPage:1},function(){
    this.loadPaymentIncentives();
  });
}
onchangePagination = (page, pageSize) => {
  console.log("page", page);
  this.setState({ currentPage: page }, function(){
      this.loadPaymentIncentives();        
  });

  console.log("page size", pageSize);
}
showChanger = (current, size) => {
  this.setState({ pageSize: size, currentPage : 1 }, function(){
      this.loadPaymentIncentives(); 
  });
}
ClearDatepicker =()=>{
  this.setState({fromDate:null,toDate:null},function(){
    this.loadPaymentDecrement();
  })
}
  render() {
    const offset = moment().utcOffset();
    return (
      <div className="zones_main"> 
        <div className="container">
       
        <div className="search-header mt-0 booking-mishaps booking-active" style={{paddingTop:30}}>
                         <div className="row">
                             <div className="col-md-3">
                                  <div class="form-group search-list payment-search">
                                      <input type="text" placeholder="Search for Driver" className="form-control" name="driver" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Image.green_search}  className="img-fluid"/>
                                  </div>
                             </div>
                             <div className="col-md-9">
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Image.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                           <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkvalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Image.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>
                    <div >
                    <div className="table-list">


<div class="table-responsive">

     
    <table class="table table-borderless">
        <thead>
            {this.state.incentiveHeader && this.state.incentiveHeader.map((item)=> {
                return(
                    <tr>     
                        <th>{item.driver_name}</th>
                        <th>{item.driver_id}</th>
                        <th>{item.incentive_type}</th>
                        {/* <th>{item.status}</th> */}
                        <th>{item.amount}</th>
                        {/* <th>{item.complaint_from}</th> */}
                    </tr>
                )
            })}
        </thead>
        <tbody>

            {this.state.incentiveList&&this.state.incentiveList.length>0&&this.state.incentiveList.map((item,index) => {

                return(
                    <tr>
                       
                <td>{item.driver_first_name} {item.driver_last_name}</td>
                        <td>ID{item.driver_id}</td>
                        <td>{item.incentive_type}</td>
                        {/* <td>{item.status}</td> */}
                        <td>{item.amount}</td>
                    </tr>
                )
            })}
        </tbody>
    </table>

    {this.state.incentiveList&&this.state.incentiveList.length==0&&
<div className="no_records_found">No Records Found</div>
}
</div>




</div>

<Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
pageSize={this.state.pageSize}  itemRender={this.itemRender}
/>
                    </div>
        </div>
       </div>
    );
  }
}
