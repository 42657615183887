import React, {Component} from 'react';
import Images from '../../helpers/images';
import apiservices from '../../helpers/apiservices';
import { Table, Tag, Pagination, Select} from 'antd';
import moment from 'moment';
import { DatePicker } from 'antd';
const dateFormat = 'YYYY-MM-DD';



const { Option } = Select;

function handleChange(value) {
  console.log(`selected ${value}`);
}


const { RangePicker } = DatePicker;

var TicketAllHeader = [{'id' : 1,'created_at':'Created Date' ,'driver_name' : 'Driver Name', 'mobile_number': 'Mobile Number', 'booking_id' : 'Booking ID', 'svc_name' :'SVC Name',  'svc_id' : 'SVC ID', 'ticket_name' : 'Ticket Name','duration':'Duration' ,'close' : 'Reason','closed_at':'Closed Date'}];
class ClosedTickets extends Component{
    constructor(props){
        super(props);
        this.state = {
            TicketAllHeader : TicketAllHeader,
            TicketAllList : [],  
            currentPage : 1,
            pageSize:10,
            driver_name:null,
            fromDate:null,
            toDate:null,
            ticketfields:{
                ticket_priority:{validations:[],error:null,value:''},
              },


        }
    }

    itemRender =(current, type, originalElement)=> {
        if (type === 'prev') {
          return <a className="prevnext"><img src={Images.arrow_green} className="arrow_cls"/>Prev</a>;
        }
        if (type === 'next') {
          return <a className="prevnext"> Next <img src={Images.arrow_forward} className="arrow_cls nextarw"/></a>;
        }
        return originalElement;
      }
      onchangePagination = (page, pageSize) => {
        console.log("page", page);
        this.setState({ currentPage: page }, function(){
            this.loadClosedTickets();        
        });
    
        console.log("page size", pageSize);
      }
      showChanger = (current, size) => {
        this.setState({ pageSize: size, currentPage : 1 }, function(){
            this.loadClosedTickets(); 
        });
      }
      loadClosedTickets=()=>{
        // this.setState({loading:true});
        const token=localStorage['accesstoken'];
        var obj={pageNo:this.state.currentPage,pageLimit:this.state.pageSize,driver_name:this.state.driver_name,ticket_priority:this.state.ticketfields.ticket_priority.value,search_from:this.state.fromDate?moment(this.state.fromDate).format(dateFormat):'',search_to:this.state.toDate?moment(this.state.toDate).format(dateFormat):''};
        console.log("actve", obj);     
        apiservices.listticketsalllhistory(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                      var limit=response.count;
                      var total_items=this.state.pageSize;
                      var calc=Math.ceil(limit/total_items)*total_items;
                      console.log("calc",calc);
                      this.setState({TicketAllList:response.response, numbercount:calc})
                      console.log("ticket close", response);
                    }
                  else{
                    this.setState({error_msg:response.message})
                  }
              })


    } 
    componentWillMount(){
        this.loadClosedTickets();
        this.loadPriorityDropdown();
    }
    checkfiltervalidations =(data)=>{
        console.log("priority",data);
       
        this.setState({fromDate:data[0],toDate:data[1]},function(){
          this.loadClosedTickets();
        })
      }
      checkpriorityfilter =(data)=>{
        var ticketfields=this.state.ticketfields;
        ticketfields['ticket_priority'].value=data;
        this.setState({ticketfields},function(){
          this.loadClosedTickets();
        })
      }
      ClearDatepicker =()=>{
        this.setState({fromDate:null,toDate:null},function(){
          this.loadClosedTickets();
        })
      }
      cityFilter =(e) =>{
        var value=e.target.value;
        // this.loadsvclist();
        if(value==''){
          value=null;
          // 
        }
        this.setState({[e.target.name]:value,currentPage:1},function(){
          this.loadClosedTickets();
        });
      }
      loadPriorityDropdown=(data)=>{
        const token=localStorage['accesstoken'];
        var obj={};
        console.log("actve", obj);     
        apiservices.listticketprioritydrop(obj,token)
              .then((response)=>{
                  // this.setState({loading:false});
                  if(!response.error){
                    this.setState({ticket_priority:response.response})
                    var ticketfields=this.state.ticketfields;
                    // if(!value){
                    // ticketfields['ticket_priority'].value=response.response.length>0?response.response.id:'';            
                    // this.setState({ticketfields})
                    // }
                    console.log("login response",response);
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
              })
    }
render(){
    const {ticket_priority} =this.state;
    return(
        <div>
              
              <div className="search-header booking-mishaps" style={{marginTop:10}}>
                         <div className="row">
                             <div className="col-md-3">
                                   <div class="form-group search-list payment-search">
                                  <input type="text" placeholder="Search for Driver" className="form-control" name="driver_name" onChange={(e)=>this.cityFilter(e)}/> 
                                      <img src={Images.green_search} className="img-fluid"/>
                                  </div> 
                             </div>
                             <div className="col-md-9">
                             <div className="payment-dropdown trends_drop"> <span className="payment">Ticket Priority</span> 
                             <Select value={[this.state.ticketfields.ticket_priority.value?parseInt(this.state.ticketfields.ticket_priority.value):'']}  onChange={(value) => this.checkpriorityfilter(value)} name="ticket_priority" >
                             <Option value="">All</Option>
                         {ticket_priority&&ticket_priority.length>0&&ticket_priority.map((item)=>{
                              return(
                               
                               
                              <Option value={item.id}>{item.priority}</Option>
                              
                              )
                            })} 
                            
                           </Select> 
                            </div>
                                    <div className="service_listing booking-list payments-list">
                                       
                                       
                                          <div className="date-pickers booking">
                                              <img src={Images.date_picker} className="img-fluid date-icon"/>
                                          <span className="from">From :</span>
                                          <span className="to">To :</span>
                                          <RangePicker
                                           allowClear={false}
                                            onChange={(value) => this.checkfiltervalidations(value)}
                                            //    defaultValue={[null,null]}
                                              value={[this.state.fromDate,this.state.toDate]}
                                             
                                            />
                                            <span className="datepicker_clear" ><img src={Images.file_remove} onClick={()=>this.ClearDatepicker()}/></span>
                                          </div>
                                    </div>
                             </div>
                         </div>
                    </div>

                    {this.state.responseMsg&&this.state.responseMsg?
                 <div className="failure_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                   }
                 {this.state.responseMsg&&!this.state.responseMsg?
                 <div className="success_msg">{this.state.responseMsg&&this.state.responseMsg}</div> :''
                 }
                    <div className="table-list">


                        <div class="table-responsive">

                        
                            <table class="table table-borderless">
                                <thead>
                                    {this.state.TicketAllHeader &&this.state.TicketAllHeader.length>0&& this.state.TicketAllHeader.map((item)=> {
                                        return(
                                            <tr>     
                                                <th>{item.created_at}</th>
                                                <th>Created Time</th>
                                                <th>{item.driver_name}</th>
                                                <th>Vehicle Number</th>
                                                <th>{item.mobile_number}</th>
                                                <th>{item.booking_id}</th>
                                                <th>{item.svc_name}</th>
                                                <th>{item.svc_id}</th>
                                                <th>{item.ticket_name}</th>
                                                <th>{item.duration}</th>
                                                <th>{item.close}</th>
                                                <th>{item.closed_at}</th>
                                            </tr>
                                        )
                                    })}
                                </thead>
                                <tbody>

                                {this.state.TicketAllList&&this.state.TicketAllList.length>0&&this.state.TicketAllList.map((item,index) => {
                                  const date = new Date(item.created_at)
                                  const close = new Date(item.closed_at)
                                  const getmonth=date.getMonth() + 1;

                                  const finalDate = date.getDate()+'-'+getmonth+'-'+date.getFullYear()
                                  const closeDate = date.getDate()+'-'+getmonth+'-'+date.getFullYear()
                                  const finalTime = date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
                                        return(
                                            <tr>
                                                  <td>{finalDate}</td>
                                                  <td>{finalTime}</td>
                                                 <td>{item.driver_first_name} {item.driver_Last_name}</td>
                                                <td>{item.vehicle_number}</td>
                                                <td>{item.driver_mobile_no}</td>
                                                <td>{item.booking_id}</td>
                                                <td>{item.centre_name}</td>
                                                <td>{item.svc_center_id}</td>
                                                <td style={{width:200,wordBreak:'break-word'}}>{item.ticket}</td>
                                                <td>{item.duration}</td>
                                                <td>{item.close_reason}</td>
                                                <td>{closeDate}</td>
                                                {/* <td><img src={Images.cancel_new} onClick={()=>this.closeTicket(item.id)}/></td> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>

                            {
                                this.state.TicketAllList&&this.state.TicketAllList.length==0&&
                                <div className="no_records_found">No Records Found</div>
                            }

 
                        </div>
                     </div>


                     <Pagination total={this.state.numbercount} current={this.state.currentPage} onChange={(page, pageSize) => this.onchangePagination(page, pageSize)}
      pageSize={this.state.pageSize} pageSizeOptions={['5', '10', '15']} onShowSizeChange={(current, size) => this.showChanger(current, size)} showSizeChanger itemRender={this.itemRender}
    />
             </div>
         
    )
}     
}

// svc_name, customer_name, vehicle_number, booking_id, booking_type, slot, ambassador, booking_status


export default ClosedTickets;