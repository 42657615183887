import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';
import apiservices from '../../../helpers/apiservices';
import images from '../../../helpers/images';
import {Tabs, Tab, Row, Col, Nav} from 'react-bootstrap';
import moment from 'moment';
var complaintHeader = [{'id' : 1, 'date' : 'Date', 'time' : 'Time', 'ambassador_name' : 'Ambassador Name', 'action_by' : 'Action By','complaint':'Complaint'}];
class BookingComplaints extends Component 
{

    constructor(props){

        super(props);

        this.state = {
            complaintHeader : complaintHeader,
            complaintsList:[]

        }
    }
    loadBookingComplaints=(data)=>{
        this.setState({loading:true})
        const token=localStorage['accesstoken'];
        var obj={id:this.props.match.params.id,assign_id:this.props.match.params.assignid};
        console.log("booking svc details obj", obj);   
        apiservices.listbookingcomplaints(obj,token)
              .then((response)=>{
                  this.setState({loading:false});
                  if(!response.error){
                      this.setState({complaintsList:response.response})
                  }else{
                    this.setState({responseMsg:response.message})
                  }
       
                })
    }
    componentWillMount(){
      this.loadBookingComplaints();
    }
render(){
  const offset = moment().utcOffset();
   return(
       

   <div>
               <div className="booking_pickup">
                       
               {this.state.loading==true&&
    <CircularProgress />}
                       <div className="graph-table-listing">
                       <div class="table-responsive">
     <table class="table table-bordered">
       <thead>
         
             {this.state.complaintHeader && this.state.complaintHeader.map((item)=> {
           return(
             <tr>
             <th>{item.date}</th>
           <th>{item.time}</th>
           <th>{item.ambassador_name}</th>
           <th>{item.action_by}</th>
           <th>{item.complaint}</th>
           </tr>
           )
             })}
            
         
       </thead>
       <tbody>
       {this.state.complaintsList && this.state.complaintsList.map((item)=> {
           return(
             <tr>
             <td>{moment(item.created_at).format('DD/MM/YY')}</td>
           <td>{moment.utc(new Date(item.created_at),'hh:mm:ss').utcOffset(offset).format("hh:mm:ss")}</td>
           <td>{item.first_name} {item.last_name}</td>
           <td>{item.action_by}</td>
           <td style={{width:250,wordBreak:'break-word'}}>{item.complaint}</td>
           </tr>
           )
             })}
       </tbody>
     </table>
     {this.state.complaintsList&&this.state.complaintsList.length==0&&
     <div>No Records Found </div>
     }
   </div>
        
 
       
 
                </div>
 </div>
 

</div>

 )
    }
    componentDidMount(){
        this.props.sendbookingid&&this.props.sendbookingid(this.props.match.params.id,this.props.match.params.assignid)
      }
}
export default BookingComplaints;