import apiCrud from './apicrud';
// const apiurl = "https://www.gsautoassist.com/api/";//dev
const apiurl = 'http://188.166.228.50:3000/'; //local
const apiservice = {
  getstudentDetails: async function (userid) {
    var response = await apiCrud.getData(apiurl + 'getstudent/' + userid);
    return response;
  },
  poststudentInfromation: async function (token) {
    var response = await apiCrud.postData(
      apiurl + 'poststudent',
      { name: 'raj', age: 18 },
      token,
    );
    return response;
  },
  login: async function (loginobj) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'adminLogin',
      loginobj,
    );
    return response;
  },
  forgotpassword: async function (loginobj) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'adminForgetPass',
      loginobj,
    );
    return response;
  },
  otpverification: async function (loginobj) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'adminVerifyOTP',
      loginobj,
    );
    return response;
  },
  setpassword: async function (loginobj) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'adminResetPass',
      loginobj,
    );
    return response;
  },
  adddealer: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addSVCDealer',
      loginobj,
      token,
    );
    return response;
  },
  dealerdropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSVCDealerDrop',
      loginobj,
      token,
    );
    return response;
  },
  shoptypedropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listShopTypeDrop',
      loginobj,
      token,
    );
    return response;
  },
  authorizeddropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAuthorisedDrop',
      loginobj,
      token,
    );
    return response;
  },
  vehicletypedropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listVehicleTypeDrop',
      loginobj,
      token,
    );
    return response;
  },
  addservicecentre: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addSVCCenter',
      loginobj,
      token,
    );
    return response;
  },
  listSVC: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllSVCCenter',
      loginobj,
      token,
    );
    return response;
  },
  svcdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSVCCenterById',
      loginobj,
      token,
    );
    return response;
  },
  updatesvccentre: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSVCCenter',
      loginobj,
      token,
    );
    return response;
  },
  designationDropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDesignationDrop',
      loginobj,
      token,
    );
    return response;
  },
  addsvcuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  svcuserlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllActiveSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  adddriver: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDriver',
      loginobj,
      token,
    );
    return response;
  },
  svcuserdisablelist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDisableSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  svcdeleteuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'disableSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  svcactiveuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'activeSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  getuserdata: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSVCUserById',
      loginobj,
      token,
    );
    return response;
  },
  svcupdateuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSVCUser',
      loginobj,
      token,
    );
    return response;
  },
  svcDealerList: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllSVCDealer',
      loginobj,
      token,
    );
    return response;
  },
  svcDealerDetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSVCDealerById',
      loginobj,
      token,
    );
    return response;
  },
  updatedealer: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSVCDealer',
      loginobj,
      token,
    );
    return response;
  },
  activedriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllActiveDriver',
      loginobj,
      token,
    );
    return response;
  },
  disableddriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + '',
      loginobj,
      token,
    );
  },
  driverDetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getDriverById',
      loginobj,
      token,
    );
    return response;
  },
  updatedriverDetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateDriver',
      loginobj,
      token,
    );
    return response;
  },
  disabledriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDisabledDriver',
      loginobj,
      token,
    );
    return response;
  },
  defaulterdriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDefaulterDriver',
      loginobj,
      token,
    );
    return response;
  },
  resigneddriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllResignedDriver',
      loginobj,
      token,
    );
    return response;
  },
  drverreasondropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getDriverStatusDrop',
      loginobj,
      token,
    );
    return response;
  },
  deleteDriver: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteDriver',
      loginobj,
      token,
    );
    return response;
  },
  activeDriver: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'activeDriver',
      loginobj,
      token,
    );
    return response;
  },
  bookingsvclistdropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSVCCenterDrop',
      loginobj,
      token,
    );
    return response;
  },
  bookingcredropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCREDrop',
      loginobj,
      token,
    );
    return response;
  },
  bookingadvicerdropdown: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listServiceAdvicerDrop',
      loginobj,
      token,
    );
    return response;
  },
  permenentDeleteDriver: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'permenentDeleteDriver',
      loginobj,
      token,
    );
    return response;
  },
  listdeleteddrivers: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDeletedDriver',
      loginobj,
      token,
    );
    return response;
  },
  listslotallocation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSlotAllocation',
      loginobj,
      token,
    );
    return response;
  },
  listaddresstype: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAddressTypeDrop',
      loginobj,
      token,
    );
    return response;
  },
  addbookingpickup: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addPickupBooking',
      loginobj,
      token,
    );
    return response;
  },
  addbookingdropoff: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDropOffBooking',
      loginobj,
      token,
    );
    return response;
  },
  addbookingpickupdropoff: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addPickupDropOffBooking',
      loginobj,
      token,
    );
    return response;
  },
  listbookingupcomingpickup: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listUpcommingPickup',
      loginobj,
      token,
    );
    return response;
  },
  listbookingupcomingdropoff: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listUpcommingDropoff',
      loginobj,
      token,
    );
    return response;
  },
  addbookingchauffeur: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addChauffeurBooking',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistactivepickup: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActivePickup',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistactivedropoff: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveDropoff',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistcancel: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCancelBooking',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistpaused: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPausedBooking',
      loginobj,
      token,
    );
    return response;
  },
  cancelbooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'cancelBooking',
      loginobj,
      token,
    );
    return response;
  },
  pausebooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'pauseBooking',
      loginobj,
      token,
    );
    return response;
  },
  activebooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'activeBooking',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistatcentre: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveAtCentre',
      loginobj,
      token,
    );
    return response;
  },
  schedulebooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'scheduleBooking',
      loginobj,
      token,
    );
    return response;
  },
  reschedulebooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'reScheduleBooking',
      loginobj,
      token,
    );
    return response;
  },
  bookinglistcompleted: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCompletedBooking',
      loginobj,
      token,
    );
    return response;
  },
  unassigneddriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listUnAssignedDriverDrop',
      loginobj,
      token,
    );
    return response;
  },
  assigneddriverlist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAssignedDriverDrop',
      loginobj,
      token,
    );
    return response;
  },
  reassignbooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'reassignBooking',
      loginobj,
      token,
    );
    return response;
  },
  assignbooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'assignBooking',
      loginobj,
      token,
    );
    return response;
  },
  interchangebooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'interchangeBooking',
      loginobj,
      token,
    );
    return response;
  },
  bookingdetailsview: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getActiveBookingDetailsById',
      loginobj,
      token,
    );
    return response;
  },
  addbookingcomplaints: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addBookingComplaints',
      loginobj,
      token,
    );
    return response;
  },
  addbookingtickets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addBookingTickets',
      loginobj,
      token,
    );
    return response;
  },
  listbookingcomplaints: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBookingComplaints',
      loginobj,
      token,
    );
    return response;
  },
  listbookingtickets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBookingTickets',
      loginobj,
      token,
    );
    return response;
  },
  listbookinghistory: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBookingProgressHistory',
      loginobj,
      token,
    );
    return response;
  },
  changeadvisorfees: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'changeAdvisoryFees',
      loginobj,
      token,
    );
    return response;
  },
  bookingpaymentuploadinvoice: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateInvoice',
      loginobj,
      token,
    );
    return response;
  },
  updatebookingcustomerdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateCustomerDetails',
      loginobj,
      token,
    );
    return response;
  },
  updatebookingVehicledetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editCustomerDetail',
      loginobj,
      token,
    );
    console.log('response', loginobj);
    return response;
  },
  updatebookingsvcdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateBookingSVCDetails',
      loginobj,
      token,
    );
    return response;
  },
  listtickethigh: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsHigh',
      loginobj,
      token,
    );
    return response;
  },
  closetickets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'closeAdminTickets',
      loginobj,
      token,
    );
    return response;
  },
  cleartickets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'cancelAdminTickets',
      loginobj,
      token,
    );
    return response;
  },
  listticketmedium: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsMedium',
      loginobj,
      token,
    );
    return response;
  },
  listticketlow: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsLow',
      loginobj,
      token,
    );
    return response;
  },
  listticketall: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsAll',
      loginobj,
      token,
    );
    return response;
  },
  listactivebookingdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveBookingDrop',
      loginobj,
      token,
    );
    return response;
  },
  listticketprioritydrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketPriorityDrop',
      loginobj,
      token,
    );
    return response;
  },
  listticketTypedrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsType',
      loginobj,
      token,
    );
    return response;
  },
  addadmintickets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addAdminTickets',
      loginobj,
      token,
    );
    return response;
  },
  listdriveraccidents: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverAccidents',
      loginobj,
      token,
    );
    return response;
  },
  closedriveraccidents: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'closeDriverAccidents',
      loginobj,
      token,
    );
    return response;
  },
  addaccidents: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDriverAccidents',
      loginobj,
      token,
    );
    return response;
  },
  listchecklist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listChecklist',
      loginobj,
      token,
    );
    return response;
  },
  addchecklist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addCheckList',
      loginobj,
      token,
    );
    return response;
  },
  deletechecklist: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteCheckList',
      loginobj,
      token,
    );
    return response;
  },
  listdriverbooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverBookingById',
      loginobj,
      token,
    );
    return response;
  },
  listdriveraudit: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverAuditById',
      loginobj,
      token,
    );
    return response;
  },
  listdriveroverspeed: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverOverSpeedById',
      loginobj,
      token,
    );
    return response;
  },
  listbasepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBasePayout',
      loginobj,
      token,
    );
    return response;
  },
  addbasepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addBasePayout',
      loginobj,
      token,
    );
    return response;
  },
  editpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editPayout',
      loginobj,
      token,
    );
    return response;
  },
  deletepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deletePayout',
      loginobj,
      token,
    );
    return response;
  },
  adddeductionpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDeductionPayout',
      loginobj,
      token,
    );
    return response;
  },
  addincentivepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addIncentivePayout',
      loginobj,
      token,
    );
    return response;
  },
  listincentivepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listIncentivePayout',
      loginobj,
      token,
    );
    return response;
  },
  listincentivepayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listIncentivePayout',
      loginobj,
      token,
    );
    return response;
  },
  listdeductionpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDeductionPayout',
      loginobj,
      token,
    );
    return response;
  },
  addsecuritydepositpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addSecurityDepositPayout',
      loginobj,
      token,
    );
    return response;
  },
  listsecuritydepositpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSecurityDepositPayout',
      loginobj,
      token,
    );
    return response;
  },
  listdrivergoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverGoals',
      loginobj,
      token,
    );
    return response;
  },
  adddailytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDailyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  editdailytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editDailyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  deletedailytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteDailyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  addWweklytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addWeeklyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  editweeklytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editWeeklyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  deleteweeklytaskgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteWeeklyTaskGoals',
      loginobj,
      token,
    );
    return response;
  },
  addWeeklyincentivegoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addWeeklyIncentiveGoals',
      loginobj,
      token,
    );
    return response;
  },
  editweeklyincentivegoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editWeeklyIncentiveGoals',
      loginobj,
      token,
    );
    return response;
  },
  deleteweeklyincentivegoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteWeeklyIncentiveGoals',
      loginobj,
      token,
    );
    return response;
  },
  addloginbonusgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addLoginBonusGoals',
      loginobj,
      token,
    );
    return response;
  },
  editloginbonusgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editLoginBonusGoals',
      loginobj,
      token,
    );
    return response;
  },
  deleteloginbonusgoals: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteLoginBonusGoals',
      loginobj,
      token,
    );
    return response;
  },
  listbankdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBankdetails',
      loginobj,
      token,
    );
    return response;
  },
  addbankdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addBankdetails',
      loginobj,
      token,
    );
    return response;
  },
  listsvcbooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSVCBooking',
      loginobj,
      token,
    );
    return response;
  },
  listsvccenterdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSVCCenterDrop',
      loginobj,
      token,
    );
    return response;
  },
  listAllActiveSVCUserSep: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllActiveSVCUserSep',
      loginobj,
      token,
    );
    return response;
  },
  listAllDisableSVCUserSep: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDisableSVCUserSep',
      loginobj,
      token,
    );
    return response;
  },
  updateSVCUserSep: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSVCUserSep',
      loginobj,
      token,
    );
    return response;
  },
  listadmindesignationdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAdminDesignationDrop',
      loginobj,
      token,
    );
    return response;
  },
  addadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  addadmindesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addAdminDesignation',
      loginobj,
      token,
    );
    return response;
  },
  listallactiveadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllActiveAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  updateadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  getadminuserbyid: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getAdminUserById',
      loginobj,
      token,
    );
    return response;
  },
  disableadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'disableAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  listalldisableadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllDisableAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  activeadminuser: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'activeAdminUser',
      loginobj,
      token,
    );
    return response;
  },
  getadmindesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getAdminDesignation',
      loginobj,
      token,
    );
    return response;
  },
  editadmindesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editAdminDesignationName',
      loginobj,
      token,
    );
    return response;
  },
  deleteadmindesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteAdminDesignation',
      loginobj,
      token,
    );
    return response;
  },
  updateadmindesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateAdminDesignation',
      loginobj,
      token,
    );
    return response;
  },
  listcustomerpayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCustomerPayments',
      loginobj,
      token,
    );
    return response;
  },
  listcashpayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCashPayments',
      loginobj,
      token,
    );
    return response;
  },
  listchequepayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listChequePayments',
      loginobj,
      token,
    );
    return response;
  },
  closepayment: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'closeCustomerBooking',
      loginobj,
      token,
    );
    return response;
  },
  listnewuserrequest: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listNewUserRequest',
      loginobj,
      token,
    );
    return response;
  },
  acceptnewuserrequest: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'acceptNewUserRequest',
      loginobj,
      token,
    );
    return response;
  },
  rejectnewuserrequest: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'rejectNewUserRequest',
      loginobj,
      token,
    );
    return response;
  },
  listticketdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketDrop',
      loginobj,
      token,
    );
    return response;
  },
  addticketdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addTicketDrop',
      loginobj,
      token,
    );
    return response;
  },
  deleteticketdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteTicketDrop',
      loginobj,
      token,
    );
    return response;
  },
  listticketsalllhistory: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsAllHistory',
      loginobj,
      token,
    );
    return response;
  },
  listsettlementadvice: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSettlementAdvice',
      loginobj,
      token,
    );
    return response;
  },
  getminsettlementdate: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getMinSettlementDate',
      loginobj,
      token,
    );
    return response;
  },
  listsettlementdetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSettlementDetails',
      loginobj,
      token,
    );
    return response;
  },
  makesettlementpay: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'makeSettlementPay',
      loginobj,
      token,
    );
    return response;
  },
  listsvcwallethistory: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSVCWalletHistory',
      loginobj,
      token,
    );
    return response;
  },
  getdesigination: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getDesigination',
      loginobj,
      token,
    );
    return response;
  },
  getlocation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getLocation',
      loginobj,
      token,
    );
    return response;
  },
  listcardpayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listCardPayments',
      loginobj,
      token,
    );
    return response;
  },
  listpendingapproval: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPendingApproval',
      loginobj,
      token,
    );
    return response;
  },
  closependingapproval: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'rejectPendingApproval',
      loginobj,
      token,
    );
    return response;
  },
  approvependingapproval: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'approvePendingApproval',
      loginobj,
      token,
    );
    return response;
  },
  listactivebookingdash: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveBookingDash',
      loginobj,
      token,
    );
    return response;
  },
  listSvcDesignationDrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSvcDesignationDrop',
      loginobj,
      token,
    );
    return response;
  },
  addSvcDesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addSvcDesignation',
      loginobj,
      token,
    );
    return response;
  },
  getSvcDesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSvcDesignation',
      loginobj,
      token,
    );
    return response;
  },
  editSvcDesignationName: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editSvcDesignationName',
      loginobj,
      token,
    );
    return response;
  },
  deleteSvcDesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteSvcDesignation',
      loginobj,
      token,
    );
    return response;
  },
  updateSvcDesignation: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSvcDesignation',
      loginobj,
      token,
    );
    return response;
  },
  listselfactivcomplaints: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSelfActiveComplaints',
      loginobj,
      token,
    );
    return response;
  },
  closeselfactivecomplaints: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'closeSelfActiveComplaints',
      loginobj,
      token,
    );
    return response;
  },
  listselfslosedcomplaints: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSelfClosedComplaints',
      loginobj,
      token,
    );
    return response;
  },
  listchannelpartner: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listChannelPartner',
      loginobj,
      token,
    );
    return response;
  },
  addchannelpartner: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addChannelPartner',
      loginobj,
      token,
    );
    return response;
  },
  listsvctargets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSvcTargets',
      loginobj,
      token,
    );
    return response;
  },
  updatesvctargets: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateSvcTargets',
      loginobj,
      token,
    );
    return response;
  },
  listsvcperformance: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSvcPerformance',
      loginobj,
      token,
    );
    return response;
  },
  listdriverattendance: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverAttendance',
      loginobj,
      token,
    );
    return response;
  },
  listqrpayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listQrPayments',
      loginobj,
      token,
    );
    return response;
  },
  listsvcpayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSvcPayments',
      loginobj,
      token,
    );
    return response;
  },
  listsvctrends: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listSvcTrends',
      loginobj,
      token,
    );
    return response;
  },
  listpaymentconveyance: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPaymentConveyance',
      loginobj,
      token,
    );
    return response;
  },
  listpaymentpayout: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPaymentPayout',
      loginobj,
      token,
    );
    return response;
  },
  listpaymentincentive: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPaymentIncentive',
      loginobj,
      token,
    );
    return response;
  },
  listpaymentincrement: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPaymentIncrement',
      loginobj,
      token,
    );
    return response;
  },
  listpaymentdecrement: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listPaymentDecrement',
      loginobj,
      token,
    );
    return response;
  },
  llistdrivermonthlymaymentsbyid: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'llistDriverMonthlyPaymentsById',
      loginobj,
      token,
    );
    return response;
  },
  llistdriverbookingpaymentsbyid: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'llistDriverBookingPaymentsById',
      loginobj,
      token,
    );
    return response;
  },
  llistDriverMonthlyPaymentsById: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'llistDriverMonthlyPaymentsById',
      loginobj,
      token,
    );
    return response;
  },
  updateAddedConvetanceAmount: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateAddedConvetanceAmount',
      loginobj,
      token,
    );
    return response;
  },
  updateDeductionsAmount: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'updateDeductionsAmount',
      loginobj,
      token,
    );
    return response;
  },
  listdriverslotperformance: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverSlotPerformance',
      loginobj,
      token,
    );
    return response;
  },
  listdriverdateperformance: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverDatePerformance',
      loginobj,
      token,
    );
    return response;
  },
  listdriverperformancebyid: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'llistDriverPerformanceById',
      loginobj,
      token,
    );
    return response;
  },
  listdrivernotification: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDriverNotification',
      loginobj,
      token,
    );
    return response;
  },
  listactivedriverdrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveDriverDrop',
      loginobj,
      token,
    );
    return response;
  },
  adddrivernotification: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addDriverNotification',
      loginobj,
      token,
    );
    return response;
  },
  listdashcarddetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listDashCardDetails',
      loginobj,
      token,
    );
    return response;
  },
  listTicketCount: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listTicketsCounts',
      loginobj,
      token,
    );
    return response;
  },
  listonlinepayments: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listOnlinePayments',
      loginobj,
      token,
    );
    return response;
  },
  listAllActiveDriverDrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllActiveDriverDrop',
      loginobj,
      token,
    );
    return response;
  },
  listAllPaymentTypeDrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listAllPaymentTypeDrop',
      loginobj,
      token,
    );
    return response;
  },
  listWalletStatusDrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listWalletStatusDrop',
      loginobj,
      token,
    );
    return response;
  },
  listActiveBookingStatusDrop: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listActiveBookingStatusDrop',
      loginobj,
      token,
    );
    return response;
  },
  getSettlementAdviceDetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSettlementAdviceDetails',
      loginobj,
      token,
    );
    return response;
  },
  getSvcCustomerPaymentById: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSvcCustomerPaymentById',
      loginobj,
      token,
    );
    return response;
  },
  getSvcAccountStatementById: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSvcAccountStatementById',
      loginobj,
      token,
    );
    return response;
  },
  getSlotDetails: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getSlotDetails',
      loginobj,
      token,
    );
    return response;
  },
  listbookingcalllogs: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBookingCallLogs',
      loginobj,
      token,
    );
    return response;
  },
  autoCompleteBooking: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'autoCompleteBooking',
      loginobj,
      token,
    );
    return response;
  },
  listBookingSms: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'listBookingSms',
      loginobj,
      token,
    );
    return response;
  },
  makeCall: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'makeCall',
      loginobj,
      token,
    );
    return response;
  },
  makeDriverAsCustomerPlace: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'makeDriverAsCustomerPlace',
      loginobj,
      token,
    );
    return response;
  },
  clearNotification: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'clearNotification',
      loginobj,
      token,
    );
    return response;
  },
  addzone: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'addZone',
      loginobj,
      token,
    );
    return response;
  },
  listallactivezone: async function (loginobj, token) {
    var response = await apiCrud.getData(
      apiurl + 'admin' + '/' + 'zoneListDropDown',
      loginobj,
      token,
    );
    return response;
  },
  listallactivezoneTable: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'zoneList',
      loginobj,
      token,
    );
    return response;
  },
  updateeditZone: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'editZone',
      loginobj,
      token,
    );
    return response;
  },
  disableadminzone: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'deleteZone',
      loginobj,
      token,
    );
    return response;
  },
  getadminzonebyid: async function (loginobj, token) {
    var response = await apiCrud.postData(
      apiurl + 'admin' + '/' + 'getOneZone',
      loginobj,
      token,
    );
    return response;
  },
};
export default apiservice;
