import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
  useRouteMatch,
  Switch,
  Redirect
} from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import './styles.css';
import AddUser from '../UsersMain/AddUser';
import DisabledUser from '../UsersMain/DisabledUser';
import UserList from '../UsersMain/UserList';
import Designation from '../UsersMain/Designation';

// import BookingListMain from './BookingListMain';
// import SlotAllocation from '../../Pages/SlotAllocation';
// import Accidents from '../../Pages/Accidents';
// import SvcList from '../../Pages/SvcList';
// import DealerMasterList from "../../Pages/DealerMasterList";
class UsersMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return (
      <div className="container">
        <div class="booking-tabs">
          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first"> */}
          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row booking_mainview" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>
                <Nav.Item>
                  <NavLink  exact={true} to={`${this.props.match.path}/`} activeClassName="active_cls">Add User</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink  to={`${this.props.match.path}/user_list`} activeClassName="active_cls">User List</NavLink>
                </Nav.Item>
                  <Nav.Item>
                  <NavLink to={`${this.props.match.path}/disabled_user`} activeClassName="active_cls">Disabled User</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to={`${this.props.match.path}/designation`} activeClassName="active_cls">Designation</NavLink>
                </Nav.Item>
                
              </Nav>
             
            </Col>
            <Col sm={12}>
             
              <Switch>
              <Route exact path={`${this.props.match.path}/`} render={props => (<AddUser {...props} />)} />
                                <Route exact path={`${this.props.match.path}/user_list`} render={props => (<UserList {...props} />)} />
                                <Route exact path={`${this.props.match.path}/disabled_user`} render={props => (<DisabledUser {...props} />)} />
                                <Route exact path={`${this.props.match.path}/designation`} render={props => (<Designation {...props} />)} />
              </Switch>
            </Col>
          </Row>
          {/* </Tab.Container> */}
        </div>
      </div>
    )

  }
  componentDidMount() {
    this.props.receivePageTitle && this.props.receivePageTitle('User');
  }

}


export default UsersMain;