import React, { Component } from 'react';
import './style.css';
import Images from '../../helpers/images';
import { Tabs, Tab, Row, Col, Nav } from 'react-bootstrap';
import
  {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,

    useRouteMatch,
    Switch,
    Redirect
  } from "react-router-dom";
import ActivePickUp from '../BookingList/ActivePickUp';
import ActiveDropOff from '../BookingList/ActiveDropOff';
import ActiveAtCenter from '../BookingList/ActiveAtCenter';

//   import SvcDetails from '../SvcDetails';
//   import SvcDetailsBooking from '../SvcDetailsBooking';
//   import Payment from '../Svc_Payment';
//   import SvcUsers from '../SvcUser';
// import { First } from 'react-bootstrap/PageItem';
class BookingListActive extends Component
{
  constructor(props)
  {
    console.log("props", props);
    super(props);
    this.state = {
      SvcDetails: false,
      svcList: true,
      tabKey: 'first', useredit_id: null,
      svcDynId: null,
      svc_id: null
    }
  }
  SvcDetails = () =>
  {

    this.setState({ SvcDetails: true });

    this.setState({ svcList: false });

  }
  componentWillMount()
  {
    if (this.props.action == 'first')
    {
      this.setState({ tabKey: 'fourth' })
    } else if (this.props.action == 'second')
    {
      this.setState({ tabKey: 'fourth' })
    } else
    {
      this.setState({ tabKey: 'first' })
    }
    console.log("this props", this.props);
  }
  svcDetailsHide = () =>
  {
    this.setState({ SvcDetails: false });

    this.setState({ svcList: true });
  }
  componentWillReceiveProps(props)
  {
    this.setState({ tabKey: props });
    //  var useredit_id=props.history.location.state;
    //  this.setState({useredit_id:useredit_id});
    //  console.log("will receive",this.state.useredit_id);
  }
  goBack = (data) =>
  {
    console.log("goback", data);
    //  this.props.goBack(data)
  }
  render()
  {

    return (
      <div >


        <div class="svcDetails-tabs svcdetails_main mt-5">

          <Row>
            <Col sm={12}>
              <Nav variant="pills" className="flex-row svcdetails_nav" selectedIndex={this.state.selectedIndex}
                onSelect={this.handleSelect}>

                <NavLink exact={true} to={`${this.props.match.path}/`}>PickUp</NavLink>
                <NavLink to={`${this.props.match.path}/drop-off`}  >Drop - off</NavLink>
                <NavLink to={`${this.props.match.path}/at-center`} >At Centre</NavLink>

              </Nav>
            </Col>
            <Col sm={12}>
              {/* <div className="svc_first"> */}
              <Switch>
                <Route exact path={`${this.props.match.path}/`} render={props => (<ActivePickUp  {...props} parentProps={this.props.parentProps} />)} />
                <Route exact path={`${this.props.match.path}/drop-off`} render={props => (<ActiveDropOff {...props} parentProps={this.props.parentProps} />)} />
                <Route exact path={`${this.props.match.path}/at-center`} render={props => (<ActiveAtCenter {...props} parentProps={this.props.parentProps} />)} />


              </Switch>

              {/* </div> */}
            </Col>
          </Row>
          {/* </Tab.Container> */}
        </div>
        {/* Form Tabs start */}
      </div>
    )
  }


}

export default BookingListActive;