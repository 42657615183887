
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import './styles.css';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import { DatePicker ,Select,Upload,message} from 'antd';
import moment from 'moment';
import S3FileUpload from "react-s3";
import Aws from "../../../helpers/awskeys";
const { Dragger } = Upload;
const config = {
  bucketName: Aws.bucketName,
  dirName: "photos" /* optional */,
  region: Aws.region,
  accessKeyId: Aws.accessKeyId,
  secretAccessKey: Aws.secretAccessKey,
};
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
function handleChange(value) {
    console.log(`selected ${value}`);
  }
  var slotdata=[{id:1,startime:'07',middletext:'to',endtime:'08',lasttext:'AM'},{id:2,startime:'08',middletext:'to',endtime:'09',lasttext:'AM'},{id:3,startime:'09',middletext:'to',endtime:10,lasttext:'AM'},{id:4,startime:10,middletext:'to',endtime:11,lasttext:'AM'},{id:5,startime:11,middletext:'to',endtime:12,lasttext:'AM'},{id:6,startime:12,middletext:'to',endtime:'01',lasttext:'PM'},{id:7,startime:'01',middletext:'to',endtime:'02',lasttext:'PM'},{id:8,startime:'02',middletext:'to',endtime:'03',lasttext:'PM'},{id:9,startime:'03',middletext:'to',endtime:'04',lasttext:'PM'},{id:10,startime:'04',middletext:'to',endtime:'05',lasttext:'PM'},{id:11,startime:'05',middletext:'to',endtime:'06',lasttext:'PM'},{id:12,startime:'06',middletext:'to',endtime:'07',lasttext:'PM'}]
class SlotServiceDetails extends Component{
    constructor(props){
        super(props);
        this.state = {  
          slotData:[],
          imageUrl:'',
          filesUpload:"",
          filenewlist:[],
          svccentreid:props.svccentreid,
          fileupload_error:null,
          fields:{
            slotdate:{validations:[{name:'required'}],error:null,value:moment()},
            slottimings:{validations:[{name:'required',errormsg:'Slot Required'}],error:null,value:''},
            cre:{validations:[{name:'required'}],error:null,value:''},
            service_advisor:{validations:[{name:'required'}],error:null,value:''},
            notes:{validations:[{name:'required'},{name:'Noemptyspace'},],error:null,value:''},

          }
        }
    }
    onChange=(date, dateString) =>{
      console.log(date, dateString);
    }
    goBack=()=>{
      this.props.sendgoback(2)
    }
    checkvalidations=(value,key)=>{
   
      var fields =this.state.fields;
      fields[key].value=value;
      if(key=='slotdate'){
        this.loadslottimings(this.props.slotService?this.props.slotService.slottimings.value:null,value)
        // this.loadslottimings(value);
      }
      console.log("key",key);
      var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
      
      fields[key].error=checkstatus.msg;
      var getallkeys=Object.keys(fields);
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        this.setState({error:true})
        //errorblock
      }else{
        this.setState({error:false},function(){
          this.props.sendpickupdetails(4,'slotService', fields);
        })
  
        //successblock
      }
      this.setState({fields});
    }
    loadcreDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={svc_center_id:this.state.svccentreid};
            apiservices.bookingcredropdown(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({cre:response.response})
                var fields=this.state.fields;
                if(!value){
                fields['cre'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    loadAdvisorDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={svc_center_id:this.state.svccentreid};
            apiservices.bookingadvicerdropdown(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({service_advisor:response.response})
                var fields=this.state.fields;
                if(!value){
                fields['service_advisor'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    loadslottimings=(id,datevalue)=>{
      console.log("date",datevalue);
      const slotday=moment(datevalue).format('YYYY-MM-DD');
      const token=localStorage['accesstoken'];
    var obj={slot_day:slotday?slotday:new Date()};
    console.log("slot obj",obj);
    apiservices.getSlotDetails(obj,token)
          .then((response)=>{
            console.log("slot data", response); 
            if(response.response.length==0){
              return true;
            }
            if(id){
              var findindex=response.response.findIndex((obj)=>obj.id==id);
              if(findindex!=-1){
                response.response[findindex].checked=true;
              }
            }  
            
              if(!response.error){
                this.setState({slotData:response.response});
                }
              else{
                this.setState({error_msg:response.message})
              }
          })
    }
    gotoSubmit = async () => {
      // this.props.sendpickupdetails(2);
      var fields =this.state.fields;
    var getallkeys=Object.keys(fields);
    getallkeys.map((obj)=>{
    var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
    fields[obj].error=checkstatus.msg;
    })
    var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
    if(filtererrors.length>0){
      //errorblock
      this.setState({error:true})
    }else{
      //successblock
      this.setState({error:false});
      this.props.sendpickupdetails(4,'slotService', fields,'submit');
     
    //   //successblock
      this.setState({error:false});
    }
    this.setState({fields});  
    
    }
    
    getSlotData=(data,index)=>{
      // this.setState({color:'grey'})
      var slotData=this.state.slotData;
      slotData.map((obj,slotindex)=>{
        var slotobj=obj
        // console.log("obj",obj)
        if(slotindex!=index){
          slotobj.checked=false;
        }
        return slotobj;
    })
      slotData[index].checked=data.checked?!data.checked:true;
      this.setState({slotData});
      // this.setState({color:!this.state.color})
      var slotfilter=slotData.filter((timeobj)=>timeobj.checked==true);
      if(slotfilter.length>0){
        this.checkvalidations(data.id,'slottimings');
      }else{
        this.checkvalidations("",'slottimings');
      }

      // console.log("slot filter",slotfilter);
    }
    componentWillMount(){
  
      this.loadslottimings(this.props.slotService?this.props.slotService.slottimings.value:null);
    }
    componentWillReceiveProps(props){
      console.log("props",props);
      if(props.addpickupdropoffSuccess){
        this.setState({bookingresponse:props.addpickupdropoffSuccess})
        setTimeout(() => {
          // props.history.push('/booking/booking_list/bokinglist_main/active');
          props.history.push('/booking/booking_list/bokinglist_main/upcoming/');
        }, 3000);
        
      }
      if(props.addpickupdropoffFailure){
        this.setState({bookingresponse:props.addpickupdropoffFailure})
      }
      
    }
    render(){
     
      const {cre,service_advisor}=this.state;
        return(
            <div className="pickup">

 
        <div class="overall-service">
         <div className="dealer-master">
            
                <div className="dealer-head">
                3. Slot and Service Details
                </div>
                <div className="dealer-body">
                     
                <div className="form-group">
                       <div class="form-inline position_slot">
                          <label>Select Date</label>
                          <DatePicker className="slotdate" onChange={(value) => this.checkvalidations(value,'slotdate')} value={this.state.fields.slotdate.value}   disabledDate={current => {
                       return current && current < moment().startOf('day');
                   }} suffixIcon={<img src={Images.date_picker} className="datepicker_img_slot" />}/>
                           {/* <img src={Images.date_picker} className="datepicker_img" onClick={()=>this.togglePicker()}/> */}
                           <span className="error_msg_adddealer">{this.state.fields['slotdate'].error&&this.state.fields['slotdate'].error}</span> 
                        </div> 
                                         
                     </div>
                     {/* <div className="form-group">
                     <div class="form-inline">
                     <div className="or_text">Or</div>
                     </div>
                     </div> */}
                     <div className="form-group">
                     <div class="form-inline" style={{width:'85%'}}>
                     <label>Slot Timings</label>
                     {this.state.slotData&&this.state.slotData.length>0&&this.state.slotData.map((item,index)=>{
                       return(
                       <div style={{ backgroundColor: item.checked==true?'#EBEBEB':'',opacity:item.is_disable!=1?1:0.4 }}  className="slot_box" onClick={()=>item.is_disable!=1?this.getSlotData(item,index):''}>{item.time}</div>
                       )
                     })}
                  <span className="error_msg_adddealer">{this.state.fields['slottimings'].error&&this.state.fields['slottimings'].error}</span>
                     
                     </div>
                     </div>
                       
                      
                     <div className="form-group">
                     <div class="form-inline position_set">
                          <label>CRE *</label>
                          {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}
                          
                          <Select value={[parseInt(this.state.fields.cre.value)]}  onChange={(value) => this.checkvalidations(value,'cre')} name="cre" >
                          {cre&&cre.length>0&&cre.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.fields['cre'].error&&this.state.fields['cre'].error}</span> 
                         
                        {/* </div> */}
                          {/* <i
                          nput type="text" className="form-control"/>  */}
                        </div>  

                        <div class="form-inline position_set">
                          <label>Service Advisor *</label>
                          {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}
                          
                          <Select value={[parseInt(this.state.fields.service_advisor.value)]}  onChange={(value) => this.checkvalidations(value,'service_advisor')} name="service_advisor" >
                          {service_advisor&&service_advisor.length>0&&service_advisor.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid reason_dropdown-img"/> 
                           
                            <span className="error_msg_adddealer">{this.state.fields['service_advisor'].error&&this.state.fields['service_advisor'].error}</span> 
                         
                        {/* </div> */}
                          
                        </div>                     
                     </div>
                    


                     
                     {/* <div class="form-inline">
                         
                        <label>Upload Invoice Copy</label>
                        <div style={{width:'100%'}}>
                         <Dragger {...props}  >
    <div><button className="upload_btn">Upload Files</button></div>
    <p className="ant-upload-text"></p>
    <p className="ant-upload-hint">
    Files can be in .jpg or .pdf format
    </p>
  </Dragger>
  
  <span className="error_msg_driver">{this.state.fields['upload_image'].error && this.state.fields['upload_image'].error}</span> 
  </div>
                          
                        </div> */}
                       <div className="form-group">
                         <label>
                         Notes
                         </label>

                         <textarea row="5" maxLength="300" className="form-control" name="notes" value={this.state.fields.notes.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)} ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['notes'].error&&this.state.fields['notes'].error}</span> 
                         
                     </div>                                      
                        






                </div>

                
             </div>
             <button disabled={this.state.bookingresponse&&!this.state.bookingresponse.error} className={`btn btn-white-blue prev-button `}  onClick={()=>this.goBack()}>  <img src={Images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button disabled={this.state.bookingresponse&&!this.state.bookingresponse.error} className="btn btn-dark-blue next-button" onClick={()=>this.gotoSubmit()}>Schedule  </button>
             {this.state.bookingresponse&&this.state.bookingresponse.error?
            <div className="failure_msg">{this.state.bookingresponse&&this.state.bookingresponse.message}</div> :''
            }
            {this.state.bookingresponse&&!this.state.bookingresponse.error?
            <div className="success_msg">{this.state.bookingresponse&&this.state.bookingresponse.message}</div> :''
            }
             </div>




 


        
            </div>
        )





        
    }
    componentDidMount(){
      console.log("didmount slotservice",this.props);
      if(this.props.slotService){
        
          this.setState({fields:this.props.slotService},function(){
            this.loadcreDropdown(this.state.fields.cre.value);
            this.loadAdvisorDropdown(this.state.fields.service_advisor.value);
          });

      }else{
        this.loadcreDropdown();
      this.loadAdvisorDropdown();
      }
      if(this.props.receiveImage){
        this.setState({filenewlist:this.props.receiveImage})
        console.log("eceive image",this.props.receiveImage);
      }
  
  }
 
}


export default SlotServiceDetails;