
import React, {Component} from 'react';
import ValidationLibrary from '../../../helpers/validationfunction';
import { Select } from 'antd';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import '../styles.css';
import Images from '../../../helpers/images';
import apiservices from '../../../helpers/apiservices';
import {Map, InfoWindow,  GoogleApiWrapper} from 'google-maps-react';
import {
  withScriptjs,
  GoogleMapReact,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import Geocode from "react-geocode";
const { Option } = Select;
const MapWithAMarkerPickup = withGoogleMap((props) =>
{
  const location={ lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const  onMarkerDragEnd = (event) =>{
    props.sendingDrabbleLocationPickup&&props.sendingDrabbleLocationPickup(event.latLng);
      }
return(
<div>
<GoogleMap
  zoom={15}
  style={{borderRadius:20,width: '100%', height: '100%'}}
  center={location}
  defaultCenter={location}
  // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl= {false}
        streetViewControl= {false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
  <Marker
    position={location}
    draggable={true}
     ref={props.onMarkerMounted}
     onDragEnd={(data)=>onMarkerDragEnd(data)}
  />
</GoogleMap>
</div>
)  
}

);
const MapWithAMarkerDrop = withGoogleMap((props) =>
{
  const locationdropoff={ lat: props.currentLocation.lat, lng: props.currentLocation.lng };
  const  onMarkerDragEndDrop = (event) =>{
    props.sendingDrabbleLocationDropoff&&props.sendingDrabbleLocationDropoff(event.latLng);
      }
return(
<div>
<GoogleMap
  zoom={15}
  style={{borderRadius:20,width: '100%', height: '100%'}}
  center={locationdropoff}
  defaultCenter={locationdropoff}
  // defaultCenter={{ lat: props.currentLatLng, lng: props.currentLatLng}}
        mapTypeControl= {false}
        streetViewControl= {false}
        fullscreenControl={false}
        zoomControl={true}
        centerAroundCurrentLocation={true}>
  <Marker
    position={locationdropoff}
    draggable={true}
     ref={props.onMarkerMounted}
     onDragEnd={(data)=>onMarkerDragEndDrop(data)}
  />
</GoogleMap>
</div>
)  
}

);

class PickupDropLocation extends Component{
    constructor(props){
        super(props);
        this.state = {
          clonedaddress:'',
          predictionsPickup:[],
          predictionDropoff:[],
          fields:{
            pickup_address:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
            pickup_address_type:{validations:[{name:'required'}],error:null,value:''},
            drop_address:{validations:[{name:'required'},{name:'Noemptyspace'}],error:null,value:''},
            drop_address_type:{validations:[{name:'required'}],error:null,value:''},
            pickupLatLng:{validations:[],error:null,value:{lat:0,lng:0}},
            dropLatLng:{validations:[],error:null,value:{lat:0,lng:0}},
            cloneddropLatLng:{validations:[],error:null,value:{lat:0,lng:0}},
            clonedaddress:{validations:[],error:null,value:''},
            sameaddress:{validations:[],error:null,value:false},
          },
 
        checkboxdisplay:false
           
        }
    }

    componentWillMount(){
   
      this.getCurrentPosition();
      // this.getlocationbyAddress();
    }
    getCurrentPosition = () => {
       
      var self = this;
      navigator.geolocation.getCurrentPosition(position => {
        console.log("position",position)
        var obj={
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          latitudeDelta: 0.005,
          longitudeDelta: 0.005,
          coords: position.coords
      }
      var fields=this.state.fields;
      fields['pickupLatLng'].value=obj;
      fields['dropLatLng'].value=obj;
      fields['cloneddropLatLng'].value=obj;
          this.setState({
              fields
          });
             });
     
    }
    gotoslotservice=()=>{
      var fields =this.state.fields;
      var getallkeys=Object.keys(fields);
      getallkeys.map((obj)=>{
      var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
      fields[obj].error=checkstatus.msg;
      })
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        //errorblock
        this.setState({error:true})
      }else{
        //successblock
        this.setState({error:false});
        this.props.sendpickupdetails(3,'pickupLocation',fields);
      
      }
      this.setState({fields});
      // this.props.sendpickupdetails(3)
    }
    goBack=()=>{
      this.props.sendgoback(1)
    }
    checkvalidations=(value,key)=>{
      var fields =this.state.fields;
      fields[key].value=value;
      console.log("value",value);
      var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
      // console.log(checkstatus);
      if(key=='pickup_address'){
        this.dropAddress();
        this.onChangePickupAddress(value);
        this.getlocationbyAddress(value,"pickupLatLng");      
      }
      if(key=='drop_address'){
        this.onChangeDropoffAddress(value);
        this.getlocationbyAddress(value,"dropLatLng","cloneddropLatLng","clonedaddress");
      }
      fields[key].error=checkstatus.msg;
      var getallkeys=Object.keys(fields);
      var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
      if(filtererrors.length>0){
        this.setState({error:true})
        //errorblock
      }else{
        this.setState({error:false})
  
        //successblock
      }
      this.setState({fields});
    }
    dropAddress =()=>{
      console.log("drop change");
      var fields=this.state.fields;
      var latitude =fields['cloneddropLatLng'].value.lat;
      var longitude =fields['cloneddropLatLng'].value.lng;
      console.log("drop checking lat",this.state.fields.cloneddropLatLng.value.lat)
      Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
    Geocode.enableDebug();
    Geocode.fromLatLng(latitude,longitude).then(
      response => {
        const address=response.results[0].formatted_address;
        console.log("drop address",address);
        // const { lat, lng } = response.results[0].geometry.location;
        // fields['drop_address'].value=address;
        // this.setState({fields});
      },
      error => {
        console.error(error);
      }
    );
    }
    getlocationbyAddress=(value,key,clonedkey,clonedaddress)=>{

      console.log("pickup change");
      var self=this;
      Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
      Geocode.enableDebug();
      Geocode.fromAddress(value).then(
        response => {
         
          console.log("response",response)
          const { lat, lng } = response.results[0].geometry.location;
          var getlocation={lat:lat,lng:lng};
          var fields=this.state.fields;
          fields[key].value=getlocation;
          // this.setState({[key]:getlocation});
          if(clonedkey){
            fields[clonedkey].value=getlocation;
            // this.setState({[clonedkey]:getlocation});
          }
          if(clonedaddress){
            fields[clonedaddress].value=value;
            
            // this.setState({[clonedaddress]:value});
          }
          
          this.setState({fields});
        },
        error => {
          console.error(error);
        }
      );
         
    }
    loadpickupaddresstypeDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={};
            apiservices.listaddresstype(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({pickup_address_type:response.response})
                var fields=this.state.fields;
                if(!value){
                fields['pickup_address_type'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    
    loaddropaddresstypeDropdown=(value)=>{
      const token=localStorage['accesstoken'];
      var obj={};
            apiservices.listaddresstype(obj,token)
            .then((response)=>{
              if(!response.error){
                this.setState({drop_address_type:response.response})
                var fields=this.state.fields;
                if(!value){
                fields['drop_address_type'].value=response.response.length>0?response.response[0].id:'';            
                this.setState({fields})
                }
                console.log("login response",response);
        
              }else{
                this.setState({error_msg:response.message})
              }
                
            })
    }
    checkboxChange=()=>{
      var fields=this.state.fields;
      fields['sameaddress'].value=!fields['sameaddress'].value;
        if(fields.sameaddress.value==true){
          fields['drop_address'].value=fields['pickup_address'].value;
          fields['cloneddropLatLng'].value=fields['pickupLatLng'].value;
        }else{
          fields['drop_address'].value=fields['clonedaddress'].value
          fields['cloneddropLatLng'].value=fields['dropLatLng'].value;
        }
        this.setState({fields})
        
    
    }
    getAddressbyLatLngPickup =(data)=>{

      var latitude=data.lat.toString();
          var longitude=data.lng.toString();
        var self=this;
        Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
        Geocode.enableDebug();
        Geocode.fromLatLng(latitude,longitude).then(
          response => {
            console.log("response",response)
            const { lat, lng } = response.results[0].geometry.location;
            const address=response.results[0].formatted_address;
            console.log("address",address)
            var fields=self.state.fields;
            fields['pickupLatLng'].value.lat=lat;
            fields['pickupLatLng'].value.lng=lng;
            fields['pickup_address'].value=address;
            if(this.state.fields.sameaddress.value==true){
            fields['drop_address'].value=address;
            }
            self.setState({fields});
          },
          error => {
            console.error(error);
          }
        );
    
      }
      getAddressbyLatLngDropoff =(data)=>{
        var latitude=data.lat.toString();
            var longitude=data.lng.toString();
          var self=this;
          Geocode.setApiKey("AIzaSyAn7Qh20zo-0AXDoS-quQ1xvoLFXnnFDLc");
          Geocode.enableDebug();
          Geocode.fromLatLng(latitude,longitude).then(
            response => {
              console.log("response",response)
              const { lat, lng } = response.results[0].geometry.location;
              const address=response.results[0].formatted_address;
              console.log("address",address)
              var fields=self.state.fields;
              fields['dropLatLng'].value.lat=lat;
              fields['dropLatLng'].value.lng=lng;
              fields['drop_address'].value=address;
              self.setState({fields});
            },
            error => {
              console.error(error);
            }
          );
      
        }

        onChangePickupAddress =(value) => {
          var address_url='https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+value+'&components=country:ind&key='
          const token=localStorage['accesstoken'];
          var obj={url:address_url};
          console.log("get location obj",obj);
                apiservices.getlocation(obj,token)
                .then((response)=>{
                  if(!response.error){
                    this.setState({predictionsPickup:response.response.predictions});
                    // this.setState({predictionDropoff:response.response.predictions});
                   console.log("get location",response)
            
                  }else{
                    this.setState({error_msg:response.message})
                  }
                    
                })
          
      
      }
      onChangeDropoffAddress =(value) => {
        var address_url='https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+value+'&components=country:ind&key='
        const token=localStorage['accesstoken'];
        var obj={url:address_url};
        console.log("get location obj",obj);
              apiservices.getlocation(obj,token)
              .then((response)=>{
                if(!response.error){
                  this.setState({predictionDropoff:response.response.predictions});
                  // this.setState({predictionDropoff:response.response.predictions});
                 console.log("get location",response)
          
                }else{
                  this.setState({error_msg:response.message})
                }
                  
              })
        
    
    }
      printPickupAddress=()=> {
        return(
            <div className="address_bardisplay"> 
      <List className="address_boxlist">
      
      {this.state.predictionsPickup.map((data,key)=>{
       return(
           
      <ListItem className="list-location" key={key}  onClick={()=>this.changeAddress(data)}>
      
      <img src={Images.map_icon} className="map_icon_cls"/><p style={{cursor:'pointer'}}>{data.description}</p>
           </ListItem>  
           )
      })}
           
           </List> 
           </div>
        )
      }
      printDropoffAddress=()=> {
        return(
            <div className="address_bardisplay"> 
      <List className="address_boxlist">
      
      {this.state.predictionDropoff.map((data,key)=>{
       return(
           
      <ListItem className="list-location" key={key}  onClick={()=>this.changeDropAddress(data)}>
      
      <img src={Images.map_icon} className="map_icon_cls"/><p style={{cursor:'pointer'}}>{data.description}</p>
           </ListItem>  
           )
      })}
           
           </List> 
           </div>
        )
      }
      changeAddress=(data)=>{
        console.log("change address",data);
        var fields=this.state.fields;
        fields['pickup_address'].value=data.description;
        this.setState({fields,predictionsPickup:[]},function(){
          this.loadMapPredictions(data.place_id);
        })
      }
      loadMapPredictions =(value)=>{
        // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
        var addres_url='https://maps.googleapis.com/maps/api/place/details/json?placeid='+value+'&key='
        const token=localStorage['accesstoken'];
        console.log("")
          var obj={url:addres_url};
        apiservices.getlocation(obj,token)
        .then((response)=>{
          if(!response.error){
      
            var getlocation=response.response.result.geometry.location;
           console.log("geolocation",getlocation)
           var fields=this.state.fields;
           fields['pickupLatLng'].value.lat=getlocation.lat;
           fields['pickupLatLng'].value.lng=getlocation.lng;
           this.setState({fields});
      
          }else{
            this.setState({error_msg:response.message})
          }
            
        })
      }
      changeDropAddress =(data)=>{
        var fields=this.state.fields;
        fields['drop_address'].value=data.description;
        this.setState({fields,predictionDropoff:[]},function(){
          this.loadDropMapPredictions(data.place_id);
        })
      }
      loadDropMapPredictions =(value)=>{
        // var addresschange_url='https://maps.googleapis.com/maps/api/geocode/json?place_id='+value+'&key='
        var addres_url='https://maps.googleapis.com/maps/api/place/details/json?placeid='+value+'&key='
        const token=localStorage['accesstoken'];
        console.log("")
          var obj={url:addres_url};
        apiservices.getlocation(obj,token)
        .then((response)=>{
          if(!response.error){
      
            var getlocation=response.response.result.geometry.location;
           console.log("geolocation",getlocation)
           var fields=this.state.fields;
           fields['dropLatLng'].value.lat=getlocation.lat;
           fields['dropLatLng'].value.lng=getlocation.lng;
           this.setState({fields});
      
          }else{
            this.setState({error_msg:response.message})
          }
            
        })
      }
    render(){
    //  console.log("pickup address",this.state.fields.pickup_address.value)
    //  console.log("drop address",this.state.fields.drop_address.value);
    //  console.log("cloned address",this.state.fields.clonedaddress.value)
    console.log("pickup latitude",this.state.fields.pickupLatLng.value.lat)
    console.log("drop latitude",this.state.fields.dropLatLng.value.lat)
    console.log("cloned latitude",this.state.fields.cloneddropLatLng.value.lat)
      const {pickup_address_type,drop_address_type,fields} =this.state;
        return(
            <div className="pickup">

 
        <div class="overall-service">
         <div className="dealer-master">
            
                <div className="dealer-head">
                2. Pickup & Drop-off Location
                </div>
                <div className="dealer-body">
                     
                <div className="form-group">
                         <div className="map-img">
           <MapWithAMarkerPickup
           currentLocation={{lat:fields.pickupLatLng.value.lat,lng: fields.pickupLatLng.value.lng}}
           sendingDrabbleLocationPickup={(latlng)=>this.getAddressbyLatLngPickup({lat:latlng.lat(),lng:latlng.lng()})}
         //  currentLocation={this.state.currentLatLng}
     containerElement={<div style={{ height: `400px`,borderRadius:20 }} />}
     mapElement={<div style={{ height: `100%`,borderRadius:20 }} />}
    /> 
        

       
                     </div>
                      </div>
                       
                      
                      <div className="form-group position-relative">
                         <label>
                        Pickup Address
                         </label>

                         <textarea row="5" maxLength="200" className="form-control" name="pickup_address" value={this.state.fields.pickup_address.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['pickup_address'].error&&this.state.fields['pickup_address'].error}</span>
                         {this.state.predictionsPickup&&this.state.predictionsPickup.length>0&& 
                         this.printPickupAddress()}
                     </div>
                     <div className="form-group">
                      

                        <div class="form-inline position_set">
                          <label>Type of Address &nbsp; * </label>
                          {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}
                          
                          <Select value={[parseInt(this.state.fields.pickup_address_type.value)]}  onChange={(value) => this.checkvalidations(value,'pickup_address_type')} name="pickup_address_type" >
                          {pickup_address_type&&pickup_address_type.length>0&&pickup_address_type.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid reason_dropdown-img"/>
                            <span className="error_msg_adddealer">{this.state.fields['pickup_address_type'].error&&this.state.fields['pickup_address_type'].error}</span> 
                           
                         
                         
                        {/* </div> */}
                          {/* <i
                          nput type="text" className="form-control"/>  */}
                        </div>                     
                     </div>
                     <div>
                     <div className="drop_off_location_text"><img className="check_img" src={fields.sameaddress.value==false?Images.checkbox_uncheck:Images.checkbox_check} onClick={()=>this.checkboxChange()}/>Drop-off Location is same as pickup</div></div>
                     <div className="form-group">
                         <div className="map-img">
                           
           <MapWithAMarkerDrop
           
           currentLocation={{lat:fields.cloneddropLatLng.value.lat,lng: fields.cloneddropLatLng.value.lng}}
           sendingDrabbleLocationDropoff={(latlng)=>this.getAddressbyLatLngDropoff({lat:latlng.lat(),lng:latlng.lng()})}
         //  currentLocation={this.state.currentLatLng}
     containerElement={<div style={{ height: `400px`,borderRadius:20 }} />}
     mapElement={<div style={{ height: `100%`,borderRadius:20 }} />}
    /> 
        

       
                     </div>
                      </div>
                      <div className="form-group position-relative">
                         <label>
                        Drop-off Address
                         </label>

                         <textarea row="5" className="form-control" name="drop_address" value={this.state.fields.drop_address.value} onChange={(e) => this.checkvalidations(e.target.value,e.target.name)}  ></textarea>
                         <span className="error_msg_adddealer">{this.state.fields['drop_address'].error&&this.state.fields['drop_address'].error}</span>
                         {this.state.predictionDropoff&&this.state.predictionDropoff.length>0&&
                         this.printDropoffAddress()}
                     </div>
                      <div className="form-group">
                      

                        <div class="form-inline position_set">
                          <label>Type of Address &nbsp; * </label>
                          {/* <div class="form-inline position-relative reason_text">
                          <label>Select Reason</label> */}
                          
                          <Select value={[parseInt(this.state.fields.drop_address_type.value)]}  onChange={(value) => this.checkvalidations(value,'drop_address_type')} name="drop_address_type" >
                          {drop_address_type&&drop_address_type.length>0&&drop_address_type.map((item)=>{
                              return(
                              <Option value={item.id}>{item.name}</Option>
                              )
                            })}
                           </Select> 
                            <img src={Images.dropdown} className="img-fluid reason_dropdown-img"/>
                            <span className="error_msg_adddealer">{this.state.fields['drop_address_type'].error&&this.state.fields['drop_address_type'].error}</span> 
                           
                         
                         
                        {/* </div> */}
                          {/* <i
                          nput type="text" className="form-control"/>  */}
                        </div>                     
                     </div>
                     
                </div>

                
             </div>
             <button className={`btn btn-white-blue prev-button `}  onClick={()=>this.goBack()}>  <img src={Images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button className="btn btn-dark-blue next-button" onClick={()=>this.gotoslotservice()}>Next  <img src={Images.next} className="img-fluid arrow"/></button>
             </div>

            </div>
        )





        
    }
    componentDidMount(){
      console.log("didmount",this.props);
      if(this.props.pickupLocation){
          this.setState({fields:this.props.pickupLocation},function(){
            this.loadpickupaddresstypeDropdown(this.state.fields.pickup_address_type.value);
            this.loaddropaddresstypeDropdown(this.state.fields.drop_address_type.value);
          });
      }else{
        this.loadpickupaddresstypeDropdown();
        this.loaddropaddresstypeDropdown();
      }
 
    }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAOFiQuLuDHiaqinhRibemtlsPMWEUJwas')
})(PickupDropLocation)
