import React, {Component} from 'react';
 
import Images from '../../helpers/images';
import ValidationLibrary from '../../helpers/validationfunction';
class AddDriverBankDetails extends Component{

    constructor(props){

        super(props);

        this.state = {

          fields : {account_name : {validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
          account_number : {validations:[{name:'required'},{name:'allowNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
          ifsc_code : {validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''},
          swipe_machine_name : {validations:[{name:'required'},{name:'alphabetsOnly'},{name:'Noemptyspace'}],error:null,value:''},
          swipe_machine_number : {validations:[{name:'required'},{name:'alphaNumaricOnly'},{name:'Noemptyspace'}],error:null,value:''}   
        }

        }
    }


    checkValidations = (value, key) => {

         var fields = this.state.fields;

         fields[key].value = value;

         var checkstatus=ValidationLibrary.checkValidation(value,fields[key].validations);
    
            fields[key].error=checkstatus.msg;
            
            var getallkeys=Object.keys(fields);
            
            var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
            
            if(filtererrors.length>0){

              this.setState({error:true})
              //errorblock
            }else{
              this.setState({error:false})

              //successblock
            }
            this.setState({fields});
        }

 

        BankDetails_next (){

          var fields = this.state.fields;
console.log("bank", fields);
          var getallkeys=Object.keys(fields);
          getallkeys.map((obj)=>{
          var checkstatus=ValidationLibrary.checkValidation(fields[obj].value,fields[obj].validations);
          fields[obj].error=checkstatus.msg;
          })
          var filtererrors=getallkeys.filter((obj)=>fields[obj].error!="");
          if(filtererrors.length>0){
            //errorblock
            this.setState({error:true})
          }else{
            //successblock
            this.setState({error:false});
          
            this.props.SendToBankNext(3,'DriverBankDetails',fields);
            console.log("rpops else")
          //   this.setState({svc1 : false});
          //   this.setState({svc2  : true});
          }
          this.setState({fields});


        }

        goBack = () => {
   
            this.props.sendBack(1);

        }

    render(){
        return(
            <div>

 
        <div class="overall-service">
             
  
             <div className="svc-dealer-sec">
             <div className="dealer-master">
                <div className="dealer-head">
                2. Bank Details
                </div>
                <div className="dealer-body">
                     
                     <div className="form-group">
                       <div class="form-inline">
                          <label>Account Name</label>
                          <input type="text" className="form-control" name="account_name" value={this.state.fields.account_name.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['account_name'].error && this.state.fields['account_name'].error}</span> 
                        </div>

                        <div class="form-inline">
                          <label>Account Number</label>
                          <input type="text" className="form-control"  maxLength="16" name="account_number" value={this.state.fields.account_number.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['account_number'].error && this.state.fields['account_number'].error}</span> 
                        </div>                     
                     </div>

                     <div className="form-group">
                       <div class="form-inline">
                          <label>IFSC Code</label>
                          <input type="text" className="form-control" name="ifsc_code" value={this.state.fields.ifsc_code.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['ifsc_code'].error && this.state.fields['ifsc_code'].error}</span> 
                        </div>

                        <div class="form-inline">
                          <label>Swipe Machine Name</label>
                          <input type="text" className="form-control" name="swipe_machine_name" value={this.state.fields.swipe_machine_name.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['swipe_machine_name'].error && this.state.fields['swipe_machine_name'].error}</span> 
                        </div>                     
                     </div>


                     <div className="form-group">
                       <div class="form-inline">
                          <label>Swipe Machine Serial Number</label>
                          <input type="text" className="form-control" name="swipe_machine_number" value={this.state.fields.swipe_machine_number.value} onChange={(e) => this.checkValidations(e.target.value, e.target.name)}/>
                          <span className="error_msg_driver">{this.state.fields['swipe_machine_number'].error && this.state.fields['swipe_machine_number'].error}</span> 
                        </div>
                      
                     </div>
                </div>


                
             </div>

             <button className="btn btn-white-blue prev-button" onClick={() => this.goBack()}><img src={Images.arrow_green} className="img-fluid pre-arrow"/> Prev</button>
             <button className="btn btn-dark-blue next-button" onClick={() => this.BankDetails_next()}>Next  <img src={Images.arrow_white} className="img-fluid arrow"/></button>
             

             {/* <button eventKey="second" className="btn btn-dark-blue next-button"  onClick={this.handleButtonClick}>Next  <img src={next} className="img-fluid arrow"/></button> */}
          </div>



 
   </div>     
            </div>
        )





        
    }

    componentDidMount(){
console.log("willreceive bank", this.props);
      if(this.props.DriverBankDetails){

        this.setState({fields : this.props.DriverBankDetails});
      }
    }
}


export default AddDriverBankDetails;